import React from "react";
import { Field } from "formik";
import { FormItem } from "formik-antd";
import { Modal, Card, Empty, Spin } from "antd";
import { FormOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../../colors";
import { cooperation } from "../../../apis";
import { useFetch } from "../../../hooks";
import { useTranslate } from "../../../hooks";

const { Meta } = Card;

export default ({ index, name }) => {
  const { translate } = useTranslate();
  const [action, setAction] = React.useState(false);
  const [cooperations, fetching] = useFetch(cooperation.list)([]);

  return (
    <FormItem name={name}>
      <Field name={name}>
        {fieldProps => {
          const {
            field: { value },
            form: { setFieldValue }
          } = fieldProps;

          const onSelect = (cooperation) => {
            setFieldValue(name, cooperation._id);
            setFieldValue(`children[${index}].cooperationValues`, {
              ...cooperation,
              type: cooperation.code
            });
            setAction(false);
          };

          const onRemove = () => {
            setFieldValue(name, undefined);
          };

          let cooperation = cooperations.find(c => c._id && c._id === value);

          const renderCooperation = (cooperation) => {
            let Icon = require("@ant-design/icons")[cooperation.icon];
            return (
              <Cooperation
                actions={[
                  <span key="remove" onClick={() => onRemove()}>{translate("system.delete", "Remove")}</span>,
                ]}>
                <Meta
                  avatar={<Icon size={22} />}
                  title={cooperation.name}
                  description={cooperation.description}
                />
              </Cooperation>
            );
          };

          return (
            <Spin spinning={fetching}>
              <CooperationContainer>
                {(() => {
                  if (cooperation)
                    return renderCooperation(cooperation);

                  if (fetching === false && cooperations.length === 0)
                    return (
                      <SelectContainer>
                        <Empty description={translate("message.error.noform", "You haven't created any cooperation form yet!")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </SelectContainer>
                    );

                  return (
                    <SelectContainer onClick={() => setAction(true)}>
                      <FormOutlined />
                      <div className="upload-text">{translate("message.selectform", "Select form")}</div>
                    </SelectContainer>
                  );
                })()}

                <Modal
                  maskClosable={false}
                  visible={action}
                  className="cooperation-modal"
                  title={translate("cooperationform.title", "Cooperation form")}
                  onCancel={() => setAction(false)}
                  footer={null}>
                  <div>
                    {cooperations[0] && cooperations[0]._id === undefined ?
                      <div>{translate("rakuten.noform", "No rakuten form")}</div>:
                      cooperations.filter(c => c._id).map((cooperation, index) => {
                        let Icon = require("@ant-design/icons")[cooperation.icon];
                        return (
                          <Cooperation
                            key={index}
                            actions={[
                              <span key="select" onClick={() => onSelect(cooperation)}>{translate("system.select", "Select")}</span>,
                            ]}>
                            <Meta
                              avatar={<Icon size={22} />}
                              title={cooperation.name}
                              description={cooperation.description}
                            />
                          </Cooperation>
                        );
                      })
                    }
                  </div>
                </Modal>
              </CooperationContainer>
            </Spin>
          );
        }}
      </Field>
    </FormItem>
  );
};

const Cooperation = styled(Card)`
  .anticon {
    font-size: 22px;
    color: ${colors.primaryDark};
  }
  .ant-card-meta-title {
    font-size: 17px;
    color: ${colors.primaryDark};
  }
  .ant-card-meta-description {
    color: #9a9a9a;
  }
`;

const CooperationContainer = styled.div`
  .ant-upload {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
    color: #9a9a9a;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 25px 0;
  border: 1px dashed #ddd;
  min-height: 160px;
  cursor: pointer;
  border-radius: 4px;
  .anticon {
    font-size: 22px;
    color: #607D8B;
  }
  &:hover {
    .upload-text {
      color: ${colors.primaryDark};
    }
  }
  .upload-text {
    font-size: 13px;
    margin-top: 10px;
    transition: all .2s;
    font-weight: 400;
  }
`;
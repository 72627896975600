import request from "../utils/request";

export const rakutenReview = (data) => request.post("/api/rakuten/review", data);
export const rakutenOrder = (data) => request.post("/api/rakuten/order", data);
export const orderMerge = (data) => request.post("/api/rakuten/order/merge", data);
export const orderReset = (data) => request.post("/api/rakuten/order/reset", data);
export const manualPayment = (data) => request.post("/api/debug/payment", data);
export const manualPaymentHistory = (data) => request.post("/api/debug/payment/history", data);
export const accountList = (data) => request.post("/api/debug/account_list", data);
export const followerSync = (data) => request.post(`/api/debug/follower_sync/${data._id}`, data);
export const richmenuDebug = () => request.post("/api/debug/richmenu");
export const scenarioCancel = () => request.post("/api/debug/follower_scenario");
export const couponResend = () => request.post("/api/debug/coupon_resend");
export const couponDelete = () => request.post("/api/debug/deletecoupon");
export const couponIssue = () => request.post("/api/debug/coupon_issue");
export const rakutenOrderReset = () => request.post("/api/debug/rakuten_order_reset");
export const logFix = () => request.post("/api/debug/log");
export const followerRakutenSync = (data) => request.post("/api/debug/follower_rakutensync", data);
export const rakutenCheck = (data) => request.post("/api/debug/rakuten_check", data);
export const crawlers = (data) => request.post("/api/debug/crawlers", data);
export const crawlerStart = (data) => request.post("/api/debug/crawler_start", data);
export const processReport = (data) => request.post("/api/debug/process_report", data);
export const broadcastReport = (data) => request.post("/api/debug/broadcast_report", data);
export const redisRemove = () => request.post("/api/debug/redis_remove");

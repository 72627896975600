import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import styled from "styled-components";
import colors from "../../colors";
import Form from "./components/AdminForm";
import { Button, Spin, Modal, message } from "antd";
import PasswordForm from "./components/PasswordForm";
import { user as userService } from "../../apis";
import { useFetch, useTranslate } from "../../hooks";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const { user } = useSelector(state => state.general);
  const [action, setAction] = useState(["update", user]);
  const [loading, isLoading] = useState(true);
  const [password, setPassword] = useState(null);

  const reload = () => {
    setAction(["update", user]);
    isLoading(false);
  };

  useEffect(() => {
    reload();
  }, []);

  const onSubmit = async (data) => {
    await userService.profile(data);

    message.success(translate("system.message.success", "Success"));
  };

  const onSubmitPassword = async (data) => {
    try {
      await userService.changePassword({ newPassword: data.newPassword, password: data.password });
      message.success(translate("system.message.success", "Password has been changed successfully."));
      setPassword();
    } catch (error) {
      if (error.message === "INVALID_PASSWORD") {
        message.error(translate("system.invalid.password", "The password is incorrect. Retype your password. Letters in passwords must be typed using the correct case"));
      }
      else {
        message.error(translate("system.error", error.message));
      }
    }
  };

  const modalPassword = () => {
    setPassword(["create"]);
  };

  const onCancel = () => {
    setPassword(null);
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("admin.edit", "Admin user configuration")} />

      <PageContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormWrapper>
            {loading ? <SpinWrapper><Spin className="spin-class" size="large" /></SpinWrapper> : (<>
              <div style={{ marginBottom: 20 }}>
                <Button type="default" onClick={modalPassword} block>{translate("useredit.password", "Change password")}</Button>
              </div>
            </>)}
            <Form action={action} onSubmit={onSubmit} />
          </FormWrapper>
        </div>
      </PageContent>

      <Modal
        maskClosable={false}
        title={translate("useredit.password", "Change password")}
        visible={password}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <PasswordForm type={user.role} onCancel={onCancel} onSubmit={onSubmitPassword} action={password} />
      </Modal>
    </PageContainer>
  );
};

const FormWrapper = styled.div`
  width: 600px;
`;

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
 
  .spin-class {
    .ant-spin-dot-item {
      background-color :${colors.primaryDark}
    }
  }
  
`;
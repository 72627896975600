import React from "react";
import styled from "styled-components";

export default ({ width, values = {} }) => {
  return (
    <Container style={{ width }}>
      <div className="image">
        <img src={values.previewUrl}/>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: transparent;
  width: 100%;
  padding: 4px;
  img{
    width :100%;
    border-radius: 10px;
  }
  .image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #e8e8e8;
  }
  .info {
    padding: 10px;
    width: 100%;
    background: #fff;
    h2 {
      font-size: 17px;
      margin-bottom: 5px;
      font-weight: 400;
    }
    p {
      font-size: 13px;
      font-weight: 300;
    }
  }
  .button {
    width: 100%;
    text-align: center;
    a {
      background: #e8e8e8;
      color: #333;
      font-weight: 300;
      display: block;
      text-transform: uppercase;
      padding: 10px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`;
/* eslint-disable no-nested-ternary */
import React from "react";
import { Form, FormItem, Select, Input, Checkbox, DatePicker } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { tags as tagsApi } from "../../../apis";
import { Form as AntForm, Checkbox as AntCheckbox, Avatar, Modal, Space, message } from "antd";
import { tailFormItemLayout } from "../../../utils";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../../colors";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import FormTag from "../../Tags/Form";

const { Option } = Select;

const ValueSchema =(translate) => Yup.object({
  tag  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  value: Yup.mixed().required(translate("system.message.required", "Please fill out this field!"))
});

const FormSchema =(translate) => Yup.object({
  tags  : Yup.array().of(Yup.string()).min(1, translate("system.message.required", "Please fill out this field!")),
  values: Yup.array().of(ValueSchema(translate)).min(1, translate("system.message.required", "Please fill out this field!"))
});

export default ({ selected, followers, onSubmit, onCancel, submitLoad }) => {
  const { tags } = useSelector(state => state.general);
  const [selections, setSelections] = React.useState(selected);
  const [action, setAction] = React.useState();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [data, setFormData] = React.useState({
    tags  : [],
    values: []
  });

  const onSave = (values) => {
    onSubmit({
      followers  : Object.keys(selections).filter(id => selections[id] === true && id !== "all"),
      tags       : values.values,
      setFormData: setFormData
    });
  };

  const onSelect = (follower, checked) => {
    setSelections({
      ...selections,
      [follower._id]: checked
    });
  };

  const onChange = (ids, values) => {
    setFormData({
      ...data,
      tags  : ids,
      values: ids.map(id => {
        let value = values.find(v => v.tag === id);
        let tag = tags.find(tag => tag._id === id);
        let val;

        switch (tag.type) {
          case "CHECKBOX":
            val = false;
            break;
          case "TAG":
            val = tag._id;
            break;
          default:
        }

        return {
          tag  : id,
          value: value !== undefined ? value.value : val
        };
      })
    });
  };

  const name = (tag) => {
    switch (tag.auto) {
      case "ages":
        return translate("tag.ages", "age");
      case "subscriptionPeriods":
        return translate("tag.days", "day");
      default:
        return "";
    }
  };

  const renderField = (id, index) => {
    let tag = tags.find(tag => tag._id === id);

    if (!tag) return;

    switch (tag.type) {
      case "SELECT": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`} required>
            <Select name={`values[${index}].value`} placeholder= {translate("system.select", "Select")}>
              {/* {tag.values.map((tag, index) => <Option key={index} value={tag._id}> {tag.auto === undefined ? tag.name : translate(`default.tag.${tag.auto}`, tag.name)} </Option>)} */}
              {tag.values.sort((a, b) => a.name > b.name ? 1 : -1).map((item, index) => <Option key={index} value={item._id}> {item.auto ?
                tag.auto === "ages" ? `${item.name} ${name(tag)}` : tag.auto === "subscriptionPeriods" ? `${item.name} ${name(item)}` :
                  translate(`default.tag.${item.value}`, item.name) : item.name
              }
              </Option>)}
            </Select>
          </FormItem>
        );
      }
      case "NUMBER": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name}name={`values[${index}].value`}>
            <Input type="number" name={`values[${index}].value`} />
          </FormItem>
        );
      }
      case "CHECKBOX": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`}>
            <Checkbox name={`values[${index}].value`} defaultChecked={false} />
          </FormItem>
        );
      }
      case "DATE": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`} required>
            <DatePicker name={`values[${index}].value`} style={{ width: "100%" }} />
          </FormItem>
        );
      }
      default:
    }
  };

  const onCreateTag = async (data) => {
    try {
      await tagsApi.create(data);
      await tagsApi.select(dispatch)();
    } catch (err) {
      message.error(err.message);
    }

    setAction(null);

    message.success(translate("system.message.success", "Success!"));

  };

  React.useEffect(() => {
    setFormData({});
    setSelections(selected);
  }, [selected]);

  return (
    <TaggedForm>
      <div className="followers">
        {followers.map((follower, index) => (
          <div key={index} className="follower">
            <AntCheckbox className="checked" checked={selections[follower._id]} onChange={e => onSelect(follower, e.target.checked)} />
            <Avatar size={22} src={follower.avatar} icon={<UserOutlined />} />
            <span className="fullname">{follower.name}</span>
          </div>
        ))}
      </div>
      <div className="tags">
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={() => FormSchema(translate)}
          onSubmit={onSave}>
          {({ isSubmitting, values, errors }) => {
            return (
              <Form layout="vertical">
                <SearchBox>

                  <Select name="tags" placeholder= {translate("system.search.button", "Search")} mode="multiple" onChange={(value) => onChange(value, values.values || [])} style={{ width: "100%" }}>
                    {tags.filter(t => !t.parent).map((tag, index) => {
                      return <Option key={index} value={tag._id}>{tag.auto === undefined ? tag.name: translate(`default.tag.${tag.auto}`, tag.name)}</Option>;
                    })}
                  </Select>
                </SearchBox>
                {values.tags && values.tags.map((id, index) => {
                  return renderField(id, index);
                })}
                <AntForm.Item style={{ marginTop: 20 }}>
                  <div className="group-btn">
                    <div >
                      <Button className="button-primary" htmlType="submit" type="primary" loading={submitLoad} style={{ marginRight: 5 }}>{translate("system.save", "Save")}</Button>
                      <Button className="button-primary" type="link" onClick={onCancel}>{translate("system.cancel", "Cancel")}</Button>
                    </div>
                    <Button onClick={()=> setAction(["createTag", {}])}>{translate("scenario.form.create.tag", "Create new tag")}</Button>
                  </div>

                </AntForm.Item>
              </Form>
            );
          }}
        </Formik>
      </div>
      {
        action&&action[0] === "createTag"&&(
          <Modal
            maskClosable ={false}
            title={translate("system.add", "Add")}
            visible={action && action[0] === "createTag"}
            onCancel={()=>setAction(null)}
            destroyOnClose
            footer={false}>
            <FormTag onCancel={()=>setAction(null)} onSubmit={onCreateTag} action={{}} />
          </Modal>
        )
      }

    </TaggedForm>
  );
};

const SearchBox = styled.div`
  margin-bottom: 20px;
`;

const TaggedForm = styled.div`
  display: flex;
  flex-direction: row;
  .group-btn {
    display:flex;
    width: 100%;
    justify-content: space-between;
  }

  .button-primary{
    border-color :white;
    background   :${colors.primary};
    color        : white;
  }
  .followers {  
    display: flex;
    flex-direction: column;
    min-height: 540px;
    overflow-y: auto;
    width: 35%;
    border-right: 1px solid #e8e8e8;

    .follower {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #e8e8e8;
      padding: 8px 10px;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: #fafafa;
      }
      .fullname {
        line-height: 17px;
        font-size: 13px;
        margin-left: 10px;
        font-weight: 500;
        color: #333;
      }
      .checked {
        margin-right: 10px;
      }
    }
  }
  .tags {
    display: flex;
    flex-direction: column;
    width: 65%;
    padding: 20px;
  }
`;
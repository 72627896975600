import React from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Button } from "antd";
import * as Yup from "yup";
import { useTranslate } from "../../../hooks";
import { useSelector } from "react-redux";


const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

const FormSchema =(translate) => Yup.object().shape({
  newPassword: Yup.string()
    .required(translate("system.message.required", "This field is required!"))
    .matches(lowercaseRegex, translate("system.password.lowercase", "Your password must have at least one lowercase"))
    .matches(uppercaseRegex, translate("system.password.uppercase", "Your password must have at least one uppercase"))
    .matches(numericRegex, translate("system.password.number", "Your password must have at least one number digit"))
    .min(8, translate("system.password.character", "Your password must have at least 8 characters")),
  password       : Yup.string().required(translate("system.message.required", "Please fill out this field")),
  confirmPassword: Yup.string().required(translate("system.message.required", "Please fill out this field"))
});

export default ({ type, action, onSubmit }) => {
  const { translate } = useTranslate();
  const { user } = useSelector(state=> state.general);
  const [data] = React.useState({
    password       : undefined,
    confirmPassword: undefined,
    newPassword    : undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const passwordLabel = (role) => {
    switch (role) {
      case "ADMIN":
        if (type === "ADMIN")
          return "Old password";
        return "Admin password";

      case "AGENCY":
        if (type === "AGENCY")
          return "Old password";
        return "Agency password";
      case "ACCOUNT":
        return "Old password";
      default: return null;
    }
  };

  const passwordLabelJP = (role) => {
    switch (role) {
      case "ADMIN":
        if (type === "ADMIN")
          return "system.password.OLD";
        return "system.password.ADMIN";

      case "AGENCY":
        if (type === "AGENCY")
          return "system.password.OLD";
        return "system.password.AGENCY";
      case "ACCOUNT":
        return "system.password.OLD";
      default: return null;
    }
  };

  return (
    <Formik
      initialValues={data}
      validationSchema={()=>FormSchema(translate)}
      validate={(data) => {
        if (data.newPassword && data.newPassword && data.newPassword !== data.confirmPassword)
          return {
            confirmPassword: translate("system.password.nomatch", "Password doesn't match")
          };
      }}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form layout="vertical">
          <FormItem label={translate(passwordLabelJP(user.role), passwordLabel(user.role))} name="password" required>
            <Input name="password" type="password" placeholder={translate("useredit.password.input", "Password")} />
          </FormItem>

          <FormItem label={translate("useredit.password.new", "New password")} name="newPassword" required>
            <Input name="newPassword" type="password" placeholder={translate("useredit.password.new", "New password")} />
          </FormItem>

          <FormItem label={translate("useredit.password.confirm", "Confirm password")} name="confirmPassword" required>
            <Input name="confirmPassword" type="password" placeholder={translate("useredit.password.confirm", "Confirm password")} />
          </FormItem>
          <AntForm.Item>
            <Button htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.change", "Change")}</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};


import request from "../utils/request";

// export const pay = (data) => request.post("/api/payment/pay", data);
export const cancel = (data) => request.post("/api/payment/cancel", data);
export const create = (data) => request.post("/api/payment/create", data);
export const history = (data) => request.post("/api/payment/history", data);
export const get = (data) => request.get(`/api/payment/${data}`);


// ========================update service=================================
export const purchase = (id) => request.post(`/api/payment/${id}/purchase`);
export const purchaseCancel = (id) => request.post(`/api/purchase/${id}/cancel`);
export const pay = (data) => request.post(`/api/payment/${data._id}/${data.type}/pay`, data.payment);
export const retry = (id) => request.post(`/api/payment/${id}/retry`);

export const exportFile = (data) => request.post("/api/payment/file/export", data);
// =======================================================================




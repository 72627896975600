import React, { useState } from "react";
import { Button, Upload, message, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../../hooks";
import styled from "styled-components";

const { Dragger } = Upload;

const validates = {
  large : { w: 1040, h: 1040 },
  custom: { w: 1040, h: [519, 2080] },
  draw  : { w: 1040, h: [519, 2080] },
};

export default (props) => {
  const { translate } = useTranslate();
  const { onCancel, action, onUpload } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { imageMapSelect } = useSelector(state => state.general);

  const beforeUpload = async (file) => {
    if (!imageMapSelect) return;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const isLt2M = file.size / 1024 / 1024 < 10;
    let isValidSize = false;

    if (!isLt2M)
      message.error(translate("imagemap.error.size", "Image must smaller than 10MB!"));

    if (!isJpgOrPng)
      message.error(translate("imagemap.error.fileformat", "You can only upload JPG/PNG file!"));

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => {
        setIsLoading(true);

        const size = validates[imageMapSelect.type];

        if ((imageMapSelect.type === "custom" || imageMapSelect.type === "draw") && size.w === image.width && size.h[0] <= image.height && size.h[1] >= image.height)
          isValidSize = true;

        if (imageMapSelect.type === "large" && size.w === image.width && size.h === image.height)
          isValidSize = true;

        if (!isValidSize) {
          message.error(translate("richmenu.invalid.image", "Invalid image size!"));
          setIsLoading(false);
        }

        if (isValidSize && isJpgOrPng && isLt2M)
          resolve(true);
        else
          reject(new Error("Invalid"));
      });

      image.src = URL.createObjectURL(file);
    });
  };

  const options = {
    name          : "file",
    accept        : ["image/jpeg", "image/png"],
    action        : action,
    beforeUpload  : beforeUpload,
    showUploadList: false,
    onChange      : (info) => {
      const { status } = info.file;

      if (status === "done") {
        message.success(`${info.file.name} ${translate("system.message.success.upload", "File uploaded successfully.")}`);

        if (onUpload)
          onUpload(info.file.response);

        setIsLoading(false);
      } else if (status === "error") {
        message.error(`${info.file.name} ${translate("system.message.failed.upload", "File upload failed.")}`);
      }
    },
  };

  return (
    <UploadWrapper>
      <Spin spinning={isLoading}>
        <Dragger {...options}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {translate(
              "richmenu.upload.text",
              "Click or drag file to this area to upload"
            )}
          </p>
          <div className="ant-upload-hint">
            <div>
              {translate("imagemap.file.size", "File formats: JPG, JPEG, PNG")}
            </div>
            <div>{translate("imagemap.filesize", "File size: Up to 10 MB")}</div>
            <div>{translate("imagemap.imagesize", "Image Sizes")}</div>

            {imageMapSelect && (imageMapSelect.type === "custom" || imageMapSelect.type === "draw") ? "1040 x 520~2080px" : "1040x1040px"}
          </div>
        </Dragger>
      </Spin>

      <UploadFooter>
        <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>
          {translate("system.cancel", "Cancel")}
        </Button>
      </UploadFooter>
    </UploadWrapper>
  );
};

const UploadFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const UploadWrapper = styled.div`
  .ant-upload-text {
    margin-bottom: 20px;
  }
  .ant-upload-hint {
    font-size: 12px!important;
  }
`;
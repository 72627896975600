import { message } from "antd";

const initialState = {
  dragging  : null,
  enter     : null,
  scale     : 0.7,
  width     : 2000,
  height    : 2000,
  data      : {},
  isSubmit  : false,
  isEditable: false,
  selected  : {
    type: ""
  },
  events      : [],
  mapEvents   : {},
  childrenForm: {},
  children    : [],
  validate    : [],
  index       : {}
};

const filterDragging = ({ children }) => {
  if (!children) return [];

  let ids = children.reduce((accumulator, iterator) => {
    if (iterator.children && iterator.children.length > 0)
      return [...accumulator, iterator._id, ...filterDragging({ children: iterator.children })];

    return [...accumulator, iterator._id];
  }, []);

  return ids;
};

const filterChildren = ({ children, ids }) => {
  if (!children) return [];

  return children.reduce((accumulator, iterator) => {
    if (ids.indexOf(iterator._id) === -1 && iterator.children && iterator.children.length > 0)
      return [...accumulator, {
        ...iterator,
        children: filterChildren({ children: iterator.children, ids })
      }];

    if (ids.indexOf(iterator._id) === -1 && iterator.type !== "ghost")
      return [...accumulator, iterator];

    return accumulator;
  }, []);
};

const ghostChildren = ({ children, child, dragging }) => {
  if (!children) return [];

  return children.reduce((accumulator, iterator) => {
    if (iterator.children && iterator.children.length >= 0) {
      if (iterator._id === child._id && child.condition === "children") {
        return [...accumulator, {
          ...iterator,
          children: ghostChildren({ children: iterator.children, child, dragging })
        }, {
          type     : "ghost",
          shape    : dragging && dragging[0] && dragging[0].type,
          condition: `${iterator.condition}`
        }];
      }

      if (iterator._id === child._id && (iterator.type === "condition" || iterator.type === "judgement" || iterator.type === "when")) {
        return [...accumulator, {
          ...iterator,
          children: [{
            type     : "ghost",
            shape    : dragging && dragging[0] && dragging[0].type,
            condition: `${child.condition}`,
          }, ...ghostChildren({ children: iterator.children, child, dragging })]
        }];
      }

      if (iterator._id === child._id) {
        return [...accumulator, {
          ...iterator,
          children: [{
            type : "ghost",
            shape: dragging && dragging[0] && dragging[0].type,
          }, ...ghostChildren({ children: iterator.children, child, dragging })]
        }];
      }

      return [...accumulator, {
        ...iterator,
        children: ghostChildren({ children: iterator.children, child, dragging })
      }];
    }

    if (iterator._id === child._id) {
      return [...accumulator, iterator, {
        type     : "ghost",
        shape    : dragging && dragging[0] && dragging[0].type,
        condition: iterator.condition
      }];
    }

    if (iterator.type !== "ghost")
      return [...accumulator, iterator];

    return accumulator;
  }, []);
};

const normalize = ({ stepIndex, children, child, dragging }) => {
  if (!children) return [];

  return children.reduce((accumulator, iterator) => {
    if (iterator.children && iterator.children.length >= 0) {
      if (child && iterator._id === child._id && child.condition === "children") {
        return [...accumulator, {
          ...iterator,
          stepIndex,
          children: normalize({ children: iterator.children, child, stepIndex, dragging })
        }, ...dragging.map(child => ({ ...child, condition: iterator.condition }))];
      }

      if (child && iterator._id === child._id && (iterator.type === "condition" || iterator.type === "judgement" || iterator.type === "when")) {
        let condition = "then";

        if (child.condition === "catch")
          condition = "catch";

        return [...accumulator, {
          ...iterator,
          stepIndex,
          children: [...dragging.map(child => ({ ...child, condition })), ...normalize({ children: iterator.children, child, stepIndex, dragging })]
        }];
      }

      if (child && iterator._id === child._id) {
        return [...accumulator, {
          ...iterator,
          stepIndex,
          children: [...dragging.map(child => ({ ...child })), ...normalize({ children: iterator.children, child, stepIndex, dragging })]
        }];
      }

      return [...accumulator, {
        ...iterator,
        stepIndex,
        children: normalize({ children: iterator.children, child, dragging, stepIndex: iterator.type === "step" ? iterator.index : stepIndex })
      }];
    }

    if (child && iterator._id === child._id) {
      return [...accumulator, { ...iterator, stepIndex }, ...dragging.map(child => ({ ...child, condition: iterator.condition }))];
    }

    if (iterator.type !== "ghost") {
      return [...accumulator, {
        ...iterator,
        stepIndex
      }];
    }

    return accumulator;
  }, []);
};

export const validateChildren = ({ childrenForm, children }) => {
  if (!children) return [];

  return children.reduce((accumulator, iterator) => {
    if (["step", "start", "trigger", "end"].indexOf(iterator.type) !== -1) return accumulator;

    if (iterator.children && iterator.children.length >= 0) {

      if ((iterator.type === "condition" || iterator.type === "judgement" || iterator.type === "when")) {
        if (iterator.type === "judgement")
          return [...accumulator, ...validateChildren({ childrenForm, children: iterator.children })];

        return [...accumulator, { id: iterator._id, valid: !!childrenForm[iterator._id] }, ...validateChildren({ childrenForm, children: iterator.children })];
      }

      return [...accumulator, ...validateChildren({ childrenForm, children: iterator.children })];
    }

    return [...accumulator, { id: iterator._id, valid: !!childrenForm[iterator._id] }];
  }, []);
};

const countChildren = ({ children = [] }, map = {}) => {
  return children.reduce((accumulator, iterator) => {
    if (iterator.children?.length > 0)
      return countChildren({ children: iterator.children }, { ...accumulator, [iterator.type]: (accumulator[iterator.type] || 0) + 1 });

    return { ...accumulator, [iterator.type]: (accumulator[iterator.type] || 0) + 1 };
  }, map);
};

const stepIndex = ({ children, state }) => {
  return children.filter(child => child.type === "step" || child.type === "trigger").sort((a, b) => {
    return a.sort - b.sort;
  }).reduce((accumulator, iterator, index) => {
    return {
      ...accumulator,
      [iterator._id]: (!state.index[iterator._id] || state.index[iterator._id] !== index) ? index : state.index[iterator._id]
    };
  }, {});
};

const process = (state = initialState, action) => {
  switch (action.type) {
    case "process.scale": {
      return {
        ...state,
        scale: action.payload
      };
    }
    case "process.step":
      let children = state.children.reduce((accumulator, iterator) => {
        if (iterator._id === action.payload._id)
          return [...accumulator, { ...iterator, x: action.payload.x, y: action.payload.y }];

        return [...accumulator, iterator];
      }, []);

      return {
        ...state,
        children: children,
        index   : stepIndex({ children, state })
      };
    case "process.valid":
      return {
        ...state,
        children: action.payload
      };
    case "process.isSubmit":
      return {
        ...state,
        isSubmit: action.payload
      };
    case "process.isEditable":
      return {
        ...state,
        isEditable: action.payload
      };
    case "process.selected": {
      if (state.selected?._id === action.payload?._id)
        return state;

      return {
        ...state,
        selected: action.payload
      };
    }
    case "process.data":
      return {
        ...state,
        data: action.payload
      };
    case "process.index":
      return {
        ...state,
        index: action.payload
      };
    case "process.childrenForm":
      return {
        ...state,
        childrenForm: action.payload
      };
    case "process.form":
      return {
        ...state,
        childrenForm: {
          ...state.childrenForm,
          [action.payload._id]: action.payload.form
        }
      };
    case "process.children":
      return {
        ...state,
        dragging: null,
        children: action.payload
      };
    case "process.validate": {
      return {
        ...state,
        validate: validateChildren({ childrenForm: state.childrenForm, children: state.children })
      };
    }
    case "process.normalize": {
      if (action.payload.type === "clear") {
        return {
          ...state,
          enter   : null,
          dragging: null
        };
      }

      if (state.enter && state.enter.type === "clear") {
        return {
          ...state,
          enter   : null,
          dragging: null,
          children: normalize({ children: state.children })
        };
      }

      let children = normalize({ children: state.children, child: state.enter, dragging: state.dragging.children });
      let childrenForm = { ...state.childrenForm };
      let invalid = children.reduce((accumulator, iterator) => {
        if (accumulator) return accumulator;
        return countChildren({ children: iterator.children })?.condition > 1;
      }, false);

      if (invalid) {
        message.warning("1つのステップの中には条件パーツは1つだけしか設定できません！");

        return state;
      }

      if (!state.enter) {
        let shape = {
          type    : "empty",
          x       : action.payload.x,
          y       : action.payload.y,
          children: state.dragging.children
        };

        if (state.dragging.children[0] && state.dragging.children[0].type === "step") {
          shape = {
            ...state.dragging.children[0],
            x: action.payload.x,
            y: action.payload.y
          };

          childrenForm[shape._id] = {
            ...(childrenForm[shape._id] || {}),
            ...shape
          };
        }

        children = [...children, shape];
      }

      children = children.reduce((accumulator, iterator) => {
        if (iterator.type === "empty" && iterator.children.length === 0)
          return accumulator;

        return [...accumulator, iterator];
      }, []);

      return {
        ...state,
        enter       : null,
        dragging    : null,
        selected    : state.dragging.children[0],
        children    : children,
        childrenForm: childrenForm,
        index       : stepIndex({ children, state })
      };
    }
    case "process.dragging": {
      let ids = filterDragging({ children: action.payload.children || [] }) || [];
      let children = filterChildren({ children: state.children || [], ids });

      return {
        ...state,
        children: children,
        dragging: action.payload
      };
    }
    case "process.enter": {
      if (action.payload && action.payload.type === "clear") {
        return {
          ...state,
          enter: action.payload
        };
      }

      if (action.payload && state.dragging && state.dragging.children && state.dragging.children[0] && state.dragging.children[0].type !== "step") {
        return {
          ...state,
          children: ghostChildren({ children: state.children, child: action.payload, dragging: state.dragging && state.dragging.children }), // [...state.children.filter(child => child._id !== iterator._id), iterator],
          enter   : action.payload
        };
      }

      return {
        ...state,
        enter   : null,
        children: normalize({ children: state.children }),
        index   : stepIndex({ children: state.children, state })
      };
    }
    case "process.events":
      return {
        ...state,
        events   : action.payload,
        mapEvents: action.payload.reduce((accumulator, iterator) => {
          return {
            ...accumulator,
            [iterator.type]: {
              ...(accumulator[iterator.type] || {}),
              [iterator.index]: iterator
            }
          };
        }, {}),
      };
    default:
      return state;
  }
};

export default process;
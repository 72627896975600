import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { coupon, coupon as couponApi } from "../../apis";
import Form from "./Form";
import { useTranslate } from "../../hooks";
import { Checkbox, message, Modal, Button } from "antd";
import moment from "moment";
import styled from "styled-components";


export default () => {
  const { translate } = useTranslate();
  const params = useParams();
  const history = useHistory();
  const [confirmCheck, setComfirmCheck] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [action, setAction] = React.useState();
  const submitRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [warningAlert, setWarningAlert] = React.useState("");

  const reload = React.useCallback(async (signal) => {
    let res = await couponApi.get(params.id, { signal });

    if (res.conditionTypeCode === "RS003") {
      res = { ...res, conditionStartValueRS003: parseInt(res.conditionStartValue, 10) };
    }
    else if (res.conditionTypeCode === "RS004") {
      res = { ...res, conditionStartValueRS004: parseInt(res.conditionStartValue, 10) };
    }
    if (res.memberAvailMaxCount === 0) {
      res.memberAvailType = false;
    }
    if (res.issueCount === 0) {
      res.issueType = false;
    }
    if (res?.itemType === 5) {
      res.itemType = 0;
    }

    setAction(["update", { ...res }]);
  }, [params.id]);

  const onSubmit = async () => {
    const isValid = await submitRef.current.validate();
    if (!isValid) return;

    if (isValid.discountType === 1) {
      isValid.discountFactor.percent = undefined;
    }
    if (isValid.discountType === 2) {
      isValid.discountFactor.flat = undefined;
    }
    if (isValid.discountType === 4) {
      isValid.discountFactor = undefined;
    }

    if (isValid.memberAvailType === false) {
      isValid.memberAvailMaxCount = 0;
    }

    if (isValid.issueType === false) {
      isValid.issueCount = 0;
    }
    let ageRangeCond = isValid.ageRangeCond;
    let purchaseHistoryCond = isValid.purchaseHistoryCond;

    setAction(["update", isValid]);
    if (ageRangeCond.ageType && ageRangeCond.upperBound < ageRangeCond.lowerBound) {
      message.error(translate("coupon.alert.agerangecond", "Age range is invalid!"));
      return;
    }

    if (purchaseHistoryCond.type === 2 && purchaseHistoryCond.purchaseCount?.maximum > 0 && purchaseHistoryCond.purchaseCount?.maximum < purchaseHistoryCond.purchaseCount?.minimum) {
      message.error(translate("coupon.alert.purchasehistorycond", "Purhcase count is invalid!"));
      return;
    }
    if (moment(isValid.schedules[0]) > moment(new Date()).add(30, "day")) {
      message.error(translate("coupon.alert.30day", "Start date must be within one month from now"));
      return;
    }
    if (moment(isValid.schedules[0]).valueOf() < moment().add(1, "hour").valueOf()) {
      message.error(translate("coupon.alert.onehour", "Start date must be after one hour"));
      return;
    }
    if (isValid.discountFactor?.flat && isValid.discountFactor?.flat > isValid?.conditionStartValueRS003) {
      setWarningAlert(translate("coupon.logic.message", "Amount of the usage condition is lower than the fixed discount amount , if you use this coupon, the sales may become 0 yen"));
      setVisible(true);
      return;
    }
    if ((isValid.discountFactor?.flat && isValid?.conditionStartValueRS004) || (isValid.discountFactor?.flat && !isValid?.conditionTypeCode)) {
      setWarningAlert(translate("coupon.logic.message.2", "If you use this coupon, the sales may be 0 yen because the flat-rate discount amount is set without specifying the amount of usage condition"));
      setVisible(true);
    }
    else {
      send(isValid);
    }
  };
  const send = async (isValid) => {

    let couponData = {};
    if (isValid?.name) {
      couponData = { ...isValid };
    } else {
      couponData = action[1];
    }

    if (!couponData.conditionTypeCode) {
      couponData.conditionTypeCode = null;
    }
    if (couponData?.item?.length === 1) {
      couponData.itemType = 1;
    }
    if (couponData?.item?.length > 1) {
      couponData.itemType = 3;
    }
    if (couponData.discountType === 4) {
      couponData.itemType = 5;
    }

    if (couponData.memberAvailType === false) {
      couponData.memberAvailMaxCount = 0;
    }

    if (couponData.conditionStartValueRS003) {
      couponData = { ...couponData, conditionStartValue: couponData.conditionStartValueRS003 };
    }
    else if (couponData.conditionStartValueRS004) {
      couponData = { ...couponData, conditionStartValue: couponData.conditionStartValueRS004 };
    }

    if (!couponData.discountFactor) {
      couponData.discountFactor = {};
    }

    try {
      setLoading(true);
      await couponApi.update({ ...couponData, item: couponData?.item?.toString(), couponCode: params.id });
      message.success(translate("system.message.success", "Success"));
      onCancel();
      history.push("/rakuten/coupon");
    } catch (err) {
      if (err && Array.isArray(err.message)){
        setErrors([...err.message]);
      } else {
        message.error(err?.message?.message);
      }
    }
    setVisible(false);
  };
  const headerButtons = () => {
    if ((action && action[1].couponStatus === "NEW" && moment(action && action[1].couponStartDate).subtract(1, "hour").format("YYYY-MM-DD HH:mm") < moment(new Date).format("YYYY-MM-DD HH:mm")) || action && action[1].couponStatus === null) {
      return [
        <Button disabled={(action && action[1].couponStatus !== "NEW")} key={2} type="primary" onClick={() => onSubmit()} >{translate("system.save", "Save")}</Button>
      ];
    }
  };

  const onCancel =()=> {
    setVisible(false);
    setLoading(false);
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <Container>
      <PageContainer style={{ PointerEvent: action && action[1]?.couponStatus !== "STARTED" ? "none" : "" }}>
        <PageHeader title={translate("coupon.edit.title", "Edit Coupon")} onBack={() => history.goBack()} />

        <PageContent>
          {action && action[1] && <Form details={true} ref={submitRef} action={action} onSubmit={null} errors={errors} />}
        </PageContent>

        <Modal
          title={<div style={{ color: "red", display: "flex", justifyContent: "center" }}>{translate("coupon.logic.title", "Please note")}</div>}
          visible={visible}
          onCancel={()=>onCancel()}
          footer={[
            <Button type="default" onClick={()=>onCancel()}>{translate("system.cancel", "Cancel")}</Button>,
            <Button disabled={!confirmCheck} type="primary" loading={loading} onClick={send}>{translate("system.confirm", "Confirm")}</Button>
          ]}
        >
          <p>{warningAlert}</p>
          <div>
            <Checkbox defaultChecked={confirmCheck} onChange={(e) => setComfirmCheck(e.target.checked)}>{translate("coupon.logic.checkbox", "Register this coupon")}</Checkbox>
          </div>
        </Modal>

      </PageContainer>
    </Container>
  );
};

const Container = styled.div`

`;
import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useFetch, useTranslate } from "../../../hooks";
import { PageContainer, PageContent, PageHeader } from "../../../components/Layout";
import CustomTable from "../../../components/CustomTable";
import { operation as operationApi } from "../../../apis";
import { Avatar, Button, Space, Spin, Tag } from "antd";


export default () => {
  const params = useParams();
  const history = useHistory();
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const [actions, setActions] = React.useState([]);

  const [data, fetching] = useFetch(operationApi.carouselMulticastDetail, params.id)({});
  

  const richActions = (item) => {

    if (item.type === "uri"){
      return (<Tag>{item.linkUri}</Tag>);
    }

    if (item.type === "coupon"){
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {item.couponCode}
          <Button onClick={()=>{history.push(`/rakuten/coupon/detail/${item.couponCode}`);}}
          size="small">{translate("system.show.detail", "Show details")}</Button>
        </div>);
    }

    if (item.type === "message"){
      return item.text;
    }

    if (item.type === "cooperation"){
      return item.type;
    }

    if (item.type === "no_action"){
      return "-";
    }
  };

  const onCarouselDetail = () => {
    history.push(`/carousel/edit/${data._id}`);
  }

  if (fetching && actions.length === 0) {
    return(
      <Spin>
          <div style={{border:"solid 1px",height:"100vh",width:"100%"}}>
          </div>
      </Spin>
    )
  }

  return (
   <Container>
      <PageContainer>
      <PageHeader onBack={()=>history.goBack()} title={translate("carousel.detail.title", "Report carousel detail")}/>
      <PageContent>
        <div>
          <Space direction="vertical">
            <div style={{color : "gray"}}>{translate("carousel.detail.explain", "Actions clicks will be counted only when action types are Weblink and Rakuten coupon")}</div>
          </Space>

          <div style={{ height: "10px" }}></div>

          <Space direction="horizontal">
            <div style={{}}>{translate("carousel.detail.carouselaltext", "Carousel title:")}</div>
            <div style={{}}>{data.altText}</div>
          </Space>

          <div style={{ height: "10px" }}></div>

          <Space direction="horizontal">
            <div style={{}}>{translate("carousel.detail.carouseltype", "Carousel type:")}</div>
            <div style={{}}>{translate(`carousel.detail.carouseltype.${data.type}`, data.type)}</div>
          </Space>

          <div style={{ height: "10px" }}></div>

          <div className="action-container">
            <Button onClick={onCarouselDetail}>{translate("carousel.detail.button","Carousel detail")}</Button>
          </div>

          <div style={{ height: "10px" }}></div>
          <CarouselDetail
            ref={myTableRef}
            dataSource={data.columns}
            bordered
            thead={() => (
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell" rowSpan="2">{translate("system.number", "No.")}</th>
                  <th className="ant-table-cell" rowSpan="2">{translate("carousel.detail.image", "Image")}</th>
                  <th className="ant-table-cell" rowSpan="2">{translate("carousel.detail.rowtitle", "Title")}</th>
                  <th className="ant-table-cell" rowSpan="2">{translate("carousel.detail.impression", "Impressions")}</th>
                  <th className="ant-table-cell" colSpan="5" >{translate("carousel.detail.action", "Action")}</th>
                </tr>
                <tr>
                  <th className="ant-table-cell">{translate("system.number", "No.")}</th>
                  <th className="ant-table-cell">{translate("carousel.detail.label", "Label")}</th>
                  <th className="ant-table-cell" >{translate("carousel.detail.type", "Type")}</th>
                  <th className="ant-table-cell" >{translate("carousel.detail.content", "Content")}</th>
                  <th className="ant-table-cell" >{translate("carousel.detail.clickcount", "Click count")}</th>
                </tr>
              </thead>
            )} tbody={(row, index) => (
              <>
                <tbody key={index} className="ant-table-tbody">
                  <tr>
                    <td className="ant-table-cell" rowSpan="3">{index + 1}</td>
                    <td className="ant-table-cell" rowSpan="3">
                      <Avatar shape="square" size={60} src={row.thumbnailImageUrl} />
                    </td>
                    <td className="ant-table-cell" rowSpan="3">{row.title}</td>
                    <td className="ant-table-cell" rowSpan="3">{row.columnSeenCount}</td>
                    <td className="ant-table-cell" width="25px">{row?.actions[0] ? 1 : "-"}</td>
                    <td className="ant-table-cell">{row?.actions[0] && row?.actions[0]?.label ? row?.actions[0]?.label : "-"}</td>
                    <td className="ant-table-cell">{row.actions[0]?.type && row.actions[0]?.type ? translate(`richmenu.action.${row.actions[0]?.type}`, row.actions[0]?.type) : "-"}</td>
                    <td className="ant-table-cell">{row?.actions[0] && row?.actions[0] ?richActions(row?.actions[0]) : "-"}</td>
                    <td className="ant-table-cell" >{row?.actions[0] && row?.actions[0]?.clickCount ? row?.actions[0]?.clickCount : "-"}</td>
                  </tr>
                  <tr>
                    <td className="ant-table-cell" width="25px">{row?.actions[1] ? 2 : "-"}</td>
                    <td className="ant-table-cell">{row?.actions[1] && row?.actions[1]?.label ? row?.actions[1]?.label : "-"}</td>
                    <td className="ant-table-cell">{row.actions[1]?.type && row.actions[1]?.type ? translate(`richmenu.action.${row.actions[1]?.type}`, row.actions[1]?.type) : "-"}</td>
                    <td className="ant-table-cell">{row?.actions[1] && row?.actions[1] ?richActions(row?.actions[1]) : "-"}</td>
                    <td className="ant-table-cell" >{row?.actions[1] && row?.actions[1]?.clickCount ? row?.actions[1]?.clickCount : "-" }</td>
                  </tr>
                </tbody>
              </>
            )} />
        </div>
      </PageContent>
    </PageContainer>
   </Container>
  );
};

const Container = styled.div`
  .action-container { 
    display: flex;
    justify-content: start;
    width: 100%;
  }
`;

const CarouselDetail = styled(CustomTable)`
  .ant-pagination { 
    display: none;
  }
  .ant-table-cell {
    padding: 8px!important;
  }
  .cm-td { 
    border-bottom: solid 2px #dcd8d8 !important
  }
  .cm-th {
    border-bottom: solid 2px #dcd8d8 !important;
      padding: 8px!important;
  }
`;
import React from "react";
import { PageHeader, PageContent, PageContainer } from "../../components/Layout";
import { RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import { coupon as couponApi, settingApi } from "../../apis";
import { Empty, Input, Space, Select, DatePicker, Tag, Image, message, Modal } from "antd";
import { PlusOutlined, SearchOutlined, ReloadOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button } from "../../components/Design";
import { usePermission, useTranslate } from "../../hooks";
import styled from "styled-components";
import colors from "../../colors";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { useSelector } from "react-redux";
import Avatar from "antd/lib/avatar/avatar";
import copy from "copy-to-clipboard";
import confirm from "antd/lib/modal/confirm";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import AutoForm from "./components/AutoForm";
import AutoFormList from "./components/AutoFormList";
import { ManualAlert, ManualDesc, ManualLink } from "../../components/Manual";

const { Option } = Select;
export default () => {
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const history = useHistory();
  const { checkRole } = usePermission();
  const [action, setAction] = React.useState([]);
  const [type, setType] = React.useState("couponName");
  const { rakutenDiscountTypes, rakutenMemberLevels, rakutenItemTypes, lang, rakutenCouponStatuses } = useSelector(state => state.general);
  const [discountTypes, setDiscountTypes] = React.useState(null);
  const [memberLevels, setMemberLevels] = React.useState(null);
  const [itemTypes, setItemTypes] = React.useState(null);
  const [couponStatuses, setCouponStatuses] = React.useState(null);
  const [query, setQuery] = React.useState("");
  const [disabled, setDisabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));
  const [showAlert, setShowAlert] = React.useState(false);

  const [filters, setFilters] = React.useState({
    query: ""
  });

  const onCopy = async (e) => {
    copy(e);
    message.success(translate("system.message.success", "Success"));
  };

  const getSystemSetting = async () => {
    try {
      const { payload } = await settingApi.get("COUPON_API_CHANGE");
      setShowAlert(payload);
    } catch (error) {
      message.error("Error fetching account settings.");
    }
  };
  React.useEffect(() => {
    getSystemSetting();
  }, []);
  const onAutoIssue = async (data) => {
    if (data.isAutoIssue && data?.autoIssue?.type === "CUSTOM") {
      if (moment(data.autoIssue?.startTime) > moment(data.autoIssue?.expireTime).add(data.autoIssue?.expireDay, "days")) {
        message.error(translate("coupon.alert.autoissue.expiretime", "Auto issue expire date must be from later than start date"));
        return;
      }
    }
    try {
      await couponApi.isAutoIssue(data);
      message.success(translate("system.message.success", "Successful"));
      setAction([]);
      myTableRef.current.reload();
    } catch (error) {
      if (error.message === "RAKUTEN_COUPON_IS_AUTO_ISSUED")
        message.error(`${translate("coupon.auto.alert.used", "Coupon is already auto issued !")}`);

      if (error.message === "RAKUTEN_COUPON_NOT_FOUND")
        message.error(`${translate("coupon.auto.alert.notfound", "Coupon not found !")}`);

      if (error.message === "RAKUTEN_COUPON_AUTOISSUE_NOTFOUND")
        message.error(`${translate("coupon.auto.alert.issue.notfound", "Can't find coupon auto issue schedule")}`);
      setAction([]);
    }
  };

  const onAction = async (key, item) => {
    switch (key) {
      case "issue": {
        setAction(["autoissue", item]);
        break;
      }
      case "edit": {
        history.push(`/rakuten/coupon/edit/${item.couponCode}`);
        break;
      }
      case "copy": {
        history.push(`/rakuten/coupon/copy/${item.couponCode}`);
        break;
      }
      case "detail": {
        history.push(`/rakuten/coupon/edit/${item.couponCode}`);
        break;
      }
      case "remove": {
        confirm({
          icon      : <ExclamationCircleOutlined />,
          content   : translate("coupon.delete.alert", "Only coupons with a start time of 60 minutes before can be deleted. Do you want to delete it?"),
          okText    : translate("system.yes", "Yes"),
          cancelText: translate("system.no", "No"),
          async onOk() {
            try {
              await couponApi.remove(item.couponCode);
              message.success(translate("system.message.success", "Successful"));
            } catch (err) {
              message.error("coupon.delete.failed", "Delete failed");
            }

            myTableRef.current.reload();
          },
          okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
          cancelButtonProps: { style: { borderColor: colors.primaryDark } }
        });
        break;
      }
      default:
        break;
    }
  };

  const isAutoIssue = (isAutoIssue) => {
    if (isAutoIssue === true)
      return translate("coupon.auto.cancel", "Cancel auto");
    return translate("coupon.auto.set", "Set auto");
  };

  const dangerDateCheck = (row) => {
    if (row.couponStatus === "NEW") {
      if (moment(row.couponStartDate).subtract(1, "hour").format("YYYY-MM-DD HH:mm") < moment(new Date).format("YYYY-MM-DD HH:mm")) {
        return {
          detail: `${translate("system.detail", "Detail")}`,
          copy  : `${translate("coupon.copy", "Copy")}`,
          issue : isAutoIssue(row.isAutoIssue)
        };
      }
      return {
        edit  : `${translate("system.edit", "Edit")}`,
        copy  : `${translate("coupon.copy", "Copy")}`,
        remove: `${translate("system.delete", "Remove")}`,
        issue : isAutoIssue(row.isAutoIssue)
      };
    }
    // cancel auto row?.autoIssue?.couponStatus === "HOLD" ||
    if (row.couponStatus === "ENDED") {
      return {
        copy  : `${translate("coupon.copy", "Copy")}`,
        detail: `${translate("system.detail", "Detail")}`,
        issue : isAutoIssue(row.isAutoIssue)
      };
    }

    return {
      copy  : `${translate("coupon.copy", "Copy")}`,
      detail: `${translate("system.detail", "Detail")}`,
      issue : isAutoIssue(row.isAutoIssue)
    };
  };

  const convertArrayToObject = (array, key) => {
    return array.reduce((accumulator, iterator) => {
      return {
        ...accumulator,
        [iterator[key]]: iterator,
      };
    }, {});
  };
  // {row.isAutoIssue=== true?"TRUE":"FALSE";}
  const renderStatus = (type, value) => {
    switch (type) {
      case "NEW":
        return (<Tag color="green">{value}</Tag>);
      case "STARTED":
        return (<Tag color="orange">{value}</Tag>);
      case "ENDED":
        return (<Tag color="red">{value}</Tag>);
      default:
        return "";
    }
  };


  React.useEffect(() => {
    if (rakutenDiscountTypes.length > 0 && rakutenCouponStatuses?.length > 0) {
      setDiscountTypes(convertArrayToObject(rakutenDiscountTypes, "code"));
      setMemberLevels(convertArrayToObject(rakutenMemberLevels, "code"));
      setItemTypes(convertArrayToObject(rakutenItemTypes, "code"));
      setCouponStatuses(convertArrayToObject(rakutenCouponStatuses, "code"));
    }
  }, [rakutenDiscountTypes]);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query, type: type });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.coupon"></ManualLink>} title={translate("rakuten.coupon", "Rakuten coupon")} extra={[
        disabled && (<Button key="addButton" type="primary" onClick={() => history.push("/rakuten/coupon/new")} ><PlusOutlined /> {translate("coupon.add.button", "Rakuten coupon")} </Button>)
      ]} />
      <ManualDesc translateKey ="manual.desc.coupon" />
      {showAlert && <ManualAlert translateKey={"coupon.api.change.alert"} />}
      <MessageFilter>
        <Space>
          <Input.Group compact>
            <Select onChange={(e) => setType(e)} defaultValue={type}>
              <Option value="couponName" > {translate("coupon.name", "Coupon name")}</Option>
              <Option value="couponCode">{translate("coupon.code", "Coupon code")}</Option>
              <Option value="startDate">{translate("coupon.startdate", "Start date")}</Option>
            </Select>
            {((() => {
              if (type === "startDate") {
                return (
                  <DatePicker
                    locale={lang === "JP" ? locale : null}
                    style={{ width: 250 }}
                    onChange={(e) => { setQuery(e); }}
                    picker="month" />
                );
              }

              return (
                <Input
                  name="query"
                  placeholder={translate("system.search.button", "Search...")}
                  onChange={e => setQuery(e.target.value)}
                  style={{ width: 250 }}
                  prefix={<SearchOutlined />}
                />
              );

            }))()}
          </Input.Group>
        </Space>
      </MessageFilter>
      <PageContent>
        {discountTypes !== null && memberLevels != null && <CouponTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          customPagination={true}
          loadData={couponApi.list}
          bordered
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>{translate("system.number", "No")}</th>
                <th className="ant-table-cell" rowSpan={2}>{translate("coupon.image", "Image")}</th>
                <th className="ant-table-cell" rowSpan={2}>{translate("coupon.status", "Status")}</th>
                <th className="ant-table-cell" colSpan={3}>{translate("coupon.name", "Coupon name")}</th>
                <th className="ant-table-cell" >{translate("coupon.members", "Target members")} </th>

                <th className="ant-table-cell" > {translate("coupon.url", "Acquisition URL")} </th>
                <th className="ant-table-cell" > {translate("coupon.user", "Number of users")} </th>
                {disabled && (<th className="ant-table-cell" rowSpan={2}> {translate("system.action", "Action")}</th>)}
              </tr>
              <tr>
                <th className="ant-table-cell" >{translate("coupon.startdate", "Start date")}</th>
                <th className="ant-table-cell" >{translate("coupon.enddate", "End date")}</th>
                <th className="ant-table-cell" >{translate("coupon.discount.type", "Discount type")}</th>
                <th className="ant-table-cell" >{translate("coupon.product", "Target product")}</th>
                <th className="ant-table-cell" >{translate("coupon.code", "Coupon code")} </th>
                <th className="ant-table-cell" >{translate("coupon.acquisition", "Number of acquisitions")} </th>
              </tr>
            </thead>
          )} tbody={(row, index) => (
            <tbody key={index} className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" rowSpan={2}>{row.key}</td>
                <td className="ant-table-cell" rowSpan={2}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Avatar
                      size={100}
                      shape="square"
                      src={<Image src={row.couponImage} />}
                    />
                  </div>
                </td>
                {/*  */}
                <td className="ant-table-cell" rowSpan={2}>{
                  <div>
                    {
                      renderStatus(row.couponStatus, couponStatuses[row.couponStatus][lang.toLowerCase()])
                    }
                    {row?.autoIssue?.couponStatus === "NEW" && row?.autoIssue?.type === "CUSTOM" && <Tag color="cyan">{translate("coupon.autoissue.new.custom", "Auto issue")}</Tag> }
                    {row?.autoIssue?.couponStatus === "NEW" && row?.autoIssue?.type === "BASIC" && <Tag color="cyan">{translate("coupon.autoissue.new.basic", "Auto issue")}</Tag>}
                    {row?.autoIssue?.couponStatus === "HOLD" && row?.autoIssue?.type === "CUSTOM" && <Tag color="cyan">{translate("coupon.autoissue.hold.custom", "Auto issue")}</Tag> }
                    { row?.autoIssue?.couponStatus === "HOLD" && row?.autoIssue?.type === "BASIC" && <Tag color="cyan">{translate("coupon.autoissue.hold.basic", "Auto issue")}</Tag>}
                    { row?.autoIssue?.couponStatus === "DONE" && row?.autoIssue?.type === "CUSTOM" && <Tag color="orange">{translate("coupon.autoissue.done.custom", "Auto issue done")}</Tag> }
                    { row?.autoIssue?.couponStatus === "DONE" && row?.autoIssue?.type === "BASIC" && <Tag color="orange">{translate("coupon.autoissue.done.basic", "Auto issue done")}</Tag>}
                    { row?.autoIssue?.couponStatus === "FAILED" && row?.autoIssue?.type === "CUSTOM" && <Tag color="red">{translate("coupon.autoissue.failed.custom", "Auto issue failed")}</Tag>}
                    { row?.autoIssue?.couponStatus === "FAILED" && row?.autoIssue?.type === "BASIC" && <Tag color="red">{translate("coupon.autoissue.failed.basic", "Auto issue failed")}</Tag>}
                    { row?.autoIssue?.couponStatus === "TAG_REMOVED" && row?.autoIssue?.type === "CUSTOM" && <Tag color="orange">{translate("coupon.autoissue.tagremoved.custom", "Auto issue done")}</Tag> }
                    { row?.autoIssue?.couponStatus === "TAG_REMOVED" && row?.autoIssue?.type === "BASIC" && <Tag color="orange">{translate("coupon.autoissue.tagremoved.basic", "Auto issue done")}</Tag>}
                  </div>
                }</td>

                <td className="ant-table-cell" colSpan={3}>{row.couponName}</td>
                <td className="ant-table-cell" >{
                  row.multiRankCond.map(item => {
                    if (item > 0) {
                      return (
                        <Tag style={{ margin: "5px" }} color="green">{memberLevels[item][lang.toLowerCase()]}</Tag>
                      );
                    }
                    return (
                      <span>-</span>
                    );
                  })}
                </td>
                <td className="ant-table-cell" >{<LinkCopy onClick={() => onCopy(row.pcGetUrl)}>{row.pcGetUrl} <div className="copy"><CopyOutlined /></div> </LinkCopy>}</td>
                <td className="ant-table-cell text-right" >{row.availCount}</td>
                {disabled && (<td className="ant-table-cell" rowSpan={2}>
                  <RowAction actions={dangerDateCheck(row)} onClick={(key) => onAction(key, row)} />
                </td>)}
              </tr>
              <tr>
                <td className="ant-table-cell">{moment(row.couponStartDate).format("YYYY-MM-DD HH:mm")}</td>
                <td className="ant-table-cell">
                  <WarrningEndDate>
                    <span className={moment(row.couponEndDate) > moment(new Date()).add(7, "days") ? "" : "warning"}>{moment(row.couponEndDate).format("YYYY-MM-DD HH:mm")}</span>
                  </WarrningEndDate></td>
                <td className="ant-table-cell" > <div style={{ display: "flex" }}>
                  {((() => {
                    if (row.discountType === 1) {
                      return (<div>{row.discountFactor}&nbsp; 円OFF</div>);
                    }
                    if (row.discountType === 2) {
                      return (<div>{row.discountFactor}&nbsp; ％OFF</div>);
                    }
                    return "送料無料";
                  }))()}</div>
                </td>
                <td className="ant-table-cell" >{
                  itemTypes[row.itemType][lang.toLowerCase()]
                }</td>
                <td className="ant-table-cell">{row.couponCode}</td>
                <td className="ant-table-cell text-right">{row.getCount}</td>
              </tr>
            </tbody>
          )} />}
        <Modal
          visible={action[0] === "autoissue"}
          maskClosable ={false}
          width="750px"
          title={ translate("coupon.auto", "Auto issue settings") }
          onCancel={() => setAction([])}
          destroyOnClose
          footer={false}
        >
          <AutoFormList
            initialValues={action[1]}
            onCancel={() => setAction([])}
            onSubmit={(e) => {
              console.log("autissuemodeal_data", e);
              onAutoIssue(e);
            }}/>
        </Modal>
      </PageContent>
    </PageContainer>
  );
};

const LinkCopy = styled.div`
  display:flex;
  font-size : 11px;
  .copy {
    margin-left: 10px;
    font-size : 14px;
    cursor: pointer;
  }
`;

const WarrningEndDate = styled.div`
  .warning {
    color : red;
  }
`;
const CouponTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 8px!important;
  }
`;

const MessageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;
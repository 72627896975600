/* eslint-disable no-nested-ternary */
import React from "react";
import styled from "styled-components";
import Thumbnail from "./ImagemapThumbnail";

export default ({ values }) => {
  return (
    <Container>
      <Uploaded>
        <Thumbnail width={275} values={{ ...values }} />
      </Uploaded>
    </Container>
  );
};

const Uploaded = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  .close {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -22px;
    background: #6f6f6f;
    color: #fff;
    width: 22px;
    height: 20px;
    text-align: center;
    font-weight: 400;
    transition: all .2s;
    &:hover {
      background: #333;
    }
  }
`;
const Container = styled.div`
  .ant-upload {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
    color: #9a9a9a;
  }
`;
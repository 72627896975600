import request from "../utils/request";

export const create = (data) => request.post("/api/customer", data);
export const update = (data) => request.put(`/api/customer/${data._id}`, data);
export const get = (id) => request.get(`/api/customer/${id}`);
export const list = (data) => request.post("/api/customer", data);
export const change = (id) => request.post(`/api/customer/change/${id}`);
export const logout = () => request.get("/api/customer/logout");
export const plans = (data) => request.get("/api/customer/plans", data);
// export const remove = (data) => request.post(`/api/customer/${data.id}/remove`, data);
export const remove = (data) => request.post("/api/customer/remove", data);
export const disableConfirm = (data) => request.get("/api/customer/disable/confirm");
export const disable = (data) => request.post("/api/customer/disable", data);

export const ecmaccesskey = (data) => request.put(`/api/customer/ecmchange/${data.id}`, data);

export const paymentUnblock = (id) => request.post(`/api/customer/${id}/autoPaymentFailed/password`);

export const exportFile = (data) => request.post("/api/customer/file/export", data);
export const ecm = (id) => request.get(`/api/customer/accounts/${id}`);
export const ecmEdit = (data) => request.put(`/api/customer/ecm/${data.customerData.id}`, data);

import React from "react";
import { Field } from "formik";
import { FormItem } from "formik-antd";
import { Modal, Spin } from "antd";
import { FolderOpenOutlined, CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../../colors";
import { useTranslate } from "../../../hooks";
import List from "../../../pages/Carousel/List";
import { carousel as carouselApi } from "../../../apis";

export default ({ index, name, values, type }) => {
  const { translate } = useTranslate();
  const [action, setAction] = React.useState(false);
  const [carousel, setCarousel] = React.useState(values);

  React.useEffect(()=> {
    setCarousel(values);
  }, [values]);


  if (type === "detail"){
    return (
      <Spin spinning={false}>
        <CooperationContainer>
          {carousel && carousel ? (
            <Uploaded>
              <Content>{carousel.altText}</Content>
              <a className="close" href="#" ><CloseOutlined /></a>
            </Uploaded>
          ) : (
            <SelectContainer onClick={() => setAction(true)}>
              <FolderOpenOutlined />
              <div className="upload-text">{translate("carousel.select", "Select carousel")}</div>
            </SelectContainer>
          )}
        </CooperationContainer>
      </Spin>
    );
  }
  return (
    <FormItem name={name}>
      <Field name={name}>
        {fieldProps => {
          const {
            form: { setFieldValue }
          } = fieldProps;

          const onSelect = async (value) => {
            console.log("value =>", value);
            setFieldValue(name, value._id);
            let res = await carouselApi.get(value._id);
            setFieldValue(`children[${index}].carouselValues`, res);
            setCarousel(value);
            setAction(null);
          };

          const onRemove = () => {
            setFieldValue(name, undefined);
            setFieldValue(`children[${index}].carouselValues`, undefined);
            setCarousel(null);
            setAction(null);
          };

          return (
            <Spin spinning={false}>
              <CooperationContainer>
                {carousel && carousel ? (
                  <Uploaded>
                    <Content>{carousel.altText}</Content>
                    <a className="close" href="#" onClick={() => onRemove({ setFieldValue })}><CloseOutlined /></a>
                  </Uploaded>
                ) : (
                  <SelectContainer onClick={() => setAction(true)}>
                    <FolderOpenOutlined />
                    <div className="upload-text">{translate("carousel.select", "Select carousel")}</div>
                  </SelectContainer>
                )}

                <Modal
                  maskClosable={false}
                  visible={action}
                  className="cooperation-modal"
                  title={translate("carousel.select", "Select cardtype message")}
                  onCancel={() => setAction(false)}
                  width={800}
                  footer={null}>
                  <List onSelect={onSelect} />
                </Modal>
              </CooperationContainer>
            </Spin>
          );
        }}
      </Field>
    </FormItem>
  );
};

const Content = styled.div`
`;

const Uploaded = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 97%;
  .close {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -22px;
    background: #6f6f6f;
    color: #fff;
    width: 22px;
    height: 20px;
    text-align: center;
    font-weight: 400;
    transition: all .2s;
    &:hover {
      background: #333;
    }
  }
`;
const CooperationContainer = styled.div`
  .ant-upload {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
    color: #9a9a9a;
  }
`;
const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 25px;
  border: 1px dashed #ddd;
  min-height: 160px;
  cursor: pointer;
  border-radius: 4px;
  .anticon {
    font-size: 22px;
    color: #607D8B;
  }
  &:hover {
    .upload-text {
      color: ${colors.primaryDark};
    }
  }
  .upload-text {
    font-size: 13px;
    margin-top: 10px;
    transition: all .2s;
    font-weight: 400;
  }
`;

import React from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Button, Alert } from "antd";
import * as Yup from "yup";
import { useTranslate } from "../../hooks";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import styled from "styled-components";

const FormSchema = (translate) => Yup.object().shape({
  password: Yup.string().required(translate("system.message.required", "Please fill out this field")),
});

export default ({ action, onSubmit, visible }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    password: undefined
  });
  return (
    <FormStyle>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form {...formItemLayout}>
            <Alert className="alert" message={translate("system.message.delete", "Deleted account can't be recovered")} type="warning" />
            <FormItem label={translate("useredit.password.input", "Password")} name="password" required>
              <Input name="password" type="password" placeholder={translate("useredit.password.input", "Password")} />
            </FormItem>

            <AntForm.Item {...tailFormItemLayout}>
              <Button danger className="button" htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.delete", "Delete")}</Button>
              <Button className="button" onClick={() => visible(false)} type="defualt" block>{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </FormStyle>
  );
};

const FormStyle = styled.div`
  .alert {
     margin-bottom: 20px;
  }
  .button {
    width: 100px;
    margin-right: 10px
  }
`;
import React from "react";
import {
  PageHeader,
  PageContent,
  PageContainer,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import { webhook, account as accountApi } from "../../apis";
import {
  Alert,
  Badge as AntBadge,
  DatePicker,
  Empty,
  Input,
  message,
  Space,
  Switch,
} from "antd";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { usePermission, useTranslate } from "../../hooks";
import Form from "./Component/Form";
import Modal from "antd/lib/modal/Modal";
import confirm from "antd/lib/modal/confirm";
import { useSelector } from "react-redux";
import { ManualDesc, ManualLink } from "../../components/Manual";
const { RangePicker } = DatePicker;
export default () => {
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const { checkRole } = usePermission();
  const history = useHistory();
  const [action, setAction] = React.useState();
  const [query, setQuery] = React.useState("");
  const [disabled, setDisabled] = React.useState(
    checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) &&
      checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission([
        "ADMIN",
        "STAFF",
        "NO_REPORT",
      ])
  );
  const { account } = useSelector((state) => state.general);
  const [replyToken, setReplyToken] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const [filters, setFilters] = React.useState({ query: "" });
  const columns = useHeader({
    history,
    tableTitles: {
      no    : translate("system.number", "No."),
      email : translate("webhook.email", "Email"),
      uri   : translate("webhook.url", "URL"),
      action: translate("system.action", "Action"),
      detail: translate("system.detail", "Details"),
      edit  : translate("system.edit", "Edit"),
      remove: translate("system.delete", "Remove"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          let res = await webhook.get(item._id);
          setAction(["update", res]);
          break;
        }
        case "remove": {
          confirm({
            icon   : <ExclamationCircleOutlined />,
            content: translate(
              "system.confirmation.question",
              "Are you sure ?"
            ),
            okText       : translate("system.yes", "Yes"),
            cancelText   : translate("system.no", "No"),
            okButtonProps: {
              style: {
                backgroundColor: colors.primaryDark,
                borderColor    : "white",
              },
            },
            cancelButtonProps: {
              style: { borderColor: colors.primaryDark, color: "black" },
            },
            className: "btn-custom-class",
            async onOk() {
              await webhook.remove(item._id);
              message.success(
                translate("system.message.success", "Successful")
              );
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
          break;
      }
    },
    translate,
    disabled,
  });
  const onSubmit = async (data) => {
    if (!action || (action && action[0] === "create"))
      try {
        await webhook.create(data);
        message.success(translate("system.message.success", "Successful"));
      } catch (error) {
        if (error.message === "DENIED_WEBHOOK_URL") {
          message.error(
            translate(
              "webhook.url.invalid.yobi",
              "You can't use Webhook generated by Yobi-com here"
            )
          );
        } else message.error(error.message);
      }
    else
      try {
        await webhook.update(data);
        message.success(translate("system.message.success", "Successful"));
      } catch (error) {
        if (error.message === "DENIED_WEBHOOK_URL") {
          message.error(
            translate(
              "webhook.url.invalid.yobi",
              "You can't use Webhook generated by Yobi-com here"
            )
          );
        } else message.error(error.message);
      }
    myTableRef.current.reload();
    onCancel(true);
  };
  const onCancel = () => {
    setAction(null);
  };
  const onChange = async () => {
    setLoading(true);
    let res = await webhook.replytoken();
    setReplyToken(res.replyToken);
    setLoading(false);
  };
  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query) setFilters({ ...filters, query });
    }, 300);
    return () => clearTimeout(timer);
  }, [query]);

  React.useEffect(() => {
    const get = async () => {
      let res = await accountApi.get(account._id);
      setReplyToken(res.useReplyToken !== false);
    };
    get();
  }, []);

  return (
    <Container>
      <PageContainer>
        <PageHeader
          subTitle={<ManualLink link="manual.link.webhook"></ManualLink>}
          title={translate("webhook.navbar.list", "Webhook")}
          extra={[
            disabled && (
              <Button
                key="createButton"
                type="primary"
                onClick={() => setAction(["create", {}])}
              >
                <PlusOutlined /> {translate("system.add", "New webhook")}
              </Button>
            ),
          ]}
        />
        <ManualDesc translateKey ="manual.desc.webhook" />
        <PageContent>
          { disabled && <Alert
            className="description"
            type="warning"
            message={translate("webhook.switch", "Settings for using reply token")}
            action={
              <Switch
                loading={loading}
                checked={replyToken}
                onChange={() => onChange()}
              />
            }
          />}
          <TableStyle>
            <MyTable
              locale={{
                emptyText: (
                  <Empty
                    description={translate("system.nodata", "No data")}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
              }}
              ref={myTableRef}
              filters={filters}
              rowKey={(record) => record._id}
              columns={columns}
              loadData={webhook.list}
              expandable
            />
          </TableStyle>
        </PageContent>
        <Modal
          maskClosable={false}
          title={
            action && action[0] === "create"
              ? translate("system.add", "Add webhook")
              : translate("system.edit", "Edit webhook")
          }
          visible={!!action}
          onCancel={onCancel}
          destroyOnClose
          footer={false}
        >

          <Form onCancel={onCancel} onSubmit={onSubmit} action={action} />
        </Modal>
      </PageContainer>
    </Container>
  );
};
const useHeader = ({ onAction, tableTitles, disabled }) => {
  return [
    { title: tableTitles.no, dataIndex: "key", width: "20px" },
    { title: tableTitles.email, dataIndex: "email" },
    { title: tableTitles.uri, dataIndex: "uri" },
    disabled
      ? {
        title : tableTitles.action,
        key   : "action",
        width : 100,
        render: (order) => {
          return (
            <RowAction
              actions={{ edit: tableTitles.edit, remove: tableTitles.remove }}
              onClick={(key) => onAction(key, order)}
            />
          );
        },
      }
      : {},
  ];
};
const TableStyle = styled.div`
  .ant-table-row-expand-icon {
    display: none;
  }
`;
const Container = styled.div`
  .copy-btn{
    margin: 1px;
  }
  .description { 
    margin : 10px 0px;
  }
`;
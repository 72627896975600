import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import styled from "styled-components";
import { Button } from "../../components/Design";
import { Modal, message, Table, Empty } from "antd";
import { useTranslate } from "../../hooks";
import colors from "../../colors";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth, customer } from "../../apis";

const { confirm } = Modal;

export default () =>{
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const { customers } = useSelector(state => state.general);

  const onLogin = async (item) => {
    confirm({
      icon             : <ExclamationCircleOutlined />,
      content          : translate("system.confirmation.question", "Are you sure ?"),
      okText           : translate("system.yes", "Yes"),
      cancelText       : translate("system.no", "No"),
      okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
      className        : "btn-custom-class",
      async onOk() {
        try {
          await customer.change(item._id);
          await auth.me()(dispatch);

          message.success(translate("system.message.success", "Success"));
          history.push("/");
        } catch (err) {
          message.error(translate("system.message.error", err.message));
        }
      },
    });
  };

  const columns = useHeader({ translate, onLogin });

  return (
    <PlanWrapper>
      <PageContainer>
        <PageHeader title="Agency"/>
        <PageContent style={{ padding: "20px 10%" }}>
          <div className="current-plan-list" style={{ borderColor: "#CDD1D9", marginTop: "20px" }}>
            <div className="header-plan">
              <h2 style={{ color: "white", marginTop: "10px" }}>Customer list</h2>
            </div>
            <div style={{ margin: "0px 20px" }}>
              <Table locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }} style={{ marginTop: "20px" }} dataSource={customers} columns={columns} />
            </div>
          </div>
        </PageContent>
      </PageContainer>
    </PlanWrapper>

  );
};

const useHeader = ({ translate, onLogin }) => {
  return [{
    dataIndex: "companyName",
    key      : "companyName",
    render   : text => <p>{text}</p>,
  }, {

    dataIndex: "email",
    key      : "email",
    render   : text => <p>{text}</p>,
  }, {
    dataIndex: "followerBalance",
    render   : followerBalance => <p>Follower balance <strong>{followerBalance}</strong></p>,
  }, {
    dataIndex: "messageBalance",
    render   : messageBalance => <p>Message balance <strong>{messageBalance}</strong></p>,
  }, {
    dataIndex: "accountBalance",
    render   : accountBalance => <p>Account balance <strong>{accountBalance}</strong></p>,
  }, {

    key   : "action",
    width : 10,
    render: (text, record) => {
      return <Button onClick={() => onLogin(record)} key="key 1" type="primary">{translate("system.login", "Login")}</Button>;
    }
  }];
};

const PlanWrapper = styled.div`
  .ant-descriptions-item-label {
    width: 70%;
  } 
  .select-plan-header {
    margin-left: -5px;
    font-weight :bold;
    color :#434A54;
    font-weight: 500;
    color: rgb(0 157 75);
    font-size: 1.5em;
  }
  .header-plan {
    border :solid 1px;
    border-color :#CDD1D9;
    height :50px;
    padding-left :20px ;
    background-color :rgb(0 157 75)
  }
  .currentPlan {
    border: solid 1px;
    height: 100px;
    border-radius: 3px;
    border-color: #FAFAFA;
    display: flex;
    flex-direction :column;
  }
  .current-plan-list {
    border: solid 1px;
    border-radius: 3px;
    border-color: #FAFAFA;
    display: flex;
    flex-direction :column;
  }
  .current-items {
    // width: 100px;
    margin: 10px;
    margin-left: 70px;
    margin-right: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item{
    display : flex;
    flex-direction : column;
  }
  .item-header{
  font-size: 23px;
  }
  .item-text {
    font-size: 17px;
    font-family: sans-serif;
  }
  .plan-button {  
    border : solid 1px;
    border-color: #F0F0F0;
    width: 100%;
    height: 47px;
    border-radius: 4px;
    font-size: 19px;
    color : black;
    display : flex;
    justify-content: center;
    align-items: center;
  }
  .plan-wrapper-title {
    padding-bottom : 15px;
  }
  .wrapper-title { 
    color : red;
    font-size: 16px;
    font-weight: 400;
  }
  .plan-card-item-wrapper {
  }
  .plan-card {
    margin-top: 50px;
  }
  .plan-card-title {
    font-size: 16px;
    font-weight: 400;
    padding-bottom : 20px;
  }
  .plan-card-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 1px;
    width: 238px;
    height: 386px;
    border-radius: 10px;
    border-color: #F0F0F0;
  }
  .card-item-title {
    display : flex;
    justify-content: center;
  }
  .text-wrapper{
    display : flex;
    flex-direction : column;
  }
  .sub-title {
    margin :20px;
    font-feature-settings: "palt";
    word-break: break-word;
    line-height: 1.6;
    font-size: 1em;
  }
`;
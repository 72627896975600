import React from "react";
import { rakuten } from "../../apis";
import { Space, Empty, Select, DatePicker } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import moment from "moment";

const { Option } = Select;
export default (props) => {
  const { RangePicker } = DatePicker;
  const { translate } = useTranslate();
  const params = useParams();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    query   : "",
    follower: params.id
  });


  const changeRange = (values) => {
    setFilters({ ...filters, startDate: values && moment(new Date(values[0])).startOf("day").toDate(), endDate: values && moment(new Date(values[1])).endOf("day").toDate() });
    setRangePicker({ startDate: values && values[0], endDate: values && values[1] });
  };
  const columns = useHeader({
    history,
    translate,
    tableTitles: {
      no              : translate("system.number", "No."),
      reviewtype      : translate("rakuten.review.type", "Review type"),
      productName     : translate("rakuten.review.product.name", "Product name"),
      reviewDetailsURL: translate("rakuten.review.url", "Review details URL"),
      evaluation      : translate("rakuten.review.score", "Evaluation"),
      postedTime      : translate("rakuten.review.date", "Posted time"),
      title           : translate("rakuten.review.title", "Title"),
      review          : translate("rakuten.review.text", "Review"),
      flag            : translate("rakuten.review.flag", "Flag"),
      orderNo         : translate("rakuten.review.orderNo", "Order No"),
      notAnsweredFlag : translate("rakuten.review.not.answered", "Not answered Flag"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "orders": {
          history.push({
            pathname: `/products/rakuten/${item._id}/orders`,
            state   : { id: item._id },
          });
          break;
        }
        default:
      }
    },
  });

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader onBack={()=> history.goBack()} title={translate("rakuten.review", "Rakuten reviews")} />

      <PageFilter>
        <Space>

          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />

          <Select
            style={{ width: 250 }}
            className="ant-input-custom"
            placeholder={translate("review.filter.all", "All")}
            onChange={(e)=>{
              setFilters({ ...filters, type: e });
            }}
          >
            <Option value="">{translate("review.filter.all", "All")}</Option>
            <Option value="SHOP">
              {translate("review.filter.shop", "Shop")}
            </Option>
            <Option value="PRODUCT">
              {translate("review.filter.product", "Product")}
            </Option>
          </Select>

          <RangePicker value={[rangePicker.startDate, rangePicker.endDate]} onChange={values => changeRange(values) } />


        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={rakuten.reviews}
        />
      </PageContent>

    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, translate }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px"
  }, {
    title : tableTitles.reviewtype,
    render: (row)=> {
      if (row.type === "SHOP"){
        return (
          <div>{translate("review.filter.shop", "Shop")}</div>
        );
      }
      return translate("review.filter.product", "Product");
    },
  }, {
    title : tableTitles.productName,
    render: (item)=>{
      if (item.productName){
        return item.productName;
      }
      return (<div>-</div>);
    }
  }, {
    title : tableTitles.reviewDetailsURL,
    render: (row)=> {
      return (
        <a
          target="blank"
          style={{ fontSize: "11px" }}
          href={row.url}>{row.url}</a>
      );
    }
  }, {
    title    : tableTitles.evaluation,
    dataIndex: "rate"
  }, {
    title : tableTitles.postedTime,
    render: (row)=> {
      return (
        <div>{moment(row.date).format("YYYY-MM-DD HH:mm")}</div>
      );
    }
  }, {
    title : tableTitles.title,
    render: (row)=> {
      if (row.title){
        return (
          <div>{row.title}</div>
        );
      }
      return (<div>-</div>);
    }
  }, {
    title    : tableTitles.review,
    dataIndex: "review"
  }, {
    title    : tableTitles.orderNo,
    dataIndex: "orderNo"
  }, {
    title    : tableTitles.flag,
    dataIndex: "flag"
  }, {
    title : tableTitles.notAnsweredFlag,
    render: (row)=> {
      if (row.notAnsweredFlag){
        return (
          <div>{row.notAnsweredFlag}</div>
        );
      }
      return (<div>-</div>);
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

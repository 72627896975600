/*
 * @Author: Nj Nj
 * @Email: njnj631@gmail.com
 * @Date: 2021-01-23 17:14:43
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-01-23 18:46:03
 * @Description: GEt followers loading visible
 */

import React from "react";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
// import useSound from "use-sound";
// import notificationMp3 from "../../assets/notification.mp3";

export let socket;

export default () => {
  // const { notifications } = useSelector(state => state.socket);
  const dispatch = useDispatch();
  // const [playNotification] = useSound(notificationMp3, { volume: 1 });

  React.useEffect(() => {
    socket = io();

    socket.on("connection", (data) => {});
    // dispatch({ type: "socket.getfollower.sync", payload: true })
    socket.on("action", (action) => {

      console.log("* * * * * * * * * * *action * * * * * * * * * *");
      console.log(action);
      console.log("* * * * * * * * * * * * * * * * * * * * *");

      dispatch(action);

      if (action.type === "socket.file.export") {
        if (action.payload.uri === "") {
          dispatch({ type: "socket.file.export", payload: action.payload.fileExportStatus });
        }
        else {
          window.open(`${action.payload.uri}`, "_blank");
          dispatch({ type: "socket.file.export", payload: action.payload.fileExportStatus });
        }
      }
      if (action.type === "socket.balance.notfication"){
        dispatch({ type: "balance.notfication", payload: true });
      }

      if (action.type === "socket.conversation") {
        dispatch({ type: "socket.conversation.status", payload: action.payload });
      }
    });
  }, []);

  // React.useEffect(() => {
  //   if (notifications && notifications.length > 0)
  //     playNotification();
  // }, [notifications]);

  return (<></>);
};

import React from "react";
import { Modal, Row, Col, message } from "antd";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import RichTemplate, { TemplateMenu } from "../../components/RichMenuTemplate";
import Form from "./components/Form";
import UploadForm from "./components/UploadForm";
import { Button } from "../../components/Design";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import { RichmenuPreview } from "../../components";
import { tags } from "../../apis";

export default React.forwardRef((props, ref) => {
  const { editable, onSubmit } = props;
  const { translate } = useTranslate();
  const { richMenuTemplates, richmenuSelect } = useSelector(state => state.general);
  const { collapse } = useSelector(state => state.message);
  const [action, setAction] = React.useState([false, null, null]);
  const [openCollapse, setOpenCollapse] = React.useState();
  const formRef = React.useRef();
  const [errors, setErrors] = React.useState({});
  const dispatch = useDispatch();
  useFetch(tags.select(dispatch))();

  const [data, setFormData] = React.useState({
    type    : "large",
    template: "A",
    image   : undefined,
    // ...(editable && editable[0] === "update"  ? editable[1]: {})
    ...(editable &&(editable[0] === "update" || editable[0] === "copy") ? editable[1]: {})
  });

  const onUploaded = ({ url }) => {
    setFormData({ ...data, image: url });
    dispatch({ type: "general.richmenuSelect", payload: { ...data, image: url } });
    setErrors({});
    onCancel();
  };

  const onSelect = async () => {
    await formRef.current.clear();

    setFormData({
      ...data,
      type    : action[1],
      template: action[2],
      image   : null
    });

    dispatch({ type   : "general.richmenuSelect", payload: {
      ...data,
      type    : action[1],
      template: action[2],
      image   : null
    } });

    onCancel();
  };

  const onSelectRichGrid = (name) => {

    setOpenCollapse(name);
  };

  const onCancel = () => {
    setAction([null]);
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      if (!data.image) {
        setErrors({ image: translate("system.image.required", "Please upload an image!") });
        return false;
      }

      let valid = await formRef.current.validate();
      // if (!valid) message.error(translate("system.error.required", "Insert necessary fields first!"));

      if (!valid && richMenuTemplates[data.type][data.template]){
        setOpenCollapse(richMenuTemplates[data.type][data.template].map(key => key));
        return valid;
      }

      return data.image && {
        ...data,
        ...valid,
        template: data?.template,
        type    : data?.type,
        image   : data?.image
      };
    }
  }));

  React.useEffect(() => {

    if (collapse[0] === "message") {
      dispatch({ type: "message.preview", payload: true });
    }

    dispatch({ type: "general.richmenuSelect.update", payload: { chatBar: data.chatBarText, template: { image: data.image } } });
  }, []);

  return (
    <RichWrapper>
      <Row gutter={[8, 8]}>
        <Col span={10}>
          <div className="template">
            <div className="rich">
              <RichmenuPreview>
                <RichTemplate width={370} height={data.type === "large" ? 247 : 123} type={data.type} template={data.template} onSelect={onSelectRichGrid} image={data.image} errors={errors} />
              </RichmenuPreview>
            </div>

            <Button type="default" style={{ marginBottom: 10, width: 370 }} onClick={() => setAction(["template", data.type, data.template])} block>{translate("richmenu.layout", "Select template")}</Button>
            <Button type="primary" style={{ width: 370 }} onClick={() => setAction(["image", data.image])} block>{translate("richmenu.upload", "Upload Image")}</Button>
            {errors.image && <div className="error">{errors.image}</div>}
          </div>
        </Col>
        <Col span={14}>
          <div className="collapse">
            {richMenuTemplates[data.type][data.template] && richMenuTemplates[data.type][data.template].length > 0 && <Form type={data.type} template={data.template} editable={editable} openCollapse={openCollapse} ref={formRef} onSubmit={onSubmit} />}
          </div>
        </Col>
      </Row>

      <Modal
        maskClosable={false}
        title={translate("richmenu.layout", "Select a template")}
        visible={action && action[0] === "template"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <h3>{translate("richmenu.layout.large", "Large")}</h3>
        {Object.keys(richMenuTemplates.large).map(template => {
          return <TemplateMenu key={template} width={100} style={{ marginRight: 10, marginBottom: 20 }} type="large" active={action[1] === "large" && action[2] === template} template={template} onSelect={() => setAction(["template", "large", template])} />;
        })}
        <h3>{translate("richmenu.layout.compact", "Compact")}</h3>
        {Object.keys(richMenuTemplates.compact).map(template => {
          return <TemplateMenu key={template} width={100} style={{ marginRight: 10, marginBottom: 20 }} type="compact" active={action[1] === "compact" && action[2] === template} template={template} onSelect={() => setAction(["template", "compact", template])} />;
        })}
        <TemplateFooter>
          <Button type="default" onClick={() => setAction([null])} style={{ marginRight: 10 }}>{translate("system.cancel", "Cancel")}</Button>
          <Button type="primary" onClick={() => onSelect()}>{translate("richmenu.layout.apply", "Apply")}</Button>
        </TemplateFooter>
      </Modal>

      <Modal
        maskClosable={false}
        title={translate("richmenu.upload", "Upload your menu image")}
        visible={action && action[0] === "image"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <UploadForm action="/api/richmenu/upload" type={data.type} onCancel={onCancel} onUpload={onUploaded} />
      </Modal>
    </RichWrapper>
  );
});

export const TemplateFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const RichWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 20px 0;
  .error {
    color: red;
    font-size: 13px;
    font-weight: 500;
    width: 240px;
  } 
  .template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .rich {
      margin-bottom: 20px;
    }
  }
  
  .collapse {
    margin-left: 30px;
    width: 600px;
  }
`;

import React, { useState } from "react";
import { Button, Upload, message, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslate } from "../../../hooks";

const { Dragger } = Upload;
const validates = [
  { w: 2500, h: 1686 },
  { w: 2500, h: 843 },
  { w: 1200, h: 810 },
  { w: 1200, h: 405 },
  { w: 800, h: 540 },
  { w: 800, h: 270 },
  { w: 945, h: 632 },
];

export default (props) => {
  const { translate } = useTranslate();
  const { onCancel, action, onUpload, type } = props;
  const [isLoading, setIsLoading] = useState(false);

  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const isLt2M = file.size / 1024 / 1024 < 1;
    let isValidSize = false;

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => {
        setIsLoading(true);
        if (validates.find(v => (v.w === image.width && v.h === image.height)))
          isValidSize = true;

        if (!isValidSize)
        {
          message.error(translate("richmenu.invalid.image", "Invalid image size!"));
          setIsLoading(false);
        }
        else if (!isLt2M){
          message.error(translate("richmenu.error.size", "Image must smaller than 1MB!"));
          setIsLoading(false);
        }
        else if (!isJpgOrPng){
          message.error(translate("richmenu.error.fileformat", "You can only upload JPG/PNG file!"));
          setIsLoading(false);
        }

        if (isValidSize && isJpgOrPng && isLt2M)
          resolve(true);
        else
          reject(new Error("Invalid"));
      });

      image.src = URL.createObjectURL(file);
    });
  };

  const options = {
    name          : "file",
    action        : action,
    beforeUpload  : beforeUpload,
    showUploadList: false,
    onChange      : (info) => {
      const { status } = info.file;

      if (status === "done") {
        message.success(`${info.file.name} ${translate("system.message.success.upload", "file uploaded successfully.")}`);

        if (onUpload)
          onUpload(info.file.response);

        setIsLoading(false);
      } else if (status === "error") {
        message.error(`${info.file.name} ${translate("system.message.failed.upload", "file upload failed.")}`);
      }
    },
  };

  return (
    <UploadWrapper>
      <Spin spinning={isLoading}>
        <Dragger {...options}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {translate(
              "richmenu.upload.text",
              "Click or drag file to this area to upload"
            )}
          </p>
          <div className="ant-upload-hint">
            <div>
              {translate("richmenu.fileformat", "File formats: JPG, JPEG, PNG")}
            </div>
            <div>{translate("richmenu.filesize", "File size: Up to 1 MB")}</div>
            <div>{translate("richmenu.imagesize", "Image Sizes")}</div>
            {type === "compact" ? "2500x843, 1200x405, 800x270" : "2500x1686, 1200x810, 800x540"}
          </div>
        </Dragger>
      </Spin>

      <UploadFooter>
        <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>
          {translate("system.cancel", "Cancel")}
        </Button>
      </UploadFooter>
    </UploadWrapper>
  );
};

const UploadFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const UploadWrapper = styled.div`
  .ant-upload-text {
    margin-bottom: 20px;
  }
  .ant-upload-hint {
    font-size: 12px!important;
  }
`;
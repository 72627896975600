import React from "react";
import styled from "styled-components";
import { MyTable, Sticker, RowAction } from "../../components";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { operation as operationApi } from "../../apis";
import { DatePicker, Space, Empty } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import moment from "moment";
import Avatar from "antd/lib/avatar/avatar";
import NoImage from "../../assets/no_image.png";
import { ManualAlert, ManualDesc, ManualLink } from "../../components/Manual";

const { RangePicker } = DatePicker;

export default () => {

  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    query    : "",
    startDate: undefined,
    endDate  : undefined
  });

  const tableTitles = {
    no         : translate("system.number", "No."),
    title      : translate("multicast.list.title", "Title"),
    messageData: translate("multicast.list.message", "Message data"),
    sendDate   : translate("multicast.list.sent", "Sent date"),
    delivered  : translate("multicast.list.delivered", "Delivered"),
    openCount  : translate("multicast.list.open", "Open count"),
    clickCount : translate("multicast.list.click", "Click count"),
    action     : translate("system.action", "Action"),
    detail     : translate("system.detail", "Detail")
  };

  const onAction = (key, item) => {
    console.log("=>", item);
    switch (key) {
      case "detail": {
        history.push(`/operation/multicast_report/${item._id}`);
        break;
      }
      default:
    }
  };

  let [columns, setColumns] = React.useState(useHeader({
    history,
    tableTitles,
    onAction
  }));

  return (
    <PageContainer>
      <Container>
        <PageHeader subTitle={<ManualLink link="manual.link.multicastreport"></ManualLink>} title={translate("multicast.list.title.page", "Multicast report")} />
        <ManualDesc translateKey ="manual.desc.multicastreport" />
        <ManualAlert translateKey={"manual.alert.multicastreport"} />
        <PageContent>
          <FilterContainer>
            <Space direction="vertical" size="large">
              <RangePicker onChange={(e)=>{
                setFilters({
                  ...filters,
                  startDate: e?moment(new Date(e[0])).startOf("day"):undefined,
                  endDate  : e?moment(new Date(e[1])).endOf("day"):undefined
                });
              }} />

            </Space>
          </FilterContainer>
          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            ref={myTableRef}
            filters={filters}
            rowKey={(record) => record._id}
            columns={columns}
            loadData={operationApi.multicast}
          />
        </PageContent>
      </Container>
    </PageContainer>
  );
};

const FilterContainer = styled.div`
    margin:10px 0px;
    .ant-space {
      width :100%;
    }
`;

const Container = styled.div`
  .ant-table-row-expand-icon-collapsed{
    display:none;
  }
`;

const checkMessages = (messages) => {
  let message ={};
  if (messages) {
    messages.forEach(item => {
      if (item?.previewImage) {
        message = item;
      }
      if (item?.type === "sticker") {
        message = item;
      }
    });
    return message;
  }
  return {};

};

const useHeader = ({ tableTitles, onAction }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title    : tableTitles.title,
    dataIndex: "title",
  }, {
    title : tableTitles.messageData,
    render: (row) => {
      if (row.messageData.length > 0) {
        return (
          <div>
            {checkMessages(row.messageData).type === "sticker"?(
              <Sticker
                size="small"
                packageId={checkMessages(row.messageData)?.packageId}
                id={checkMessages(row.messageData)?.stickerId}
              />
            ):(
              <Avatar shape="square" size={80} src={checkMessages(row.messageData)?.previewImage?checkMessages(row.messageData)?.previewImage:NoImage} />
            )}
          </div>
        );
      }
      return (<div>-</div>);

    },
  }, {
    title : tableTitles.sendDate,
    render: (row) => {
      return (moment(row.sendDate).format("YYYY-MM-DD HH:mm"));
    }
  }, {
    title : tableTitles.delivered,
    render: (row)=> {
      if (row.total){
        return (<div>{row.total}</div>);
      }
      return (<div>-</div>);
    }
  }, {
    title : tableTitles.openCount,
    render: (row)=> {
      if (row.openCount){
        return (<div>{row.openCount}</div>);
      }
      return (<div>-</div>);
    }
  }, {
    title : tableTitles.clickCount,
    render: (row)=> {
      if (row.clickCount){
        return (<div>{row.clickCount}</div>);
      }
      return (<div>-</div>);
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (order) => {
      return <RowAction actions={{
        detail: tableTitles.detail,
      }} onClick={(key) => onAction(key, order)} />;
    }
  }];
};
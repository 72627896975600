import styled from "styled-components";
import { Checkbox } from "antd";
import colors from "../../colors";

export default styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: green;
    border-color: green;
  }
  .ant-checkbox-inner {
    border-color: green;
  }
`;
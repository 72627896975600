import React from "react";
import colors from "../../colors";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useTranslate } from "../../hooks";
import { staff as staffApi } from "../../apis";
import { Space, message, Empty, Modal, Button } from "antd";
import { MyTable, RowAction } from "../../components";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ManualDesc from "../../components/Manual/ManualDesc";
import { ManualAlert, ManualLink } from "../../components/Manual";



export default ()=> {
  const { confirm } = Modal;
  const history = useHistory();
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const { lang, permissions } = useSelector((state)=> state.general);
  const [permission, setPermission] = React.useState(null);
  const [action, setAction] = React.useState();
  const [filters, setFilters] = React.useState({
    query: ""
  });



  const columns = useHeader({
    history,
    tableTitles: {
      no      : translate("system.number", "No."),
      username: translate("staff.username", "Username"),
      role    : translate("staff.role", "Role"),
      email   : translate("staff.email", "Email"),
      action  : translate("system.action", "Action"),
      edit    : translate("system.edit", "Edit"),
      remove  : translate("system.delete", "Remove"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/staff/edit/${item._id}`);
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await staffApi.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
    lang,
    permission

  });

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  React.useEffect(()=> {
    if (permissions.length > 0){
      setPermission(convertArrayToObject(permissions, "code"));
    }
  }, [permissions]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.staff"></ManualLink>} title={translate("staff", "Staff")} extra={[
        <Button key="createButton" type="primary" onClick={() => history.push("/staff/create")} ><PlusOutlined /> {translate("system.add", "New staff")} </Button>
      ]}/>
      <ManualDesc translateKey ="manual.desc.staff" />
      <ManualAlert translateKey={"manual.alert.staff"} />
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          // filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={staffApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};


const useHeader = ({ onAction, tableTitles, lang, permission }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.username,
    render: (record) => {
      return `${record.name}`;
    },
  }, {
    title : tableTitles.role,
    render: (record) => {
      return `${permission!== null?permission[record.permission][lang?.toLowerCase()]:null}`;
    },
  }, {
    title : tableTitles.email,
    render: (record) => {
      return `${record.email}`;
    },
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (row) => {
      return (
        <RowAction
          actions={{
            edit  : tableTitles.edit,
            remove: tableTitles.remove,
          }}
          onClick={(key) => onAction(key, row)}
        />
      );
    },
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

const initialState = {
  sent: [],
  send: [],
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case "dashboard.sent":
      return {
        ...state,
        sent: action.payload
      };

    case "dashboard.send":
      return {
        ...state,
        send: action.payload
      };
    default:
      return state;
  }
};
export default dashboard;
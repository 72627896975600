import React from "react";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import ManualText from "./ManualText";

export default ({ translateKey }) => {
  const { translate } = useTranslate();
  return (
    <Container>
      {translate(translateKey, ManualText[translateKey])}
    </Container>
  );
};

const Container = styled.div`
  color: #5a5d61;
  margin: 0px 24px 12px;
`;
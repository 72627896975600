import React from "react";
import { useParams } from "react-router-dom";
import { richmenu } from "../../apis";
import moment from "moment";
import Index from "./index";

export default () => {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [editable, setEditable] = React.useState([]);

  const reload = React.useCallback(
    async (signal) => {
      let res = await richmenu.get(params.id, { signal });

      setEditable(["copy", {
        ...res,
        schedules: [moment(res.schedules[0]), moment(res.schedules[1])],
        segments : res.targets,
        // tags     : res.tags.map(item => item.tag),
        // values   : res.tags.map(item => {
        //   let value = item.value;

        //   if (item.dates.length > 0)
        //     value = [moment(item.dates[0]), moment(item.dates[1])];

        //   if (item.numbers.length > 0)
        //     value = item.numbers;

        //   if (item.checked !== undefined)
        //     value = item.checked;

        //   return {
        //     tag  : item.tag,
        //     value: value
        //   };
        // })
      }]);

      setLoading(false);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (<>{!loading && <Index editable={editable} />}</>);
};

import React from "react";
import { useParams } from "react-router-dom";
import { richmenu } from "../../apis";
import moment from "moment";
import Form from "./Form";

export default () => {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [editable, setEditable] = React.useState([]);

  const reload = React.useCallback(
    async (signal) => {
      let res = await richmenu.get(params.id, { signal });

      setEditable(["copy", {
        ...res,
        schedules: [moment(res.schedules[0]), moment(res.schedules[1])],
        segments : res.targets,
      }]);

      setLoading(false);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (<>{!loading && <Form editable={editable} />}</>);
};

import request from "../utils/request";

export const create = (data) => request.post("/api/tags", data);
export const list = (data) => request.get("/api/tags", data);
export const remove = (id)=> request.del(`/api/tags/${id}`);
export const update = (data)=> request.put(`/api/tags/${data._id}`, data);
export const get = (id) => request.get(`/api/tags/${id}`);

export const select = (dispatch) => async () => {
  let res = await request.get("/api/tags/select");

  dispatch({
    type   : "general.tags",
    payload: res,
  });

  return res;
};
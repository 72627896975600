import React from "react";
import styled from "styled-components";
import colors from "../../../colors";

export default ({ values }) => {
  return (
    <UploadContainer>
      <Uploaded>
        <img src={values.previewImageUrl} alt='avatar' style={{ width: "100%" }} />
      </Uploaded>
    </UploadContainer>
  );
};

const Uploaded = styled.div`
  width: 120px;
  height: 100px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #c7c7c7;
  img {
    display: flex;
  }
  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    background: #6f6f6f;
    color: #fff;
    width: 22px;
    height: 20px;
    text-align: center;
    font-weight: 400;
    transition: all .2s;
    &:hover {
      background: #333;
    }
  }
`;
const UploadContainer = styled.div`
  .ant-upload {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
    color: #9a9a9a;
  }
`;
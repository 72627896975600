import React from "react";
import { Button, Form as AntForm, Row, Col } from "antd";
import { Formik } from "formik";
import { useTranslate } from "../../../hooks";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { Form, FormItem, TimePicker, Input } from "formik-antd";
import styled from "styled-components";
import colors from "../../../colors";
import * as Yup from "yup";

const FormSchema =(translate) => Yup.object({
  days: Yup.number().required(translate("system.message.required", "This field is required!")),
  time: Yup.date().required(translate("system.message.required", "This field is required!")),
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    days: undefined,
    time: undefined,
    ...(action && action[1])
  });

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {() => {
          return (
            <Form {...formItemLayout}>
              <AntForm.Item label={translate("scenario.settings.form.timer", "Set timer")}>
                <Row gutter={8}>
                  <Col span={10}>
                    <FormItem name="days">
                      <Input min={0} type="number" name="days" placeholder={translate("scenario.settings.form.timer.days", "〇〇 Days")} />
                    </FormItem>
                  </Col>
                  <Col span={2} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}><label>~</label></Col>
                  <Col span={10}>
                    <FormItem name="time">
                      <TimePicker name="time" format="HH:mm" style={{ width: "100%" }} />
                    </FormItem>
                  </Col>
                </Row>
              </AntForm.Item>
              <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
                <Button htmlType="submit" type="primary" style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
                <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
const Container = styled.div`
  padding : 10px;
  width: 100%;
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;
import React from "react";
import { Form, FormItem, DatePicker } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslate } from "../../../hooks";
import { Button } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;


const FormSchema = (translate) => Yup.object().shape({
  dates: Yup.array().of(Yup.date().required()).required(translate("system.message.required", "Please fill out this field!"))
});

export default ({ onSubmit })=> {
  const { translate } = useTranslate();
  return (
    <div>
      <Formik
        initialValues={{
          dates: [moment(), moment()],
        }}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <FormItem name="dates">
              <RangePicker placeholder={translate("process.report.form.daterange", "Date range")} name="dates" />
            </FormItem>
            <Button className="form-button" htmlType="submit" type="primary" loading={isSubmitting} block>
              <span>{translate("system.confirm", "Confirm")}</span>
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
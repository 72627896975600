import React, { useState } from "react";
import { Space, Tooltip } from "antd";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { InfoCircleOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useTranslate } from "../../hooks";
import NoneImage from "../../assets/noneImage.jpg";

const RichmenuAliasPreview = (props) => {
  const { translate, changeLanguage, lang } = useTranslate();
  const dispatch = useDispatch();
  const { preview, message } = useSelector(state => state.message);
  const data = useSelector(state => state.general.richmenuSelect);
  const [errors, setErrors] = React.useState({});
  const [isText, setIsText] =useState("none");
  const [text, setText] =useState("");
  const [newLine, setNewLine] = useState("black");
  const [chatBarState, setChatBarState] = useState({ height: null, isclick: false });
  const setPreview = (preview) => {
    dispatch({
      type   : "message.preview",
      payload: preview
    });
  };
  React.useEffect(()=> {
    setText(""+ message.text);

    if (message.text === undefined || message.text === "" || message.text === null)
      setIsText("none");
    else
      setIsText("flex");
    if (text.length >= 20){
      setNewLine("darkgreen");
    }
    else
      setNewLine("black");
  }, [message.text]);

  const clickChatBar = () => {
    if (!chatBarState.isclick) {
      setChatBarState({ ...chatBarState, isclick: true, height: "0px" });
    }
    else {
      setChatBarState({ ...chatBarState, isclick: false, height: null });
    }
  };
  return (
    <PreviewContainer>
      <PreviewHeader>
        <Space>
          <a className="nav" onClick={() => setPreview(!preview)} href="#">
            {translate("system.messagepre.preview", "Richmenu alias preview")}
          </a>
        </Space>

      </PreviewHeader>
      <PreviewContent style={{ marginRight: "0px" }} className={`${preview && "open"}`}>
        <div>
          {props.children}
        </div>
        <div onClick={clickChatBar} style={{ width: "100%", height: "40px", background: "#343c3f", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: "white" }}>
          <label>{data.chatBar}</label>
        </div>
        {/* <RichTemplate width={240} height={data.type === "large" ? 160 : 80} type={data.type} template={data.template} image="https://miro.medium.com/max/2560/1*0SUtMG4TcLz8wn2wpXyOTg.png" errors={{}} /> */}
      </PreviewContent>
    </PreviewContainer>
  );
};

const PreviewContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  width: 372px;
  height: 600px;
  &.dark {
    background: #666f86;
    border: none!important;
  }
  &.fixed {
    height: inherit!important;
    border-bottom: 0;
    position: fixed;
    right: 1rem;
    bottom: 0;
    z-index: 3;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  .avatar {
    img {
      width: 45px;
      height: 45px;  
      border-radius :30px;
      border-radius: 50%;
      margin-right : 10px;
    }
  }
  .message {
    position: relative;
    .title {
      font-size: 13px;
      color: #fff;
      padding: 2px;
    }
    .body {
      line-break: anywhere;
      display: inline-block;
      position: relative;
      font-size: 13px;
      /* padding: 6px 8px; */
      background: #e6f7ff;
      border-radius: 20px;
      max-width: 300px;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0.75rem;
        width: 0;
        height: 0;
        border: 0.5rem solid transparent;
        border-right-color: #edeff0;
        border-left: 0;
        border-top: 0;
        margin-left: -0.375rem;
      }
    }
  }
`;

const PreviewContent = styled.div`
  display :  flex;
  flex-direction : column;
  justify-content : flex-end;
  width: 370px;
  height: 540px;
  margin-bottom: -540px;
  /* padding-right: 20px; */
  overflow-y: auto;
  transition: margin 0.3s ease-in-out;
  /* padding: 20px 0 20px 20px; */
  &.open {
    margin-bottom: 0;
  }
  .image { 
    width : 100%;
    transition: width 3s;
  }
`;

const PreviewHeader = styled.div`
  background: #343c3f;
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .nav {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    .anticon {
      margin-right: 5px;
    }
  }
  .info {
    text-decoration: none;
    font-size: 14px;
    color: #fff;
  }
`;


export default RichmenuAliasPreview;
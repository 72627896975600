import request from "./request";
import moment from "moment";
import numeral from "numeral";

const dateFormat = (date) => moment(date).format("YYYY-MM-DD");
const datetimeFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm");

// numeral.register("locale", "jp", {
//   delimiters: {
//     thousands: ",",
//     decimal  : ","
//   },
//   abbreviations: {
//     thousand: "k",
//     million : "m",
//     billion : "b",
//     trillion: "t"
//   },
//   currency: {
//     symbol: "₮"
//   }
// });
// numeral.locale("jp");

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  style: {
    marginTop: 20
  },
  wrapperCol: {
    xs: {
      span  : 24,
      offset: 0,
    },
    sm: {
      span  : 16,
      offset: 8,
    },
  },
};


const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  console.log("convert canvas ==>", canvas);



  // As Base64 string
  // return canvas.toDataURL("image/jpeg");
  return canvas;
}

export const generateDownload = async (imageSrc, crop) => {
  const canvas = await getCroppedImg(imageSrc, crop);
  return canvas;
};


export const renderImage=(canvas, blob)=> {
  const ctx = canvas.getContext("2d");
  const img = new Image();
  img.onload = (event) => {
    URL.revokeObjectURL(event.target.src); // 👈 This is important. If you are not using the blob, you should release it if you don't want to reuse it. It's good for memory.
    ctx.drawImage(event.target, 0, 0);
  };
  img.src = URL.createObjectURL(blob);
  return img;
};

const formatNumber = (val) => {
  if (val === undefined || val === null || val === -99999 || val === -9999 || val === -999.99) return "-";
  return numeral(val).format("0,0.00");
};

const formatIntNumber = (val) => {
  if (val === undefined || val === null || val < 0) return "-";
  return numeral(val).format("0,0");
};

export { formatNumber, request, formatIntNumber, dateFormat, datetimeFormat, formItemLayout, tailFormItemLayout };

import React from "react";
import CardForm from "./CardForm";
import { Radio, Spin, Alert, Tabs, Card, Descriptions, Empty, message } from "antd";
import styled from "styled-components";
import { card as cardApi, payment as paymentApi } from "../../apis";
import { Button } from "../Design";
import { CreditCardOutlined } from "@ant-design/icons";
import { useFetch, useTranslate } from "../../hooks";
import { useSelector } from "react-redux";
import colors from "../../colors";

const { TabPane } = Tabs;

export default ({ onFinish, payment }) => {
  const { translate } = useTranslate();
  const Multipayment = window.Multipayment;
  const { shopId } = useSelector(state => state.general);
  const formRef = React.useRef();
  const [card, setCard] = React.useState({});
  const [action, setAction] = React.useState(["new"]);
  const [error, setError] = React.useState(null);
  const [loadButton, setLoadButton] = React.useState(false);
  const [cards, fetching] = useFetch(cardApi.list, null)({
    rows: []
  }, (err, cards) => {
    if (cards.rows.length > 0)
      setAction(["exist"]);

    setCard(cards.rows[0]);
  });

  const onChangeRadio = (id) => {
    let card = cards.rows.find(row => row._id === id.target.value);
    setCard(card);
  };

  const getMultiPayment = (data = {}) => {
    let date = [];
    date[0] = data.expiryDate.replaceAll("/", "").slice(0, 2);
    date[1] = data.expiryDate.replaceAll("/", "").slice(2, 4);
    return new Promise((resolve, reject) => {
      Multipayment.init(shopId);
      Multipayment.getToken({
        cardno      : data.cardNumber.replaceAll(" ", ""),
        expire      : `${date[1]}${date[0]}`,
        securitycode: data.cvv,
        holdername  : data.holderName,
        token       : ""
      }, (res) => {
        if (res.resultCode === "000") {
          resolve(res.tokenObject.token);
        } else {
          reject(res);
        }
      });
    });
  };

  const onPay = async () => {
    setLoadButton(true);
    try {
      let result;
      if (action[0] === "exist") {
        try {
          result = await paymentApi.pay({
            type   : payment.type,
            _id    : payment.payload._id,
            payment: {
              method: "EXIST",
              cardId: card._id,
              expire: null,
              holder: null,
              save  : null,
              token : null
            }
          });
        }catch(err){
          setLoadButton(false);
          if(err.message === "GMO_MEMBER_NOT_FOUND")
          {
            message.error(translate("payment.error.gmomembernotfound", "Card info not found, please register card info again"));
          }
        }
      } else {
        let data = await formRef.current.validate();
        let token = await getMultiPayment(data);
        try{
          result = await paymentApi.pay({
            type   : payment.type,
            _id    : payment.payload._id,
            payment: {
              method: "NEW",
              cardId: null,
              expire: data.expiryDate.replaceAll("/", ""),
              holder: data.holderName,
              save  : data.save,
              token : token
            }
          });
        }catch(err){
          setLoadButton(false);
          if(err.message === "GMO_MEMBER_NOT_FOUND")
          {
            message.error(translate("payment.error.gmomembernotfound", "Card info not found, please register card info again"));
          }
        }
      }

      onFinish(result, setLoadButton, payment.payload);
    } catch (err) {
      setError(err.message);
      setLoadButton(false);
      await formRef.current.clear();
    }
  };

  const onChangeTab = (tab) => {
    setAction([tab]);
  };

  React.useEffect(() => {
    setError(null);
  }, [payment]);

  return (
    <Container>
      {
        error && error.message && error.message.message ?
          <Alert
            message={error.message.message + ""}
            type="error"
            showIcon
          /> :
          error && error.message && <Alert
            message={error && error.message + ""}
            type="error"
            showIcon
          />
      }

      <Spin spinning={fetching}>
        {payment && payment.object && (
          <Payment>
            <Descriptions size="small" title={payment.object.description} bordered>
              <Descriptions.Item label={translate("receipt.tax", "Tax")} span={2}>{payment.object.taxAmount.toLocaleString("jp-JP") + "￥"}</Descriptions.Item>
              <Descriptions.Item label={translate("plan.follower.limit", "Follower")} span={2}>{payment.object.purchase.follower === -1 ? "Unlimited" : payment.object.purchase.follower}</Descriptions.Item>
              <Descriptions.Item label={translate("receipt.price", "Amount")} span={2}>{payment.object.amount.toLocaleString("jp-JP") + "￥"}</Descriptions.Item>
              <Descriptions.Item label={translate("receipt.accountlimit", "Account")} span={2}>{payment.object.purchase.account === -1 ? "Unlimited" : payment.object.purchase.account}</Descriptions.Item>
              <Descriptions.Item label={translate("receipt.total", "Total")} span={2}>{payment.object.payAmount.toLocaleString("jp-JP") + "￥"}</Descriptions.Item>
              <Descriptions.Item label={translate("receipt.message", "Message")} span={2}>{payment.object.purchase.message === -1 ? "Unlimited" : payment.object.purchase.message}</Descriptions.Item>
            </Descriptions>
          </Payment>
        )}

        <Method>
          {fetching === false && (
            <Tabs defaultActiveKey={cards.rows.length > 0 ? "exist" : "new"} onChange={onChangeTab}>
              <TabPane tab={translate("receipt.method", "SAVED CARDS")} key="exist">
                <Spin spinning={fetching}>
                  <RadioContainter>
                    <Radio.Group value={card && card._id} className="radio-wrapper" onChange={onChangeRadio}>
                      {cards && fetching === false ? (
                        <div className="card-overlay-wrapper">
                          {card && cards.rows.map(item => (
                            <Card bordered={true} className={`${card._id === item._id && "selected"}`}>
                              <Radio key={item._id} value={item._id} className="radio">
                                <div className="card-wrapper" >
                                  <span>{item.holder}</span>
                                  <span>**** **** **** {item.cardNo.slice(12)}</span>
                                </div>
                              </Radio>
                            </Card>
                          ))}
                        </div>
                      ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{translate("", "No cards")}</span>} />}
                    </Radio.Group>
                  </RadioContainter>
                </Spin>
              </TabPane>
              <TabPane tab={translate("card.addnew", "CREDIT CARD")} key="new">
                <CardForm ref={formRef} />
              </TabPane>
            </Tabs>
          )}
        </Method>
      </Spin>
      <Button loading={loadButton} type="primary" size="large" style={{ marginTop: 20 }} onClick={onPay}>
        <CreditCardOutlined /> {translate("card.pay", "Pay now")}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Payment = styled.div`
  .ant-descriptions-header {
    .ant-descriptions-title {
      font-size: 14px;
    }
    margin-bottom: 10px;
  }
  .ant-descriptions-item-content {
    text-align : right;
  }
  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, 
  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
    padding: 3px 4px !important;
    border:none;
    color : ${colors.primaryDark};
    font-weight: 400;
  }
  .ant-descriptions-item-label {
    /* background-color: #129d4b; */
    color: #333!important;
  }
`;
const Method = styled.div`
  .payment-method {
    padding: 0px 13px;
    width: 100%;
  }
  .payment-detail-body {
    height: 100%;
  }
`;
const RadioContainter = styled.div`
  .ant-radio-wrapper {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .ant-card-body {
    padding: 5px !important;
  }
  .ant-radio-group {
    width: 100%;
  }
  .card-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content :space-between;
    font-weight: 400;
    color: #565656;
  }
  .ant-card-bordered {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 7px 5px;
    border-width: 2px;
    &.selected {
      border-color: #129d4b;
    }
  }
  span.ant-radio + * {
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
  }
`;

import React from "react";
import { Button, Form as AntForm, Row, Col } from "antd";
import { Formik } from "formik";
import { useTranslate } from "../../../hooks";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { Form, FormItem, Input, Checkbox } from "formik-antd";
import styled from "styled-components";
import colors from "../../../colors";
import * as Yup from "yup";

const FormSchema =(translate) => Yup.object({
  isDefault: Yup.boolean(),
  text     : Yup.string().when("isDefault", {
    is  : false,
    then: Yup.string().max(100).required(translate("system.message.required", "This field is required!")),
  })
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    text     : undefined,
    isDefault: false,
    ...(action && action[1])
  });

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ values }) => {
          console.log("vlaues:-", values);
          return (
            <Form {...formItemLayout}>
              <AntForm.Item label={translate("trigger.settings.text.setdefault", "Set as default")}>
                <FormItem name="isDefault" required>
                  <SwitchStyle>
                    <Checkbox name="isDefault"></Checkbox>
                  </SwitchStyle>
                </FormItem>
              </AntForm.Item>
              <AntForm.Item label={translate("scenario.settings.form.text", "Set text")}>
                <FormItem name="text">
                  <Input disabled={values.isDefault} min={0} type="text" name="text" placeholder={translate("scenario.settings.form.text.placeholder", "Text")} />
                </FormItem>
              </AntForm.Item>
              <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
                <Button htmlType="submit" type="primary" style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
                <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
const Container = styled.div`
  padding : 10px;
  width: 100%;
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;
const SwitchStyle = styled.div`
  display :flex;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color : ${colors.primaryDark};
  }
  .ant-checkbox {
    margin-right: 10px;
  }
  .ant-checkbox-wrapper {
    padding-top:3px;
  }
  .ant-checkbox-inner::hover {
    border-color : yellow;
  }
`;
import request from "../utils/request";

export const list = (data) => request.get("/api/user", data);
export const create = (data) => request.post("/api/user", data);
export const update = (data) => request.put(`/api/user/${data._id}`, data);
export const remove = (data) => request.del("/api/user/remove", data);
export const get = (id) => request.get(`/api/user/${id}`);
export const invite = (data) => request.post("/api/user/invite", data);
export const inviteConfirm = (data) => request.post("/api/user/invite/confirm", data);



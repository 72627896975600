import React from "react";
import Form from "./Form";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { staff as staffApi } from "../../apis";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { message } from "antd";


export default ()=> {
  const { translate } = useTranslate();
  const history = useHistory();

  const onSubmit =async (e)=> {
    try {
      await staffApi.create({
        ...e,
        email: e.email.trim()
      });
      message.success(translate("system.message.success", "Success"));
      history.push("/staff");
    } catch (err){
      if (err.message === "ALREADY_REGISTERED") {
        message.error(translate("signup.warning.registered", "This e-mail address is already registered!"));
      } else {
        console.log(err.message);
      }
    }
  };

  return (
    <div>
      <PageContainer>
        <PageHeader onBack={() => history.goBack()} title={translate("staff.create", "Create new staff")} />
        <PageContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormWrapper>
              <Form onSubmit={onSubmit} />
            </FormWrapper>
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};


const FormWrapper = styled.div`
  width: 600px;
`;
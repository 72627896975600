import { Avatar, Button, Empty, Input, message, PageHeader, Space } from "antd";
import React from "react";
import { PageContainer, PageContent } from "../../../components/Layout";
import { useTranslate } from "../../../hooks";
import { MyTable, RowAction } from "../../../components";
import { useHistory } from "react-router";
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../../colors";
import confirm from "antd/lib/modal/confirm";
import { imagemap as imagemapService } from "../../../apis";
import { ManualDesc, ManualLink } from "../../../components/Manual";

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: ""
  });


  const columns = useHeader({
    history,
    tableTitles: {
      no    : translate("system.number", "No."),
      image : translate("imagemap.image", "Image"),
      title : translate("imagemap.title", "Title"),
      action: translate("imagemap.action", "Action"),
      edit  : translate("system.edit", "Edit"),
      remove: translate("system.delete", "Remove"),
      copy  : translate("system.copy", "Copy")
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          // let res = await tags.get(item._id);
          history.push(`/imagemap/video/${item._id}`);
          // let res;
          // setAction(["update", res]);
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await imagemapService.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        case "copy": {
          let result = await imagemapService.copy(item._id);
          message.success(translate("system.message.success", "Successful"));
          history.push(`/imagemap/video/${result._id}`);
          break;
        }
        default:
      }
    },
  });

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.video"></ManualLink>} title={translate("imagemap.type.video", "Imagemap: Video")} extra={[
        <Button key={1} type="primary" onClick={() => history.push("/imagemap/video/create")}><PlusOutlined />{translate("system.create", "Create new")}</Button>
      ]} />
      <ManualDesc translateKey ="manual.desc.video" />
      <PageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              value={query}
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
          <Button onClick={() => setQuery("")} type="default">{translate("system.clear", "Clear")}</Button>
        </Space>

      </PageFilter>
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={imagemapService.list("video")}
        />
      </PageContent>
    </PageContainer>
  );
};


const useHeader = ({ onAction, tableTitles }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.image,
    width : "100px",
    render: (item) => {
      return (<Avatar shape="square" size={100} src={`${item.previewUrl}`}/>);
    }
  }, {
    title    : tableTitles.title,
    dataIndex: "altText",
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (order) => {
      return <RowAction actions={{
        edit  : tableTitles.edit,
        remove: tableTitles.remove,
        copy  : tableTitles.copy
      }} onClick={(key) => onAction(key, order)} />;
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;
import React, { memo } from "react";
import { MyTable } from "../../components";
import { useTranslate } from "../../hooks";
import { formatIntNumber, formatNumber } from "../../utils";

const ReportTable = memo(({ oa }) => {
  const { translate } = useTranslate();

  const columns = useHeader({
    tableTitles: {
      date       : translate("rmsreport.table.date", "期間"),
      visitor    : translate("rmsreport.table.visitor", "訪問者数"),
      visitor_cvr: translate("rmsreport.table.visitor_cvr", "転換率"),
      gms        : translate("rmsreport.table.gms", "売上"),
      gms_cvr    : translate("rmsreport.table.gms_cvr", "転換数")
    }
  });
  return <MyTable dataSource={oa} columns={columns}/>;
});

ReportTable.displayName = "ReportTable";

export { ReportTable };

const useHeader = ({ tableTitles }) => {
  return [
    {
      title    : tableTitles.date,
      dataIndex: "date",

    },
    {
      title : tableTitles.visitor,
      // dataIndex: "visits_value",
      render: (record) => {
        return `${record.visits_value}`;
      }
    },
    {
      title : tableTitles.visitor_cvr,
      // dataIndex: "visits_cvr",
      render: (record) => {
        return `${formatNumber(record.visits_cvr)}%`;
      }
    },
    {
      title : tableTitles.gms,
      // dataIndex: "gms_value",
      render: (record) => {
        return `${formatIntNumber(record.gms_value)}円`;
      }
    },
    {
      title : tableTitles.gms_cvr,
      // dataIndex: "gms_conversion",
      render: (record) => {
        return `${formatIntNumber(record.gms_conversion)}`;
      }
    },
  ];
};

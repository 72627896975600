import React from "react";
import { Form, Input, FormItem, DatePicker } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslate } from "../../../hooks";
import { Button } from "antd";

const FormSchema = (translate) => Yup.object().shape({
  date: Yup.string().required(translate("system.message.required", "This field is required!"))
});

export default ({ onSubmit })=> {
  const { translate, lang } = useTranslate();
  return (
    <div>
      <Formik
        initialValues={{
          date: undefined,
        }}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <FormItem name="date">
              <DatePicker placeholder={translate("debug.rakutenorder", "Send date")} name="date" />
            </FormItem>
            <Button className="form-button" htmlType="submit" type="primary" loading={isSubmitting} block>
              <span>{translate("system.confirm", "Confirm")}</span>
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
import React from "react";
import { useParams } from "react-router-dom";
import Form from "./Form";
import { richmenu as richmenuApi } from "../../apis";
import moment from "moment";
import { useDispatch } from "react-redux";

export default () => {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [editable, setEditable] = React.useState([]);
  const dispatch = useDispatch();


  const reload = React.useCallback(
    async (signal) => {


      let res = await richmenuApi.get(params.id, { signal });

      setEditable(["update", {
        ...res,
        schedules: [moment(res.schedules[0]), moment(res.schedules[1])],
        segments : res.targets,
      }]);
      dispatch({ type: "general.richmenuAlias", payload: [...res.richmenus] });


      setLoading(false);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <div>
      {loading === false && <Form editable={editable}/>}
    </div>
  );
};

import { Spin } from "antd";
import React from "react";
import styled from "styled-components";
import NoImageAspect1 from "../../../assets/no_image1.png";
import NoImageAspect51 from "../../../assets/no_image51.png";
import { useTranslate } from "../../../hooks";

export default ({ item, type })=> {
  const { translate } = useTranslate();
  return (
    <div style={{ width: "230px", marginRight: "20px" }}>
      <Container>
        <Spin spinning={item.imageLoading !== undefined && item.imageLoading !== false}>
          {type === "IMAGE" ? (
            <img className="img" src={item?.thumbnailImageUrl || NoImageAspect1}/>
          ): (
            <img className="img" src={item?.thumbnailImageUrl || NoImageAspect51}/>
          )}
          {type === "IMAGE"&&(
            <ImageLabel>
              <div className="label-container">
                {item.actions[0].label || translate("carousel.action.label", "Enter action label")}
              </div>
            </ImageLabel>
          )}
        </Spin>
        {type === "PRODUCT" &&
      <div className="footer">
        <div className="title">
          <h3>{item?.title || translate("carousel.preview.title", "Insert card title")}</h3>
          <div className="description">{item?.text || translate("carousel.preview.desc", "Insert card description")}</div>
        </div>

        <div className="action">
          {item?.actions?.map((item, index) => {
            return actinoLabel(item, index, translate);
          })}
        </div>
      </div>}

      </Container>
      {type === "PRODUCT"&&<div style={{ fontWeight: "600", marginBottom: "7px", marginTop: "12px", color: "#625f5f" }} >{translate("carousel.alert", "※Caution※ ")}</div>}
      {type === "PRODUCT"&&<div style={{ color: "#625f5f" }}>{translate("carousel.alert.body", "The location where the description is automatically wrapped depends on the device.")}</div>}
    </div>
  );
};

const ImageLabel = styled.div`
  display: flex;
  justify-content: center;
  .label-container {
    position: absolute;
    bottom: 30px;
    color:white;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 24px;
    padding: 5px 11px;
  }
`;
const actinoLabel =(item, index, translate)=> {
  if (item?.isCheck === true){
    return (<div key={index}>{item?.label || translate("carousel.preview.action.label", "Action label")}</div>);
  }
  return "";
};
const Container =styled.div`
  border: solid 1px #E5E4E5;
  padding : 2px;
  margin-top: 30px;
  margin-right: 20px;
  border-radius: 5px;
  width: 230px;

  .description {
    text-overflow: clip;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width : 205px;
  }
  
  .title {
    margin-bottom: 30px
  }
  .footer {
    
    margin-top : 10px;
    padding : 0px 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 38%;
  }
  .action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 12px;
  }
  .img {
    width : 100%;
    border-radius : 5px;
  }
`;

import React from "react";
import { Form, FormItem, Select, Checkbox } from "formik-antd";
import { Formik } from "formik";
import { Col, Form as AntForm, Row } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { Button } from "../../Design";
import { useTranslate } from "../../../hooks";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import colors from "../../../colors";

const { Option } = Select;

const FormSchema = (translate) => {
  const required = translate("system.message.required", "Please fill out this field!");

  return Yup.object({
    type               : Yup.string().required(required),
    subscriptionPeriods: Yup.array().when("type", {
      is  : "subscriptionPeriods",
      then: Yup.array().of(Yup.string()).min(1).required(required),
    }),
    ages: Yup.array().when("type", {
      is  : "ages",
      then: Yup.array().of(Yup.string()).min(1).required(required),
    }),
    areas: Yup.array().when("type", {
      is  : "areas",
      then: Yup.array().of(Yup.string()).min(1).required(required),
    }),
    genders: Yup.array().when("type", {
      is  : "genders",
      then: Yup.array().of(Yup.string()).min(1).required(required),
    }),
    appTypes: Yup.array().when("type", {
      is  : "appTypes",
      then: Yup.array().of(Yup.string()).min(1).required(required),
    })
  });
};

export const INIT_DEMOGRAPHIC = {
  type               : undefined,
  subscriptionPeriods: undefined,
  ages               : undefined,
  areas              : undefined,
  genders            : undefined,
  appTypes           : undefined,
};

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const { demographics } = useSelector(state => state.general);
  const [data] = React.useState({
    ...INIT_DEMOGRAPHIC,
    ...(action ? action[1] : {})
  });

  const renderCheckbox = ({ type }) => {
    let values = demographics[type].values;
    let label = translate(`system.constant.demo.${demographics[type].code}`, demographics[type].name);

    switch (type) {
      case "subscriptionPeriods":
        return (
          <FormItem label={label} name="subscriptionPeriods" required>
            <Checkbox.Group name="subscriptionPeriods" style={{ width: "100%" }}>
              <Row>
                {values.map((item, index) => (
                  <Col key={index} span={8}>
                    <Checkbox value={item.value}>{translate(`default.tag.${item.value}`, `${item.label} ${translate("tag.days", "day")}`)}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </FormItem>
        );
      case "ages":
        return (
          <FormItem label={label} name="ages" required>
            <Checkbox.Group name="ages" style={{ width: "100%" }}>
              <Row>
                {values.map((item, index) => (
                  <Col key={index} span={8}>
                    <Checkbox value={item.value}>{translate(`default.tag.${item.value}`, `${item.label} ${translate("tag.ages", "age")}`)}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </FormItem>
        );
      case "areas":
        return (
          <FormItem label={label} name="areas" required>
            <Checkbox.Group name="areas" style={{ width: "100%" }}>
              <Row>
                {values.map(item => (
                  <Col span={8}>
                    <Checkbox value={item.value}>{translate(`default.tag.${item.value}`, `${item.label.en}`)}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </FormItem>
        );
      case "genders":
        return (
          <FormItem label={label} name="genders" required>
            <Checkbox.Group name="genders" style={{ width: "100%" }}>
              <Row>
                {values.map((item, index) => (
                  <Col key={index} span={12}>
                    <Checkbox value={item.value}>{translate(`default.tag.${item.value}`, item.label)}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </FormItem>
        );
      case "appTypes":
        return (
          <FormItem label={label} name="appTypes" required>
            <Checkbox.Group name="appTypes" style={{ width: "100%" }}>
              <Row>
                {values.map((item, index) => (
                  <Col key={index} span={12}>
                    <Checkbox value={item.value}>{translate(`default.tag.${item.value}`, item.label)}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </FormItem>
        );
      default:
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem label={translate("system.select", "Select")} name="type" required>
              <Select name="type" placeholder={translate("system.select", "Select")}>
                {Object.keys(demographics).map((type, index) => {
                  return <Option key={index} value={type}>{translate(`system.constant.demo.${demographics[type].code}`, demographics[type].name)}</Option>;
                })}
              </Select>
            </FormItem>

            {demographics && demographics[values.type] && renderCheckbox({ type: values.type })}

            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>{translate("system.add", "Add")}</Button>
              <Button type="default" style={{ color: colors.primary, marginLeft: 10 }} onClick={onCancel}>{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};
import React from "react";
import { message, Upload } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import { TranslationOutlined } from "@ant-design/icons";
import colors from "../../colors";
import styled from "styled-components";

const { Dragger } = Upload;

export default () => {
  const { translate } = useTranslate();
  const data = {
    name    : "file",
    multiple: false,
    action  : "/api/localization/upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading")
        if (status === "done") {
          message.success(`${info.file.name} ${translate("system.message.success.upload", "File uploaded successfully")}`);
        } else if (status === "error") {
          message.error(`${info.file.name} ${translate("system.message.failed.upload", "File upload failed!")}`);
        }
    },
  };

  return (
    <PageContainer>
      <PageHeader title={translate("setting.localization", "Localization")}/>
      <PageContent>
        <ExcelImport>
          <Dragger {...data}>
            <p className="ant-upload-drag-icon">
              <TranslationOutlined color={colors.primaryDark} />
            </p>
            <p className="ant-upload-text">日本語</p>
            <p className="ant-upload-hint">{translate("system.upload", "Import file")} <b>.xlsx</b></p>
          </Dragger>
        </ExcelImport>
      </PageContent>
    </PageContainer>
  );
};

const ExcelImport = styled.div`
  .anticon {
    color: ${colors.primaryDark}!important;
  }
  .ant-upload:hover {
    border-color: ${colors.primaryDark}!important;
  }
`;
import request from "../utils/request";

export const list = (data) => request.get("/api/staff", data);

export const get = (id) => request.get(`/api/staff/${id}`);

export const create = (data) => request.post("/api/staff", data);

export const remove = (id) => request.del(`/api/staff/${id}`);

export const update = (data) => request.put(`/api/staff/${data._id}`, data);

import React from "react";
import { DatePicker, Form, FormItem, InputNumber, Select } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";

const { Option } = Select;

const FormSchema = (translate) => Yup.object({
  value    : Yup.number().required(translate("system.message.required", "This field is required!")),
  startDate: Yup.string().required(translate("system.message.required", "This field is required!")),
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const { tagTypes } = useSelector(state => state.general);
  const [data, setFormData] = React.useState({
    startDate: undefined,
    value    : undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onChangeType = (value, values) => {
    setFormData({
      ...values,
      type  : value,
      values: value === "SELECT" ? [{
        _id : undefined,
        name: undefined
      }] : []
    });
  };

  const onRemoveValue = ({ values, index }) => {
    if (values.values <= 1) return;

    setFormData({
      ...data,
      values: values.values.filter((value, i) => i !== index)
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem label={translate("tax.startdate", "Start date")} name="startDate" required>
              <DatePicker showTime name="startDate" style={{ width: "100%" }} />
            </FormItem>
            <FormItem label={translate("tax.manage.percent", "Tax")} name="value" required>
              <InputNumber max={99} name="value" placeholder={translate("tax.manage.percent", "Tax %")} style={{ width: "100%" }} />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

const InlineGroup = styled.div`
  display: flex;
  flex-direction: row;
`;
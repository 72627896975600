import React from "react";
import { Form, FormItem, Input, Select, Checkbox, DatePicker, TimePicker } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Row, Col } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { useSelector } from "react-redux";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";
import colors from "../../../colors";
import * as Yup from "yup";
const { Option } = Select;

const types = [{
  name : "Monthly",
  value: "MONTH"
}, {
  name : "Weekly",
  value: "WEEK"
}];

const AutoFormSchema =(translate) => Yup.object({
  pattern     : Yup.string().required(translate("system.message.required", "This field is required!")),
  days        : Yup.array().min(1).required(translate("system.message.required", "This field is required!")),
  startTime   : Yup.string().required(translate("system.message.required", "This field is required!")),
  isEndOfMonth: Yup.boolean().required(translate("system.message.required", "This field is required!")),
});

export default ({ initialValues, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const { regularSets } = useSelector(state => state.general);
  const [data, setFormData] = React.useState({
    ...initialValues,
  });

  const onEndOfMonth = (value, values) => {
    setFormData({
      ...values,
      days        : value === true ? [...values.days, 32] : [...values.days.filter(i => i !== 32)],
      isEndOfMonth: value
    });
  };

  const onChangeRegularSet = (value, values) => {
    setFormData({
      ...values,
      daySet      : value,
      days        : regularSets.find(i => i.code === value).value,
      isEndOfMonth: false
    });
  };

  const renderWeek = (day) =>{
    switch (day) {
      case 1: {
        return translate("system.constant.monday", "Monday");
      }
      case 2: {
        return translate("system.constant.tuesday", "Tuesday");
      }
      case 3: {
        return translate("system.constant.wednesday", "Wednesday");
      }
      case 4: {
        return translate("system.constant.thursday", "Thursday");
      }
      case 5: {
        return translate("system.constant.friday", "Friday");
      }
      case 6: {
        return translate("system.constant.saturday", "Saturday");
      }
      case 7: {
        return translate("system.constant.sunday", "Sunday");
      }
      default: {
        return day;
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => AutoFormSchema(translate)}
      key={"add_autoissue"}
      id="add_autoissue"
      onSubmit={onSubmit}>
      {({ isSubmitting, values, handleSubmit }) => {
        return (
          <Form >
            <FormItem label={translate("regular.type", "Type")} name="pattern" required>
              <Select name="pattern" placeholder={translate("system.select", "Select")} style={{ width: "100%" }}>
                {types.map((type, index) => {
                  return <Option key={index} value={type.value}>{translate(`system.constant.${type.value}`, type.name)}</Option>;
                })}
              </Select>
            </FormItem>
            { values.pattern === "MONTH" &&
               <FormItem label={translate("regular.manage.type", "Preset")} name="daySet">
                 <Select name="daySet" placeholder={translate("system.select", "Select")} onChange={(e) => onChangeRegularSet(e, values)} style={{ width: "100%" }}>
                   {regularSets.map((item, index) => {
                     return <Option key={index} value={item.code}>{translate(`regular.sets.${item.code.toLowerCase()}`, item.name)}</Option>;
                   })}
                 </Select>
               </FormItem>
            }
            {values.pattern === "MONTH" &&
               <FormItem label={translate("regular.days", "Days")} name="days" required>
                 <Checkbox.Group name="days" style={{ width: "100%" }}>
                   <Row>
                     {Array.from(Array(32).keys()).map((item, index) => {
                       if (item === 31) {
                         return <Col key={index} span={3}>
                           <Checkbox value={item + 1} onChange={(e) => onEndOfMonth(e.target.checked, values)}> {translate("regular.endofmonth", "End")}</Checkbox>
                         </Col>;
                       }
                       return <Col key={index} span={3}>
                         <Checkbox value={item + 1}> {item + 1}</Checkbox>
                       </Col>;
                     }
                     )}
                   </Row>
                 </Checkbox.Group>
               </FormItem>
            }
            {values.pattern === "WEEK" &&
               <FormItem label={translate("coupon.weekdays", "Week days")} name="days" required>
                 <Checkbox.Group name="days" style={{ width: "100%" }}>
                   <Row>
                     {Array.from(Array(7).keys()).map((item, index) => (
                       <Col key={index} span={8}>
                         <Checkbox value={item + 1}> {renderWeek(item + 1)}</Checkbox>
                       </Col>
                     ))}
                   </Row>
                 </Checkbox.Group>
               </FormItem>
            }
            <FormItem label={translate("coupon.issue.starttime", "Start time")} name={"startTime"} key='startTime' required>
              <TimePicker format={(date) => date.tz("Asia/Tokyo").format("HH:mm")} name={"startTime"} showSecond={false} />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
              <Button onClick={handleSubmit} type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

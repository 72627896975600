import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React from "react";
import styled from "styled-components";

export default ({
  listNumber,
  initialPage,
  onShowSizeChange = () => { },
})=> {
  const [current, setCurrent] = React.useState(initialPage);

  const increment =()=> {
    setCurrent(current + 1);
    onShowSizeChange(current +1);
  };
  const decrement =()=> {
    if (current !== 1){
      setCurrent(current -1);
      onShowSizeChange(current -1);
    }
  };

  React.useEffect(()=> {
    setCurrent(initialPage);
  }, [initialPage]);

  return (
    <Container>
      <Pagination>
        <Button onClick={decrement} size="small"><LeftOutlined /></Button>
        <Input className="input" value={current} size="small"/>
        <Button disabled={listNumber === 0} onClick={increment} size="small"><RightOutlined /></Button>
      </Pagination>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Pagination = styled.div` 
  width: 128px;
  display :flex;
  padding : 14px 0px;
  justify-content: space-between;
  .input{
    margin: 0px 5px;
    text-align: center;
    pointer-events: none;
  }
`;
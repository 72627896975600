import React from "react";
import { Form, FormItem, Input, Radio } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Collapse, Row, Col, Space, message } from "antd";
import { useTranslate } from "../../../hooks";
import CooperationPreview from "../../../components/CooperationPreview";
import styled from "styled-components";
import ImageUpload from "../../../components/ImageUpload";
import ColorSchema from "../../../components/ColorSchema";
import colors from "../../../colors";
import { cooperation as cooperationApi } from "../../../apis";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DefualtSuccess from "./DefualtSuccess";
import { Cooperation, CooperationSection, MessageContent, MessageToolbar, WriteMessage } from "./RakutenOrderForm";

const { Panel } = Collapse;
const { TextArea } = Input;

export default ({ onReload, values })=> {
  const { translate, changeLanguage, lang } = useTranslate();
  return (
    <div>
      <Cooperation>
        <CooperationPreview theme="light" onReload={onReload("success")} />
        <div className="content">

          <CooperationSection title={translate("cooperationform.header", "Header")}>
            <Row gutter={15}>
              <Col>
                <FormItem label={translate("cooperationform.textcolor", "Text color")} name="success.header.textColor">
                  <ColorSchema name="success.header.textColor" />
                </FormItem>
              </Col>
              <Col>
                <FormItem label={translate("cooperationform.backgroundcolor", "Background color")} name="success.header.backgroundColor">
                  <ColorSchema name="success.header.backgroundColor" />
                </FormItem>
              </Col>
            </Row>
          </CooperationSection>
          <CooperationSection title={translate("cooperationform.picture", "Image")}>
            <FormItem name="success.logo" required>
              <ImageUpload name="success.logo" size="small" action="/api/general/upload" />
            </FormItem>
          </CooperationSection>
          <CooperationSection title={translate("cooperationform.description", "Description")}>
            <WriteMessage>
              <MessageToolbar>
                <Space className="left">
                  <Radio.Group name="success.description.type">
                    <Radio.Button value="text">{translate("cooperationform.text", "Text")}</Radio.Button>
                    <Radio.Button value="html">HTML</Radio.Button>
                  </Radio.Group>
                </Space>
              </MessageToolbar>
              <MessageContent>
                {(() => {
                  switch (values.success.description.type) {
                    case "text":
                      return (
                        <FormItem name="success.description.text" required>
                          <TextArea name="success.description.text" rows={4} />
                        </FormItem>
                      );
                    case "html":
                      return (
                        <FormItem name="success.description.html" required>
                          <TextArea name="success.description.html" rows={4} />
                        </FormItem>
                      );
                    default:
                  }
                })()}
              </MessageContent>
            </WriteMessage>
          </CooperationSection>
        </div>
      </Cooperation>
    </div>
  );
};
import { Tag } from "antd";
import React from "react";
import { dashboard } from "../../../../apis";
import colors from "../../../../colors";
import { PanelBody, PanelContainer, PanelContent, PanelWrapper } from "../../../../components/Design/Panel";
import AccountImage from "../../../../assets/account.png";
import AgencyImage from "../../../../assets/select.png";
import YenImage from "../../../../assets/yen.png";
import { useTranslate } from "../../../../hooks";

export default () => {
  const { translate } = useTranslate();
  const [data, setData] = React.useState({});
  const [totalPrice, setTotalPrice] = React.useState(0);

  const reload = async () => {
    let res = await dashboard.users();
    let plan = await dashboard.plans();
    let totalSum = 0;
    plan.rows.forEach(item => {
      totalSum += item.totals;
    });
    setTotalPrice(totalSum);
    setData(res);
  };

  React.useEffect(() => {
    reload();
  }, [data === null]);

  return (
    <>
      <PanelContainer>
        <PanelWrapper>
          <PanelBody>
            <PanelContent>
              <div className="card-wrapper">
                <div className="card-icon" style={{ background: colors.primaryDark, color: "white" }}>
                  <img className="card-image" style={{ width: "31px" }} src={AccountImage} />
                </div>
                <div className="card-body">
                  <Tag color={colors.primaryDark}><strong style={{ fontSize: "16px" }}>{data && data.accounts}</strong><span style={{ marginLeft: "8px" }}>{translate("dashboard.accounts", "Total users")}</span></Tag></div>
              </div>
              <div className="card-wrapper">
                <div className="card-icon" style={{ background: colors.primaryDark, color: "white" }}>
                  <img style={{ width: "34px" }} src={AgencyImage} />
                </div>
                <div className="card-body">
                  <Tag color={colors.primaryDark}><strong style={{ fontSize: "16px" }}>{data && data.agencies}</strong><span style={{ marginLeft: "8px" }}>{translate("dashboard.agencies", "Total Agencies")}</span></Tag></div>
              </div>
              <div className="card-wrapper">
                <div className="card-icon" style={{ background: colors.primaryDark, color: "white" }}>
                  <img style={{ width: "30px" }} src={YenImage} />
                </div>
                <div className="card-body">
                  <Tag color={colors.primaryDark}><strong style={{ fontSize: "16px" }}>{totalPrice.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</strong><span style={{ marginLeft: "8px" }}>{translate("dashboard.totalincome", "Total Income")}</span></Tag></div>
              </div>
            </PanelContent>
          </PanelBody>
        </PanelWrapper>
      </PanelContainer>
    </>
  );
};
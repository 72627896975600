import React from "react";
import styled from "styled-components";
import { MyTable, RowAction, Sticker } from "../../components";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { operation as operationApi } from "../../apis";
import { DatePicker, Collapse, Space, Empty, Checkbox, Tag, Alert } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import moment from "moment";
import Avatar from "antd/lib/avatar/avatar";
import NoImage from "../../assets/no_image.png";
import { ManualAlert, ManualDesc, ManualLink } from "../../components/Manual";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

export default () => {

  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    query    : "",
    startDate: undefined,
    endDate  : undefined
  });

  const tableTitles = {
    no                         : translate("system.number", "No."),
    action                     : translate("system.action", "Action"),
    delivered                  : translate("broadcast.delivered", "Delivered count"),
    openCount                  : translate("broadcast.open.count", "Open count"),
    clickedUserCount           : translate("broadcast.clicked.user", "Clicked user count"),
    uniqueMediaPlayed          : translate("broadcast.played.user.count.all", "Played user count (all users)"),
    uniqueMediaPlayed100Percent: translate("broadcast.played.user.count.100", "Played user count (100% playback)"),
    openRate                   : translate("broadcast.open.rate", "Open rate"),
    clickRate                  : translate("broadcast.click.rate", "Click rate"),
    playRate                   : translate("broadcast.play.rate", "Play rate"),
    fullPlayRate               : translate("broadcast.full.play.rate", "Full play rate "),
    sendDate                   : translate("broadcast.send.date", "Send date"),
    detail                     : translate("broadcast.detail", "Detail"),
    title                      : translate("broadcast.title", "Title"),
    messageData                : translate("broadcast.message.data", "Message data"),
  };

  const onAction = (key, item) => {
    switch (key) {
      case "detail": {
        history.push(`/operation/broadcast_report/${item._id}`);
        break;
      }
      default:
    }
  };

  let [columns, setColumns] = React.useState(useHeader({
    history,
    tableTitles,
    translate,
    onAction
  }));

  let [options, setOptions] = React.useState([
    { label: translate("broadcast.delivered", "Delivery count"), value: "delivery", isActive: true },
    { label: translate("broadcast.open.count", "Open count"), value: "openCount", isActive: true },
    { label: translate("broadcast.clicked.user", "Clicked user count"), value: "clickedUserCount", isActive: false },
    { label: translate("broadcast.played.user.count.all", "Played user count (all users)"), value: "playedUserCountAll", isActive: false },
    { label: translate("broadcast.played.user.count.100", "Played user count (100% playback)"), value: "playedUserCountPlayback", isActive: false },
    { label: translate("broadcast.open.rate", "Open rate"), value: "openRate", isActive: true },
    { label: translate("broadcast.click.rate", "Click rate"), value: "clickRate", isActive: false },
    { label: translate("broadcast.play.rate", "Play rate"), value: "playRate", isActive: false },
    { label: translate("broadcast.full.play.rate", "Full play rate"), value: "fullPlayRate", isActive: false },
  ]);


  let colmns = [];

  const onChange = (array) => {
    array.forEach(element => {

      switch (element) {
        case "delivery": {
          colmns.push({
            title : tableTitles.delivered,
            render: (row)=> {
              if (row.delivered){
                return (<div>{row.delivered}</div>);
              }
              return (<div>-</div>);
            }
          });
          break;
        }
        case "openCount": {
          colmns.push({
            title : tableTitles.openCount,
            render: (row)=> {
              if (row.uniqueImpression){
                return (<div>{row.uniqueImpression}</div>);
              }
              return (<div>-</div>);
            }
          });
          break;
        }
        case "clickedUserCount": {
          colmns.push({
            title : tableTitles.clickedUserCount,
            render: (row)=> {
              if (row.uniqueClick){
                return (<div>{row.uniqueClick}</div>);
              }
              return (<div>-</div>);
            }
          });
          break;
        }
        case "playedUserCountAll": {
          colmns.push({
            title : tableTitles.uniqueMediaPlayed,
            render: (row)=> {
              if (row.uniqueMediaPlayed){
                return (<div>{row.uniqueMediaPlayed}</div>);
              }
              return (<div>-</div>);
            }
          });
          break;
        }
        case "playedUserCountPlayback": {
          colmns.push({
            title : tableTitles.uniqueMediaPlayed100Percent,
            render: (row) => {
              if (row.uniqueMediaPlayed100Percent) {
                return (<div>{row.uniqueMediaPlayed100Percent}</div>);
              }
              return (<div>-</div>);

            },
          });
          break;
        }
        case "fullPlayRate": {
          colmns.push({
            title : tableTitles.fullPlayRate,
            render: (row) => {
              return (
                <div>{`${parseInt(row?.fullPlayRate, 10)}%`}</div>
              );
            },
          });
          break;
        }
        case "openRate": {
          colmns.push({
            title : tableTitles.openRate,
            render: (row) => {
              return (
                <div>{`${parseInt(row?.openRate, 10)}%`}</div>
              );
            },
          });
          break;
        }
        case "playRate": {
          colmns.push({
            title : tableTitles.playRate,
            render: (row) => {
              return (
                <div>{`${parseInt(row?.playRate, 10)}%`}</div>
              );
            },
          });
          break;
        }
        case "clickRate": {
          colmns.push({
            title : tableTitles.clickRate,
            render: (row) => {
              return (
                <div>{`${parseInt(row?.clickRate, 10)}%`}</div>
              );
            },
          });
          break;
        }
        default:

      }
    });

    colmns.splice(0, 0, {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    }, {
      title    : tableTitles.title,
      dataIndex: "title",
    }, {
      title : tableTitles.messageData,
      render: (row) => {
        if (row.messageData.length > 0) {
          return (
            <div>
              {checkMessages(row.messageData).type === "sticker"?(
                <Sticker
                  size="small"
                  packageId={checkMessages(row.messageData)?.packageId}
                  id={checkMessages(row.messageData)?.stickerId}
                />
              ):(
                <Avatar shape="square" size={80} src={checkMessages(row.messageData)?.previewImage?checkMessages(row.messageData)?.previewImage:NoImage}/>
              )}
            </div>
          );
        }
        return (<div>-</div>);

      },
    }, {
      title : tableTitles.sendDate,
      render: (row) => {
        return (moment(row.sendDate).format("YYYY-MM-DD HH:mm"));
      }
    });

    setColumns([...colmns, {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (row) => {
        return (
          <RowAction
            actions={{
              detail: tableTitles.detail,
            }}
            onClick={(key) => onAction(key, row)}
          />
        );
      },
    }]);
  };


  const actionOption = (e, index) => {
    options[index] = { ...options[index], isActive: e.target.checked };
    setOptions(options);
  };


  return (
    <PageContainer>
      <Container>
        <PageHeader subTitle={<ManualLink link="manual.link.broadcastreport"></ManualLink>} title={translate("broadcast.list.title", "Braodcast report")} />
        <ManualDesc translateKey ="manual.desc.broadcastreport" />
        <ManualAlert translateKey={"manual.alert.broadcastreport"} />
        <PageContent>
          <FilterContainer>
            <Space direction="vertical" size="large">
              <RangePicker onChange={(e)=>{
                setFilters({
                  ...filters,
                  startDate: e?moment(new Date(e[0])).startOf("day"):undefined,
                  endDate  : e?moment(new Date(e[1])).endOf("day"):undefined
                });
              }} />
              <Collapse defaultActiveKey={["1"]} >
                <Panel header={translate("broadcast.show.metrics", "Show metrics")} key="1">
                  <Checkbox.Group defaultValue={["delivery", "openCount", "openRate"]} style={{ width: "100%" }} onChange={(e) => onChange(e)}>
                    {
                      options.map((item, index) => {
                        return (
                          <Checkbox onChange={(e) => actionOption(e, index)} disabled={columns.length === 10 && item.isActive === false} value={item.value}>{item.label}</Checkbox>
                        );
                      })
                    }
                  </Checkbox.Group>
                </Panel>
              </Collapse>
            </Space>
          </FilterContainer>

          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            ref={myTableRef}
            filters={filters}
            rowKey={(record) => record._id}
            columns={columns}
            loadData={operationApi.broadcast}
          />

        </PageContent>
      </Container>
    </PageContainer>
  );
};



const FilterContainer = styled.div`
    margin:10px 0px;
    .ant-space {
      width :100%;
    }
`;

const Container = styled.div`
  .ant-table-row-expand-icon-collapsed{
    display:none;
  }
`;

const checkMessages = (messages) => {
  let message ={};
  if (messages) {
    messages.forEach(item => {
      if (item?.previewImage) {
        message = item;
      }
      if (item?.type === "sticker") {
        message = item;
      }
    });
    return message;
  }
  return {};

};

const useHeader = ({ tableTitles, onAction, translate }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.title,
    render: (row) => {
      if (row.reportTaken){
        return (<div>{row.title}</div>);
      }
      return (<div>{row.title}<Tag color="red">{translate("broadcast.message.reportNotTaken", "No data")}</Tag></div>);
    }
  }, {
    title : tableTitles.messageData,
    render: (row) => {
      if (row.messageData.length > 0) {
        return (
          <div>
            {checkMessages(row.messageData).type === "sticker"?(
              <Sticker
                size="small"
                packageId={checkMessages(row.messageData)?.packageId}
                id={checkMessages(row.messageData)?.stickerId}
              />
            ):(
              <Avatar shape="square" size={80} src={checkMessages(row.messageData)?.previewImage?checkMessages(row.messageData)?.previewImage:NoImage} />
            )}
          </div>
        );
      }
      return (<div>-</div>);

    },
  }, {
    title : tableTitles.sendDate,
    render: (row) => {
      return (moment(row.sendDate).format("YYYY-MM-DD HH:mm"));
    }
  }, {
    title : tableTitles.delivered,
    render: (row)=> {
      if (row.delivered){
        return (<div>{row.delivered}</div>);
      }
      return (<div>-</div>);
    }
  }, {
    title : tableTitles.openRate,
    render: (row) => {
      return (
        <div>{`${parseInt(row?.openRate, 10)}%`}</div>
      );
    },
  }, {
    title : tableTitles.openCount,
    render: (row)=> {
      if (row.uniqueImpression){
        return (<div>{row.uniqueImpression}</div>);
      }
      return (<div>-</div>);
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (row) => {
      return (
        <RowAction
          actions={{
            detail: tableTitles.detail,
          }}
          onClick={(key) => onAction(key, row)}
        />
      );
    },
  }];
};

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  .ant-tag {
    margin-top: 11px;
  }
`;
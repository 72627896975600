import React, { useState } from "react";
import { Collapse, Modal, message } from "antd";
import { FormItem, Checkbox } from "formik-antd";
import styled from "styled-components";
import { Button } from "../../../../components/Design";
import { useTranslate } from "../../../../hooks";
import colors from "../../../../colors";
import { Formik } from "formik";
import { grant } from "../../../../apis";
import VerifyPasswordForm from "../../VerifyPasswordForm";
import { errors } from "../../../../utils/Errors";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const { Panel } = Collapse;

export default ({ customer }) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(true);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [data, setFormData] = useState({
    rakutenFormAgreement  : false,
    lineScheduledAgreement: false,
    yobiPlanAgreement     : false
  });


  const submitVerifyPassword = async (data) => {
    try {
      await grant.disableCustomer({ ...customer, password: data.password });
      message.success(translate("system.message.success", "Success"));
      setRemoveModal(false);
      history.push("/customer");
    } catch (error) {
      message.error(translate("system.invalid.password", "Password is invalid"));

    }
  };

  const onSubmit = () => {
    setRemoveModal(true);
  };

  return (
    <div>
      <Formik
        initialValues={data}
        validate={data => {
          const {
            rakutenFormAgreement,
            lineScheduledAgreement,
            yobiPlanAgreement
          } = data;

          (rakutenFormAgreement && lineScheduledAgreement && yobiPlanAgreement) ? setIsDisabled(false) : setIsDisabled(true);

          setFormData(data);
        }}>
        <Collapse>
          <Panel header={translate("useredit.about.disactive", "About disactivation of an account")} key="cancellation">
            <FormItem name="rakutenFormAgreement" label="" required>
              <SwitchStyle>
                <Checkbox name="rakutenFormAgreement"></Checkbox>
                <p style={{ textAlign: "justify" }}>
                  {translate("useredit.checkbox1", "If you cancel the service, you will not be able to log in to the management screen. In addition, the association between Rakuten purchase data and friend information acquired by using Yobicom, tags, and other information will all be deleted and cannot be recovered")}
                </p>
              </SwitchStyle>
            </FormItem>
            <FormItem name="lineScheduledAgreement" label="" required>
              <SwitchStyle>
                <Checkbox name="lineScheduledAgreement"></Checkbox>
                <p style={{ textAlign: "justify" }}>
                  {translate("useredit.checkbox2", "Even if you make a delivery reservation before canceling the service, all the reserved deliveries will be deleted and will not be delivered after the cancellation")}
                </p>
              </SwitchStyle>
            </FormItem>
            <FormItem name="yobiPlanAgreement" label="" required>
              <SwitchStyle>
                <Checkbox name="yobiPlanAgreement"></Checkbox>
                <p style={{ textAlign: "justify" }}>
                  {translate("useredit.checkbox3", "The usage amount in the month of service cancellation cannot be refunded even in the middle of the month. In addition, if a message that exceeds the free transmission amount is delivered in the service cancellation month, we will charge a pay-as-you-go charge for the excess message at the beginning of the following month.")}
                </p>
              </SwitchStyle>
            </FormItem>
            <Button onClick={onSubmit} disabled={isDisabled} danger>{translate("useredit.disactive.button", "Disactivate") }</Button>
          </Panel>
        </Collapse>
      </Formik>
      <Modal
        maskClosable={false}
        title={translate("system.delete", "Delete")}
        visible={removeModal}
        onOk={null}
        onCancel={() => setRemoveModal(false)}
        footer={null}>
        <VerifyPasswordForm onSubmit={submitVerifyPassword} visible={setRemoveModal} />
      </Modal>
    </div>
  );
};

const SwitchStyle = styled.div`
  display :flex;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color : ${colors.primaryDark};
  }
  .ant-checkbox {
    margin-right: 10px;
  }
  .ant-checkbox-wrapper {
    padding-top:3px;
  }
  .ant-checkbox-inner::hover {
    border-color : yellow;
  }
`;
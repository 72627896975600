import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Input, Tag, Tabs, Modal, Badge, Empty, message, Spin } from "antd";
import { SearchOutlined, MessageOutlined, UserOutlined, CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { conversation as conversationApi, chat, note, follower as followerApi, tags as tagsApi } from "../../apis";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import colors from "../../colors";
import NoteForm from "./components/NoteForm";
import { Button } from "../../components/Design";
import { socket } from "../../components/MessageSocket";
import { usePermission, useTranslate } from "../../hooks";
import { v4 as uuidv4 } from "uuid";
import Note from "./components/Note";
import { NoteDesign } from "../../components/Design/NoteDesign";
import Loading from "./components/Loading";
import TaggedForm from "../../pages/Follower/components/TaggedForm";
import { Sticker } from "../../components";
import InlineNoteForm from "./components/InlineNoteForm";
import { ConversationItem } from "./components/Conversation";
import renderHTML from "react-render-html";
import RichTextArea from "../../components/Message/components/RichTextArea";
import ImagePreview from "./components/ImagePreview";
import ManualDesc from "../../components/Manual/ManualDesc";


const { TabPane } = Tabs;
const chatLoading = () => (
  <>
    <Loading type="chat" clsName="" />
    <Loading type="chat-right" clsName="chat right" />
    <Loading type="chat" clsName="" />
    <Loading type="chat-right" clsName="chat right" />
    <Loading type="chat" clsName="" />
    <Loading type="chat-right" clsName="chat right" />
    <Loading type="chat" clsName="" />
  </>
);

export default () => {
  const dispatch = useDispatch();
  useFetch(tagsApi.select(dispatch))();
  const { checkRole } = usePermission();
  const chatsEndRef = React.useRef();
  const { translate } = useTranslate();
  const { account } = useSelector(state => state.general);
  const { chats, conversation, conversations, notifications } = useSelector(state => state.socket);
  const friendsRef = React.useRef();
  const [noteResult, setNoteResult] = React.useState({ rows: [], count: 0 });
  const [action, setAction] = React.useState([]);
  const [notting, setNotting] = React.useState(true);
  const [chatting, setChatting] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [connectTag, setConnectTag] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const [followers, setFollowers] = React.useState([]);
  const [follower, setFollower] = React.useState();
  const [disabledButton, setdisabledButton] = React.useState(false);
  const [notAnswered, setNotAnswered] = React.useState(true);
  const [height, setHeight] = React.useState({ friends: "100%" });
  const [emojiAction, setEmojiAction] = React.useState("none");
  const [onMore, setOnMore] = React.useState(false);
  const [filters, setFilters] = React.useState({
    filter: {
      query : "",
      type  : "not_answer",
      action: null
    },
    offset: {
      page : 1,
      limit: 20
    }
  });
  const [{ rows, count }, fetching] = useFetch(conversationApi.list, filters)({
    rows : [],
    count: 0
  });

  const onConnectTag = () => {
    setConnectTag(true);

    let follower = conversation.follower;

    let selections = {
      [follower._id]: true
    };

    setSelected(selections);
    setFollowers([follower]);
  };

  const onCancelTag = (reload) => {
    if (reload === true) {
      setSelected({});
    }

    setConnectTag(false);
  };

  const onSubmit = async (data) => {
    await followerApi.tag(data);

    message.success(translate("system.message.success", "Success"));

    loadTags(conversation.follower);
    dispatch({ type: "socket.conversation.follower.tag", payload: follower.tags });
    onCancelTag(true);
    data.setFormData({});
    setFollowers([]);
  };

  // X button songoson tag -g ustgah service
  const onUntag = async ({ follower, tag, value }) => {
    await followerApi.untag({ followers: [follower], tags: [{ tag: tag, value }] });
    loadTags(conversation.follower);
    dispatch({ type: "socket.conversation.follower.tag", payload: follower.tags });
    message.success(translate("system.message.success", "Success"));
  };
  const loadTags = async (follower) => {
    let res = await followerApi.get(follower._id);
    setFollower(res);
  };

  const onConversation = async (conversation) => {
    if (!conversation) return;

    dispatch({
      type   : "socket.on.conversation",
      payload: conversation
    });

    if (conversation.follower) {
      loadNoteResult(conversation);
      loadTags(conversation.follower);
    }

    setChatting(true);

    let { rows } = await chat.list({
      filter: {
        conversation: conversation._id,
        query       : ""
      },
      offset: {
        limit: 100,
        page : 1
      }
    });

    setChatting(false);

    dispatch({
      type   : "socket.chats",
      payload: rows
    });

    socket.emit("action", {
      type   : "conversation.change",
      payload: conversation._id
    });

    if (chatsEndRef.current)
      chatsEndRef.current.scrollIntoView({ behavior: "auto" });
    // setNotAnswered(conversation.conversationAnswered);
    // dispatch({ type: "socket.conversation.status", payload: conversation.conversationAnswered });
  };

  // chat render (sticket , emoji , text)
  const renderChat = (chat, index) => {
    if (chat.message && chat.message.type === "sticker") {
      return (
        <div key={index} className={`chat ${chat.type.toLowerCase()}`}>
          <div className="bodies">
            {chat.message && (
              <>
                <span className="timer">
                  {moment(chat.createdAt).format("YYYY/MM/DD HH:mm")}
                </span>
                <div className="chat-child">
                  <div className="body">
                    <Sticker packageId={chat.message.sticker.packageId} id={chat.message.sticker.stickerId} />
                  </div>
                </div>
              </>
            )}
            {chat.chatStatus === "SEND" && <div style={{ width: "70px", color: "#155724" }} className="status">{translate("privatechat.sending", "Sending...")}</div>}
          </div>
        </div>
      );
    }

    if (chat.message && chat.message.type === "image") {
      return (
        <div key={index} className={`chat ${chat.type.toLowerCase()}`}>
          <div className="bodies">
            {chat.message && (
              <>
                <span className="timer">
                  {moment(chat.createdAt).format("YYYY/MM/DD HH:mm")}
                </span>
                <div className="chat-child">
                  <div className="body">
                    <ImagePreview message={chat.message}/>
                  </div>
                </div>
              </>
            )}
            {chat.chatStatus === "SEND" && <div style={{ width: "70px", color: "#155724" }} className="status">{translate("privatechat.sending", "Sending...")}</div>}
          </div>
        </div>
      );
    }


    return (
      <div key={index} className={`chat ${chat.type.toLowerCase()}`}>
        <div className="bodies">
          {chat.message && (
            <>
              <span className="timer">
                {moment(chat.createdAt).format("YYYY/MM/DD HH:mm")}
              </span>
              <div className="chat-child">
                <div className="body">
                  {renderTextEmoji(chat.message)}
                </div>
              </div>
            </>
          )}
          {chat.chatStatus === "SEND" && <div style={{ width: "70px", color: "#155724" }} className="status">{translate("privatechat.sending", "Sending...")}</div>}
        </div>
      </div>
    );
  };

  const renderTextEmoji = ({ text, characters }) => {
    if (!characters || characters.length === 0)
      return text;

    let size = "22px";

    if (characters && characters.length === 1 && !(typeof characters[0] === "string" || characters[0] instanceof String))
      size = "66px";

    let html = characters.reduce((accumulator, iterator) => {
      if (typeof iterator === "string" || iterator instanceof String)
        return accumulator + `<span>${iterator}</span>`;
      if (typeof iterator[0] === "string" || iterator[0] instanceof String)
        return accumulator + `<span>${iterator[0]}</span>`;
      return accumulator + `<img style="width:${size}" src="/emoji/${iterator.productId}/${iterator.emojiId}_key.png" alt="[${iterator.altText}]" />`;
    }, "");

    return renderHTML(`${html.replace(/(?:\n|\n)/g, "<br />")}`);
  };

  const loadNoteResult = async (conversation) => {
    setNotting(true);

    let res = await note.list({
      filter: {
        followerId: conversation.follower._id
      },
      offset: {
        page : 1,
        limit: 10
      }
    });

    setNotting(false);
    setNoteResult(res);
  };

  const onEditNote = (note) => {
    setAction(["note", "update", note]);
  };

  const onAddNote = async (values, { resetForm }) => {
    try {
      await note.create(conversation.follower._id, values);

      message.success(translate("system.message.success", "Success"));

      loadNoteResult(conversation);
      resetForm({});
    } catch (err) {
      message.error(err.message);
    }

    onCancel();
  };
  const name = (tag) => {
    switch (tag.auto) {
      case "ages":
        return translate("tag.ages", "age");
      case "subscriptionPeriods":
        return translate("tag.days", "day");
      default:
        return "";
    }
  };
  const renderTag = (tag, index) => {
    switch (tag.type) {
      case "NUMBER": {
        return <Tag key={index} icon={<span className="tagIcon" onClick={() => onUntag({ follower: conversation.follower._id, tag: tag.parent || tag._id, value: tag._id })}><CloseCircleOutlined /></span>} color="cyan">{tag.name}:{tag.number}</Tag>;
      }
      case "CHECKBOX": {
        return <Tag key={index} icon={<span disabled={true} className="tagIcon" onClick={() => onUntag({ follower: conversation.follower._id, tag: tag.parent || tag._id, value: tag._id })}><CloseCircleOutlined /></span>} color="orange">{tag.name}:{tag.checked ? "YES" : "NO"}</Tag>;
      }
      case "DATE": {
        return <Tag key={index} icon={<span disabled={true} className="tagIcon" onClick={() => onUntag({ follower: conversation.follower._id, tag: tag.parent || tag._id, value: tag._id })}><CloseCircleOutlined /></span>} color="lime">{tag.auto ? translate("default.tag.registeredDate", tag.name) : tag.name}:{moment(tag.date).format("YYYY-MM-DD")}</Tag>;
      }
      default:
        return (
          <Tag key={index} icon={<span className="tagIcon" onClick={() => onUntag({ follower: conversation.follower._id, tag: tag.parent || tag._id, value: tag._id })}><CloseCircleOutlined /></span>} color="green">
            {(() => {
              if (tag.auto && tag.auto === "ages")
                return `${tag.name}${name(tag)}`;
              if (tag.auto && tag.auto === "subscriptionPeriods")
                return `${tag.name}${name(tag)}`;
              if (tag.auto)
                return translate(`default.tag.${tag.value}`, tag.name);

              return tag.name;
            })()}
          </Tag>
        );
    }
  };

  const sendMessage = (value) => {
    if (!conversation) return;

    let CHAT = {
      uid         : uuidv4(),
      conversation: conversation,
      type        : "RIGHT",
      chatStatus  : "SEND",
      message     : {
        text      : value.text,
        characters: value.characters
      }
    };

    socket.emit("action", {
      type   : "conversation.chat",
      payload: {
        ...CHAT,
        conversationId: conversation._id
      },
    });

    dispatch({
      type   : "socket.chat",
      payload: CHAT,
    });
  };

  const onSubmitNote = async (data) => {
    if (action[1] === "create")
      await note.create(conversation.follower._id, data);
    else
      await note.update(data);

    message.success(translate("system.message.success", "Success"));

    loadNoteResult(conversation);
    onCancel();
  };

  const onRemoveNote = async (data) => {
    await note.remove(data._id);
    message.success(translate("system.message.success", "Success"));

    loadNoteResult(conversation);
    onCancel();
  };

  const onCancel = () => {
    setAction([]);
  };


  const onChangeStatus = async (status) => {
    let res = await conversationApi.status(conversation._id, status);
    // setNotAnswered(res.conversationAnswered);

    // if (res.conversationResolved === true) {
    //   setFilters({ ...filters, filter: { ...filters.filter, type: "resolved" } });
    // }
    // testConversations();
    // setNotAnswered(res.conversationAnswered);
    // dispatch({ type: "socket.conversation.status", payload: res });
    message.success(translate("system.message.success", "Success"));
  };

  const onPullDown = () => {
    setFilters({
      ...filters,
      offset: {
        ...filters.offset,
        page: filters.offset.page + 1
      }
    });
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (query !== filters.filter.query){
        dispatch({
          type   : "socket.on.conversation",
          payload: null
        });

        dispatch({
          type   : "socket.chats",
          payload: []
        });
        dispatch({
          type   : "socket.conversations",
          payload: []
        });
        setNoteResult({ rows: [], count: 0 });
        setFilters({
          ...filters,
          filter: {
            ...filters.filter,
            query: query
          },
          offset: {
            ...filters.offset,
            page : 1,
            limit: 20
          }
        });
      }

    }, 300);

    return () => {
      if (timer)
        clearTimeout(timer);
    };
  }, [query]);

  React.useEffect(() => {
    if (!checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_REPORT", "SUPPORT"])){
      setdisabledButton(true);
    }
    if (fetching === false) {
      // if (!query && !onSearch) {
      if (rows.length === 0){
        setOnMore(false);
      } else {
        setOnMore(true);
      }
      let convrstns = [...conversations, ...rows];
      dispatch({
        type   : "socket.conversations",
        payload: [...convrstns.filter((item, index) => convrstns.findIndex(e => e._id === item._id) === index && item)]
      });

      if (!conversation) {
        onConversation(rows[0]);
        setNotAnswered(rows[0]&&rows[0].conversationAnswered);
      }

      else if (conversation.follower) {
        loadTags(conversation.follower);
        loadNoteResult(conversation);
      }
    } else {
      setNotting(false);
    }
  }, [rows, count, fetching]);

  React.useEffect(() => {
    if (conversation) {
      // setdisabledButton(false);

      socket.emit("action", {
        type   : "conversation.seen",
        payload: conversation._id
      });
      dispatch({
        type   : "socket.conversation.seen",
        payload: conversation
      });
      setNotAnswered(conversation.conversationAnswered);
    } else {
      // setdisabledButton(true);
    }

    if (chatsEndRef.current)
      chatsEndRef.current.scrollIntoView({ behavior: "smooth" });

  }, [chats]);


  React.useEffect(() => {

    setHeight({
      ...height,
      friends: window.innerHeight - friendsRef.current.offsetTop
    });
  }, [friendsRef]);

  return (
    <ChatContainer>
      <Modal
        maskClosable={false}
        title={translate("followers.connect.tag", "Connect Tag")}
        visible={connectTag}
        onCancel={onCancelTag}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        {connectTag && (
          <TaggedForm
            onSubmit={onSubmit}
            onCancel={onCancelTag}
            selected={selected}
            followers={followers}
          />
        )}
      </Modal>

      <FriendsContainer>
        <div className="header">
          <Input
            className="search"
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={translate("system.search.button", "Search...")}
            prefix={<SearchOutlined />}
          />
          <div className="filter-container">
            <h2 className="title">
              {translate("privatechat.message", "Message")} <Badge count={ notifications.filter((n) => n.account === account._id).length} />
            </h2>
          </div>

          <Tabs
            activeKey={filters.filter.type}
            defaultActiveKey={filters.filter.type}
            onChange={(type) =>
              setFilters({
                ...filters,
                filter: { ...filters.filter, type: type },
              })
            }>
            <TabPane tab={translate("privatechat.all", "All")} key="all" />
            <TabPane
              tab={translate("privatechat.unread", "Not answered")}
              key="not_answer"
            />
            <TabPane
              tab={translate("privatechat.followup", "Follow up")}
              key="followup"
            />
            <TabPane
              tab={translate("privatechat.resolve", "Resolved")}
              key="resolved"
            />
          </Tabs>
        </div>
        <div className="friends" style={{ height: height.friends }} ref={friendsRef}>
          {(() => {
            if (fetching)
              return <Loading type="friends" />;

            if (conversations && conversations.length === 0)
              return (
                <div style={{ textAlign: "center", color: "#b3b9c3" }}>
                  <UserOutlined style={{ fontSize: 20 }} />
                  <p>
                    {translate(
                      "privatechat.convo",
                      "Your conversations not found"
                    )}
                  </p>
                </div>
              );

            return conversations.map((item, index) => {
              return ConversationItem(index, item, filters, conversation, notifications, onConversation, translate);
            });
          })()}
          {onMore === true && (<div style={{ display: "flex", justifyContent: "center", color: "#b3b9c3" }} >
            <a onClick={onPullDown}>{translate("system.more", "More")}</a>
          </div>)}
        </div>
      </FriendsContainer>
      <MessageContainer>
        {/* <Spin spinning={load}> */}
        <div className="header">
          <div className="friend">
            <div className="avatar">
              {conversation && conversation.follower && <Avatar src={conversation.follower.avatar} />}
            </div>
            <div className="fullname">
              {conversation && conversation.follower && `${conversation.follower.name}`}
            </div>
          </div>
          <div className="action" style={{ display: disabledButton?"none":"" }}>
            {conversation && (
              <ButtonStyle
                type={
                  conversation.conversationAnswered === true ? "danger" : "default"
                }
                onClick={() => onChangeStatus("NOT_ANSWERED")}
              >
                {conversation.conversationAnswered === true
                  ? translate("privatechat.unread", "Not answered")
                  : translate("privatechat.unread", "Not answered")}
              </ButtonStyle>
            )}
            {conversation && (
              <ButtonStyle
                type={
                  conversation.conversationFollow ? "primary" : "default"
                }
                onClick={() => onChangeStatus("FOLLOWUP")}
              >
                {conversation.conversationFollow
                  ? translate("privatechat.followup", "Follow")
                  : translate("privatechat.followup", "Follow up")}
              </ButtonStyle>
            )}

            {conversation && (
              <ButtonStyle
                type={
                  conversation.conversationResolved ? "primary" : "default"
                }
                onClick={() => onChangeStatus("RESOLVED")}
              >
                {conversation.conversationResolved
                  ? translate("privatechat.resolve", "Resolved")
                  : translate("privatechat.resolve", "Resolved")}
              </ButtonStyle>
            )}

          </div>
        </div>
        <div className="chatting">
          {(() => {
            if (!conversation)
              return (
                <div className="chat-empty">
                  <MessageOutlined style={{ fontSize: 60 }} />
                  <p>
                    {translate(
                      "privatechat.empty",
                      "Your chats will appear here"
                    )}
                  </p>
                </div>
              );

            if (chatting)
              return chatLoading();

            return Object.keys(chats).map((key, index) => {
              return renderChat(chats[key], index);
            });
          })()}
          <div ref={chatsEndRef} />
        </div>
        <div className="message">
          <div className="toolbar">
            <div className="attaching">
              <Button disabled={disabledButton} onClick={() => setEmojiAction(emojiAction === "none" ? "" : "none")}>{translate("message.emoji", "Emoji")}</Button>
              <span className="info">{translate("chat.write.info", "[Enter]: Send, [Shift + Enter]: Line break")}</span>
            </div>
            <div className="action">
              {emojiAction !== "none" && <a className="close" onClick={() => setEmojiAction("none")}><CloseOutlined /></a>}
            </div>
          </div>
          <div className="emoji" style={{ marginBottom: 10 }}>
            {!disabledButton && <RichTextArea
              action={emojiAction}
              sendMessage={sendMessage}
            />}
          </div>
        </div>
      </MessageContainer>
      <ProfileContainer>
        <ManualDesc translateKey ="manual.chat.desc" />
        {conversation && conversation.follower && (
          <div className="profile">
            <div className="avatar">
              {conversation && conversation.follower && (
                <Avatar size={120} src={conversation.follower.avatar} />
              )}
            </div>
            <div className="fullname">
              {conversation &&
                    conversation.follower &&
                    conversation.follower.name}
            </div>
            <div className="tags" style={{ pointerEvents: disabledButton?"none":"" }}>
              {follower &&
                    follower.tags.map((tag, index) => {
                      return renderTag(tag, index);
                    })}
            </div>
            <Button
              size="small"
              onClick={onConnectTag}
              type="primary"
              style={{ display: disabledButton?"none":"", marginRight: 10 }}
            >
              {translate("privatechat.connecttag", "Connect tag")}
            </Button>
            <div style={{ display: disabledButton?"none":"" }} className="note-form">
              <InlineNoteForm onSubmit={onAddNote} action={action} />
            </div>
          </div>
        )}

        <div className="notes">
          <h2>{translate("privatechat.note", "Notes")}</h2>

          {notting === true ? (
            <Spin>
              <div style={{ width: "100%", height: "10px" }}></div>
            </Spin>
          ) : (
            <>
              {noteResult.rows.length === 0 && (
                <Empty description={false} />
              )}
              {noteResult.rows.map((note, index) => {
                return (
                  <div key={index}>
                    <NoteDesign key={index} className={note.style}>
                      <Note note={note} onEditNote={onEditNote} />
                    </NoteDesign>
                  </div>
                );
              })}
            </>
          )}
          <Modal
            maskClosable={false}
            title={
              action[1] === "create"
                ? translate("system.add", "Add")
                : translate("system.edit", "Edit")
            }
            visible={action[0] === "note"}
            onCancel={onCancel}
            destroyOnClose
            footer={false}
          >
            <NoteForm
              onCancel={onCancel}
              onSubmit={onSubmitNote}
              onRemove={onRemoveNote}
              action={action}
            />
          </Modal>
        </div>
      </ProfileContainer>
    </ChatContainer>
  );
};

const ChatContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  .ant-popover-inner {
    background-color : black;
  }
  .disabled {
    pointer-events: none;
  }
`;
const FriendsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 24%;
  height: 100%;
  border-right: 1px solid #e8e8e8;
  padding: 10px;
  background-color: #f7f7f7;
  .header {
    display         : flex;
    flex-direction  : column;
    .filter-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      h2.title {
        margin: 10px 0;
        font-size: 18px;
        .ant-badge {
          margin-top: -5px;
          margin-left: 5px;
          .ant-badge-count {
            padding: 0 4px;
          }
        }
      }
    }
    .ant-tabs-tab-btn {
      color: ${colors.primaryDark};
      font-weight: 500;
    }
    .ant-tabs-ink-bar {
      background: ${colors.primaryDark};
    }
    .ant-input-affix-wrapper {
      border-radius: 5px;
      margin-bottom: 10px;
      height: 35px;
      &:hover {
        border-color: #129d4b;
      }
    }
    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      border-color: #129d4b;
      box-shadow: 0 0 0 2px rgb(18 157 75 / 13%);
    }
  }
  .friends {
    display         : block;
    height          : 100%;
    overflow-y: auto;
    .friend {
      display       : flex;
      flex-direction: row;
      padding       : 12px;
      cursor        : pointer;
      transition    : background 0.5s;
      background: #fff;
      margin-bottom: 15px;
      border-radius: 8px;
      overflow: hidden;
      border-bottom: 2px solid #ddd;
      position: relative;
      min-height: 75px;
      &:hover {
        background: #e0e0e0;
      }
      &.notified {
        .message .body {
          font-weight: 500;
          color: #333!important;
        }
        .reddot {
          display: block;
        }
      }
      .reddot {
        display: none;
        position: absolute;
        top: 2px;
        right: 2px;
        .ant-badge-status-dot {
          width: 8px;
          height: 8px;
        }
      }
      &.selected {
        background: ${colors.primaryDark};
        .info {
          .fullname {
            font-weight: 700;
            color: #fff;
          }
          .message .body, .message .date {
            color: #efefef;
          }
        }
      }
      .avatar {
        display       : flex;
        margin-right  : 10px;
      }
      .info {
        display       : flex;
        flex-direction: column;
        width         : 100%;
        .fullname {
          display     : flex;
          font-size   : 13px;
          font-weight: 500;
          color: ${colors.primaryDark};
          margin-bottom: 2px;
        }
        .message {
          display       : flex;
          flex-direction: column;
          font-size     : 13px;
          .body {
            line-break: anywhere;
            font-size: 13px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100px;
            width: calc(100% - 30px);
            color: #909090;
            
          }
          .date {
            font-size: 11px;
            font-weight: 400;
            color: #777;
          }
        }
      }
    }
  }
`;
const MessageContainer = styled.div`
  display           : flex;
  flex-direction    : column;
  flex              : 1;
  height            : 100%;
  max-width: 46%;
  overflow: auto;
  .header {
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
    border-bottom   : 1px solid #e8e8e8;
    padding         : 10px;
    min-height      : 55px;
    .friend {
      display       : flex;
      flex-direction: row;
      align-items   : center;
      .avatar {
        margin-right: 10px;
      }
      .fullname {
        font-weight : 400;
        font-size   : 15px;
      }
    }
    button {
      margin-left   : 10px;
    }
  }
 
  .chatting::-webkit-scrollbar {
    width: 5px;

  }
  .chatting::-webkit-scrollbar-thumb {
    background: ${colors.primaryDark}; 
    border-radius: 10px;
  }
  .chatting {
    display             : flex;
    flex                : 1;
    flex-direction      : column;
    padding             : 10px;
    overflow-y          : scroll;
    .chat-empty {
      text-align: center;
      color: rgb(179, 185, 195);
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
    .chat {
      display           : flex;
      flex-direction    : row;
      margin-top        : 2px;
        justify-content : flex-start;
      .timer{ 
        font-size: 11px;
        color: #8e8e8e;
        margin: 8px 0;
        margin-top: 12px;
        margin: 4px 0;
        display          : flex; 
        justify-content : flex-start;
      }
        margin-top: 2px;
      &.right {
        margin-top: 2px;
        justify-content : flex-end;
        .chat-child{   
          /* line-break: anywhere;  */
          text-align: end;
          margin-top: 2px;
          display: flex;
          justify-content: flex-end;
        }
        .timer{ 
          display       : flex;
          font-size: 10px;
          color: #8e8e8e;
          margin: 8px 0;
          margin-top: 12px;
          justify-content : flex-end;
        }
      }
      &.left {
        .body {
          background-color: rgb(240, 240, 240)!important;
          color: #333!important;
        }
      }
      .bodies{
        max-width: 60%;
      }
      .body {
        line-break: anywhere;
        position: relative;
        border-radius   : 14px;
        /* background      : #f2f3f5; */
        background-color:#d4edda;
        color           : #155724;
        padding         : 5px 8px;
        font-size       : 15px;
        
        margin-bottom   : 15px;
        text-align      : initial;
        .status {
          position: absolute;
          bottom: -15px;
          right: 0;
          font-size: 10px;
          color: #333;
          font-weight: 500; 
        }
      } 
      .ant-popover-content {
        border :solid ;
      }
      .ant-popover-inner-content {
        padding : 0;
      }
      .emoji-picker-react {
        border: none;
        box-shadow : none;
      }
      .ant-popover-inner-content {
        padding : 0;
        margin  : 0;
      }
    }
  }
  .message {
    display             : flex;
    flex-direction      : column;
    background          : #f7f7f7;
    .toolbar {
      display           : flex;
      flex-direction    : row;
      padding           : 10px;
      border-top        : 1px solid #e8e8e8;
      border-bottom     : 1px solid #e8e8e8;
      justify-content   : space-between;
      .attaching {
        .info {
          font-size: 13px;
          padding: 0 10px;
          color: #8e8e8e;
        }
      } 
      .action {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .close {
          font-size: 15px;
          color: #333;
          display: flex;
        }
      }
      a {
        margin-right    : 10px;
        font-size       : 17px;
        color           : #333;
      } 
    }
    .emoji {
      padding: 10px 15px 0 15px;
    }
    .ant-popover-message-title {
      margin-left: 0px;
    }
    .emoji-picker-react {
      border: none;
      box-shadow : none;
    }
    .ant-popover-inner-content {
      padding : 0;
      margin  : 0;
    }
    .enter {
      position: relative;
      .ant-input {
        padding: 10px 10px;
        border: none;
        background: #fff;
        height: 80px;
        font-size: 13px;
        color: #333;
      }
      .ant-input:focus, .ant-input-focused {
        box-shadow: none;
      }
      button {
        position: absolute;
        top: 20px;
        right: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
const ProfileContainer = styled.div`
  display               : flex;
  flex-direction        : column;
  align-items           : center;
  border-left           : 1px solid #e8e8e8;
  width: 34%;
  .profile {
    text-align          : center;
    width               : 100%;
    padding             : 30px 0;
    border-bottom       : 1px solid #e8e8e8;
    position            : relative;
    .more {
      position          : absolute;
      top               : 10px;
      right             : 10px;
      font-size         : 18px;
    }
    .avatar {
      margin-bottom     : 10px;
    }
    .fullname {
      font-size         : 18px;
      color             : #333;
      margin-bottom     : 10px;
    }
    .tags {
      padding: 10px;
      text-align: center;
      .ant-tag {
        margin: 5px;
        padding: 2px 8px;
        font-weight: 500;
        border-radius: 5px;
        font-size: 12px;
      }
      .tagIcon {
        cursor: pointer;
        margin-right: 10px
      }
    }
    .note-form {
      margin: 12px 24px;
    }
  }
  .notes {
    display: block;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
    margin-right:10px;
    h2 {
      font-size: 18px;
    }
  }
  .notes::-webkit-scrollbar {
    width: 5px;
    
  }
  .notes::-webkit-scrollbar-thumb {
    background: ${colors.primaryDark}; 
    border-radius: 10px;
    
  }
`;

const ButtonStyle = styled(Button)`
  border :none;
  box-shadow :none;
  border-radius :33px;
  &.ant-btn-danger{
    pointer-events :none;
  }
`;
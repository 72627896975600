import React from "react";
import { Form, FormItem, Radio, DatePicker, Input } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { Form as AntForm, Row, Col, message, Button, Modal } from "antd";
import * as Yup from "yup";
import styled from "styled-components";
import { formItemLayout } from "../../utils";
import { useTranslate } from "../../hooks";
import { regular as regularApi } from "../../apis";
import SelectRegular from "../../pages/Regular/List";
import moment from "moment";
import usePlan from "../../hooks/usePlan";

const FormSchema =(translate) => Yup.object({
  sendType: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  sendDate: Yup.date().when("sendType", {
    is  : "DATE",
    then: Yup.date().required(translate("system.message.required", "Please fill out this field!"))
  }),
  regular: Yup.string().when("sendType", {
    is  : "REGULAR",
    then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
  }),
});

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values } = useFormikContext();
  const { translate } = useTranslate();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (errors && errors.sendDate) message.error(translate("system.message.required", "You must select broadcast time!"));

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});



export default React.forwardRef((props, ref) => {
  const { editable } = props;
  const { translate } = useTranslate();
  const [action, setAction] = React.useState();
  const submitRef = React.useRef();
  const { checkPermission } = usePlan();
  const [data, setFormData] = React.useState({
    sendType   : "NOW",
    sendDate   : undefined,
    regular    : undefined,
    regularName: undefined,
    ...(editable && (editable[0] === "update" || editable[0] === "reply") ? editable[1] : {})
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    }
  }));

  const onSelectRegular = async (item, setFieldValue)=> {
    let res = await regularApi.get(item._id);
    // setFormData({ ...data, regular: res._id, regularName: res.name });
    setFieldValue("regular", res._id);
    setFieldValue("regularName", res.name);
    setAction([]);
  };

  const disablePastDates = (current) => {
    return current.valueOf() < moment().startOf("day");
  };

  const changeRadio = (e, values, setValues) => {
    console.log("changeradio-");
    if (e.target.value === "NOW") {
      setValues({ ...values, sendType: e.target.value, sendDate: undefined });
    }
    else if (e.target.value === "REGULAR"){
      setValues({ ...values, sendType: e.target.value, sendDate: undefined });
    }
    else {
      setValues({ ...values, sendType: e.target.value });
    }
  };

  return (
    <FormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() =>FormSchema(translate)}
        onSubmit={()=>{}}>
        {({ values, setValues, setFieldValue }) => {
          return (
            <Form {...formItemLayout}>
              <AntForm.Item label={translate("broadcast", "Broadcast Time")}>
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <Radio.Group name="sendType" onChange={(e) => changeRadio(e, values, setValues)}>
                      <Radio value="NOW">
                        <span className="send-now">{translate("broadcast.sendnow", "Send now")}</span>
                      </Radio>
                      <Radio value="DATE" className="send-date">
                        <FormItem name="sendDate">
                          <DatePicker placeholder={translate("broadcast.startdate", "Send date")} name="sendDate" disabled={values.sendType !== "DATE"} disabledDate={disablePastDates} showTime />
                        </FormItem>
                      </Radio>
                      {checkPermission("REGULAR") && <Radio value="REGULAR" className="send-date">
                        <div style={{ display: "flex", gap: "10px" }}>
                          <FormItem name="regularName">
                            <Input name="regularName" style={{ cursor: "pointer", background: "#fff", color: "#000" }} placeholder={translate("regular.name", "Regular setting name")} disabled></Input>
                          </FormItem>
                          <Button style={{ width: "100px" }} disabled={values.sendType !== "REGULAR"} type="primary" onClick={() => setAction(["select"])} >{translate("system.select", "Select")}</Button>
                        </div>
                      </Radio>}
                    </Radio.Group>
                  </Col>
                </Row>
              </AntForm.Item>
              <SubmitForm ref={submitRef} />
              {action && action[0] === "select" && (
                <Modal
                  maskClosable={false}
                  width={1000}
                  title={translate("system.select", "Select")}
                  onCancel={() => setAction(null)}
                  visible={action && action[0] === "select"}
                  footer={null}>
                  {action && <SelectRegular select={(e) => onSelectRegular(e, setFieldValue)}/>}
                </Modal>
              )}
            </Form>
          );
        }}
      </Formik>

    </FormWrapper>
  );
});

const FormWrapper = styled.div`
  .send-date {
    display: inline-flex;
    margin-top: 10px;
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width: 240px;
      }
    }
  }
  .send-now {
    display: inline-block;
    padding: 10px 0;
  }

`;
/* eslint-disable no-fallthrough */
import React from "react";
import { Empty, Space, Spin, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, DatePicker, Table, message } from "antd";
import { debug as debugApi } from "../../apis";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";

const { RangePicker } = DatePicker;

export default () => {
  const { translate } = useTranslate();
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: "",
  });

  const columns = useHeader({
    tableTitles: {
      no         : translate("system.number", "No."),
      name       : translate("debug.account.name", "	Name"),
      customer   : translate("useraccount.company", "Customer"),
      action     : translate("system.action", "Action"),
      sync       : translate("debug.follower.sync", "Sync"),
      rakutensync: translate("debug.follower.rakutensync", "Rakuten sync")
    },
    history,
    onAction: async (key, item) => {
      switch (key) {
        case "sync": {
          try {
            await debugApi.followerSync(item);
            message.success(translate("system.message.success", "Success"));
          } catch (err){
            message.error(err.message);
          }
          myTableRef.current.reload();
          break;
        }
        case "rakutensync": {
          try {
            await debugApi.followerRakutenSync({ accountId: item._id });
            message.success(translate("system.message.success", "Success"));
          } catch (err){
            message.error(err.message);
          }
          myTableRef.current.reload();
          break;
        }
        default:
      }
    },
  });

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title={translate("debug.account.list", "Account management")} />
      <PageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
        </Space>
        <Space>
          <Button type="default" onClick={() => setFilters({ ...filters })}>{translate("system.clear", "Clear")}</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          maskClosable={false}
          filters={filters}
          ref={myTableRef}
          rowKey={(record) => record._id}
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          columns={columns}
          loadData={debugApi.accountList}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles }) => {
  return [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    },
    {
      title : tableTitles.name,
      render: (item) => <div>{item.displayName }</div>
    },
    {
      title : tableTitles.customer,
      render: (item) => {
        return (<div >{item?.customer?.companyName || "-"}</div>);
      }
    },
    {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (record) => {
        return <RowAction actions={{
          sync       : tableTitles.sync,
          rakutensync: tableTitles.rakutensync,
        }} onClick={(key) => onAction(key, record)} />;
      }
    }
  ];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .item1 {
    width: 70%;
  }
  .item2 {
    width: 30%;
  }
  .button-primary:hover {
    background : ${colors.primary} ;
    border-color : white;
    color        : white;
  }
  .button-primary {
    background    : ${colors.primaryDark} ;
    border-color  : white;
    color         : white;
  }
`;
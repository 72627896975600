import React from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";

import * as Yup from "yup";
import { useTranslate } from "../../../hooks";
import { MailOutlined } from "@ant-design/icons";
import { Button } from "antd";

const FormSchema = (translate) => Yup.object().shape({
  email: Yup.string()
    .required(translate("system.message.required", "This field is required!"))
    .email(translate("system.message.email", "Insert a valid e-mail address"))
});

export default ({ onSubmit })=> {
  const { translate, lang } = useTranslate();
  return (
    <div>
      <Formik
        initialValues={{
          email: undefined,
        }}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <FormItem name="email" style={{ marginBottom: 20 }}>
              <Input type="email" className="form-input" prefix={<MailOutlined />} name="email" placeholder={translate("system.email", "Send to email")} />
            </FormItem>

            <Button className="form-button" htmlType="submit" type="primary" loading={isSubmitting} block>
              <span>{translate("system.confirm", "Confirm")}</span>
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
import React from "react";
import { Space, Button, Spin } from "antd";
import { useTranslate } from "../../hooks";
import styled from "styled-components";
import Iframe from "react-iframe";
import { ReloadOutlined } from "@ant-design/icons";
import NoImage from "./images/no-image-profile.png";
import { useSelector } from "react-redux";

export default (props) => {
  const { type, theme = "dark", onReload } = props;
  const { translate } = useTranslate();
  const [link, setLink] = React.useState();
  const [load, setPreviewLoad] = React.useState(false);
  const { account } = useSelector(state => state.general);

  const onChange = async () => {
    setPreviewLoad(true);
    setLink(null);
    let link = await onReload();
    setLink(link);


    let timer = setTimeout(() => {
      setPreviewLoad(false);
    }, 2500);
    return () => clearTimeout(timer);
  };

  React.useEffect(() => {
    if (onReload)
      onChange();
  }, []);

  return (
    <PreviewContainer className={`${type} ${theme}`}>
      <PreviewHeader className={theme}>
        <Space>{translate("cooperationform.preview", "Preview")}</Space>
        {onReload !== undefined && (
          <Space>
            <Button type="default" size="small" onClick={onChange}>
              <ReloadOutlined />
            </Button>
          </Space>
        )}
      </PreviewHeader>
      <PreviewAccountInfo>
        <span>&#60;</span>
        {account && account?.displayName}
      </PreviewAccountInfo>
      <Spin spinning={load}>
        <PreviewContent className={`open ${type}`}>
          {onReload !== undefined ? (
            <Iframe
              url={link}
              load={true}
              width="100%"
              height="100%"
              id="cooperation-preview"
              className="cooperation-preview"
              display="initial"
              position="relative"
            />
          ) : (
            <div style={{ padding: 20 }}>
              <Message>
                <div className="avatar">
                  <img src={NoImage} />
                </div>
                <div className="message">
                  <div>{props.children}</div>
                </div>
              </Message>
            </div>
          )}
        </PreviewContent>
      </Spin>
    </PreviewContainer>
  );
};

const PreviewContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  width: 372px;
  height: 600px;
  &.dark {
    background: #666f86;
    border: none!important;
  }
  &.fixed {
    height: inherit!important;
    border-bottom: 0;
    position: fixed;
    right: 1rem;
    bottom: 0;
    z-index: 3;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  
  .avatar {
    img {
      width: 45px;
      height: 45px;  
      border-radius :30px;
      border-radius: 50%;
      margin-right : 10px;
    }
  }
  .message {
    position: relative;
    width: 100%;
    .title {
      font-size: 13px;
      color: #fff;
      padding: 2px;
    }
    .body {
      
      line-break: anywhere;
      display: inline-block;
      position: relative;
      font-size: 13px;
      padding: 6px 8px;
      background: #e6f7ff;
      border-radius: 20px;
      max-width: 300px;
      
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0.75rem;
        width: 0;
        height: 0;
        border: 0.5rem solid transparent;
        border-right-color: #edeff0;
        border-left: 0;
        border-top: 0;
        margin-left: -0.375rem;
      }
    }
  }
`;
const PreviewContent = styled.div`
  position: relative;
  width: 370px;
  height: 558px;
  overflow-y: auto;
  transition: margin 0.3s ease-in-out;
  padding: 0;
  overflow: hidden;
  &.fixed {
    margin-bottom: -540px;
    padding-right: 20px;
  }
  &.open {
    margin-bottom: 0;
  }
  .button a {
    text-transform: none;
  }
`;
const PreviewHeader = styled.div`
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fcfcfc;
  border-bottom: 1px solid #dfdfdf;
  min-height: 41px;
  &.dark {
    background: #343c3f;
    border: none!important;
    color: #fff;
    .nav {
      color: #fff;
    }
  }
  .nav {
    text-decoration: none;
    font-size: 14px;
    color: #333;
    .anticon {
      margin-right: 5px;
    }
  }
  .info {
    text-decoration: none;
    font-size: 14px;
    color: #fff;
  }
`;
const PreviewAccountInfo = styled.div`
  background: #4d5466;
  padding: 12px 15px;
  color: #fff;

  span {
    margin-right: 12px;
  }
`;
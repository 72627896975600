import React from "react";
import * as Yup from "yup";
import { Alert, Button, message } from "antd";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm } from "antd";
import { useTranslate } from "../../../hooks";
import { PageContainer, PageContent, PageHeader } from "../../../components/Layout";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { user as userService } from "../../../apis";

const FormSchema =(translate) => Yup.object().shape({
  newEmail: Yup.string().required(translate("system.message.required", "Please fill out this field")),
  password: Yup.string().required(translate("system.message.required", "Please fill out this field"))
});

export default (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { translate } = useTranslate();
  const [data] = React.useState({
    email   : props.location.state.email,
    newEmail: "",
    password: ""
  });
  const onSubmit = async (data) => {
    try {
      await userService.changeEmail({
        email   : props.location.state.email,
        newEmail: data.newEmail,
        password: data.password
      });
      message.success(translate("system.email.changed", "E-mail has been changed successfully"));
      history.goBack();
    } catch (error) {
      if (error.message === "INVALID_PASSWORD") {
        message.error(translate("system.invalid.password", "The password is incorrect. Retype your password. Letters in passwords must be typed using the correct case"));
      }
      if (error.message === "EMAIL_DUPLICATED") {
        message.error(translate("signup.warning.registered", "E-mail address already been used !"));
      }
    }
  };

  return (
    <div>
      <PageContainer>
        <PageHeader onBack={() => history.goBack()} title={translate("useredit", "Edit Account")} />
        <PageContent>
          <Wrapper>
            <div className="body">
              <Alert className="alert" message={translate("system.email.changing", "After changing E-mail any further contacts concerning our service will be sent to your new E-mail")} type="warning" />
              <Formik
                initialValues={data}
                validationSchema={() => FormSchema(translate)}
                onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                  <Form layout="vertical">
                    <hr/>
                    <FormItem label={translate("agency.email", "Email")} name="email" required>
                      <Input name="email" type="email" placeholder={translate("agency.email", "Email")} disabled />
                    </FormItem>

                    <FormItem label={translate("system.email.change", "Email change")} name="newEmail" required>
                      <Input name="newEmail" type="email" placeholder={translate("system.email.change", "Email change")} />
                    </FormItem>

                    <FormItem label={translate("login.password", "Password")} name="password" required>
                      <Input name="password" type="password" placeholder={translate("login.password", "Password")} />
                    </FormItem>

                    <AntForm.Item>
                      <Button htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.change", "Change")}</Button>
                    </AntForm.Item>
                  </Form>
                )}
              </Formik>
            </div>
          </Wrapper>
        </PageContent>
      </PageContainer>
    </div>
  );
};

const Wrapper = styled.div`
  display :flex;
  justify-content : center;

  .body {
    width :500px;
  }
  .alert {
    margin-bottom :20px;
  }
`;
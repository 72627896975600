import React from "react";
import parse from "html-react-parser";
import styled from "styled-components";

export default ({ values: { characters = [], text } }) => {

  const renderEmoji = (emoji) => {
    if (emoji.productId && emoji.emojiId)
      return `<span style='background: url("/emoji/${emoji.productId}/${emoji.emojiId}.png") center center / cover no-repeat; color: transparent;'>${String.fromCodePoint(9940)}</span>`;
    return `${emoji[0]}`;
  };

  const renderCharacters = () => {
    if (!characters || characters.length === 0)
      return text;

    let html = characters.reduce((accumulator, iterator) => {
      return accumulator + ((typeof iterator === "string" || iterator instanceof String) ? iterator : renderEmoji(iterator));
    }, "");

    return parse(html);
  };

  return (
    <TextContainer>
      {renderCharacters()}
    </TextContainer>
  );
};

const TextContainer = styled.div`
  white-space: pre-wrap;
  pointer-events:none;

  -webkit-touch-callout: none;
  -webkit-user-select: none; 
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import colors from "../../colors";
import { useHistory } from "react-router";
import { useTranslate } from "../../hooks";
import { Button, Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Form, FormItem, Input, Select, Checkbox } from "formik-antd";



const FormSchema =(translate, type) => Yup.object({
  name       : Yup.string().required(translate("system.message.required", "required!")),
  email      : Yup.string().required(translate("system.message.required", "required!")),
  companyName: Yup.string().required(translate("system.message.required", "required!")),
  permission : type !== "settings"?Yup.string().required(translate("system.message.required", "required!")):Yup.string().optional(),
  branchName : type === "settings"?Yup.string().required(translate("system.message.required", "required!")):Yup.string().optional(),
  active     : type !== "settings"?Yup.bool().required(translate("system.message.required", "required!")):Yup.string().optional(),
});

const { Option } = Select;

export default ({ onSubmit, action, type })=> {
  const history = useHistory();
  const { translate } = useTranslate();
  const [data] = React.useState({
    active     : false,
    name       : undefined,
    email      : undefined,
    companyName: undefined,
    permission : undefined,
    branchName : undefined,
    ...(action&&action[0] === "update"?action[1]:{})
  });

  console.log("action => ", action);

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={()=>FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout} layout="vertical">

            {type === "settings"&&(
              <FormItem label={translate("staff.branchName", "Branch name")} name="branchName" required>
                <Input name="branchName" placeholder={translate("staff.branchName.placeholder", "Branch name")} />
              </FormItem>
            )}
            <FormItem label={translate("staff.firstname", "Name")} name="name" required>
              <Input name="name" placeholder={translate("staff.firstname.placeholder", "Name")} />
            </FormItem>

            <FormItem disabled={action&&action[0] === "update"} label={translate("staff.email", "Email")} name="email" required>
              <Input disabled={type ==="settings" || action&&action[0] === "update"} name="email" placeholder={translate("staff.email.placeholder", "Email")} />
            </FormItem>

            <FormItem label={translate("staff.company", "Company name")} name="companyName" required>
              <Input name="companyName" placeholder={translate("staff.company.placeholder", "Company name")} />
            </FormItem>

            {type !== "settings"&&(
              <FormItem label={translate("staff.role", "Role")} name="permission" required>
                <Select placeholder={translate("staff.role.select", "Select role")} name="permission">
                  <Option value="ADMIN">{translate("staff.role.admin", "Admin")}</Option>
                  <Option value="STAFF">{translate("staff.role.staff", "Staff")}</Option>
                  <Option value="NO_MESSAGE">{translate("staff.role.nomessage", "Staff(No message)")}</Option>
                  <Option value="NO_REPORT">{translate("staff.role.noreport", "Staff(No reports)")}</Option>
                  <Option value="SUPPORT">{translate("staff.role.support", "Customer Support")}</Option>
                </Select>
              </FormItem>

            )}
            {
              type !== "settings"&&(
                <FormItem name="active" required>
                  <Checkbox name="active" placeholder={translate("staff.active", "Active")}>{translate("staff.active", "Active")}</Checkbox>
                </FormItem>
              )
            }

            <AntForm.Item style={{ marginTop: 0 }}>
              <Button loading={isSubmitting} htmlType="submit" type="primary" style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              <Button type="default" onClick={()=>{history.goBack();}} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};
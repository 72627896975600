import request from "../utils/request";

export const copy = (id) => request.post(`/api/trigger/${id}/copy`);
export const create = (data) => request.post("/api/trigger", data);
export const update = (id, data) => request.put(`/api/trigger/${id}`, data);
export const get = (id) => request.get(`/api/trigger/${id}`);
export const remove = (id) => request.del(`/api/trigger/${id}`);
export const list = (data) => request.get("/api/trigger", data);
export const report = (data) => request.get("/api/trigger/report", data);
export const setEvent = (id, data) => request.post(`/api/trigger/${id}/event`, data);
export const stopEvent = (id) => request.post(`/api/trigger/event/${id}/stop`);
export const hide = (id) => request.post(`/api/trigger/${id}/hide`
);






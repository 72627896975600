import React from "react";
import { Checkbox, Form, FormItem, Input } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslate } from "../../hooks";

import { message, Button } from "antd";
import { news } from "../../apis";
import { useHistory } from "react-router-dom";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import styled from "styled-components";
import colors from "../../colors";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const FormSchema = (translate) => Yup.object({
  title: Yup.string().required(translate("system.message.required", "This field is required!")),
  desc : Yup.string().required(translate("system.message.required", "This field is required!")),
});


export default ({ action }) => {
  const { translate } = useTranslate();
  const [loading, setLoading] = React.useState();
  const history = useHistory();
  const [data] = React.useState({
    title   : "",
    desc    : "",
    body    : "",
    hide    : false,
    priority: false,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = async (values) => {
    setLoading(true);

    if (action && action[0] === "update")
      try {
        await news.update({ _id: data._id, ...values });
        setLoading(false);
        message.success(translate("system.message.success", "Success"));
        history.goBack();
      } catch (error) {
        if (error.message.body.type === "MAX_LENGTH") {
          message.error(translate("news.alert", "The number of characters have exceeded 4000!"));
        }
        else {
          message.error("Error");
        }
        setLoading(false);
      }
    else {
      try {
        await news.create(values);
        setLoading(false);
        message.success(translate("system.message.success", "Success"));
        history.goBack();
      } catch (error) {
        if (error.message.body.type === "MAX_LENGTH") {
          message.error(translate("news.alert", "The number of characters have exceeded 4000!"));
        }
        else {
          message.error("Error");
        }
        setLoading(false);
      }
    }
  };

  const remove = () => {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("system.confirmation.question", "Are you sure ?"),
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      async onOk() {
        try {
          await news.remove(data._id);
          history.goBack();
          message.success(translate("system.message.success", "Successful"));
        } catch (error) {
          message.error(translate("system.message.error", "News not found"));
        }

      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ setFieldValue }) => {
        return (
          <PageContainer>
            <Form layout={"vertical"}>
              <PageHeader onBack={() => history.goBack()} title={translate("dashboard.announcements", "Announcements")} extra={[
                <Button key={1} type="default" loading={loading} onClick={() => history.goBack()}>{translate("system.cancel", "Cancel")}</Button>,
                action && action[0] === "update" ? <Button key={2} type="danger" loading={loading} onClick={() => remove()}>{translate("system.delete", "Remove")}</Button> : null,
                <Button key={3} type="primary" htmlType="submit" loading={loading}>{translate("system.save", "Save")}</Button>
              ]} />
              <PageContent style={{ justifyContent: "center", display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <FormItem label={translate("system.title", "Title")} name="title" required>
                    <Input name="title" placeholder={translate("system.title", "Title")} />
                  </FormItem>
                  <FormItem label={translate("announcement.description", "Description")} name="desc" required>
                    <Input name="desc" placeholder={translate("announcement.description", "description")} />
                  </FormItem>
                  <GroupCheckbox>
                    <FormItem name="priority" required>
                      <Checkbox name="priority" placeholder={translate("news.priority.label", "Show on top")} >{translate("news.priority.label", "Show on top")}</Checkbox>
                    </FormItem>
                    <FormItem name="hide" required>
                      <Checkbox name="hide" >{translate("news.hide", "Hide")}</Checkbox>
                    </FormItem>
                  </GroupCheckbox>
                  <FormItem label={translate("announcement.contents", "Contents")} name="body" required>
                    <SunEditor
                      name="body"
                      height="400px"
                      enableToolbar={true}
                      onChange={(e) => setFieldValue("body", e)}
                      setContents={data.body}
                    />
                  </FormItem>
                </div>
              </PageContent>
            </Form>
          </PageContainer>
        );
      }}
    </Formik>
  );
};

const GroupCheckbox = styled.div`

  display:flex;
  

`;
import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

export default () => {
  // document.getElementById("scrollControl").className = "showBar";
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry!, Page not found."
      extra={<Button type="primary" onClick={() => history.push("/")}>Нүүр хуудас</Button>}
    />
  );
};

import React from "react";
import { Form, Select, Input, FormItem, Checkbox } from "formik-antd";
import { Formik, useFormikContext, ErrorMessage } from "formik";
import { Collapse, Modal, Button, Input as InputAnt, Tooltip, Alert } from "antd";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useFetch, useTranslate } from "../../../hooks";
import { cooperation as cooperationApi, coupon as couponApi } from "../../../apis";
import CouponList from "../../Coupon/SelectList";
import SelectList from "../../Coupon/SelectList";
import { TaggedUrl, URL_REGEX } from "../../Imagemap/image/components/Form";
import { useHistory } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";

const ActionFormSchema = (translate) => Yup.object({
  type: Yup.string().required(translate("system.message.required", "Required!")),

  uri: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).when("type", {
    is  : "uri",
    then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
  }),
  lineBasicId: Yup.string().when("type", {
    is  : "share",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  label: Yup.string().when("type", {
    is  : "message",
    then: Yup.string().max(20, translate("system.message.max20", "20文字以内")).required(translate("system.message.required", "Required!"))
  }),
  text: Yup.string().when("type", {
    is  : "message",
    then: Yup.string().max(250, translate("system.message.max250", "Max 250 character!")).required(translate("system.message.required", "Required!"))
  }),
  cooperation: Yup.string().when("type", {
    is  : "cooperation",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  couponCode: Yup.string().when("type", {
    is  : "coupon",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  isTagged : Yup.boolean().nullable(),
  taggedUrl: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!"))
    .when("type", {
      is  : "uri",
      then: Yup.string().when("isTagged", {
        is  : true,
        then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
      }).nullable()
    }).when("type", {
      is  : "coupon",
      then: Yup.string().when("isTagged", {
        is  : true,
        then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
      }).nullable()
    }).nullable(),
});

const FormSchema = (translate)=> Yup.object({
  actions: Yup.array().of(ActionFormSchema(translate))
});

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values, setValues } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    },
    async resetForm() {
      setValues({ ...values, actions: [] });
    }
  }));

  return null;
});

const INITIALIZE_DATA = {
  name       : undefined,
  chatBarText: undefined,
};

const INITIALIZE_ACTION_DATA = {
  type           : undefined,
  uri            : undefined,
  label          : undefined,
  text           : undefined,
  cooperation    : undefined,
  couponCode     : undefined,
  taggedUrl      : undefined,
  isTagged       : false,
  tagType        : 0,
  externalBrowser: false
};

export default React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const [action, setAction] = React.useState([]);
  const { type, template, editable, onSubmit, openCollapse } = props;
  const { richMenuTemplates, richMenuActions, account } = useSelector(state => state.general);
  const [collapse, setCollapse] = React.useState(openCollapse);
  const [cooperations] = useFetch(cooperationApi.list)([]);
  const submitRef = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [query, setQuery] = React.useState("");
  const [data, setFormData] = React.useState({
    ...INITIALIZE_DATA,
    actions: richMenuTemplates[type][template].map(() => ({ ...INITIALIZE_ACTION_DATA })),
    ...(editable && (editable[0] === "update" || editable[0] === "copy") ? editable[1]: {})
  });


  const onChangeCollapse = (key) => {
    setCollapse(key);
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();

      return isValid;
    },

    async clear() {
      await submitRef.current.resetForm();
    }
  }));
  const onClearTaggedUrl = (setFieldValue, index, e) => {
    if (!e){
      setFieldValue(`actions[${index}].isTagged`, false);
      setFieldValue(`actions[${index}].tagType`, 0);
      setFieldValue(`actions[${index}].taggedUrl`, undefined);
    }
    if (!e?.target?.value || e?.target?.value === ""){
      setFieldValue(`actions[${index}].isTagged`, false);
      setFieldValue(`actions[${index}].tagType`, 0);
      setFieldValue(`actions[${index}].taggedUrl`, undefined);
    }
    if (e?.target?.value && (e?.target?.value.includes("mailto:") || e?.target?.value.includes("tel:"))){
      setFieldValue(`actions[${index}].externalBrowser`, false);
    }
  };

  const externalBrowserDisabled = (value) => {
    if (!value) return false;
    return value.includes("mailto:") || value.includes("tel:");
  };

  const renderField = (value, index, setFieldValue, values, errors, setFieldTouched) => {
    switch (value.type) {
      case "message":
        return (
          <>
            <FormItem label={translate("richmenu.name", "Name")} name={`actions[${index}].label`} required>
              <Input name={`actions[${index}].label`} placeholder="..." />
            </FormItem>
            <FormItem label={translate("richmenu.action.message", "Message")} name={`actions[${index}].text`} required>
              <TextArea name={`actions[${index}].text`} placeholder="..." />
            </FormItem>
          </>
        );
      case "uri":
        return (
          <>
            <FormItem label={translate("richmenu.action.uri", "Link")} name={`actions[${index}].uri`} onChange={(e) => onClearTaggedUrl(setFieldValue, index, e)} required>
              <Input name={`actions[${index}].uri`} placeholder="https://... , tel:0000000000, mailto:example@example.com" />
            </FormItem>

            <FormItem name={`actions[${index}].externalBrowser`} required>
              <Checkbox disabled={externalBrowserDisabled(values?.actions[index]?.uri)} name={`actions[${index}].externalBrowser`}>{translate("richmenu.external.browser", "Open in external browser")}</Checkbox>
              {(values?.actions[index]?.uri?.length > 900 && values?.actions[index].externalBrowser) &&
                <Alert style={{ marginTop: 5 }} type="warning" message={
                  <div>
                    {translate("actions.weblink.length.alert", "Link length maybe a too long to be processed by Line!")}
                  </div>
                }
                />}
            </FormItem>
            <FormItem name={`actions[${index}].isTagged`} required>
              <Checkbox onChange={() => {
                setFieldValue(`actions[${index}].tagType`, 0);
                setFieldValue(`actions[${index}].taggedUrl`, undefined);
              }} disabled={!values.actions[index].uri || !account?.ecmid} name={`actions[${index}].isTagged`}>{
                  <div>
                    { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                    <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
            </FormItem>
            <TaggedUrl values={values} index={index} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} tagType={`actions[${index}].tagType`} taggedUrl={`actions[${index}].taggedUrl`}/>
          </>
        );
      case "cooperation":
        return (
          <>
            <FormItem label={translate("cooperationform.title", "Cooperation form")} name={`actions[${index}].cooperation`} required>
              <Select name={`actions[${index}].cooperation`} placeholder={translate("system.select", "Select")} style={{ width: "100%" }}>
                {cooperations.filter(c => c.template).map((item, index2) => {
                  return item.link ? <Option key={index2} value={item._id}>{translate(`cooperation.${item.code}`, item.name)}</Option>
                    : <Option disabled key={index2}>{translate("rakuten.noform", "No rakuten form")}</Option>;
                })}
              </Select>
            </FormItem>
          </>
        );
      case "coupon":
        return (
          <>
            <FormItem name={`actions[${index}].couponCode`}>
              <SelectList onChange={(e) => onClearTaggedUrl(setFieldValue, index, e)} name={`actions[${index}].couponCode`} urlField={`actions[${index}].uri`}/>
            </FormItem>
            <FormItem style = {{ marginTop: "10px" }} name={`actions[${index}].additionalParameter`} label={translate("imagemap.additional.parameter", "Additional Parameter")} >
              <Input name={`actions[${index}].additionalParameter`} placeholder={translate("imagemap.additional.parameter.placeholder", "Additional Parameter")} />
            </FormItem>
            <FormItem style = {{ marginTop: "10px" }} name={`actions[${index}].externalBrowser`} required>
              <Checkbox name={`actions[${index}].externalBrowser`}>{translate("richmenu.external.browser", "Open in external browser")}</Checkbox>
            </FormItem>
            <FormItem name={`actions[${index}].isTagged`} required>
              <Checkbox onChange={() => {
                setFieldValue(`actions[${index}].tagType`, 0);
                setFieldValue(`actions[${index}].taggedUrl`, undefined);
              }} disabled={!values.actions[index].couponCode || !account?.ecmid} name={`actions[${index}].isTagged`}>{
                  <div>
                    { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                    <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
            </FormItem>
            <TaggedUrl values={values} index={index} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} tagType={`actions[${index}].tagType`} taggedUrl={`actions[${index}].taggedUrl`}/>
          </>
        );
      case "share":
        return (
          <>
            <FormItem label={translate("richmenu.action.share", "Share")} name={`actions[${index}].lineBasicId`} required>
              <Input name={`actions[${index}].lineBasicId`} placeholder={`${account?.lineBasicId}`} />
            </FormItem>
          </>
        );
      default:
        return null;
    }
  };

  const actionChange = (e, values, setValues, index) => {
    let copyData = [...values.actions];
    if (e === "share")
      copyData[index] = {
        ...values.actions[index],
        couponCode         : undefined,
        lineBasicId        : account?.lineBasicId,
        uri                : undefined,
        label              : undefined,
        text               : undefined,
        cooperations       : undefined,
        type               : e,
        externalBrowser    : undefined,
        isTagged           : false,
        taggedUrl          : undefined,
        additionalParameter: undefined,
        tagType            : 0
      };
    else
      copyData[index] = {
        ...values.actions[index],
        couponCode         : undefined,
        lineBasicId        : undefined,
        uri                : undefined,
        externalBrowser    : false,
        additionalParameter: undefined,
        label              : undefined,
        text               : undefined,
        cooperations       : undefined,
        type               : e,
        isTagged           : false,
        taggedUrl          : undefined,
        tagType            : 0 };

    setValues({ ...values, actions: [...copyData] });
  };

  React.useEffect(() => {
    setFormData({
      ...INITIALIZE_DATA,
      actions: richMenuTemplates[type][template].map(() => ({ ...INITIALIZE_ACTION_DATA })),
      ...(editable && (editable[0] === "update" || editable[0] === "copy") ? editable[1]: {})
    });

  }, [type, template, editable, richMenuTemplates]);

  React.useEffect(() => {
    setCollapse(openCollapse);
  }, [openCollapse]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (query === "")
        dispatch({ type: "general.richmenuSelect.text", payload: data.chatBarText });
      else
        dispatch({ type: "general.richmenuSelect.text", payload: query });
    }, 300);

    return () => clearTimeout(timer);
  }, [dispatch, data, query]);

  return (
    <RichFormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ values, setValues, setFieldValue, errors, setFieldTouched }) => {
          return (
            <Form layout="vertical">
              <Collapse activeKey={collapse} onChange={onChangeCollapse}>
                {richMenuTemplates[type][template].map((template, index) => {
                  return (
                    <Panel key={template} header={template}>
                      <FormItem label={translate("richmenu.action", "Action type")} name={`actions[${index}].type`} required>
                        <Select name={`actions[${index}].type`} placeholder={translate("system.select", "Select")} style={{ width: "100%" }} onChange={(e)=>actionChange(e, values, setValues, index)}>
                          {richMenuActions.map((type, index2) => {
                            return <Option key={index2} value={type.code}>{translate(`richmenu.action.${type.code}`, type.name)}</Option>;
                          })}
                        </Select>
                      </FormItem>

                      {values && values.actions && values.actions[index] && renderField(values.actions[index], index, setFieldValue, values, errors, setFieldTouched)}
                    </Panel>
                  );
                })}
              </Collapse>

              <SubmitForm ref={submitRef} />
            </Form>
          );
        }}
      </Formik>
    </RichFormWrapper>
  );
});

const ValidButton = styled(Button)`
  &.error {
      border-color: red;
  }
`;
const RichFormWrapper = styled.div`
  .ant-collapse-header {
    padding: 6px 16px 6px 40px!important; 
    .ant-collapse-arrow {
      top: 11px!important;
    }
  }
`;
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { Children, Step, Trigger } from "./shapes";
import Draggable from "../Draggable";
import Empty from "./shapes/Empty";
import styled from "styled-components";
import colors from "../../colors";
import { StepMenu } from "./shapes/Step";
import { StartStepMenu } from "./shapes/StartStep";
import { EndStepMenu } from "./shapes/EndStep";
import { ActionMenu } from "./shapes/Action";
import { TagMenu } from "./shapes/Tag";
import { ConditionMenu } from "./shapes/Condition";
import { TimerMenu } from "./shapes/Timer";
import { NextStepMenu } from "./shapes/NextStep";
import { DeleteOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { useTranslate } from "../../hooks";
import { WhenMenu } from "./shapes/When";
import Judgement from "./shapes/Judgement";
import ZoomInImage from "../../assets/zoom-in.svg";
import ZoomOutImage from "../../assets/zoom-out.svg";
import MenuImage from "../../assets/menu.svg";

const getBoundRectHeight = ({ children }) => {
  return children?.reduce((accumulator, iterator) => {
    switch (iterator.type) {
      case "trigger":
        return accumulator + getBoundRectHeight({ children: iterator.children }) + 48;
      case "step":
        return accumulator + getBoundRectHeight({ children: iterator.children }) + 84;
      case "next":
      case "start":
      case "end":
      case "timer":
      case "tag":
      case "action": {
        return accumulator + 48;
      }
      case "condition": {
        let conditionHeight = 196 - 8;

        if (iterator.children.filter(child => child.condition === "then").length > 0)
          conditionHeight -= 40;

        if (iterator.children.filter(child => child.condition === "catch").length > 0)
          conditionHeight -= 40;

        return accumulator + getBoundRectHeight({ children: iterator.children }) + conditionHeight;
      }
      default:
        return accumulator;
    }
  }, 0);
};

export default ({ height, width, type }) => {
  const dispatch = useDispatch();
  const processDrawRef = React.useRef();
  const processDragRef = React.useRef();
  const { translate } = useTranslate();
  const [scroll, setScroll] = React.useState({ top: 0, left: 0 });
  const { children, index, dragging, scale, width: w, height: h } = useSelector(state => state.process);

  const onDragEnd = ({ x: positionX, y: positionY }) => {
    let x = positionX;
    let y = positionY;

    let childrenHeight = getBoundRectHeight({ children: dragging.children });

    x = x < 0 ? 0 : x;
    y = y < 0 ? 0 : y;
    x = x > width - 240 ? width - 240 - 157 : x;
    y = y > height ? height - childrenHeight : y;

    if (dragging && dragging.children[0] && dragging.children[0].type === "step") {
      if (children.filter(child => child.type === "step").length >= 7) {
        message.warning(translate("scneario.form.step.count", "Amount of steps you can use at for a scenario set is seven at most!"));
        dispatch({
          type   : "process.normalize",
          payload: {
            type: "clear"
          }
        });

        return;
      }
    }

    dispatch({
      type   : "process.normalize",
      payload: {
        x: (x + scroll.left) * (1 / scale),
        y: (y + scroll.top) * (1 / scale)
      }
    });
  };

  const onMouseEnter = (e, ui) => {
    dispatch({
      type   : "process.enter",
      payload: ui
    });
  };

  const onZoom = (zoom) => {
    if (zoom < 0 && w * scale < (width - 240) && h * scale < height)
      return;

    if (zoom > 0 && (scale + zoom) > 2)
      return;

    dispatch({
      type   : "process.scale",
      payload: zoom === 0 ? 0.7 : (scale + zoom)
    });
  };

  const onScroll = () => {
    processDragRef.current.scrollTop = processDrawRef.current.scrollTop;
    processDragRef.current.scrollLeft = processDrawRef.current.scrollLeft;

    setScroll({
      top : processDrawRef.current.scrollTop,
      left: processDrawRef.current.scrollLeft
    });
  };

  const Render = ({ index }) => {
    return (
      <>
        {children.map((child, i) => {
          if (child.type === "empty")
            return (
              <Empty key={i} child={child}>
                <Children children={child.children} />
              </Empty>
            );

          if (child.type === "judgement")
            return (
              <Judgement key={i} index={index} child={child} dragging={[child]} transform={`translate(${child.x || 50}, ${child.y || 50})`} />
            );

          if (child.type === "trigger")
            return (
              <Trigger key={i} index={index} step={child.index} child={child} dragging={[child]} transform={`translate(${child.x}, ${child.y})`}>
                <Children step={child.index} children={child.children} />
              </Trigger>
            );

          return (
            <Step key={i} index={index} step={child.index} child={child} dragging={[child]} transform={`translate(${child.x}, ${child.y})`}>
              <Children step={child.index} children={child.children} />
            </Step>
          );
        })}
      </>
    );
  };

  return (
    <Container>
      <div className="process-shape">
        <div className="shape-header">
          <span>{translate("scenario.form.motion", "Parts")}</span> <div className="line" />
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="240" height="100%">
          <g transform="translate(15, 15) scale(0.7)">

            {type === "trigger"?(
              <>
                <ActionMenu x={0} y={20} />
                <TagMenu x={0} y={100} />
                <WhenMenu x={0} y={180} />
              </>
            ) : (
              <>
                <StepMenu x={0} y={20} />
                <StartStepMenu x={0} y={88} />
                <EndStepMenu x={0} y={156} />
                <ActionMenu x={0} y={224} />
                <TagMenu x={0} y={290} />
                <NextStepMenu x={0} y={360} />
                <TimerMenu x={0} y={428} />
                <ConditionMenu x={0} y={496} />
              </>
            )}
          </g>
        </svg>

        {dragging && (
          <div className="shape-trash">
            <DeleteOutlined />
          </div>
        )}
      </div>

      <div className="process-draw" style={{ height, width: width - 240 }} ref={processDrawRef} onScroll={onScroll}>
        <svg xmlns="http://www.w3.org/2000/svg" width={w * scale} height={h * scale}>
          <defs>
            <pattern id="pattern" patternUnits="userSpaceOnUse" width={27} height={27} x="519.6672351837159" y="546.9749999999958">
              <line stroke="#ddd" strokeWidth="0.675" x1="13.162500000000001" y1="13.8375" x2="14.512500000000001" y2="13.8375" />
              <line stroke="#ddd" strokeWidth="0.675" x1="13.8375" y1="13.162500000000001" x2="13.8375" y2="14.512500000000001" />
            </pattern>
            <filter id="selected" height="160%" width="180%" y="-30%" x="-40%">
              <feGaussianBlur in="SourceGraphic" stdDeviation="4"></feGaussianBlur>
              <feComponentTransfer result="outBlur">
                <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1"></feFuncA>
              </feComponentTransfer>
              <feFlood flood-color="#FFF200" flood-opacity="1" result="outColor"></feFlood>
              <feComposite in="outColor" in2="outBlur" operator="in" result="outGlow"></feComposite>
              <feComposite in="SourceGraphic" in2="outGlow" operator="over"></feComposite>
            </filter>
          </defs>

          <g transform={`scale(${scale})`}>
            <rect height="100%" width="100%" fill="url(#pattern)" />
            <Render index={index} />
          </g>
        </svg>

        <div className="zoom-control">
          <img width="30" height="30" onClick={() => onZoom(0.1)} src={ZoomInImage} />
          <img width="30" height="30" onClick={() => onZoom(-0.1)} src={ZoomOutImage} />
          <img width="30" height="30" onClick={() => onZoom(0)} src={MenuImage} />
        </div>
      </div>

      <div className="process-drag" style={{ height, width: width + 10, zIndex: dragging ? 1 : -1 }} ref={processDragRef}>
        <svg xmlns="http://www.w3.org/2000/svg" width={w * scale + 240} height={h * scale}>
          <defs>
            <filter id="shadow">
              <feGaussianBlur in="SourceAlpha" stdDeviation="6"></feGaussianBlur>
              <feComponentTransfer result="offsetBlur">
                <feFuncA slope="0.6" type="linear"></feFuncA>
              </feComponentTransfer>
              <feComposite in="SourceGraphic" in2="offsetBlur"></feComposite>
            </filter>
          </defs>

          <rect height="100%" width="100%" onMouseEnter={e => onMouseEnter(e, null)} fill="transparent" />
          <rect height="100%" width="240" onMouseEnter={e => onMouseEnter(e, { type: "clear" })} fill="transparent" />

          <g transform={"translate(253, 1)"}>
            <g transform={`scale(${scale})`}>
              <Render index={index} />
            </g>
          </g>

          {dragging && (
            <Draggable x={dragging.x} y={dragging.y} scroll={scroll} child={dragging?.children[0]} onDragEnd={onDragEnd}>
              <g filter="url(#shadow)" transform={`scale(${scale})`}>
                <Children children={dragging.children} draggable={true} />
              </g>
            </Draggable>
          )}
        </svg>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .zoom-control {
    position: absolute;
    bottom: 20px;
    right: 40px;
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 8px;
      cursor: pointer;
    }
  }

  .process-drag {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
  }
  .process-shape {
    display: flex;
    flex-direction: column;
    border: solid 1px #ECECEC;
    border-radius : 8px;
    margin-right: 10px;
    .shape-header {
      font-size: 15px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      span {
        margin-right: 5px;
      }
      .line {
        border-top: solid 1px ${colors.primaryDark};
        width: 100%;
        height: 0px;
      }
    }
    .shape-trash {
      text-align: center;
      width: 100%;
      padding: 20px;
      font-size: 29px;
      color: #fd6680;
      background: #ffd5dc;
    }
  }
  .process-draw {
    border:solid 1px #ECECEC;
    border-radius : 8px;
    margin-right: 10px;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.primaryDark}; 
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${colors.primary}; 
    }
  }
`;
import React from "react";
import Form from "./Form";
import { Button, Col, message, Row } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { staff as staffApi, user as userApi } from "../../apis";
import { useFetch, useTranslate } from "../../hooks";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Modal from "antd/lib/modal/Modal";
import PasswordForm from "../Admin/components/PasswordForm";

export default ()=> {
  const history = useHistory();
  const { translate } = useTranslate();
  const { user } =useSelector(state => state.general);
  const [password, setPassword] = React.useState(null);
  const [staff, fetching] = useFetch(staffApi.get, user._id)({});

  const onSubmit = async (data)=> {
    await staffApi.update(data);
    message.success(translate("system.message.success", "Success"));
  };

  const onChangePasword = () => {
    setPassword(["create"]);
  };
  const onCancel = () => {
    setPassword(null);
  };

  const onSubmitPassword = async (data) => {
    try {
      await userApi.changePassword({ newPassword: data.newPassword, password: data.password });
      message.success(translate("system.message.success", "Password has been changed successfully."));
      setPassword();
    } catch (error) {
      if (error.message === "INVALID_PASSWORD") {
        message.error(translate("system.invalid.password", "The password is incorrect. Retype your password. Letters in passwords must be typed using the correct case"));
      }
      else {
        message.error(translate("system.error", error.error));
      }
    }
  };


  return (
    <PageContainer>
      <PageHeader onBack={()=>history.goBack()} title={translate("system.settings", "Settings")} />
      <PageContent>
        <Container>
          <div>
            <div className="header">
              {translate("", "Staff information")}
            </div>
            <Row>
              <Col span={16}>
                <div style={{ marginBottom: 20 }}>
                  <Button type="default" onClick={onChangePasword} block>{translate("useredit.password", "Change Password")}</Button>
                </div>
              </Col>
            </Row>
            <div className="form">
              {!fetching&&<Form type="settings" action={["update", staff]} onSubmit={onSubmit}/>}
            </div>
          </div>

        </Container>
      </PageContent>

      <Modal
        maskClosable={false}
        title={translate("useredit.password", "Change password")}
        visible={!!password}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <PasswordForm onCancel={onCancel} onSubmit={onSubmitPassword} action={password} />
      </Modal>


    </PageContainer>
  );
};

const Container = styled.div`
  display:flex;
  justify-content: center;
  width:100%;
  .form {
    width : 600px;
  }
  .header{
    width: 66.5%;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 500;
    background: #e7e7e7;
    padding: 4px 8px;
    border-radius: 2px;
    color: #009d4b;
  }
`;
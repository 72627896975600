import React from "react";
import { message, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { plan as planService } from "../../../../apis";
import { MyTable } from "../../../../components";
import { useTranslate } from "../../../../hooks";

export default ({ customers }) => {
  const history = useHistory();
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const [load, setLoad] = React.useState(false);
  const [data, setData] = React.useState([]);
  const planFilter = (id) => {
    let res = data.filter(item => {
      return item._id === id;
    });
    return res[0];
  };

  const columns = useHeader({
    history,
    tableTitles: {
      customers: translate("dashboard.customers", "Customer name"),
      plan     : translate("dashboard.customersplan", "Current plan"),
      balance  : translate("dashboard.messagebalance", "Message limit"),
    },
    planFilter
  });
  const getPlan = async () => {
    try {
      setLoad(true);
      let res = await planService.list({ filter: { orderName: null, orderBy: null, query: null }, offset: { page: 1, limit: 30 } });
      setData(res && res.rows);
      setLoad(false);
    } catch (error) {
      message.error(error.message);
      setLoad(false);
    }
  };
  React.useEffect(() => {
    getPlan();
  }, []);

  return (
    <>
      <Spin spinning={load}>
        <MyTable
          ref={myTableRef}
          rowKey={(record) => record._id}
          columns={columns}
          dataSource={customers}
        />
      </Spin>
    </>
  );
};

const useHeader = ({ onAction, tableTitles, planFilter }) => {
  return [{
    title    : tableTitles.customers,
    dataIndex: "companyName",
  }, {
    title : tableTitles.plan,
    render: (item) => {
      let res = planFilter(item.plan);
      return (
        <div>{res&&res.name}</div>
      );
    }
  }, {
    title    : tableTitles.balance,
    dataIndex: "messageBalance",
  }, ];
};
import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/Layout";
import styled from "styled-components";
import { Alert, Button, DatePicker, Modal, Radio, Space, message } from "antd";
import { VisitorGraph } from "./VisitorGraph";
import { ColumnChart } from "./ColumnChart";
import { ReportTable } from "./ReportTable";
import { BoxList } from "./BoxList";
import { SalesGraph } from "./SalesGraph";
import moment from "moment";
import { ecm as ecmApi } from "../../apis";
import { useTranslate } from "../../hooks";

const RmsReport = memo(() => {
  const [monthReport, setMonthReport] = useState();
  const [rmsReport, setRmsReport] = useState({
    visits        : [],
    visits_cvr    : [],
    oa            : [],
    gms           : [],
    gms_conversion: [],
  });
  const [radioValue, setRadioValue] = useState("CURRENT");
  const [radioValue2, setRadioValue2] = useState("CURRENT");
  const [radioValue3, setRadioValue3] = useState("MONTH");
  const [dispType, setDispType] = useState(0);
  const { translate } = useTranslate();
  const [filter, setFilter] = useState(moment().tz("Asia/Tokyo"));
  const [filters, setFilters] = useState({
    start_date : moment().tz("Asia/Tokyo").startOf("month"),
    end_date   : moment().tz("Asia/Tokyo").endOf("month"),
    start_month: moment().tz("Asia/Tokyo").subtract(6, "month").startOf("month"),
    end_month  : moment().tz("Asia/Tokyo")
  });
  const [error, setError] = useState(null);
  const options = [
    { label: "当月", value: "CURRENT" },
    { label: "前月", value: "LAST" },
  ];

  const onSubmit = async () => {
    try {
      const res = await ecmApi.getMonthReport({ targetmonth: filter.format("YYYY-MM") });
      setMonthReport(res);

    } catch (err) {
      console.log(err);
      let error = {};
      error = {
        code   : err.status_code,
        message: err.message
      };
      setError(error);
    }
  };

  const onClear = () => {
    setFilter(moment().tz("Asia/Tokyo"));
    setRadioValue("CURRENT");
    onSubmit();
  };

  const onGetReport = async () => {
    if (dispType === 0) {
      let startdate = moment(filters.start_date);
      let enddate = moment(filters.end_date);
      if (enddate.diff(startdate, "days") > 60){
        message.error(translate("rms.report.invaliddate.date", "Set filter date range within 60 days!"));
        return;
      }
    }
    if (dispType === 1) {
      let startdate = moment(filters.start_month);
      let enddate = moment(filters.end_month);
      if (enddate.diff(startdate, "month") > 11){
        message.error(translate("rms.report.invaliddate.month", "Set filter date range within one year!"));
        return;
      }
    }
    try {
      const res = await ecmApi.getReport({
        disp_type  : dispType,
        start_date : filters?.start_date ? filters?.start_date.format("YYYY-MM-DD") : undefined,
        end_date   : filters?.end_date ? filters?.end_date.format("YYYY-MM-DD") : undefined,
        start_month: filters?.start_month ? filters?.start_month.format("YYYY-MM"): undefined,
        end_month  : filters?.end_month ? filters?.end_month.format("YYYY-MM"): undefined,
      });
      setRmsReport(res);
    } catch (err) {
      console.log(err);
      let error = {};
      error = {
        code   : err.status_code,
        message: err.message
      };
      setError(error);
    }
  };
  const onClearFilters = () => {
    setFilters({
      disp_type  : 0,
      start_date : moment().tz("Asia/Tokyo").startOf("month"),
      end_date   : moment().tz("Asia/Tokyo").endOf("month"),
      start_month: moment().tz("Asia/Tokyo").subtract(6, "month").startOf("month"),
      end_month  : moment().tz("Asia/Tokyo").endOf("month")
    });
    setDispType(0);
    setRadioValue2("CURRENT");
    setRadioValue3("MONTH");
    onGetReport();
  };

  useEffect(() => {
    console.log("on submit change");
    onSubmit();
    onGetReport();
  }, []);

  return (
    <PageContainer>
      <PageHeader title={"LINE経由の売上(RMS)"} />
      <PageContent>
        <Container>
          <div>
            計測タグ付きURLで取得した、LINE経由の楽天売上データをご確認いただけます。
          </div>
          <a href="https://sns.rms.rakuten.co.jp/#/summary" target="_blank" className="subtitle">RMSはこちら</a>
          {error && <Alert
            description={
              (<div>
                <div>{"Code : "}{error?.code},</div>
                <div> {"Message : "}{error?.message}</div>
                <div>ECマスターズクラブからのデータ取得に失敗しましたため、データが正しく表示できません。</div>
              </div>)
            }
            type="warning"
            showIcon={false}
          />}
          <div className="h30" />
          <div className="title">{filter.format("YYYY年MM月")} の月次主要指標</div>
          <div className="divider" />
          <div className="h20" />
          <div className="filter-container">
            <div className="row">
              <div className="row acenter">
                <div className="label">表示期間</div>
                <div className="w20" />
                <DatePicker
                  allowClear={false}
                  onChange={(e) => {
                    console.log("onchange", e);
                    setFilter(e);
                  }}
                  disabledDate={(current) => {
                    return current && current > moment().endOf("month");
                  }}
                  format={"YYYY年MM月"}
                  picker="month"
                  value={filter}
                />
              </div>
              <div className="w10" />
              <Radio.Group
                options={options}
                value={radioValue}
                optionType="button"
                onChange={(e) => {
                  setRadioValue(e.target.value);
                  if (e.target.value === "CURRENT"){
                    setFilter(moment().tz("Asia/Tokyo"));
                  } else {
                    setFilter(moment().tz("Asia/Tokyo").subtract(1, "month"));
                  }
                }}
                buttonStyle="solid"
              />
              <div className="w10" />
              <Button onClick={() => onClear()}>クリア</Button>
              <div className="w10" />
              <Button onClick={() => onSubmit()} type="primary">絞り込み</Button>
            </div>
          </div>
          <div className="h20" />
          <div className="divider mv-7" />
          <div className="title color-gray">売上の公式</div>
          <div className="divider mv-7" />
          <div className="title color-gray">データ対象期間：{filter.startOf("month").format("YYYY年MM月DD日")}〜{filter.endOf("month").format("YYYY年MM月DD日")}</div>
          <div className="h35" />
          <BoxList data={monthReport} />
          <div className="h30" />
          <div className="title">メッセージの効果推移</div>
          <div className="divider" />
          <div className="h20" />
          <div className="filter-container">
            <div className="label">表示期間</div>
            <div className="h10" />
            <Radio.Group
              value={dispType}
              onChange={(e) => {
                setDispType(e.target.value);
                setRadioValue2("CURRENT");
                setRadioValue3("MONTH");
                setFilters({
                  start_date : moment().tz("Asia/Tokyo").startOf("month"),
                  end_date   : moment().tz("Asia/Tokyo").endOf("month"),
                  start_month: moment().tz("Asia/Tokyo").subtract(6, "month").startOf("month"),
                  end_month  : moment().tz("Asia/Tokyo").endOf("month")
                });
              }}
            >
              <Space direction='vertical'>
                <Radio value={0}>
                  <div className="row">
                    <div className="label">日次</div>
                    <div className="row acenter">
                      <div className="w20" />
                      <DatePicker
                        allowClear={false}
                        disabledDate={(current) => {
                          return current > moment().endOf("day");
                        }}
                        format={"YYYY年MM月DD日"}
                        onChange={(e) => setFilters({ ...filters, start_date: e })}
                        disabled={dispType !== 0}
                        value={filters.start_date} />
                      <div className="space-character">~</div>{" "}
                      <DatePicker
                        allowClear={false}
                        disabledDate={(current) => {
                          const startDate = filters.start_date;
                          return current && (current < startDate || current > startDate.clone().add(60, "days").endOf("day"));
                        }}
                        format={"YYYY年MM月DD日"}
                        onChange={(e) => setFilters({ ...filters, end_date: e })}
                        disabled={dispType !== 0}
                        value={filters.end_date} />
                    </div>
                    <div className="w10" />
                    <Radio.Group
                      disabled={dispType !== 0}
                      options={options}
                      value={radioValue2}
                      optionType="button"
                      onChange={(e) => {
                        setRadioValue2(e.target.value);
                        if (e.target.value === "CURRENT"){
                          setFilters({
                            start_date: moment().tz("Asia/Tokyo").startOf("month"),
                            end_date  : moment().tz("Asia/Tokyo").endOf("month"),
                          });
                        } else {
                          setFilters({
                            start_date: moment().tz("Asia/Tokyo").subtract(1, "month").startOf("month"),
                            end_date  : moment().tz("Asia/Tokyo").subtract(1, "month").endOf("month"),
                          });
                        }
                      }}
                      buttonStyle="solid"
                    />
                  </div>
                </Radio>
                <div className="h5" />
                <Radio value={1}>
                  <div className="row">
                    <div className="label">月次</div>
                    <div className="row acenter">
                      <div className="w20" />
                      <DatePicker
                        allowClear={false}
                        disabledDate={(current) => {
                          return current > moment().startOf("month");
                        }}
                        onChange={(e) => setFilters({ ...filters, start_month: e })} disabled={dispType !== 1} format={"YYYY年MM月"} value={filters.start_month} picker="month"/> <div className="space-character">~</div>{" "}
                      <DatePicker
                        allowClear={false}
                        disabledDate={(current) => {
                          const startDate = filters.start_month;
                          return current && (current < startDate || current > startDate.clone().add(12, "month"));
                        }}
                        onChange={(e) => setFilters({ ...filters, end_month: e })} disabled={dispType !== 1} format={"YYYY年MM月"} value={filters.end_month} picker="month"/>
                    </div>
                    <div className="w10" />
                    <Radio.Group
                      disabled={dispType !== 1}
                      options={[{ label: "過去６ヶ月", value: "MONTH" }, { label: "過去１年", value: "YEAR" }]}
                      value={radioValue3}
                      optionType="button"
                      onChange={(e) => {
                        setRadioValue3(e.target.value);
                        if (e.target.value === "MONTH"){
                          setFilters({
                            start_month: moment().tz("Asia/Tokyo").subtract(6, "month").startOf("month"),
                            end_month  : moment().tz("Asia/Tokyo").endOf("month"),
                          });
                        } else {
                          setFilters({
                            start_month: moment().tz("Asia/Tokyo").subtract(11, "month").startOf("month"),
                            end_month  : moment().tz("Asia/Tokyo").endOf("month"),
                          });
                        }
                      }}
                      buttonStyle="solid"
                    />
                  </div>
                </Radio>
              </Space>
            </Radio.Group>
            <div className="h30" />
            <div className="row jcenter">
              <Button onClick={() => onClearFilters()}>クリア</Button>
              <div className="w10" />
              <Button onClick={() => onGetReport()} type="primary" danger>
                絞り込み
              </Button>
            </div>
          </div>
          <div className="h30" />
          <div className="h30" />
          <div className="title">テーター対象期間：{}{}</div>
          <div className="divider" />
          <div className="h20" />
          <div className="layout">
            <div className="title">訪問者数x転換率</div>
            <div className="h20" />
            <VisitorGraph visits={rmsReport?.visits} visits_cvr={rmsReport?.visits_cvr}/>
          </div>
          <div className="h20" />
          <div className="layout">
            <div className="title">売上x転換数</div>
            <div className="h20" />
            <SalesGraph gms={rmsReport.gms} gms_conversion={rmsReport.gms_conversion} />
          </div>
          <div className="h20" />
          <div className="layout">
            <div className="title">LINEの売上</div>
            <div className="h20" />
            <ColumnChart oa={rmsReport.oa}/>
          </div>
          <div className="h20" />
          <ReportTable oa={rmsReport.oa}/>
        </Container>
      </PageContent>
    </PageContainer>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .subtitle {
    color: red;
  }
  .space-character {
    padding-left: 5px;
    padding-right: 5px;
    color: #5e6777;
  }
  .h10 {
    height: 10px;
  }
  .h35 {
    height: 35px;
  }
  .h30 {
    height: 30px;
  }
  .h20 {
    height: 20px;
  }
  .h25 {
    height: 25px;
  }
  .h30 {
    height: 30px;
  }
  .h20 {
    height: 20px;
  }
  .w10 {
    width: 10px;
  }
  .w20 {
    width: 20px;
  }
  .mv-7 {
    margin-bottom: 7px;
    margin-top: 7px;
  }
  .layout {
    background: #fbfbfb;
    padding: 30px;
  }
  .character {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .box-list {
    display: flex;
    flex-direction: row;
    overflow: auto;
  }
  .ph-40 {
    padding: 40px;
    width: 100%;
  }
  .color-gray {
    color: #5e6777;
  }
  .divider {
    border: solid 1.5px #eeeeee;
  }
  .title {
    font-weight: 700;
    font-size: 14px;
  }
  .filter-container {
    background-color: #eeeeee;
    padding: 20px;
    border-radius: 4px;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .jcenter {
    justify-content: center;
  }
  .acenter {
    align-items: center;
  }
  .label {
    white-space: nowrap;
    margin-right: 10px;
  }
  .w10 {
    width: 10px;
  }
`;

RmsReport.displayName = "RmsReport";

export { RmsReport };

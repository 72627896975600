import { PageHeader, PageContainer, PageContent } from "../../components/Layout";
import React from "react";
import styled from "styled-components";
import { useFetch, useTranslate } from "../../hooks";
import { Button, message } from "antd";
import Form, { ACTION_INIT } from "./component/Form";
import { carousel as carouselApi } from "../../apis";
import { useHistory, useParams } from "react-router";

export default ()=> {
  const { translate } = useTranslate();
  const params = useParams();
  const [data, fetching, reload, setData] = useFetch(carouselApi.get, params.id)({});
  const history = useHistory();
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async ()=> {
    let value = await formRef.current.validate();
    if (!value) return;

    setLoading(true);
    try {
      await carouselApi.update({ ...data, ... value });
      message.success(translate("system.message.success", "Success!"));
      history.push("/carousel");
      setLoading(false);
    }
    catch (err){
      message.error(err.message);
      setLoading(false);
    }
  };

  React.useEffect(()=> {
    if (fetching === false){
      let res = data.columns.map(column => {
        let actions = column.actions;
        if (actions.length === 1){
          let colmn = { ...column, actions: [...column.actions, { ...ACTION_INIT }] };
          return colmn;
        }
        return column;
      });
      setLoading(true);
      setData({ ...data, columns: res });
      setTimeout(()=>{
        setLoading(false);
      }, 10);
    }
  }, [fetching]);

  if (fetching === true || loading === true) return "";

  const onCopy = async () => {
    let result = await carouselApi.copy(data?._id);
    message.success(translate("system.message.success", "Successful"));
    history.push(`/carousel/edit/${result._id}`);
  };

  return (
    <Container>
      <PageContainer>
        <PageHeader onBack={()=>history.goBack()} title={translate("carousel.edit", "Card type message edit")} extra={[
          data && <Button key={2} loading={loading} type='default' onClick={onCopy}>{translate("system.copy", "Copy")}</Button>,
          <Button loading={loading} key={"1"} onClick={onSubmit} type="primary">{translate("system.save", "Save")}</Button>
        ]}/>
        <PageContent>
          <Form ref={formRef} editable={["update", { ...data }]}/>
        </PageContent>
      </PageContainer>
    </Container>
  );
};

const Container = styled.div`
`;


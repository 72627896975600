import React, { useEffect, useCallback, useState } from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Form from "./components/AddCustomerForm";
import styled from "styled-components";
import { auth } from "../../apis";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { message } from "antd";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      await auth.signup(data);
      message.success(translate("login.register.checkmail", "Please check your email for a message with your verify link."));
      history.goBack();
    } catch (error) {
      if (error.message === "ALREADY_REGISTERED")
        message.error(translate("signup.warning.registered", "This e-mail address is already registered!"));
    }
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("navbar.permission", "New user register")} />
      <PageContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormWrapper>
            <Form onSubmit={onSubmit} />

          </FormWrapper>
        </div>
      </PageContent>
    </PageContainer>
  );

};

const FormWrapper = styled.div`
  width: 600px;
`;
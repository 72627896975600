import React from "react";
import { message as notify, Collapse, Row, Col, Modal, Alert } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../components/Design";
import { Progress, Spin } from "antd";
import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import TargetForm from "../../components/Message/Target";
import { richmenu as richmenuApi, tags as tagsApi } from "../../apis";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import colors from "../../colors";
import NewRichMenu from "./New";
import EditRichMenu from "./Edit";
import CopyRichMenu from "./Copy";
import RichMenuList from "./List";
import Form from "./Form";
import SettingsForm from "./components/SettingsForm";
import moment from "moment";

export { NewRichMenu, EditRichMenu, CopyRichMenu, RichMenuList };

const { Panel } = Collapse;
const { confirm } = Modal;

export default ({ editable }) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const { target, collapse } = useSelector(state => state.message);
  const { checkRole } = usePermission();
  const [loading, setLoading] = React.useState(false);
  const targetForm = React.useRef();
  const broadcastForm = React.useRef();
  const richmenuForm = React.useRef();
  const [disabled, setDisabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));

  useFetch(tagsApi.select(dispatch))();
  const onChangeCollapse = (collapse) => {
    dispatch({
      type   : "message.collapse",
      payload: collapse
    });
  };

  const onNext = async (type) => {
    switch (type) {
      case "target": {
        let isValid = await targetForm.current.validate();

        if (isValid) {
          dispatch({
            type   : "message.collapse",
            payload: ["broadcast"]
          });
        }
        break;
      }
      case "broadcast": {
        const isValid = await broadcastForm.current.validate();

        if (isValid) {
          dispatch({
            type   : "message.collapse",
            payload: ["message"]
          });

          dispatch({
            type   : "message.preview",
            payload: true
          });
        }
        break;
      }
      default:
    }
  };

  const onSubmit = async (type, data) => {
    console.log("DATA", data);


    switch (type) {
      case "target": {
        dispatch({
          type   : "message.target.data",
          payload: data.tags
        });
        break;
      }
      case "broadcast": {
        dispatch({
          type   : "message.broadcast.data",
          payload: {
            sendType: data.sendType,
            sendDate: data.sendDate
          }
        });
        break;
      }
      case "message": {
        dispatch({
          type   : "message.message.data",
          payload: {
            title: data.title,
            text : data.text
          }
        });
        break;
      }
      default:
    }
  };

  const onSave = async (save) => {
    try {
      dispatch({
        type   : "message.collapse",
        payload: ["target", "broadcast", "message"]
      });

      let target = await targetForm.current.validate();
      let broadcast = await broadcastForm.current.validate();
      let richmenu = await richmenuForm.current.validate();



      if (target === false || richmenu === false || broadcast === false)
        notify.error(translate("system.error.required", "Insert necessary fields first!"));

      if (!target || !broadcast || !richmenu) return;

      let actionCheck = [];
      richmenu.actions.forEach(item => {
        if (item.type !== "no_action"){
          actionCheck = [...actionCheck, item];
        }
      });

      if (actionCheck.length === 0) {
        notify.error(translate("richmenu.action.required.one", "Set at least one action "));
        return;
      }

      setLoading(true);

      const asyncSend = async () => {
        console.log("=====>", editable);
        if (editable[0] === "update") {
          await richmenuApi.update({
            _id         : editable[1]._id,
            name        : broadcast.name,
            behaviorType: broadcast.behaviorType,
            chatBarType : broadcast.chatBarType,
            chatBarText : broadcast.chatBarText,
            actions     : richmenu.actions,
            image       : richmenu.image,
            template    : richmenu.template,
            type        : richmenu.type,
            saveType    : save,
            sendType    : broadcast.sendType,
            schedules   : broadcast.schedules,
            targetType  : target.targetType,
            targets     : target.targetType === "SEGMENTS" ? target.targets : [],
            demographic : target.targetType === "DEMOGRAPHIC" ? target.targets : null
          });
        } else {
          await richmenuApi.create({
            name        : broadcast.name,
            behaviorType: broadcast.behaviorType,
            chatBarType : broadcast.chatBarType,
            chatBarText : broadcast.chatBarText,
            actions     : richmenu.actions,
            image       : richmenu.image,
            template    : richmenu.template,
            type        : richmenu.type,
            saveType    : save,
            sendType    : broadcast.sendType,
            schedules   : broadcast.schedules,
            targetType  : target.targetType,
            targets     : target.targetType === "SEGMENTS" ? target.targets : [],
            demographic : target.targetType === "DEMOGRAPHIC" ? target.targets : null
          });
        }
      };

      if (save === "SEND" && broadcast.sendType === "NOW") {
        confirm({
          icon      : <ExclamationCircleOutlined />,
          okText    : translate("system.yes", "Yes"),
          cancelText: translate("system.no", "No"),
          content   : translate("richmenu.confirm.sendnow", "Message has been set to send immediately, are you sure to send it immediately?"),
          async onOk() {
            if (moment(broadcast.schedules[1]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && broadcast.sendType === "NOW") {
              try {
                await asyncSend();
                notify.success(translate("system.message.success", "Success"));
                history.push("/products/richmenu");
              } catch (err) {
                if (err.message.message === "RAKUTEN_HTTP_ERROR")
                  notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
                else
                  notify.error(err.message);
                setLoading(false);
              }
            } else {
              notify.error(translate("system.error.schedule.time", "Set schedule time in the future"));
              setLoading(false);
            }
          },
          async onCancel() {
            setLoading(false);
          }
        });
      }

      if (save === "SEND" && broadcast.sendType === "DATE") {
        if (moment(broadcast.schedules[0]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && moment(broadcast.schedules[1]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && broadcast.sendType === "DATE") {
          try {
            await asyncSend();
            notify.success(translate("system.message.success", "Success"));
            setLoading(false);
            history.push("/products/richmenu");
          } catch (err) {
            if (err.message.message === "RAKUTEN_HTTP_ERROR")
              notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
            else
              notify.error(err.message);
            setLoading(false);
          }
        } else {
          notify.error(translate("system.error.schedule.time", "Set schedule time in the future"));
          setLoading(false);
        }
      }

      if (save === "DRAFT") {
        if (moment(broadcast.schedules[1]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && broadcast.sendType === "NOW") {
          try {
            await asyncSend();
            notify.success(translate("system.message.success", "Success"));
            setLoading(false);
            history.push("/products/richmenu");
          } catch (err) {
            if (err.message.message === "RAKUTEN_HTTP_ERROR")
              notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
            else
              notify.error(err.message);
            setLoading(false);
          }
          // await asyncSend();

          // notify.success(translate("system.message.success", "Success"));
          // setLoading(false);

          // history.push("/products/richmenu");
        } else if (moment(broadcast.schedules[0]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && moment(broadcast.schedules[1]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && broadcast.sendType === "DATE") {
          try {
            await asyncSend();
            notify.success(translate("system.message.success", "Success"));
            history.push("/products/richmenu");
          } catch (err) {
            if (err.message.message === "RAKUTEN_HTTP_ERROR")
              notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
            else
              notify.error(err.message);
            setLoading(false);
          }
        } else {
          notify.error(translate("system.error.schedule.time", "Set schedule time in the future"));
          setLoading(false);
        }
      }
    } catch (error) {
      notify.error(translate("system.error.required", "Please fill out the required fields"));
      console.log("==ERROR====>", error);
      if (error.message === "NO_TARGET")
        notify.error(translate("system.notarget", "No target has been set"));
      if (error.message.message === "RAKUTEN_HTTP_ERROR")
        notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
      else
        notify.error(error.message);

      setLoading(false);
    }
  };

  const onRemove = () => {
    confirm({
      width  : 750,
      icon   : false,
      content: <div>
        <Alert
          description={
            <div>
              <div> {translate("richmenu.confirm.remove_1", "If you delete the rich menu within the display period, the rich menu will be deleted from the user who displayed the rich menu to be deleted, and you will need to set another rich menu again.")}</div>
              <div> {translate("richmenu.confirm.remove_2", "(If the default rich menu is set, the default rich menu will be displayed)")}</div>
              <br/>
              <div>{translate("richmenu.confirm.remove_3", "Is it OK？")}</div>
            </div>}
          type="warning"
          showIcon={false}
        />
      </div>,
      async onOk() {
        await richmenuApi.remove(editable[1]._id);
        notify.success(translate("system.message.success", "Success"));
        history.push("/products/richmenu");
      },
    });
  };

  const onCopy = () => {
    history.push(`/products/richmenu/copy/${editable[1]?._id}`);
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={editable[0] === "update" ? translate("richmenu.edit", "Edit rich menu") : translate("richmenu.new", "New rich menu")} extra={[
        editable[0] === "update" && disabled && <Button key={1} loading={loading} type="default" onClick={onCopy}>{translate("system.copy", "Copy")}</Button>,
        editable[0] === "update" && disabled && <Button key={1} loading={loading} type="danger" onClick={onRemove}>{translate("system.delete", "Remove")}</Button>,
        <Button key={2} type="default" loading={loading} onClick={() => onSave("DRAFT")}>{translate("message.save.draft", "Save draft")}</Button>,
        disabled&&<Button key={3} type="primary" loading={loading} onClick={() => onSave("SEND")}>{translate("message.setting.end", "Send")}</Button>
      ]} />

      <PageContent>
        <MessageContainer>
          <Collapse bordered={false} ghost={true} activeKey={collapse} onChange={onChangeCollapse}>
            <Panel className="richmenu" key="target" header={translate("message.targetestimation", "Target Estimation")}>
              <Row gutter={[8, 8]}>
                <Col span={14}>
                  <TargetForm hideNarrowcast={true} ref={targetForm} editable={editable} onSubmit={(data) => onSubmit("target", data)} isMessage={false} segment estimate />
                </Col>
                <Col span={10}>
                  <Estimate>
                    <div className="header">{translate("message.targetestimation", "Target Estimation")}</div>
                    <div className="body">
                      <Spin spinning={target.calculating} indicator={<LoadingOutlined spin />}>
                        <Progress type="circle" percent={target.percent} />
                      </Spin>
                      <div className="friends">{translate("message.approx", "Approx")} {target.friends} {translate("message.people", "friends")}</div>
                    </div>
                  </Estimate>
                </Col>
              </Row>

              <Row gutter={[8, 8]} style={{ marginTop: 40 }}>
                <Col span={14}>
                  <Row>
                    <Col span={14} offset={8}>
                      <Button onClick={() => onNext("target")} type="default">{translate("system.next", "Next")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
            <Panel className="richmenu" key="broadcast" header={translate("richmenu.display.settings", "Display settings")}>
              <Row gutter={[8, 8]}>
                <Col span={14}>
                  <SettingsForm ref={broadcastForm} editable={editable} onSubmit={(data) => onSubmit("settings", data)} />
                  <Row style={{ marginTop: 40 }}>
                    <Col span={14} offset={8}>
                      <Button type="default" onClick={() => onNext("broadcast")}>{translate("system.next", "Next")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
            <Panel className="richmenu" key="message" header={translate("richmenu.create", "Rich Menu")}>
              {<Form ref={richmenuForm} editable={editable} onSubmit={(data) => onSubmit("message", data)} />}
            </Panel>
          </Collapse>
        </MessageContainer>
      </PageContent>
    </PageContainer>
  );
};

export const Estimate = styled.div`
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: auto;
  .header { 
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 8px 10px;
    font-size: 15px;
  }
  .body { 
    padding: 20px 10px;
    text-align: center;
    .friends {
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: ${colors.primaryDark};
  }
  .ant-progress-circle .ant-progress-text {
    color : ${colors.primary}
  }
`;
export const MessageContainer = styled.div`
  .richmenu {
    & > .ant-collapse-header {
      border-bottom: 1px solid #d9d9d9;
      font-size: 16px;
      padding-left: 20px!important;
      .anticon {
        left: 0!important;
      }
    }
    & > .ant-collapse-content-box {
      padding: 20px 0!important;
    }
  }
`;
import React from "react";
import { Field } from "formik";
import { FormItem } from "formik-antd";
import { Modal, Tabs } from "antd";
import { ClockCircleOutlined, CloseOutlined, SmileOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Sticker from "../../Sticker";
import colors from "../../../colors";
import { account } from "../../../apis";
import { useFetch } from "../../../hooks";
import { useTranslate } from "../../../hooks";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

export default ({ name }) => {
  const { translate } = useTranslate();
  const { stickerPackages, stickers } = useSelector(state => state.general);
  const [action, setAction] = React.useState(false);
  const [recents, fetched] = useFetch(account.stickers)([]);

  return (
    <FormItem name={name}>
      <Field name={name}>
        {fieldProps => {
          const {
            field: { value },
            form: { setFieldValue }
          } = fieldProps;

          const onSelect = ({ packageId, stickerId }) => {
            setFieldValue(name, {
              packageId,
              stickerId
            });

            setAction(false);
          };

          const onRemove = () => {
            setFieldValue(name, undefined);
          };

          return (
            <StickerContainer>
              {value ? (
                <Uploaded>
                  <Sticker packageId={value.packageId} id={value.stickerId} />
                  <a className="close" href="#" onClick={() => onRemove({ setFieldValue })}><CloseOutlined /></a>
                </Uploaded>
              ) : (
                <SelectFile onClick={() => setAction(true)}>
                  <SmileOutlined />
                  <div className="upload-text">{translate("message.sticker", "Select sticker")}</div>
                </SelectFile>
              )}

              <Modal
                maskClosable={false}
                visible={action}
                className="sticker-modal"
                title={translate("message.sticker", "Sticker")}
                onCancel={() => setAction(false)}
                footer={null}>
                <Tabs defaultActiveKey={stickerPackages[0] && `${stickerPackages[0].packageId}`}>
                  <TabPane tab={<ClockCircleOutlined />} key={"last"}>
                    {fetched === false && recents.length === 0 && (
                      <Empty>
                        <SmileOutlined />
                        <span>{translate("message.nosticker", "No used sticker")}</span>
                      </Empty>
                    )}
                    {recents.map((sticker, index) => {
                      return <Sticker key={index} onClick={() => onSelect({ packageId: sticker.packageId, stickerId: sticker.stickerId })} packageId={sticker.packageId} id={sticker.stickerId} />;
                    })}
                  </TabPane>
                  {stickerPackages.map((stickerPackage, index) => {
                    let items = stickers.filter(s => s.packageId === stickerPackage.packageId);

                    return (
                      <TabPane key={index} tab={<Sticker packageId={stickerPackage.packageId} id={items[0].stickerId} />} key={`${stickerPackage.packageId}`}>
                        {items.map((sticker, index) => {
                          return <Sticker key={index} onClick={() => onSelect({ packageId: sticker.packageId, stickerId: sticker.stickerId })} packageId={sticker.packageId} id={sticker.stickerId} />;
                        })}
                      </TabPane>
                    );
                  })}
                </Tabs>
              </Modal>
            </StickerContainer>
          );
        }}
      </Field>
    </FormItem>
  );
};

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  .anticon {
    font-size: 22px;
    color: #607D8B;
  }
  span {
    margin-top: 5px;
    font-size: 13px;
    color: #555;
  }
`;
const StickerContainer = styled.div`
  .ant-upload {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
    color: #9a9a9a;
  }
`;

const SelectFile = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 25px 0;
  border: 1px dashed #ddd;
  min-height: 160px;
  cursor: pointer;
  border-radius: 4px;
  .anticon {
    font-size: 22px;
    color: #607D8B;
  }
  &:hover {
    .upload-text {
      color: ${colors.primaryDark};
    }
  }
  .upload-text {
    font-size: 13px;
    margin-top: 10px;
    transition: all .2s;
    font-weight: 400;
  }
`;
const Uploaded = styled.div`
  width: 120px;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .play {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: #6f6f6f;
  }
  img {
    display: flex;
  }
  .close {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    background: #6f6f6f;
    color: #fff;
    width: 22px;
    height: 20px;
    text-align: center;
    font-weight: 400;
    transition: all .2s;
    &:hover {
      background: #333;
    }
  }
`;
import React from "react";
import { message as notify, Space, Collapse, message } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Button } from "../../components/Design";
import { useFetch, useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { template as templateApi } from "../../apis";
import { Form, FormItem, Input, Radio } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import CooperationPreview from "../../components/CooperationPreview";
import styled from "styled-components";
import ImageUpload from "../../components/ImageUpload";
import Thumbnail from "../../components/Message/components/Thumbnail";
import Preview from "../../components/Preview";
import colors from "../../colors";
import { useDispatch, useSelector } from "react-redux";
import { tags as tagsApi } from "../../apis";
import TaggedForm from "./components/TaggedForm";
import copy from "copy-to-clipboard";

const { TextArea } = Input;
const { Panel } = Collapse;

const FormSchema = (translate) => Yup.object().shape({
  image: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  title: Yup.string().max(30).required(translate("system.message.required", "Please fill out this field!")),
  body : Yup.object({
    type: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    text: Yup.string().when("type", {
      is  : "text",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    html: Yup.string().when("type", {
      is  : "html",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    })
  }),
  description: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  button     : Yup.object({
    text: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
  }),
});

const SubmitForm = React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const { validateForm, submitForm, values } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        return false;
      }

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { action } = props;
  const type = "message";
  const { translate } = useTranslate();
  const [loading, setLoading] = React.useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const submitRef = React.useRef();
  const taggedForm = React.useRef();
  useFetch(tagsApi.select(dispatch))();
  const [collapse, setCollapse] = React.useState(["target", "template"]);

  const [data] = React.useState({
    image: undefined,
    title: undefined,
    body : {
      type: "text",
      text: undefined,
      html: undefined
    },
    button: {
      text: undefined,
    },
    ...(action && action[0] === "update" ? action[1] : {})
  });
  const onCopy = async (link) => {
    if (link) {
      copy(link);
      notify.success(translate("system.message.success", "Success"));
    }
  };
  const onReload = async () => {
    let values = await submitRef.current.submitForm();

    if (!values) return;

    let link = await templateApi.preview({
      type,
      ...values
    });
    return link;
  };

  const onSave = async () => {
    let values = await submitRef.current.submitForm();
    let tagged = await taggedForm.current.validate();
    if (!values || !tagged) {
      message.error(
        translate("system.error.required", "Insert necessary fields first!")
      );
      return false;
    }

    let res;

    setLoading(true);

    await templateApi.preview({
      type,
      ...values
    });

    if (tagged.targetType === "TAGS" && tagged.tags.length === 0) {
      notify.error(translate("system.error.required", "Insert tags before sending this message!"));
      setLoading(false);
      return;
    }

    if (action[0] === "update") {
      res = await templateApi.update({
        ...values,
        targetType: tagged.targetType,
        tags      : tagged.tags,
        type
      });
    } else {
      res = await templateApi.create({
        ...values,
        targetType: tagged.targetType,
        tags      : tagged.tags,
        type
      });
    }

    setLoading(false);
    notify.success(translate("system.message.success", "Success"));
    history.push("/message/template");
  };

  const onChangeCollapse = (collapse) => {
    setCollapse(collapse);
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={action[0] === "create" ? translate("system.create", "New template") : translate("system.edit", "Edit template")} extra={[
        <Button key={3} type="primary" loading={loading} onClick={onSave}>{translate("system.save", "Save")}</Button>
      ]} />
      <PageContent>
        <MessageContainer>
          <Collapse bordered={false} ghost={true} activeKey={collapse} onChange={onChangeCollapse}>
            <Panel key="target" header={translate("messagetemplate.autotag", "Auto tag")}>
              <div style={{ padding: 20 }}>
                <TaggedForm editable={action[1]} ref={taggedForm} />
              </div>
            </Panel>
            <Panel key="template" header={translate("messagetemplate.template", "Template")}>
              <Formik
                enableReinitialize
                initialValues={data}
                validationSchema={() => FormSchema(translate)}
                onSubmit={() => { }}>
                {({ values }) => {
                  return (
                    <Form layout="vertical">
                      <Cooperation>
                        <Preview>
                          <Thumbnail values={{ ...values, button: values && values.button && values.button.text }} />
                        </Preview>
                        <div className="content">
                          <CooperationSection title={translate("template.thumbnail", "Thumbnail")}>
                            <FormItem name="image" label={translate("cooperationform.picture", "Image")} required>
                              <ImageUpload name="image" size="small" action="/api/general/upload" />
                            </FormItem>
                            <FormItem name="title" label={translate("system.title", "Title")} required>
                              <Input name="title" maxLength={30} />
                            </FormItem>
                            <FormItem name="description" label={translate("cooperationform.description", "Description")} required>
                              <TextArea name="description" maxLength={50} />
                            </FormItem>
                            <FormItem name="button.text" label={translate("cooperationform.button", "Button")} required>
                              <Input name="button.text" maxLength={20} />
                            </FormItem>
                            <FormItem name="link" label={translate("messagetemplate.link", "Link")} required>
                              <Input
                                disabled
                                name="link"
                                suffix={
                                  <Button size="small" onClick={()=> onCopy(data.link)}>
                                    {translate("system.copy", "Copy")}
                                  </Button>}/>
                            </FormItem>
                          </CooperationSection>
                        </div>
                      </Cooperation>
                      <Cooperation>
                        <CooperationPreview theme="light" onReload={onReload} />
                        <div className="content">
                          <CooperationSection title={translate("template.detail", "Detail")}>
                            <WriteMessage>
                              <MessageToolbar>
                                <Space className="left">
                                  <Radio.Group name="body.type">
                                    <Radio.Button value="text">{translate("cooperationform.text", "Text")}</Radio.Button>
                                    <Radio.Button value="html">HTML</Radio.Button>
                                  </Radio.Group>
                                </Space>
                              </MessageToolbar>
                              <MessageContent>
                                {(() => {
                                  switch (values.body.type) { // type !!!
                                    case "text":
                                      return (
                                        <FormItem name="body.text" required>
                                          <TextArea name="body.text" rows={4} />
                                        </FormItem>
                                      );
                                    case "html":
                                      return (
                                        <FormItem name="body.html" required>
                                          <TextArea name="body.html" rows={4} />
                                        </FormItem>
                                      );
                                    default:
                                  }
                                })()}
                              </MessageContent>
                            </WriteMessage>
                          </CooperationSection>

                        </div>
                      </Cooperation>
                      <SubmitForm ref={submitRef} />
                    </Form>
                  );
                }}

              </Formik>
            </Panel>
          </Collapse>
        </MessageContainer>
      </PageContent>
    </PageContainer>
  );
});
// onClick={() => onCopy(cooperation.link)}
const CooperationSection = (props) => {
  return (
    <CooperationContent>
      <div className="label">{props.title}</div>
      <div className="content">{props.children}</div>
    </CooperationContent>
  );
};

const MessageContainer = styled.div`
  .ant-collapse-header {
    border-bottom: 1px solid #d9d9d9;
    font-size: 16px;
    padding-left: 20px!important;
    .anticon {
      left: 0!important;
    }
  }
  .ant-collapse-content-box {
    padding: 20px 0!important;
  }
`;
const Cooperation = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  .content {
    padding-left: 40px;
  }
`;
const CooperationContent = styled.div`
  display: flex;
  flex-direction: row;
  .label {
    font-size: 15px;
    color: #333;
    min-width: 100px;
    font-weight: 500;
  }
  .content {
    /* max-width: 370px; */
    min-height: 600px;
    padding: 0;
  }
  .description-field {
    max-width: 100% !important;
  }
`;
const MessageContent = styled.div`
  padding: 15px;
  .max-length {
    margin-top: 5px;
    text-align: right;
    font-size: 14px;
    color: #adb5bd;
    .length {
      font-weight: 500;
    }
  }
`;
const MessageToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: rgba(0,0,0,0.03);
  border-bottom: 1px solid rgba(0,0,0,0.125);
`;
const WriteMessage = styled.div`
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 20px;
  .ant-radio-button-wrapper {
    &:hover {
      color: ${colors.primary};
    }
  }
  .ant-radio-button-wrapper-checked {
    &:hover {
      color: ${colors.primary}!important;
    }
    color: ${colors.primaryDark};
    border-color: ${colors.primaryDark}!important;
    &:before {
      background-color: ${colors.primaryDark}!important;
    }
  }
`;
export const Estimate = styled.div`
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: auto;
  .header { 
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 8px 10px;
    font-size: 15px;
  }
  .body { 
    padding: 20px 10px;
    text-align: center;
    .friends {
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .alert-box{
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
    margin-left: 5px;
    border: solid 1px;
    border-radius: 3px;
    margin-bottom: 5px;
    background: #fcf8e3;
    color: #8a6d3b;
    border-color: #faebcc;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: ${colors.primaryDark};
  }
  .ant-progress-circle .ant-progress-text {
    color : ${colors.primary}
  }
`;
import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import colors from "../../colors";
import { RowAction, MyTable } from "../../components";
import { useHistory } from "react-router-dom";
import Button from "../../components/Design/Button";
import { Empty, Input, message, Modal, Space, Tag } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { agency, grant } from "../../apis";
import styled from "styled-components";
import VerifyPasswordForm from "../Admin/VerifyPasswordForm";

const { confirm } = Modal;


export default () => {
  const history = useHistory();
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const [action, setAction] = React.useState();
  const [removeModal, setRemoveModal] = React.useState(false);
  const [filters, setFilters] = React.useState({
    query: ""
  });

  const columns = useHeader({
    history,
    tableTitles: {
      no      : translate("system.number", "No."),
      name    : translate("agency.firstname", "Name"),
      role    : translate("agency.role", "Role"),
      email   : translate("forgot.email", "E-mail"),
      status  : translate("system.status", "Status"),
      action  : translate("system.action", "Action"),
      edit    : translate("system.edit", "Edit"),
      remove  : translate("system.delete", "Remove"),
      disabled: translate("system.disabled", "Disabled"),
      enabled : translate("system.enabled", "Enabled"),
      blocked : translate("useredit.block", "Blocked"),

    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/user/update/${item._id}`);
          // let res = await tags.get(item._id);
          // setAction(["update", res]);

          break;
        }
        case "remove": {
          setRemoveModal(true);
          setAction(["", item]);
          break;
        }
        default:
      }
    },
  });
  const submitVerifyPassword = async (data) => {
    try {
      let res = await grant.removeAgency({ id: `${action[1]._id}`, password: data.password });
      setRemoveModal(false);
      myTableRef.current.reload();

    } catch (error) {
      message.error(translate("system.error.PASSWORD_INVALID", "Password is incorrect"));
    }
  };

  const createUser = () => {history.push("/user/create"); setAction(["create", {}]);};


  return (
    <div>
      <PageContainer>
        <PageHeader title={translate("agency.accounts", "Agency accounts")} extra={[
          <Button key="create users" type="primary" onClick={() => createUser() }><PlusOutlined /> {translate("system.add", "Create user")}</Button>
        ]} />

        <PageContent>

          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            ref={myTableRef}
            filters={filters}
            rowKey={(record) => record._id}
            columns={columns}
            loadData={agency.list}
          />
          <Modal
            title={translate("login.password", "Insert password")}
            visible={removeModal}
            onOk={null}
            onCancel={() => setRemoveModal(false)}
            footer={null}
          >
            <VerifyPasswordForm onSubmit={submitVerifyPassword} visible={setRemoveModal} />
          </Modal>
        </PageContent>
      </PageContainer>
    </div>
  );
};


const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
`;

const useHeader = ({ onAction, tableTitles }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",

  }, {
    title : tableTitles.name,
    render: (item) => <div>{ item.active ? item.name : `*${item.name}` }</div>
  }, {
    title    : tableTitles.role,
    dataIndex: "role",
  }, {
    title    : tableTitles.email,
    dataIndex: "email",
  }, {
    title : tableTitles.status,
    render: (item)=>{
      return (<div>{item.active === false ? <span ><Tag color="error">{tableTitles.disabled}</Tag></span>:<span style={{ color: colors.primaryDark }}><Tag style={{ color: colors.primaryDark }} color="success">{tableTitles.enabled}</Tag></span>}</div>);
    }
  },
  // {
  //   title: tableTitles.blocked,
  //   render: (item) => {
  //     return (<div>{item.passwordBlocked === false ?
  //       <div>-</div>
  //       : <span><Tag color="red">{tableTitles.blocked}</Tag></span>}</div>);
  //   }
  // },
  {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (row) => {
      return <RowAction actions={{
        edit  : tableTitles.edit,
        remove: tableTitles.remove
      }} onClick={(key) => onAction(key, row)} />;
    }
  }];
};
import React from "react";
import { scenario as scenarioApi } from "../../apis";
import { Button, Space, Input, Empty } from "antd";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { MyTable } from "../../components";
import styled from "styled-components";
import { useSelector } from "react-redux";

export default ({ onSelect, action }) => {
  const [query, setQuery] = React.useState("");
  const { processEvents, lang } = useSelector(state=>state.general);
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    query    : "",
    hide     : false,
    eventType: action && action[1].code
  });

  const convertArrayToObject = () => {
    const initialValue = {};
    if (processEvents){
      return processEvents.reduce((obj, item) => {
        return {
          ...obj,
          [item.code]: item,
        };
      }, initialValue);
    }
  };

  const columns = useHeader({
    history,
    translate,
    onSelect,
    tableTitles: {
      no       : translate("system.number", "No."),
      name     : translate("scenario.list.name", "Scenario set name"),
      eventType: translate("scenario.list.event", "Start event"),
      action   : translate("system.action", "Action"),
      edit     : translate("system.edit", "Edit"),
      remove   : translate("system.delete", "Remove"),
      details  : translate("system.detail", "Details"),
      activate : translate("scenario.list.activate", "Activate"),
      hide     : translate("scenario.list.hide", "Hide")
    },
    events: convertArrayToObject(),
    lang
  });



  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <Container>
      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />


        </Space>
      </PageFilter>

      <MyTable
        locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
        ref={myTableRef}
        filters={filters}
        rowKey={(record) => record._id}
        columns={columns}
        loadData={scenarioApi.list}
      />

    </Container>
  );
};

const useHeader = ({ tableTitles, translate, onSelect, events, lang }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.name,
    render: (record) => {
      return `${record.name}`;
    },
  }, {
    title : tableTitles.eventType,
    render: (record) => {
      return events[record.eventType][lang.toLowerCase()];
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (item) => (
      <Button onClick={() => onSelect(item)}>{translate("system.select", "Select")}</Button>
    ),
  }];
};

const Container = styled.div`
  .ant-table-content {
    table {
      width: 300px !important;
    }
  }
`;

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

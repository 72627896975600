import request from "../utils/request";

export const select = () => request.get("/api/coupon/select");
export const list = (data) => request.post("/api/coupon/list", data);
export const get = (id) => request.get(`/api/coupon/${id}`);
export const create = (data) => request.post("/api/coupon", data);

export const update = (data) => request.post("/api/coupon/update", data);
export const remove = (couponCode) => request.del(`/api/coupon/${couponCode}`);

export const isAutoIssue = (data) => request.post("/api/coupon/auto/issue", data);


export const couponReports = (data) => request.post("/api/coupon/report", data);


import React, { useEffect } from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Button } from "antd";
import * as Yup from "yup";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";

const DataSchema = (translate) => Yup.object().shape({
  ecmaccesskey: Yup.string().optional().nullable().trim(),
  ecmid       : Yup.string().optional().nullable().trim(),
});

const FormSchema = (translate) => Yup.object().shape({
  customerData: DataSchema(translate),
  accountsData: Yup.array().of(DataSchema(translate)).min(0),
});

export default ({ action, onSubmit }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    accountsData: [{
      ecmaccesskey: null,
      ecmid       : null,
      id          : null,
      name        : null
    }],
    customerData: {
      ecmaccesskey: "",
      ecmid       : "",
      id          : null,
      name        : null
    },
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  return (
    <Formik
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={(data, { setFieldError }) => {
        let errors = {};
        console.log(data)
        data.accountsData.forEach((item, index) => {
          if ((item?.ecmaccesskey && item?.ecmaccesskey !== "") || (item?.ecmid && item?.ecmid !== "")) {
            if (!item?.ecmaccesskey) {
              errors[`accountsData[${index}].ecmaccesskey`] = translate("system.message.required", "Please fill out this field");
            }

            if (!item?.ecmid) {
              errors[`accountsData[${index}].ecmid`] = translate("system.message.required", "Please fill out this field");
            }
          } else {
            delete errors[`accountsData[${index}].ecmaccesskey`];
            delete errors[`accountsData[${index}].ecmid`];
          }
        });

        if ((data.customerData?.ecmaccesskey && data.customerData?.ecmaccesskey !== "") || (data.customerData?.ecmid && data.customerData?.ecmid !== "")) {
          if (!data.customerData?.ecmaccesskey) {
            errors["customerData.ecmaccesskey"] = translate("system.message.required", "Please fill out this field");
          }

          if (!data.customerData?.ecmid) {
            errors["customerData.ecmid"] = translate("system.message.required", "Please fill out this field");
          }
        } else {
          delete errors["customerData.ecmaccesskey"];
          delete errors["customerData.ecmid"];
        }

        Object.keys(errors).forEach(code => {
          setFieldError(code, errors[code]);
        });

        if (Object.keys(errors).length > 0)
          return false;

        onSubmit(data);
      }}>
      {({ isSubmitting, values }) => {

        return (
          <Form layout="vertical">
            <h3>{values.customerData.name}</h3>
            <Input type="hidden" name={"customerData.id"} />

            <ItemsWrapper >
              <FormItem label={translate("ecm.accesskey", "ECM Accesskey")} name="customerData.ecmaccesskey" required>
                <Input name="customerData.ecmaccesskey" placeholder={translate("ecm.accesskey", "ECM Accesskey")} />
              </FormItem>
              <FormItem label={translate("ecm.id", "ECM ID")} name="customerData.ecmid" required>
                <Input name="customerData.ecmid" placeholder={translate("ecm.id", "ECM ID")} />
              </FormItem>
            </ItemsWrapper>

            {values?.accountsData?.length > 0 ? values?.accountsData.map((item, index) => {
              return <div key={index} style={{ marginTop: "20px" }}>
                <h3>{item.name}</h3>
                <Input type="hidden" name={`accountsData[${index}].id`} />
                <Input type="hidden" name={`accountsData[${index}].isRequired`} />

                <ItemsWrapper>
                  <FormItem label={translate("ecm.accesskey", "ECM Accesskey")} name={`accountsData[${index}].ecmaccesskey`} required>
                    <Input name={`accountsData[${index}].ecmaccesskey`} placeholder={translate("ecm.accesskey", "ECM Accesskey")}  />
                  </FormItem>
                  <FormItem label={translate("ecm.id", "ECM ID")} name={`accountsData[${index}].ecmid`} required>
                    <Input name={`accountsData[${index}].ecmid`} placeholder={translate("ecm.id", "ECM ID")}  />
                  </FormItem>
                </ItemsWrapper>
              </div>;
            }) : []}

            <AntForm.Item>
              <Button style={{ float: "right", marginRight : 10, marginTop: "10px", width: "200px" }} htmlType="submit" type="primary" loading={isSubmitting} block >{translate("system.save", "Save")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

const ItemsWrapper = styled.div `
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 20px;
  background: #fcfcfc; 
`;

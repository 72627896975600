/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { general, auth, tags as tagsApi, account } from "./apis";
import { PrivateRoute, PublicRoute, AccountBar, NavBar } from "./components";
import { useFetch, useTranslate } from "./hooks";
import styled from "styled-components";
import colors from "./colors";
import jaJP from "antd/lib/locale/ja_JP";
import "moment/locale/ja";

import { Login, Signup, Verify, Password, Forgot, DisableConfirm, ThankYou, AgencyInvite, VerifyStaff, VerifyEcm } from "./pages/Auth";
import { NewMessage, EditMessage, SentList, SendList, ReplyMessage, SentDetail } from "./pages/Message";
import { MessageTemplateList, MessageTemplateNew, MessageTemplateEdit } from "./pages/MessageTemplate";
import { FollowerList } from "./pages/Follower";
import { TagsList } from "./pages/Tags";
import { MessageSocket } from "./components";
import { Debug, Plan, CustomerList, CrawlerList, CustomerEdit, EmailChange, AddCustomer, RegisterCustomer, AccountListAdmin, FileListAdmin, SettingList } from "./pages/Admin";
import { ReferenceList } from "./pages/Reference";
import { LocalizationList } from "./pages/Localization";
import { NewRichMenu, EditRichMenu, RichMenuList, CopyRichMenu } from "./pages/RichMenu";
import { NewNews, EditNews, NewsList } from "./pages/News";
import { NewAccount, EditAccount } from "./pages/Account";
import { NewsDetail } from "./pages/Dashboard/components";
import { CreateUser, UserList, UpdateUser } from "./pages/User";
import { PaymentMethod } from "./pages/Payment";
import { ReportList, LogList, PaymentList, WebhookList, ScenarioReport, BroadcastReport, BroadcastDetails, TriggerReport, MulticastReport, MulticastDetails } from "./pages/Operation";

import { CooperationList, CooperationForm } from "./pages/Cooperation";
import { CouponList, NewCoupon, EditCoupon, DetailCoupon, CopyCoupon } from "./pages/Coupon";
import { CouponReports } from "./pages/CouponReports";
import AccountList from "./pages/AccountList";
import AgencyPage from "./pages/Agency";
import PlanConfiguration from "./pages/PlanConfiguration";
import LoadingPage from "./components/LoadingPage";
import AdminEdit from "./pages/Admin/UserEdit";
import Notfound from "./pages/404";
import Dashboard from "./pages/Dashboard";
import Chat from "./pages/Chat";
import { useCookies } from "react-cookie";
import { VerifyEmail } from "./pages/EmailChange";
import { Webhook } from "./pages/Webhook";
import TargetFormList, { TargetFormNew, TargetFormEdit } from "./pages/TargetForm";
import { StaffNew, StaffList, StaffEdit, StaffSettings } from "./pages/Staff";
import ScenarioList, { EditScenario, NewScenario } from "./pages/Scenario";
import TriggerList, { EditTrigger, NewTrigger } from "./pages/Trigger";

// import TriggerList, { EditTrigger, NewTrigger } from "./pages/TriggerAction";
import { TaxList } from "./pages/Tax";

import { ImagemapImage, ImagemapNewImage, ImagemapUpdateImage } from "./pages/Imagemap/image";
import { ImagemapVideo, ImagemapNewVideo, ImagemapUpdateVideo } from "./pages/Imagemap/video";
import { LoginInfo, LoginInfoEdit, LoginInfoNew } from "./pages/LoginInfo";

// Connective
import Connective from "./pages/RakutenConnective/Edit";
import RakuponCon from "./pages/RakuponConnect/Edit";
import { RakutenOrderList, RakutenFollowerList, RakutenFollowerRewviews } from "./pages/RakutenOrder";
import { PrivacyPolicy, TermsOfUse, DocumentEdit } from "./pages/Document";
import { LoadingOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";

// Payment blocked

import PaymentBlocked from "./pages/PaymentBlocked";
import { ConfigProvider, Tag } from "antd";

// Carousel
import Carousel, { CarouselNew, CarouselEdit } from "./pages/Carousel";
import CustomerEcm from "./pages/Admin/CustomerEcm";
import MulticastCarouselDetail from "./pages/Operation/components/MulticastCarouselDetail";

// Liff application
import { LiffApplicationList } from "./pages/Liff";
import RichMenuAliasNew from "./pages/RichMenuAlias/New";
import RichMenuAliasEdit from "./pages/RichMenuAlias/Edit";
import RichMenuAliasCopy from "./pages/RichMenuAlias/Copy";
import { FileList } from "./pages/File";
import { RegularList } from "./pages/Regular";
import { TokenList } from "./pages/Token";
import { RmsReport } from "./pages/RmsReport";
import { reach } from "yup";
import Translate from "./components/Translate";
import Check from "./pages/RakutenConnective/Check";
import { account as accountApi } from "./apis";
import { RakutenCategoryList } from "./pages/RakutenCategory";


export default () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["api.yobi-com.sec"]);
  const { translate } = useTranslate();
  const { logged, loading, account, user, rakutenAccessHide, accounts, customer, lang, checkRakutenConnective } = useSelector(state => state.general);
  const [open, setOpen] = React.useState(false);
  const [hide, setHide] = React.useState(false);

  useMemo(() => {
    let check_error = false;
    if (account?.rakutenAccessShow) {
      const keysToCheck = [
        "licenseDateGet",
        "rpay_order_getOrder",
        "rpay_order_searchOrder",
        "coupon_issue",
        "coupon_update",
        "coupon_delete",
        "coupon_search",
        "coupon_get",
        "cabinet_folder_insert",
        "cabinet_file_insert",
        "cabinet_files_search",
        "shop_categories_get",
        "item_category_get",
        "shop_categories_getv2",
        "shop_status_get",
      ];
      let failCount = 0;
      keysToCheck.forEach(key => {
        if (account?.rakutenAccess[key] === false) {
          failCount++;
        }
      });
      if (failCount > 0) check_error = true;
      console.log("-----checkerror", check_error);
      console.log("-----rakutenAccessHide----->", rakutenAccessHide);
      if (account?.rakutenAccessShow){
        if (account?.rakutenAccess?.status === "FAILED"){
          setOpen(true);
        }
        if (account?.rakutenAccess?.status === "PARTLY_FAILED"){
          if (rakutenAccessHide && !check_error){
            setOpen(false);
          } else {
            setOpen(true);
          }
        }
      } else {
        setOpen(false);
      }
    }

  }, [account]);

  const onClose = () => {
    setOpen(false);
    accountApi.rakutenAccessShow({ hide });
  };

  const accountCheck = (component) => {
    // return accounts.length === 0 && user.balance === null ? PlanConfiguration : accounts.length === 0 && user.balance ? AccountList : component;
    // // account === undefined && user.balance ? AccountList : component;
    if (customer && customer?.paymentBlock === true) {
      return PaymentBlocked;
    }
    if (customer && !account) {
      return AccountList;
    }
    return component;
  };
  const planCheck = (component) => {

    if (accounts === null && user.role === "ACCOUNT") {
      return AccountList;
    }
    if (customer && customer?.paymentBlock === true) {
      return PaymentBlocked;
    }
    return component;

    // return accounts === null &&
    //   user.role === "ACCOUNT" ?
    //   AccountList :
    //   account === undefined && user.role === "ACCOUNT" ? AccountList : component;
  };

  React.useEffect(() => {
    const init = async () =>{
      await Promise.all([general.init()(dispatch), auth.me()(dispatch)]);
      dispatch({ type: "loading", payload: false });
    };
    if (loading === true){
      init();
    }
  }, [dispatch, loading]);

  React.useEffect(() => {
    if (logged === false)
      removeCookie("api.yobi-com.sec");
  }, [logged]);


  if (loading) return <LoadingPage />;

  const renderTitle =()=> {
    let status = account && account?.rakutenAccess?.status;
    switch (status){
      case "FAILED":
        return {
          title : "FAILED",
          color : "red",
          status: status
        };
      case "PARTLY_FAILED":
        return {
          title : "PARTLY_FAILED",
          color : "orange",
          status: status
        };
      case "SUCCESS":
        return {
          title : "SUCCESS",
          color : "green",
          status: status
        };
      default:
        return {
          title : "SUCCESS",
          color : "green",
          status: status
        };
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <ConfigProvider locale={lang === "JP" ? jaJP : null}>
        <Switch>
          <Route path="/password/:token" component={Password} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/terms" component={TermsOfUse} exact/>
          <Route path="/ecm_verify/:token" component={VerifyEcm}/>
          <Route path="/staff/verify/:token" component={VerifyStaff} />
          <Route path="/email/verify/:token" component={VerifyEmail} exact />
          <Route path="/signup" component={Signup} />
          <Route path="/verify/:token" component={Verify} />
          <Route path="/disable/confirm/:token" component={DisableConfirm} />
          <Route path="/forgot" component={Forgot} />
          <Route path="/forgot" />
          <Route path="/thankYou" component={ThankYou} />

          <PublicRoute path="/login" component={Login} />
          {/* <PublicRoute path="/termss" component={TermsOfUse} /> */}

          <PrivateRoute path="/account/new" component={NewAccount} exact />
          <PrivateRoute path="/account/:id" component={EditAccount} exact />

          <PrivateRoute path="/plan/configuration" component={PlanConfiguration} />
          <PrivateRoute path="/products/scenario/new" component={accountCheck(NewScenario)} exact />
          <PrivateRoute path="/products/scenario/:id/:type?" component={accountCheck(EditScenario)} exact />

          <PrivateRoute path="/products/trigger/new" component={accountCheck(NewTrigger)} exact />
          <PrivateRoute path="/products/trigger/:id/:type?" component={accountCheck(EditTrigger)} exact />


          {/* <PrivateRoute path="/products/trigger/new" component={accountCheck(NewTrigger)} exact />
          <PrivateRoute path="/products/trigger/:id/:type?" component={accountCheck(EditTrigger)} exact /> */}

          <PrivateRoute path="/">
            <Container>
              <div className="content">
                <div className="navbar">
                  <AccountBar />
                  <NavBar />
                </div>

                <div className="wrapper">
                  <Switch>
                    <Route path="/" component={accountCheck(Dashboard)} exact />
                    <Route path="/chat" component={accountCheck(Chat)} exact />
                    <Route path="/list/account" component={AccountList} exact />

                    <Route path="/token" component={accountCheck(TokenList)} exact />

                    <Route path="/message/sent" component={accountCheck(SentList)} exact />
                    <Route path="/message/send" component={accountCheck(SendList)} exact />
                    <Route path="/message/send/new" component={accountCheck(NewMessage)} exact />
                    <Route path="/message/send/edit/:id" component={accountCheck(EditMessage)} exact />
                    <Route path="/message/send/reply/:id" component={accountCheck(ReplyMessage)} exact />
                    <Route path="/message/template" component={accountCheck(MessageTemplateList)} exact />
                    <Route path="/message/template/new" component={accountCheck(MessageTemplateNew)} exact />
                    <Route path="/message/template/edit/:id" component={accountCheck(MessageTemplateEdit)} exact />
                    <Route path="/message/sent/detail/:id" component={accountCheck(SentDetail)} exact />
                    <Route path="/message/target_form" component={accountCheck(TargetFormList)} exact />
                    <Route path="/message/target_form/edit/:id" component={accountCheck(TargetFormEdit)} exact />
                    <Route path="/message/target_form/new" component={accountCheck(TargetFormNew)} exact />
                    <Route path="/message/regular" component={accountCheck(RegularList)} exact />

                    <Route path="/imagemap/image" component={accountCheck(ImagemapImage)} exact />
                    <Route path="/imagemap/video" component={accountCheck(ImagemapVideo)} exact />

                    <Route path="/imagemap/image/create" component={accountCheck(ImagemapNewImage)} exact />
                    <Route path="/imagemap/video/create" component={accountCheck(ImagemapNewVideo)} exact />
                    <Route path="/imagemap/image/:id" component={accountCheck(ImagemapUpdateImage)} exact />
                    <Route path="/imagemap/video/:id" component={accountCheck(ImagemapUpdateVideo)} exact />

                    <Route path="/staff" component={accountCheck(StaffList)} exact />
                    <Route path="/staff/create" component={accountCheck(StaffNew)} exact />
                    <Route path="/settings/staff" component={accountCheck(StaffSettings)} exact />
                    <Route path="/staff/edit/:id" component={accountCheck(StaffEdit)} exact />
                    <Route path="/file" component={FileList} exact />
                    <Route path="/admin_file" component={FileListAdmin} exact />

                    <Route path="/products/tags" component={accountCheck(TagsList)} exact />
                    <Route path="/products/richmenu" component={accountCheck(RichMenuList)} exact />
                    <Route path="/products/richmenu/new" component={accountCheck(NewRichMenu)} exact />
                    <Route path="/products/richmenu/:id" component={accountCheck(EditRichMenu)} exact />
                    <Route path="/products/richmenu/copy/:id" component={accountCheck(CopyRichMenu)} exact />

                    <Route path="/products/richmenu/alias/new" component={accountCheck(RichMenuAliasNew)} exact />
                    <Route path="/products/richmenu/alias/:id" component={accountCheck(RichMenuAliasEdit)} exact />
                    <Route path="/products/richmenu_alias/copy/:id" component={accountCheck(RichMenuAliasCopy)} exact />

                    <Route path="/products/scenario" component={accountCheck(ScenarioList)} exact />
                    <Route path="/products/trigger" component={accountCheck(TriggerList)} exact />
                    {/* DetailCoupon */}
                    <Route path="/rakuten/cooperation" component={accountCheck(CooperationList)} exact />
                    <Route path="/rakuten/coupon" component={accountCheck(CouponList)} exact />
                    <Route path="/rakuten/coupon/new" component={accountCheck(NewCoupon)} exact />
                    <Route path="/rakuten/connective" component={accountCheck(Connective)} exact />
                    <Route path="/rakupon/con" component={accountCheck(RakuponCon)} exact />
                    <Route path="/rakuten/coupon/detail/:id" component={accountCheck(DetailCoupon)} exact />
                    <Route path="/rakuten/coupon/edit/:id" component={accountCheck(EditCoupon)} exact />
                    <Route path="/rakuten/coupon/copy/:id" component={accountCheck(CopyCoupon)} exact />
                    <Route path="/rakuten/cooperation/:type" component={accountCheck(CooperationForm)} exact />

                    <Route path="/rakuten/reports" component={accountCheck(CouponReports)} exact />
                    <Route path="/rakuten/category" component={accountCheck(RakutenCategoryList)} exact />

                    <Route path="/webhook" component={accountCheck(Webhook)} exact />
                    <Route path="/follower/list" component={accountCheck(FollowerList)} exact />
                    <Route path="/news/detail/:id" component={NewsDetail} exact />

                    <Route path="/operation/payment" component={PaymentList} exact />
                    <Route path="/operation/operation_report" component={planCheck(ReportList)} exact />
                    <Route path="/operation/log" component={planCheck(LogList)} exact />
                    <Route path="/operation/webhook_report" component={accountCheck(WebhookList)} exact />
                    <Route path="/operation/scenario_report" component={accountCheck(ScenarioReport)} exact />
                    <Route path="/operation/broadcast_report" component={accountCheck(BroadcastReport)} exact />
                    <Route path="/operation/multicast_report" component={accountCheck(MulticastReport)} exact />
                    <Route path="/operation/multicast_report/:id" component={accountCheck(MulticastDetails)} exact />
                    <Route path="/operation/multicast_report/carousel/:id" component={accountCheck(MulticastCarouselDetail)} exact />
                    <Route path="/operation/broadcast_report/:id" component={accountCheck(BroadcastDetails)} exact />
                    <Route path="/operation/trigger_report" component={accountCheck(TriggerReport)} exact />

                    <Route path="/admin/news" component={NewsList} exact />
                    <Route path="/admin/news/new" component={NewNews} exact />
                    <Route path="/admin/news/:id" component={EditNews} exact />

                    <Route path="/admin/plan" component={Plan} exact />
                    <Route path="/admin/customer/add" component={AddCustomer} exact />
                    <Route path="/admin/debug" component={Debug} exact />
                    <Route path="/admin/account/list" component={AccountListAdmin} exact />
                    <Route path="/admin/customer/register" component={RegisterCustomer} exact />

                    <Route path="/user/list" component={UserList} exact />
                    <Route path="/user/create" component={CreateUser} exact />
                    <Route path="/user/update/:id" component={UpdateUser} exact />

                    <Route path="/customer" component={CustomerList} exact />
                    <Route path="/crawler" component={CrawlerList} exact />
                    <Route path="/customer/email/change" component={EmailChange} exact />
                    <Route path="/customer/:id" component={CustomerEdit} exact />
                    <Route path="/customer/ecm/:id" component={CustomerEcm} exact />

                    <Route path="/products/rakuten/followers" component={RakutenFollowerList} exact />
                    <Route path="/products/rakuten/:id/orders" component={RakutenOrderList} exact />
                    <Route path="/products/rakuten/:id/reviews" component={RakutenFollowerRewviews} exact />

                    <Route path="/settings/reference/:type" component={ReferenceList} exact />
                    <Route path="/settings/localization" component={LocalizationList} exact />
                    <Route path="/settings/tax" component={TaxList} exact />
                    <Route path="/system/setting" component={SettingList} exact />
                    <Route path="/agency/customer" component={AgencyPage} />

                    <Route path="/configuration" component={AdminEdit} exact />
                    <Route path="/payment/methods" component={PaymentMethod} exact />

                    <Route path="/info/login" component={LoginInfo} exact />
                    <Route path="/info/login/new" component={LoginInfoNew} exact />
                    <Route path="/info/login/edit/:id" component={LoginInfoEdit} exact />

                    <Route path="/carousel/new" component={accountCheck(CarouselNew)} exact />
                    <Route path="/carousel/edit/:id" component={accountCheck(CarouselEdit)} exact />
                    <Route path="/carousel" component={accountCheck(Carousel)} exact />

                    <Route path="/liff/application" component={accountCheck(LiffApplicationList)} exact />


                    <Route path="/settings/document/:type" component={DocumentEdit} />
                    <Route path="/rms_report" component={RmsReport} />
                    <Route component={Notfound} />
                  </Switch>
                </div>
              </div>
            </Container>
          </PrivateRoute>
        </Switch>
        <LoadModal
          maskClosable={false}
          closable={false}
          visible={!!(account && account.followerSyncStatus === "PENDING")}
          footer={[<div></div>]}>
          {/* <Spin> */}
          <div className="load-modal-container">
            {/* <img className="logo" src={LogoWhite} /> */}
            <div>
              <LoadingOutlined />
            </div>
            <label className="description">{translate("followers.get.load", "Updating User List ...")}</label>
          </div>
          {/* </Spin> */}
        </LoadModal>
        <PopupModal
          width={800}
          maskClosable = {false}
          title={
            <div>
              <span>{translate("api.check.connection", "Check")}</span>
              <Tag style={{ marginLeft: "10px" }} color={renderTitle().color}>{translate(`api.check.status.${renderTitle().status}`, renderTitle().title)}</Tag>
            </div>
          }
          visible={open}
          onCancel={() => onClose()}
          footer={false}>
          <ModalContainer>
            <Check hideSuccess rakutenAccessHide action={account} onCheck={(e) => {
              setHide(e.target.checked);
            }} />
          </ModalContainer>
        </PopupModal>

        <LoadModal
          maskClosable={false}
          closable={false}
          visible={!!(account && account.fileExportStatus === "PENDING")}
          footer={[<div></div>]}>
          {/* <Spin> */}
          <div className="load-modal-container">
            {/* <img className="logo" src={LogoWhite} /> */}
            <div>
              <LoadingOutlined />
            </div>
            <label className="description">{translate("payment.history.file.export", "File export ...")}</label>
          </div>
          {/* </Spin> */}
        </LoadModal>
        <LoadModal
          maskClosable={false}
          closable={false}
          visible={checkRakutenConnective}
          footer={[<div></div>]}>
          {/* <Spin> */}
          <div className="load-modal-container">
            {/* <img className="logo" src={LogoWhite} /> */}
            <div>
              <LoadingOutlined />
            </div>
            <label className="description">{translate("api.check.loading", "Connective check please wait ...")}</label>
          </div>
          {/* </Spin> */}
        </LoadModal>

        <MessageSocket />
      </ConfigProvider>
    </div>
  );
};


const PopupModal = styled(Modal)`
  .load-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
`;


const LoadModal = styled(Modal)`
  .anticon {
    font-size: 35px;
    color: white; 
  }
  .description {
    color: white;
    font-weight: bold;
    top: 60%;
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.70) !important;
  }
  .ant-modal-content {
    background-color:transparent !important;
    box-shadow :none !important;
  }
  .ant-modal-content {
    -webkit-box-shadow  :none !important;
    box-shadow : none !important;
  }
  .ant-modal-header {
    display : none;
  }
  .ant-modal-footer {
    display :none;
  }
  .ant-modal-content {
    border-radius : 15px;
  }
  .load-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
  .logo {
    width: 100px;
    position: absolute;
    bottom: 60%;
  }
  label {
    position: absolute;
    top: 55%;
  }
`;

const ModalContainer =styled.div`
  height:400px;
  overflow-y: auto;
  &::-webkit-scrollbar {
      height: 1px;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.primaryDark}; 
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${colors.primary}; 
    }
`;

const Container = styled.div`
  height: 100%;
  .ant-spin-dot-item {
    background : ${colors.primaryDark};
  }
  .content {
    position: relative;
    width: 100%;
    height: 100%;
    .navbar {
      display: flex;
      flex-direction: row;
      border-right: 1px solid #e8e8e8;
      min-height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1000;
      width: 370px;
    }
    .wrapper {
      position: relative;
      padding-left: 370px;
      height: 100%;
    }
  }
`;
import { Empty, Spin } from "antd";
import { Select } from "formik-antd";
import React from "react";

const { Option } = Select;

let timeout;
let currentValue;

export default ({ placeholder, loadData, page, limit, name, ...props })=> {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(undefined);
  const [filters, setFilters] = React.useState({
    filter: {
      query: ""
    },
    offset: {
      page : 1,
      limit: 20
    }
  });


  const fetch = (value, callback) =>{
    setLoading(true);
    setRows([]);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    const api = async () => {
      try {
        const res = await loadData(filters);
        setRows(res.rows);
        setLoading(false);
      } catch (error){
        setRows([]);
        setLoading(false);
      }
    };
    timeout = setTimeout(api, 300);
  };

  const handleSearch = value => {
    setFilters({ ...filters, filter: { query: value } });
  };

  const handleChange = value => {
    setValue(value);
    console.log("values ===>", value);
  };

  React.useEffect(()=>{
    fetch();
  }, [filters]);

  return (
    <Select
      onBlur={()=> handleSearch(undefined)}
      name={name}
      showSearch
      value={value}
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      showSearch={true}
      loading={true}
      notFoundContent={loading ? <Spin size="small" /> : <Empty/>}
      // notFoundContent={<Empty/>}
    >
      {rows&&rows?.map(item => <Option key={item._id}>{item.companyName}</Option>)}
    </Select>
  );
};
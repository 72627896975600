import React from "react";
import { Space, Radio, Input, Form } from "antd";
import { PictureOutlined, PlayCircleOutlined, MessageOutlined, FormOutlined, FolderOpenOutlined, ProfileOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { TextMessage, ImageMessage, VideoMessage, StickerMessage, CooperationMessage, TemplateMessage, ImagemapMessage } from "./components";
import StickerIcon from "../../assets/sticker.svg";
import colors from "../../colors";
import { useTranslate } from "../../hooks";
import CarouselMessage from "../Message/components/CarouselMessage";

export default ({ data }) => {
  const { translate } = useTranslate();
  const { message } = useSelector(state => state.message);

  const renderMessage = ({ index, child }) => {

    console.log("MESSAGE DETAILD ==>", child.carouselValues);
    return (
      <WriteMessage key={index}>
        <MessageToolbar>
          <Space className="left">
            <Radio.Group value={child.type}>
              <Radio.Button value="text"><MessageOutlined /></Radio.Button>
              <Radio.Button value="sticker"><img src={StickerIcon} style={{ width: "14px", height: "14px" }} /></Radio.Button>
              <Radio.Button value="image"><PictureOutlined /></Radio.Button>
              <Radio.Button value="video"><PlayCircleOutlined /></Radio.Button>
              <Radio.Button value="cooperation"><FormOutlined /></Radio.Button>
              <Radio.Button value="template"><FolderOpenOutlined /></Radio.Button>
              <Radio.Button value="imagemap"><ProfileOutlined /></Radio.Button>
              <Radio.Button value="carousel"><EditOutlined /></Radio.Button>

            </Radio.Group>
          </Space>
        </MessageToolbar>
        <MessageContent>
          {(() => {
            switch (child.type) {
              case "text":
                return <TextMessage values={child} />;
              case "sticker":
                return <StickerMessage values={child.sticker} />;
              case "image":
                return <ImageMessage values={child.image} />;
              case "video":
                return <VideoMessage values={child.video} />;
              case "cooperation":
                return <CooperationMessage values={child.cooperationValues} />;
              case "template":
                return <TemplateMessage values={child.templateValues} />;
              case "imagemap":
                return <ImagemapMessage index={index} name={`children[${index}].imagemap`} values={child.imagemapValues} />;
              case "carousel":
                return <CarouselMessage type="detail" index={index} name={`children[${index}].carousel`} values={child.carouselValues} />;
              default:
                return null;
            }
          })()}
        </MessageContent>
      </WriteMessage>
    );
  };

  return (
    <MessageContainer>
      <Form layout="vertical">
        {data && (
          <Form.Item label={translate("message.title", "Title")} required>
            <Input value={data.title} placeholder={translate("message.placeholder", "Title")} disabled />
          </Form.Item>
        )}

        {(data ? data.children : message.children).map((child, index) => {
          return renderMessage({ index, child });
        })}
      </Form>
    </MessageContainer>
  );
};
const MessageContainer = styled.div`
  .ant-input-disabled {
    background: #fff!important;
    color: #333!important;
  }
`;
const MessageToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: rgba(0,0,0,0.03);
  border-bottom: 1px solid rgba(0,0,0,0.125);

  .close-button{
    background-color: #f7f7f7;
  }
`;
const MessageContent = styled.div`
  padding: 15px;
  .max-length {
    margin-top: 5px;
    text-align: right;
    font-size: 14px;
    color: #adb5bd;
    .length {
      font-weight: 500;
    }
  }
`;
const WriteMessage = styled.div`
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 20px;
  .ant-radio-button-wrapper {
    &:hover {
      color: ${colors.primary};
    }
  }
  .ant-radio-button-wrapper-checked {
    &:hover {
      color: ${colors.primary}!important;
    }
    color: ${colors.primaryDark};
    border-color: ${colors.primaryDark}!important;
    &:before {
      background-color: ${colors.primaryDark}!important;
    }
  }
`;
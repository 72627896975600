const initialState = {
  type  : "rakuten_order",
  name  : "Rakuten order number",
  html  : undefined,
  header: {
    logo           : "https://profile.line-scdn.net/0hnlgJqnbGMUNaMxiGB0FOFGZ2Py4tHTcLIgV4JX5jbiYgCnJAM1F9cCs0aHFwACYSMVYtcXw0PHR_",
    title          : "Rakuten coupon",
    backgroundColor: "#fffff"
  },
  footer: {
    image          : undefined,
    backgroundColor: "#fffff"
  },
  rakuten: {
    serviceSecret: undefined,
    licenseKey   : undefined
  },
  backgroundColor: "#fffff"
};

// const initialState = {
//   type: "rakuten_order",
//   name: undefined,
//   html: undefined,
//   header: {
//     logo: undefined,
//     title: undefined,
//     backgroundColor: undefined
//   },
//   footer: {
//     image: undefined,
//     backgroundColor: undefined
//   },
//   rakuten: {
//     serviceSecret: undefined,
//     licenseKey: undefined
//   },
//   backgroundColor: undefined,
// };

const cooperation = (state = initialState, action) => {
  switch (action.type) {
    case "cooperation.data": {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};

export default cooperation;
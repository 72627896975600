import React from "react";
import Form from "./Form";
import styled from "styled-components";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { staff as staffApi, grant as grantApi, user as userApi } from "../../apis";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Button, Col, message, Row } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import PasswordForm from "../Admin/components/PasswordForm";



export default ()=> {
  const { translate } = useTranslate();
  const params = useParams();
  const { checkRole } = usePermission();
  const [password, setPassword] = React.useState(null);
  const [staff, fetching] = useFetch(staffApi.get, params.id)({});
  const [action, setAction] = React.useState(["update", staff]);
  const history = useHistory();


  const onChangePasword = () => {
    setPassword(["create"]);
  };
  const onCancel = () => {
    setPassword(null);
  };

  const onSubmit =async (data)=> {

    try {
      await staffApi.update({
        ...data,
        email: data.email.trim()
      });
      message.success(translate("system.message.success", "Success"));
      history.push("/staff");
    } catch (err){
      if (err.message === "ALREADY_REGISTERED") {
        message.error(translate("line.alert2", "This account has been registered already!"));
      } else {
        console.log(err.message);
      }
    }
  };

  const onSubmitPassword = async (data) => {
    if (checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN"]) === true) {
      try {
        await grantApi.changePassword({ newPassword: data.newPassword, password: data.password, id: staff._id });
        message.success(translate("system.message.success", "Password has been changed successfully."));
        setPassword();
      } catch (error) {
        if (error.message === "INVALID_PASSWORD") {
          message.error(translate("system.invalid.password", "The password is incorrect. Retype your password. Letters in passwords must be typed using the correct case"));
        }
        else {
          message.error(translate("system.error", error.error));
        }
      }
    }
  };

  return (
    <div>
      <PageContainer>
        <PageHeader onBack={() => history.push("/staff")} title={translate("staff.edit", "Edit staff")} />
        <PageContent>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormWrapper>
              <Row>
                <Col span={16}>
                  <div style={{ marginBottom: 20 }}>
                    <Button type="default" onClick={onChangePasword} block>{translate("useredit.password", "Change Password")}</Button>
                  </div>
                </Col>
              </Row>
              {fetching === false&&<Form action={["update", staff]} onSubmit={onSubmit} />}
            </FormWrapper>
          </div>
        </PageContent>

        <Modal
          maskClosable={false}
          title={translate("useredit.password", "Change password")}
          visible={!!password}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          <PasswordForm onCancel={onCancel} onSubmit={onSubmitPassword} action={password} />
        </Modal>

      </PageContainer>
    </div>
  );

};


const FormWrapper = styled.div`
  width: 600px;
`;
import request from "../utils/request";

export const send = (data) => request.post("/api/dashboard/send", { ...data });
export const sent = (data) => request.post("/api/dashboard/sent", { ...data });
export const users = (data) => request.get("/api/dashboard/users", data);
export const plans = (data) => request.post("/api/dashboard/plans", data);






import React from "react";
import { Form, FormItem, Input, Select, Checkbox, DatePicker, TimePicker } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Row, Col } from "antd";
import { tailFormItemLayout } from "../../utils";
import { useSelector } from "react-redux";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import colors from "../../colors";
import * as Yup from "yup";

const FormSchema =(translate) => Yup.object({
  permissions: Yup.array().min(1).required(translate("system.message.required", "This field is required!")),
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const { tokenPermissions } = useSelector(state => state.general);
  const [data] = React.useState({
    permissions: undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      key={"tokenform"}
      id="tokenform"
      onSubmit={onSubmit}>
      {({ isSubmitting, values, handleSubmit }) => {
        return (
          <Form >
            <FormItem label={translate("token.form.permissions", "Permissions")} name="permissions" required>
              <Checkbox.Group name="permissions" style={{ width: "100%" }}>
                <Row>
                  {tokenPermissions.map((item, index) => (
                    <Col key={index} span={8}>
                      <Checkbox value={item}> {translate(item, item)}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </FormItem>
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
              <Button onClick={handleSubmit} type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

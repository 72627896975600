import React from "react";
import { Tooltip } from "antd";
import { Button } from "../../../components/Design";

export default ({ record, current, purchase, pay, translate, order, purchaseCancel }) => {

  if (order.planData._id === record._id) {
    return <Button danger onClick={() => purchaseCancel(order._id)} style={{ width: "120px" }} key="key 1" type="primary">{translate("plan.order.cancel", "Cancel order")}</Button>;
  }

  if (current && current._id === record._id)
    return <Button style={{ width: "120px" }} type="primary" disabled>{translate("plan.current.title", "Current Plan")}</Button>;
  if (record.amount === 0)
    return <Button style={{ width: "120px" }} onClick={() => purchase(record)} type="primary">{translate("plan.free", "FREE")}</Button>;
  if (record.invoice)
    return (
      <Tooltip placement="right" title={"Waiting for payment..."}>
        <Button onClick={() => pay(record)} style={{ width: "116px" }} type="default">{translate("plan.payment.status", "Pay")}</Button>
      </Tooltip>
    );

  if (current) {
    if (current.type === "PAID" || current.type === "LIMITED") {
      return <Button onClick={() => purchase(record)} style={{ width: "120px" }} key="key 1" type="primary">{translate("plan.order", "Order")}</Button>;
    }
    return <Button onClick={() => purchase(record)} style={{ width: "120px" }} key="key 1" type="primary">{translate("plan.buy", "Purchase")}</Button>;

  }
  return <Button onClick={() => purchase(record)} style={{ width: "120px" }} key="key 1" type="primary">{translate("plan.buy", "Purchase")}</Button>;

};
import { useMemo } from "react";
import { useSelector } from "react-redux";

export default () => {
  const { customer:_customer, user } = useSelector(state => state.general);

  const customer = useMemo(() => {
    console.log("usel_plan_hook", _customer);
    return _customer;
  }, [_customer]);

  return {
    checkPermission: (permission) => {
      if (user?.role === "ADMIN"){
        return true;
      }
      if (!customer?.plan?.permissions){
        console.log("=========NO_PLAN_PERMISSION");
        return false;
      }
      if (!customer.plan.permissions.includes(permission)) {
        console.log("=========PALN_PERMISSION_NOTINCLUDED");
        return false;
      }
      return true;
    }
  };
};

import React from "react";
import styled from "styled-components";

export default ({ name, onSelect }) => {
  return (
    <RichGrid onClick={() => onSelect(name)}>{name}</RichGrid>
  );
};

const RichGrid = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
`;
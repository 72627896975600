import React from "react";
import { Button, Modal, message } from "antd";
import { useDispatch } from "react-redux";
import { useTranslate } from "../../../hooks";
import Upload from "./components/Upload";
import ImagemapTemplate from "../../../components/ImagemapTemplate";
import { PageContainer, PageContent, PageHeader } from "../../../components/Layout";
import Form from "./components/Form";
import styled from "styled-components";
import { imagemap as imagemapApi } from "../../../apis";
import { useHistory } from "react-router";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import colors from "../../../colors";

const { confirm } = Modal;

export default ({ editable }) => {
  const formRef = React.useRef();
  const history = useHistory();
  const [action, setAction] = React.useState([false, null, null]);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const [data, setFormData] = React.useState({
    type      : "large",
    template  : "A",
    mapType   : "video",
    baseUrl   : undefined,
    altText   : undefined,
    previewUrl: undefined,
    ...(editable && editable[0] === "update" ? editable[1] : {})
  });

  const onCancel = () => {
    setAction([null]);
  };

  const onUploaded = (uploaded) => {
    let payload = {
      ...data,
      baseUrl   : uploaded.originalContentUrl,
      previewUrl: uploaded.previewImageUrl,
      baseSize  : uploaded.baseSize
    };

    setFormData(payload);

    dispatch({
      type: "general.imagemapSelect",
      payload
    });

    setErrors({});
    onCancel();
  };

  const onRemove = () => {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("system.confirmation.question", "Are you sure ?"),
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      async onOk() {
        await imagemapApi.remove(data._id);

        message.success(translate("system.message.success", "Success!"));
        history.push("/imagemap/video");
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };

  const onCopy = async () => {
    let result = await imagemapApi.copy(data._id);
    message.success(translate("system.message.success", "Successful"));
    history.push(`/imagemap/video/${result._id}`);
  };

  const onSave = async () => {
    let valid = await formRef.current.validate();

    if (!data.baseUrl) {
      setErrors({ baseUrl: translate("system.video.required", "Please upload a video!") });
      message.error(translate("system.video.required", "Please upload a video!"));

      return;
    }

    if (!valid) {
      message.error(translate("system.error.required", "Insert necessary fields first!"));
      return;
    }

    let payload = {
      _id       : data._id,
      altText   : valid.altText,
      baseSize  : data.baseSize,
      type      : data.type,
      template  : data.template,
      mapType   : data.mapType,
      baseUrl   : data.baseUrl,
      previewUrl: data.previewUrl,
      video     : {
        area: {
          x     : 0,
          y     : 0,
          width : data.baseSize.width,
          height: data.baseSize.height
        },
        originalContentUrl: data.baseUrl,
        previewImageUrl   : data.previewUrl,
        externalLink      : {
          ...valid.externalLink,
          linkUri: valid.externalLink.linkUri.trim()
        }
      },
      actions: [{
        cooperation : undefined,
        cooperations: undefined,
        label       : undefined,
        text        : "hello",
        type        : "message",
        uri         : undefined,
        area        : {
          x     : 0,
          y     : 0,
          width : data.baseSize.width,
          height: data.baseSize.height
        },
      }]
    };

    if (editable[0] === "update") {
      await imagemapApi.update(payload);
    } else {
      await imagemapApi.create(payload);
    }

    message.success(translate("system.message.success", "Success!"));
    history.push("/imagemap/video");
  };

  return (
    <PageContainer>
      <PageHeader title={editable && editable[0] === "update" ? translate("imagemap.update.video", "Edit rich video message") : translate("imagemap.create.video", "New rich video message")} extra={[
        editable && editable[0] === "update" && <Button key={2} loading={loading} type='default' onClick={onCopy}>{translate("system.copy", "Copy")}</Button>,
        editable && editable[0] === "update" && <Button key={1} loading={loading} type="danger" onClick={onRemove}>{translate("system.delete", "Remove")}</Button>,
        <Button key={3} type="primary" loading={loading} onClick={() => onSave("SEND")}>{translate("system.save", "Save")}</Button>
      ]} />

      <PageContent>
        <div style={{ display: "flex" }}>
          <TemplateWrapper>
            <ImagemapTemplate
              width={data.baseSize && data.baseSize.width ? (data.baseSize.width/3) :247}
              height={data.baseSize &&data.baseSize.height ? (data.baseSize.height/3) : 247}
              type={data.type}
              template={"G"}
              image={data.previewUrl}
              errors={errors} />

            <div className="button-wrapper">
              <Button type="primary" style={{ width: "100%", marginTop: "5px" }} onClick={() => setAction(["image", data.baseUrl])} block>{translate("imagemap.upload.video", "Upload video")}</Button>
              {errors.baseUrl && <div style={{ color: "#f00" }} className="error">{errors.baseUrl}</div>}
            </div>
          </TemplateWrapper>

          <div style={{ width: "100%", marginLeft: "20px" }}>
            <Form editable={editable} ref={formRef} />
          </div>
        </div>

        <Modal
          maskClosable={false}
          title={translate("imagemap.upload", "Upload")}
          visible={action && action[0] === "image"}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          <Upload action="/api/imagemap/upload"name="upload" type={data.type} onCancel={onCancel} onUpload={onUploaded} />
        </Modal>

      </PageContent>
    </PageContainer>
  );
};

const TemplateWrapper = styled.div`
  display : flex;
  flex-direction : column;
  .button-wrapper {
    display: flex;
    flex-direction: column;
  }
`;

import React, { useMemo } from "react";
import { Button, Modal, Radio, message, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../../hooks";
import { PageContainer, PageHeader, PageContent } from "../../../components/Layout";
import { imagemap as imagemapApi } from "../../../apis";
import { useHistory } from "react-router";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Upload from "./components/Upload";
import ImagemapTemplate, { TemplateMenu } from "../../../components/ImagemapTemplate";
import styled from "styled-components";
import Form from "./components/Form";
import colors from "../../../colors";

const { confirm } = Modal;

export default (props) => {
  const { editable } = props;
  const formRef = React.useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { translate } = useTranslate();
  const { imageMapTemplates, imageMapSelectTemplates, imageMapSelectBounds } = useSelector(state => state.general);
  const [loading, setLoading] = React.useState(false);
  const [openCollapse, setOpenCollapse] = React.useState();
  const [action, setAction] = React.useState([false, null, null]);
  const [errors, setErrors] = React.useState({});

  const [data, setFormData] = React.useState({
    type      : "large",
    template  : "A",
    baseUrl   : undefined,
    baseSize  : {},
    altText   : undefined,
    previewUrl: undefined,
    mapType   : "image",
    actions   : [],
    ...(editable && editable[0] === "update" ? editable[1] : {}),
  });

  const onSave = async () => {
    let valid = await formRef.current.validate();

    if (!data.baseUrl) {
      setErrors({ baseUrl: translate("system.image.required", "Please upload an image!") });
      message.error(translate("system.image.required", "Please upload an image!"));

      return;
    }

    console.log("valid =>", valid);

    if (valid === false || !valid.actions || valid.actions.length === 0) {
      setOpenCollapse(imageMapSelectTemplates);
      message.error(translate("system.error.required", "Insert necessary fields first!"));
      return;
    }

    let actionCheck = [];

    // eslint-disable-next-line no-unused-expressions
    valid?.actions.forEach(item => {
      if (item.type !== "no_action") {
        actionCheck.push(item);
      }
    });

    if (actionCheck.length === 0) {
      message.error(translate("richmenu.action.required.one", "Set at least one action "));
      setLoading(false);
      return;
    }
    setLoading(true);

    let payload = {
      _id       : data._id,
      type      : data.type,
      template  : data.template,
      baseUrl   : data.baseUrl,
      baseSize  : data.baseSize,
      previewUrl: data.previewUrl,
      altText   : valid.altText,
      mapType   : "image",
      actions   : valid.actions,
    };

    if (editable[0] === "update") {
      await imagemapApi.update({
        ...payload,
        bounds: imageMapSelectBounds
      });
    } else {
      await imagemapApi.create({
        ...payload,
        bounds: imageMapSelectBounds
      });
    }

    setLoading(false);

    message.success(translate("system.message.success", "Success!"));
    history.push("/imagemap/image");
  };


  const onRemove = () => {
    confirm({
      icon             : <ExclamationCircleOutlined />,
      content          : translate("system.confirmation.question", "Are you sure ?"),
      okText           : translate("system.yes", "Yes"),
      cancelText       : translate("system.no", "No"),
      okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
      className        : "btn-custom-class",
      async onOk() {
        await imagemapApi.remove(data._id);
        message.success(translate("system.message.success", "Success!"));
        history.push("/imagemap/image");
      },
    });
  };

  const onChangeCustomType = (e) => {
    const value = [null, e.target.value, e.target.value === "custom" ? "H" : "A"];

    setAction(value);
    onApplyTemplate(value);
  };

  const onApplyTemplate = async (value) => {
    await formRef.current.clear();
    let payload = {
      ...data,
      type    : value ? value[1] : action[1],
      template: value ? value[2] : action[2],
    };

    if (data.type !== payload.type) {
      payload = {
        ...payload,
        baseUrl   : "",
        previewUrl: "",
        baseSize  : {
          height: 1040,
          width : 1040,
        },
      };

      if (["custom", "draw"].indexOf(data.type) > -1 && ["custom", "draw"].indexOf(payload.type) > -1) {
        payload = {
          ...payload,
          baseSize  : data.baseSize,
          baseUrl   : data.baseUrl,
          previewUrl: data.previewUrl
        };
      }
    }

    setFormData(payload);

    dispatch({
      type: "general.imagemapSelect",
      payload
    });

    if (["draw"].indexOf(payload.type) === -1) {
      dispatch({
        type   : "general.imagemapSelect.templates",
        payload: imageMapTemplates[payload.type][payload.template] || []
      });
    }

    onCancel();
  };

  const onSelectRichGrid = (name) => {
    setOpenCollapse(name);
  };

  const onCancel = () => {
    setAction([null]);
  };

  const onCopy = async () => {
    let result = await imagemapApi.copy(data._id);
    message.success(translate("system.message.success", "Successful"));
    history.push(`/imagemap/image/${result._id}`);
  };

  const onUploaded = (uploaded) => {
    setFormData({
      ...data,
      baseUrl   : uploaded.originalContentUrl,
      previewUrl: uploaded.previewImageUrl,
      baseSize  : uploaded.baseSize
    });

    dispatch({
      type   : "general.imagemapSelect",
      payload: {
        ...data,
        baseUrl   : uploaded.originalContentUrl,
        previewUrl: uploaded.previewImageUrl,
        baseSize  : uploaded.baseSize
      }
    });
    setErrors({});
    onCancel();
  };

  // useMemo(() => {
  //   onApplyTemplate();
  // }, [data._id]);

  return (
    <PageContainer>
      <PageHeader title={editable && editable[0] === "update" ? translate("imagemap.update.image", "Edit rich image message") : translate("imagemap.create.image", "New rich image message")} extra={[
        editable && editable[0] === "update" && <Button key={2} loading={loading} type='default' onClick={onCopy}>{translate("system.copy", "Copy")}</Button>,
        editable && editable[0] === "update" && <Button key={1} loading={loading} type="danger" onClick={onRemove}>{translate("system.delete", "Remove")}</Button>,
        <Button key={3} type="primary" loading={loading} onClick={() => onSave("SEND")}>{translate("system.save", "Save")}</Button>
      ]} />
      <PageContent>
        <div style={{ display: "flex" }}>
          <TemplateWrapper style={{ width: (1040 / 3) }}>
            <ImagemapTemplate
              width={(1040 / 3)}
              height={(
                data
                  && data.baseSize
                  && data.baseSize.height ?
                  data.baseSize.height / 3 :
                  1040 / 3
              )}
              type={data.type}
              template={data.template}
              onSelect={onSelectRichGrid}
              image={data.previewUrl}
              errors={errors} />

            {["custom", "draw"].indexOf(data.type) > -1 && (
              <div className="button-group" onChange={onChangeCustomType}>
                <Radio.Group value={data.type}>
                  <Radio.Button value="custom">{translate("imagemap.action.full", "Full")}</Radio.Button>
                  <Radio.Button value="draw">{translate("imagemap.action.draw", "Draw")}</Radio.Button>
                </Radio.Group>
              </div>
            )}

            {errors.image && <div className="error">{errors.image}</div>}

            <div className="button-wrapper">
              <Button type="default" style={{ marginBottom: 10, width: "100%" }} onClick={() => setAction(["template", data.type, data.template])} block>{translate("imagemap.layout", "Select template")}</Button>
              <Button type="primary" style={{ width: "100%" }} onClick={() => setAction(["image", data.baseUrl])} block>{translate("imagemap.upload.image", "Upload Image")}</Button>
              {errors.baseUrl && <div className="has-error">{errors.baseUrl}</div>}
            </div>

            {["custom", "draw"].indexOf(data.type) > -1 && (
              <p className="custom-alert">
                <b>{translate("imagemap.alert.full", "Full")}</b> <br/>
                {translate("imagemap.alert.1", "・Sets up only one action for full image.")} <br/><br/>
                <b>{translate("imagemap.alert.draw", "Draw")}</b> <br/>
                {translate("imagemap.alert.2", "・You can set an action area of any size on the image by drawing with the mouse.")} <br/>
                {translate("imagemap.alert.3", "・The size of the action area can be specified as 15 in height or 15 in width or more.")} <br/>
                {translate("imagemap.alert.4", "・If the area overlaps, the action of the area specified later has higher priority.")} <br/>
                {translate("imagemap.alert.5", "・Action areas can be set up to 26.")}
              </p>
            )}
          </TemplateWrapper>

          <div style={{ width: "100%", marginLeft: "20px" }}>
            {imageMapSelectTemplates && <Form editable={editable} templates={imageMapSelectTemplates} openCollapse={openCollapse} ref={formRef} />}

          </div>
        </div>
        {
          action && action[0] === "template" &&
          <Modal
            maskClosable={false}
            width={598}
            title={translate("imagemap.layout", "Select a template")}
            visible={action && action[0] === "template"}
            onCancel={onCancel}
            destroyOnClose
            footer={false}>
            <ImagemapTemapltesWrapper>
              <div>
                <h3>{translate("imagemap.layout.custom", "Custom")}</h3>
                {Object.keys(imageMapTemplates.custom).map(template => {
                  return <TemplateMenu key={template} width={100} style={{ marginRight: 10, marginBottom: 8 }} type="custom" active={action[1] === "custom" && action[2] === template || data.type === "draw"} template={template} onSelect={() => setAction(["template", "custom", template])} />;
                })}
              </div>
              <div>
                <h3>{translate("imagemap.layout.square", "Square")}</h3>
                {Object.keys(imageMapTemplates.large).map(template => {
                  return <TemplateMenu
                    key={template} width={100}
                    style={{ marginRight: 10, marginBottom: 8 }}
                    type="large"
                    active={action[1] === "large" && action[2] === template}
                    template={template}
                    onSelect={() => setAction(["template", "large", template])} />;
                })}
              </div>
            </ImagemapTemapltesWrapper>
            <TemplateFooter>
              <Button type="default" onClick={() => setAction([null])} style={{ marginRight: 10 }}>{translate("system.cancel", "Cancel")}</Button>
              <Button type="primary" onClick={() => onApplyTemplate()}>{translate("richmenu.layout.apply", "Apply")}</Button>
            </TemplateFooter>
          </Modal>
        }
        {
          action && action[0] === "image" &&
          <Modal
            maskClosable={false}
            title={translate("imagemap.upload", "Upload")}
            visible={action && action[0] === "image"}
            onCancel={onCancel}
            destroyOnClose
            footer={false}>
            <Upload action="/api/imagemap/upload" name="upload" onCancel={onCancel} onUpload={onUploaded} />
          </Modal>
        }
      </PageContent>
    </PageContainer>
  );
};
const TemplateWrapper = styled.div`
  display : flex;
  flex-direction : column;
  .custom-alert {
    padding: 10px;
    margin-top: 10px;
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    .has-error {
      color: red;
      font-size: 13px;
      font-weight: 500;
      width: 240px;
    }
  }
  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
  }
`;

const TemplateFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
`;

const ImagemapTemapltesWrapper = styled.div`
  display:flex
`;

import React, { useState } from "react";
import { Select, Spin, DatePicker } from "antd";
import styled from "styled-components";
import { dashboard } from "../../../../../apis";
import { useTranslate } from "../../../../../hooks";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import colors from "../../../../../colors";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const { RangePicker } = DatePicker;

export default ({ customer, customers }) => {
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const { translate } = useTranslate();
  const { Option } = Select;
  const { user } = useSelector(state => state.general);
  const [load, setLoad] = React.useState(true);
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  let firstDay = new Date(y, m, 1);
  let lastDay = new Date(y, m + 1, 0);
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    filter: {
      startDate: moment(new Date(firstDay)).startOf("day").toDate(),
      endDate  : moment(new Date(lastDay)).endOf("day").toDate()
    }
  });

  const cols = {
    value: { min: 0 },
    day  : { range: [0, 1] },
  };

  const reload = async () => {
    setLoad(true);
    if (customers.length > 0 && customer === undefined) {
      let sent = await dashboard.sent({ customerId: customers&&customers[0]._id, filter: { ...filters.filter } });
      dispatch({ type: "dashboard.sent", payload: sent });
      setData(sent);
      setLoad(false);
    }
    if (customer) {
      let sent = await dashboard.sent({ customerId: customer._id, filter: { ...filters.filter } });
      dispatch({ type: "dashboard.sent", payload: sent });
      setData(sent);
      setLoad(false);
    }
    if (user.role === "ADMIN" && customer === undefined){
      let sent = await dashboard.sent({ customerId: null, filter: { ...filters.filter } });
      dispatch({ type: "dashboard.sent", payload: sent });
      setData(sent);
      setLoad(false);
    }
  };

  const selectCustomer = async (id) => {
    setLoad(true);
    let sent = await dashboard.sent({ customerId: id, filter: { ...filters.filter } });
    dispatch({ type: "dashboard.sent", payload: sent });
    setData(sent);
    setLoad(false);
  };

  const changeRange = (e) => {
    setFilters({ ...filters, filter: { startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() } });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  React.useEffect(() => {
    reload();
  }, [filters]);

  return (
    <ChartWrapper style={{ width: "100%" }}>
      <Spin spinning={load} style={{ height: "280px" }}>
        <div className="chart-header-month">
          <strong>{moment(rangePicker.startDate).format("YYYY-MM")} 月  {
            moment(rangePicker.endDate).format("YYYY-MM") !== moment(rangePicker.startDate).format("YYYY-MM") && ` ~ ${moment(rangePicker.endDate).format("YYYY-MM")}月`
          } </strong>
          {/* <RangePicker size="small"
            allowClear = {false}
            value={[rangePicker.startDate, rangePicker.endDate]}
            onChange={(e) => changeRange(e)} /> */}
        </div>
        <Chart height={350} data={data} scale={cols} forceFit >
          <Axis title={{

            position : "end",
            offset   : 15,
            textStyle: {
              fontSize  : "12",
              textAlign : "center",
              fill      : "#999",
              fontWeight: "bold",
              rotate    : 0,
              autoRotate: true
            }
          }} />
          <Axis title={{
            position : "end",
            offset   : 5.5,
            textStyle: {
              fontSize  : "12",
              textAlign : "right",
              fill      : "#999",
              fontWeight: "bold",
              rotate    : 0
            }
          }} />
          <Tooltip
            color={colors.primary}
            crosshairs={{
              type: "y"
            }}
          />
          <Geom color={colors.primary} type="line" position="day*value" size={1}
            tooltip={["day*value", (day, value) => {
              return {
                name : translate("report.sentcount", "Sent message"),
                value: value,
                title: day
              };
            }]} />
          <Geom
            color={colors.primaryDark}
            type="point"
            position="day*value"
            size={4}
            shape={"circle"}
            style={{
              stroke   : "#fff",
              lineWidth: 1
            }}
            tooltip={["day*value", (day, value) => {
              return {
                name : translate("report.sentcount", "Sent message"),
                value: value,
                title: day
              };
            }]}
          />

        </Chart>
      </Spin>
      <ChartForm>
        <div className="wrap">
          {customers.length >0 && customer === undefined?
            <Select placeholder="select customer" style={{ width: "100%" }} size="small" onChange={(id)=>selectCustomer(id)} defaultValue={customers.length > 0 ? customers[0]._id : null} >
              {customers.map(item => (
                <Option value={item._id} className="select-text">{item.companyName}</Option>
              ))}
            </Select>:null
          }
        </div>
      </ChartForm>
    </ChartWrapper>
  );
};

const ChartForm = styled.div`
  display : flex;
  width : 100%;
  margin : 10px;  
  /* border : solid 1px; */
  padding : 0px 40px;
  justify-content : center;
  margin-top: -67px;
  .select-text {
    font-size : 11px;
  }
  .wrap {
    width : 500px;
    display : flex;
    justify-content: space-between;
  }
`;

const ChartWrapper = styled.div`
  border-radius : 5px;
 .ant-picker-range  {
    height :25px;
  }
  .chart-header-month {
    display :flex;
    justify-content :space-between;
    align-items :center;
    strong {
      z-index: 1;
      font-size: 12px;
      margin: 10px;
    }
  }
`;
/* eslint-disable no-nested-ternary */
import React from "react";
import { Field } from "formik";
import { FormItem } from "formik-antd";
import { Modal, Spin } from "antd";
import { FolderOpenOutlined, CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../../colors";
import { useTranslate } from "../../../hooks";
import List from "../../../pages/MessageTemplate/List";
import Thumbnail from "./Thumbnail";

export default ({ index, name, values }) => {
  const { translate } = useTranslate();
  const [action, setAction] = React.useState(false);
  const [template, setTemplate] = React.useState(values);

  return (
    <FormItem name={name}>
      <Field name={name}>
        {fieldProps => {
          const {
            form: { setFieldValue }
          } = fieldProps;

          const onSelect = (value) => {
            setFieldValue(name, value._id);
            setFieldValue(`children[${index}].templateValues`, value);
            setTemplate(value);
            setAction(null);
          };

          const onRemove = () => {
            setFieldValue(name, undefined);
            setFieldValue(`children[${index}].templateValues`, undefined);
            setTemplate(null);
            setAction(null);
          };

          return (
            <Spin spinning={false}>
              <CooperationContainer>
                {template && template.content ? (
                  <Uploaded>
                    <Thumbnail width={275} values={{ ...template.content, button: template.content.button.text }} />
                    <a className="close" href="#" onClick={() => onRemove({ setFieldValue })}><CloseOutlined /></a>
                  </Uploaded>
                ) : (
                  <SelectContainer onClick={() => setAction(true)}>
                    <FolderOpenOutlined />
                    <div className="upload-text">{translate("message.selecttemplate", "Select template")}</div>
                  </SelectContainer>
                )}

                <Modal
                  maskClosable={false}
                  visible={action}
                  className="cooperation-modal"
                  title={translate("message.selecttemplate", "Select template")}
                  onCancel={() => setAction(false)}
                  footer={null}>
                  <List onSelect={onSelect} />
                </Modal>
              </CooperationContainer>
            </Spin>
          );
        }}
      </Field>
    </FormItem>
  );
};

const Uploaded = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  .close {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -22px;
    background: #6f6f6f;
    color: #fff;
    width: 22px;
    height: 20px;
    text-align: center;
    font-weight: 400;
    transition: all .2s;
    &:hover {
      background: #333;
    }
  }
`;
const CooperationContainer = styled.div`
  .ant-upload {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
    color: #9a9a9a;
  }
`;
const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 25px 0;
  border: 1px dashed #ddd;
  min-height: 160px;
  cursor: pointer;
  border-radius: 4px;
  .anticon {
    font-size: 22px;
    color: #607D8B;
  }
  &:hover {
    .upload-text {
      color: ${colors.primaryDark};
    }
  }
  .upload-text {
    font-size: 13px;
    margin-top: 10px;
    transition: all .2s;
    font-weight: 400;
  }
`;
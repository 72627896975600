const initialState = {
  password: undefined,

  companyName : undefined,
  address     : undefined,
  phoneNumber : undefined,
  businessType: undefined,

  branch    : undefined,
  position  : undefined,
  staffName : undefined,
  firstLogin: true
};

const register = (state = initialState, action) => {
  switch (action.type) {
    case "register/verify/formAutomation":
      return {
        ...state,
        ...action.payload
      };
    case "register/verify/CompanyInfo":
      return {
        ...state,
        ...action.payload,
      };
    case "register/verify/ContactInfo":
      return {
        ...state,
        ...action.payload
      };
    case "register/verify/passwordVerify":
      return {
        ...state,
        ...action.payload
      };
    default :
      return state;
  }
};
export default register;
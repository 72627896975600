import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { message } from "../../apis";
import { Row, Col, Button } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useDispatch } from "react-redux";
import { MessagePreview } from "../../components";
import { useTranslate } from "../../hooks";
import moment from "moment";
import styled from "styled-components";
import MessageDetail from "../../components/MessageDetail";

export default () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { translate } = useTranslate();
  const [loading, setLoading] = React.useState(true);
  const [data, setFormData] = React.useState();
  const [_id, setId] = React.useState();
  const reload = React.useCallback(
    async (signal) => {
      let res = await message.get(params.id, { signal });

      const payload = {
        ...res,
        children: res.children.map(child => ({
          ...child,
          template         : child.template && child.template._id,
          templateValues   : child.template,
          cooperation      : child.cooperation && child.cooperation._id,
          cooperationValues: child.cooperation,
          imagemap         : child.imagemap && child.imagemap._id,
          imagemapValues   : child.imagemap,
          carousel         : child.carousel && child.carousel._id,
          carouselValues   : child.carousel
        })),
        segments: res.targets,
        // tags  : res.tags.map(item => item.tag),
        // values: res.tags.map(item => {
        //   let value = item.value;

        //   if (item.dates.length > 0)
        //     value = [moment(item.dates[0]), moment(item.dates[1])];

        //   if (item.numbers.length > 0)
        //     value = item.numbers;

        //   if (item.checked !== undefined)
        //     value = item.checked;

        //   return {
        //     tag  : item.tag,
        //     value: value
        //   };
        // }),
        _id: undefined
      };

      dispatch({
        type   : "message.message.data",
        payload: payload
      });

      setFormData(payload);
      setId(res._id);
      setLoading(false);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  if (loading)
    return <div>Loading...</div>;

  const onCopy = () => {
    history.push(`/message/send/reply/${_id}`);
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("message.detail", "Message detail")}
        extra={[
          <Button key={2} type="default" loading={loading} onClick={onCopy}>{translate("system.copy", "Copy")}</Button>,
        ]}
      />
      <PageContent>
        <Row gutter={[8, 8]}>
          <Col span={14}>
            <MessageInfo>
              <div className="info">
                <div className="label">{translate("system.title", "Title")}</div>
                <div className="value">{data.title}</div>
              </div>
              <div className="info">
                <div className="label">{translate("message.type", "Target type")}</div>
                <div className="value">{
                  data.targetType !== "PROCESS"?translate(`system.constant.${data.targetType}`, data.targetType):"-"
                }</div>
              </div>
              <div className="info">
                <div className="label">{translate("message.senttype", "Sent type")}</div>
                <div className="value">{
                  data.sentType? translate(`system.constant.${data.sendType}`, data.sendType) : "-"
                }</div>
              </div>
              <div className="info">
                <div className="label">{translate("message.sentdate", "Sent date")}</div>
                <div className="value">{moment(data.messageSentDate).format("YYYY-MM-DD HH:mm") }</div>
              </div>
              <div className="info">
                <div className="label">{translate("message.totalsent", "Total send message")}</div>
                <div className="value">{data.totals}</div>
              </div>
            </MessageInfo>
            <MessageDetail />
          </Col>
        </Row>
        <MessagePreview type="fixed" />
      </PageContent>
    </PageContainer>
  );
};

const MessageInfo = styled.div`
  display: flex;
  flex-direction: column;
  border :1px solid #f0f0f0;
  margin-bottom: 20px;

  .info {
    display: flex;
    flex-direction: row;
    border-bottom : 1px solid #f0f0f0;
    .label {
      display: flex;
      flex: 1;
      background-color:#fafafa;
      padding: 4px 8px;
    }
    .value {
      display: flex;
      flex: 1;
      padding: 4px 8px;
    }
  }
`;
import React from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Alert, Card, Switch } from "antd";
import Button from "../../components/Design/Button";
import * as Yup from "yup";
import { auth } from "../../apis";
import { useHistory, useRouteMatch } from "react-router-dom";
import PasswordIcon from "../../assets/password.png";
import Logo from "../../assets/logo_white.png";
import Humans from "../../assets/humans.png";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { WarningOutlined } from "@ant-design/icons";


const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

const FormSchema = (translate) => Yup.object().shape({
  verifyPassword: Yup.string().required(translate("system.message.required", "This field is required!")),
  password      : Yup.string()
    .required(translate("system.message.required", "This field is required!"))
    .matches(lowercaseRegex, translate("system.password.lowercase", "Your password must have at least one lowercase"))
    .matches(uppercaseRegex, translate("system.password.uppercase", "Your password must have at least one uppercase"))
    .matches(numericRegex, translate("system.password.number", "Your password must have at least one number digit"))
    .min(8, translate("system.password.character", "Your password must have at least 8 characters"))

});

export default () => {
  const { translate, changeLanguage, lang } = useTranslate();
  const history = useHistory();
  const match = useRouteMatch();
  const [error, setError] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [alertBgColor, setAlertBgColor] = React.useState("#E35755");


  const onSubmit = async (data) => {
    setDisabled(true);
    if (data.password === data.verifyPassword) {
      await auth.changePassword({ password: data.password, verifyToken: match.params.token });
      history.push("/login");
      setDisabled(false);
    }
    else {
      setDisabled(false);
      setAlertBgColor("#E35755");
      setError(<div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("system.password.nomatch", "Password doesn't match !")}</div>);
    }
  };



  return (
    <AuthWrapper>
      <Card className="login">
        <div className="logo">
          <img className="logo" src={Logo}/>
        </div>
        {error && <Alert style={{ marginBottom: 20, background: alertBgColor, border: "none", borderRadius: "10px" }} message={<div style={{ color: "white" }}>{error}</div>} />}
        <Formik
          initialValues={{
            password       : undefined,
            confirmPassword: undefined,
          }}
          validationSchema={() => FormSchema(translate)}
          onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form layout="vertical">
              <FormItem name="password" style={{ marginBottom: 20 }}>
                <Input.Password className="form-input" prefix={<img className="icon" src={PasswordIcon} />} name="password" placeholder={translate("login.password", "Enter new password")} disabled={disabled} />

                {/* <PasswordInput name="password" /> */}
              </FormItem>

              <FormItem name="verifyPassword" style={{ marginBottom: 20 }}>
                <Input.Password className="form-input" prefix={<img className="icon" src={PasswordIcon} />} name="verifyPassword" placeholder={translate("system.confirm", "Confirm password")} disabled={disabled} />
              </FormItem>
              <Button className="form-button" htmlType="submit" type="primary" loading={isSubmitting} disabled={disabled} block>
                <span>{translate("system.save", "Save")}</span>
              </Button>
            </Form>
          )}
        </Formik>
        <Switch style={{ marginTop: " 5px" }} checkedChildren="JP" unCheckedChildren="EN" onChange={() => changeLanguage(lang === "EN" ? "JP" : "EN") } defaultChecked={lang === "EN"} />
        <img className="humans-image" src={Humans}/>
      </Card>
    </AuthWrapper>
  );
};

const AuthWrapper = styled.div`
  background          : #f8f8f8;
  position            : absolute;
  top                 : 0;
  bottom              : 0;
  right               : 0;
  left: 0;
  justify-content     : center;
  display             : flex;
  flex-direction      : center;
  align-items         : center;
  
  .ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .login {
    background        : #6ad137;
    border-radius     : 10px;
    width             : 400px;
    margin            : auto;
    padding-bottom    : 10px;

    .form-input .icon {
      width           : 14px
    }
    .form-input {
      height          : 45px;
      border-radius   : 10px;
    }
    .logo {
      text-align      : center;
      margin          : 10px 0 30px 0;
      img {
        height        : 50px;
        margin        : auto;
      }
    }
    .forgot {
      text-align      : right;
      margin-top      : 10px;
    }
    .form-text{
      color           : white;
      font-weight     : 400;
      font-size       : 14px; 
      transition      : all 0.3s ease-in;
      &:hover {
        color         : #007b3b;
        text-decoration-line : underline;
      }
    }
    .form-button {
    height            : 40px;
    border-radius     : 10px;
    background-color  : #009d4b;
    border            : none;
    font-size         : 21px;
    font-weight       : bold;
      transition: all 0.3s ease-in;
      &:hover {
        background-color : #007b3b;
      }
    }
    .register {
      text-align      : center;
      margin-top      : 10px;
    }
    .humans-image {
      position        : relative;
      bottom          : -20px;
      left            : -20;
    }
    .form-logo-image {
      position        : absolute;
      left            : -141px;
    }
  }
`;
import request from "../utils/request";

export const init = () => async dispatch => {
  const res = await request.get("/api/init");
  const { notifications } = res;

  dispatch({
    type   : "general.init",
    payload: res
  });

  dispatch({
    type   : "socket.notifications",
    payload: notifications
  });

  return res;
};

export const localization = (data) => request.post("/api/localization", data);

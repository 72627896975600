import React from "react";
import { useDispatch } from "react-redux";
import { Children, Dragging, IsInvalid } from "./";
import { useTranslate } from "../../../hooks";

export const ConditionMenu = ({ x, y }) => {
  const { translate } = useTranslate();
  return (
    <Dragging transform={`translate(${x}, ${y})`} menu={{ type: "condition", children: [] }}>
      <path stroke="#CF8B17" fill="#FFAB19" d={`m 0,4 A 4,4 0 0,1 4,0 H 12 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 156 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 64 c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 h -8  a 4,4 0 0,0 -4,4 v ${30} a 4,4 0 0,0 4,4 h  8 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 156 H 156 a 4,4 0 0,1 4,4 v 24  a 4,4 0 0,1 -4,4 H 64 c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 h -8  a 4,4 0 0,0 -4,4 v ${30} a 4,4 0 0,0 4,4 h  8 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 156 a 4,4 0 0,1 4,4 v 24  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z`} />
      <text fill="#fff" transform="translate(8, 24)" style={{ userSelect: "none" }}>{translate("shape.if", "if")}</text>
      <text fill="#fff" transform="translate(104, 24)" style={{ userSelect: "none" }}>{translate("shape.then", "")}</text>
      <text fill="#fff" transform={"translate(8,  106)"} style={{ userSelect: "none" }}>{translate("shape.else", "else")}</text>
    </Dragging>
  );
};

export const ConditionGost = ({ children, transform }) => {
  return (
    <g transform="translate(0, 0)">
      <path stroke="#e5e5e5" fill="#e5e5e5" d="m 0,4 A 4,4 0 0,1 4,0 H 12 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 156 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 64 c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 h -8  a 4,4 0 0,0 -4,4 v 30 a 4,4 0 0,0 4,4 h  8 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 156 H 156 a 4,4 0 0,1 4,4 v 24  a 4,4 0 0,1 -4,4 H 64 c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 h -8  a 4,4 0 0,0 -4,4 v 30 a 4,4 0 0,0 4,4 h  8 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 156 a 4,4 0 0,1 4,4 v 24  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      <g transform={transform}>{children}</g>
    </g>
  );
};

const getBoundRectHeight = ({ children }) => {

  return children?.reduce((accumulator, iterator) => {
    switch (iterator.type) {
      case "next":
      case "start":
      case "end":
      case "timer":
      case "tag":
      case "action": {
        return accumulator + 48;
      }
      case "condition": {
        let conditionHeight = 196 - 8;

        if (iterator.children.filter(child => child.condition === "then").length > 0)
          conditionHeight -= 40;

        if (iterator.children.filter(child => child.condition === "catch").length > 0)
          conditionHeight -= 40;

        return accumulator + getBoundRectHeight({ children: iterator.children }) + conditionHeight;
      }
      case "ghost": {
        if (iterator.shape === "condition")
          return accumulator + 196 - 8;
        return accumulator + 48;
      }
      default:
        return accumulator;
    }
  }, 0);
};

export default ({ child, children, draggable, dragging }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  let thenChildren = child.children?.filter(child => child.condition === "then");
  let catchChildren = child.children?.filter(child => child.condition === "catch");

  let thenHeight = getBoundRectHeight({ children: thenChildren }) - 8;

  if (!thenHeight || thenHeight <= 0)
    thenHeight = 30;

  let catchHeight = getBoundRectHeight({ children: catchChildren }) - 8;

  if (!catchHeight || catchHeight <= 0)
    catchHeight = 30;

  let thenOffetTop = 48;
  let catchOffetTop = thenHeight + 88;
  let childrenOffsetTop = catchOffetTop + catchHeight + 40;

  const onChange = (e, child) => {
    e.stopPropagation();
    dispatch({ type: "process.selected", payload: {} });
    dispatch({ type: "process.selected", payload: child });
  };

  return (
    <Dragging child={child} dragging={dragging} draggable={draggable} cursor="pointer">

      <path stroke="#C5771A" fill="#FFAB19" d={`m 0,4 A 4,4 0 0,1 4,0 H 12 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 156 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 64 c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 h -8  a 4,4 0 0,0 -4,4 v ${thenHeight} a 4,4 0 0,0 4,4 h  8 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 156 H 156 a 4,4 0 0,1 4,4 v 24  a 4,4 0 0,1 -4,4 H 64 c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 h -8  a 4,4 0 0,0 -4,4 v ${catchHeight} a 4,4 0 0,0 4,4 h  8 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 156 a 4,4 0 0,1 4,4 v 24  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z`} />
      <text fill="#fff" transform="translate(8, 24)" style={{ userSelect: "none" }}>{translate("shape.if", "if")}</text>
      <text fill="#fff" transform={`translate(8, ${thenHeight + 76})`} style={{ userSelect: "none" }}>{translate("shape.else", "else")}</text>

      <Dragging dragging={dragging} child={{ ...child, condition: "then" }} draggable={draggable} cursor="pointer" onClick={(e) => onChange(e, child)}>
        <path fill="transparent" fillOpacity="1" d="m 0,4 A 4,4 0 0,1 4,0 H 12 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 162.84796905517578 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      </Dragging>

      <g transform={`translate(16, ${thenOffetTop})`}>
        <Children children={thenChildren} dragging={dragging} draggable={draggable} />
      </g>

      <g transform={`translate(0, ${thenHeight + 40})`}>
        <Dragging dragging={dragging} child={{ ...child, condition: "catch" }} draggable={draggable} cursor="pointer" onClick={(e) => onChange(e, child)}>
          <path fill="transparent" fillOpacity="1" d="M 0 15 h 24 c 2 0 3 1 4 2 l 4 4 c 1 1 2 2 4 2 h 12 c 2 0 3 -1 4 -2 l 4 -4 c 1 -1 2 -2 4 -2 H 156 H 156 a 4 4 0 0 1 4 4 v 24 a 4 4 0 0 1 -4 4 H 64 c -2 0 -3 1 -4 2 l -4 4 c -1 1 -2 2 -4 2 h -12 c -2 0 -3 -1 -4 -2 l -4 -4 c -1 -1 -2 -2 -4 -2 h -8 c -2 0 -3 1 -4 2 H 4 a 4 4 0 0 1 -4 -4 z" />
        </Dragging>
      </g>

      <g transform={`translate(16, ${catchOffetTop})`}>
        <Children children={catchChildren} dragging={dragging} draggable={draggable} />
      </g>

      <g transform={`translate(0, ${thenHeight + catchHeight + 80})`}>
        <Dragging dragging={dragging} child={{ ...child, condition: "children" }} draggable={draggable} cursor="pointer" onClick={(e) => onChange(e, child)}>
          <path fill="transparent" fillOpacity="1" d="M 0 15 h 24 c 2 0 3 1 4 2 l 4 4 c 1 1 2 2 4 2 h 12 c 2 0 3 -1 4 -2 l 4 -4 c 1 -1 2 -2 4 -2 H 156 H 156 a 4 4 0 0 1 4 4 v 24 a 4 4 0 0 1 -4 4 H 49 c -2 0 -3 1 -4 2 l -4 4 c -1 1 -2 2 -4 2 h -12 c -2 0 -3 -1 -4 -2 l -4 -4 c -1 -1 -2 -2 -4 -2 h -8 a 4 4 0 0 1 -5 -5 z" />
        </Dragging>
      </g>

      <g transform={`translate(0, ${childrenOffsetTop})`}>{children}</g>

      <IsInvalid child={child} strokeWidth={3} strokeColor="#CF8B17" draggable={draggable}>
        {({ strokeColor, strokeWidth }) => (
          <g transform="translate(60, 0)" onClick={(e) => onChange(e, child)}>
            <path fill="#FFAB19" strokeWidth={strokeWidth} stroke={strokeColor} d="M16 0h8a16 16 0 010 32h-8a16 16 0 010-32z" transform="translate(48 8)"></path>
            <text fill="#ffffff" x="12" y="18" dominantBaseline="middle" dy="0" textAnchor="middle" transform="translate(48 8) translate(8)">{translate("shape.set", "Set")}</text>
          </g>
        )}
      </IsInvalid>
    </Dragging>
  );
};
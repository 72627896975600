/* eslint-disable radix */
import React from "react";
import { Form, FormItem, Input, Select, } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Col, Row, Progress, Spin } from "antd";
import { formItemLayout } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import { useFetch, useTranslate } from "../../hooks";
import Target from "../../components/Message/Target";
import { tags as tagsApi } from "../../apis";


const { Option } = Select;

const FormSchema =(translate) => Yup.object({
  name: Yup.string().max(50, translate("target.form.name.alert", "Group name exceeded character limit")).required(translate("system.message.required", "This field is required!")),
});

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { action, onSubmit } = props;
  const { translate } = useTranslate();
  const submitRef = React.useRef();
  const dispatch = useDispatch();
  const targetRef = React.useRef();
  useFetch(tagsApi.select(dispatch))();
  const { target } = useSelector(state => state.message);
  const [data, setFormData] = React.useState({
    name: "",
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      let targetForm = await targetRef.current.validate();
      return {
        isValid: isValid,
        targets: targetForm
      };
    }
  }));


  return (
    <Row gutter={[8, 8]}>
      <Col span={14}>
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={()=>FormSchema(translate)}
          onSubmit={()=>{}}>
          {({ isSubmitting, values }) => {
            return (
              <Form {...formItemLayout}>
                <FormItem label={translate("tag.manage.name", "Name")} name="name" required>
                  <Input name="name" placeholder={translate("target.form.name.alert", "Group name must be under 50 characters")} />
                </FormItem>
                <SubmitForm ref={submitRef} />
              </Form>
            );
          }}
        </Formik>

        <Target onlySave={true} onlyCustomSegments="segments" customName={translate("target.form.select.segment", "Select segment")} type="targets" ref={targetRef} editable={["update", { segments: action[1].targets }]} onSubmit={(data) => {}} />
      </Col>
      <Col span={10}>
        <Estimate>
          <div className="header">{translate("message.targetestimation", "Target Estimation")}</div>
          <div className="body">
            <Spin spinning={target.calculating} indicator={<LoadingOutlined spin />}>
              <Progress type="circle" percent={target.percent} />
            </Spin>
            <div className="friends">{translate("message.approx", "Approx")} {parseInt(target.friends)} {translate("message.people", "friends")}</div>
          </div>
        </Estimate>
      </Col>
    </Row>

  );
});


export const Estimate = styled.div`
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: auto;
  .header { 
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 8px 10px;
    font-size: 15px;
  }
  .body { 
    padding: 20px 10px;
    text-align: center;
    .friends {
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .alert-box{
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
    margin-left: 5px;
    border: solid 1px;
    border-radius: 3px;
    margin-bottom: 5px;
    background: #fcf8e3;
    color: #8a6d3b;
    border-color: #faebcc;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: ${colors.primaryDark};
  }
  .ant-progress-circle .ant-progress-text {
    color : ${colors.primary}
  }
`;
import React, { useState } from "react";
import { Form, Input, FormItem, Field, Checkbox } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Row, Col, Alert, Button } from "antd";
import * as Yup from "yup";
import styled from "styled-components";
import { useTranslate } from "../../../hooks";
import colors from "../../../colors";

import CardNumber from "./cardMask/CardNumber";
import CardCVV from "./cardMask/CardCVV";
import CardExpiry from "./cardMask/CardExpiry";
import CardHolder from "./cardMask/CardHolder";

import { CreditCardOutlined } from "@ant-design/icons";

const FormSchema = (translate) => Yup.object({
  holderName: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  cardNumber: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  expiryDate: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  cvv       : Yup.string().required(translate("system.message.required", "Please fill out this field!"))
});

export default ({ onSubmit, error, load }) => {
  const { translate } = useTranslate();

  const [data, setData] = useState({
    holderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv       : ""
  });

  return (
    <Container>
      {
        error && error.message && error.message.message ?
          <Alert
            message={error.message.message + ""}
            type="error"
            showIcon
          /> :
          error && error.message && <Alert
            message={error && error.message + ""}
            type="error"
            showIcon
          />
      }
      <Formik
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values, resetForm);
        }}>

        {(isSubmitting, value) => (
          <Form layout="vertical">
            <Form.Item name="cardNumber" label={translate("card.number", "Card number:")} required>
              <CardNumber name="cardNumber" placeholder="45** **** **33 4534" />
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="expiryDate" label={translate("card.expire", "Expiry date:")} required>
                  <CardExpiry name="expiryDate" placeholder="MM/YY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="cvv" label={translate("card.cvv", "CVV:")} required>
                  <CardCVV name="cvv" placeholder="***" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="holderName" label={translate("card.holder", "Card holder:")} required>
              <CardHolder name="holderName" placeholder={translate("card.holder", "Card holder's name")} />
            </Form.Item>
            <Button loading={load} htmlType="submit" type="primary" > <CreditCardOutlined /> {translate("card.save", "Add new method")}</Button>
          </Form>

        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  Button {
    width : 100%;
  }
  .ant-form-item {
    margin-bottom: 0!important;
    .ant-form-item-label {
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 13px;
    }
    .ant-form-item-control {
      margin-bottom: 10px;
    } 
  }
`;


const Divider = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 500;
  background: #e7e7e7;
  padding: 4px 8px;
  border-radius: 2px;
  color: ${colors.primaryDark};
`;

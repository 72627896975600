import React, { useState } from "react";
import { Form, Input, FormItem, Select, Checkbox } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, message, Spin } from "antd";
import * as Yup from "yup";
import styled from "styled-components";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import colors from "../../../colors";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import confirm from "antd/lib/modal/confirm";
import Modal from "antd/lib/modal/Modal";
import VerifyPasswordForm from "../VerifyPasswordForm";
import { agency, auth } from "../../../apis";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ToggleForm from "./ToggleForm";


const FormSchema = (translate) => Yup.object().shape({
  username: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  email   : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
});
const { Option } = Select;
export default ({ action, onSubmit }) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(state => state.general);
  const [removeModal, setRemoveModal] = React.useState(false);
  const dispatch = useDispatch();


  const [data] = useState({
    username: undefined,
    email   : undefined,
    active  : true,
    ...(action && action[0] === "update" ? action[1] : {}),
  });
  const changeEmail = () => {
    history.push({ pathname: "/customer/email/change", state: { email: data.email } });
  };
  const remove = async () => {
    setRemoveModal(true);
  };
  const logout = async () => {
    setLoading(true);
    await auth.logout();

    dispatch({
      type: "auth.logout"
    });
  };
  const submitVerifyPassword = async (data) => {
    try {
      let res = await agency.remove({ password: data.password });
      if (res.error) {
        message.error(translate("system.invalid.password", "Password is not correct"));
      }
      else {
        message.success("system.alert.delete", "Account has been deleted");
        logout();
      }

    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Spin spinning={loading}>
      <Formik
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        validate={(data) => {
          if (data.password && data.password && data.password !== data.confirmPassword)
            return {
              confirmPassword: translate("system.password.nomatch", "Password doesn't match!")
            };
        }}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <Divider>{translate("admin.logincred", "Login credentials")}</Divider>
            <FormItem label={translate("agency.firstname", "Name")} name="name" required>
              <Input name="name" placeholder={translate("", "Name")} />
            </FormItem>
            <FormItem label={translate("agency.email", "Email")} name="email" required>
              <Input
                disabled
                name="email"
                type="email"
                placeholder={translate("agency.email", "Email")}
                suffix={
                  <Button size="small" onClick={changeEmail}>
                    {translate("system.edit", "Edit")}
                  </Button>
                }
              />
            </FormItem>
            {/* {user.role ==="ADMIN"&&data.active === true ? <div style={{ color: "green", marginBottom: "10px" }}>{translate("useredit.active", "This account is active")}</div> :
              <div style={{ color: "red", marginBottom: "10px" }}>{translate("useredit.disactive", "This account is inactive")}</div>} */}
            {
              user.role === "ADMIN" && <FormItem name="active" label="" required>
                <SwitchStyle>
                  <Checkbox name="active">{translate("accounts.status", "Enable/Disable this account")}</Checkbox>
                </SwitchStyle>
              </FormItem>
            }
            <AntForm.Item>
              <Button htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.save", "Save")}</Button>
            </AntForm.Item>
            {/* {user.role === "ADMIN" &&
              <Button style={{ width: "100%" }} onClick={() => setRemoveModal(true)} danger>{translate("system.delete", "Delete account")}</Button>
            } */}
          </Form>
        )}
      </Formik>
      {user.role !== "ADMIN" && <ToggleForm onSubmit={() => setRemoveModal(true)} />}
      <Modal
        title={translate("system.delete", "Delete")}
        visible={removeModal}
        onOk={null}
        onCancel={() => setRemoveModal(false)}
        footer={null}
      >
        <VerifyPasswordForm onSubmit={submitVerifyPassword} visible={setRemoveModal} />
      </Modal>
    </Spin>
  );
};

const Divider = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 500;
  background: #e7e7e7;
  padding: 4px 8px;
  border-radius: 2px;
  color: ${colors.primaryDark};
`;
const SwitchStyle = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color : ${colors.primaryDark};
  }
  .ant-checkbox-inner::hover {
    border-color : yellow;
  }
`;
import React from "react";
import { tokenApi } from "../../apis";
import { message, Empty, Modal, Tag, } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Form from "./Form";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import copy from "copy-to-clipboard";
import { ManualDesc } from "../../components/Manual";

const { confirm } = Modal;

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [action, setAction] = React.useState();
  const tokenForm = React.useRef();

  const columns = useHeader({
    history,
    translate,
    tableTitles: {
      no    : translate("system.number", "No."),
      token : translate("token.list.token", "Token"),
      action: translate("system.action", "Action"),
      edit  : translate("system.edit", "Edit"),
      copy  : translate("system.copy", "Copy"),
      remove: translate("system.delete", "Remove"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          setAction(["update", item]);
          break;
        }
        case "copy": {
          copy(item.token);
          message.success(translate("system.message.success", "Success"));
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("api.token.remove.modal", "Would like to delete api token ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await tokenApi.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction(null);
  };

  const onSubmit = async (data, { resetForm, setStatus }) => {
    try {
      if (!action || (action && action[0] === "create")){
        await tokenApi.create(data);
        resetForm({});
        setStatus({ success: true });
        message.success(translate("system.message.success", "Successful"));
        myTableRef.current.reload();
        onCancel(true);
      }
      else {
        await tokenApi.update(data);
        message.success(translate("system.message.success", "Successful"));
        resetForm({});
        setStatus({ success: true });
        myTableRef.current.reload();
        onCancel(true);
      }
    } catch (err) {
      console.log("Regular update error", err);
      message.error(err.message);
    }
  };

  return (
    <PageContainer>
      <PageHeader title={translate("navbar.token", "Token settings")} extra={[
        <Button key="createButton" type="primary" onClick={() => setAction(["create", {}])} ><PlusOutlined /> {translate("system.add", "Create token")} </Button>
      ]} />
      <ManualDesc translateKey ="manual.desc.token" />
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={tokenApi.list}
        />
      </PageContent>
      <Modal
        maskClosable ={false}
        width="750px"
        title={action && action[0] === "create" ? translate("system.add", "Add") : translate("system.edit", "Edit") }
        visible={!!action}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form ref={tokenForm} onCancel={onCancel} onSubmit={onSubmit} action={action} />
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, translate }) => {
  return [{
    title    : tableTitles.no,
    align    : "center",
    dataIndex: "key",
    width    : "50px",
  }, {
    title : tableTitles.token,
    render: (record) => {
      return <div style={{ fontSize: 12 }}>{record.token}</div>;
    },
  },
  {
    title : tableTitles.action,
    key   : "action",
    width : 120,
    fixed : "right",
    align : "center",
    render: (row) => {
      return (
        <RowAction
          actions={{
            edit  : tableTitles.edit,
            remove: tableTitles.remove,
            copy  : tableTitles.copy,
          }}
          onClick={(key) => onAction(key, row)}
        />
      );
    },
  }];
};
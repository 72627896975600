/* eslint-disable no-fallthrough */
import React from "react";
import { Empty, Space, } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { PlusOutlined } from "@ant-design/icons";
import { DatePicker, message } from "antd";
import { debug as debugApi, } from "../../apis";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import moment from "moment";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Modal from "antd/lib/modal/Modal";
import CrawlerForm from "./components/CrawlerForm";

const { RangePicker } = DatePicker;

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    startDate: null,
    endDate  : null,
  });

  React.useEffect(() => {
    moment.tz.setDefault("Asia/Tokyo");
    return () => {
      moment.tz.setDefault();
    };
  }, []);

  const changeRange = (e) => {
    setFilters({ ...filters, ...filters.filter, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const columns = useHeader({
    tableTitles: {
      no       : translate("system.number", "No."),
      account  : translate("crawler.account", "Account"),
      customer : translate("crawler.customer", "Customer"),
      status   : translate("crawler.status", "Status"),
      startDate: translate("crawler.startdate", "Start date"),
      endDate  : translate("crawler.enddate", "End date"),
      action   : translate("system.action", "Action"),
      detail   : translate("system.detail", "Detail"),
    },
    history,
    onAction: async (key, item) => {
      switch (key) {
        case "detail": {
          history.push(`/customer/${item._id}`);
          break;
        }
        default:
      }
    },
  });


  const startCrawlers = async (data)=> {
    console.log("------start_crawlers------------>");
    try {
      await debugApi.crawlerStart(data);
      message.success(translate("crawler.success", "Successfull"));
      setOpen(false);
    } catch (err) {
      message.error(translate("crawler.list.failed", "Error occured!"));
    }

  };

  return (
    <PageContainer>
      <PageHeader title={translate("admin.menu.crawlers", "Crawlers")} extra={[
        <Button key="inviteButton" type="primary" onClick={() => setOpen(true)} ><PlusOutlined /> {translate("system.add", "Start crawlers")} </Button>
      ]} />
      <PageFilter>
        <Space>
          <RangePicker allowClear={false} value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
          <Button type="default" onClick={() => setFilters({ ...filters, startDate: null, endDate: null })}>{translate("system.clear", "Clear")}</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          maskClosable={false}
          filters={filters}
          ref={myTableRef}
          rowKey={(record) => record._id}
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          columns={columns}
          loadData={debugApi.crawlers}
        />
      </PageContent>
      <Modal
        onCancel={()=>setOpen(false)}
        title={translate("agency.invite.customer", "Invite customer")}
        visible={open}
        footer={null}>
        <CrawlerForm onSubmit={startCrawlers}/>
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles }) => {
  return [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    },
    {
      title : tableTitles.customer,
      render: (item) => <div>{item.account?.customer ? item.account?.customer?.companyName : "Customer not found" }</div>
    },
    {
      title : tableTitles.account,
      render: (item) => <div>{item.account ? item.account?.displayName || "-" : "Account not found" }</div>
    },
    {
      title : tableTitles.status,
      render: (item) => {
        return (<div >{item.crawlerStatus}</div>);
      }
    },
    {
      title : tableTitles.startDate,
      render: (item) => { return (<div>{moment(item.startDate).tz("Asia/Tokyo").format("YYYY-MM-DD")}</div>); }
    },
    {
      title : tableTitles.endDate,
      render: (item) => { return (<div>{moment(item.endDate).tz("Asia/Tokyo").format("YYYY-MM-DD")}</div>); }
    },
    {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (record) => {
        return <RowAction actions={{
          detail: tableTitles.detail,
        }} onClick={(key) => onAction(key, record)} />;
      }
    }
  ];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .item1 {
    width: 70%;
  }
  .item2 {
    width: 30%;
  }
  .button-primary:hover {
    background : ${colors.primary} ;
    border-color : white;
    color        : white;
  }
  .button-primary {
    background    : ${colors.primaryDark} ;
    border-color  : white;
    color         : white;
  }
`;

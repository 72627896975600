import React from "react";
import { Form, FormItem, Radio, Select, Checkbox, TimePicker, InputNumber } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Modal, Space, Tag } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";
import colors from "../../../colors";
import * as Yup from "yup";
import AutoForm from "./AutoForm";
import moment from "moment";

const FormSchema =(translate) => Yup.object({
  isAutoIssue: Yup.boolean().required(translate("system.message.required", "Please fill out this field!")),
  autoIssue  : Yup.object({}).when("isAutoIssue", {
    is  : true,
    then: Yup.object({
      type      : Yup.string().required(translate("coupon.autoissue.type.required", "Please select one of the above!")),
      expireTime: Yup.string().when("type", {
        is       : "CUSTOM",
        then     : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
        otherwise: Yup.string().nullable()
      }),
      expireDay: Yup.string().when("type", {
        is       : "CUSTOM",
        then     : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
        otherwise: Yup.string().nullable()
      }),
    }),
    otherwise: Yup.object({
      expireTime: Yup.string().nullable(),
      expireDay : Yup.string().nullable(),
    }).nullable()
  }).nullable()
});

const AUTOISSUE = {
  days        : [],
  pattern     : undefined,
  daySet      : undefined,
  startTime   : undefined,
  type        : undefined,
  isEndOfMonth: false,
  expireDay   : undefined,
  expireTime  : undefined,
};


export default ({ initialValues, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const [autoIssue, setAutoIssue] = React.useState(false);
  const [data, setFormData] = React.useState({
    autoIssue: AUTOISSUE,
    ...initialValues,
  });
  const [disable, setDisable] = React.useState(false);
  React.useEffect(() => {
    moment.tz.setDefault("Asia/Tokyo");
    if (initialValues.autoIssue?.couponStatus === "HOLD"){
      setDisable(true);
    }
    return () => {
      moment.tz.setDefault();
    };
  }, []);

  const renderWeek = (day) =>{
    switch (day) {
      case 1: {
        return translate("system.constant.monday", "Monday");
      }
      case 2: {
        return translate("system.constant.tuesday", "Tuesday");
      }
      case 3: {
        return translate("system.constant.wednesday", "Wednesday");
      }
      case 4: {
        return translate("system.constant.thursday", "Thursday");
      }
      case 5: {
        return translate("system.constant.friday", "Friday");
      }
      case 6: {
        return translate("system.constant.saturday", "Saturday");
      }
      case 7: {
        return translate("system.constant.sunday", "Sunday");
      }
      default: {
        return day;
      }
    }
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue, handleSubmit, errors }) => {
          return (
            <Form {...formItemLayout}>
              <FormItem name="isAutoIssue">
                <Checkbox name="isAutoIssue" onChange={(e) => {setFieldValue("autoIssue", AUTOISSUE);}}> {translate("coupon.isAutoIssue.select", "Auto issue")} </Checkbox>
              </FormItem>
              <FormItem name="autoIssue.type">
                <Radio.Group disabled={!values?.isAutoIssue || disable} name="autoIssue.type" onChange={(e) => {
                  setFieldValue("autoIssue.type", e.target.value);
                }}>
                  <Space direction="vertical">
                    <RadioItem className={values?.autoIssue?.type === "BASIC" ? "click" : "unClick"}>
                      <Radio value={"BASIC"}>
                        <div className="radio-item title">
                          <div className="title">{translate("coupon.autotype.basic", "Basic")}</div>
                        </div>
                      </Radio>
                    </RadioItem>
                    <RadioItem className={values?.autoIssue?.type === "CUSTOM" ? "click" : "unClick"}>
                      <Radio value={"CUSTOM"}>
                        <div className="radio-item title">
                          <div className="title">{translate("coupon.autotype.custom", "Custom")}</div>
                        </div>
                      </Radio>
                    </RadioItem>
                  </Space>
                </Radio.Group>
              </FormItem>
              {values.autoIssue.type === "CUSTOM" ?
                <RadioItem>
                  <div className="radio-item">
                    <div className="title">{translate("coupon.custom.resenddays", "Resend days")}</div>
                    <div style={{ width: "30px" }}></div>
                    {values.autoIssue.pattern === "MONTH" && <div className="title">{translate("coupon.pattern.month", "Monthly")}</div> }
                    {values.autoIssue.pattern === "WEEK" && <div className="title">{translate("coupon.pattern.week", "Weekly")}</div> }
                    <div className="title">
                      {values.autoIssue?.days.length < 1 && <Tag color="orange">{translate("coupon.custom.resenddays.notfound", "Days has not been selected!")}</Tag>}
                      {
                        values.autoIssue?.days.sort((a, b) => a - b).map(day => {
                          if (values.autoIssue?.pattern === "WEEK"){
                            return <Tag >{renderWeek(day)}</Tag>;
                          }
                          if (day === 32){
                            return <Tag >{translate("regular.endofmonth", "End")}</Tag>;
                          }
                          return <Tag>{day}</Tag>;
                        })
                      }
                    </div>
                    <Button disabled={disable} className="title" onClick={() => setAutoIssue(true)}>{translate("system.update", "Edit")}</Button>
                  </div>
                </RadioItem>
                : null}
              {values.autoIssue.type === "CUSTOM" ?
                <RadioItem>
                  <div className="radio-item">
                    <div className="title">{translate("coupon.expiretime", "Duration")}</div>
                    <div style={{ width: "30px" }}></div>
                    <div className="title">{translate("coupon.expiretime.before", "After")}</div>
                    <FormItem name="autoIssue.startTime">
                      <TimePicker disabled format={(date) => date.tz("Asia/Tokyo").format("HH:mm")} name={"autoIssue.startTime"} showSecond={false} />
                    </FormItem>
                    <div style={{ width: "5px" }}></div>
                    <div className="title">{translate("coupon.expiretime.from", "from")}</div>
                    <FormItem name="autoIssue.expireDay">
                      <Number disabled={disable} min={0} name="autoIssue.expireDay" placeholder="0" />
                    </FormItem>
                    <div className="title">{translate("coupon.expiretime.after", "days")}</div>
                    <FormItem name="autoIssue.expireTime">
                      <TimePicker disabled={disable} format={(date) => date.tz("Asia/Tokyo").format("HH:mm")} name={"autoIssue.expireTime"} showSecond={false} />
                    </FormItem>
                    <div className="title">{translate("coupon.expiretime.hours", "hours")}</div>
                  </div>
                </RadioItem>
                : null}
              <Modal
                visible={autoIssue}
                maskClosable ={false}
                width="750px"
                title={translate("coupon.auto.days", "Auto days") }
                onCancel={() => setAutoIssue(false)}
                destroyOnClose
                footer={false}
              >
                <AutoForm
                  initialValues={values.autoIssue}
                  onCancel={() => setAutoIssue(false)}
                  onSubmit={(e) => {
                    console.log("modalform_value", e);
                    setFieldValue("autoIssue.days", e.days);
                    setFieldValue("autoIssue.pattern", e.pattern);
                    setFieldValue("autoIssue.daySet", e.daySet);
                    setFieldValue("autoIssue.isEndOfMonth", e.isEndOfMonth);
                    setFieldValue("autoIssue.startTime", e.startTime);
                    setAutoIssue(false);
                  }}/>
              </Modal>
              <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
                <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
                <Button onClick={handleSubmit} type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </Container>

  );
};
const Number = styled(InputNumber)`
  background-color : #FFFFE0;
`;

const RadioItem = styled.div`
  border-radius: 8px;
  padding: 10px;
  display: inline-flex;
  &&:hover {
    background-color: #FDFDEA;
  }
  &.click {
    background-color:#DEEAFF;
  }
  &.unClick {
    background-color:#fff;
  }
  .ant-input {
    width :100px;
  }
  .ant-input-number {
    margin: 0 10px;
  }
  .input-desc {
    font-size :12px;
    margin : 5px 10px;
  }
  .radio-item { 
    display:flex;
     .title {
      margin :7px 0;
      margin-right: 10px;
    }
  }
`;

const Container = styled.div`
 .ant-col-sm-16 {
    max-width : 100% !important;
  }  
  .ant-input-number-disabled {
    background-color : #F1F1F1 !important;
  }
  .head-container {
    padding: 15px;
    .ant-col-sm-16 {
      max-width : 100% !important;
    }
    .footer-description {
      font-size: 10px;
      color :#9DA5A4;
      margin-top: 6px;
    }
    .label {
      b {
        color :red;
      }
    }
    .ant-form-item {
      margin : 0px !important;
    }
    background-color: #FAFAFA;
  }
  table { 
    border:solid 1px  #F0F0F0;
  }
  .disabled {
    pointer-events: none;
  }
  .table-title {
    padding-left :5px;
    background-color : #FAFAFA;
  }
  .space {
    margin-left :14px;
  }
  .group-item {
    display :flex;
    margin-left :14px;
    .ant-form-item { 
      margin-bottom: 0px !important;
      margin-right: 14px;
    }
    .ant-col-sm-16 {
      width :100% !important;
      max-width : 100% !important;
    }

    .ant-col-xs-24 {
      width :100% !important;
      max-width : 100% !important;
    }

    .item {
      width: 174px;
    }
    .inline {
      display:flex;
    }
  }

  .input-desc {
    font-size :12px;
    margin-top :  10px;
    margin-bottom :  10px;
  }
  .contents {
    height:100%;
    padding :10px;
    padding-left: 24px;
    .ant-radio {
      margin-top : 9px;
    }
    .wrap {
      height: 32px;
    }
  }
  .fields {
    width :80%;
    .textarea {
      font-size: 14px;
    }
    .ant-row{
      width: 100%;
    }
    .ant-col-sm-16 {
      width :100% !important;
      max-width : 100% !important;
    }
  }
`;

/* eslint-disable radix */
import { Button, Col, Modal, Radio, Row, Tabs } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import RichmenuAliasPreview, { RichmenuPreview } from "../../../components/RichmenuPreview/RichmenuAlias";
import RichmenuAliasTemplate from "../../../components/RichMenuTemplate/RichmenuAliasTemplate";
import { useTranslate } from "../../../hooks";
import ActionForm, { INITIALIZE_ACTION_DATA } from "./ActionForm";
import UploadForm from "../../RichMenu/components/UploadForm";
import { RichWrapper, TemplateFooter } from "../../RichMenu/Form";
import { TemplateMenu } from "../../../components/RichMenuTemplate";
import styled from "styled-components";

export const INIT_RICHMENU = {
  type    : "large",
  template: "A",
  image   : undefined,
};

export default React.forwardRef((props, ref) => {
  const { editable, onSubmit, step, isRemove } = props;
  const { translate } = useTranslate();
  const { richMenuTemplates, richmenuSelect, richmenuAlias } = useSelector(state => state.general);
  const { collapse } = useSelector(state => state.message);
  const [action, setAction] = React.useState([false, null, null]);
  const [openCollapse, setOpenCollapse] = React.useState();
  const formRef = React.useRef();
  const [errors, setErrors] = React.useState({});
  const dispatch = useDispatch();

  const [data, setFormData] = React.useState({
    type    : "large",
    template: "A",
    image   : undefined,
    // ...(editable && editable[0] === "update"  ? editable[1]: {})
    ...(editable &&(editable[0] === "update" || editable[0] === "copy") ? editable[1]: {})
  });

  //   console.log("FORM EDITABLE =>", data);

  const onCancel = () => {
    setAction([null]);
  };

  const onUploaded = ({ url }) => {
    setFormData({ ...data, image: url });
    dispatch({ type: "general.richmenuSelect", payload: { ...data, image: url } });
    setErrors({});
    onCancel();
  };

  const onSelect = async () => {
    await formRef.current.clear();

    setFormData({
      ...data,
      type    : action[1],
      template: action[2],
      image   : null
    });

    dispatch({ type   : "general.richmenuSelect", payload: {
      ...data,
      type    : action[1],
      template: action[2],
      image   : null
    } });

    onCancel();
  };

  const onSelectRichGrid = (name) => {
    setOpenCollapse(name);
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let imageValidate = true;

      let valid = await formRef?.current?.validate();

      if (!data.image) {
        setErrors({ image: translate("system.image.required", "Please upload an image!") });
        imageValidate = false;
      }else{
        imageValidate = true;
      }

      if(!imageValidate || !valid) return false;

      return data.image && {
        ...data,
        ...valid,
        template: data?.template,
        type    : data?.type,
        image   : data?.image,
        step    : step
      };
    },
    async getValues() {
      let res = await formRef.current.getValues();

      return {
        ...data,
        ...res,
        template: data?.template,
        type    : data?.type,
        image   : data?.image,
        step    : step
      };

    }
  }));

  React.useEffect(() => {
    if (collapse[0] === "message") {
      dispatch({ type: "message.preview", payload: true });
    }

    dispatch({ type: "general.richmenuSelect.update", payload: { chatBar: data.chatBarText, template: { image: data.image } } });
  }, []);


  React.useEffect(()=> {
    setFormData({
      type    : "large",
      template: "A",
      image   : undefined,
      ...(editable &&(editable[0] === "update" || editable[0] === "copy") ? editable[1]: {})
    });
  }, [step, isRemove]);

  return (
    <RichWrapper>
      <Row gutter={[8, 8]}>
        <Col span={10}>
          <div className="template">
            <div className="rich">
              <RichmenuAliasPreview>
                <div style={{ height: data.type === "large" ? 247 : 125, position: "relative" }}>
                  <div style={{ height: 37, position: "absolute", width: "100%", zIndex: 1 }}>
                    <TabCotnainer>
                      {
                        richmenuAlias && richmenuAlias?.map((item, index) => (<div className={`tab-item ${index === parseInt(step) && "selected-item"}`}>{translate("richmenu.alias.tab", "Tab")} {index+1}</div>))
                      }
                    </TabCotnainer>
                  </div>
                  {/* <RichmenuAliasTemplate width={370} height={data.type === "large" ? 247 : 123-18} type={data.type} template={data.template} onSelect={onSelectRichGrid} image={data.image} errors={errors} /> */}
                  <RichmenuAliasTemplate width={370} height={data.type === "large" ? 247 : 125} type={data.type} template={data.template} onSelect={onSelectRichGrid} image={data.image} errors={errors} />
                </div>
              </RichmenuAliasPreview>
            </div>

            <Button type="default" style={{ marginBottom: 10, width: 370 }} onClick={() => setAction(["template", data.type, data.template])} block>{translate("richmenu.layout", "Select template")}</Button>
            <Button type="primary" style={{ width: 370 }} onClick={() => setAction(["image", data.image])} block>{translate("richmenu.upload", "Upload Image")}</Button>
            {errors.image && <div className="error">{errors.image}</div>}
          </div>
        </Col>
        <Col span={14}>
          <div className="collapse">
            {richMenuTemplates[data.type][data.template] && richMenuTemplates[data.type][data.template].length > 0 && <ActionForm type={data.type} template={data.template} editable={editable} openCollapse={openCollapse} setOpenCollapse={setOpenCollapse} ref={formRef} onSubmit={onSubmit} />}
          </div>
        </Col>
      </Row>

      <Modal
        maskClosable={false}
        title={translate("richmenu.layout", "Select a template")}
        visible={action && action[0] === "template"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <h3>{translate("richmenu.layout.large", "Large")}</h3>
        {Object.keys(richMenuTemplates.large).map(template => {
          return <TemplateMenu key={template} width={100} style={{ marginRight: 10, marginBottom: 20 }} type="large" active={action[1] === "large" && action[2] === template} template={template} onSelect={() => setAction(["template", "large", template])} />;
        })}
        <h3>{translate("richmenu.layout.compact", "Compact")}</h3>
        {Object.keys(richMenuTemplates.compact).map(template => {
          return <TemplateMenu key={template} width={100} style={{ marginRight: 10, marginBottom: 20 }} type="compact" active={action[1] === "compact" && action[2] === template} template={template} onSelect={() => setAction(["template", "compact", template])} />;
        })}
        <TemplateFooter>
          <Button type="default" onClick={() => setAction([null])} style={{ marginRight: 10 }}>{translate("system.cancel", "Cancel")}</Button>
          <Button type="primary" onClick={() => onSelect()}>{translate("richmenu.layout.apply", "Apply")}</Button>
        </TemplateFooter>
      </Modal>

      <Modal
        maskClosable={false}
        title={translate("richmenu.upload", "Upload your menu image")}
        visible={action && action[0] === "image"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <UploadForm action="/api/richmenu/upload" type={data.type} onCancel={onCancel} onUpload={onUploaded} />
      </Modal>
    </RichWrapper>
  );

});

const TabCotnainer = styled.div`
    display: flex;
    flex-direction: row;
    .tab-item {
        flex: 1;
        height: 37px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: solid 0px;
        border: solid 0.3px #000000;
        background: rgba(0, 0, 0, 0.2);
    }

    .selected-item {
        background: rgb(52,60,63,0.75);
        /* border: solid 0.2px #009d4b; */
        color: white;
    }

`;
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { agency, user as userService, grant as grantService } from "../../apis";
import { useTranslate } from "../../hooks";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Alert as AntAlert, Button, message, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import PasswordForm from "../Admin/components/PasswordForm";
import styled from "styled-components";
import EditForm from "./EditForm";
import VerifyPasswordForm from "../Admin/VerifyPasswordForm";
import { useSelector } from "react-redux";

export default () => {
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [editable, setEditable] = React.useState([]);
  const [password, setPassword] = React.useState(null);
  const { user } = useSelector(state => state.general);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [blockVsible, setBlockVsible] = React.useState(false);
  const { translate } = useTranslate();
  const type = "AGENCY";

  const reload = React.useCallback(
    async (signal) => {
      let res = await userService.get(params.id, { signal });
      setEditable(["update", {
        ...res
      }]);
      setLoading(false);
    },
    [params.id]
  );

  const onSubmit = async (data) => {
    let result = null;
    try {
      result = await agency.update(data);
      message.success(translate("system.message.success", "Successful"));
      history.push("/user/list");
    } catch (error) {
      message.error(error.message);
    }
  };
  const onCancel = () => {
    setPassword(null);
  };
  const modalPassword = () => {
    setPassword(["password_change"]);
  };

  const onSubmitPassword = async (data) => {
    try {
      await grantService.changePassword({ newPassword: data.newPassword, password: data.password, id: params.id });
      message.success(translate("system.message.success", "Password has been changed successfully."));
      setPassword();
    } catch (error) {
      if (error.message === "INVALID_PASSWORD") {
        message.error(translate("system.invalid.password", "The password is incorrect. Retype your password. Letters in passwords must be typed using the correct case"));
      }
      else {
        message.error(translate("system.error", error.message));
      }
    }
  };

  const submitVerifyPassword = async (data) => {
    try {
      let res = await grantService.removeAgency({ id: `${editable[1]._id}`, password: data.password });
      setRemoveModal(false);
      history.push("/user/list");

    } catch (error) {
      message.error(translate("system.error.PASSWORD_INVALID", "Password is incorrect"));
    }
  };
  const unblock = async () => {
    setBlockVsible(true);
    await userService.unblock(editable[1]&&editable[1]._id);
    message.success(translate("system.message.success", "Successful"));
    reload();
    setBlockVsible(false);
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);

  if (loading) return <></>;

  return (
    <PageContainer>
      <PageHeader onBack={()=>history.goBack()} title="User form" />
      <PageContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormWrapper>
            <div>
              {
                editable[1] && editable[1].passwordBlocked == true &&
                <Alert
                  message={translate("login.password.blocked", "Account has been blocked due to too many failed login attempts")}
                  type="warning"
                  action={
                    <Button size="small" type="ghost" onClick={unblock} loading={blockVsible}>
                      {translate("useredit.unblock", "Unblock")}
                    </Button>
                  }
                  showIcon />
              }
            </div>

            {loading ? <SpinWrapper><Spin className="spin-class" size="large" /></SpinWrapper> : (<>
              <div style={{ marginBottom: 20 }}>
                <Button className="button" type="default" onClick={modalPassword} block>{translate("useredit.password", "Change password")}</Button>
              </div>
            </>)}
            <EditForm action={editable} onSubmit={onSubmit} unblock={unblock } />
            {user.role === "ADMIN" &&
              <Button style={{ width: "100%" }} onClick={() => setRemoveModal(true)} danger>{translate("system.delete", "Delete account")}</Button>
            }

          </FormWrapper>
        </div>

        <Modal
          title={translate("login.password", "Insert password")}
          visible={removeModal}
          onOk={null}
          onCancel={() => setRemoveModal(false)}
          footer={null}
        >
          <VerifyPasswordForm onSubmit={submitVerifyPassword} visible={setRemoveModal} />

        </Modal>
        <Modal
          maskClosable={false}
          title={translate("useredit.password", "Change password")}
          visible={password}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          <PasswordForm type={type} onCancel={onCancel} onSubmit={onSubmitPassword} action={password} />
        </Modal>
      </PageContent>
    </PageContainer>
  );
};
const Alert = styled(AntAlert)`
  margin-bottom :20px;
  Button {
    margin-bottom : 0px !important;
  }
  .ant-alert .ant-alert-warning {
    width :100% !important;
  }
`;
const FormWrapper = styled.div`
  width: 600px;
 Button{
   width :100%;
   margin-bottom:5px;
 }
`;
const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
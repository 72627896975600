import React from "react";
import styled from "styled-components";
import RichGrid from "./RichGrid";
import colors from "../../colors";
import { Stage, Layer, Rect, Group, Text, Image, } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import useImage from "use-image";

const DRAW_TEMPLATES = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

export const TemplateMenu = (props) => {
  const { width, type, template, active, onClick } = props;

  return (
    <Menu className={`${active && "active"}`} onClick={() => onClick(type)} {...props}>
      <img alt="origin" width={width || null} className="origin" src={require(`./images/${type}_map_${template}.jpg`)} />
      <img alt="hover" width={width || null} className="hover" src={require(`./images/_${type}_map_${template}.jpg`)} />
    </Menu>
  );
};

const IMAGEMAP_TEMPLATES = {
  custom: {
    H: ({ showDivider, width, height, onClick }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="100%">
                <RichGrid showDivider={showDivider} name="A" onSelect={onClick} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  },
  large: {
    A: ({ showDivider, width, height, onClick }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="A" onSelect={onClick} />
              </td>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="B" onSelect={onClick} />
              </td>
            </tr>
            <tr>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="C" onSelect={onClick} />
              </td>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="D" onSelect={onClick} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    B: ({ showDivider, width, height, onClick }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="A" onSelect={onClick} />
              </td>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="B" onSelect={onClick} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    C: ({ showDivider, width, height, onClick }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="A" onSelect={onClick} />
              </td>
            </tr>
            <tr>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="B" onSelect={onClick} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    D: ({ showDivider, width, height, onClick }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td colSpan="3" width="100%">
                <RichGrid showDivider={showDivider} name="A" onSelect={onClick} />
              </td>
            </tr>
            <tr>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="B" onSelect={onClick} />
              </td>
              <td width="50%">
                <RichGrid showDivider={showDivider} name="C" onSelect={onClick} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    E: ({ showDivider, width, height, onClick }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="33.3%">
                <RichGrid showDivider={showDivider} name="A" onSelect={onClick} />
              </td>
              <td width="33.3%">
                <RichGrid showDivider={showDivider} name="B" onSelect={onClick} />
              </td>
              <td width="33.3%">
                <RichGrid showDivider={showDivider} name="C" onSelect={onClick} />
              </td>
            </tr>
            <tr>
              <td width="33.3%">
                <RichGrid showDivider={showDivider} name="D" onSelect={onClick} />
              </td>
              <td width="33.3%">
                <RichGrid showDivider={showDivider} name="E" onSelect={onClick} />
              </td>
              <td width="33.3%">
                <RichGrid showDivider={showDivider} name="F" onSelect={onClick} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    F: ({ showDivider, width, height, onClick }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="100%">
                <RichGrid showDivider={showDivider} name="A" onSelect={onClick} />
              </td>
            </tr>
            <tr>
              <td width="100%">
                <RichGrid showDivider={showDivider} name="B" onSelect={onClick} />
              </td>
            </tr>
            <tr>
              <td width="100%">
                <RichGrid showDivider={showDivider} name="C" onSelect={onClick} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },

    G: ({ showDivider, width, height, onClick }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="100%">
                <RichGrid showDivider={showDivider} name="A" onSelect={onClick} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

  },
};

export default ({ showDivider, width = 180, height = 60, type = "large", template = "G", onClick, errors, image }) => {
  const dispatch = useDispatch();
  const { imageMapSelect, imageMapSelectTemplates } = useSelector(state => state.general);
  const [annotations, setAnnotations] = React.useState([]);
  const [bitmap] = useImage(image);

  React.useEffect(() => {
    if (imageMapSelect && imageMapSelect.type === "draw" && imageMapSelect.actions) {
      let templates = [];
      let array = imageMapSelect.actions.filter(action => action.area).map((action, index) => {
        templates.push(DRAW_TEMPLATES[index]);
        return {
          x     : action.area.x,
          y     : action.area.y,
          width : action.area.width,
          height: action.area.height,
          key   : DRAW_TEMPLATES[index]
        };
      });

      setAnnotations(array);

      dispatch({
        type   : "general.imagemapSelect.templates",
        payload: templates
      });

      dispatch({
        type   : "general.imagemapSelect.bounds",
        payload: array
      });
    }
  }, [imageMapSelect]);

  React.useEffect(() => {
    if (imageMapSelect && imageMapSelect.type === "draw" && annotations.length > 0 && imageMapSelectTemplates.length < annotations.length) {
      let templates = [];
      let array = annotations.filter(annotation => imageMapSelectTemplates.indexOf(annotation.key) !== -1).reduce((accumulator, iterator, index) => {
        templates.push(DRAW_TEMPLATES[index]);

        return [
          ...accumulator, {
            ...iterator,
            key: DRAW_TEMPLATES[index]
          }];
      }, []);

      setAnnotations(array);

      dispatch({
        type   : "general.imagemapSelect.templates",
        payload: templates
      });

      dispatch({
        type   : "general.imagemapSelect.bounds",
        payload: array
      });
    }
  }, [imageMapSelectTemplates]);

  const annotationsToDraw = [...annotations];

  const RenderImage = ({ width, height }) => {
    return <Image width={width} height={height} image={bitmap} />;
  };

  return (
    <Template showDivider={showDivider} style={{ width: Math.round(width), height: Math.round(height) }} className={`${errors.baseUrl && "error"}`}>
      {(() => {
        if (type === "draw")
          return (
            <div style={{ border: `2px solid ${errors.baseUrl ? "red" : "#009d4b"}`, width: Math.round(width), height: Math.round(height), overflow: "hidden" }}>
              <Stage
                width={width}
                height={height}>
                <Layer>
                  <RenderImage width={Math.round(width)} height={Math.round(height)} />
                  {annotationsToDraw.map((value, index) => {
                    return (
                      <Group key={index} >
                        <Rect
                          x={value.x}
                          y={value.y}
                          width={value.width}
                          height={value.height}
                          onClick={() => onClick(value.key)}
                          fill="rgba(0, 0, 0, 0.36)"
                          stroke={errors.baseUrl ? "red" : "#009d4b"} />
                        {showDivider && <Text
                          x={value.x + (value.width / 2) - 40}
                          y={value.y + (value.height / 2) - 10}
                          text={value.key}
                          fontSize={20}
                          fill="#ffffff"
                          align="center"
                          width={80}
                          height={20} />}
                      </Group>
                    );
                  })}
                </Layer>
              </Stage>
            </div>
          );

        return (
          <>
            <div style={{ width: Math.round(width), height: Math.round(height), overflow: "hidden" }}>
              {image && <img src={`${image}`} width={Math.round(width)} height={Math.round(height)} />}
            </div>
            {IMAGEMAP_TEMPLATES[type][template]({ showDivider: showDivider, width: Math.round(width), height: Math.round(height), onClick: (name) => onClick && onClick(name) })}
          </>
        );
      })()}
    </Template>
  );
};

const Template = styled.div`
  position: relative;
  background-color: #00000024;
  &.error {
    table, th, td {
      border-color: red;
    }
  }
  table {
    border-collapse: collapse;
    position: absolute;
  }
  table, th, td {
    border: 2px solid ${colors.primaryDark}; // #06b900;
    padding: 0;
    top: 0;
    ${({ showDivider }) => showDivider && `
    border: none;
    `}
  }
`;

const Menu = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  &.active {
    .origin {
      display: none;
    }
    .hover {
      display: inline-block;
    }
  }
  .hover {
    display: none;
  }
`;
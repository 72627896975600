import React, { useState } from "react";
import { Button, Upload, message, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslate } from "../../../../hooks";

const { Dragger } = Upload;

export default (props) => {
  const { translate } = useTranslate();
  const { onCancel, action, onUpload } = props;
  const [isLoading, setIsLoading] = useState(false);

  const beforeUpload = (file) => {
    setIsLoading(true);
    const isMP4 = file.type === "video/mp4";
    const isLt2M = file.size / 204800 / 204800 < 200;

    if (!isMP4) {
      setIsLoading(false);
      message.error(translate("imagemap.video.format.error", "You can only upload MP4 file!"));
      return false;
    }
    if (!isLt2M) {
      setIsLoading(false);
      message.error(translate("imagemap.video.size.error", "Video must be smaller than 200MB!"));
      return false;
    }
    return true;
  };

  const options = {
    name          : "file",
    accept        : ["video/mp4"],
    action        : action,
    beforeUpload  : beforeUpload,
    showUploadList: false,
    onChange      : (info) => {
      const { status } = info.file;

      if (status === "done") {
        message.success(`${info.file.name} ${translate("system.message.success.upload", "File uploaded successfully.")}`);

        if (onUpload)
          onUpload(info.file.response);

        setIsLoading(false);
      } else if (status === "error") {
        message.error(`${info.file.name} ${translate("system.message.failed.upload", "File upload failed.")}`);
      }
    },
  };

  return (
    <UploadWrapper>
      <Spin spinning={isLoading}>
        <Dragger {...options}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {translate(
              "richmenu.upload.text",
              "Click or drag file to this area to upload"
            )}
          </p>
          <div className="ant-upload-hint">
            <div>
              {translate("imagemap.video.format", "File formats:MP4")}
            </div>
            <div>{translate("imagemap.video.size", "File size: Up to 200 MB")}</div>
          </div>
        </Dragger>
      </Spin>

      <UploadFooter>
        <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>
          {translate("system.cancel", "Cancel")}
        </Button>
      </UploadFooter>
    </UploadWrapper>
  );
};

const UploadFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const UploadWrapper = styled.div`
  .ant-upload-text {
    margin-bottom: 20px;
  }
  .ant-upload-hint {
    font-size: 12px!important;
  }
`;
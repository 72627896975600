import request from "../utils/request";

export const list = (data) => request.post("/api/rakuten_order/list", data);
export const reviews = (data) => request.post("/api/rakuten_order/review", data);
export const followers = (data) => request.post("/api/rakuten_order/followers", data);
export const calculate = (id) => request.post(`/api/rakuten_order/calculate/${id}`);
export const renew = (data) => request.post(`/api/rakuten_order/renew`, data);


export const connective = (id, data) => request.put(`/api/account/rakuten/${id}`, data);
export const disconnect = (id) => request.put(`/api/account/rakuten/${id}/disconnect`);
export const check = (id, data) => request.post("/api/account/check", data);



/* eslint-disable radix */
import React from "react";
import { Button, Col, Collapse, Modal, Progress, Row, Spin, Tabs, message as notify, Alert } from "antd";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import { Estimate, MessageContainer } from "../RichMenu";
import TargetForm from "../../components/Message/Target";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SettingsForm from "../RichMenu/components/SettingsForm";
import Form, { INIT_RICHMENU } from "./components/Form";
import styled from "styled-components";
import { URL_REGEX } from "../Imagemap/image/components/Form";
import * as Yup from "yup";
import { INITIALIZE_ACTION_DATA } from "./components/ActionForm";
import { richmenu as richmenuApi, tags } from "../../apis";
import usePlan from "../../hooks/usePlan";
import moment from "moment";

const { Panel } = Collapse;
const { confirm } = Modal;


const ActionFormSchema = (translate) => Yup.object({
  type: Yup.string().required(translate("system.message.required", "Required!")),

  uri: Yup.string().matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).when("type", {
    is  : "uri",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  label: Yup.string().when("type", {
    is  : "message",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  text: Yup.string().when("type", {
    is  : "message",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  cooperation: Yup.string().when("type", {
    is  : "cooperation",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  couponCode: Yup.string().when("type", {
    is  : "coupon",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
});

const FormSchema = (translate)=> Yup.object({
  actions: Yup.array().of(ActionFormSchema(translate))
});


export default ({ editable }) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  useFetch(tags.select(dispatch))();
  const { target, collapse } = useSelector(state => state.message);
  const { checkRole } = usePermission();
  const [loading, setLoading] = React.useState(false);
  const targetForm = React.useRef();
  const broadcastForm = React.useRef();
  const richmenuForm = React.useRef();
  const { checkPermission } = usePlan();
  const [isRemove, setIsRemove] = React.useState(false);
  const [tabAction, setTabAction] = React.useState({});
  const [actionsErrorMessage, setActionsErrorMessage] = React.useState(false);

  const [usable, setUsable] = React.useState(checkPermission("RICHMENU_ALIAS"));
  const [disabled, setDisabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));
  const [action, setAction] = React.useState({
    ...(editable && (editable[0] === "update" || editable[0] === "copy") ? editable[1] : {
      richmenus: [
        { ...INIT_RICHMENU },
        { ...INIT_RICHMENU },
      ]
    })
});
  const [step, setStep] = React.useState("0");
  
  
  const onChangeCollapse = (collapse) => {
    dispatch({
      type   : "message.collapse",
      payload: collapse
    });
  };
 
  const onNext = async (type) => {
    switch (type) {
      case "target": {
        let isValid = await targetForm.current.validate();

        if (isValid) {
          dispatch({
            type   : "message.collapse",
            payload: ["broadcast"]
          });
        }
        break;
      }
      case "broadcast": {
        const isValid = await broadcastForm.current.validate();

        if (isValid) {
          dispatch({
            type   : "message.collapse",
            payload: ["message"]
          });

          dispatch({
            type   : "message.preview",
            payload: true
          });
        }
        break;
      }
      default:
    }
  };


  const onSave = async (save) =>{
    dispatch({
      type   : "message.collapse",
      payload: ["target", "broadcast", "message"]
    });

    let target = await targetForm?.current?.validate();
    let broadcast = await broadcastForm?.current?.validate();
    let richmenusResult = await richmenuForm?.current?.validate();

    console.log("richmenusResult =>", richmenusResult);
    setActionsErrorMessage(richmenusResult ? false: true);
    if (target === false || broadcast === false || richmenusResult === false)
      notify.error(translate("system.error.required", "Insert necessary fields first!"));

    if (!target || !broadcast || !richmenusResult) return;

    let rchmenus = [...action.richmenus];
    rchmenus[parseInt(richmenusResult.step)] = richmenusResult;
    setAction({ ...action, richmenus: [...rchmenus] });
    dispatch({ type: "general.richmenuAlias", payload: [...rchmenus] });
    let actionCheck = [];

    rchmenus.map(async (item, index) => {
      let res = await FormSchema(translate).isValid({ ...item });

      item.actions.forEach(item => {
        if (item.type !== "no_action"){
          actionCheck = [...actionCheck, item];
        }
      });
      if (res === false){
        setStep(`${index}`);
      }
    });

    // if (actionCheck.length === 0) {
    //   notify.error(translate("richmenu.action.required.one", "Set at least one action "));
    //   return;
    // }
    // ==============


    try {
      setLoading(true);

      const asyncSend = async () => {
        if (editable[0] === "update") {
          await richmenuApi.updateAlias({
            _id         : editable[1]._id,
            name        : broadcast.name,
            behaviorType: broadcast.behaviorType,
            chatBarType : broadcast.chatBarType,
            chatBarText : broadcast.chatBarText,
            richmenus   : rchmenus,
            saveType    : save,
            sendType    : broadcast.sendType,
            schedules   : broadcast.schedules,
            targetType  : target.targetType,
            targets     : target.targetType === "SEGMENTS" ? target.targets : [],
            demographic : target.targetType === "DEMOGRAPHIC" ? target.targets : null
          });
        } else {
          await richmenuApi.createAlias({
            name        : broadcast.name,
            behaviorType: broadcast.behaviorType,
            chatBarType : broadcast.chatBarType,
            chatBarText : broadcast.chatBarText,
            richmenus   : rchmenus,
            saveType    : save,
            sendType    : broadcast.sendType,
            schedules   : broadcast.schedules,
            targetType  : target.targetType,
            targets     : target.targetType === "SEGMENTS" ? target.targets : [],
            demographic : target.targetType === "DEMOGRAPHIC" ? target.targets : null
          });
        }
      };

      if (save === "SEND" && broadcast.sendType === "NOW") {
        confirm({
          icon      : <ExclamationCircleOutlined />,
          okText    : translate("system.yes", "Yes"),
          cancelText: translate("system.no", "No"),
          content   : translate("richmenu.confirm.sendnow", "Message has been set to send immediately, are you sure to send it immediately?"),
          async onOk() {
            if (moment(broadcast.schedules[1]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && broadcast.sendType === "NOW") {
              try {
                await asyncSend();
                notify.success(translate("system.message.success", "Success"));
                history.push("/products/richmenu");
              } catch (err) {
                if (err && err.message && err?.message?.message === "RAKUTEN_HTTP_ERROR")
                  notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
                if(err && err.message && err.message.message === "PLAN_PERMISSION_INSUFFICIENT")
                  notify.error(translate("system.error.plan.permission", "Your plan permission is insufficient to use this function"));
                else
                  notify.error(err.message);
                setLoading(false);
              }
            } else {
              notify.error(translate("system.error.schedule.time", "Set schedule time in the future"));
              setLoading(false);
            }
          },
          async onCancel() {
            setLoading(false);
          }
        });
      }

      if (save === "SEND" && broadcast.sendType === "DATE") {
        if (moment(broadcast.schedules[0]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && moment(broadcast.schedules[1]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && broadcast.sendType === "DATE") {
          try {
            await asyncSend();
            notify.success(translate("system.message.success", "Success"));
            setLoading(false);
            history.push("/products/richmenu");
          } catch (err) {
            if (err.message.message === "RAKUTEN_HTTP_ERROR")
              notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
           if(err && err.message && err.message.message === "PLAN_PERMISSION_INSUFFICIENT")
              notify.error(translate("system.error.plan.permission", "Your plan permission is insufficient to use this function"));
            else
              notify.error(err.message);
            setLoading(false);
          }
        } else {
          notify.error(translate("system.error.schedule.time", "Set schedule time in the future"));
          setLoading(false);
        }
      }

      if (save === "DRAFT") {
        if (moment(broadcast.schedules[1]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && broadcast.sendType === "NOW") {
        try {
            await asyncSend();
            notify.success(translate("system.message.success", "Success"));
            setLoading(false);
            history.push("/products/richmenu");
          } catch (err) {
            if (err.message.message === "RAKUTEN_HTTP_ERROR")
              notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
            if(err && err.message && err.message.message === "PLAN_PERMISSION_INSUFFICIENT")
              notify.error(translate("system.error.plan.permission", "Your plan permission is insufficient to use this function"));
            else
              notify.error(err.message);
            setLoading(false);
          }
        } else if (moment(broadcast.schedules[0]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && moment(broadcast.schedules[1]).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") && broadcast.sendType === "DATE") {
        try {
            await asyncSend();
            notify.success(translate("system.message.success", "Success"));
            history.push("/products/richmenu");
          } catch (err) {
            if (err.message.message === "RAKUTEN_HTTP_ERROR")
              notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
            if(err && err.message && err.message.message === "PLAN_PERMISSION_INSUFFICIENT")
              notify.error(translate("system.error.plan.permission", "Your plan permission is insufficient to use this function"));
            else
              notify.error(err.message);
            setLoading(false);
          }
        } else {
          notify.error(translate("system.error.schedule.time", "Set schedule time in the future"));
          setLoading(false);
        }
      }
    } catch (error) {
      notify.error(translate("system.error.required", "Please fill out the required fields"));
      if (error.message === "NO_TARGET")
        notify.error(translate("system.notarget", "No target has been set"));
      if (error.message.message === "RAKUTEN_HTTP_ERROR")
        notify.error(translate("system.error.rakuten.http", "Rakuten data request failed!"));
      if(error && error.message && error.message === "PLAN_PERMISSION_INSUFFICIENT")
        notify.error(translate("system.error.plan.permission", "Your plan permission is insufficient to use this function"));
      else
        notify.error(error.message);

      setLoading(false);
    }
  };

  const onSubmit = async (type, data) => {
    switch (type) {
      case "target": {
        dispatch({
          type   : "message.target.data",
          payload: data.tags
        });
        break;
      }
      case "broadcast": {
        dispatch({
          type   : "message.broadcast.data",
          payload: {
            sendType: data.sendType,
            sendDate: data.sendDate
          }
        });
        break;
      }
      case "message": {
        dispatch({
          type   : "message.message.data",
          payload: {
            title: data.title,
            text : data.text
          }
        });
        break;
      }
      default:
    }
  };

  const onChangeTab = async (index) => {
    let res = await richmenuForm?.current?.validate();
    setActionsErrorMessage(true);
    if (!res) return;
    
    setActionsErrorMessage(false);

    let rchmenus = [...action.richmenus];
    rchmenus[parseInt(res.step)] = res;
    setAction({ ...action, richmenus: [...rchmenus] });
    setStep(index);
    dispatch({ type: "general.richmenuAlias", payload: [...rchmenus] });
  };

  const onEdit = (targetKey, actionType, index) => {
    console.log("action?.richmenus.length =>", action);
    if (action?.richmenus.length < 4 && actionType === "add") {
      let rchmenus = [...action?.richmenus, { ...INIT_RICHMENU }];
      setAction({ ...action, richmenus: [...rchmenus] });
      dispatch({ type: "general.richmenuAlias", payload: [...rchmenus] });
    }

    if (action.richmenus.length > 2 && actionType === "remove") {
      confirm({
        icon      : <ExclamationCircleOutlined />,
        content          : translate("system.confirmation.question", "Are you sure ?"),
        okText           : translate("system.yes", "Yes"),
        cancelText       : translate("system.no", "No"),
        async onOk() {
            let rchmenus = [...action?.richmenus];
            rchmenus.splice(targetKey, 1);
            setAction({ ...action, richmenus: [...rchmenus] });

            if(rchmenus.length < parseInt(step) + 1) {
              setStep(`${parseInt(step) - 1}`);
            }
            
            if(parseInt(step)-1  === parseInt(targetKey)) {
              setStep(`${parseInt(step) - 1}`);
            }

            dispatch({ type: "general.richmenuAlias", payload: [...rchmenus] });
            setIsRemove(!isRemove);
        } });
    }
  };
  const onRemove = () => {
    confirm({
      width  : 750,
      icon   : false,
      content: <div>
        <Alert
          description={
            <div>
              <div> {translate("richmenu.confirm.remove_1", "If you delete the rich menu within the display period, the rich menu will be deleted from the user who displayed the rich menu to be deleted, and you will need to set another rich menu again.")}</div>
              <div> {translate("richmenu.confirm.remove_2", "(If the default rich menu is set, the default rich menu will be displayed)")}</div>
              <br/>
              <div>{translate("richmenu.confirm.remove_3", "Is it OK？")}</div>
            </div>}
          type="warning"
          showIcon={false}
        />
      </div>,
      async onOk() {
        await richmenuApi.remove(editable[1]._id);
        notify.success(translate("system.message.success", "Success"));
        history.push("/products/richmenu");
      },
    });
  };
  
  const onCopy = () => {
      history.push(`/products/richmenu_alias/copy/${editable[1]?._id}`);
  };

  React.useEffect(() => {
    if(editable && editable[0] === "update"){
      setAction({...editable[1]})
    }
  }, [editable])

  React.useEffect(() => {
      if(tabAction && tabAction.targetKey){
        onEdit(tabAction.targetKey, tabAction.action, tabAction.index);
      }
    },[tabAction]);

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={editable[0] === "update" ? translate("richmenualias.edit", "Edit rich menu alias") : translate("richmenualias.new", "New rich menu alias")} extra={[
        editable[0] === "update" && disabled && <Button key={1} loading={loading} type="default" onClick={onCopy}>{translate("system.copy", "Copy")}</Button>,
        editable[0] === "update" && disabled && <Button key={1} loading={loading} type="danger" onClick={onRemove}>{translate("system.delete", "Remove")}</Button>,
        <Button key={2} type="default" loading={loading} onClick={() =>{
          if(!usable){
            notify.error(translate("system.error.plan.permission", "Your plan permission is insufficient to use this function"));
          } else {
            onSave("DRAFT")
          }
        }}>{translate("message.save.draft", "Save draft")}</Button>,
        disabled&&<Button key={3} type="primary" loading={loading} onClick={() => {
          if(!usable){
            notify.error(translate("system.error.plan.permission", "Your plan permission is insufficient to use this function"));
          } else { 
          onSave("SEND")
          }
        }
        }>{translate("message.setting.end", "Send")}</Button>,
        
      ]} />

      <PageContent>
      {!usable && <Alert className="description " type="warning" message={
          <>
            {translate("system.error.plan.permission", "Your plan permission is insufficient to use this function")}  
          </>
         } />}
        <MessageContainer>
          <Collapse bordered={false} ghost={true} activeKey={collapse} onChange={onChangeCollapse}>
            <Panel className="richmenu" key="target" header={translate("message.targetestimation", "Target Estimation")}>
              <Row gutter={[8, 8]}>
                <Col span={14}>
                  <TargetForm hideNarrowcast={true} ref={targetForm} editable={editable} onSubmit={(data) => onSubmit("target", data)} isMessage={false} segment estimate />
                </Col>
                <Col span={10}>
                  <Estimate>
                    <div className="header">{translate("message.targetestimation", "Target Estimation")}</div>
                    <div className="body">
                      <Spin spinning={target.calculating} indicator={<LoadingOutlined spin />}>
                        <Progress type="circle" percent={target.percent} />
                      </Spin>
                      <div className="friends">{translate("message.approx", "Approx")} {target.friends} {translate("message.people", "friends")}</div>
                    </div>
                  </Estimate>
                </Col>
              </Row>

              <Row gutter={[8, 8]} style={{ marginTop: 40 }}>
                <Col span={14}>
                  <Row>
                    <Col span={14} offset={8}>
                      <Button onClick={() => onNext("target")} type="default">{translate("system.next", "Next")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Panel>
            <Panel className="richmenu" key="broadcast" header={translate("richmenu.display.settings", "Display settings")}>
              <Row gutter={[8, 8]}>
                <Col span={14}>
                  <SettingsForm ref={broadcastForm} editable={editable} onSubmit={(data) => onSubmit("settings", data)} />
                  <Row style={{ marginTop: 40 }}>
                    <Col span={14} offset={8}>
                      <Button type="default" onClick={() => onNext("broadcast")}>{translate("system.next", "Next")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>

            <Panel className="richmenu" key="message" header={translate("richmenu.create", "Rich Menu Alias")}>
              <TabCotnainer>
                {actionsErrorMessage === true ? (<Alert type="error" message={translate("richmenu.alias.tab.switch.error", "You have to fill out current tab forms before moving to another")} banner />) : (<></>)}
                <div style={{height:20}}/>
                <Tabs onEdit={async (targetKey, actn, index)=> {
                    if(actn === "remove"){
                      await onChangeTab(step);
                    }
                    await delay(200);
                    setTabAction({targetKey, action: actn, index})
                  }
                  } activeKey={step} defaultActiveKey="1" type="editable-card" onChange={onChangeTab}>
                  {action?.richmenus.map((item, index) => (
                    <Tabs.TabPane  tab={`${translate("richmenu.alias.tab", "Tab")} ${index + 1}`} key={`${index}`}></Tabs.TabPane>
                  ))}
                </Tabs>
              </TabCotnainer>
              { step && (<Form isRemove={isRemove} ref={richmenuForm} editable={["update", { ...action?.richmenus[parseInt(step)] }]} step={step} onSubmit={(data) => onSubmit("message", data)} />) }
            </Panel>
            {/* editable */}
          </Collapse>
        </MessageContainer>
      </PageContent>
    </PageContainer>
  );
};

export const  delay = (delayInms) => {
  return new Promise(resolve => setTimeout(resolve, delayInms));
}

const TabCotnainer = styled.div`
  .ant-tabs-tab:first-child {
      .ant-tabs-tab-remove {
        display: none;
      }
    }
`;
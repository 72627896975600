import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Children, Dragging, IsInvalid } from "./";
import { useTranslate } from "../../../hooks";

const getBoundRectHeight = ({ children }) => {
  return children?.reduce((accumulator, iterator) => {
    switch (iterator.type) {
      case "next":
      case "start":
      case "end":
      case "timer":
      case "tag":
      case "action": {
        return accumulator + 48;
      }
      case "condition": {
        let conditionHeight = 196 - 8;

        if (iterator.children.filter(child => child.condition === "then").length > 0)
          conditionHeight -= 40;

        if (iterator.children.filter(child => child.condition === "catch").length > 0)
          conditionHeight -= 40;

        return accumulator + getBoundRectHeight({ children: iterator.children }) + conditionHeight;
      }
      case "ghost": {
        if (iterator.shape === "condition")
          return accumulator + 196 - 8;

        return accumulator + 48;
      }
      default:
        return accumulator;
    }
  }, 0);
};

export default ({ child, draggable, dragging, transform }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  let thenChildren = child.children?.filter(child => child.condition === "then");
  let catchChildren = child.children?.filter(child => child.condition === "catch");

  let thenHeight = getBoundRectHeight({ children: thenChildren }) - 8;

  if (!thenHeight || thenHeight <= 0)
    thenHeight = 30;

  let catchHeight = getBoundRectHeight({ children: catchChildren }) - 8;

  if (!catchHeight || catchHeight <= 0)
    catchHeight = 30;

  let thenOffetTop = 56;
  let catchOffetTop = thenHeight + 94;

  const onChange = (e, child) => {
    e.stopPropagation();
    dispatch({ type: "process.selected", payload: {} });
    dispatch({ type: "process.selected", payload: child });
  };

  return (
    <Dragging transform={transform} child={child} dragging={dragging} draggable={draggable} cursor="pointer" onClick={(e) => onChange(e, child)}>
      <path stroke="rgb(51, 115, 204)" fill="rgb(76, 151, 255)" d={`m 0 0 C 25 -22 71 -22 96 0 H 156 a 4 4 0 0 1 4 4 v 47 a 4 4 0 0 1 -4 4 H 64 c -2 0 -3 1 -4 2 l -4 4 c -1 1 -2 2 -4 2 h -12 c -2 0 -3 -1 -4 -2 l -4 -4 c -1 -1 -2 -2 -4 -2 h -8 a 4 4 0 0 0 -4 4 v ${thenHeight} a 4 4 0 0 0 4 4 h 8 c 2 0 3 1 4 2 l 4 4 c 1 1 2 2 4 2 h 12 c 2 0 3 -1 4 -2 l 4 -4 c 1 -1 2 -2 4 -2 H 156 H 156 a 4 4 0 0 1 4 4 v 24 a 4 4 0 0 1 -4 4 H 64 c -2 0 -3 1 -4 2 l -4 4 c -1 1 -2 2 -4 2 h -12 c -2 0 -3 -1 -4 -2 l -4 -4 c -1 -1 -2 -2 -4 -2 h -8 a 4 4 0 0 0 -4 4 v ${catchHeight} a 4 4 0 0 0 4 4 h 8 c 2 0 3 1 4 2 l 4 4 c 1 1 2 2 4 2 h 12 c 2 0 3 -1 4 -2 l 4 -4 c 1 -1 2 -2 4 -2 H 156 a 4 4 0 0 1 4 4 v 24 a 4 4 0 0 1 -4 4 H 48 c -2 0 -3 1 -4 2 l -4 4 c -1 1 -2 2 -4 2 h -12 H 4 a 4 4 0 0 1 -4 -4 z m 0 0 z`} />
      <text fill="#fff" transform="translate(8, 12)" style={{ userSelect: "none" }}>{translate("shape.judgement", "Judgement")}</text>
      <text fill="#fff" transform="translate(8, 42)" style={{ userSelect: "none" }}>{translate("shape.if", "if")}</text>
      <text fill="#fff" transform={`translate(8, ${thenHeight + 86})`} style={{ userSelect: "none" }}>{translate("shape.else", "else")}</text>

      <Dragging transform={"translate(16, 16)"} dragging={dragging} child={{ ...child, condition: "then" }} draggable={draggable} cursor="pointer" onClick={(e) => onChange(e, child)}>
        <path fill="transparent" fillOpacity="1" d="m 0,4 A 4,4 0 0,1 4,0 H 12 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 162.84796905517578 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      </Dragging>

      <g transform={`translate(16, ${thenOffetTop})`}>
        <Children children={thenChildren} dragging={dragging} draggable={draggable} />
      </g>

      <g transform={`translate(0, ${thenHeight + 57})`}>
        <Dragging dragging={dragging} child={{ ...child, condition: "catch" }} draggable={draggable} cursor="pointer" onClick={(e) => onChange(e, child)}>
          <path fill="transparent" fillOpacity="1" d="M 0 15 h 24 c 2 0 3 1 4 2 l 4 4 c 1 1 2 2 4 2 h 12 c 2 0 3 -1 4 -2 l 4 -4 c 1 -1 2 -2 4 -2 H 156 H 156 a 4 4 0 0 1 4 4 v 24 a 4 4 0 0 1 -4 4 H 64 c -2 0 -3 1 -4 2 l -4 4 c -1 1 -2 2 -4 2 h -12 c -2 0 -3 -1 -4 -2 l -4 -4 c -1 -1 -2 -2 -4 -2 h -8 c -2 0 -3 1 -4 2 H 4 a 4 4 0 0 1 -4 -4 z" />
        </Dragging>
      </g>

      <g transform={`translate(16, ${catchOffetTop})`}>
        <Children children={catchChildren} dragging={dragging} draggable={draggable} />
      </g>

      <g transform="translate(60, 5)">
        <path fill="rgb(76, 151, 255)" strokeWidth={3} stroke="rgb(51, 115, 204)" d="M16 0h8a16 16 0 010 32h-8a16 16 0 010-32z" transform="translate(48 8)"></path>
        <text fill="#ffffff" x="12" y="18" dominantBaseline="middle" dy="0" textAnchor="middle" transform="translate(48 8) translate(8)">{translate("shape.set", "Set")}</text>
      </g>
    </Dragging>
  );
};
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { PageContainer, PageContent } from "../../components/Layout";
import { useFetch, useTranslate } from "../../hooks";
import { PageHeader, Spin, Alert } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { verifyEmail } from "../../apis";
import Success from "../../assets/success.svg";

export default () => {
  const match = useRouteMatch();
  const { translate } = useTranslate();
  const [error, setError] = useState(null);
  const [fetching] = useFetch(verifyEmail.verify, match.params.token)(null, (err) => {
    if (err.message === "ALREADY_USED_TOKEN")
      setError(<div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} />{translate("system.token.used", "Already used token.")}</div>);
  });

  return (
    <div>
      <PageContainer>
        <PageHeader title="" />
        <PageContent>
          <Spin spinning={fetching}>
            {error === null ?
              <SuccessWrapper>
                <div className="success-body">
                  <img alt="Success Icon" src={Success} />
                  <span className="text">{translate("system.email.changed", "Your email is successfully verified.")}</span>
                </div>
              </SuccessWrapper>
              :
              <Alert style={{ marginBottom: 20, background: "#E35755", border: "none", borderRadius: "10px" }} message={<div style={{ color: "white" }}>{error}</div>} />
            }
          </Spin>
        </PageContent>
      </PageContainer>
    </div>
  );
};

const SuccessWrapper = styled.div`
    display : flex;
    position: absolute;
    justify-content :center;
    height: 300px;
    width: 100%;

    .success-body {
      display :flex;
      flex-direction  :column;
      align-items     :center;

      img{
          width: 100px;
      }
      .text {
        font-size: 20px;
      }
    }
`;

import React from "react";
import { useHistory } from "react-router-dom";
import { InvoiceMessage, MessageDashboard, MyTable, RowAction } from "../../components";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { payment } from "../../apis";
import { DatePicker, Descriptions, Divider, Empty, message, Space } from "antd";
import { Button } from "../../components/Design";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { useFetch } from "../../hooks";
import { useTranslate } from "../../hooks";
import { CloseCircleOutlined, ExclamationCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import Receipt from "./components/Receipt";
import confirm from "antd/lib/modal/confirm";
import colors from "../../colors";
import usePlan from "../../hooks/usePlan";
import { ManualDesc } from "../../components/Manual";

const { RangePicker } = DatePicker;

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef(null);
  const [paymentID, setPaymentID] = React.useState();
  const [receipt, setReceipt] = React.useState(false);
  const [fileExportVisible, setFileExportVisible] = React.useState(false);
  const { checkPermission } = usePlan();
  const [usable, setUsable] = React.useState(checkPermission("FILE_EXPORT"));
  const { user, customer } = useSelector(state=>state.general);
  const [filters, setFilters] = React.useState({
    query    : "",
    startDate: undefined,
    endDate  : undefined
  });

  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });

  const changeRange = (e) => {
    setFilters({ ...filters, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };
  const columns = useHeader({
    user,
    history,
    tableTitles: {
      no         : translate("system.number", "No."),
      userAccount: translate("payment.useraccount", "User account"),
      amountTax  : translate("payment.amounttax", "Amount (Tax included)"),
      paymentDate: translate("payment.paymentdate", "Payment date"),
      paymentID  : translate("payment.paymentid", "Payment ID"),
      details    : translate("payment.details", "Details"),
      status     : translate("system.status", "Status"),
      retry      : translate("payment.retry", "Retry"),
      action     : translate("system.action", "Action"),
      view       : translate("system.detail", "Detail"),
      orderID    : translate("payment.orderId", "Order ID")
    },
    onAction: async (key, item) => {
      switch (key) {
        case "view":
          let res = await payment.get(item._id);
          setPaymentID(["paymentHistory", res]);
          myTableRef.current.reload();
          setReceipt(true);
          break;
        case "retry":
          retry(item);
          break;
        default:
          return null;
      }
    },
    translate
  });

  const handlePrint = event => {
    event.preventDefault();
    window.print();
  };
  const closeReceipt = () => {
    setReceipt(false);
  };
  const exportDone = () => {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("file.export.request.accepted", "File export request has been accepted?"),
      okText    : translate("file.list.title", "Go to exported files"),
      cancelText: translate("file.export.ok", "Ok"),
      async onOk() {
        if (customer){
          history.push("/file");
        } else {
          history.push("/admin_file");
        }
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };
  const fileExport = async () => {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("file.export.request", "Would like to start exporting data to excel file?"),
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      async onOk() {
        try {
          await payment.exportFile({ filter: filters });
          exportDone();
        } catch (err) {
          console.log("err  : ", err);
          if (err.message === "FILE_PROCESSING"){
            message.error(translate("file.export.error.processing", "File is in process. Please wait until current export process is over!"));
          }
          console.log("FILE_EXPORT_ERROR : ", err);
          if (err.message === "PLAN_PERMISSION_INSUFFICIENT"){
            message.error(translate("system.error.plan.permission", "Your plan permission is insufficient to use this function"));
          }
        }
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };

  const retry = async (item) => {
    confirm({
      icon             : <ExclamationCircleOutlined />,
      content          : translate("system.confirmation.question", "Are you sure ?"),
      okText           : translate("system.yes", "Yes"),
      cancelText       : translate("system.no", "No"),
      okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
      className        : "btn-custom-class",
      async onOk() {
        await payment.retry(item._id);
        message.success(translate("system.message.success", "Success"));
        myTableRef.current.reload();
      },
    });
  };
  const clearRange = () => {
    setFilters({ ...filters, startDate: null, endDate: null });
    setRangePicker({ startDate: null, endDate: null });
  };
  return (
    <PageContainer>
      <PageHeader title={translate("payment.history", "Payment History")} extra={[]} />
      <ManualDesc translateKey ="manual.desc.payment" />
      <PageFilter>
        <Space>
          {
            usable && <Button loading={fileExportVisible} onClick={fileExport} type="primary">{translate("payment.file.export", "File export")}</Button>
          }
        </Space>
        <Space>
          <RangePicker value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
          <Button type="default" onClick={() => clearRange()}>{translate("system.clear", "Clear")}</Button>
        </Space>
      </PageFilter>
      {/* {<InvoiceMessage message={null} onFinish={() => {
        myTableRef.current.record();
      }} />} */}
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={payment.history}
          // onResult={(item) => setExportData(item.rows)}
        />
        <Modal
          maskClosable={false}
          title={translate("system.detail", "Detail")}
          visible={receipt}
          onCancel={closeReceipt}
          footer={[
            <Button type="primary" onClick={handlePrint}> <PrinterOutlined />{translate("receipt.print", "Print")}</Button>,
            <Button type="default" onClick={closeReceipt}> <CloseCircleOutlined />{translate("receipt.close", "Close")}</Button>
          ]}>
          <Receipt action={paymentID} />
        </Modal>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ tableTitles, onAction, translate, user }) => {
  return [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    },
    {
      title : tableTitles.userAccount,
      render: (value, row, index) => (
        <div>{row.customer && row.customer.companyName && row.customer.companyName}</div>
      )
    },
    {
      title : tableTitles.amountTax,
      render: (value, row, index) => {
        if (row.invoice) {
          return (
            <div>{row.invoice.payAmount.toLocaleString("jp-JP") + "￥"}</div>
          );
        }
        return (
          <div style={{ textAlign: "center" }}>-</div>
        );
      }
    },
    {
      // tz("Asia/Tokyo").
      title : tableTitles.paymentDate,
      render: (value, row, index) => {
        return row.paymentStatus === "PENDING" ? <div>-</div> : <div>{moment(row.paymentStatusDate).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm")}</div>;
      }
    },
    {
      title : tableTitles.paymentID,
      // dataIndex: "gmoTranID",
      render: (record) => {
        return (<div>{ record.gmoTranID === undefined ? (record.invoice._id) : (record.gmoTranID) }</div>);
      }
    },
    {
      title : tableTitles.orderID,
      // dataIndex: "gmoTranID",
      render: (record) => {
        return (<div>{record.invoice._id}</div>);
      }
    },
    {
      title : tableTitles.status,
      render: (record) => <div>{record.invoice && translate("system.constant." + record.paymentStatus, record.paymentStatus)}</div>
    }, {
      title : tableTitles.details,
      render: (record) => (
        <ul>{record.invoice.invoiceLines.map(item => (
          <li key={item._id}>{ item.description }</li>
        ))}</ul>
      )
    }, {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (order) => {
        if (order.paymentStatus === "FAILED") {
          return <RowAction actions={{
            retry: tableTitles.retry,
          }} onClick={(key) => onAction(key, order)} />;
        }

        return <RowAction actions={{
          view: tableTitles.view,
        }} onClick={(key) => onAction(key, order)} />;

      }
    }
  ];
};

const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-descriptions {
    width: 100%;
  }
`;
const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

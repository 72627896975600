import React from "react";
import { Form, FormItem, Input, Select } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import styled from "styled-components";
import colors from "../../../colors";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";

const URL = /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;
const FormSchema = (translate) => Yup.object({
  email: Yup.string().email(translate("webhook.mail.invalid", "Mail addres is invalid!"))
    .required(translate("system.message.required", "This field is required!")),
  uri: Yup.string().required(translate("system.message.required", "This field is required!"))
    .matches(URL, translate("webhook.url.invalid", "Format of the URL is wrong!"))
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const [data, setFormData] = React.useState({
    uri  : "",
    email: "",
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem label={translate("webhook.url", "Webhook URL")} name="uri" required>
              <Input name="uri" placeholder={translate("webhook.url", "Webhook URL")} />
            </FormItem>
            <FormItem label={translate("webhook.email", "Email")} name="email" required>
              <Input name="email" placeholder={translate("webhook.email", "Email")} />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

const InlineGroup = styled.div`
  display: flex;
  flex-direction: row;
`;
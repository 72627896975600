import React from "react";
import { Formik } from "formik";
import { Form, FormItem, Input, Radio } from "formik-antd";
import styled from "styled-components";
import { useTranslate } from "../../../hooks";
import * as Yup from "yup";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import ChooserColor from "./ChooserColor";
import { Button } from "../../../components/Design";

const { TextArea } = Input;

const FormSchema = (translate) => Yup.object().shape({
  body : Yup.string().max(500).required(translate("system.message.required", "Please fill out this field!")),
  style: Yup.string().max(500).required(translate("system.message.required", "Please fill out this field"))
});

export default ({ action, onSubmit }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    body : "",
    style: "",
    ...(action && action[1] === "update" ? action[2] : {})
  });

  return (
    <InlineNoteForm>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit} >
        {({ isSubmitting }) => {
          return (
            <Form>
              <FormItem
                name="body"
                label={translate("privatechat.addnote", "Note")}
                required
              >
                <TextArea
                  name="body"
                  rows="1"
                  placeholder={translate(
                    "privatechat.note.placeholder",
                    "Write the note here"
                  )}
                />
              </FormItem>
              <FormItem
                name="style"
                label={translate("privatechat.note.colors", "Color")}
                required
              >
                <Radio.Group name="style" size="middle" defaultValue="primary">
                  <Radio.Button value="primary">
                    <div className="color-wrapper">
                      <ChooserColor color="#429bf9" selected={false} />
                    </div>
                  </Radio.Button>
                  <Radio.Button value="error">
                    <div className="color-wrapper">
                      <ChooserColor color="#ef808a" selected={false} />
                    </div>
                  </Radio.Button>
                  <Radio.Button value="success">
                    <div className="color-wrapper">
                      <ChooserColor color="#55c771" selected={false} />
                    </div>
                  </Radio.Button>
                  <Radio.Button value="warning">
                    <div className="color-wrapper">
                      <ChooserColor color="#f5c83a" selected={false} />
                    </div>
                  </Radio.Button>
                  <Radio.Button value="info">
                    <div className="color-wrapper">
                      <ChooserColor color="#1dd5f7" selected={false} />
                    </div>
                  </Radio.Button>
                </Radio.Group>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  style={{ verticalAlign: "top", marginLeft: 10 }}
                >
                  {translate("privatechat.addnote", "Add note")}
                </Button>
              </FormItem>
            </Form>
          );
        }}
      </Formik>
    </InlineNoteForm>
  );
};

const InlineNoteForm = styled.div`
  .ant-radio-button-wrapper {
    border: none;

    &::before {
      width: 0;
    }
  }
`;
import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import { Input } from "antd";

export default ({ name, placeholder, ...restProps }) => {
  return (
    <Field name={name}>
      {fieldProps => {
        const {
          field: { value },
          form: { setFieldValue }
        } = fieldProps;

        const onChange = (e) => {
          let value = e.target.value;
          setFieldValue(name, value.toUpperCase());
        };

        return (
          <Container>
            <Input type="text" value={value} placeholder={placeholder} onChange={onChange} />
          </Container>
        );
      }}
    </Field>
  );
};
const Container = styled.div`
  position: relative;
`;
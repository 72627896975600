import React from "react";
import Form from "./Form";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import arrayToTree from "array-to-tree";
import { useTranslate } from "../../hooks";

export default () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const [editable, setEditable] = React.useState([]);

  React.useEffect(() => {
    let children = [{
      _id     : new Date().getTime(),
      index   : 0,
      type    : "trigger",
      x       : 50 * (1 / 0.7),
      y       : 50 * (1 / 0.7),
      children: []
    }];

    let tree = arrayToTree(children, {
      parentProperty: "parent",
      customID      : "_id"
    });

    let form = children.reduce((accumulator, iterator) => {
      return {
        ...accumulator,
        [iterator._id]: iterator
      };
    }, {
      [params.id]: {
        eventType: undefined,
        name     : undefined
      }
    });

    dispatch({
      type   : "process.children",
      payload: tree
    });

    dispatch({
      type   : "process.index",
      payload: children.reduce((accumulator, iterator) => {
        return {
          ...accumulator,
          [iterator._id]: iterator.index
        };
      }, {})
    });

    dispatch({
      type   : "process.childrenForm",
      payload: form
    });

    dispatch({
      type   : "general.isSubmit",
      payload: false
    });

    dispatch({
      type   : "process.isEditable",
      payload: true
    });

    dispatch({
      type   : "process.data",
      payload: {}
    });

    setTimeout(() => {
      setEditable(["create", {}, form]);
    }, 100);
  }, []);

  if (!editable[1]) return <div>{translate("system.loading", "Loading...")}</div>;

  return <Form editable={editable} />;
};

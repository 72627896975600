import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Button, Form as AntForm } from "antd";
import { Form, FormItem, Input, Select } from "formik-antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";

const { Option } = Select;
const FormSchema = (translate) => Yup.object({
  comapnyName: Yup.string().required(translate("system.message.required", "This field is required!")),
  name       : Yup.string().required(translate("system.message.required", "This field is required!")),
  email      : Yup.string().required(translate("system.message.required", "This field is required!")),
  roles      : Yup.string().required(translate("system.message.required", "This field is required!")),
});

export default ({ onSubmit, action })=> {
  const { translate } = useTranslate();
  const history = useHistory();
  const [role, setRole] = React.useState([]);
  const [data] = React.useState({
    email      : "",
    companyName: "",
    name       : "",
    roles      : [],
    ...(action && action[0] === "update" ? action[1] : {}),
  });


  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ setFieldValue, isSubmitting }) => {
          return (
            <Form {...formItemLayout} layout={"vertical"}>

              <FormItem label={translate("info.login.email", "Email")} name="email" required>
                <Input name="email" placeholder={translate("info.login.email", "Email")} />
              </FormItem>

              <FormItem label={translate("info.login.name", "Name")} name="name" required>
                <Input name="name" placeholder={translate("info.login.name", "name")} />
              </FormItem>


              <FormItem label={translate("info.login.title", "Company name")} name="companyName" required>
                <Input name="companyName" placeholder={translate("info.login.title", "Compan name")} />
              </FormItem>

              <FormItem label={translate("info.login.roles", "Roles")} name="roles" required>
                <Select placeholder={translate("", "Roles")} name="roles">
                  <Option>Role 1</Option>
                  <Option>Role 2</Option>
                </Select>
              </FormItem>

              <AntForm.Item style={{ marginTop: 20 }}>
                <Button key="1" htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
                <Button key="2" type="default" onClick={()=>{}}>{translate("system.cancel", "Cancel")}</Button>
              </AntForm.Item>

            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

const Container =styled.div`
  width :40%;
`;
import request from "../utils/request";

export const list = (data) => request.get("/api/plan", data);
export const get = (id) => request.get(`/api/plan/${id}`);
export const create = (data) => request.post("/api/plan", data);
export const update = (data) => request.put(`/api/plan/${data._id}`, data);
export const remove = (id) => request.del(`/api/plan/${id}`);

export const codes = (data) => request.get("/api/customer/plans", data);


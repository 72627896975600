import React from "react";
import { Table, message } from "antd";
import styles from "./styles.module.scss";
import styled from "styled-components";
import colors from "../../colors";

const MyTable = React.forwardRef(({
  loadData,
  columns,
  scroll,
  filters,
  pagination = true,
  limit: initialLimit = 20,
  page:  initialPage = 1,
  order,
  onTableChange = () => { },
  onResult = () => {},
  children,
  ...rest
}, ref) => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(initialPage);
  const [result, setResult] = React.useState({});
  const [limit, setLimit] = React.useState(initialLimit);

  const onChange = (pagination, filterData, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    onTableChange(filterData);

    if (sorter && Object.keys(sorter).length && sorter.order) {
      setField(sorter.columnKey);
      setSort(sorter.order === "ascend" ? 1 : -1);
    } else {
      setField(field);
      setSort(sort);
    }
  };

  const reload = React.useCallback(
    async (signal) => {
      setLoading(true);
      try {
        const res = await loadData({
          filter: filters || {
            query: ""
          },
          offset: {
            page : page,
            limit: limit,
          },
        }, { signal });

        if (res.message && res.message.type === "error")
          message.error(res.message.text);
        const data = res.rows.map((item, index) => {
          item.key = ((page - 1) * limit) + (index + 1);
          return item;
        });

        setItems(data);
        setResult(res);
        setTotal(res.count);
        setLoading(false);
        onResult(res);
      } catch (error) {
        setLoading(false);
      }
    },
    [loadData, limit, page, filters]
  );

  const changePage = (number) => {
    setPage(number);
  };
  const onShowSizeChange=(current, pageSize)=> {
    setPage(current);
    setLimit(pageSize);
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [page, limit, filters]);

  React.useEffect(() => {
    setPage(initialPage);
    setLimit(initialLimit);

    const abortController = new AbortController();
    return () => abortController.abort();
  }, [filters]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    getItems() {
      return items;
    },
    getResult() {
      return result;
    }
  }));

  return (
    <TableStyled>
      <Table
        {...{
          scroll      : scroll || { x: 1000 },
          bordered    : false,
          rowClassName: styles.row,
          className   : styles.table,
          columns,
          dataSource  : items,
          pagination  : pagination
            ? {
              className       : styles.pagination,
              defaultCurrent  : 1,
              showTitle       : true,
              showSizeChanger : true,
              onShowSizeChange: (current, pageSize) => { onShowSizeChange(current, pageSize); },
              onChange        : pageNumber => { changePage(pageNumber);},
              total           : total,
              pageSize        : limit,
              current         : page,
            }
            : false,
          onChange,
          ...rest,
        }}
        loading={loading}
      />
      {children}
    </TableStyled>
  );
});

const TableStyled = styled.div`
  .ant-spin-dot-item {
    background : ${colors.primaryDark};
  }
  .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link {
    border-color : ${colors.primaryDark}
  }
  .ant-pagination-item-active {
    border-color :#85e455;
    color :black;
  }
  .ant-pagination-item-active a {
    color : black;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color : ${colors.primaryDark}
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color : ${colors.primary}
  }
`;

export default MyTable;

import { useMemo } from "react";
import { useSelector } from "react-redux";

export default () => {
  const { user } = useSelector(state => state.general);


  return {
    checkRole: (roles = []) => {
      if (!roles && roles.length === 0) {
        return {
          permission: (permissions = []) => {
            return false;
          }
        };
      }
      if (!roles.includes(user.role))
        return false;

      return {
        permission: (permissions = []) => {
          if (!permissions.includes(user.permission))
            return false;
          return true;
        }
      };
    }
  };
};

import styled from "styled-components";
export const NoteDesign = styled.div`
  border-radius :8px;
    &&.primary {
      
        border-color : #b8daff ;
        background-color : #cce5ff ;
        color : #004085;
        .color {
          background-color : #429bf9;
        }
    }
    &&.error {
        border-color : #f5c6cb ;
        background-color : #f8d7da ;
        color : #721c24;
        .color {
          background-color : #ef808a;
        }
    }
    &&.success {
        border-color : #c3e6cb ;
        background-color : #d4edda ;
        color : #155724;
        .color {
          background-color : #55c771;
        }
    }
    &&.warning {
        border-color : #ffeeba ;
        background-color : #fff3cd;
        color : #856404 ;
        .color {
          background-color : #f5c83a;
        }
    }
    &&.info {
        border-color : #bee5eb ;
        background-color : #d1ecf1;
        color : #0c5460 ;
        .color {
          background-color : #1dd5f7;
        }
    }
`;
import React from "react";
import { Space, Tooltip } from "antd";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useTranslate } from "../../../hooks";
import moment from "moment";

export default ({ type, theme = "dark", user, account }) => {

  const dispatch = useDispatch();
  const { isBalanceNotfication } = useSelector(state => state.general);
  const { balanceNotfication } = useSelector(state => state.socket);
  const { translate } = useTranslate();
  const [alert, setAlert] = React.useState(user.balance.alert);

  const onPreview = (preview) => {
    dispatch({ type: "balance.notfication", payload: preview });
  };
  const reload = (type) => {

    if (balanceNotfication && Object.keys(balanceNotfication).length > 0)
    {
      setAlert(balanceNotfication);
    }
    else {
      setAlert(user.balance.alert);
    }
  };


  React.useEffect(() => {
    console.log(account);
    reload();
  }, [user.balance.alert]);

  React.useEffect(() => {
    reload("socket");
  }, [balanceNotfication]);

  return (
    <PreviewContainer className={`${type} ${theme}`}>
      <PreviewHeader className={theme} onClick={() => onPreview(!isBalanceNotfication)}>
        <Space>
          <a className="nav" onClick={() => onPreview(!isBalanceNotfication)} href="#">
            {type === "fixed" &&
              (isBalanceNotfication === true ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownOutlined />
              ))}{" "}
          </a>
        </Space>
        {/* <Space>
          <Tooltip placement="topLeft" title={"Notfication."}>
            <a className="info" href="#">
              <InfoCircleOutlined />
            </a>
          </Tooltip>
        </Space> */}
      </PreviewHeader>

      <PreviewContent

        style={{ marginRight: "20px" }}
        className={`${isBalanceNotfication && "open"} ${type}`}
      >
        <div className="limited-card-wrapper">

          {
            alert.follower &&
          <div className="limited-card-item">
            <div className="card-body">
              {translate("system.alert.follower.limit", "Follower Limit has been reached. Please upgrade your plan")}
            </div>
            <div className="card-footer">
              {moment(alert.follower.date).format("YYYY/MM/DD")}
            </div>
          </div>
          }

          {
            alert.message &&
          <div className="limited-card-item">
            <div className="card-body">
              {translate("system.alert.message.limit", "Free message limit balance is low")}
            </div>
            <div className="card-footer">
              {moment(alert.message.date).format("YYYY/MM/DD")}
            </div>
          </div>
          }
          {
            alert.account &&
          <div className="limited-card-item">
            <div className="card-body">
              {translate("system.alert.account.limit", "Account connection limit has been reached. Please upgrade your plan")}
            </div>
            <div className="card-footer">
              {moment(alert.account.date).format("YYYY/MM/DD")}
            </div>
          </div>
          }

          {
            account?.alert?.licenseKey &&
          <div className="limited-card-item">
            <div className="card-body">
              {translate("system.alert.rakuten.licensekey1", "Rakuten License key due date will be over in ")}{account?.alert?.licenseKey?.days ? account?.alert?.licenseKey?.days : "~" }{translate("system.alert.rakuten.licensekey2", "days. Please update license key !")}
            </div>
            <div className="card-footer">
              {moment(account?.alert?.licenseKey.date).format("YYYY/MM/DD")}
            </div>
          </div>
          }
        </div>
      </PreviewContent>
      {/* <PreviewAccountInfo/> */}
    </PreviewContainer>
  );
};
const PreviewContainer = styled.div`
   

  border-radius: 4px;
  border: 1px solid #dfdfdf;
  width: 270px;
 
  &.dark {
    background: white;
    border: none!important;
  }

  &.fixed {
    height: inherit!important;
    border-bottom: 0;
    position: absolute;
    right: 1rem;
    bottom: 0;
    z-index: 1000;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  }
`;
const Message = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  .avatar {
    img {
      width: 45px;
      height: 45px;  
      border-radius :30px;
      border-radius: 50%;
      margin-right : 10px;
    }
  }
  .message {
    position: relative;
    width: 100%;
    .title {
      font-size: 13px;
      color: #fff;
      padding: 2px;
    }
    .body {
      line-break: anywhere;
      display: inline-block;
      position: relative;
      font-size: 13px;
      padding: 6px 8px;
      background: #e6f7ff;
      border-radius: 20px;
      max-width: 250px;
      
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0.75rem;
        width: 0;
        height: 0;
        border: 0.5rem solid transparent;
        border-right-color: #edeff0;
        border-left: 0;
        border-top: 0;
        margin-left: -0.375rem;
      }
    }
  }
`;
const PreviewContent = styled.div`
  width: 270px;
  height: 252px;
  overflow-y: auto;
  
  transition: margin 0.3s ease-in-out;
  padding: 0px 0px 0px 5px;
  // 282
  &.fixed {
    margin-bottom: -282px;
    padding-right: 5px;
  }
  &.open {
    margin-bottom: 0;
  }
`;
const PreviewHeader = styled.a`
  padding: 0px 15px;
  margin-top: -20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fcfcfc;
  border-bottom: 1px solid #dfdfdf;
  
  &.dark {
    background: #009d4b;
    border: none!important;
    .nav {
      color: #fff;
    }
  }
  .nav {
    text-decoration: none;
    font-size: 14px;
    color: #333;
    .anticon {
      margin-right: 5px;
    }
  }
  .info {
    text-decoration: none;
    font-size: 14px;
    color: #fff;
  }
`;

const PreviewAccountInfo = styled.div`
  /* background: white; */
  /* padding: 12px 18px; */
  /* color: #fff; */

  /* span { margin-right: 12px; } */
`;
import React from "react";
import styled from "styled-components";
import colors from "../../../../colors";
import { Tabs } from "antd";
import { useSelector } from "react-redux";

export default ({ onClick, emojiAction }) => {
  const { TabPane } = Tabs;
  const { emojiProducts, emojis } = useSelector(state => state.general);

  return (
    <Emoji style={{ display: emojiAction }}>
      <Tabs defaultActiveKey={`${emojiProducts[0] && emojiProducts[0].productId}`}>
        {emojiProducts.map((product, index) => (
          <TabPane key={index} tab={<span><img src={`/emoji/${product.productId}/tab_on.png`} /> </span>} key={product.productId}>
            <div className="emoji-wrapper">
              {emojis.filter(emoji => emoji.productId === product.productId).map((emoji, index) => {
                return (
                  <a key={index} onClick={() => onClick({
                    productId: emoji.productId,
                    emojiId  : emoji.emojiId,
                    altText  : emoji.altText
                  })}>
                    <img alt={`[${emoji.altText}]`} src={`/emoji/${emoji.productId}/${emoji.emojiId}_key.png`} />
                  </a>
                );
              })}
            </div>
          </TabPane>
        ))}
      </Tabs>
    </Emoji>
  );
};

const Emoji = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
  padding: 0 5px;
  .emoji-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: 300px;
    overflow: scroll;
    justify-content: space-between;
    a {
      display: flex;
      cursor: pointer;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.primaryDark};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.primaryDark};
    }
  }
  .ant-tabs {
    width :100%; 
  }
  img {
    display: block;
    height: 36px;
    transition: width .1s; 
  }
`;
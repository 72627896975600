import React from "react";
import { Upload, message, Button } from "antd";
import { useTranslate } from "../../../hooks";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

export default ({ action, text, onResult = () => {} })=> {
  const { translate } = useTranslate();
  const [load, setLoad] = React.useState(false);
  const FILE_TYPES = ["image/jpeg", "image/png", "image/jpg", "image/tiff", "image/gif", "image/bmp"];

  const onBeforeUpload = (file, translate, setLoad) => {

    const isJpgOrPng = FILE_TYPES.indexOf(file.type) !== -1;

    if (!isJpgOrPng) {
      message.error(translate("message.error.fileformat", "You can only upload JPG/PNG file!"));
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error(translate("system.file.size.2mb", "Image must smaller than 2MB!"));
    }


    return isJpgOrPng && isLt2M;
  };

  const props = {
    name          : "file",
    maxCount      : 1,
    action        : `${action}`,
    showUploadList: false,
    beforeUpload  : (e)=>onBeforeUpload(e, translate, setLoad),
    accept        : ["image/jpeg", "image/png", "image/tiff", "image/gif", "image/bmp"],

    onChange(info) {
      console.log("=> ", info);
      setLoad(true);
      if (info.file.status !== "uploading") {
        onResult(info.file.response);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} ${translate("system.message.success.upload", "File uploaded successfully!")} `);
        setLoad(false);
      } else if (info.file.status === "error") {
        console.log(info);
        setLoad("=======>", false);
        if (info?.file?.response?.message === "AuthError") {
          message.error(translate("system.message.failed.upload.auth", "File upload failed!"));
        }
        message.error(info?.file?.response?.message || `${info.file.name}${translate("system.message.failed.upload", "File upload failed!")} `);
        return null;
      } else if (!info.file.status) {
        setLoad(false);
        return null;
      }
    },
  };

  return (
    <Upload {...props}>
      <Button loading={load} icon={<UploadOutlined />}>{text}</Button>
    </Upload>
  );
};
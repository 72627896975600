import React from "react";
import { operation as operationApi } from "../../../apis";
import { useFetch, useTranslate } from "../../../hooks";
import { PageContainer, PageContent, PageHeader } from "../../../components/Layout";
import { useHistory, useParams } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import styled from "styled-components";
import moment from "moment";
import Avatar from "antd/lib/avatar/avatar";
import { message, Space, Spin, Tooltip } from "antd";
import { MessagePreview, Sticker } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { QuestionCircleOutlined } from "@ant-design/icons";
import NoImage from "../../../assets/no_image.png";

export default () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const { lang, messageChildTypes } = useSelector(state =>state.general);
  const [details, fetching] = useFetch(operationApi.broadcastDetail, params.id)({});
  const { filters } = React.useState({
    filter: {},
    offset: {
      page : 1,
      limit: 30
    }
  });
  const messagePreview =()=> {
    const payload = {
      children: details.children.map(child => ({
        ...child,
        template         : child.template && child.template._id,
        templateValues   : child.template,
        cooperation      : child.cooperation && child.cooperation._id,
        cooperationValues: child.cooperation,
        imagemap         : child.imagemap && child.imagemap._id,
        imagemapValues   : child.imagemap,
        carousel         : child.carousel && child.carousel._id,
        carouselValues   : child.carousel
      })),
      segments: details.targets,
      _id     : undefined
    };
    dispatch({
      type   : "message.message.data",
      payload: payload
    });
  };

  const messageView =(message)=> {
    if (message){
      if (message.type === "sticker"){
        return (
          // <Sticker id={message.packageId} />
          <Sticker
            size="small"
            packageId={message.packageId}
            id={message.stickerId}
          />
        );
      } if (message?.text){
        return (<div>{message.text}</div>);
      }
      return (<Avatar shape="square" size={80} src={message && message?.previewImage?message?.previewImage:NoImage} />);
    }
    return (<div></div>);
  };


  const convertType = (array, key) => {
    const initialValue = {};
    let objectsType ={};

    objectsType = array.reduce((obj, item) => {
      return {
        ...obj,
        [item.code]: item,
      };
    }, initialValue);

    if (objectsType[key])
      return objectsType[key][lang.toLowerCase()];
    return "-";
  };

  React.useEffect(()=> {
    if (!fetching){
      messagePreview();
    }
  }, [fetching]);

  return (
    <Spin spinning={fetching}>
      <PageContainer>
        <PageHeader onBack={() => history.push("/operation/broadcast_report")} title={translate("broadcast.detail.title", "Broadcast detail")} />
        {!fetching && (
          <PageContent>
            <Container>
              <h3 > </h3>
              <BroadcastDetail
                ref={myTableRef}
                filters={filters}
                dataSource={[{}]}
                bordered
                thead={() => (
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" >{translate("broadcast.detail.sent.date", "Date sent")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.delivered", "Delivered")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.open.count", "Open count")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.clicked.user", "Clicked user count")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.played.user.all", "Played count (all users)")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.played.user.100", "Played count (100% playback)")}</th>
                    </tr>
                  </thead>
                )} tbody={(row, index) => (
                  <tbody key={index} className="ant-table-tbody">
                    <tr>
                      <td className="ant-table-cell" >{moment(details.sendDate).format("YYYY-MM-DD HH:mm")}</td>
                      <td className="ant-table-cell" >{details.delivered ? details.delivered : "-"}</td>
                      <td className="ant-table-cell" >{details.uniqueImpression ? details.uniqueImpression : "-"}</td>
                      <td className="ant-table-cell" >{details.uniqueClick ? details.uniqueClick : "-"}</td>
                      <td className="ant-table-cell" >{details.uniqueMediaPlayed ? details.uniqueMediaPlayed : "-"}</td>
                      <td className="ant-table-cell" >{details.uniqueMediaPlayed100Percent ? details.uniqueMediaPlayed100Percent : "-"}</td>
                    </tr>
                  </tbody>
                )} />
            </Container>

            <Container>
              <h3 className="title">{translate("broadcast.detail.impressions", "Impressions")}</h3>
              <BroadcastDetail
                ref={myTableRef}
                filters={filters}
                dataSource={details.impressions}
                bordered
                thead={() => (
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" >{translate("broadcast.detail.impressions.order", "Order sent")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.impressions.message", "Message")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.impressions.type", "Type")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.impressions.open", "Impressions")}</th>
                    </tr>
                  </thead>
                )} tbody={(row, index) => {
                  return (
                    <tbody key={index} className="ant-table-tbody">
                      <tr>
                        <td className="ant-table-cell" >{index + 1}</td>
                        <td className="ant-table-cell" >{messageView(row?.messageData)}</td>
                        <td className="ant-table-cell" >{messageChildTypes && convertType(messageChildTypes, row?.messageData?.type)}</td>
                        <td className="ant-table-cell" >{row?.impression? row?.impression:"-"}</td>
                      </tr>
                    </tbody>
                  );
                }} />
            </Container>
            <Container>
              <h3 className="title">{translate("broadcast.detail.clicks", "Link clicks")}</h3>
              <BroadcastDetail
                ref={myTableRef}
                filters={filters}
                bordered
                dataSource={details.clicks}
                thead={() => (
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" >{translate("broadcast.detail.clicks.url", "URL")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.clicks.order", "Order sent")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.clicks.click", "Click")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.clicks.unique.click", "Unique Click")}</th>
                      <th className="ant-table-cell" >
                        <Space>
                          {translate("broadcast.detail.clicks.click.request", "Click Request")}
                          <Tooltip placement="top" title={translate("broadcast.detail.clicks.click.request.tooltip", "Number of users who opened this url through any link in the message. If a message contains two links to the same URL and a user opens both links, they're counted only once.")}>
                            <a>
                              <QuestionCircleOutlined />
                            </a>
                          </Tooltip>
                        </Space>
                      </th>
                    </tr>
                  </thead>
                )} tbody={(row, index) => (
                  <tbody key={index} className="ant-table-tbody">
                    <tr>
                      <td>{row.url ? row.url : "-"}</td>
                      <td>{row.seq ? row.seq : "-"}</td>
                      <td>{row.click ? row.click : "-"}</td>
                      <td>{row.uniqueClick ? row.uniqueClick : "-"}</td>
                      <td>{row.uniqueClickOfRequest ? row.uniqueClickOfRequest : "-"}</td>
                    </tr>
                  </tbody>
                )} />
            </Container>
            <Container>
              <h3 className="title">{translate("broadcast.detail.video", "Video and audio")}</h3>
              <BroadcastDetail
                ref={myTableRef}
                filters={filters}
                dataSource={details.videoData}
                bordered
                thead={() => (
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="cm-th" rowSpan="2" colSpan="1" >{translate("broadcast.detail.video.message", "Message")}</th>
                      <th className="cm-th" rowSpan="2" colSpan="1">{translate("broadcast.detail.video.order", "Order sent")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.video.impressions", "Impressions")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.video.played.25", "Media played (25%)")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.video.played.50", "Media played (50%)")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.video.played.75", "Media played (75%)")}</th>
                      <th className="ant-table-cell" >{translate("broadcast.detail.video.played.100", "Media played (100%)")}</th>
                    </tr>
                    <tr>
                      <th className="cm-th">{translate("broadcast.detail.video.unique.impressions", "uniqueMediaPlayed")}</th>
                      <th className="cm-th">{translate("broadcast.detail.video.unique.played.25", "Unique media played (25%)")}</th>
                      <th className="cm-th">{translate("broadcast.detail.video.unique.played.50", "Unique media played (50%)")}</th>
                      <th className="cm-th">{translate("broadcast.detail.video.unique.played.75", "Unique media played (75%)")}</th>
                      <th className="cm-th">{translate("broadcast.detail.video.unique.played.100", "Unique media played (100%)")}</th>
                    </tr>
                  </thead>
                )} tbody={(row, index) => (
                  <tbody key={index} className="ant-table-tbody">
                    <tr style={{ borderCollapse: "collapse", borderColor: "orange" }}>
                      <td className="cm-td" rowSpan="2"><Avatar shape="square" size={80} src={row.messageData[0]?.previewImage ? row.messageData[0]?.previewImage : NoImage} /></td>
                      <td className="cm-td" rowSpan="2"> {row.seq}</td>

                      <td> {row.mediaPlayed ? `${row.mediaPlayed} ` : "-"}</td>
                      <td> {row.mediaPlayed25Percent ? `${row.mediaPlayed25Percent} ` : "-"}</td>
                      <td> {row.mediaPlayed50Percent ? `${row.mediaPlayed50Percent} ` : "-"}</td>
                      <td> {row.mediaPlayed75Percent ? `${row.mediaPlayed75Percent} ` : "-"}</td>
                      <td> {row.mediaPlayed100Percent ? `${row.mediaPlayed100Percent} ` : "-"}</td>
                    </tr>
                    <tr style={{ borderCollapse: "collapse", borderColor: "orange" }}>
                      <td className="cm-td"> {row.uniqueMediaPlayed ? `${row.uniqueMediaPlayed} ` : "-"}</td>
                      <td className="cm-td"> {row.uniqueMediaPlayed25Percent ? `${row.uniqueMediaPlayed25Percent} ` : "-"}</td>
                      <td className="cm-td"> {row.uniqueMediaPlayed50Percent ? `${row.uniqueMediaPlayed50Percent} ` : "-"}</td>
                      <td className="cm-td"> {row.uniqueMediaPlayed75Percent ? `${row.uniqueMediaPlayed75Percent} ` : "-"}</td>
                      <td className="cm-td"> {row.uniqueMediaPlayed100Percent ? `${row.uniqueMediaPlayed100Percent} ` : "-"}</td>
                    </tr>
                  </tbody>
                )} />
            </Container>
            <MessagePreview type="fixed" />
          </PageContent>
        )}
      </PageContainer>
    </Spin>
  );
};

const Container = styled.div`
  .title { 
    font-weight : 500;
    margin-top: 40px;
  }
`;
const BroadcastDetail = styled(CustomTable)`
  .ant-pagination { 
    display: none;
  }
  .ant-table-cell {
    padding: 8px!important;
  }
  .cm-td { 
    border-bottom: solid 2px #dcd8d8 !important
  }
  .cm-th {
    border-bottom: solid 2px #dcd8d8 !important;
      padding: 8px!important;
  }

`;

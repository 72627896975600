import React from "react";
import Step from "./Step";
import Tag, { TagGhost } from "./Tag";
import Action, { ActionGhost } from "./Action";
import NextStep, { NextStepGhost } from "./NextStep";
import StartStep, { StartStepGhost } from "./StartStep";
import EndStep, { EndStepGhost } from "./EndStep";
import Timer, { TimerGhost } from "./Timer";
import Condition, { ConditionGost } from "./Condition";
import When, { WhenGhost } from "./When";
import Trigger from "./Trigger";
import { useDispatch, useSelector } from "react-redux";

export const IsInvalid = ({ child, strokeWidth, strokeColor, draggable, children }) => {
  const { selected, childrenForm } = useSelector(state => state.process);
  const { isSubmit } = useSelector(state => state.general);

  let valid = !!childrenForm[child._id],
    width = strokeWidth,
    color = strokeColor;

  if (!valid && !draggable && isSubmit) {
    width = 3;
    color = "red";

    return children({ strokeColor: color, strokeWidth: width });
  }

  if (selected && child && child._id === selected._id) {
    width = strokeWidth;
    color = strokeColor;
  }

  return children({ strokeColor: color, strokeWidth: width });
};

export const Dragging = ({ child, dragging, draggable: isDraggable, children, transform, menu, onStop, onClick, cursor }) => {
  const dispatch = useDispatch();
  const { process } = useSelector(state => state);
  const [draggable, setDraggable] = React.useState({
    dragging : false,
    originalX: child ? child.x : 0,
    originalY: child ? child.y : 0
  });

  const onMouseDown = (e) => {
    e.stopPropagation();

    const { clientX, clientY } = e;

    setTimeout(() => {
      setDraggable({
        dragging : true,
        originalX: clientX - 24 - 24,
        originalY: clientY - 88
      });
    }, 50);
  };

  const onMouseUp = (e) => {
    e.stopPropagation();
    setDraggable({
      dragging: false
    });

    setTimeout(() => {
      setDraggable({
        dragging: false,
      });
    }, 50);

    if (onStop)
      onStop(e);
  };

  const onMouseMove = (e) => {
    e.stopPropagation();
    if (draggable.dragging === true && !process.dragging) {
      dispatch({
        type   : "process.dragging",
        payload: {
          x       : draggable.originalX,
          y       : draggable.originalY,
          children: menu ? [{ ...menu, _id: new Date().getTime(), sort: new Date().getTime(), valid: null }] : dragging
        }
      });

      setDraggable({
        ...draggable,
        dragging: false
      });
    }
  };

  const onMouseEnter = () => {
    if (child && (process.enter && process.enter._id !== child._id || !process.ender)) {
      dispatch({
        type   : "process.enter",
        payload: child
      });
    }
  };

  if (isDraggable)
    return <g>{children}</g>;

  if (process.dragging || !process.isEditable)
    return <g cursor={cursor} transform={transform || "translate(0, 0)"} onMouseEnter={onMouseEnter} onClick={onClick}>{children}</g>;

  if (child?.type === "judgement" || child?.type === "trigger")
    return <g filter={process?.selected?._id === child?._id && "url(#selected)"} cursor={cursor} transform={transform || "translate(0, 0)"} onClick={onClick}>{children}</g>;

  return (
    <g filter={!menu && process?.selected?._id === child?._id && "url(#selected)"} cursor={cursor} transform={transform || "translate(0, 0)"} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove} onClick={onClick}>{children}</g>
  );
};

export const Children = React.forwardRef(({ step, children, draggable }, ref) => {
  if (!children) return;

  let Render = children.reduce((Accumulator, child, index) => {
    let dragging = children.slice(index, children.length);

    switch (child.type) {
      case "step":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <Step child={child} dragging={dragging} draggable={draggable} transform={transform} index={index}>
              {children}
            </Step>
          </Accumulator>
        );
      case "trigger":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <Trigger child={child} dragging={dragging} draggable={draggable} transform={transform} index={index}>
              {children}
            </Trigger>
          </Accumulator>
        );
      case "action":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <Action child={child} step={step} dragging={dragging} draggable={draggable} transform={transform} index={index}>
              {children}
            </Action>
          </Accumulator>
        );
      case "tag":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <Tag child={child} step={step} dragging={dragging} draggable={draggable} transform={transform} index={index}>
              {children}
            </Tag>
          </Accumulator>
        );
      case "next":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <NextStep child={child} step={step} dragging={dragging} draggable={draggable} transform={transform} index={index}>
              {children}
            </NextStep>
          </Accumulator>
        );
      case "start":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <StartStep child={child} step={step} dragging={dragging} draggable={draggable} transform={transform} index={index}>
              {children}
            </StartStep>
          </Accumulator>
        );
      case "end":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <EndStep child={child} step={step} dragging={dragging} draggable={draggable} transform={transform} index={index}>
              {children}
            </EndStep>
          </Accumulator>
        );
      case "timer":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <Timer child={child} step={step} dragging={dragging} draggable={draggable} transform={transform} index={index}>
              {children}
            </Timer>
          </Accumulator>
        );
      case "condition":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <Condition child={child} step={step} dragging={dragging} draggable={draggable} transform={transform}>
              {children}
            </Condition>
          </Accumulator>
        );
      case "when":
        return ({ children, transform }) => (
          <Accumulator key={index} transform="translate(0, 48)">
            <When child={child} step={step} dragging={dragging} draggable={draggable} transform={transform}>
              {children}
            </When>
          </Accumulator>
        );
      case "ghost": {
        return ({ children, transform }) => {
          switch (child.shape) {
            case "action": {
              return (
                <Accumulator key={index} transform="translate(0, 48)">
                  <ActionGhost child={child} step={step} dragging={dragging} transform={transform}>{children}</ActionGhost>
                </Accumulator>
              );
            }
            case "tag": {
              return (
                <Accumulator key={index} transform="translate(0, 48)">
                  <TagGhost child={child} step={step} dragging={dragging} transform={transform}>{children}</TagGhost>
                </Accumulator>
              );
            }
            case "next": {
              return (
                <Accumulator key={index} transform="translate(0, 48)">
                  <NextStepGhost child={child} step={step} dragging={dragging} transform={transform}>{children}</NextStepGhost>
                </Accumulator>
              );
            }
            case "start": {
              return (
                <Accumulator key={index} transform="translate(0, 48)">
                  <StartStepGhost child={child} step={step} dragging={dragging} transform={transform}>{children}</StartStepGhost>
                </Accumulator>
              );
            }
            case "end": {
              return (
                <Accumulator key={index} transform="translate(0, 48)">
                  <EndStepGhost child={child} step={step} dragging={dragging} transform={transform}>{children}</EndStepGhost>
                </Accumulator>
              );
            }
            case "timer": {
              return (
                <Accumulator key={index} transform="translate(0, 48)">
                  <TimerGhost child={child} step={step} dragging={dragging} transform={transform}>{children}</TimerGhost>
                </Accumulator>
              );
            }
            case "condition": {
              return (
                <Accumulator key={index} transform="translate(0, 48)">
                  <ConditionGost child={child} step={step} dragging={dragging} transform={"translate(0, 188)"}>{children}</ConditionGost>
                </Accumulator>
              );
            }
            case "when": {
              return (
                <Accumulator key={index} transform="translate(0, 48)">
                  <WhenGhost child={child} step={step} dragging={dragging} transform={"translate(0, 188)"}>{children}</WhenGhost>
                </Accumulator>
              );
            }
            default:
              return (
                <Accumulator key={index} transform="translate(0, 48)">
                  <ActionGhost child={child} step={step} dragging={dragging} transform={transform}>{children}</ActionGhost>
                </Accumulator>
              );
          }
        };
      }
      default:
        return <></>;
    }
  }, ({ children }) => <g transform="translate(0, 0)" ref={ref}>{children}</g>);

  return <Render />;
});

export { Step, Trigger, Condition, Action };
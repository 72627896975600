
import React, { useEffect } from "react";
import { Image } from "antd";
import "antd/dist/antd.css";
import styled from "styled-components";
import NoImage from "../../../assets/no_image.png";


export default ({ message }) => {
  const [image, setImage] = React.useState(message?.image?.previewImageUrl);

  useEffect(() => {
    setImage(message?.image?.previewImageUrl);
  }, [message?._id]);

  return (
    <Container style={{ pointerEvents: image?"":"none" }} >
      <Image
        placeholder ={true}
        width={200}
        src={image || NoImage}
        onPreviewClose= {(vis) =>{
          setImage(message.image?.previewImageUrl);
        }}
        onClick={(e)=> setImage(message?.image?.originalContentUrl)}
      />
    </Container>
  );
};

const Container = styled.div`

    .ant-image-img {
        border-radius: 13px; 
    }
`;
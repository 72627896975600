import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dragging, IsInvalid } from ".";
import { useTranslate } from "../../../hooks";

export const ActionMenu = ({ x, y }) => {
  const { translate } = useTranslate();
  return (
    <Dragging transform={`translate(${x}, ${y})`} menu={{ type: "action" }}>
      <path stroke="#774DCB" fill="#9966FF" d="m 0,4 A 4,4 0 0,1 4,0 H 12 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 220.8638801574707 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      <text fill="#fff" transform="translate(8, 30)" style={{ userSelect: "none" }}>{translate("shape.action", "Action set")}</text>
    </Dragging>
  );
};

export const ActionGhost = ({ children, transform }) => {
  return (
    <g transform="translate(0, 0)">
      <path stroke="#e5e5e5" fill="#e5e5e5" d="m 0,4 A 4,4 0 0,1 4,0 H 12 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 220.8638801574707 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      <g transform={transform}>{children}</g>
    </g>
  );
};

export default ({ child, children, dragging, draggable, transform, index }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { childrenForm } = useSelector(state => state.process);

  const onChange = (e, child) => {
    e.stopPropagation();
    dispatch({ type: "process.selected", payload: {} });
    dispatch({ type: "process.selected", payload: child });
  };

  const title = (str) => {
    if (str?.length > 10)
      return str.substring(0, 10) + "...";

    return str;
  };

  return (
    <Dragging dragging={dragging} draggable={draggable} child={child} index={index} cursor="pointer" onClick={(e) => onChange(e, child)}>
      <path stroke="#774DCB" fill="#9966FF" d="m 0,4 A 4,4 0 0,1 4,0 H 12 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 220.8638801574707 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      <text fill="#fff" transform="translate(8, 30)" style={{ userSelect: "none" }}>{childrenForm[child?._id] ? title(childrenForm[child?._id].name) : translate("shape.action", "Action set")}</text>
      <IsInvalid child={child} strokeWidth={3} strokeColor="#774DCB" draggable={draggable}>
        {({ strokeColor, strokeWidth }) => (
          <g transform="translate(125, 0)">
            <path fill="#9966FF" stroke={strokeColor} strokeWidth={strokeWidth} d="M16 0h8a16 16 0 010 32h-8a16 16 0 010-32z" transform="translate(48 8)"></path>
            <text x="12" y="18" fill="#fff" dominantBaseline="middle" dy="0" textAnchor="middle" transform="translate(48 8) translate(8)">{translate("shape.set", "Set")}</text>
          </g>
        )}
      </IsInvalid>
      <g transform={transform}>{children}</g>
    </Dragging>
  );
};
import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { cooperation } from "../../../apis";

export default ({ values, index })=> {
  const [scrollLeftDisabled, setScrollLeftDisabled] = React.useState(true);
  const [scrollRightDisabled, setScrollRightDisabled] = React.useState(false);
  const ref = React.useRef();

  const right=()=> {
    let elmnt = document.getElementById(`myDIV${index}`);
    if ((elmnt.scrollLeft +280) + 308 >= ref.current.scrollWidth) {
      setScrollRightDisabled(true);
    } else {
      setScrollLeftDisabled(false);
    }
    elmnt.scrollLeft += 280;
  };

  const left=()=> {
    let elmnt = document.getElementById(`myDIV${index}`);
    if ((elmnt.scrollLeft -280) < 0) {
      setScrollLeftDisabled(true);
    } else {
      setScrollRightDisabled(false);
    }
    elmnt.scrollLeft -= 280;
  };
  const onClick = async (action) => {
    console.log("CLICKED_ACTION", action);
    switch (action.type) {
      case "uri":
        window.open(action.isTagged ? action?.taggedUrl : action?.linkUri, "_blank", "noreferrer");
        break;
      case "message":
        break;
      case "share":
        window.open(`https://line.me/R/nv/recommendOA/${action.lineBasicId}`, "_blank", "noreferrer");
        break;
      case "cooperation":
        let data = await cooperation.get("rakuten_order");
        window.open(data?.link, "_blank", "noreferrer");
        break;
      case "coupon":
        window.open(action.isTagged ? action?.taggedUrl : action?.linkUri, "_blank", "noreferrer");
        break;
      case "no_action":
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {values.columns.length > 1 &&
      <ButtonGroup>
        <Button size="large" shape="circle" onClick={left}>
          <LeftOutlined />
        </Button>
        <Button size="large" shape="circle" onClick={right}>
          <RightOutlined />
        </Button>
      </ButtonGroup>
      }
      <Wrapper id={`myDIV${index}`} ref={ref}>
        <div id="container">
          {values.columns.map((item) => {
            return <Card type={values.type} onClick={onClick} value={item} width={values.columns.length === 1 ? "310px" : "280px"}/>;
          })}
        </div>
      </Wrapper>
    </div>
  );
};

const Card =({ value, next, width, type, onClick })=> {
  if (type === "IMAGE"){
    return (
      <div id="content" style={{ width: width, height: width, background: "none" }}>
        <div className="image" style={{ height: "280px", position: "relative", objectFit: "contain" }} >
          <a onClick={()=> onClick(value.actions[0])}>
            <img width="100%" src={value.thumbnailImageUrl} style={{ borderRadius: "19px" }} />
          </a>
          <div className="action-container" style={{ position: "absolute" }}>
            <a className="action-label" onClick={() => onClick(value.actions[0])} >
              {value.actions[0].label}
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div id="content" style={{ width: width }}>
      <div className="image">
        <img width="100%" src={value.thumbnailImageUrl} />
      </div>
      <div className="footer">
        <div className="footer-title">{value.title}</div>
        <div className="footer-body">
          {value?.text}
        </div>
        {
          value.actions.map((item, index) => {
            return (
              <a key={index} className="footer-action" onClick={() => onClick(item)} >
                {item.label}
              </a>
            );
          })
        }
      </div>
    </div>
  );
};


const ButtonGroup = styled.div`
  position: absolute;
  margin-top: 40%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  padding: 10px 7px;
`;
const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  // height:390px;
  overflow:scroll;
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: hidden;

  #container {
  display:inline-flex;
  /* height:390px; */
  overflow:scroll;
  overflow-y: hidden;
  overflow-x: hidden;
}

  #content {
    border-radius: 10px;
    background: white;
    /* height : 390px; */
    width:290px;
    margin-right: 3px;
    margin-left: 3px;
    .footer {
      padding: 0px 10px;
      padding-bottom: 14px;
      .footer-title {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
      }
      .footer-body {
        margin-bottom: 24px;
      }
      .footer-action {
        // border: solid 1px;
        display: flex;
        justify-content: center;
        margin-top: 5px;
      }
    }
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .image-action {
      position: relative;
      width: 290px;
    }
    .action-label {
      background-color :rgba(0, 0, 0, 0.4);
      padding: 10px 13px;
      border-radius: 19px;
      color: white;
    }
    .action-container {
      position: absolute;
      z-index: 100000;
      bottom: 24px;
      display: flex;
      width: 100%;
      justify-content: center;
      color:white;
     }
    }
`;

const Container = styled.div`
  border-radius: 10px;
  background: white;
  // height : 390px;
  width:290px;
  margin-right: 2px;
  .footer {
    padding: 0px 10px;

    .footer-title {
      margin-top: 10px;
      font-weight: bold;
      font-size: 16px;
    }
    .footer-body {
      margin-bottom: 24px;
    }
    .footer-action {
      // border: solid 1px;
      display: flex;
      justify-content: center;
      margin-top: 5px;
    }
  }
  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

import { combineReducers } from "redux";
import general from "./general";
import socket from "./socket";
import register from "./register";
import message from "./message";
import cooperation from "./cooperation";
import dashboard from "./dashboard";
import process from "./process";

export default combineReducers({
  general,
  socket,
  message,
  register,
  cooperation,
  dashboard,
  process
});

import React from "react";
import { useDispatch } from "react-redux";
import Form from "./Form";

export default () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type   : "message.message.data",
      payload: {}
    });
  }, [dispatch]);

  return (
    <Form editable={[]} />
  );
};

/* eslint-disable no-nested-ternary */
import React from "react";
import { Form, FormItem, Select, Input, Checkbox, DatePicker, Radio } from "formik-antd";
import { Form as AntForm, Tag } from "antd";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../hooks";
import { formItemLayout } from "../../../utils";

const { Option } = Select;

const ValueSchema = (translate) => Yup.object({
  tag  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  value: Yup.mixed().required(translate("system.message.required", "Please fill out this field!"))
});

const FormSchema = (translate) => Yup.object({
  targetType: Yup.string().required(),
  tags      : Yup.array().when("targetType", {
    is  : "TAGS",
    then: Yup.array().of(Yup.string()).min(1).required(translate("system.tag.required", "Please select a tag!"))
  }),
  values: Yup.array().of(ValueSchema(translate)).min(0, translate("system.message.required", "Please fill out this field!")).optional()
});

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { editable } = props;
  const { tags } = useSelector(state => state.general);
  const { translate } = useTranslate();
  const [data, setFormData] = React.useState({
    targetType: "NO_AUTOTAG",
    tags      : undefined,
    values    : [],
    ...editable
  });
  const submitRef = React.useRef();

  const onChange = (ids, values) => {
    setFormData({
      ...data,
      tags  : ids,
      values: ids.map(id => {
        let value = values.find(v => v.tag === id);
        let tag = tags.find(tag => tag._id === id);
        let val;

        switch (tag.type) {
          case "CHECKBOX":
            val = false;
            break;
          case "TAG":
            val = tag._id;
            break;
          default:
        }

        return {
          tag  : id,
          value: value !== undefined ? value.value : val
        };
      })
    });
  };

  const onChangeTargetType = (e) => {
    setFormData({
      ...data,
      targetType: e.target.value,
      tags      : undefined,
      values    : [],
    });
  };
  const name = (tag) => {
    switch (tag.auto) {
      case "ages":
        return translate("tag.ages", "age");
      case "subscriptionPeriods":
        return translate("tag.days", "day");
      default:
        return "";
    }
  };
  const renderField = (id, index) => {
    let tag = tags.find(tag => tag._id === id);

    if (!tag) return;

    switch (tag.type) {
      case "SELECT": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`} required>
            <Select name={`values[${index}].value`} placeholder={translate("system.select", "Select")}>
              {tag.values.map((item, index) => <Option key={index} value={item._id}> {item.auto ?
                tag.auto === "ages" ? `${item.name} ${name(tag)}` : tag.auto === "subscriptionPeriods" ? `${item.name} ${name(item)}` :
                  translate(`default.tag.${item.value}`, item.name) : item.name
              }
              </Option>)}
            </Select>
          </FormItem>
        );
      }
      case "NUMBER": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`}>
            <Input type="number" name={`values[${index}].value`} />
          </FormItem>
        );
      }
      case "CHECKBOX": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`}>
            <Checkbox name={`values[${index}].value`} defaultChecked={false} />
          </FormItem>
        );
      }
      case "DATE": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`} required>
            <DatePicker name={`values[${index}].value`} style={{ width: "100%" }} />
          </FormItem>
        );
      }
      default:
    }
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let values = await submitRef.current.submitForm();
      return values && {
        targetType: values.targetType,
        tags      : values.values
      };
    },
    clear() {
      setFormData({
        targetType: "NO_AUTOTAG",
        tags      : undefined,
        values    : [],
        ...(editable && editable[0] === "update" ? editable[1] : {})
      });
    }
  }));

  return (
    <FormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={() => { }}>
        {({ values }) => {
          return (
            <Form {...formItemLayout}>
              <AntForm.Item label={translate("tag.auto.use", "Auto")}>
                <Radio.Group
                  name="targetType"
                  className="target"
                  onChange={onChangeTargetType}
                  defaultValue={
                    values.targetType === "NO_AUTOTAG" ? "NO_AUTOTAG" : "TAGS"
                  }
                >
                  <Radio value="NO_AUTOTAG">
                    <span className="all">
                      {translate("tag.auto.notuse", "No")}
                    </span>
                  </Radio>
                  <Radio value="TAGS" className="tags">
                    <TargetBox>
                      <FormItem name="tags">
                        <SearchBox>
                          <Select
                            disabled={values.targetType === "NO_AUTOTAG"}
                            name="tags"
                            placeholder={translate(
                              "system.search.button",
                              "Search"
                            )}
                            mode="multiple"
                            onChange={(value) =>
                              onChange(value, values.values || [])
                            }
                            style={{ width: "100%" }}
                          >
                            {tags
                              .filter((t) => !t.parent)
                              .map((tag, index) => {
                                return (
                                  <Option key={index} value={tag._id}>
                                    {tag.auto === undefined
                                      ? tag.name
                                      : translate(
                                        `default.tag.${tag.auto}`,
                                        tag.name
                                      )}
                                  </Option>
                                );
                              })}
                          </Select>
                        </SearchBox>
                      </FormItem>
                      {data.deleteds && data.deleteds.length > 0 && (
                        <div style={{ marginTop: 10 }}>
                          <span style={{ marginRight: 6 }}>
                            {translate("tag.deleted", "Deleted tags:")}
                          </span>
                          {data.deleteds.map((deleted) => (
                            <Tag color="red">{deleted.name}</Tag>
                          ))}
                        </div>
                      )}
                    </TargetBox>
                  </Radio>
                </Radio.Group>
              </AntForm.Item>

              {values.tags &&
                values.tags.map((id, index) => {
                  return renderField(id, index);
                })}

              <SubmitForm ref={submitRef} />
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
});
const SearchBox = styled.div``;
const TargetBox = styled.label``;
const FormWrapper = styled.div`
  .target {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-wrapper {
    margin-right: 0!important;
  }
  .tags {
    display: inline-flex;
    margin-top: 10px;
    span.ant-radio + * {
      width: 100%;
      padding-right: 0!important;
    }
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width: 100%;
      }
    }
  }
  .segment-checkbox {
    margin-left :20px;
  }
  .all {
    display: inline-block;
    padding: 10px 0;
  }
  .narrowcast {
    margin-top   : 5px;
    margin-bottom: 15px;
    display: inline-flex;
    span.ant-radio + * {
      width: 100%;
    }
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width: 100%;
      }
    }
  }
`;

import React from "react";
import { Children } from "./";

export default ({ child }) => {
  return (
    <g transform={`translate(${child.x}, ${child.y})`}>
      <Children children={child.children} />
    </g>
  );
};

import React from "react";
import { Form, FormItem, Radio, DatePicker, Input } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { Form as AntForm } from "antd";
import * as Yup from "yup";
import styled from "styled-components";
import { formItemLayout } from "../../../utils";
import { useTranslate } from "../../../hooks";
import moment from "moment";

const { RangePicker } = DatePicker;

const FormSchema = (translate) => Yup.object({
  name       : Yup.string().max(30).required(translate("system.message.required", "Please fill out this field!")),
  chatBarType: Yup.string().required(),
  chatBarText: Yup.string().when("chatBarType", {
    is  : "CUSTOM",
    then: Yup.string().max(14).required(translate("system.message.required", "Please fill out this field!"))
  }),
  behaviorType: Yup.string().required(),
  sendType    : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  schedules   : Yup.array().of(Yup.date().required()).required(translate("system.message.required", "Please fill out this field!"))
});

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { editable } = props;
  const { translate } = useTranslate();
  const { onSubmit } = props;
  const submitRef = React.useRef();
  const [data, setFormData] = React.useState({
    name        : undefined,
    sendType    : "DATE",
    chatBarType : "MENU",
    chatBarText : undefined,
    behaviorType: "SHOWN",
    schedules   : [moment(), moment()],
    ...(editable && (editable[0] === "update" || editable[0] === "reply" || editable[0] === "copy") ? editable[1] : {})
  });

  const onChangeEndDate = (value, values) => {
    if (value === null) {
      setFormData({
        ...values,
        schedules: [value, undefined]
      });
    }
    else {
      setFormData({
        ...values,
        schedules: [value, value]
      });
    }
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    }
  }));

  const disablePastDates = (current) => {
    return current.valueOf() < moment().startOf("day");
  };
  const onChangeBroadcast = (e, values, setValues) => {

    if (e.target.value === "NOW") {
      setValues({
        ...values,
        sendType : e.target.value,
        schedules: [values.schedules[0], values.schedules[1]]
      });
    }
  };

  return (
    <FormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ values, setValues }) => {
          return (
            <Form {...formItemLayout}>
              <div>
                <FormItem style={{ marginTop: 20 }} label={translate("richmenu.name", "Name")} name="name" required>
                  <Input name="name" placeholder="..." />
                </FormItem>
              </div>
              <AntForm.Item label={translate("broadcast", "Broadcast Time")}>
                <Radio.Group name="sendType" onChange={(e) => { onChangeBroadcast(e, values, setValues); }}>
                  <Radio value="NOW">
                    <span className="send-now">
                      {translate("richmenu.sendnow", "Send now ~ End date")}
                      <DatePicker placeholder={translate("broadcast.enddate", "End date")} value={values.schedules && values.schedules[1]} onChange={(e) => onChangeEndDate(e, values)} disabled={values.sendType !== "NOW"} disabledDate={disablePastDates} showTime />
                    </span>
                  </Radio>
                  <Radio value="DATE" className="send-date">
                    <FormItem name="schedules">
                      <RangePicker allowEmpty={true} placeholder={translate("broadcast.startdate", "Send date")} name="schedules" disabled={values.sendType !== "DATE"} disabledDate={disablePastDates} showTime />
                    </FormItem>
                  </Radio>
                </Radio.Group>
              </AntForm.Item>

              <AntForm.Item label={translate("richmenu.chatbar", "Menu bar label")}>
                <Radio.Group name="chatBarType">
                  <Radio value="MENU">
                    <span className="send-now" style={{ width: "100%" }}>
                      {translate("richmenu.menu", "Menu")}
                    </span>
                  </Radio>
                  <Radio value="CUSTOM" className="send-date">
                    <FormItem name="chatBarText">
                      <span style={{ marginRight: 10 }}>{translate("richmenu.custom", "Custom")}</span>
                      <Input style={{ width: 160 }} name="chatBarText" placeholder={translate("richmenu.chatbar", "Enter custom label")} maxLength={14} disabled={values.chatBarType !== "CUSTOM"} />
                    </FormItem>
                  </Radio>
                </Radio.Group>
              </AntForm.Item>

              <AntForm.Item label={translate("richmenu.behavior", "Default behavior")}>
                <Radio.Group name="behaviorType">
                  <Radio value="SHOWN">
                    {translate("richmenu.shown", "Shown")}
                  </Radio>
                  <Radio value="COLLAPSED" style={{ marginTop: 10 }}>
                    {translate("richmenu.collapsed", "Collapsed")}
                  </Radio>
                </Radio.Group>
              </AntForm.Item>

              <SubmitForm ref={submitRef} />
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
});

const FormWrapper = styled.div`
  .send-date {
    display: inline-flex;
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width : 100%;
      }
    }
    .ant-form-item-control-input {
      width   : 381px;
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  .send-now {
    display: inline-block;
    padding: 10px 0;
    width: 100%;
    .ant-picker {
      width: 245px;
      margin-left: 10px;
    }
  }
`;
import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import images from "./libs/images";
import { formatCardNumber, getCardTypeByValue, hasCardNumberReachedMaxLength } from "./libs/formatter";
import { Input } from "antd";

export default ({ name, placeholder, ...restProps }) => {
  const [image, setImage] = React.useState(images.placeholder);

  return (
    <Field name={name}>
      {fieldProps => {
        const {
          field: { value },
          form: { setFieldValue, setFieldError }
        } = fieldProps;

        const onChange = (e) => {
          let value = e.target.value;
          let cardType = getCardTypeByValue(value);

          if (cardType)
            setImage(images[cardType.type] || images.placeholder);

          const valueLength = value.split(" ").join("").length;

          if (hasCardNumberReachedMaxLength(value, valueLength)) {
            return;
          }

          value = formatCardNumber(value);

          setFieldValue(name, value);
        };

        return (
          <Container>
            <Input type="text" value={value ? formatCardNumber(value) : ""} placeholder={placeholder} onChange={onChange} />
            {image && (
              <div className="image">
                <img alt="Card Icon" src={image} />
              </div>
            )}
          </Container>
        );
      }}
    </Field>
  );
};

const Container = styled.div`
  position: relative;
  .image {
    position: absolute;
    right: 7px;
    top: 4px;
    width: 34px;
    height: 22px;
    img {
      width: 100%;
    }
  }
`;
import React from "react";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Children, Dragging } from "./";
import { useTranslate } from "../../../hooks";

export const StepMenu = ({ x, y }) => {
  const { translate } = useTranslate();
  return (
    <Dragging transform={`translate(${x}, ${y})`} menu={{ type: "step", children: [] }}>
      <path stroke="#00a03f" fill="#00a03f" d="m 0,0 c 25,-22 71,-22 96,0 H 144.4799575805664 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      <text fill="#fff" x="19.855995178222656" transform="translate(8, 24)">{translate("shape.step", "Step")}</text>
    </Dragging>
  );
};

export default ({ child, dragging, draggable, transform, index }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { childrenForm, isEditable, children } = useSelector(state => state.process);

  const onChangeJudgement = (e) => {
    e.preventDefault();
    if (!isEditable) return;

    let judgement = children.find(child => child.type === "judgement");
    if (!judgement || !childrenForm[judgement._id] || childrenForm[judgement._id].targets.length === 0) {
      message.warning(translate("shape.judgement.alert", "Judgement configurations are needed!"));
      return;
    }

    dispatch({
      type   : "process.form",
      payload: {
        _id : child._id,
        form: {
          ...(childrenForm[child._id] || {}),
          isJudgement: !childrenForm[child._id]?.isJudgement
        }
      }
    });
  };

  return (
    <Dragging cursor="pointer" transform={transform} child={child} dragging={dragging} draggable={draggable}>
      <path stroke="#00a03f" fill="#00a03f" d="m 0,0 c 25,-22 71,-22 96,0 H 144.4799575805664 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      <text fill="#fff" x="19.855995178222656" transform="translate(8, 24)">{translate("shape.step", "Step")}</text>
      {index[child._id] === 0 && <g transform="translate(4, 10)"></g>}

      <g transform="translate(0, 48)">
        <path stroke="rgb(51, 115, 204)" fill="rgb(76, 151, 255)" d="m 0,4 A 4,4 0 0,1 4,0 H 12 c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2 H 220.8638801574707 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
        <text fill="#fff" transform="translate(8, 30)" style={{ userSelect: "none" }}>{translate("shape.judgement", "Judgement")}</text>
        <g transform="translate(125, 0)" onClick={onChangeJudgement}>
          <path fill={childrenForm[child._id]?.isJudgement ? "#00a03f" : "rgb(76, 151, 255)"} stroke="rgb(51, 115, 204)" strokeWidth={3} d="M16 0h8a16 16 0 010 32h-8a16 16 0 010-32z" transform="translate(48 8)"></path>
          <text x="12" y="18" fill="#fff" dominantBaseline="middle" dy="0" fontWeight="bold" textAnchor="middle" transform="translate(48 8) translate(8)">{childrenForm[child._id]?.isJudgement ? "On" : "Off"}</text>
        </g>
      </g>

      <Dragging transform="translate(0, 48)" dragging={dragging} child={child} draggable={draggable}>
        <path fill="transparent" d="m 0,0 c 25,-22 71,-22 96,0 H 144.4799575805664 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      </Dragging>

      <g transform="translate(0, 96)">
        <Children children={child.children} draggable={draggable} />
      </g>

      {!draggable && (
        <g transform="translate(50, 2)" >
          <path fill="#FFF" stroke="#00a03f" d="M16 0h8a16 16 0 010 32h-8a16 16 0 010-32z" transform="translate(48 8)"></path>
          <g className="blocklyEditableText" cursor="text">
            <text x="12" y="18" dominantBaseline="middle" dy="0" textAnchor="middle" transform="translate(48 8) translate(8)">{index[child._id] + 1}</text>
          </g>
        </g>
      )}
    </Dragging>
  );
};

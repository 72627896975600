import React from "react";
import MyTable from "../../components/MyTable";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import { liff as liffApi, tags as tagsApi } from "../../apis";
import { RowAction } from "../../components";
import moment from "moment";
import { Modal, Button, message, Tag, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Form from "./Form";
import { CopyOutlined, DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { QRCodeImg } from "@cheprasov/react-qrcode";
import QRCode from "qrcode";
import { ManualDesc, ManualLink } from "../../components/Manual";



export default () => {

  const { translate } = useTranslate();
  const { account } = useSelector(state => state.general);
  const dispatch = useDispatch();
  const { checkRole } = usePermission();

  const myTableRef = React.useRef(null);
  const formRef = React.useRef(null);
  const taggedRef = React.useRef();
  const [isSubmit, setIsSubmit] = React.useState(false);
  const qrCodeRef = React.useRef();
  const { tags } = useSelector(state => state.general);
  const [action, setAction] = React.useState([]);
  const [disabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));

  useFetch(tagsApi.select(dispatch))();

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array?.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const generateQr = (record) => {
    setAction(["qr", { ...record }]);
  };

  const onCopy = async (uri) => {
    copy(uri);
    message.success(translate("system.message.success", "Success"));
  };

  const downloadQrCode = () => {

    let qrImage = document.getElementsByClassName("QRCodeImg")[0];
    console.log(document.getElementsByClassName("QRCodeImg"));

    let linkSource = qrImage.currentSrc;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = `${action[1].liffUrl}.png`;
    downloadLink.click();
  };

  const name = (tag) => {
    switch (tag.auto) {
      case "ages":
        return translate("tag.ages", "age");
      case "subscriptionPeriods":
        return translate("tag.days", "day");
      default:
        return "";
    }
  };
  const column = useHeader({
    tableTitles: {
      no         : translate("system.number", "No."),
      title      : translate("liff.title", "Title"),
      date       : translate("liff.createdat", "Created date"),
      tag        : translate("liff.tag", "Tag"),
      size       : translate("liff.view.type", "Size"),
      action     : translate("system.action", "Action"),
      edit       : translate("system.edit", "Edit"),
      liffUrl    : translate("liff.url", "Liff Url"),
      liffId     : translate("liff.id", "Liff ID"),
      remove     : translate("system.delete", "Remove"),
      editOneLine: translate("liff.editonline", "Edit on line"),
      qr         : translate("liff.generateqr", "QR Code generate"),
      qrGenerate : translate("liff.generateqr", "QR Code generate"),
    },
    onCopy,
    generateQr,
    translate,
    disabled,
    tags    : convertArrayToObject(tags, "_id"),
    onAction: async (key, item) => {
      switch (key){
        case "remove": {
          await remove(item);
          break;
        }
        case "edit": {
          let res = await liffApi.get(item._id);
          setAction(["update", {
            ...res,
            ...res.app,
            _id       : res && res._id,
            targetType: "TAGS",
            tags      : res.tags.map(t => t.tag),
            values    : res.tags.map(t => {
              return {
                tag  : t.tag,
                value: t.value
              };
            })
          }]);
          break;
        }
        default: {
          break;
        }
      }
    },
    name
  });

  const remove = async (item) => {
    try {
      await liffApi.remove(item._id);
      message.success(translate("system.success", "Success"));
      myTableRef.current.reload();
    } catch (err) {
      message.error(translate("system.error", "Error"));
    }
  };

  const createApplication = () => {
    setAction(["create"]);
  };

  const onSubmit = async () => {
    let taggedForm = await taggedRef.current.validate();
    let form = await formRef.current.validate();

    if (!taggedForm || !form) return null;
    setIsSubmit(true);

    console.log(taggedForm);
    console.log(form);
    try {
      if (action[0] === "create"){
        await liffApi.create({ ...form, tags: taggedForm.tags });
      } else {
        await liffApi.update({ ...form, tags: taggedForm.tags });
      }
      message.success(translate("system.message.success", "Success"));
      setAction([]);
      myTableRef.current.reload();
      setIsSubmit(false);
    } catch (err){
      setIsSubmit(false);
      if (err.message?.message === "INVALID_LIFF_NAME"){
        message.error(translate("system.message.INVALID_LIFF_NAME", "Invalid LIFF name"));
      }
      else {
        message.error(translate("system.message.error", "Error"));
      }
    }
  };

  return (
    <Container>
      <PageContainer>
        <PageHeader subTitle={<ManualLink link="manual.link.liff"></ManualLink>} title={translate("liff.page.title", "Liff application")} extra={[
          disabled && <Button key="new" onClick={createApplication} type="primary"><PlusOutlined /> {translate("system.create", "Create liff application")}</Button>
        ]}/>
        <ManualDesc translateKey ="manual.desc.liff" />
        <PageContent>
          <Alert className="description " type="warning" message={
            <>
              {translate("liff.manual.text", "About setting you have to set your LIFF application in your LINE account ")}
              <a href={"https://www.notion.so/ec-masters/40369e9a326f4915b72ebaba68bef8e2"} target="_blank">
                {translate("liff.manual", "See more...")}
              </a>
            </>
          } />
          {/*
          <Alert className="description " type="warning" message={
            <>
              {translate("liff.editonline.warning", "You need set Messaging channel as linked channel for your Line Login Channel at Line Console ")}
              <a href={`https://developers.line.biz/console/channel/${account?.lineClientChannelId}/basics`} target="_blank">
                {translate("liff.editonline", "Edit on line")}
              </a>
            </>
          } />
         */}
          <MyTable
            ref={myTableRef}
            loadData={liffApi.list}
            columns={column}/>
          {
            action && (action[0] === "create" || action[0] === "update") && (
              <Modal
                maskClosable ={false}
                confirmLoading={isSubmit}
                title={translate("liff.modal.title", "Liff application")}
                onCancel={() => setAction([])}
                onOk={onSubmit}
                visible={!!(action[0] === "create" || action[0] === "update")}>
                <Form action={action} ref={formRef} taggedRef={taggedRef} />
              </Modal>
            )
          }

          {
            action && action[0] === "qr" && (
              <Modal
                maskClosable ={false}
                confirmLoading={isSubmit}
                title={ translate("liff.qrcode", "Qr code")}
                onCancel={() => setAction([])}
                footer={null}
                width = {340}
                visible={!!(action && action[0])}>
                <div style={{
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center",
                  flexDirection : "column"
                }}>
                  <QRCodeImg value={action[1].liffUrl} />

                  <Button type="default" style={{ marginTop: "20px" }} onClick={downloadQrCode}>
                    {translate("liff.downloadqr", "Download PNG")}
                    <DownloadOutlined/>
                  </Button>
                </div>
              </Modal>
            )
          }

        </PageContent>
      </PageContainer>
    </Container>
  );
};

const useHeader = ({ onAction, tableTitles, onCopy, translate, name, generateQr, disabled }) => {
  const columns = [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title    : tableTitles.title,
    dataIndex: "description",
  }, {
    title : tableTitles.tag,
    render: (record) => {
      return (
        <div>
          {record.tags.map((tag, index) => {
            switch (tag.type) {
              case "NUMBER": {
                return (
                  <Tag
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="cyan">
                    {tag.name}:{tag.value}
                  </Tag>
                );
              }
              case "CHECKBOX": {
                return (
                  <Tag
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="orange">
                    {tag.name}:{tag.value ? "YES" : "NO"}
                  </Tag>
                );
              }
              case "DATE": {
                return (
                  <Tag
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="lime">
                    {tag.auto === undefined ? tag.name : translate("default.tag.registeredDate", tag.name)}:{moment(tag.date).format("YYYY-MM-DD")}
                  </Tag>
                );
              }
              default:
                return (
                  <Tag
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="green">
                    {(() => {
                      if (tag.auto && tag.auto === "ages")
                        return `${tag.name}${name(tag)}`;
                      if (tag.auto && tag.auto === "subscriptionPeriods")
                        return `${tag.name}${name(tag)}`;
                      if (tag.auto)
                        return translate(`default.tag.${tag.value}`, tag.name);

                      return tag.name;
                    })()}
                  </Tag>
                );
            }
          })}
        </div>
      );
    }
  }, {
    title    : tableTitles.liffId,
    dataIndex: "liffId",
  }, {
    title : tableTitles.liffUrl,
    render: (row)=> {
      return (
        <div>
          <Tag >
            <a>{row.liffUrl}</a>
            <Button onClick={()=>onCopy(row.liffUrl)} type="text" className="copy-btn" size="small">
              <CopyOutlined />
            </Button>
          </Tag>
        </div>
      );
    }
  }, {
    title : tableTitles.date,
    render: (record) => {
      return (<div>{moment(record.createdAt).format("YYYY-MM-DD HH:mm")}</div>);
    }
  }, {
    title : tableTitles.qr,
    render: (record) => {
      return (
        <Button onClick={()=>generateQr(record)}>
          {tableTitles.qrGenerate}
        </Button>
      );
    }
  }];
  if (disabled) {
    columns.push({
      title : tableTitles.action,
      width : "20px",
      render: (record) => {
        return (
          <RowAction actions={{
            edit  : tableTitles.edit,
            remove: tableTitles.remove,
          }} onClick={(key) => onAction(key, record)} />
        );
      }
    });
  }
  return columns;
};

const Container = styled.div`
  .copy-btn{
    margin: 1px;
  }
  .description { 
    margin : 10px 0px;
  }
`;

/* eslint-disable no-fallthrough */
import React from "react";
import { Empty, Space, } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { PlusOutlined } from "@ant-design/icons";
import { DatePicker, message } from "antd";
import { debug as debugApi, settingApi, } from "../../apis";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import moment from "moment";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Modal from "antd/lib/modal/Modal";
import SettingForm from "./components/SettingForm";

const { RangePicker } = DatePicker;

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    startDate: null,
    endDate  : null,
  });

  React.useEffect(() => {
    moment.tz.setDefault("Asia/Tokyo");
    return () => {
      moment.tz.setDefault();
    };
  }, []);

  const changeRange = (e) => {
    setFilters({ ...filters, ...filters.filter, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const columns = useHeader({
    tableTitles: {
      no     : translate("system.number", "No."),
      payload: translate("setting.payload", "Payload"),
      type   : translate("setting.type", "Type"),
    },
    history,
    onAction: async (key, item) => {
      switch (key) {
        case "detail": {
          history.push(`/customer/${item._id}`);
          break;
        }
        default:
      }
    },
  });


  const onSubmit = async (data)=> {
    console.log("------create_settings------------>");
    try {
      await settingApi.create(data);
      message.success(translate("system.success", "Successfull"));
      myTableRef.current.reload();
      setOpen(false);
    } catch (err) {
      message.error(translate("crawler.list.failed", "Error occured!"));
    }

  };

  return (
    <PageContainer>
      <PageHeader title={translate("admin.menu.setting", "Settings")} extra={[
        <Button key="inviteButton" type="primary" onClick={() => setOpen(true)} ><PlusOutlined /> {translate("system.add", "Add")} </Button>
      ]} />
      <PageContent>
        <MyTable
          maskClosable={false}
          filters={filters}
          ref={myTableRef}
          rowKey={(record) => record._id}
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          columns={columns}
          loadData={settingApi.list}
        />
      </PageContent>
      <Modal
        onCancel={()=>setOpen(false)}
        title={translate("admin.menu.add", "Create")}
        visible={open}
        footer={null}>
        <SettingForm onSubmit={onSubmit}/>
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ tableTitles }) => {
  return [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    },
    {
      title : tableTitles.type,
      render: (item) => {
        return (<div >{item.type}</div>);
      }
    },
    {
      title : tableTitles.payload,
      render: (item) => {
        return (<div >{JSON.stringify(item.payload)}</div>);
      }
    },
  ];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .item1 {
    width: 70%;
  }
  .item2 {
    width: 30%;
  }
  .button-primary:hover {
    background : ${colors.primary} ;
    border-color : white;
    color        : white;
  }
  .button-primary {
    background    : ${colors.primaryDark} ;
    border-color  : white;
    color         : white;
  }
`;

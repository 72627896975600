import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import LogoImage from "../../assets/logo_green.png";
import { useTranslate } from "../../hooks";

export default () => {
  const { translate } = useTranslate();
  return (
    <LoadingContainer>
      <img alt="Loading..." className="loading-icon" src={LogoImage} />
      <Spin size="large" />
      <span>{translate("system.loading", "Loading")}</span>
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f6f7f8;
  justify-content: center;
  align-items: center;

  .loading-icon {
    margin-bottom: 40px;
    height: 96px;
  }

  span {
    margin-top: 20px;
    color: #009d4b;
  }

  .ant-spin-dot-item {
    background-color: #009d4b;
  }
`;
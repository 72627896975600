import React from "react";
import { Children, Dragging } from ".";
import { useTranslate } from "../../../hooks";

export default ({ child, dragging, draggable, transform, index }) => {
  const { translate } = useTranslate();

  return (
    <Dragging cursor="pointer" transform={transform} child={child} dragging={dragging} draggable={draggable}>
      <path stroke="#00a03f" fill="#00a03f" d="m 0,0 c 25,-22 71,-22 96,0 H 144.4799575805664 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      <text fill="#fff" x="19.855995178222656" transform="translate(8, 24)">{translate("shape.trigger", "Start")}</text>
      {index[child._id] === 0 && <g transform="translate(4, 10)"></g>}

      <Dragging transform="translate(0, 48)" dragging={dragging} child={child} draggable={draggable}>
        <path fill="transparent" d="m 0,0 c 25,-22 71,-22 96,0 H 144.4799575805664 a 4,4 0 0,1 4,4 v 40  a 4,4 0 0,1 -4,4 H 48   c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2 H 4 a 4,4 0 0,1 -4,-4 z" />
      </Dragging>

      <g transform="translate(0, 48)">
        <Children children={child.children} draggable={draggable} />
      </g>

      {/* {!draggable && (
        <g transform="translate(50, 2)" >
          <path fill="#FFF" stroke="#00a03f" d="M16 0h8a16 16 0 010 32h-8a16 16 0 010-32z" transform="translate(48 8)"></path>
          <g className="blocklyEditableText" cursor="text">
            <text x="12" y="18" dominantBaseline="middle" dy="0" textAnchor="middle" transform="translate(48 8) translate(8)">{index[child._id] + 1}</text>
          </g>
        </g>
      )} */}
    </Dragging>
  );
};

import React from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { message, Button } from "antd";
import { useTranslate } from "../../hooks";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Checkbox, Form, FormItem, Input } from "formik-antd";
import moment from "moment";
import EcLogo from "../../assets/ec-logo.png";

const FormSchema = (translate) => Yup.object({
  rakurakuToken: Yup.string().required(translate("system.message.required", "This field is required!")).typeError(translate("system.message.required", "This field is required!")),
});
const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values, resetForm } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    },
    async reload() {
      await resetForm();
    }
  }));

  return null;
});


export default React.forwardRef((props, ref) => {
  const submitRef = React.useRef();
  const { translate } = useTranslate();
  const { account, } = useSelector(state => state.general);
  const [data, setData] = React.useState({
    rakurakuToken: account?.rakurakuToken,
    id           : account?._id
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let values = await submitRef.current.submitForm();
      if (!values) message.error(translate("system.error.required", "Insert necessary fields first!"));

      return values;
    },
    async reload() {
      await submitRef.current.reload();
    }
  }));

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={()=>{}}>
        {({ values }) => {
          return (
            <div>
              <CooperationAlert>
                {translate("system.alert.rakupontoken", "Please insert Rakurakupon issued token below!")}
              </CooperationAlert>
              <Form layout={"vertical"} autocomplete="off">
                <RakutenSection>
                  <FormItem label={translate("rakupon.secret.key", "Rakurakupon service token")} name="rakurakuToken" required>
                    <Input disabled={data?.rakurakuToken} autocomplete="off" name="rakurakuToken" placeholder={translate("rakupon.secret.placeholder", "Rakurakupon service token")} />
                  </FormItem>
                </RakutenSection>
                <SubmitForm ref={submitRef} />
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
});

const CooperationAlert =styled.div`
  padding-left : 15px;
  padding-bottom : 10px;
  margin : 10px 0;
  color : #7C708F;
`;

const RakutenContent = styled.div`
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 20px;
  background: #fcfcfc;
  margin: 0 16px;
`;

const RakutenSection = (props) => {
  return (
    <RakutenContent>
      {props.children}
    </RakutenContent>
  );
};
import React from "react";
import { Form, FormItem, Input, Select, Checkbox } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert, Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import { customer } from "../../apis";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const { Option } = Select;
const FormSchema = (translate) => Yup.object({
  name : Yup.string().required(translate("system.message.required", "required!")),
  email: Yup.string().required(translate("system.message.required", "required!")).email(),
  role : Yup.string().required(translate("system.message.required", "required!")),
});

export default ({ action, onSubmit, onCancel, unblock }) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const { user } = useSelector(state => state.general);
  const [onChangeEvent, setOnChangeEvent] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    filter: {
      orderName: "startDate",
      orderBy  : "desc",
      query    : "",
      startDate: "",
      endDate  : "",
    },
    offset: {
      page : 1,
      limit: 30,
    }
  });

  const [data] = React.useState({
    name     : "",
    email    : "",
    role     : "AGENCY",
    customers: [],
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const [customersList, setCustomers] = React.useState([]);

  const customerInit = async (filter) => {
    let list = await customer.list(filter);
    setCustomers(list.rows);
  };

  const changeEmail = () => {
    history.push({ pathname: "/customer/email/change", state: { email: data.email } });
  };

  React.useEffect(() => {
    console.log(query);
    let timer = setTimeout(() => {
      if (filters.filter.query !== query){
        setFilters({ ...filters, filter: { query: query } });
        customerInit({ ...filters, filter: { query: query } });
      }

    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  React.useEffect(()=> {
    customerInit(filters);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form layout="vertical">
            <Divider>{translate("admin.logincred", "Login credentials")}</Divider>
            <FormItem label={translate("agency.firstname", "Name")} name="name" required>
              <Input name="name" placeholder={translate("", "Name")} />
            </FormItem>
            <FormItem label={translate("agency.email", "Email")} name="email" required>
              <Input
                disabled
                name="email"
                type="email"
                placeholder={translate("agency.email", "Email")}
                suffix={
                  <Button size="small" onClick={changeEmail}>
                    {translate("system.edit", "Edit")}
                  </Button>
                }
              />
            </FormItem>
            {
              data.role === "AGENCY" &&
              <FormItem label={translate("agency.customer", "Customers")} name="customers" required>
                <Select
                  onBlur={()=> customerInit({ ...filters, filter: { query: "" } })}
                  filterOption={false}
                  name="customers"
                  mode="multiple"
                  onChange={(e)=> {
                    setOnChangeEvent(e);
                  }}
                  onSearch={(value)=> {
                    setQuery(value);
                  }}
                  style={{ width: "100%" }}
                  placeholder={translate("agency.customer", "Select customer")}
                  optionLabelProp="label">
                  {customersList.map(item => {
                    return (
                      <Option key={item._id} value={item._id} label={item.companyName}>
                        {item.companyName}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            }
            {user.role ==="ADMIN"&&data.active === true ? <div style={{ color: "green", marginBottom: "10px" }}>{translate("useredit.active", "This account is active")}</div> :
              <div style={{ color: "red", marginBottom: "10px" }}>{translate("useredit.disactive", "This account is inactive")}</div>}
            <FormItem name="active" label="" required>
              <SwitchStyle>
                <Checkbox name="active">{translate("accounts.status", "Enable/Disable this account")}</Checkbox>
              </SwitchStyle>
            </FormItem>
            <AntForm.Item style={{ marginTop: 0 }}>
              <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              {/* <Button type="default" onClick={() => { history.goBack(); }} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button> */}
            </AntForm.Item>

          </Form>
        );
      }}
    </Formik>
  );
};

const SwitchStyle = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color : ${colors.primaryDark};
  }
  .ant-checkbox-inner::hover {
    border-color : yellow;
  }
`;
const Divider = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 500;
  background: #e7e7e7;
  padding: 4px 8px;
  border-radius: 2px;
  color: ${colors.primaryDark};
`;
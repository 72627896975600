import React from "react";
import { regular as regularApi } from "../../apis";
import { Space, message, Empty, Input, Modal, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Form from "./Form";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import moment from "moment";
import { ManualLink } from "../../components/Manual";
import ManualDesc from "../../components/Manual/ManualDesc";

const { confirm } = Modal;

export default ({ select }) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [action, setAction] = React.useState();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: ""
  });
  const regularForm = React.useRef();

  const renderWeek = (day) => {
    switch (day) {
      case 1: {
        return translate("system.constant.monday", "Monday");
      }
      case 2: {
        return translate("system.constant.tuesday", "Tuesday");
      }
      case 3: {
        return translate("system.constant.wednesday", "Wednesday");
      }
      case 4: {
        return translate("system.constant.thursday", "Thursday");
      }
      case 5: {
        return translate("system.constant.friday", "Friday");
      }
      case 6: {
        return translate("system.constant.saturday", "Saturday");
      }
      case 7: {
        return translate("system.constant.sunday", "Sunday");
      }
      default: {
        return day;
      }
    }
  };

  const columns = useHeader({
    history,
    renderWeek,
    select,
    translate,
    tableTitles: {
      no    : translate("system.number", "No."),
      name  : translate("regular.name", "Name"),
      type  : translate("regular.type", "Type"),
      days  : translate("regular.days", "Days"),
      action: translate("system.action", "Action"),
      hours : translate("regular.hours", "Sending hours"),
      edit  : translate("system.edit", "Edit"),
      remove: translate("system.delete", "Remove"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          let res = await regularApi.get(item._id);
          setAction(["update", res]);
          break;
        }
        case "remove": {
          confirm({
            icon   : <ExclamationCircleOutlined />,
            content: <div>
              <div> {translate("regular.update.confirm1", "If you save it, the regular delivery that you set for delivery will be cancelled.")}</div>
              <div>{translate("regular.update.confirm2", "(Messages that have already been scheduled for delivery due to regular delivery will not be canceled.)")}</div>
              <div> {translate("system.confirmation.question", "Are you sure ?")}</div>
            </div>,
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await regularApi.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction(null);
  };

  const onSubmit = async (data, { resetForm, setStatus }) => {
    try {
      if (!action || (action && action[0] === "create")){
        await regularApi.create(data);
        resetForm({});
        setStatus({ success: true });
        message.success(translate("system.message.success", "Successful"));
        myTableRef.current.reload();
        onCancel(true);
      }
      else {
        confirm({
          icon   : <ExclamationCircleOutlined />,
          content: <div>
            <div> {translate("regular.update.confirm1", "If you save it, the regular delivery that you set for delivery will be cancelled.")}</div>
            <div>{translate("regular.update.confirm2", "(Messages that have already been scheduled for delivery due to regular delivery will not be canceled.)")}</div>
            <div> {translate("system.confirmation.question", "Are you sure ?")}</div>
          </div>,
          okText           : translate("system.yes", "Yes"),
          cancelText       : translate("system.no", "No"),
          okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
          cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
          className        : "btn-custom-class",
          async onOk() {
            await regularApi.update(data);
            message.success(translate("system.message.success", "Successful"));
            resetForm({});
            setStatus({ success: true });
            myTableRef.current.reload();
            onCancel(true);
          },
        });
      }
    } catch (err) {
      console.log("Regular update error", err);
      message.error(err.message);
    }
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.regular"></ManualLink>} title={translate("navbar.regular", "Regular settings")} extra={[
        <Button key="createButton" type="primary" onClick={() => setAction(["create", {}])} ><PlusOutlined /> {translate("system.add", "New regular")} </Button>
      ]} />
      <ManualDesc translateKey ="manual.desc.regular" />

      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={regularApi.list}
        />
      </PageContent>
      <Modal
        maskClosable ={false}
        width="750px"
        title={action && action[0] === "create" ? translate("system.add", "Add") : translate("system.edit", "Edit") }
        visible={!!action}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form ref={regularForm} onCancel={onCancel} onSubmit={onSubmit} action={action} />
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, translate, renderWeek, select }) => {
  return [{
    title    : tableTitles.no,
    align    : "center",
    dataIndex: "key",
    width    : "50px",
  }, {
    title : tableTitles.name,
    render: (record) => {
      return `${record.name}`;
    },
  }, {
    title : tableTitles.type,
    render: (record) => {
      return <div>{translate(`system.constant.${record.type}`, record.type)}</div>;
    }
  }, {
    title : tableTitles.days,
    render: (record) => {
      let array = record.days.sort((a, b) => {
        return a - b;
      });
      return (<div >
        {
          record.type === "WEEK" ?
            array.map(day => {
              return <Tag >{renderWeek(day)}</Tag>;
            })
            : array.map(day => {
              if (day === 32){
                return <Tag >{translate("regular.endofmonth", "End")}</Tag>;
              }
              return <Tag>{day}</Tag>;
            })
        }
      </div>);
    }
  },
  {
    title : tableTitles.hours,
    render: (record) => {
      let array = record.hours.sort((a, b) => {
        return moment(a).hour() - moment(b).hour();
      });
      return <div >{array.map(item => (<Tag >{moment(item).format("HH:mm")}</Tag>))}</div>;
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 120,
    fixed : "right",
    align : "center",
    render: (row) => {
      if (select){
        return (
          <Button onClick={()=>select(row)}>{translate("system.select", "Select")}</Button>
        );
      }
      return (
        <RowAction
          actions={{
            edit  : tableTitles.edit,
            remove: tableTitles.remove,
          }}
          onClick={(key) => onAction(key, row)}
        />
      );
    },
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, message, Empty } from "antd";
import React from "react";
import { tax } from "../../apis";
import { MyTable, RowAction } from "../../components";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import colors from "../../colors";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Form from "./Form";

const { confirm } = Modal;

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const [action, setAction] = React.useState();
  const [filters, setFilters] = React.useState({
    startDate: new Date(),
    value    : 1

  });
  const columns = useHeader({
    history,
    tableTitles: {
      no    : translate("system.number", "No."),
      tax   : translate("tax.manage.percent", "Tax"),
      date  : translate("tax.startdate", "Date"),
      action: translate("system.action", "Action"),
      edit  : translate("system.edit", "Edit"),
      remove: translate("system.delete", "Remove"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          console.log(item);
          let res = await tax.get(item._id);
          setAction(["update", res]);
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await tax.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction(null);
  };

  const onSubmit = async (data) => {
    try {
      if (!action || (action && action[0] === "create"))
        await tax.create(data);
      else
        await tax.update(data);
    } catch (err) {
      message.error(err.message);
    }

    message.success(translate("system.message.success", "Success!"));
    myTableRef.current.reload();
    onCancel(true);
  };


  return (
    <PageContainer>
      <PageHeader title={translate("tax.manage.title", "Tax management")} extra={[
        <Button key="createButton" type="primary" onClick={() => setAction(["create", {}])} ><PlusOutlined /> {translate("tax.add", "New tax")} </Button>
      ]} />
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          columns={columns}
          loadData={tax.list}
        />
      </PageContent>

      <Modal
        maskClosable ={false}
        title={action && action[0] === "create" ? translate("tax.add", "New tax") : translate("system.edit", "Edit") }
        visible={!!action}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form onCancel={onCancel} onSubmit={onSubmit} action={action} />
      </Modal>


    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.tax,
    render: (record) => {
      return `${record.value} %`;
    },
  }, {
    title : tableTitles.date,
    render: (record) => {
      return moment(record.startDate).format("YYYY-MM-DD HH:mm");
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (row) => {
      return (
        <RowAction
          actions={{
            edit  : tableTitles.edit,
            remove: tableTitles.remove,
          }}
          onClick={(key) => onAction(key, row)}
        />
      );
    },
  }];
};
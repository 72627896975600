import React from "react";
import { PageHeader, PageContent, PageContainer } from "../../components/Layout";
import { trigger as triggerApi, tags as tagsApi } from "../../apis";
import { Empty, Input, Space, Select, Spin, Badge, Tag } from "antd";
import { SearchOutlined, CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Button } from "../../components/Design";
import { useFetch, useTranslate } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../components/CustomTable";
import styled from "styled-components";
import Target from "../../components/Message/Target";
import moment from "moment";
import { ManualAlert, ManualDesc, ManualLink } from "../../components/Manual";

const { Option } = Select;

export default ()=> {
  const myTableRef = React.useRef();
  const { translate, lang } = useTranslate();
  const dispatch = useDispatch();
  const [query, setQuery] = React.useState("");
  const [eventTypeList, setEventTypeList] = React.useState({});
  const { processEvents, tags } = useSelector(state => state.general);
  const [filters, setFilters] = React.useState({
    query: "",
    hide : false
  });

  useFetch(tagsApi.select(dispatch))();

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const onChangeEvent = (type) => {
    setFilters({
      ...filters,
      eventType: type
    });
  };

  const onChangeIsShow =(type)=> {
    setFilters({
      ...filters,
      hide: type
    });
  };


  React.useEffect(()=> {
    setEventTypeList(convertArrayToObject(processEvents, "code"));
  }, [processEvents]);


  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.triggerreport"></ManualLink>} title={translate("trigger.report", "Trigger report")} />
      <ManualDesc translateKey ="manual.desc.triggerreport" />
      <ManualAlert translateKey={"manual.alert.triggerreport"} />
      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
          <Select name="eventType" style={{ width: 180 }} onChange={onChangeEvent} placeholder= {translate("trigger.action.all", "All trigger")} allowClear>
            {processEvents.map((event, index) => <Option value={event.code} key={index}>{event[`${lang?.toLowerCase()}`]}</Option>)}
          </Select>
          <Select name="eventType" defaultValue={filters.hide} style={{ width: 180 }} onChange={onChangeIsShow} placeholder= {translate("scenario.report.filter.placeholder", "All scenario")}>
            <Option value={false} key={1}>{translate("trigger.action.filter.show", "Show")}</Option>
            <Option value={true} key={2}>{translate("trigger.action.filter.hide", "Hide")}</Option>
            <Option value={null} >{translate("system.all", "All")}</Option>
          </Select>
        </Space>
      </PageFilter>

      <PageContent>
        <TriggerTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          loadData={triggerApi.report}
          bordered
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "10px" }}>{translate("system.number", "No")}</th>
                <th className="ant-table-cell">{translate("trigger.report.name", "Trigger name")}</th>
                <th className="ant-table-cell">{translate("scenario.report.events", "Event type")}</th>
                <th className="ant-table-cell">{translate("trigger.report.message", "Message")}</th>
                <th className="ant-table-cell">{translate("trigger.report.users", "Users")}</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return Object.keys(eventTypeList).length > 0 && (<TriggerItem eventTypeList={eventTypeList} lang={lang} key={index} index={index} row={row} translate={translate} tags={tags} />);
          }} />
      </PageContent>
    </PageContainer>
  );
};

const TriggerItem =({ row, index, translate, eventTypeList, lang, tags })=> {
  const [step, setStep] = React.useState();
  const [action, setAction] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const onSelect = (index) => {
    if (step && row.steps[index]?._id === step?._id)
      return setStep(null);

    setLoading(true);

    setStep(row.steps[index] || {});

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const onAction = (child) => {
    setAction({
      ...action,
      [child._id]: !action[child._id]
    });
  };

  return (
    <>
      <tbody className="ant-table-tbody">
        <tr className="tr-border">
          <td rowSpan={2}>{index + 1}</td>
          <td rowSpan={2}>
            <div style={{ display: "flex" }}>
              {row?.processStatus === "START" && (<Badge status="processing" color="green" />)}
              <div>{row.name}</div>
            </div>
          </td>
          <td rowSpan={2}>{eventTypeList[row.eventType][lang.toLowerCase()]}</td>
          <td>{row.message ||(<span className="none">-</span>)}</td>
          <td>{row.followers || (<span className="none">-</span>)}</td>
        </tr>
        <tr>
          {[0].map(index => (
            <td key={index} colSpan={2} style={{ padding: 0 }}>
              <Button size="small" type="link" style={{ width: "100%" }} onClick={() => onSelect(index)} disabled={!row.steps[index]}>
                {translate("trigger.report.more", "More")} {step && row.steps[index]?._id === step?._id ? <CaretDownOutlined /> : <CaretRightOutlined />}
              </Button>
            </td>
          ))}
        </tr>
      </tbody>

      {loading && (
        <tbody>
          <tr>
            <td colspan="5">
              <Spin spinning={loading}>
                <div style={{ width: "100%", height: 100 }}></div>
              </Spin>
            </td>
          </tr>
        </tbody>
      )}


      {!loading && step && (
        <tbody>
          <tr>
            <td colSpan={5} style={{ padding: 0 }}>
              <Children translate={translate} children={step?.children} action={action} onAction={onAction} tags={tags} />
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};

const renderTags = ({ child, tags, translate }) => {
  const name = (tag) => {
    switch (tag.auto) {
      case "ages":
        return translate("tag.ages", "age");
      case "subscriptionPeriods":
        return translate("tag.days", "day");
      default:
        return "";
    }
  };
  console.log("----->", child,);
  console.log("----->", tags,);
  return (
    <>
      <span style={{ marginRight: 5 }}>{translate("scenario.report.action.type.tag", "Auto tag:")}</span>
      {child.tags.map((item, index) => {
        let tag = tags.find(tag => tag._id === item.tag);
        if (!tag.value){
          tag.values.forEach(val => {
            if (item?.value === val?._id)
              tag = val;
          });
        }
        if (!tag) return (
          <Tag
            key={index}
            style={{ marginBottom: 4 }}
            color="red">
            {translate("tag.deleted", "Deleted")}
          </Tag>
        );

        switch (tag.type) {
          case "NUMBER": {
            return (
              <Tag
                key={index}
                style={{ marginBottom: 4 }}
                color="cyan">
                {tag.name}:{item.value}
              </Tag>
            );
          }
          case "CHECKBOX": {
            return (
              <Tag
                key={index}
                style={{ marginBottom: 4 }}
                color="orange">
                {tag.name}:{tag.checked ? "NO" : "YES"}
              </Tag>
            );
          }
          case "DATE": {
            return (
              <Tag
                key={index}
                style={{ marginBottom: 4 }}
                color="lime">
                {tag.auto === undefined ? tag.name : translate("default.tag.registeredDate", tag.name)}:{moment(tag.date).format("YYYY-MM-DD")}
              </Tag>
            );
          }
          default:
            return (
              <Tag
                key={index}
                style={{ marginBottom: 4 }}
                color="green">
                {(() => {
                  if (tag.auto && tag.auto === "ages")
                    return `${tag.name}${name(tag)}`;
                  if (tag.auto && tag.auto === "subscriptionPeriods")
                    return `${tag.name}${name(tag)}`;
                  if (tag.auto)
                    return translate(`default.tag.${tag.value}`, tag.name);

                  return tag.name;
                })()}
              </Tag>
            );
        }
      })}
    </>
  );
};

const Children = ({ children, onAction, action, translate, tags }) => {
  return (
    <table className="ant-table ant-table-bordered">
      <thead className="ant-table-thead">
        <tr>
          <th className="ant-table-cell" style={{ textAlign: "left" }} colSpan={3}>{translate("trigger.report.detail", "Detail")}</th>
          <th className="ant-table-cell" rowSpan={1}>{translate("trigger.report.parts.users", "Users")}</th>
        </tr>
      </thead>
      <tbody className="ant-table-tbody">
        {(() => {
          const Action = ({ child }) => {
            if (child.type === "tag")
              return renderTags({ child, tags, translate });

            if (child.type === "when")
              return <Button type="link" onClick={() => onAction(child)}>{translate("trigger.report.when", "When")} {action[child._id] ? <CaretDownOutlined /> : <CaretRightOutlined />}</Button>;

            return child.name || child.type;
          };

          return (
            <>
              {children.sort((a, b) => a.index - b.index).map((iterator, index) => {
                if (iterator.type === "when") {
                  return (
                    <>
                      {iterator?.children.sort((a, b) => a.index - b.index).map((child, index) => {
                        if (index === 0) {
                          return (
                            <>
                              <tr key={index}>
                                <td width={480} rowSpan={iterator.children.length * 2} style={{ borderLeft: "1px solid #F0F0F0", borderRight: "1px solid #F0F0F0", minWidth: 480 }}>
                                  <Target editable={["update", { segments: iterator?.targets }]} onlyCustomSegments disabled={true} />
                                </td>
                                <td rowSpan={iterator.children.length * 2} style={{ borderRight: "1px solid #F0F0F0" }}>{translate("trigger.report.then", "Then")}</td>
                                <td style={{ minWidth: 200 }}>{Action({ child })}</td>
                                <td style={{ width: 100 }}>{child.followers || (<span className="none">-</span>)}</td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{ padding: 0 }}>
                                  {action[child._id] && child?.children && child?.children.length > 0 && (
                                    <Children translate={translate} children={[child]} onAction={onAction} action={action} tags={tags} />
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        }
                        return (
                          <>
                            <tr key={index}>
                              <td style={{ minWidth: 200 }}>{Action({ child })}</td>
                              <td style={{ width: 100 }}>{child.followers || (<span className="none">-</span>)}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} style={{ padding: 0 }}>
                                {action[child._id] && child?.children && child?.children.length > 0 && (
                                  <Children translate={translate} children={[child]} onAction={onAction} action={action} tags={tags} />
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  );
                }

                return (
                  <tr key={index}>
                    <td colSpan={3}>{Action({ child: iterator })}</td>
                    <td style={{ width: 100 }}>{iterator.followers || (<span className="none">-</span>)}</td>
                  </tr>
                );
              })}
            </>
          );
        })()}
      </tbody>
    </table>
  );
};


const TriggerTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px!important;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 2px 16px !important;
  }
  
  tbody > tr > td {
    border-bottom: 1px solid #F0F0F0;
    
  }
  .tr-border { 
     td {
      border-top: 0.1px solid #cbcbcb;
    }
  }
  .none {
    color :#BFBFBF;
  }
  .sub-table{
    border: solid 1px #F0F0F0;
    border-radius: 5px;
    table {
      width :500px;
    }
    thead> tr > th {
      padding: 16px!important;
      font-weight : 500;
      background-color :#FBFBFB !important;
      border-bottom: 1px solid #F0F0F0;
      border-right: solid 1px #f2f1f1;
    }
    tbody> tr > td {
      padding: 4px!important;
      /* background-color :#c6bebef5 !important; */
      border-bottom: 1px solid #FBFBFB;
      border-right: solid 1px #f2f1f1;
    }
  }
`;

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

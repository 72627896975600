import request from "../utils/request";

export const list = (data) => request.post("/api/followers", data);
export const tag = (data) => request.post("/api/followers/tag", data);
export const untag = (data) => request.post("/api/followers/untag", data);
export const get = (id) => request.get(`/api/follower/${id}`);
export const setTest = (id) => request.post(`/api/follower/set_test/${id}`);
export const rakutenRemove = (id) => request.post(`/api/follower/rakuten_remove/${id}`);

export const sync = () => request.get("/api/followers/sync");

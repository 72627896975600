import React from "react";
import styled from "styled-components";
import { PlayCircleFilled } from "@ant-design/icons";

export default ({ src }) => {
  return (
    <VideContainer>
      <img src={src} />
      <div className="play"><PlayCircleFilled /></div>
    </VideContainer>
  );
};

const VideContainer = styled.div`
  width: 100%;
  /* height: 100px; */
  /* overflow: hidden; */
  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #9096a5;
  border-radius: 6px;
  cursor: not-allowed!important;
  .play {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: #6f6f6f;
  }
  img {
    display: flex;
    width: 100%;
    border-radius : 5px;
  }
`;
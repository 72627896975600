import React from "react";
import { useParams } from "react-router";
import { imagemap as imagemapApi } from "../../../apis";
import Form from "./Form";
import { Spin } from "antd";

export default () => {
  const params = useParams();
  const [editable, setEditable] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const reload = React.useCallback(async (signal) => {
    setLoading(true);
    try {
      let res = await imagemapApi.get(params.id, { signal });
      let payload = {
        ...res,
        type      : "large",
        template  : res.template,
        baseUrl   : res.baseUrl,
        altText   : res.altText,
        previewUrl: res.previewUrl,
        video     : {
          ...res?.video,
          externalLink: {
            ...res?.video?.externalLink,
            tagType: parseInt(res?.video?.externalLink?.tagType, 10)
          }
        }
      };
      setEditable(["update", payload]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  if (!editable || loading)
    return <Spin spinning={true}/>;

  return <Form editable={editable} />;
};
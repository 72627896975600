import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, children, ...rest }) => {
  const { customer, logged, loading, account, user } = useSelector(state => state.general);

  return (
    <Route
      {...rest}
      render={props => {
        if (props.location.pathname !== "/plan/configuration" && customer && !customer.plan)
          return <Redirect to={{ pathname: "/plan/configuration", state: { from: props.location } }} />;
        if (props.location.pathname !== `/account/${account?._id}` && account && !account?.lineChannelId && account?.ecmaccesskey && user?.role === "ACCOUNT")
          return <Redirect to={{ pathname: `/account/${account._id}`, state: { from: props.location } }} />;
        if (!logged)
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;

        return Component ? <Component {...props} /> : children;
      }}
    />
  );
};

export default PrivateRoute;

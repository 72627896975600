import React from "react";
import { Form, FormItem, Input } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form as AntForm } from "antd";
import { Button } from "../../components/Design";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { useTranslate } from "../../hooks";

const FormSchema = (translate) => Yup.object().shape({
  name: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    name: "",
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem label={translate("businesstype.name", "Name")} name="name" required>
              <Input name="name" placeholder={translate("businesstype.name.placeholder", "Name")} />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button style={{ marginRight: "10px" }} type="primary" htmlType="submit" loading={isSubmitting} >{translate("system.save", "Save")}</Button>
              <Button type="default" onClick={onCancel}>{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};
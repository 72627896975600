import React from "react";
import { PageHeader, PageContent, PageContainer } from "../../components/Layout";
import Form from "./Form";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";


export default ()=> {
  const history = useHistory();
  const { translate } = useTranslate();


  return (
    <PageContainer>
      <PageHeader onBack={()=>history.push("/info/login")} title={translate("", "Add")} />
      <PageContent>
        <Form/>
      </PageContent>
    </PageContainer>
  );
};
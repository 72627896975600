import React from "react";
import { Pagination, message, Spin, Empty } from "antd";
import styled from "styled-components";
import CustomizePagination from "./component/CustomizePagination";
import { item } from "../../apis";
import { useTranslate } from "../../hooks";

export default React.forwardRef(({
  loadData,
  thead,
  tbody,
  scroll,
  filters,
  customPagination,
  dataSource,
  limit: initialLimit = 20,
  page:  initialPage = 1,
  width,
  onTableChange = () => { },
  onResult = () => {},
  pagination,
  className,
  rest
}, ref) => {
  const scrollRef = React.useRef();
  const tableRef = React.useRef();
  const [scrolling, setScrolling] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState(dataSource || []);
  const [total, setTotal] = React.useState(0);
  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(initialPage);
  const [result, setResult] = React.useState({});
  const [limit, setLimit] = React.useState(initialLimit);
  const { translate }= useTranslate();

  const onScroll = () => {
    let classLeft;
    let classRight;
    let scrollLeft = Math.abs(tableRef.current.clientWidth - scrollRef.current.clientWidth);

    if (scrollRef.current.scrollLeft < scrollLeft)
      classRight = "ant-table-ping-right";
    else
      classRight = "";

    if (scrollRef.current.scrollLeft > 0)
      classLeft = "ant-table-ping-left";
    else
      classLeft = "";

    setScrolling(classLeft + " " + classRight);
  };

  const onChange = (pagination, filterData, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    onTableChange(filterData);

    if (sorter && Object.keys(sorter).length && sorter.order) {
      setField(sorter.columnKey);
      setSort(sorter.order === "ascend" ? 1 : -1);
    } else {
      setField(field);
      setSort(sort);
    }
  };

  const reload = React.useCallback(
    async (signal) => {
      setLoading(true);
      try {
        const res = await loadData({
          filter: filters || {
            query: ""
          },
          offset: {
            page : page,
            limit: limit,
          },
        }, { signal });

        if (res.message && res.message.type === "error")
          message.error(res.message.text);

        const data = res.rows.map((item, index) => {
          item.key = ((page - 1) * limit) + (index + 1);
          return item;
        });

        setItems(data);
        setResult(res);
        setTotal(res.count);
        setLoading(false);
        onResult(res);
        onScroll();
      } catch (error) {
        setLoading(false);
      }
    },
    [loadData, limit, page, filters]
  );

  const changePage = (number) => {
    setPage(number);
  };

  const onShowSizeChange=(current, pageSize)=> {
    setPage(current);
    setLimit(pageSize);
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [page, limit, filters]);

  React.useEffect(() => {
    setPage(initialPage);
    setLimit(initialLimit);
    // console.log("initialPage =>", initialPage);
    const abortController = new AbortController();

    // const signal = abortController.signal;
    // reload(signal);
    // onScroll();
    return () => abortController.abort();

  }, [filters]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    getItems() {
      return items;
    },
    getResult() {
      return result;
    },
    setLoading(value){
      setLoading(value);
    }
  }));

  return (
    <Container className={`ant-table-wrapper ${className}`}>
      <Spin spinning={loading}>
        {((()=>{
          if (customPagination){
            return (
              <CustomizePagination
                defaultCurrent = {1}
                listNumber ={items.length}
                initialPage ={page}
                onShowSizeChange={ (current) => { onShowSizeChange(current, limit); }}
              />
            );
          }
        }))()}
        <div className={`ant-table ant-table-bordered ${scrolling} ant-table-scroll-horizontal`}>
          <div className="ant-table-container">
            <div ref={scrollRef} onScroll={onScroll} className="ant-table-content" style={{ overflow: "auto hidden" }}>
              <table ref={tableRef} style={scroll || { width: width??"1000px", minWidth: "100%", tableLayout: "auto" } }>
                {thead({ className: "ant-table-thead" })}
                {items.length > 0?items.map((row, index) => {
                  return tbody(row, index);
                }):(
                  <div style={{ height: "135px" }}>
                    <TableEmpty style={{ border: "none", paddingRight: "2px" }}>
                      <TableEmpty>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={translate("system.nodata", "No data")} />
                      </TableEmpty>
                    </TableEmpty>
                  </div>
                )}
              </table>
            </div>
          </div>
        </div>
        {((() => {
          if (pagination === false)
            return;

          if (customPagination) {
            return (
              <CustomizePagination
                defaultCurrent = {1}
                listNumber ={items.length}
                initialPage ={page}
                onShowSizeChange={ (current) => { onShowSizeChange(current, limit); }}
              />
            );
          }

          return (
            <Pagination { ...{
              className       : "ant-table-pagination ant-table-pagination-right",
              defaultCurrent  : 1,
              showTitle       : true,
              showSizeChanger : true,
              onShowSizeChange: (current, pageSize) => { onShowSizeChange(current, pageSize); },
              onChange        : (pageNumber) => { changePage(pageNumber);},
              total           : total,
              pageSize        : limit,
              current         : page,
            }} />
          );
        }))()}
      </Spin>
    </Container>
  );
});

const TableEmpty =styled.div`
  display:flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  border-bottom: solid 1px #f0f0f0;
  border-right: solid 1px #f0f0f0;
`;
const Container = styled.div`

`;

import React from "react";
import { message, message as notify, Spin } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Button } from "../../components/Design";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import { useHistory, useParams } from "react-router-dom";
import { cooperation as cooperationApi } from "../../apis";
import RakutenOrderForm from "./components/RakutenOrderForm";

export default () => {
  const { type } = useParams();
  const { checkRole } = usePermission();
  const { translate } = useTranslate();
  const [cooperation, fetching] = useFetch(cooperationApi.get, type)(null);
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState();
  const history = useHistory();
  const [disabled, setDisabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));

  const onSave = async () => {
    let data = await formRef.current.validate();

    if (!data) return;

    setLoading(true);

    try {
      await cooperationApi.update({
        ...data,
        type
      });
    } catch (error) {
      message.error(error.message);
    }

    setLoading(false);
    notify.success(translate("system.message.success", "Success"));
    history.push("/rakuten/cooperation");
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("cooperationform.title", "Cooperation form")} extra={[
        disabled&&(<Button key={3} type="primary" loading={loading} onClick={() => onSave()}>{translate("system.save", "Save")}</Button>)
      ]} />
      <PageContent>
        {fetching &&
          <Spin>
            <div></div>
          </Spin>}
        {!fetching && (() => {
          switch (type) {
            case "rakuten_order":
              return (
                <RakutenOrderForm ref={formRef} cooperation={cooperation} />
              );
            default:
          }
        })()}
      </PageContent>
    </PageContainer>
  );
};
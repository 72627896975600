import React from "react";
import { message as notify, Collapse, Row, Col, Modal, Select, Alert } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useSelector, useDispatch } from "react-redux";
import { MessagePreview } from "../../components";
import { Button } from "../../components/Design";
import { Progress, Spin } from "antd";
import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { message as messageApi, tags as tagsApi, auth } from "../../apis";
import { BroadcastForm, MessageForm, Target } from "../../components/Message";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import colors from "../../colors";
import moment from "moment";
import { delay } from "../RichMenuAlias/Form";

const { Panel } = Collapse;
const { confirm } = Modal;
const { Option } = Select;

export default ({ editable }) => {
  const { translate } = useTranslate();
  const { checkRole } = usePermission();
  const history = useHistory();
  const dispatch = useDispatch();
  useFetch(tagsApi.select(dispatch))();
  const { target, collapse } = useSelector(state => state.message);
  const [loading, setLoading] = React.useState(false);
  const notifPositionRef = React.useRef();
  const targetForm = React.useRef();
  const broadcastForm = React.useRef();
  const messageForm = React.useRef();
  const [disabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));

  const onChangeCollapse = (collapse) => {
    dispatch({
      type   : "message.collapse",
      payload: collapse
    });
  };

  const onNext = async (type) => {
    switch (type) {
      case "target": {
        let isValid = await targetForm.current.validate();

        if (isValid) {
          dispatch({
            type   : "message.collapse",
            payload: ["broadcast"]
          });
        } 
        break;
      }
      case "broadcast": {
        const isValid = await broadcastForm.current.validate();

        if (isValid) {
          dispatch({
            type   : "message.collapse",
            payload: ["message"]
          });

          dispatch({
            type   : "message.preview",
            payload: true
          });
        }
        break;
      }
      default:
    }
  };

  const onSubmit = async (type, data) => {
    switch (type) {
      case "target": {
        dispatch({
          type   : "message.target.data",
          payload: data.tags
        });
        break;
      }
      case "broadcast": {
        dispatch({
          type   : "message.broadcast.data",
          payload: {
            sendType: data.sendType,
            sendDate: data.sendDate
          }
        });
        break;
      }
      case "message": {
        dispatch({
          type   : "message.message.data",
          payload: {
            title: data.title,
            text : data.text
          }
        });
        break;
      }
      default:
    }
  };

  const onSave = async (save) => {
    dispatch({
      type   : "message.collapse",
      payload: ["target", "broadcast", "message"]
    });

    let target = await targetForm.current.validate();
    let broadcast = await broadcastForm.current.validate();
    let message = await messageForm.current.validate();

    if (!target || !message){
      if (!message){
        notify.error(translate("system.error.required", "Insert necessary fields first!"));
      }
      else {
        notify.error(translate("message.targetform.settarget", "Please set targets to deliver!"));
      }
      return;
    }
    console.log('0------->target ',JSON.stringify(target));

    setLoading(true);

    if (broadcast.sendType === "NOW" && save === "SEND") {
      confirm({
        icon      : <ExclamationCircleOutlined />,
        okText    : translate("system.yes", "Yes"),
        cancelText: translate("system.no", "No"),
        content   : translate("message.confirm.sendnow", "Message has been set to send immediately, are you sure to send it immediately?"),
        async onOk() {
          if (target.targetType === "TAGS" && target.targets.length === 0) {
            notify.error(translate("system.error.required", "Insert tags before sending this message!"));
            setLoading(false);
            return;
          }
          if (broadcast.sendDate === undefined || moment(broadcast.sendDate).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss")) {
            if (editable[0] === "update") {
              try {
                await messageApi.update({
                  _id        : editable[1]._id,
                  title      : message.title,
                  children   : message.children,
                  saveType   : save,
                  sendType   : broadcast.sendType,
                  sendDate   : broadcast.sendDate,
                  regular    : broadcast.regular,
                  targetType : target.targetType,
                  targets    : target.targetType === "SEGMENTS" ? target.targets : [],
                  demographic: target.targetType === "DEMOGRAPHIC" ? target.targets : null,
                });
                dispatch({
                  type   : "message.message.data",
                  payload: []
                });
                dispatch({
                  type   : "message.preview",
                  payload: false
                });

                history.push("/message/send");
              } catch (error) {
                switch (error.message) {
                  case "ADDITIONAL_FEE_NOTPAID":
                    notify.error(translate("system.additionalfee.error", "Previous payment hasn't been made"));
                    break;
                  case "NO_TARGET":
                    notify.error(translate("system.notarget", "No target has been set"));
                    break;
                  case "TARGET_ERROR_DEMOGRAPHIC":
                    notify.error(translate("system.target_error_demographic", "Target demographic must be more than"));
                    break;
                  default:
                    if (error.error !== "JoiValidationError") notify.error(error.message);
                    break;
                }
              }

              await auth.me()(dispatch);
            } else {
              try {
                await messageApi.create({
                  title      : message.title,
                  children   : message.children,
                  saveType   : save,
                  sendType   : broadcast.sendType,
                  regular    : broadcast.regular,
                  sendDate   : broadcast.sendDate,
                  targetType : target.targetType,
                  targets    : target.targetType === "SEGMENTS" ? target.targets : [],
                  demographic: target.targetType === "DEMOGRAPHIC" ? target.targets : null,
                });

                dispatch({
                  type   : "message.message.data",
                  payload: []
                });
                dispatch({
                  type   : "message.preview",
                  payload: false
                });

                history.push("/message/send");
              } catch (error) {
                if (error.message === "ADDITIONAL_FEE_NOTPAID") {
                  setLoading(false);
                  return notify.error(translate("system.additionalfee.error", "Previous payment hasn't been made"));
                }
                if (error.message === "NO_TARGET") {
                  setLoading(false);
                  return notify.error(translate("system.notarget", "No target has been set"));
                }
                if (error.message === "TARGET_ERROR_DEMOGRAPHIC") {
                  setLoading(false);
                  return notify.error(translate("system.target_error_demographic", "Target demographic must be more than"));
                }
                if (error.message === "DEFAULT_CARD_NOTFOUND") {
                  setLoading(false);
                  notify.error(translate("system.alert.addcard", "This month's free usage has been expired. Change your plan or register your credit card"));
                }
                setLoading(false);
              }

              await auth.me()(dispatch);
              setLoading(false);
            }
          } else {
            notify.error(translate("system.error.schedule.time", "Set schedule time in the future"));
          }
        },
      });
    } else {
      if (target.targetType === "TAGS" && target.targets.length === 0) {
        notify.error(translate("system.error.required", "Insert tags before sending this message!"));
        setLoading(false);
        return;
      }

      if (broadcast.sendDate === undefined || moment(broadcast.sendDate).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss")) {
        if (editable[0] === "update") {
          try {
            await messageApi.update({
              _id        : editable[1]._id,
              title      : message.title,
              children   : message.children,
              regular    : broadcast.regular,
              saveType   : save,
              sendType   : broadcast.sendType,
              sendDate   : broadcast.sendDate,
              targetType : target.targetType,
              targets    : target.targetType === "SEGMENTS" ? target.targets : [],
              demographic: target.targetType === "DEMOGRAPHIC" ? target.targets : null,
            });
            dispatch({
              type   : "message.message.data",
              payload: []
            });

            history.push("/message/send");
          } catch (error) {
            switch (error.message) {
              case "ADDITIONAL_FEE_NOTPAID":
                notify.error(translate("system.additionalfee.error", "Previous payment hasn't been made"));
                break;
              case "NO_TARGET":
                notify.error(translate("system.notarget", "No target has been set"));
                break;
              case "TARGET_ERROR_DEMOGRAPHIC":
                notify.error(translate("system.target_error_demographic", "Target demographic must be more than"));
                break;
              default:
                if (error.error !== "JoiValidationError") notify.error(error.message);
                break;
            }
          }

          await auth.me()(dispatch);
        } else {
          try {
            await messageApi.create({
              title      : message.title,
              children   : message.children,
              regular    : broadcast.regular,
              saveType   : save,
              sendType   : broadcast.sendType,
              sendDate   : broadcast.sendDate,
              targetType : target.targetType,
              targets    : target.targetType === "SEGMENTS" ? target.targets : [],
              demographic: target.targetType === "DEMOGRAPHIC" ? target.targets : null,
            });
            dispatch({
              type   : "message.message.data",
              payload: []
            });

            history.push("/message/send");
          } catch (error) {
            if (error.message === "ADDITIONAL_FEE_NOTPAID") {
              setLoading(false);
              return notify.error(translate("system.additionalfee.error", "Previous payment hasn't been made"));
            }
            if (error.message === "NO_TARGET") {
              setLoading(false);
              return notify.error(translate("system.notarget", "No target has been set"));
            }
            if (error.message === "TARGET_ERROR_DEMOGRAPHIC") {
              setLoading(false);
              return notify.error(translate("system.target_error_demographic", "Target demographic must be more than"));
            }
            if (error.message === "DEFAULT_CARD_NOTFOUND") {
              setLoading(false);
              notify.error(translate("system.alert.addcard", "This month's free usage has been expired. Change your plan or register your credit card"));
            }
            setLoading(false);
          }
          await auth.me()(dispatch);
          setLoading(false);
        }
      } else {
        notify.error(translate("system.error.schedule.time", "Set schedule time in the future"));
      }
    }

    setLoading(false);
  };
  const ontestSend = async () => {

    dispatch({
      type   : "message.collapse",
      payload: ["target", "broadcast", "message"]
    });

    await delay(300);

    let message = await messageForm.current.validate();
    if (!message){
      notify.error(translate("system.error.required", "Insert necessary fields first!"));
      return;
    }
    confirm({
      icon      : <div/>,
      content: <TestSendModal >
        <div className="header">{translate('testsend.modal.field', "Would you like to send test message?")}</div>
       <div className="body">
       <Select
          className="select"
          placeholder={translate("testsend.modal.placeholder", "Set test")}
          defaultValue={'FIRST'}
          onChange={(value) => {
            notifPositionRef.current = value            
          }
          }>
          {["FIRST", "LAST"]?.map((item, index) => (
            <Option key={index} value={item}>
              {translate(`system.constant.${item}`, item)}
            </Option>
          ))}
        </Select>
       </div>
      </TestSendModal>,
      async onOk() {
        try {
          await messageApi.testSend({
            title   : message.title,
            children: message.children,
            notifPosition : notifPositionRef.current ? notifPositionRef.current :  "FIRST"
          });
          notify.success(translate("system.message.success", "Success"));
        } catch (error) {
          if (error.message === "FOLLOWER_NOTFOUND"){
            notify.error(translate("message.test.send.notfound", "Test user not found!"));
          } else {
            notify.error(translate("system.error.required", "Insert necessary fields first!"));
          }
        }
      },
    });
  };
  const onRemove = () => {
    confirm({
      icon   : <ExclamationCircleOutlined />,
      content: translate("system.confirmation.question", "Are you sure ?"),
      async onOk() {
        await messageApi.remove(editable[1]._id);
        notify.success(translate("system.message.success", "Success"));
        history.push("/message/send");
      },
    });
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={editable[0] === "update" ? translate("message.edit", "Edit message") : translate("message.create", "New Message")} extra={[
        editable[0] === "update" &&disabled&& <Button key={1} loading={loading} type="danger" onClick={onRemove}>{translate("system.delete", "Remove")}</Button>,
        <Button key={2} type="default" loading={loading} onClick={() => onSave("DRAFT")}>{translate("message.save.draft", "Save draft")}</Button>,
        disabled && <Button key={3} type="primary" loading={loading} onClick={() => onSave("SEND")}>{translate("message.setting.end", "Send")}</Button>
      ]} />

      <PageContent>
        <MessageContainer>
          <Collapse bordered={false} ghost={true} activeKey={collapse} onChange={onChangeCollapse}>
            <Panel key="target" header={translate("message.targetestimation", "Target Estimation")}>
              <Row gutter={[8, 8]}>
                <Col span={14}>
                  <Target ref={targetForm} type="message" editable={editable} onSubmit={(data) => onSubmit("target", data)} />
                </Col>
                <Col span={10}>
                  <Estimate>
                    <div className="header">{translate("message.targetestimation", "Target Estimation")}</div>
                    <div className="body">
                      <Spin spinning={target.calculating} indicator={<LoadingOutlined spin />}>
                        <Progress type="circle" percent={target.percent} />
                      </Spin>
                      <div className="friends">{translate("message.approx", "Approx")} {parseInt(target.friends)} {translate("message.people", "friends")}</div>
                    </div>
                    {target.type ==="DEMOGRAPHIC" && target.friends < 50 && (
                      <div className="alert-box">
                        <ExclamationCircleOutlined />{translate("narrowcast.error", "The number of recipients must be at leats 50 to use this function")}
                      </div>
                    )}
                  </Estimate>
                </Col>
              </Row>

              <Row gutter={[8, 8]} style={{ marginTop: 40 }}>
                <Col span={14}>
                  <Row>
                    <Col span={14} offset={8}>
                      <Button onClick={() => onNext("target")} type="default">{translate("system.next", "Next")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
            <Panel key="broadcast" header={translate("broadcast", "Broadcast Time")}>
              <Row gutter={[8, 8]}>
                <Col span={14}>
                  <BroadcastForm ref={broadcastForm} editable={editable} onSubmit={(data) => onSubmit("broadcast", data)} />
                  <Row style={{ marginTop: 40 }}>
                    <Col span={14} offset={8}>
                      <Button type="default" onClick={() => onNext("broadcast")}>{translate("system.next", "Next")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
            <Panel key="message" header={translate("message.subtitle", "Message")}>
              <Row gutter={[8, 8]}>
                <Col span={14}>
                  <MessageForm ref={messageForm} editable={editable} onSubmit={(data) => onSubmit("message", data)} />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </MessageContainer>

        <MessagePreview type="fixed" ontestSend={ontestSend} />
      </PageContent>
    </PageContainer>
  );
};

export const Estimate = styled.div`
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: auto;
  .header { 
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 8px 10px;
    font-size: 15px;
  }
  .body { 
    padding: 20px 10px;
    text-align: center;
    .friends {
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .alert-box {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
    margin-left: 5px;
    border: solid 1px;
    border-radius: 3px;
    margin-bottom: 5px;
    background: #fcf8e3;
    color: #8a6d3b;
    border-color: #faebcc;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: ${colors.primaryDark};
  }
  .ant-progress-circle .ant-progress-text {
    color : ${colors.primary}
  }
`;
const MessageContainer = styled.div`
  .ant-collapse-header {
    border-bottom: 1px solid #d9d9d9;
    font-size: 16px;
    padding-left: 20px!important;
    .anticon {
      left: 0!important;
    }
  }
  .ant-collapse-content-box {
    padding: 20px 0!important;
  }
`;
const TestSendModal = styled.div`
  margin: auto;
  .header { 
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 8px 10px;
    font-size: 15px;
  }
  .body { 
    padding: 6px 3px;
    text-align: center;
    /* border: 1px solid #ddd; */
  }
  .select {
    width: 100%;
  }
`;
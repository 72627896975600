import React from "react";
import { useDispatch } from "react-redux";
import Form from "./Form";

export default () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type   : "general.imagemapSelect",
      payload: {
        type      : "large",
        template  : "A",
        baseUrl   : undefined,
        baseSize  : {},
        altText   : undefined,
        previewUrl: undefined,
        mapType   : "image",
        actions   : [],
      }
    });

    dispatch({
      type   : "general.imagemapSelect.templates",
      payload: ["A", "B", "C", "D"]
    });
  }, []);

  return <Form editable={["create"]} />;
};
import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../../components/Layout";
import parse from "html-react-parser";

import { useHistory, useParams } from "react-router-dom";
import { news } from "../../../apis";
import { Spin } from "antd";
import { PanelBody, PanelContainer, PanelContent } from "../../../components/Design/Panel";
import moment from "moment";

export default ()=>{
  const history = useHistory();
  const [data, setFormData] = React.useState();
  const params = useParams();

  const reload = React.useCallback(async (signal) => {
    let res = await news.get(params.id, { signal });

    setFormData(res);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);


    return () => abortController.abort();
  }, [reload]);

  return (
    <div>
      <PageContainer>
        <PageHeader subTitle={data && moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")} onBack={() => history.goBack()} title={data && data.title} />
        <PageContent>
          <Spin spinning={!data}>
            <PanelContainer style={{ display: "block" }}>
              {data && parse(data.body)}
            </PanelContainer>
          </Spin>
        </PageContent>
      </PageContainer>
    </div>
  );
};
import { PageHeader, PageContainer, PageContent } from "../../components/Layout";
import React from "react";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { Button, message } from "antd";
import Form from "./component/Form";
import { carousel as carouselApi } from "../../apis";
import { useHistory } from "react-router";

export default ()=> {
  const { translate } = useTranslate();
  const history = useHistory();
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  const onSubmit =async ()=> {
    let value = await formRef.current.validate();
    if (!value) return;

    setLoading(true);
    try {
      await carouselApi.create(value);
      message.success(translate("system.message.success", "Success!"));
      history.push("/carousel");
      setLoading(false);
    }
    catch (err){
      message.error(err.message);
      setLoading(false);
    }
  };


  return (
    <Container>
      <PageContainer>
        <PageHeader onBack={()=>history.goBack()} title={translate("carousel.create", "Card type message create")} extra={[
          <Button loading={loading} onClick={onSubmit} type="primary">{translate("system.save", "Save")}</Button>
        ]}/>
        <PageContent>
          <Form ref={formRef}/>
        </PageContent>
      </PageContainer>
    </Container>
  );
};

const Container = styled.div`
`;


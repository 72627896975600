import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../hooks";
import { Empty, Modal, message, Badge as AntBadge, Alert } from "antd";
import { Button } from "../../components/Design";
import { BranchesOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import CardForm from "./components/CardForm";
import { card as cardApi } from "../../apis";
import { MyTable, RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import colors from "../../colors";
import { setDefault } from "../../apis/richmenu";
import styled from "styled-components";
const { confirm } = Modal;

export default () => {
  const Multipayment = window.Multipayment;
  const { shopId, gmoUrl } = useSelector(state => state.general);
  const { translate } = useTranslate();
  const [visible, isVisible] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const myTableRef = React.useRef();
  const history = useHistory();
  const [load, setLoad] = React.useState(false);
  const [action, setAction] = React.useState(["new", {}]);
  const [cardInfo, setCardInfo] = useState({
    // holderName: "ENKHJARGAL.L",
    // cardNumber: "4100000000005000",
    // expiryDate: "202009",
    // cvv       : "489"
  });
  const [filters, setFilters] = React.useState({
    query: ""
  });

  const columns = useHeader({
    history,
    tableTitles: {
      no        : translate("system.number", "No."),
      action    : translate("system.action", "Action"),
      remove    : translate("system.delete", "Remove"),
      brand     : translate("payment.brand", "Brand"),
      cardnumber: translate("card.number", "Card number"),
      holdername: translate("card.holder", "Holder name"),
      expiry    : translate("card.expire", "Expiry Date"),
      setDefault: translate("card.set.default", "Set default"),
      defualt   : translate("card.default", "Default"),
      edit      : translate("system.edit", "Edit")

    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          setAction(["edit", { ...item }]);
          isVisible(true);
          break;
        }
        case "setDefault": {
          await cardApi.setChange(item._id);
          message.success(translate("system.message.success", "Successful"));
          myTableRef.current.reload();
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              try {
                await cardApi.remove(item._id);
                message.success(translate("system.message.success", "Successful"));
                myTableRef.current.reload();
              } catch (error) {

                if (error.message === "DEFAULT_CARD_DENIED")
                  message.error(translate("card.alert.undeletable", "Default card can not be deleted"));
              }
            },
          });
          break;
        }
        default:
      }
    },
  });

  const handleNewButton = () => {
    setAction(["new", {}]);
    isVisible(true);
  };

  const handleModalCancel = () => {
    isVisible(false);
  };

  const handleModalSubmit = async (formData, resetForm) => {
    setLoad(true);
    setError(null);
    let date = [];
    date[0] = formData.expiryDate.replaceAll("/", "").slice(0, 2);
    date[1] = formData.expiryDate.replaceAll("/", "").slice(2, 4);

    Multipayment.init(shopId);
    Multipayment.getToken({
      cardno      : formData.cardNumber.replaceAll(" ", ""),
      securitycode: formData.cvv,
      expire      : `${date[1]}${date[0]}`,
      holdername  : formData.holderName,
      token       : ""
    }, async (res) => {
      if (res.resultCode === "000") {

        try {

          if (action[0] === "new") {
            try {
              const response = await cardApi.add({
                holder: formData.holderName,
                cardno: res.tokenObject.maskedCardNo,
                token : res.tokenObject.token
              });
              setData([...data, response]);
              isVisible(false);
              message.success(translate("system.message.success", "Success!"));
              myTableRef.current.reload();
              setLoad(false);
              resetForm({});
            }
            catch (err) {
              setError(err);
              setLoad(false);
            }
          }
          if (action[0] === "edit") {
            const response = await cardApi.update({
              _id   : action[1]._id,
              holder: formData.holderName,
              cardno: res.tokenObject.maskedCardNo,
              token : res.tokenObject.token
            });
            setData([...data, response]);
            isVisible(false);
            message.success(translate("system.message.success", "Success!"));
            myTableRef.current.reload();
            setLoad(false);
          }
        } catch (err) {
          setError(err.message.message);
        }
      } else {
        setError(res);
        setLoad(false);
      }
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = gmoUrl;
    document.body.appendChild(script);
  }, [gmoUrl]);

  return (
    <PageContainer>
      <PageHeader title={translate("payment.method", "Payment methods")} extra={[
        <Button key="createButton" type="primary" onClick={handleNewButton}><PlusOutlined /> {translate("card.add", "New payment method")}</Button>
      ]} />

      <PageContent>


        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={cardApi.list}
        />


        <Modal
          maskClosable={false}
          width={400}
          title={translate("card.save", "Add new method")}
          visible={visible}
          onCancel={handleModalCancel}
          footer={false}>
          <CardForm onSubmit={handleModalSubmit} error={error} load={load} />
        </Modal>
      </PageContent>
    </PageContainer>
  );
};

// cardNo: "************5000"
// cardSeq: 0
// createdAt: "2020-11-29T06:49:33.674Z"
// customer: "5fc1fbe5206a226f1050a402"
// expire: 202009
// forward: "2a99662"
// holder: "ENKHJARGAL.L"
// status: true

const useHeader = ({ onAction, tableTitles }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px"
  }, {
    title    : tableTitles.holdername,
    dataIndex: "holder",
  }, {
    // slice(4, 6);
    title : tableTitles.cardnumber,
    render: (record) => {
      return <span>**** **** **** {record.cardNo&&record.cardNo.slice(12)}</span>;
    }
  }, {
    // slice(4, 6);
    title : tableTitles.defualt,
    render: (record) => (
      <div style={{ marginLeft: "15px" }}>{record.isDefault === true ?
        <Badge color="green" /> : <Badge color="red" />}</div>
    ),
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (row) => {
      if (row.isDefault === true) {
        return <RowAction actions={{
          remove: tableTitles.remove,
          edit  : tableTitles.edit
        }} onClick={(key) => onAction(key, row)} />;
      }

      return <RowAction actions={{
        remove    : tableTitles.remove,
        edit      : tableTitles.edit,
        setDefault: tableTitles.setDefault
      }} onClick={(key) => onAction(key, row)} />;

    }
  }];
};

const Badge = styled(AntBadge)`
  .ant-badge-status-dot {
    width :10px;
    height:10px;
  }
`;
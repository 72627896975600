import React, { memo } from "react";
import { DualAxes } from "@ant-design/plots";

const SalesGraph = memo(({ gms, gms_conversion }) => {
  const config = {
    data           : [gms, gms_conversion],
    xField         : "date",
    yField         : ["gms", "gms_conversion"],
    geometryOptions: [
      {
        geometry        : "column",
        isStack         : true,
        seriesField     : "type",
        columnWidthRatio: 0.4,
        color: ['#aeda85', '#8ed6e6', '#7c9ed1', '#9a9a9a'],
      },
      {
        geometry   : "line",
        seriesField: "type",
        color: ['#53a843', '#2333d7', '#305eb6', '#555555'],
      },
    ],
  };

  return <DualAxes {...config} />;
});

SalesGraph.displayName = "SalesGraph";

export { SalesGraph };

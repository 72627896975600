import React from "react";
import { tags } from "../../apis";
import { Space, message, Empty, Input, Modal, Alert } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Form from "./Form";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { ManualAlert, ManualDesc, ManualLink } from "../../components/Manual";

const { confirm } = Modal;

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [action, setAction] = React.useState();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: ""
  });

  const columns = useHeader({
    history,
    tableTitles: {
      no    : translate("system.number", "No."),
      name  : translate("tag.manage.name", "Name"),
      type  : translate("tag.manage.type", "Type"),
      action: translate("system.action", "Action"),
      edit  : translate("tag.manage.change", "Change name"),
      remove: translate("system.delete", "Remove"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          let res = await tags.get(item._id);
          setAction(["update", res]);
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await tags.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction(null);
  };

  const onSubmit = async (data, { resetForm, setStatus }) => {
    try {
      if (!action || (action && action[0] === "create"))
        await tags.create(data);
      else
        await tags.update(data);
    } catch (err) {
      message.error(err.message);
    }
    resetForm({});
    setStatus({ success: true });
    message.success(translate("system.message.success", "Success!"));
    myTableRef.current.reload();
    onCancel(true);
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.tags"></ManualLink>} title={translate("tag.manage", "Tag management")} extra={[
        <Button key="createButton" type="primary" onClick={() => setAction(["create", {}])} ><PlusOutlined /> {translate("system.add", "New tag")} </Button>
      ]} />
      <ManualDesc translateKey ="manual.desc.tags" />
      <ManualAlert translateKey={"manual.alert.tags"} />
      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={tags.list}
        />
      </PageContent>
      <Modal
        maskClosable ={false}
        title={action && action[0] === "create" ? translate("system.add", "Add") : translate("system.edit", "Edit") }
        visible={!!action}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form onCancel={onCancel} onSubmit={onSubmit} action={action} />
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles }) => {
  const { lang, tagTypes } = useSelector((state) => state.general);
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.name,
    render: (record) => {
      return `${record.name}`;
    },
  }, {
    title : tableTitles.type,
    render: (record) => {
      let type = tagTypes.find((tag) => tag.code === record.type);
      if (type)
        return type[lang.toLowerCase()] || record.type;
      return record.type;
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (row) => {
      return (
        <RowAction
          actions={{
            edit  : tableTitles.edit,
            remove: tableTitles.remove,
          }}
          onClick={(key) => onAction(key, row)}
        />
      );
    },
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  gap: 15px;
  justify-content: space-between; 
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

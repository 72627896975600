import React from "react";
import { useHistory } from "react-router-dom";
import { Empty, List, Spin, Tag } from "antd";
import { useTranslate } from "../../../hooks";
import Modal from "antd/lib/modal/Modal";
import AnnouncementsDetail from "./NewsDetail";
import styled from "styled-components";
import colors from "../../../colors";
import { news } from "../../../apis";
import moment from "moment";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const [visible, setVisible] = React.useState(false);
  const [newsList, setNewsList] = React.useState(null);


  const handleCancel = e => { setVisible(false); };

  const getList = async () => {
    setNewsList(
      await news.list({
        filter: {
          orderName: "startDate",
          orderBy  : "desc",
          query    : "",
          startDate: "",
          endDate  : "",
        },
        offset: {
          page : 1,
          limit: 30,
        },
      }));
  };

  const nextPage = (item) => { history.push(`/news/detail/${item._id}`); };
  React.useEffect(() => { getList(); }, []);

  return (
    <NewsWrapper>
      <div className="news-container">
        <Card >
          {newsList === null ?
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin />
            </div> :
            <List
              locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
              itemLayout="horizontal"
              dataSource={newsList.rows}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<div className="avatarWrapper">
                      <div>{moment(item.createdAt).format("MM")} <span style={{ fontSize: "8px" }}>{translate("dashboard.mm", "MM")} </span></div>
                      <div className="line"></div>
                      <div style={{ paddingRight: "4px" }}>{moment(item.createdAt).format("DD")}<span style={{ fontSize: "8px", marginLeft: "4px" }}>{translate("dashboard.day", "DD")}</span></div>

                    </div>}
                    title={<a onClick={() => nextPage(item)}>{item.title}</a>}
                    description={item.desc}
                  />
                  <span>
                    {(() => {
                      if (item.priority === true) {
                        return (
                          <div>
                            <Tag className="tags" color="green">{translate("news.priority", "Priority")}</Tag>
                          </div>
                        );
                      }
                    })()}
                    <a onClick={() => nextPage(item)} style={{ color: colors.primaryDark }}>{translate("dashboard.seemore", "See more")}</a>
                    <div>{moment(item.createdAt).format("MM/DD HH:MM")}</div>
                  </span>
                </List.Item>
              )}
            />
          }
        </Card>
      </div>

      <Modal
        title={translate("announcements.detail.title", "Announcements detail title")}
        visible={visible}
        footer={null}
        onCancel={handleCancel}>
        <AnnouncementsDetail />
      </Modal>
    </NewsWrapper>
  );
};

const TagWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
`;

const NewsWrapper = styled.div`
    width: 100%;  
    .news-container{
      width: 100%;
      padding: 20px;
      height: 400px;
      overflow: scroll;
      overflow-x: hidden;
    }
    .news-container::-webkit-scrollbar {
      width: 5px;
    }
    .news-container::-webkit-scrollbar-thumb {
      background: ${colors.primaryDark}; 
      border-radius: 10px;
    }
    `;
const Card = styled.div`
 .ant-spin-dot-item {
    background : ${colors.primaryDark};
  }
  .avatarWrapper {
      padding : 1px;
      border: solid 1px;
      width: 48px;
      height: 48px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: ${colors.primaryDark};
      color: white;
      font-size: 15px;
      font-weight: bold;
  }
  .line {
    border-bottom : solid 1px;
    width         : 100%;
  }
`;


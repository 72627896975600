import request from "../utils/request";

export const login = (data) => request.post("/api/auth/login", data);
export const logout = () => request.get("/api/auth/logout");
export const check = (token) => request.get(`/api/auth/verify/${token}`);
export const ecm_verify = (token) => request.get(`/api/auth/ecm_verify/${token}`);
export const checkStaff = (token) => request.get(`/api/auth/staff/verify/${token}`);
export const verifyStaff = (token, data) => request.post(`/api/auth/staff/verify/${token}`, data);

export const verify = (token, data) => request.post(`/api/auth/verify/${token}`, data);

export const signup = (data) => request.post("/api/auth/signup", data);
export const changePassword = (data) => request.post(`/api/auth/password/verify/${data.verifyToken}`, data);
export const forgetPassword = (data) => request.post("/api/auth/forgot", data);

export const register = (data) => request.post("/api/auth/register", data);

export const me = () => async dispatch => {
  let res;

  try {
    res = await request.get("/api/auth/me", null, null, false);

    dispatch({
      type   : "auth.me",
      payload: res
    });
  } catch (err) {
    dispatch({
      type   : "auth.change",
      payload: {
        logged: false
      }
    });
  }

  return res;
};

import request from "../utils/request";

export const list = (data) => request.get("/api/user", data);
export const update = (data) => request.put(`/api/user/${data._id}`, data);
export const changePassword = (data) => request.post("/api/user/change/password", data);
export const get = (id) => request.get(`/api/user/${id}`);
export const profile = (data) => request.put("/api/user/profile", data);
export const changeEmail = (data) => request.post("/api/user/change/email", data);

export const disable = (data) => request.post("api/user/disable", data);

export const unblock = (id) => request.post(`/api/user/${id}/unblock/password`);


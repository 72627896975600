import React from "react";

class Draggable extends React.Component {
  state = {
    isDragging: false,
    originalX : 0,
    originalY : 0,
    translateX: this.props.x,
    translateY: this.props.y
  };

  componentDidMount() {
    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("mouseup", this.handleMouseUp);

    if (this.props.onDragStart) {
      this.props.onDragStart();
    }

    this.setState({
      originalX : this.props.x,
      originalY : this.props.y,
      isDragging: true
    });
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("mouseup", this.handleMouseUp);
  }

  handleMouseMove = (e) => {
    const { onDrag } = this.props;
    const { clientX, clientY } = e;

    this.setState(() => ({
      translateX: clientX - 30 - 24,
      translateY: clientY - 108 + (this.props?.child?.type === "step" ? 12 : 0)
    }), () => {
      if (onDrag) {
        onDrag({
          translateX: this.state.translateX,
          translateY: this.state.translateY
        });
      }
    });
  };

  handleMouseUp = () => {
    this.setState({
      originalX : 0,
      originalY : 0,
      isDragging: false
    }, () => {
      if (this.props.onDragEnd) {
        this.props.onDragEnd({
          x: this.state.translateX - 252,
          y: this.state.translateY
        });
      }
    });
  };

  render() {
    const { children } = this.props;
    const { translateX, translateY } = this.state;

    return (
      <g transform={`translate(${translateX + this.props.scroll.left}, ${translateY + this.props.scroll.top})`}>{children}</g>
    );
  }
}

export default Draggable;
import React from "react";
import { rakuten } from "../../apis";
import { Space, Empty, message } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { ReconciliationFilled, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Modal } from "antd";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import Avatar from "antd/lib/avatar/avatar";
const { confirm } = Modal;


export default (props) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query      : "",
    cooperation: props.location?.state?.id
  });
  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  const columns = useHeader({
    history,
    tableTitles: {
      no                : translate("system.number", "No."),
      avatar            : translate("rakuten.user", "User name"),
      isRakutenDate     : translate("rakuten.follower.rakuten.register.date", "Rakuten register date"),
      shopReviewCount   : translate("rakuten.follower.review.count.shop", "Shop Reviews"),
      avgShopScore      : translate("rakuten.follower.review.avg.shop", "Avg Score"),
      productReviewCount: translate("rakuten.follower.review.count.product", "Product reviews"),
      avgProductScore   : translate("rakuten.follower.review.avg.product", "Avg product score"),
      totalAmount       : translate("rakuten.follower.total.amount", "Total Amount"),
      averageAmount     : translate("rakuten.follower.average.amount", "Average Amount"),
      totalPurchases    : translate("rakuten.follower.total.purchase", "Total Purchase"),
      couponUsages      : translate("rakuten.follower.coupon.usage", "Coupon Usage"),
      orderno           : translate("rakuten.ordernumber", "Order No."),
      orderdate         : translate("rakuten.date", "Order date"),
      price             : translate("rakuten.price", "Order amount"),
      orders            : translate("rakuten.order.title", "Orders"),
      reviews           : translate("rakuten.review", "Reviews"),
      calculate         : translate("rakuten.re.calculate", "Re-calculate"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "reviews" : {
          history.push(`/products/rakuten/${item._id}/reviews`);
          break;
        }
        case "orders": {
          history.push({
            pathname: `/products/rakuten/${item._id}/orders`,
            state   : { id: item._id },
          });
          break;
        }
        case "calculate": {
          await rakuten.calculate(item._id);
          message.success(translate("system.message.success", "Successful"));
          myTableRef.current.reload();
          break;
        }
        default:
      }
    },
  });

  return (
    <PageContainer>
      <PageHeader title={translate("rakuten.follower.title", "Rakuten user")} />
      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={rakuten.followers}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px"
  }, {
    title : tableTitles.avatar,
    render: (record) => {
      return (
        <div>
          <Avatar src={<img src={record.avatar} />} /><span style={{ marginLeft: "20px" }}>{record.name}</span>
        </div>
      );
    },
  },
  {
    title : tableTitles.isRakutenDate,
    render: (record) => {
      if (record.isRakutenDate){
        return (
          <div>
            {record?.isRakutenDate}
          </div>
        );
      }
      return (<div>-</div>);
    },
  }, {
    title    : tableTitles.shopReviewCount,
    dataIndex: "shopReviewCount"
  }, {
    title    : tableTitles.avgShopScore,
    dataIndex: "avgShopScore"
  }, {
    title    : tableTitles.productReviewCount,
    dataIndex: "productReviewCount"
  }, {
    title    : tableTitles.avgProductScore,
    dataIndex: "avgProductScore"
  }, {
    title    : tableTitles.totalAmount,
    dataIndex: "totalAmount"
  }, {
    title    : tableTitles.totalPurchases,
    dataIndex: "totalPurchases"
  }, {
    title    : tableTitles.averageAmount,
    dataIndex: "averageAmount"
  }, {
    title    : tableTitles.couponUsages,
    dataIndex: "couponUsages"
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (follower) => {
      return <RowAction actions={{
        orders   : tableTitles.orders,
        reviews  : tableTitles.reviews,
        calculate: tableTitles.calculate
      }} onClick={(key) => onAction(key, follower)} />;
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

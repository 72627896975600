/* eslint-disable radix */
import React from "react";
import { Button, message } from "antd";
import Form from "./Form";
import { tagForm as tagFormApi } from "../../apis";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";

export default ()=> {
  const { translate } = useTranslate();
  const history = useHistory();
  const formRef = React.useRef();
  const [load, setLoad] = React.useState(false);

  const onSubmit =async ()=> {
    setLoad(true);
    let form = await formRef.current.validate();
    if (!form.isValid || !form.targets) {
      setLoad(false);
      message.error(`${translate("system.error.required", "Insert necessary fields first!")}`);
      return null;
    }
    try {
      await tagFormApi.create({
        name   : form.isValid.name,
        targets: form.targets.targets
      });
      message.success(translate("system.message.success", "Success!"));
    } catch (err) {
      setLoad(false);
      message.error(err.message);
    }
    setLoad(false);
    history.goBack();
  };


  return (
    <PageContainer>
      <PageHeader onBack={()=>history.goBack()} title={translate("target.form.create", "Target form")} extra={[
        <Button loading={load} onClick={onSubmit} key={3} type="primary">{translate("system.save", "Save")}</Button>
      ]}/>
      <PageContent>
        <Form ref={formRef} action={["create", {}]}/>
      </PageContent>
    </PageContainer>
  );
};


// Core modules
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Card, Empty, message as notify, Spin } from "antd";
import { EditOutlined, LinkOutlined, UserOutlined } from "@ant-design/icons";
import { cooperation as cooperationApi } from "../../apis";
import styled from "styled-components";
import colors from "../../colors";
import copy from "copy-to-clipboard";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import { ManualDesc, ManualLink } from "../../components/Manual";

const { Meta } = Card;

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const { checkRole } = usePermission();
  const [cooperations, fetching] = useFetch(cooperationApi.list)([]);
  const { cooperationTypes, lang } = useSelector(state => state.general);

  const onCopy = async (link) => {
    if (link) {
      copy(link);
      notify.success(translate("system.message.success", "Success"));
    }
  };

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.cooperation"></ManualLink>} title= {translate("cooperationform.title", "Cooperation form")} />
      <ManualDesc translateKey ="manual.desc.cooperation" />

      <PageContent>
        <Spin spinning={fetching || (cooperationTypes.length === 0)}>
          {
            ((()=>{
              if (cooperationTypes.length !== 0) {
                return cooperations.map((cooperation, index) => {
                  let Icon = require("@ant-design/icons")[cooperation.icon];
                  return (
                    <Cooperation
                      key={index}
                      style={{ width: 300 }}
                      actions={[
                        <UserOutlined
                          key="user"
                          onClick={() =>
                            history.push({
                              pathname: "/products/rakuten/followers",
                              state   : { id: cooperation && cooperation._id },
                            })
                          }
                        />,
                        // <LinkOutlined
                        //   key="link"
                        //   onClick={() => onCopy(cooperation.link)}
                        // />,
                        <EditOutlined
                          key="edit"
                          onClick={() =>
                            history.push(
                              `/rakuten/cooperation/${cooperation.code.toLowerCase()}`
                            )
                          }
                        />,
                      ]}
                    >
                      <Meta
                        avatar={<Icon size={22} />}
                        title={
                          Object.values(cooperationTypes).find(
                            (language) => language[lang?.toLowerCase()]
                          )[lang.toLowerCase()].name
                        }
                        description={
                          Object.values(cooperationTypes).find(
                            (language) => language[lang.toLowerCase()]
                          )[lang.toLowerCase()].description
                        }
                      />
                    </Cooperation>
                  );
                });
              }
              return (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />);

            }))()
          }

        </Spin>
      </PageContent>
    </PageContainer>
  );
};

const Cooperation = styled(Card)`
  .anticon {
    font-size: 22px;
    color: ${colors.primaryDark};
  }
  .ant-card-meta-title {
    font-size: 17px;
    color: ${colors.primaryDark};
  }
  .ant-card-meta-description {
    color: #9a9a9a;
  }
`;
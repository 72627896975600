import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Form, Input, FormItem, Select } from "formik-antd";
import { auth } from "../../../apis";
import { Formik } from "formik";
import { Button, Form as AntForm, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useTranslate } from "../../../hooks";

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

export default ({ nextPage }) => {
  const { translate } = useTranslate();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { register } = useSelector(state => state);

  const onSubmit = async (data) => {
    register.password = data.password;

    let res = await auth.verify(match.params.token, register);

    dispatch({
      type   : "auth.change",
      payload: res
    });
    message.success(translate("system.message.success", "Successful"));
    location.push("/");
  };

  const [data, setFormData] = React.useState({
    password       : undefined,
    confirmPassword: undefined,
  });

  const FormSchema = Yup.object().shape({
    password: Yup.string()
      .required(translate("system.message.required", "This field is required!"))
      .matches(lowercaseRegex, translate("system.password.lowercase", "Your password must have at least one lowercase"))
      .matches(uppercaseRegex, translate("system.password.uppercase", "Your password must have at least one uppercase"))
      .matches(numericRegex, translate("system.password.number", "Your password must have at least one number digit"))
      .min(8, translate("system.password.character", "Your password must have at least 8 characters")),
    confirmPassword: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
  });

  return (
    <StepOneWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        validate={(data) => {
          if (data.password && data.password && data.password !== data.confirmPassword)
            return {
              confirmPassword: translate("system.password.nomatch", "Password doesn't match!")
            };
        }}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical" style={{ width: "100%" }}>
            <FormItem label={<label className="form-input-title">{translate("useredit.password.input", "Password")}</label>} name="password" required>
              <Input className="form-input" name="password" type="password" placeholder={translate("useredit.password.input", "Password")} />
            </FormItem>
            <FormItem label={<label className="form-input-title">{translate("useredit.password.confirm", "Confirm password")}</label>} name="confirmPassword" required>
              <Input className="form-input" name="confirmPassword" type="password" placeholder={translate("useredit.password.confirm", "Confirm password")} />
            </FormItem>
            <AntForm.Item>
              <Button className="form-button" htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.confirm", "Confirm")}</Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>

    </StepOneWrapper>
  );
};

const StepOneWrapper = styled.div`
     display : flex;
     justify-content : center;
`;
/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
import React from "react";
import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import { Button, Tabs, Radio as AntRadio, message, Spin, Tooltip, Alert } from "antd";
import styled from "styled-components";
import { useTranslate, useFetch } from "../../../hooks";
import { Form, FormItem, Input, Select, Checkbox, Radio } from "formik-antd";
import { formItemLayout } from "../../../utils";
import Card from "./Card";
import { cooperation as cooperationApi } from "../../../apis";
import { useSelector } from "react-redux";
import confirm from "antd/lib/modal/confirm";
import colors from "../../../colors";
import { CloseOutlined, ExclamationCircleOutlined, LeftOutlined, QuestionCircleOutlined, RightOutlined, SnippetsOutlined } from "@ant-design/icons";
import Upload from "./Upload";
import SelectList from "../../Coupon/SelectList";
import ActionField from "./ActionField";
import { TaggedUrl, URL_REGEX } from "../../Imagemap/image/components/Form";
import { useHistory } from "react-router-dom";

const { TabPane } = Tabs;

const ValueScheamProduct =(translate, additional, setKey, type) => Yup.object({
  thumbnailImageUrl: Yup.string().required(translate("system.message.required", "Required!")),
  title            : Yup.string().required(translate("system.message.required", "Required!")),
  text             : Yup.string().when([], ()=> {
    if (type === "IMAGE") return Yup.string();
    return Yup.string().required(translate("system.message.required", "Required!")).max(60, "Max length 60");
  }),
  actions: Yup.array().of(Yup.object({
    type: Yup.string().when(["isCheck"], (isCheck, data, shcm)=> {
      if (isCheck === true){
        return Yup.string().required(translate("system.message.required", "Required!"));
      }
      return Yup.string();
    }),
    linkUri: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).when("type", {
      is  : "uri",
      then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
    }),
    text: Yup.string().when("type", {
      is  : "message",
      then: Yup.string().max(250, translate("system.message.max250", "Max 250 character!")).required(translate("system.message.required", "Required!"))
    }),
    cooperation: Yup.string().when("type", {
      is  : "cooperation",
      then: Yup.string().required(translate("system.message.required", "Required!"))
    }),
    couponCode: Yup.string().when("type", {
      is  : "coupon",
      then: Yup.string().required(translate("system.message.required", "Required!"))
    }),
    lineBasicId: Yup.string().when("type", {
      is  : "share",
      then: Yup.string().required(translate("system.message.required", "Required!"))
    }),
    isTagged : Yup.boolean().nullable(),
    taggedUrl: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!"))
      .when("type", {
        is  : "uri",
        then: Yup.string().when("isTagged", {
          is  : true,
          then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
        }).nullable()
      }).when("type", {
        is  : "coupon",
        then: Yup.string().when("isTagged", {
          is  : true,
          then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
        }).nullable()
      }).nullable(),
    label: Yup.string().when("isCheck", (isCheck)=> {
      if (isCheck === true){
        return Yup.string().max(20, translate("carousel.action.label.product", "Insert within 20 characters!")).required(translate("system.message.required", "Required!"));
      }
      return Yup.string();
    }),
  })).required(translate("system.message.required", "Required!")),
});

const ValueScheamImage =(translate, additional, setKey, type)=> Yup.object({
  thumbnailImageUrl: Yup.string().required(translate("system.message.required", "Required!")),
  title            : Yup.string().required(translate("system.message.required", "Required!")),
  actions          : Yup.array().of(ActionFormSchema(translate, type)).required(translate("system.message.required", "Required!"))
});

const ActionFormSchema = (translate, type) => Yup.object({
  type: Yup.string().when(["isCheck"], (isCheck, data, shcm)=> {
    if (isCheck === true){
      return Yup.string().required(translate("system.message.required", "Required!"));
    }
    return Yup.string();
  }),
  linkUri: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).when("type", {
    is  : "uri",
    then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
  }),
  text: Yup.string().when("type", {
    is  : "message",
    then: Yup.string().max(250, translate("system.message.max250", "Max 250 character!")).required(translate("system.message.required", "Required!"))
  }),
  cooperation: Yup.string().when("type", {
    is  : "cooperation",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  lineBasicId: Yup.string().when("type", {
    is  : "share",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  couponCode: Yup.string().when("type", {
    is  : "coupon",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  isTagged : Yup.boolean().nullable(),
  taggedUrl: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!"))
    .when("type", {
      is  : "uri",
      then: Yup.string().when("isTagged", {
        is  : true,
        then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
      }).nullable()
    }).when("type", {
      is  : "coupon",
      then: Yup.string().when("isTagged", {
        is  : true,
        then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
      }).nullable()
    }).nullable(),
  label: Yup.string().when("isCheck", (isCheck)=> {
    if (isCheck === true && type === "IMAGE"){
      return Yup.string().max(12, translate("carousel.action.label.image", "Insert within 12 characters!")).required(translate("system.message.required", "Required!"));
    }
    return Yup.string();
  }),
});

const FormSchema =(translate, additional, setKey) => Yup.object({
  isCheck: Yup.bool(),
  type   : Yup.string().required(translate("system.message.required", "This field is required!")),
  altText: Yup.string().max(300, translate("carousel.title.overlimit", "Title exceeded character limit")).required(translate("system.message.required", "This field is required!")),
  columns: Yup.array().when(["type"], (type)=> {
    if (type === "IMAGE"){
      return Yup.array().of(ValueScheamImage(translate, additional, setKey, type)).min(0, "0 < 1", type);
    }
    return Yup.array().of(ValueScheamProduct(translate, additional, setKey, type));
  })
});


const SubmitForm = React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      let errors;
      await submitForm();

      errors = await validateForm();
      if (errors && Object.keys(errors).length > 0){
        message.error(
          translate("system.error.required", "Insert necessary fields first!")
        );
        return false;
      }
      return values;

    },
  }));

  return null;
});


export const ACTION_INIT ={
  isCheck        : false,
  type           : undefined,
  label          : undefined,
  data           : undefined,
  linkUri        : undefined,
  text           : undefined,
  cooperation    : undefined,
  couponCode     : undefined,
  isTagged       : false,
  taggedUrl      : undefined,
  tagType        : 0,
  externalBrowser: false
};

export const CAROUSEL_INIT = {
  imageLoading        : false,
  thumbnailImageUrl   : undefined,
  imageBackgroundColor: undefined,
  title               : undefined,
  text                : undefined,
  actions             : [{ ...ACTION_INIT, isCheck: true }, { ...ACTION_INIT }]
};

const { TextArea } = Input;
const { Option } = Select;

export default React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const [loading, setLoading]= React.useState(false);
  const { editable } = props;
  const submitRef = React.useRef();
  const [key, setKey] = React.useState(0);
  const [cooperations] = useFetch(cooperationApi.list)([]);
  const { imageMapActions, account } = useSelector(state => state.general);
  const history = useHistory();
  const [data] = React.useState({
    altText: undefined,
    type   : undefined,
    isCheck: (editable&&editable[0] === "update" ? editable[1]?.columns[0]?.actions[1]?.isCheck : false),
    columns: [{
      ...CAROUSEL_INIT,
    }],
    ...(editable&&editable[0] === "update"?editable[1]:{})
  });

  const onClearTaggedUrl = (setFieldValue, index, e) => {
    if (!e){
      setFieldValue(`columns[${key}].actions[${index}].isTagged`, false);
      setFieldValue(`columns[${key}].actions[${index}].tagType`, 0);
      setFieldValue(`columns[${key}].actions[${index}].taggedUrl`, undefined);
    }
    if (!e?.target?.value || e?.target?.value === ""){
      setFieldValue(`columns[${key}].actions[${index}].isTagged`, false);
      setFieldValue(`columns[${key}].actions[${index}].tagType`, 0);
      setFieldValue(`columns[${key}].actions[${index}].taggedUrl`, undefined);
    }
    if (e?.target?.value && (e?.target?.value.includes("mailto:") || e?.target?.value.includes("tel:"))){
      setFieldValue(`columns[${key}].actions[${index}].externalBrowser`, false);
    }
  };
  const externalBrowserDisabled = (value) => {
    if (!value) return false;
    return value.includes("mailto:") || value.includes("tel:");
  };
  const renderField = (value, key, poss, values, setFieldValue, setFieldTouched) => {
    console.log("values =>", values);
    console.log("values =>", setFieldTouched);
    switch (value.type) {
      case "message":
        return (
          <>
            <FormItem key={"1"} label={translate("richmenu.action.message", "Message")} name={`columns[${key}].actions[${poss}].text`} required>
              <TextArea showCount name={`columns[${key}].actions[${poss}].text`} placeholder="..." />
            </FormItem>
          </>
        );
      case "uri":
        return (
          <>
            <FormItem key={"2"} label={translate("richmenu.action.linkUri", "Link")} name={`columns[${key}].actions[${poss}].linkUri`} onChange={(e) => onClearTaggedUrl(setFieldValue, poss, e)} required>
              <Input name={`columns[${key}].actions[${poss}].linkUri`} placeholder="https://... , tel:0000000000, mailto:example@example.com" />
            </FormItem>

            <CustomFormItem key={"3"} name={`columns[${key}].actions[${poss}].externalBrowser`} required>
              <Checkbox disabled={externalBrowserDisabled(values.actions[poss]?.linkUri)} name={`columns[${key}].actions[${poss}].externalBrowser`}>{translate("imagemap.external.browser", "Open in external browser")}</Checkbox>
              {(values?.actions[poss]?.linkUri?.length > 900 && values?.actions[poss].externalBrowser) &&
                <Alert
                  style={{ marginTop: 5 }} type="warning" message={
                    <div>
                      {translate("actions.weblink.length.alert", "Link length maybe a too long to be processed by Line!")}
                    </div>
                  }
                />}
            </CustomFormItem>

            <CustomFormItem name={`columns[${key}].actions[${poss}].isTagged`} required>
              <Checkbox onChange={() => {
                setFieldValue(`columns[${key}].actions[${poss}].tagType`, 0);
                setFieldValue(`columns[${key}].actions[${poss}].taggedUrl`, undefined);
              }} disabled={!values.actions[poss].linkUri || !account?.ecmid} name={`columns[${key}].actions[${poss}].isTagged`}>{
                  <div>
                    { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                    <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
            </CustomFormItem>
            <TaggedUrl values={values} index={poss} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} tagType={`columns[${key}].actions[${poss}].tagType`} taggedUrl={`columns[${key}].actions[${poss}].taggedUrl`}/>

            {/* </div> */}

          </>
        );
      case "cooperation":
        return (
          <>
            <FormItem key={"4"} label={translate("cooperationform.title", "Cooperation form")} name={`columns[${key}].actions[${poss}].cooperation`} required>
              <Select name={`columns[${key}].actions[${poss}].cooperation`} placeholder={translate("system.select", "Select")} style={{ width: "100%" }}>
                {cooperations.filter(c => c.template).map((item, index2) => {
                  return item.link ? <Option key={index2} value={item._id}>{translate(`cooperation.${item.code}`, item.name)}</Option>
                    : <Option disabled key={index2}>{translate("rakuten.noform", "No rakuten form")}</Option>;
                })}
              </Select>
            </FormItem>
          </>
        );
      case "coupon":
        return (
          <>
            <div>
              <FormItem key={"5"} name={`columns[${key}].actions[${poss}].couponCode`}>
                <SelectList onChange={(e) => onClearTaggedUrl(setFieldValue, poss, e)} name={`columns[${key}].actions[${poss}].couponCode`} urlField={`columns[${key}].actions[${poss}].linkUri`} />
              </FormItem>
              <FormItem key={"6"} style = {{ marginTop: "10px" }} name={`columns[${key}].actions[${poss}].additionalParameter`} label={translate("imagemap.additional.parameter", "Additional Parameter")} >
                <Input name={`columns[${key}].actions[${poss}].additionalParameter`} placeholder={translate("imagemap.additional.parameter.placeholder", "Additional Parameter")} />
              </FormItem>
              <CustomFormItem key={"7"} style = {{ marginTop: "10px" }} name={`columns[${key}].actions[${poss}].externalBrowser`} required>
                <Checkbox name={`columns[${key}].actions[${poss}].externalBrowser`}>{translate("richmenu.external.browser", "Open in external browser")}</Checkbox>
              </CustomFormItem>
              <CustomFormItem name={`columns[${key}].actions[${poss}].isTagged`} required>
                <Checkbox onChange={() => {
                  setFieldValue(`columns[${key}].actions[${poss}].tagType`, 0);
                  setFieldValue(`columns[${key}].actions[${poss}].taggedUrl`, undefined);
                }} disabled={!values.actions[poss].couponCode || !account?.ecmid} name={`columns[${key}].actions[${poss}].isTagged`}>{
                    <div>
                      { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                      <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                      </Tooltip>
                    </div>
                  }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
              </CustomFormItem>
              <TaggedUrl values={values} index={poss} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} tagType={`columns[${key}].actions[${poss}].tagType`} taggedUrl={`columns[${key}].actions[${poss}].taggedUrl`}/>
            </div>
          </>
        );
      case "share":
        return (
          <>
            <FormItem label={translate("richmenu.action.share", "Share")} name={`columns[${key}].actions[${poss}].lineBasicId`} required>
              <Input name={`columns[${key}].actions[${poss}].lineBasicId`} placeholder={`${account?.lineBasicId}`} />
            </FormItem>
          </>
        );
      default:
        return null;
    }
  };


  const discard =(value, setValues, setFieldTouched)=> {
    confirm({
      icon             : <ExclamationCircleOutlined />,
      content          : translate("carousel.modal.body", "Changes that have not been saved will be discarded. Do you want to discard it?"),
      okText           : translate("system.yes", "Yes"),
      cancelText       : translate("system.no", "No"),
      okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
      className        : "btn-custom-class",
      async onOk() {
        if (value === "IMAGE"){
          setValues({
            altText: undefined,
            type   : value,
            columns: [{
              ...CAROUSEL_INIT,
              actions: [{ ...ACTION_INIT, isCheck: true }]
            }]
          });
          setLoading(true);
          setKey(0);
          setFieldTouched("altText", false);

        } else {
          setValues({
            altText: undefined,
            type   : value,
            columns: [{
              ...CAROUSEL_INIT,
              actions: [{ ...ACTION_INIT, isCheck: true }, { ...ACTION_INIT }]
            }]
          });
          setLoading(true);
          setKey(0);
          setFieldTouched("altText", false);
          setFieldTouched(`columns[${key}]`, false);
        }
      },
    });
  };

  const onSort = (action, values, setFieldValue) => {
    let colmns = [...values.columns];
    let index = key;
    if (action === "up"){
      index = parseInt(key) +1;
      colmns[index] = values.columns[key];
      colmns[key] = values.columns[index];
      setFieldValue("columns", [...colmns]);
      setKey(index);
    }
    if (action === "down"){
      index = parseInt(key) -1;
      colmns[index] = values.columns[key];
      colmns[key] = values.columns[index];
      setFieldValue("columns", [...colmns]);
      setKey(index);
    }
  };

  const onEdit =(targetKey, action, setFieldValue, values)=> {
    if (action === "add"){
      if (values.columns.length < 9){
        let add = [...values.columns, { ...CAROUSEL_INIT, actions: [{ ...ACTION_INIT, isCheck: true }, { ...ACTION_INIT, isCheck: values.isCheck }] }];
        setFieldValue("columns", add);
        setKey(add.length-1);
      }
    }
    if (action === "remove"){
      let colmns = values.columns;
      colmns.splice(targetKey, 1);
      setFieldValue("columns", [...colmns]);
      setKey(colmns.length-1);
      if (colmns.length === 0){
        setFieldValue("type", undefined);
      }
    }
  };

  const copy =(values, setFieldValue)=> {
    let index = values.columns.length -1;
    index +=1;
    if (index < 9){
      setKey(index);
      setFieldValue(`columns[${index}]`, values.columns[key]);
    }
  };

  const renderCarousel =(values, setFieldValue, setValues, setFieldTouched)=> {
    if (values.type === "PRODUCT"){
      return (
        <div key={"render-product"}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "250px" }}>
              <Card type={values.type} item={values.columns[key]}/>
            </div>
            <div style={{ width: "700px" }}>
              <FormItem key={"100"} label={translate("carousel.card.title", "Card title")} name={`columns[${key}].title`} required>
                <Input type={values.type} name={`columns[${key}].title`} placeholder={translate("carousel.card.title.placeholder", "Insert card title")} maxLength={20} />
              </FormItem>
              <FormItem key={"11"} label={translate("carousel.card.image", "Card image")} name={`columns[${key}].thumbnailImageUrl`} required>
                <Upload action="/api/carousel/upload" imageLoading={`columns[${key}].imageLoading`} name={`columns[${key}].thumbnailImageUrl`} placeholder={translate("carousel.card.image.placeholde", "Upload card image")}/>
              </FormItem>
              <FormItem key={"10"} label={translate("carousel.card.desc", "Description")} name={`columns[${key}].text`} required>
                <Input.TextArea showCount rows="5" name={`columns[${key}].text`} placeholder={translate("carousel.card.desc.placeholder", "Insert descrition")} />
              </FormItem>

              <ActionField
                keyIndex={key}
                setFieldTouched={setFieldTouched}
                imageMapActions={imageMapActions}
                name={`columns[${key}]`}
                values={values}
                setFieldValue={setFieldValue}
                translate={translate}
                renderField={renderField}
                setValues={setValues}
              />

            </div>
            <CardAction>
              <Button type="text" onClick={()=>{ copy(values, setFieldValue); }}>
                <SnippetsOutlined />
              </Button>
              <Button disabled={parseInt(key) === 0} type="text" onClick={()=>{ onSort("down", values, setFieldValue); }}>
                <LeftOutlined />
              </Button>

              <Button disabled={(parseInt(key) + 1) >= values.columns.length} type="text" onClick={()=>{
                onSort("up", values, setFieldValue);
              }}>
                <RightOutlined />
              </Button>

              <Button disabled={values.columns.length === 1} type="text" onClick={()=>{
                if (key > 0){
                  let colmns = values.columns;
                  colmns.splice(key, 1);
                  setFieldValue("columns", [...colmns]);
                  setKey(colmns.length-1);
                  if (colmns.length === 0){
                    setFieldValue("type", undefined);
                  }
                }
              }}><CloseOutlined /></Button>
            </CardAction>
          </div>
        </div>
      );
    }
    if (values.type === "IMAGE"){
      return (
        <div key="render-image">
          <div style={{ display: "flex" }}>
            <div style={{ width: "250px" }}>
              <Card type={values.type} item={values.columns[key]}/>
            </div>
            <div style={{ width: "700px" }}>
              <FormItem key={"100"} label={translate("carousel.card.title", "Card title")} name={`columns[${key}].title`} required>
                <Input name={`columns[${key}].title`} placeholder={translate("carousel.card.title.placeholder", "Insert card title")} maxLength={20} />
              </FormItem>
              <FormItem key={"11"} label={translate("carousel.card.image", "Card image")} name={`columns[${key}].thumbnailImageUrl`} required>
                <Upload type={values.type} action="/api/carousel/upload" imageLoading={`columns[${key}].imageLoading`} name={`columns[${key}].thumbnailImageUrl`} placeholder={translate("carousel.card.image.placeholde", "Upload card image")}/>
              </FormItem>

              <ActionField
                type="image"
                keyIndex={key}
                setFieldTouched={setFieldTouched}
                imageMapActions={imageMapActions}
                name={`columns[${key}]`}
                values={values}
                setFieldValue={setFieldValue}
                translate={translate}
                renderField={renderField}
                setValues={setValues}
              />

            </div>
            <CardAction>
              <Button key="btn-copy" type="text" onClick={()=>{ copy(values, setFieldValue); }}>
                <SnippetsOutlined />
              </Button>
              <Button key="btn-left-arrow" disabled={parseInt(key) === 0} type="text" onClick={()=>{ onSort("down", values, setFieldValue); }}>
                <LeftOutlined />
              </Button>

              <Button key="btn-right-arrow" disabled={(parseInt(key) + 1) >= values.columns.length} type="text" onClick={()=>{
                onSort("up", values, setFieldValue);
              }}>
                <RightOutlined />
              </Button>

              <Button key="btn-remove" disabled={values.columns.length === 1} type="text" onClick={()=>{
                if (key > 0){
                  let colmns = values.columns;
                  colmns.splice(key, 1);
                  setFieldValue("columns", [...colmns]);
                  setKey(colmns.length-1);
                  if (colmns.length === 0){
                    setFieldValue("type", undefined);
                  }
                }
              }}><CloseOutlined /></Button>
            </CardAction>
          </div>
        </div>
      );
    }
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    }
  }));

  React.useEffect(()=> {
    setTimeout(()=>{setLoading(false);}, 500);
  }, [loading]);

  return (
    <Container>
      <Formik
        key="formik"
        initialValues={data}
        onSubmit={()=>{console.log();}}
        validationSchema={props => FormSchema(translate, setKey)}>
        {({ isSubmitting, values, setFieldValue, errors, setFieldTouched, setValues }) => {

          return (
            <Form key="form" layout="vertical" {...formItemLayout}>
              <FormItem key="altText" label={translate("carousel.title.form", "Title")} name="altText" required>
                <Input name="altText" placeholder={translate("carousel.title.form.placeholder", "Insert title")} className="title" />
              </FormItem>

              <AntRadio.Group key="type" name="type" value={values.type} buttonStyle="solid" onChange={(item) => {
                if (values.type !== item && values.type !== undefined){
                  discard(item.target.value, setValues, setFieldTouched);
                } else {
                  setFieldValue("type", item.target.value);
                  setLoading(true);
                }
              }}
              style={{ marginBottom: "10px" }}>
                <AntRadio.Button key={"product"} value="PRODUCT">{translate("carousel.card", "Card type")}</AntRadio.Button>
                <AntRadio.Button key="image" value="IMAGE">{translate("carousel.image", "Image type")}</AntRadio.Button>
              </AntRadio.Group>
              {
                values.type && (
                  <SubFormContainer>
                    <Tabs
                      activeKey={`${key}`}
                      tabBarExtraContent={<Button onClick={()=> {
                        onEdit(null, "add", setFieldValue, values);
                      }}>{translate("system.add", "Add card")}</Button>}
                      onChange={(key)=> {
                        setKey(key);
                      }}
                      onEdit={(targetKey, action)=> onEdit(targetKey, action, setFieldValue, values)}
                      type="card"
                    >
                      {[...Array.from({ length: values?.columns?.length }, (v, i) => i)].map(i => (
                        <TabPane key={i} disabled={i === 28} tab={i +1}/>
                      ))}
                      {/* {values.columns.map((item, index) => {
                        return (
                          <>
                            <TabPane
                              closable={values.columns.length !== 1}
                              key={`${keyGenerate(index)}`}
                              tab={index+1}></TabPane>;
                          </>
                        );
                      })} */}
                    </Tabs>
                    <Spin spinning={loading}>
                      {loading === false ?renderCarousel(values, setFieldValue, setValues, setFieldTouched):""}
                    </Spin>
                  </SubFormContainer>
                )
              }
              <SubmitForm ref={submitRef} translate={translate} setKey={setKey} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});


const CustomFormItem = styled(FormItem)`
.ant-checkbox-wrapper {
  position:inherit !important;
  z-index: 0 !important; 
  top: 0px !important; 
  left: 0px !important;
}
`;

const CardAction = styled.div`
  width : 100%;
  display:flex;
  justify-content:end;
`;


const Container = styled.div`
  .title {
    width: 716px !important;
  }
  .ant-input-textarea {
    width:467px;
  }
  .ant-input-group {
    width:469px !important;
  }
  .ant-input {
    width:467px;
  }
  
`;

const SubFormContainer = styled.div``;
/* eslint-disable no-nested-ternary */

import React from "react";
import { Form, FormItem, Input, Checkbox, InputNumber, Select } from "formik-antd";
import { Col, Form as AntForm, message, Row, Space, Spin } from "antd";
import { Field, Formik } from "formik";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import * as Yup from "yup";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";
import colors from "../../../colors";
import copy from "copy-to-clipboard";
import { general, plan } from "../../../apis";
import { useSelector } from "react-redux";

const FormSchema = (translate) => Yup.object().shape({
  name: Yup.string()
    .required(translate("system.message.required", "Please fill out this field")),
  desc: Yup.string()
    .required(translate("system.message.required", "Please fill out this field")),
  messageLimit: Yup.number().max(999999999, translate("plan.message.limit", "Must be under 9 digit"))
    .required(translate("system.message.required", "Please fill out this field")),
  accountLimit: Yup.number()
    .required(translate("system.message.required", "Please fill out this field")),
  followerLimit: Yup.number()
    .required(translate("system.message.required", "Please fill out this field")),
  unitPrice: Yup.number().min(0)
    .required(translate("system.message.required", "Please fill out this field")),
  amount: Yup.number().min(1)
    .required(translate("system.message.required", "Please fill out this field")),
  type: Yup.string()
    .required(translate("system.message.required", "Please fill out this field")),
  limitedExpires: Yup.string()
    .required(translate("system.message.required", "Please fill out this field")),
  ecmLevel: Yup.string()
    .nullable(translate("system.message.required", "Please fill out this field")),
});

const { Option } = Select;

export default ({ onCancel, action, onSubmit }) => {
  const { translate } = useTranslate();
  const { planPermissions } = useSelector(state => state.general);
  const [data] = React.useState({
    name          : "",
    desc          : "",
    accountLimit  : 1,
    followerLimit : 1,
    messageLimit  : 1,
    active        : false,
    unitPrice     : 1,
    limitedExpires: 0,
    ecmLevel      : "",
    limitedCode   : "",
    sort          : 123,
    type          : "",
    isTrail       : false,
    invitational  : false,
    testUserLimit : 0,
    permissions   : [],
    ...(action && action[0] === "update" ? action[1] : {}),
    // amount        : action[1] && action[1].type === "FREE" ? 1 : action[1].amount
    amount        : (action[1] && action[1].type ? action[1].type === "FREE" ? 1 : action[1].amount:1),
  });

  const onCopyLimitedCode = async () => {
    copy(data.limitedCode);
    message.success(translate("system.message.success", "Success"));
  };
  const selectType = (setValues, values, type) => {
    if (type === "FREE") {
      setValues({ ...values, amount: 1, limitedExpires: 0, limitedCode: 0, type: type });
    }
    if (type === "PAID") {
      setValues({ ...values, limitedExpires: 0, limitedCode: 0, type: type });
    }
    if (type === "LIMITED") {
      setValues({ ...values, type: type, limitedCode: 0, });
    }
  };
  const [permission, setPermissions] = React.useState([]);

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={()=>FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting, values, setValues }) => (
        <Form {...formItemLayout}>
          <FormItem name="type" label={translate("plan.select.type", "Plan type")} required>
            <Select placeholder={translate("plan.select.type", "Plan type")} name="type" onChange={(e) => selectType(setValues, values, e)}>
              <Option key="free" value="FREE">{translate("plan.free", "Free") }</Option>
              <Option key="paid" value="PAID">{translate("plan.paid", "Paid")}</Option>
              <Option key="limited" value="LIMITED">{translate("plan.limited", "Limited")}</Option>
            </Select>
          </FormItem>

          {
            values.type === "PAID" ?
              (<FormItem label={translate("plan.price", "Price")} name="amount" required>
                <Input name="amount" type="number" placeholder={translate("payment.amount", "Price")} />
              </FormItem>) :
              values.type === "LIMITED" ?
                (
                  <div>
                    <FormItem label={translate("plan.price", "Price")} name="amount" required>
                      <Input name="amount" type="number" placeholder={translate("payment.amount", "Price")} />
                    </FormItem>
                    <FormItem label={translate("plan.free.duration", "Free duration(day)")} name="limitedExpires" required>
                      <Input name="limitedExpires" type="number" placeholder={translate("plan.free.duration", "Free duration(day)")} />
                    </FormItem>
                  </div>
                ): null
          }
          {
            data.limitedCode&& <FormItem label={translate("plan.ivnitation.code", "Invitation Code")} name="limitedCode" required>
              <Input disabled name="limitedCode" placeholder={translate("plan.ivnitation.code", "Invitation Code")} suffix={<Button size="small" type="default" onClick={onCopyLimitedCode}>{translate("line.copy", "Copy")}</Button>} />
            </FormItem>
          }
          <FormItem label={translate("plan.name", "Name")} name="name" required>
            <Input name="name" placeholder={translate("plan.name.placeholder", "Name")} />
          </FormItem>
          <FormItem label={translate("plan.description", "Description")} name="desc" required>
            <Input name="desc" placeholder={translate("plan.description.placeholder", "Description")} />
          </FormItem>
          <FormItem label={translate("plan.message", "Message limit")} name="messageLimit" required>
            <Input name="messageLimit" type="number" placeholder={translate("plan.message.placeholder", "Message limit")} />
          </FormItem>
          <FormItem label={translate("plan.acc.limit", "Accounts limit")} name="accountLimit" required>
            <Input name="accountLimit" type="number" placeholder={translate("plan.acc.limit", "Accounts limit")} />
          </FormItem>
          <FormItem label={translate("plan.follower.limit", "Follower limit")} name="followerLimit" required>
            <Input name="followerLimit" type="number" placeholder={translate("plan.follower.limit", "Follower limit")} />
          </FormItem>
          <FormItem label={translate("plan.additional", "Additional message fee")} name="unitPrice" required>
            <Input name="unitPrice" type="number" placeholder={translate("plan.additional", "Unit price")} />
          </FormItem>
          <FormItem label={translate("plan.follower.testuserlimit", "Test user limit")} name="testUserLimit" required>
            <Input name="testUserLimit" type="number" placeholder={translate("plan.follower.testuserlimit", "Test user limit")} />
          </FormItem>
          <FormItem label={translate("plan.ecmlevel", "Ecm service level")} name="ecmLevel" required>
            <Input name="ecmLevel" placeholder={translate("plan.ecmlevel", "Ecm service level")} />
          </FormItem>
          <FormItem name="active" label={translate("system.status", "Active/Disactive")} required>
            <SwitchStyle>
              <Checkbox name="active" checkedChildren="Enabled" unCheckedChildren="Disabled" defaultChecked />
            </SwitchStyle>
          </FormItem>

          {values.type === "FREE" &&
              <FormItem name="invitational" label={translate("plan.free.invitation", "Invitation")} required>
                <SwitchStyle>
                  <Checkbox name="invitational" checkedChildren="Enabled" unCheckedChildren="Disabled" defaultChecked />
                </SwitchStyle>
              </FormItem>
          }
          {
            <FormItem label={translate("plan.form.permissions", "Permissions")} name="permissions" required>
              <Checkbox.Group name="permissions" style={{ width: "100%" }}>
                <Row>
                  {planPermissions.map((item, index) => (
                    <Col key={index} span={8}>
                      <Checkbox value={item}> {translate(`system.constant.plan.${item}`, translate(`system.constant.plan.en.${item}`, item))}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </FormItem>
          }
          <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
            <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
            <Button type="default" onClick={onCancel}>{translate("system.cancel", "Cancel")}</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};

const SwitchStyle = styled.div`
  .ant-switch-checked {
    background-color : ${colors.primaryDark};
  }
`;
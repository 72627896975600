import React from "react";
import { operation as operationApi } from "../../../apis";
import { useFetch, useTranslate } from "../../../hooks";
import { PageContainer, PageContent, PageHeader } from "../../../components/Layout";
import { useHistory, useParams } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import styled from "styled-components";
import moment from "moment";
import Avatar from "antd/lib/avatar/avatar";
import { Button, Space, Spin, Tag, Tooltip } from "antd";
import { MessagePreview, Sticker } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import NoImage from "../../../assets/no_image.png";

export default () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const { lang, messageChildTypes } = useSelector(state =>state.general);
  const [details, fetching] = useFetch(operationApi.multicastDetail, params.id)({});
  const { filters } = React.useState({
    filter: {},
    offset: {
      page : 1,
      limit: 30
    }
  });
  const messagePreview =()=> {
    const payload = {
      children: details?.children?.map(child => ({
        ...child,
        template         : child.template && child.template._id,
        templateValues   : child.template,
        cooperation      : child.cooperation && child.cooperation._id,
        cooperationValues: child.cooperation,
        imagemap         : child.imagemap && child.imagemap._id,
        imagemapValues   : child.imagemap,
        carousel         : child.carousel && child.carousel._id,
        carouselValues   : child.carousel
      })),
      segments: details.targets,
      _id     : undefined
    };
    dispatch({
      type   : "message.message.data",
      payload: payload
    });
  };


  const messageView =(message)=> {
    if (message){
      if (message.type === "sticker"){
        return (
          // <Sticker id={message.packageId} />
          <Sticker
            size="small"
            packageId={message.packageId}
            id={message.stickerId}
          />
        );
      } if (message?.text){
        return (<div>{message.text}</div>);
      }
      return (<Avatar shape="square" size={80} src={message && message?.previewImage?message?.previewImage:NoImage} />);
    }
    return (<div></div>);
  };

  const convertType = (array, key) => {
    const initialValue = {};
    let objectsType ={};

    objectsType = array.reduce((obj, item) => {
      return {
        ...obj,
        [item.code]: item,
      };
    }, initialValue);

    if (objectsType[key])
      return objectsType[key][lang.toLowerCase()];
    return "-";
  };



  const richActions =(item)=> {
    if (item.type === "uri"){
      return item.linkUri;
    }
    if (item.type === "coupon"){
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {item.couponCode}
          <Button onClick={()=>{
            history.push(`/rakuten/coupon/detail/${item.couponCode}`);
          }
          }
          size="small">{translate("system.show.detail", "Show details")}</Button>
        </div>);
    }
    if (item.type === "message"){
      return item.text;
    }
    if (item.type === "cooperation"){
      return item.type;
    }
    if (item.type === "no_action"){
      return "-";
    }
  };

  const detail = (item) => {
    console.log("**** **** **** **** **** ****");
    console.log(item);
    history.push(`/operation/multicast_report/carousel/${item._id}`);
  };

  React.useEffect(()=> {
    if (details.imagemaps){
      dispatch({
        type   : "general.imagemapSelect",
        payload: details.imagemaps[0]
      });
    }
    if (!fetching){

      messagePreview();
    }
  }, [fetching]);

  return (
    <Spin spinning={fetching}>
      <PageContainer>
        <PageHeader onBack={() => history.push("/operation/multicast_report")} title={translate("multicast.detail.title", "Multicast detail")} />
        {!fetching && (
          <PageContent>
            <Container>
              <h3 > </h3>
              <BroadcastDetail
                ref={myTableRef}
                filters={filters}
                dataSource={[{}]}
                bordered
                thead={() => (
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" >{translate("multicast.detail.sent.date", "Date sent")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.delivered", "Delivered")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.open.count", "Open count")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.clicked.user", "Clicked user count")}</th>
                    </tr>
                  </thead>
                )} tbody={(row, index) => (
                  <tbody key={index} className="ant-table-tbody">
                    <tr>
                      <td className="ant-table-cell" >{moment(details.sendDate).format("YYYY-MM-DD HH:mm")}</td>
                      <td className="ant-table-cell" >{details.delivered ? details.delivered : "-"}</td>
                      <td className="ant-table-cell" >{details.uniqueImpression ? details.uniqueImpression : "-"}</td>
                      <td className="ant-table-cell" >{details.uniqueClick ? details.uniqueClick : "-"}</td>

                    </tr>
                  </tbody>
                )} />
            </Container>

            <Container>
              <h3 className="title">{translate("multicast.detail.impressions", "Impressions")}</h3>
              <BroadcastDetail
                ref={myTableRef}
                filters={filters}
                dataSource={details.impressions}
                bordered
                thead={() => (
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" >{translate("multicast.detail.impressions.order", "Order sent")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.impressions.message", "Message")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.impressions.type", "Type")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.impressions.open", "Impressions")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.impressions.click", "Clicks")}</th>
                    </tr>
                  </thead>
                )} tbody={(row, index) => {

                  return (
                    <tbody key={index} className="ant-table-tbody">
                      <tr key={index}>
                        <td className="ant-table-cell" >{index + 1}</td>
                        <td className="ant-table-cell" >{messageView(row)}</td>
                        <td className="ant-table-cell" >
                          <Space style={{display : "flex", justifyContent : "space-between"}}>
                            <Space> 
                              {messageChildTypes && convertType(messageChildTypes, row?.type)}
              
                              {row.type ==="carousel" && translate(`carousel.detail.carouseltype.${row.carouselType}`, `:${row.carouselType}`)}
                            </Space>
                            {row.type === "carousel" ? <Button style={{color : "green", borderColor : "green"}} onClick={()=>detail(row)}>{translate("multicast.detail.impressions.detail", "Detail")}</Button> : ""}
                          </Space>
                        </td>

                        <td className="ant-table-cell" >{row.openCount ? row.openCount:"-"}</td>
                        <td className="ant-table-cell" >{row.clickCount ? row.clickCount:"-"}</td>

                      </tr>
                    </tbody>
                  );
                }} />
            </Container>
            <Container style={{ paddingBottom: "70px" }}>
              <h3 className="title">{translate("multicast.detail.imagemap", "Rich messages")}</h3>
              <BroadcastDetail
                ref={myTableRef}
                filters={filters}
                dataSource={details.imagemaps}
                bordered
                thead={() => (
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" >{translate("multicast.detail.imagemap.image", "Image")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.imagemap.template", "Template & Type")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.imagemap.content", "Content")}</th>
                      <th className="ant-table-cell" >{translate("multicast.detail.imagemap.click", "Clicks")}</th>
                    </tr>
                  </thead>
                )} tbody={(row, index) => {
                  return (
                    <tbody key={index} className="ant-table-tbody">
                      <tr>
                        <td className="ant-table-cell" style={{ width: "100px" }} rowSpan={row?.actions?.length +1} >
                          {row.mapType === "video"?(
                            <Avatar shape="square" size={100} src={row?.previewUrl}/>
                          ):
                            (<Avatar shape="square" size={100} src={row?.baseUrl}/>)}

                        </td>
                      </tr>
                      {
                        row.actions.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="ant-table-cell" style={{ width: "100px" }}>

                                {row.mapType === "video"?(
                                  <Tag>{translate("multicast.detail.imagemap.video", "Rich video message")}</Tag>
                                ):
                                  (<Space><Tag>{DRAW_TEMPLATES[index]}</Tag>{<Tag color="green">{translate(`richmenu.action.${item.type}`, item.type)}</Tag>}</Space>)}

                              </td>
                              <td className="ant-table-cell">
                                {
                                  row.mapType === "video"?(
                                    <Tag>{row.video && row.video?.externalLink ? row.video?.externalLink?.linkUri : "-"}</Tag>
                                  ):richActions(item)
                                }
                              </td>
                              <td className="ant-table-cell">{
                                row.mapType === "video"?(
                                  <div>{row.video ? row.video?.externalLink?.clicks : "-"}</div>
                                ):
                                  (<div>{item.clicks ? item.clicks:"-"}</div>)
                              }</td>
                            </tr>
                          );
                        })
                      }

                    </tbody>
                  );
                }} />
            </Container>
            <MessagePreview type="fixed" />
          </PageContent>
        )}
      </PageContainer>
    </Spin>
  );
};
const DRAW_TEMPLATES = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const TemplateWrapper = styled.div`
  display : flex;
  flex-direction : column;
  .custom-alert {
    padding: 10px;
    margin-top: 10px;
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    .has-error {
      color: red;
      font-size: 13px;
      font-weight: 500;
      width: 240px;
    }
  }
  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
  }
`;

const Container = styled.div`
  .title { 
    font-weight : 500;
    margin-top: 40px;
  }
`;
const BroadcastDetail = styled(CustomTable)`
  .ant-pagination { 
    display: none;
  }
  .ant-table-cell {
    padding: 8px!important;
  }
  .cm-td { 
    border-bottom: solid 2px #dcd8d8 !important
  }
  .cm-th {
    border-bottom: solid 2px #dcd8d8 !important;
      padding: 8px!important;
  }
`;

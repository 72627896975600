import React from "react";
import Form from "./Form";
import { coupon as couponApi } from "../../apis";
import { Checkbox, message, Modal, Button } from "antd";
import { useTranslate } from "../../hooks";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import moment from "moment";
import { useHistory } from "react-router-dom";
import styled from "styled-components";


export default () => {
  const { translate } = useTranslate();
  const [action, setAction] = React.useState();
  const [errors, setErrors] = React.useState([]);
  const history = useHistory();
  const [visible, setVisible] = React.useState(false);
  const [confirmCheck, setComfirmCheck] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [warningAlert, setWarningAlert] = React.useState("");
  const submitRef = React.useRef();

  const onSubmit = async (data) => {
    const isValid = await submitRef.current.validate();

    if (!isValid) return;

    if (isValid.isMultiRankCond){
      isValid.purchaseHistoryCond = { type: 0 };
      isValid.genderCond = "NONE";
      isValid.ageRangeCond = { ageType: false };
      isValid.birthmonthCond = 0;
      isValid.multiPrefectureCondSet = false;
    }
    if (isValid.discountType === 1) {
      isValid.discountFactor.percent = undefined;
    }
    if (isValid.discountType === 2) {
      isValid.discountFactor.flat = undefined;
    }
    if (isValid.discountType === 4) {
      isValid.discountFactor = undefined;
    }

    if (isValid.memberAvailType === false) {
      isValid.memberAvailMaxCount = 0;
    }
    let ageRangeCond = isValid.ageRangeCond;
    let purchaseHistoryCond = isValid.purchaseHistoryCond;

    setAction(["update", isValid]);
    console.log("------------autoissue--------->", isValid.autoIssue);

    console.log("START TIME :", moment(isValid.autoIssue?.startTime).format("YYYY-MM-DD HH:mm"));
    console.log("START TIME :", moment(isValid.autoIssue?.startTime).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm"));
    if (ageRangeCond.ageType && ageRangeCond.upperBound < ageRangeCond.lowerBound) {
      message.error(translate("coupon.alert.agerangecond", "Age range is invalid!"));
      return;
    }

    if (purchaseHistoryCond.type === 2 && purchaseHistoryCond.purchaseCount?.maximum > 0 && purchaseHistoryCond.purchaseCount?.maximum < purchaseHistoryCond.purchaseCount?.minimum) {
      message.error(translate("coupon.alert.purchasehistorycond", "Purhcase count is invalid!"));
      return;
    }

    if (isValid.isAutoIssue && isValid?.autoIssue?.type === "CUSTOM") {
      if (moment(isValid.autoIssue?.startTime) > moment(isValid.autoIssue?.expireTime).add(isValid.autoIssue?.expireDay, "days")) {
        message.error(translate("coupon.alert.autoissue.expiretime", "Auto issue expire date must be from later than start date"));
        return;
      }
    }
    if (moment(isValid.schedules[0]) > moment(new Date()).add(30, "day")) {
      message.error(translate("coupon.alert.30day", "Start date must be within one month from now"));
      return;
    }
    if (moment(isValid.schedules[0]).valueOf() < moment().add(1, "hour").valueOf()) {
      message.error(translate("coupon.alert.onehour", "Start date must be after one hour"));
      return;
    }
    if (isValid.discountFactor?.flat && isValid.discountFactor?.flat > isValid?.conditionStartValueRS003) {
      setWarningAlert(translate("coupon.logic.message", "Amount of the usage condition is lower than the fixed discount amount , if you use this coupon, the sales may become 0 yen"));
      setVisible(true);
      return;
    }
    if ((isValid.discountFactor?.flat && isValid?.conditionStartValueRS004) || (isValid.discountFactor?.flat && !isValid?.conditionTypeCode)) {
      setWarningAlert(translate("coupon.logic.message.2", "If you use this coupon, the sales may be 0 yen because the flat-rate discount amount is set without specifying the amount of usage condition"));
      setVisible(true);
    }
    else {
      send(isValid);
    }
  };

  const send = async (data) => {

    // let couponData = !data ? { ...(action && action[1]) } : data;

    let couponData = {};

    if (data?.name) {
      couponData = { ...data };
    } else {
      couponData = action[1];
    }

    if (couponData?.item?.length === 1) {
      couponData.itemType = 1;
    }
    if (couponData?.item?.length > 1) {
      couponData.itemType = 3;
    }
    if (couponData.discountType === 4) {
      couponData.itemType = 5;
    }

    if (couponData.conditionStartValueRS003) {
      couponData = { ...couponData, conditionStartValue: couponData.conditionStartValueRS003 };
    }
    else if (couponData.conditionStartValueRS004) {
      couponData = { ...couponData, conditionStartValue: couponData.conditionStartValueRS004 };
    }

    if (!couponData.discountFactor) {
      couponData.discountFactor = {};
    }

    try {
      setLoading(true);
      await couponApi.create({ ...couponData, item: couponData?.item?.toString() });
      message.success(translate("system.message.success", "Success"));
      onCancel();
      history.push("/rakuten/coupon");
    } catch (err) {
      if (err && Array.isArray(err.message)){
        setErrors([...err.message]);
      } else {
        message.error(err?.message?.message);
      }
      onCancel();

    }
    setVisible(false);
  };
  const onCancel =()=> {
    setVisible(false);
    setLoading(false);
  };

  return (
    <Container>
      <PageContainer>
        <PageHeader onBack={() => history.push("/rakuten/coupon")} title={translate("coupon.new.title", "Create rakuten coupon")} extra={[
          <Button key={2} type="primary" onClick={() => onSubmit()} loading={action && action[0] === "load" && action[1]}>{translate("system.save", "Save")}</Button>
        ]} />

        <PageContent>
          <Form ref={submitRef} onSubmit={onSubmit} errors={errors} />
        </PageContent>

        <Modal
          title={<div style={{ color: "red", display: "flex", justifyContent: "center" }}>{translate("coupon.logic.title", "Please note")}</div>}
          visible={visible}
          onCancel={()=>onCancel()}
          footer={[
            <Button type="default" onClick={() => onCancel()}>{translate("system.cancel", "Cancel")}</Button>,
            <Button loading={loading} disabled={!confirmCheck} type="primary" onClick={() => send(undefined)}>{translate("system.confirm", "Confirm")}</Button>
          ]}
        >
          <p>{warningAlert}</p>
          <div>
            <Checkbox defaultChecked={confirmCheck} onChange={(e) => setComfirmCheck(e.target.checked)}>{translate("coupon.logic.checkbox", "Register this coupon")}</Checkbox>
          </div>
        </Modal>

      </PageContainer>
    </Container>
  );
};


const Container = styled.div`
`;
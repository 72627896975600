import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { MyTable } from "../../components";
import { useSelector } from "react-redux";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { customer as customerService, operation } from "../../apis";
import { AutoComplete, Button, DatePicker, Empty, Input, Select, Space } from "antd";
import moment from "moment";
import { useFetch, useTranslate } from "../../hooks";
import styled from "styled-components";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import { ManualDesc, ManualLink } from "../../components/Manual";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default () => {

  const { translate } = useTranslate();
  const history = useHistory();
  const { logTypes, lang, customer, customers, user } = useSelector(state => state.general);
  const myTableRef = useRef(null);
  const [query, setQuery] = React.useState(null);
  const [customerfilter, setCustomer] = React.useState(null);
  const [code, setCode] = React.useState(null);
  const [rows, setRows] = React.useState([]);

  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth(),
    d = date.getDate();

  let firstDay = new Date(y, m, d);
  let lastDay = new Date(y, m, d);

  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    filter: {
      query    : null,
      customer : null,
      code     : null,
      startDate: moment(new Date(firstDay)).startOf("day").toDate(),
      endDate  : moment(new Date(lastDay)).endOf("day").toDate()
    },
    offset: {
      limit: 50,
      page : 1
    }
  });

  const customerReload = async () => {
    let res = await customerService.list({
      filter: {
        query   : null,
        customer: null,
        code    : null,
      },
      offset: {
        limit: 50,
        page : 1
      }
    });
    setRows(res.rows);
  };

  const customerColumns = useHeader({
    history,
    logTypes, lang,
    tableTitles: {
      lineAccountName: translate("report.lineaccount", "LINE Account name"),
      customerName   : translate("login.username", "Customer name"),
      logName        : translate("log.type", "Log menu"),
      date           : translate("log.date", "Operation Date"),
      user           : translate("log.user", "Login"),
      self           : translate("log.self", "Self"),
    },
    translate
  });
  const adminOrAgency = useHeaderAdminOrAgency({
    history,
    logTypes, lang,
    tableTitles: {
      lineAccountName: translate("report.lineaccount", "LINE Account name"),
      customerName   : translate("login.username", "Customer name"),
      logName        : translate("log.type", "Log menu"),
      date           : translate("log.date", "Operation Date"),
      user           : translate("log.user", "Login"),
      self           : translate("log.self", "Self"),
    },
    translate
  });

  const changeRange = (e) => {
    // setFilters({ ...filters, filter: { ...filters.filter, startDate: e && moment(e[0]), endDate: e && moment(e[1]) } });
    setFilters({ ...filters, filter: { ...filters.filter, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() } });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const clear = () => {
    setCode(null);
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.filter.query !== query)
        setFilters({ ...filters, filter: { ...filters.filter, query: query } });
      if (filters.filter.customer !== customerfilter)
        setFilters({ ...filters, filter: { ...filters.filter, customer: customerfilter } });
      if (filters.filter.code !== code)
        setFilters({ ...filters, filter: { ...filters.filter, code: code } });
    }, 300);
    return () => clearTimeout(timer);
  }, [query, customerfilter, code]);


  React.useEffect(() => {
    setRangePicker({ startDate: moment(firstDay).startOf("day"), endDate: moment(lastDay).endOf("day") });
  }, []);

  React.useEffect(() => {
    if (!customer && user.role === "ADMIN") {
      customerReload();
    }
  }, [user]);



  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.log"></ManualLink>} title={translate("operation.log", "Operation Logs")} extra={[]} />
      <ManualDesc translateKey ="manual.desc.log" />
      <PageFilter>
        <Space>
          {
            <Input
              name="query"
              value={query}
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              className="ant-input-custom"
              prefix={<SearchOutlined />}
            />
          }
          <Select
            showSearch
            name="code"
            placeholder={translate("log.type", "Select a log type")}
            value={code === null ? null : code}
            style={{ width: 300 }}
            optionFilterProp="children"
            onChange={e => setCode(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.keys(logTypes).map((item, index) => (
              <Option key={index} value={`${logTypes[item].code}`}>{`${logTypes[item][lang.toLowerCase()]}`}</Option>
            ))}
          </Select>
          <Button onClick={clear}>{translate("system.clear", "Clear")}</Button>
        </Space>
        <Space>
          {customers && customers.length > 0 && <Select
            showSearch
            value={customerfilter === undefined ? undefined : customerfilter}
            placeholder="Select a customer"
            style={{ width: 200 }}
            optionFilterProp="children"
            onChange={e => setCustomer(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {customers && customers.map(item => (
              <Option key={item._id} value={item._id}>{item.companyName}</Option>
            ))}
          </Select>}
          {!customer && user.role === "ADMIN" ? <Select
            showSearch
            value={customerfilter === undefined ? undefined : customerfilter}
            placeholder={translate("login.username", "Select a customer")}
            style={{ width: 200 }}
            optionFilterProp="children"
            onChange={e => setCustomer(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {rows.map(item => (
              <Option key={item._id} value={item._id}>{item.companyName}</Option>
            ))}

          </Select> : null}

          <RangePicker allowClear={false} value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
          {/* <Button onClick={() => { setQuery(undefined); setCode(undefined); setCustomer(undefined); setFilters({ ...filters, startDate: undefined, endDate: undefined }); }}>{translate("system.clear", "Clear")}</Button> */}

        </Space>
      </PageFilter>

      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters.filter}
          rowKey={(record) => record._id}
          columns={
            customer ? customerColumns : adminOrAgency
          }
          loadData={operation.logs}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ logTypes, lang, tableTitles, translate }) => {
  return [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    }, {
      title : tableTitles.lineAccountName,
      render: (record) => <span>{record.lineAccountName ? record.lineAccountName: "-" }</span>,
    }, {
      title : tableTitles.logName,
      render: (record) => <span>{logTypes[record.code] && `${logTypes[record.code][lang.toLowerCase()]}` || "-"}</span>
    }, {
      title : tableTitles.date,
      render: (record) => moment(record.date).format("YYYY-MM-DD HH:mm:ss")
    }, {
      title : tableTitles.user,
      render: (item) => (<div>{item.login ? item.login : item.companyName}</div>)
    }];
};

const useHeaderAdminOrAgency = ({ logTypes, lang, tableTitles, translate }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.customerName,
    render: (item) => (<div>{item.companyName ? item.companyName : "-"}</div>),
  }, {
    title : tableTitles.lineAccountName,
    render: (record) => <span>{record.lineAccountName ? record.lineAccountName : "-"}</span>,
  }, {
    title : tableTitles.logName,
    render: (record) => <span>{logTypes[record.code] && `${logTypes[record.code][lang.toLowerCase()]}` || "-"}</span>
  }, {
    title : tableTitles.date,
    render: (record) => moment(record.date).format("YYYY-MM-DD HH:mm:ss")
  }, {
    title : tableTitles.user,
    render: (item) => (<div>{item.login ? item.login : item.companyName}</div>)
  }];
};
const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;
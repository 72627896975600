import React from "react";
import { Upload, Button, message } from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslate } from "../../hooks";

const { Dragger } = Upload;

export default ()=> {
  const { translate, changeLanguage, lang } = useTranslate();
  const data = {
    name    : "file",
    multiple: false,
    action  : "/api/localization/upload",
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        message.success(translate("info.file.name", "Uploaded successfully", info.file.name));
      } else if (status === "error") {
        message.error(`${info.file.name} ${translate("system.message.failed.upload", "Upload failed!")}`);
      }
    },
  };

  return (
    <div style={{ width: "600px" }}>
      <Dragger {...data}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Japanese Language</p>
        <p className="ant-upload-hint">
          XLSX file import
        </p>
      </Dragger>
    </div>
  );
};
import React from "react";
import { Button, Form as AntForm, Row, Col } from "antd";
import { Formik } from "formik";
import { useTranslate } from "../../../hooks";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { Form, FormItem, TimePicker, Input } from "formik-antd";
import styled from "styled-components";
import colors from "../../../colors";
import * as Yup from "yup";

const FormSchema =(translate) => Yup.object({
  text: Yup.string().max(100).required(translate("system.message.required", "This field is required!")),
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    text: undefined,
    ...(action && action[1])
  });

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {() => {
          return (
            <Form {...formItemLayout}>
              <AntForm.Item label={translate("scenario.settings.form.text", "Set text")}>
                <FormItem name="text">
                  <Input min={0} type="text" name="text" placeholder={translate("scenario.settings.form.text.placeholder", "Text")} />
                </FormItem>
              </AntForm.Item>
              <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
                <Button htmlType="submit" type="primary" style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
                <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
const Container = styled.div`
  padding : 10px;
  width: 100%;
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;
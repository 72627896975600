import React from "react";
import { Spin } from "antd";
import styled from "styled-components";
import colors from "../../colors";

export const Loading = () => {
  const SpinStyle = styled.div`
    .ant-spin-dot-item {
      background-color : ${colors.primaryDark};
    }
  `;
  return (
    <SpinStyle>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Spin />
      </div>
    </SpinStyle>
  );

};

export const PanelWrapper = styled.div`
    border-radius : 5px;
    width  : 100%;
`;

export const PanelHeader = styled.div`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color :${colors.primaryDark};
    display : flex;
    flex-direction : column;
    align-items: center;
    justify-content: center;
    height : 40px;
    strong {
      font-size : 15px;
      color     : white;
    }
`;

export const PanelBody = styled.div`
  display : flex;
`;

export const PanelContent =styled.div` 
    border-radius : 4px;
    border : solid 1px rgb(247 247 249);
    width  : 100%;
    /* height :35px; */
    display:flex;
    justify-content : space-around;
    align-items     : center;
    .card-image {
      fill :red;
    }
    div {
      font-size: 25px;
      color: #a2a2a2;
      font-weight: 500;
    }
    .select-plan-header {
      margin-left: -5px;
      font-weight :bold;
      color :#434A54;
      font-weight: 500;
      color: rgb(0 157 75);
      font-size: 1.5em;
    }
    .header-plan {
      border :solid 1px;
      border-color :#CDD1D9;
      height :50px;
      padding-left :20px ;
      background-color :rgb(0 157 75)
    }
    .currentPlan {
      border: solid 1px;
      height: 100px;
      border-radius: 3px;
      border-color: #FAFAFA;
      display: flex;
      flex-direction :column;
    }
    .current-plan-list {
      border: solid 1px;
      border-radius: 3px;
      border-color: #FAFAFA;
      display: flex;
      flex-direction :column;
    }
    .current-items {
      // width: 100px;
      margin: 10px;
      margin-left: 70px;
      margin-right: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;
export const PlanWrapper = styled.div`
  .ant-descriptions-item-label {
    width : 70%;
    border :none;
  }
  .select-plan-header {
      margin-left: -5px;
      font-weight :bold;
      color :#434A54;
      font-weight: 500;
      color: rgb(0 157 75);
      font-size: 1.5em;
    }
    .header-plan {
      height :45px;
      padding-left :20px ;
      background-color :rgb(0 157 75)
    }
    .currentPlan {
      height: 109px;
      border-radius: 3px;
      display: flex;
      flex-direction :column;
    }
    .current-plan-list {
      border-radius: 3px;
      display: flex;
      flex-direction :column;
    }
    .current-items {
      // width: 100px;
      margin: 10px;
      margin-left: 70px;
      margin-right: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;

export const PanelContainer = styled.div`

display  : flex;
.ant-spin-dot-item {
  background-color : ${colors.primaryDark};
}

.panel-delivery-header {
  padding-left : 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color :${colors.primaryDark};
  display : flex;
  flex-direction : column;
  align-items: flex-start;
  justify-content: center;
  height : 40px;
  strong {
    font-size : 15px;
    color     : white;
  }
}
.card-wrapper {
  border : solid 1px  rgb(247 247 249);
  width  : 300px;
  height : 80px;
  margin : 20px;
  display: flex;
  border-radius : 5px;
}
.card-wrapper:hover {
  box-shadow: 1px 1px 4px rgb(205, 209, 217);
}
.card-title {
  font-feature-settings: "palt";
  word-break: break-word;
  line-height: 1.6;
  font-size: 1em;
}
.card-count {
  font-size : 25px;
}
.card-icon {
  border-right: solid 1px;
  width: 60px;
  background-color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size : 35px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.panel-wrapper {  
  border : solid 1px rgb(244 244 247);
  border-radius : 5px;
  width  : 100%;
}
.panel-wrapper-delivery {  
  border : solid 1px rgb(244 244 247);
  border-radius : 5px;
  width  : 100%;
  &:first-child {
    margin-right: 10px;
  }
}
.card-body {
    display :flex;
    flex-direction : column;
    justify-content : center;
    margin-left : 10px;
}
.panel-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color :${colors.primaryDark};
  display : flex;
  flex-direction : column;
  align-items: center;
  justify-content: center;
  height : 40px;
  strong {
    font-size : 15px;
    color     : white;
  }
}
.panel-body {
  display : flex;
}
`;
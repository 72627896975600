import React from "react";
import { Tooltip, Modal } from "antd";
import styled from "styled-components";
import { QuestionCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button } from "../Design";
import { PaymentMethod } from "../";
import { useTranslate } from "../../hooks";
import { payment as paymentApi } from "../../apis";
import colors from "../../colors";

const { confirm } = Modal;

export default ({ message, onFinish }) => {
  const { translate } = useTranslate();
  const [action, setAction] = React.useState();

  const onPay = async () => {
    confirm({
      icon             : <ExclamationCircleOutlined />,
      content          : translate("system.confirmation.question", "Are you sure ?"),
      okText           : translate("system.yes", "Yes"),
      cancelText       : translate("system.no", "No"),
      okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
      className        : "btn-custom-class",
      async onOk() {
        let invoice = await paymentApi.create({
          objectType: "ADDITIONAL_FEE",
          objectId  : message.invoice._id
        });

        setAction(["method", {
          objectType: "INVOICE",
          objectId  : invoice._id,
          object    : invoice
        }]);
      },
    });
  };

  const onCancel = () => {
    confirm({
      icon             : <ExclamationCircleOutlined />,
      content          : translate("system.confirmation.question", "Are you sure ?"),
      okText           : translate("system.yes", "Yes"),
      cancelText       : translate("system.no", "No"),
      okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
      className        : "btn-custom-class",
      async onOk() {
        await paymentApi.cancel({
          objectType: "INVOICE",
          objectId  : message.invoice._id
        });

        onFinish(true);
      },
    });
  };

  return (
    <>
      <Container>
        <div className="header">
          <div className="title">
            {translate("messages.onwait", "Messages waiting for payment")}
            <Tooltip placement="right" overlayStyle={{ fontSize: 12 }} title="You have to pay additional messages fee before sending!" hover><QuestionCircleOutlined /></Tooltip>
          </div>
        </div>
        <div className="invoice">
          <div className="desc">{message.invoice.description}</div>
          <div className="action">
            <Button type="default" size="small" onClick={onCancel}>{translate("system.cancel", "Cancel")}</Button>
            <Button type="primary" size="small" onClick={onPay}>{translate("plan.payment.status", "Pay")}</Button>
          </div>
        </div>
        <div className="details">
          <div className="row">
            <div className="label">{translate("report.messagetitle", "Message:")}</div>
            <div className="value">{message.title}</div>
          </div>
          <div className="row">
            <div className="label">{translate("payment.quantity", "Send count:")}</div>
            <div className="value">{message.totals}</div>
          </div>
          <div className="row">
            <div className="label">{translate("plan.additional", "Additional message fee:")}</div>
            <div className="value">{message.invoice.payAmount}</div>
          </div>
        </div>
      </Container>

      <Modal
        maskClosable={false}
        title="Payment method"
        width={400}
        visible={action && action[0] === "method"}
        onCancel={() => setAction([])}
        footer={false}>
        <PaymentMethod onFinish={onFinish} payment={action && action[1]} />
      </Modal>
    </>
  );
};

const Container = styled.div`
  padding: 20px 24px;
  margin-bottom: 20px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
      font-weight: 500;
      .anticon {
        margin-left: 5px;
        cursor: pointer;
        font-size: 14px;
        color: #545454;
      }
    }
  }
  .invoice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    color: #00b900;
    background: #e0f7e0;
    border: 1px solid #00b900;
    border-radius: 2px;
    font-size: 13px;
    margin-top: 10px;
    .action {
      button {
        margin-left: 5px;
      }
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    .row {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
        flex: 1;
        padding: 4px 5px;
        &.label {
          justify-content: flex-end;
        }
      }
    }
  }
`;
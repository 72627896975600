import React from "react";
import { Checkbox, FormItem, Input, Radio, Select } from "formik-antd";
import { ErrorMessage } from "formik";
import { Collapse, Row, Col, Space, Button, Tag, Alert, Tooltip } from "antd";
import { useTranslate } from "../../../hooks";
import CooperationPreview from "../../../components/CooperationPreview";
import { Cooperation, CooperationSection, MessageContent, MessageToolbar, WriteMessage } from "./RakutenOrderForm";
import Modal from "antd/lib/modal/Modal";
import CouponList from "../../Coupon/SelectList";
import { coupon, ecm as ecmApi } from "../../../apis";
import moment from "moment";
import { ColorSchema } from "../../../components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import colors from "../../../colors";
import { QuestionCircleOutlined } from "@ant-design/icons";
const { Option } = Select;
const { TextArea } = Input;

export default ({ onReload, values, setFieldValue })=> {
  const [action, setAction] = React.useState();
  const { account } = useSelector(state => state.general);
  const { translate } = useTranslate();
  const history = useHistory();

  const onClearTaggedUrl = (setFieldValue, index, e) => {
    if (!e?.target?.value || e?.target?.value === ""){
      setFieldValue("coupon.isTagged", false);
      setFieldValue("coupon..tagType", 0);
      setFieldValue("coupon.taggedUrl", undefined);
    }
  };

  const couponDetail =(coupon)=> {
    if (Object.keys(coupon).length > 1){
      return (
        <div>
          <br/>
          <CooperationSection title={translate("coupon.image", "Coupon image")}>
            <FormItem name="couponImage">
              <Input disabled name="couponImage" value={coupon.image ? coupon.image : "-"}/>
            </FormItem>
          </CooperationSection>
          <CooperationSection title={translate("coupon.url", "Coupon url")}>
            <FormItem name="couponUrl">
              <Input disabled name="couponUrl" value={coupon.url ? coupon.url : "-"}/>
            </FormItem>
          </CooperationSection>
          <CooperationSection title={translate("coupon.schedule", "Schedule")}>
            <FormItem name="couposchedulenUrl">
              <Input name="schedule" style={{ display: "none" }}/>
              {coupon.schedules ? (
                <Tag color="green" style={{ fontSize: "16px" }}>{`${moment(coupon?.schedules[0]).format("YYYY-MM-DD HH:mm")} - ${moment(coupon?.schedules[1]).format("YYYY-MM-DD HH:mm")}`}</Tag>
              ):(
                <Tag color="green" style={{ fontSize: "16px" }}>----/--/-- 〜 ----/--/--</Tag>
              )}
            </FormItem>
          </CooperationSection>
        </div>
      );
    }
    return "";
  };
  const CountDown = React.forwardRef((props, ref) => {
    const [timer, setTimer] = React.useState(0);
    const { callback } = props;

    React.useEffect(() => {
      const interval = setInterval(() => {
        setTimer(prev => {
          if (prev === 0) {
            clearInterval(interval);
            return 0;
          }
          if (prev !== 0) {
            return prev - 1;
          }
          return prev;
        });
      }, 1000);
      return () => clearInterval(interval);
    }, [timer]);

    const startCount = async () => {
      setTimer(60);
    };

    React.useEffect(() => {
      if (timer >= 0) {
        callback(timer);
      }
    }, [timer]);

    React.useImperativeHandle(ref, () => ({
      async start(){
        startCount();
      }
    }));

    return (
      <div></div>
    );
  });
  const TaggedUrl = ({ setFieldValue }) => {
    const countDownRef = React.useRef();
    const [timer, setTimer] = React.useState(0);
    const [action, setAction] = React.useState([]);
    const { rakutentagTypes } = useSelector(state => state.general);
    const { translate } = useTranslate();
    const onCancel = () => {
      setAction([null]);
    };

    const onGetTaggedUrl = async () => {
      let res;
      countDownRef.current.start();
      try {
        res = await ecmApi.taggedUrl({
          type               : values.coupon.tagType,
          couponCode         : values.coupon?.code,
          additionalParameter: values.coupon?.additionalParameter,
          url                : values.coupon?.url,
        });
        setFieldValue("coupon.taggedUrl", res);
      } catch (err) {
        let error = {};
        if (err.message === "ECM_405") {
          error = {
            code   : "405",
            message: translate("action.form.failed.403", "Can't use this api with you Plan")
          };
        }
        else if (err.message === "ECM_HTTP_ERROR") {
          error = {
            code   : err.status_code,
            message: err.message
          };
        } else {
          error = {
            code   : err.message.code,
            message: err.message.message
          };
        }
        setAction(["error", error]);
      }
    };
    if (values.coupon.isTagged === true) {
      console.log("coupon_values=====>", values.coupon);
      return (
        <>
          <FormItem label={
            <div>
              {translate("action.form.tagtype", "Tag type")}
              {" "}
              <Tooltip placement="top" overlayInnerStyle={{ width: 700 }} overlayStyle={{ fontSize: 12, }} title={<div>
                <div>{translate("action.form.tooltip1", "Message type refers to each function of LINE Official Account.")}</div>
                <div>{translate("action.form.tooltip2", "By issuing a URL with a measurement tag for each message type, you can check the effect of attracting customers and sales by function on the report.")}</div>
                <div>{translate("action.form.tooltip3", "Usage:")}</div>
                <div>{translate("action.form.tooltip4", "・Normal message: Select this if the URL is used for LINE message delivery.")}</div>
                <div>{translate("action.form.tooltip5", "・Greeting message: Select this if the URL will be used for the greeting message automatically sent when the user adds the LINE official account as a friend.")}</div>
                <div>{translate("action.form.tooltip6", "・Rich menu: Select this if the URL is used for the rich menu that is fixed at the bottom of the LINE chat screen.")}</div>
                <div>{translate("action.form.tooltip7", "・Other: Select this if the URL is used for other functions such as timeline or chat.")}</div>
              </div>} hover><QuestionCircleOutlined />
              </Tooltip>
            </div>
          } name={"coupon.tagType"} required>
            <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }} >
              <Select name={"coupon.tagType"} placeholder={translate("system.select", "Select")}>
                {
                  rakutentagTypes.map((item, index) => {
                    return (<Option key={index} value={item.code}>{translate(`actionform.tagtype.${item.code}`, item.name)}</Option>);
                  })
                }
              </Select>
              <Button disabled={timer > 0 || values.coupon?.tagType === undefined} type="primary" onClick={onGetTaggedUrl}>
                {timer === 0 ? translate("actionform.geturl", "Get url"):(<div style={{ color: colors.primary }}>{timer}</div>)}
              </Button>
              <CountDown callback={(e) => setTimer(e)} ref={countDownRef} />
            </div>
          </FormItem>
          <FormItem label={translate("actionform.taggedurl", "Tagged link")} name={"coupon.taggedUrl"} required>
            <Input name={"coupon.taggedUrl"} style={{ cursor: "pointer", background: "#fff", color: "#000" }} disabled={true} placeholder="https://..." />
          </FormItem>
          {
            action && action[0] === "error" &&
            <Modal
              maskClosable={false}
              title={translate("action.form.failed.alert1", "Failed to get Rakuten tagged URL.")}
              visible={action && action[0] === "error"}
              destroyOnClose
              width={650}
              onCancel={onCancel}
              footer={false}>
              <Alert
                description={
                  action[1]?.code === "405"
                    ? (<div>{"Code : " }{action[1]?.code}, {"Message :"}{action[1]?.message}</div>)
                    : (<div>
                      <div>{"Code : " }{action[1]?.code}, {"Message :"}{action[1]?.message}</div>
                      <div> {translate("action.form.failed.alert2", "From EC Masters Club or EC Masters Tools check")}</div>
                      <div> {translate("action.form.failed.alert3", "・R-Login ID")}</div>
                      <div> {translate("action.form.failed.alert4", "・R-Loginパスワード")}</div>
                      <div> {translate("action.form.failed.alert5", "・Rakuten user ID")}</div>
                      <div> {translate("action.form.failed.alert6", "・Rakute user password")}</div>
                      <div> {translate("action.form.failed.alert7", "are up to date.")}</div>
                      <br/>
                      <div> {translate("action.form.failed.alert8", "Also, make sure that the R-Login user authority is the [Normal] Rakuten member ID.")}</div>
                      <div> {translate("action.form.failed.alert9", "After confirming, please update the EC Masters Club or EC Masters Tools settings.")}</div>
                      <div> {translate("action.form.failed.alert10", "(Please perform update even if there is no change as a result of checking)")}</div>
                      <br/>
                    </div>)
                }
                type="warning"
                showIcon={false}
              />
            </Modal>
          }
        </>
      );
    }
    return <div/>;
  };
  return (
    <div>
      <Cooperation>
        <CooperationPreview theme="light" onReload={onReload("success")} />
        <div className="content">

          <CooperationSection title={translate("cooperationform.header", "Header")}>
            <Row gutter={15}>
              <Col>
                <FormItem label={translate("cooperationform.textcolor", "Text color")} name="success.header.textColor">
                  <ColorSchema name="success.header.textColor" />
                </FormItem>
              </Col>
              <Col>
                <FormItem label={translate("cooperationform.backgroundcolor", "Background color")} name="success.header.backgroundColor">
                  <ColorSchema name="success.header.backgroundColor" />
                </FormItem>
              </Col>
            </Row>
          </CooperationSection>
          <CooperationSection title={translate("system.coupon", "Coupon")}>
            <FormItem name={"success.logo"}>
              <CouponList onChange={(e) => onClearTaggedUrl(setFieldValue, e)} name={"coupon.code" } schedules={"coupon.schedules"} image={"coupon.image"} urlField={"coupon.url"} />
            </FormItem>
            {
              ((()=>{
                if (values.coupon?.status === false) {
                  return <Alert style={{ marginTop: "16px", marginBottom: "16px" }} message={translate("coupon.alert.cooperation.failed.get", "Failet to get live coupon data from Rakuten")} type="warning" showIcon closable />;
                }
                return (<></>);
              })())
            }
            <ErrorMessage name="coupon.code" component="div" style={{ color: "red" }}></ErrorMessage>
            <FormItem style = {{ marginTop: "10px" }} name={"coupon.additionalParameter"} label={translate("imagemap.additional.parameter", "Additional Parameter")} >
              <Input name={"coupon.additionalParameter"} placeholder={translate("imagemap.additional.parameter.placeholder", "Additional Parameter")} />
            </FormItem>
          </CooperationSection>
          <CooperationSection>
            <FormItem name={"coupon.isTagged"} required>
              <Checkbox disabled={!values.coupon?.code || !account?.ecmid} name={"coupon.isTagged"}>{
                <div>
                  { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                  {" "}
                  <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                  </Tooltip>
                </div>
              }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
            </FormItem>
          </CooperationSection>
          <CooperationSection>
            <TaggedUrl setFieldValue={setFieldValue} />
          </CooperationSection>
          {couponDetail(values?.coupon)}
          <CooperationSection title={translate("cooperationform.description", "Description")}>
            <WriteMessage>
              <MessageToolbar>
                <Space className="left">
                  <Radio.Group name="success.description.type">
                    <Radio.Button value="text">{translate("cooperationform.text", "Text")}</Radio.Button>
                    <Radio.Button value="html">{translate("cooperationform.html", "Html")}</Radio.Button>
                  </Radio.Group>
                </Space>
              </MessageToolbar>
              <MessageContent>
                {(() => {
                  switch (values.success.description.type) {
                    case "text":
                      return (
                        <FormItem name="success.description.text" required>
                          <TextArea name="success.description.text" rows={4} />
                        </FormItem>
                      );
                    case "html":
                      return (
                        <FormItem name="success.description.html" required>
                          <TextArea name="success.description.html" rows={4} />
                        </FormItem>
                      );
                    default:
                  }
                })()}

              </MessageContent>
            </WriteMessage>
          </CooperationSection>
        </div>
      </Cooperation>
    </div>
  );
};
import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Form, Input, FormItem, Select } from "formik-antd";
import { Formik } from "formik";
import { Button, Form as AntForm } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "../../../hooks";

export default ({ nextPage }) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { serviceTypes } = useSelector(state => state.general);
  const staffName = useSelector(state => state.register.staffName);
  const onSubmit = async (data) => {
    dispatch({
      type   : "register/verify/ContactInfo",
      payload: data
    });
    nextPage();
  };
  const [data, setFormData] = React.useState({
    staffBranch  : undefined,
    staffPosition: undefined,
    staffName    : undefined
  });
  const FormSchema = Yup.object().shape({
    staffBranch  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    staffPosition: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    staffName    : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  });

  const { Option } = Select;

  React.useEffect(() => {
    setFormData({ ...data, staffName });
  }, []);
  return (
    <StepOneWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical" style={{ width: "100%" }}>
            <FormItem label={<label className="form-input-title">{translate("useredit.name", "Name")}</label>} name="staffName" required>
              <Input className="form-input" name="staffName" placeholder={translate("useredit.name", "Name")} />
            </FormItem>
            <FormItem label={<label className="form-input-title">{translate("useredit.branch", "Name of branch office")}</label>} name="staffBranch" required>
              <Input className="form-input" name="staffBranch" placeholder={translate("useredit.branch", "Name of branch office")} />
            </FormItem>
            <FormItem label={<label className="form-input-title">{translate("useredit.position", "Position held")}</label>} name="staffPosition" required>
              <Input className="form-input" name="staffPosition" placeholder={translate("useredit.position_placeholder", "Position held")}/>
            </FormItem>
            <AntForm.Item>
              <Button className="form-button" htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.confirm", "Confirm")}</Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </StepOneWrapper>
  );
};

const StepOneWrapper = styled.div`
     display : flex;
     justify-content : center;
`;
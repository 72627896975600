import React from "react";
import styled from "styled-components";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";

export default ({ onEditNote, note }) => {
  return (
    <Note >
      <div>
        <div className="color" />
      </div>
      <div className="content" >
        <div className="head">
          <div className="date">
            {moment(note.createdAt).format("YYYY-MM-DD HH:mm")}
          </div>
          <a href="#" onClick={() => onEditNote(note)}><EditOutlined /></a>
        </div>
        <div className="body">{note.body}</div>
      </div>
    </Note>
  );
};

const Note = styled.div`
  position: relative;
  padding: 8px;
  padding-top : 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #ddd;
  .color {
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 8px;
    background: white;
  }
  .content {
    display: flex;
    flex-direction: column;
    .head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .date {
        display: flex;
        font-size: 14px;
      }
      button {
        display: flex;
      }
    }
    .body {
      font-size: 13px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`;

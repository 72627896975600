import React from "react";
import { auth } from "../../apis";
import { Card, message, Steps, Switch } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AuthWrapper } from "../../components/Design";
import Logo from "../../assets/logo_green.png";
import DisplayImageLeft from "../../assets/display_image_left.png";
import DisplayImageRight from "../../assets/display_image_right.png";
import CloudImage from "../../assets/cloud.png";
import { StepOne, StepTwo, StepThree } from "./registerStaff";
import { useTranslate } from "../../hooks";
import { useDispatch } from "react-redux";
const { Step } = Steps;

export default () => {
  const { translate, changeLanguage, lang } = useTranslate();
  const match = useRouteMatch();
  const [current, setCurrent] = React.useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);

  const [data, setFormData] = React.useState({
    password       : undefined,
    confirmPassword: undefined,
    branchName     : undefined,
    name           : undefined,
    companyName    : undefined
  });

  const nextPage = () =>{
    setCurrent(current + 1);
  };

  const reload = React.useCallback(async (signal) => {
    setLoading(true);
    try {
      let res = await auth.checkStaff(match.params.token, { signal });
      setFormData({
        ...data,
        ...res
      });
      setLoading(false);
      // dispatch({
      //   type   : "register/verify/CompanyInfo",
      //   payload: res
      // });
    } catch (error)
    {
      if (error.message === "ALREADY_REGISTERED")
      {
        history.push({
          pathname: "/login",
          state   : { alert: true }
        });
      }
      if (error.message === "VERIFY_TOKEN_EXPIRED")
      {
        history.push({
          pathname: "/login"
        });
        message.error(translate("system.token.expired", "Verify token expired"));
      }
      if (error.message === "INVALID_VERIFY_TOKEN")
      {
        history.push({
          pathname: "/login"
        });
        message.error(translate("system.invalid.token", "Invalid verify token"));
      }
    }

  }, [match.params.token]);


  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  const steps = [{
    title  : <span className="stepper-title" ></span>,
    content: <StepOne nextPage={nextPage} action={["update", data]} setFormData={setFormData} />,
    index  : 0
  }, {
    title  : <span className="stepper-title" ></span>,
    content: <StepThree nextPage={nextPage} editable={["update", data]}/>,
    index  : 1
  }];

  return (
    <AuthWrapper style={{ paddingTop: "91px" }}>
      {/* <img className="display-image-left" style={{ position: "fixed", left: "0", bottom: "0" }} src={DisplayImageLeft} />
      <img className="display-image-right" style={{ position: "fixed", right: "0", bottom: "0" }} src={DisplayImageRight} /> */}
      <Card className="login" >
        <div>
          <img className="form-image-cloud" src={CloudImage} />
        </div>
        <div className="logo-wrapper">
          <img className ="logo-image" src={Logo}/>
        </div>
        <div className="logo">
          <h2 className="form-title-one-text"></h2>
        </div>
        <div className="stepper-wrapper">
          <div className="stepper">
            <Steps current={current} className = "stepper-gustom">
              {steps.map(item => (
                <Step className="stepper-title" key={item.title} subTitle={item.title} />
              ))}
            </Steps>
          </div>
        </div>
        <div className="stepper-content-wrapper">
          {!loading&&(<div className="stepper-content">{steps[current].content}</div>)}
        </div>
        <Switch checkedChildren="JP" unCheckedChildren="EN" onChange={() => changeLanguage(lang === "EN" ? "JP" : "EN") } defaultChecked={lang === "EN"} />
      </Card>
    </AuthWrapper>
  );
};
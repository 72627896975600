import React from "react";
import { useParams } from "react-router-dom";
import { message } from "../../apis";
// import moment from "moment";
import Form from "./Form";
import { useDispatch } from "react-redux";

export default () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [editable, setEditable] = React.useState([]);

  const reload = React.useCallback(
    async (signal) => {
      let res = await message.get(params.id, { signal });

      const payload = {
        ...res,
        regular    : res?.regular && res?.regular?._id,
        regularName: res?.regular && res?.regular?.name,
        segments   : res.targets,
        children   : res.children.map(child => ({
          ...child,
          template         : child.template && child.template._id,
          templateValues   : child.template,
          cooperation      : child.cooperation && child.cooperation._id,
          cooperationValues: child.template,
          imagemap         : child.imagemap && child.imagemap._id,
          imagemapValues   : child.imagemap,
          carousel         : child.carousel && child.carousel._id,
          carouselValues   : child.carousel
        })),
        // tags    : res.tags.map(item => item.tag),
        // deleteds: res.deleteds,
        // values  : res.tags.map(item => {
        //   let value = item.value;

        //   if (item.dates.length > 0)
        //     value = [moment(item.dates[0]), moment(item.dates[1])];

        //   if (item.numbers.length > 0)
        //     value = item.numbers;

        //   if (item.checked !== undefined)
        //     value = item.checked;

        //   return {
        //     tag  : item.tag,
        //     value: value
        //   };
        // })
      };

      setEditable(["update", payload]);

      dispatch({
        type   : "message.message.data",
        payload: payload
      });

      setLoading(false);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (<>{!loading && <Form editable={editable} />}</>);
};

import request from "../utils/request";

export const list = (type) => (data) => request.post(`/api/message/${type}/list`, data);
export const target = (data) => request.post("/api/message/target", data);
export const create = (data) => {
  console.log("# # # # # # # # # # # # # # # # # # # # # # # # # # #");
  console.log(JSON.stringify(data));
  console.log("# # # # # # # # # # # # # # # # # # # # # # # # # # #");
  return request.post("/api/message", data);
};
export const testSend = (data) => {
  console.log("# # # # # # # # # # # # # # # # # # # # # # # # # # #");
  console.log(JSON.stringify(data));
  console.log("# # # # # # # # # # # # # # # # # # # # # # # # # # #");
  return request.post("/api/message/send_test", data);
};
export const update = (data) => request.put(`/api/message/${data._id}`, data);
export const remove = (id) => request.del(`/api/message/${id}`);
export const get = (id) => request.get(`/api/message/${id}`);
export const sentDetail = (id) => request.get(`/api/message/sent/${id}`);

import React from "react";
import { useDispatch } from "react-redux";
import { INIT_RICHMENU } from "./components/Form";
import Form from "./Form";
export default () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({ type: "general.richmenuAlias", payload: [INIT_RICHMENU, INIT_RICHMENU] });
  }, []);

  return (
    <div>
      <Form editable={["create"]}/>
    </div>
  );
};

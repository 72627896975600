import React, { useState } from "react";
import { Form, Input, FormItem, Checkbox } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { Row, Col } from "antd";
import * as Yup from "yup";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { formItemLayout } from "../../utils";

import CardNumber from "./components/CardNumber";
import CardExpiry from "./components/CardExpiry";
import CardHolder from "./components/CardHolder";
import CardCVV from "./components/CardCVV";

const FormSchema = (translate) => Yup.object().shape({
  holderName: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  cardNumber: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  expiryDate: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  cvv       : Yup.string().required(translate("system.message.required", "Please fill out this field!"))
});

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values, resetForm } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        return false;
      }

      return values;
    },
    clearForm() {
      resetForm();
    }

  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const submitRef = React.useRef();
  const [data] = useState({
    holderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv       : "",
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    },
    clear() {
      submitRef.current.clearForm();
    }
  }));

  return (
    <Container>
      <Formik
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={() => { }}>
        {(formProps) => (
          <Form layout="vertical">
            <Form.Item name="cardNumber" label={translate("card.number", "Card number")} required>
              <CardNumber name="cardNumber" placeholder="45** **** **33 4534" />
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="expiryDate" label={translate("card.expire", "Expire date")} required>
                  <CardExpiry name="expiryDate" placeholder="MM/YY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="cvv" label={translate("card.cvv", "CVV")} required>
                  <CardCVV name="cvv" placeholder="***" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="holderName" label={translate("card.holder", "Card holder")} required>
              <CardHolder key="holderName" name="holderName" placeholder={translate("card.holder", "Card holder's name")} />
            </Form.Item>
            <SubmitForm ref={submitRef} formProps={formProps}/>
          </Form>
        )}
      </Formik>
    </Container>
  );
});
const Container = styled.div`
  .ant-form-item {
    margin-bottom: 0!important;
    .ant-form-item-label {
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 13px;
    }
    .ant-form-item-control {
      margin-bottom: 10px;
    } 
  }
`;

import React from "react";
import styled from "styled-components";
import EmojiContents from "./Emoji";
import TextArea from "antd/lib/input/TextArea";
import parse from "html-react-parser";
import { usePermission, useTranslate } from "../../../../hooks";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import emoji from "emoji-aware";
import { Tabs, message } from "antd";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";

const DEFAULT_EMOJI = String.fromCodePoint(9940);
const { TabPane } = Tabs;

export default ({ sendMessage, action, value = "", onAction, characters: values, onChangeValues, onMouseDown }) => {
  const textAreaRef = React.useRef();
  const overlayRef = React.useRef();
  const [UNDO_HISTORIES, setUndoHistories] = React.useState([]);
  const [REDO_HISTORIES, setRedoHistories] = React.useState([]);
  const [LAST_UNDO, setLastUndo] = React.useState();
  const [LAST_REDO, setLastRedo] = React.useState();
  const [UNDO_LENGTH, setUndoLength] = React.useState();
  const { emojis } = useSelector(state => state.general);
  const [characters, setCharacters] = React.useState(values && values.length > 0 ? values : value.split(""));
  const [cursor, setCursor] = React.useState(null);
  const [cursorEnd, setCursorEnd] = React.useState(null);
  const [scrollTop, setScrollTop] = React.useState(null);
  const [size, setSize] = React.useState({ width: "100%", height: "100px" });
  const { checkRole } = usePermission();
  const { translate } = useTranslate();

  const findAltEmoji = (values) => {
    let start = null;
    let end = null;

    // [[] []] [[] []]

    let CHARACTERS = [];

    values.forEach((char, index) => {
      if (char === "[" && values[index - 1] !== "[") {
        start = index;
        end = null;
      } else if (start !== null && char === "]" && values[index + 1] !== "]") {
        end = index;

        let altText = values.slice(start + 1, end).join("");
        let emoji = emojis.find(e => e.altText === altText);

        if (emoji) {
          CHARACTERS.push({
            productId: emoji.productId,
            emojiId  : emoji.emojiId,
            altText  : emoji.altText
          });
        } else {
          start = CHARACTERS.slice(0, index);
          end = CHARACTERS.slice(index + 1, characters.length);

          CHARACTERS = [...start, ...`[${altText}]`.split(""), ...end];
        }

        start = null;
        end = null;
      } else if (start === null) {
        if (char.length > 1)
          CHARACTERS.push([char === "☹️" ? "🙁" : char]);
        else
          CHARACTERS.push(char);
      }
    });

    if (start && !end) {
      let altText = value.slice(start, values.length);
      CHARACTERS = CHARACTERS.concat(altText);
    }

    return CHARACTERS;
  };

  const onSubmit = (e) => {
    if (sendMessage && characters.length > 0 && e.which === 13) {
      if (e.shiftKey === false) {
        if (characters.filter(f => f !== "\n").length > 0) {
          sendMessage(parseMessage());
          setCharacters([]);

          e.preventDefault();
        } else {
          setCharacters([]);
          e.preventDefault();
        }
      }
    }
  };

  const onKeyDown = (e) => {
    if (e.which === 90 && e.shiftKey) {
      e.preventDefault();

      let redo = REDO_HISTORIES[0 + (LAST_REDO ? 0 : 1)];

      if (redo) {
        let changes = [redo];

        if (LAST_UNDO)
          changes.push(LAST_UNDO);

        let start = redo[1];
        let end = redo[2];

        setUndoHistories([...changes, ...UNDO_HISTORIES].slice(0, 100));
        setRedoHistories(REDO_HISTORIES.slice(1 + (LAST_REDO ? 0 : 1), 100));

        setLastRedo(redo);
        setLastUndo(null);

        setCursor(start);
        setCursorEnd(end);
        setCharacters(redo[0]);
      }
    } else if (e.which === 90) {
      e.preventDefault();

      let undo = UNDO_HISTORIES[0 + (LAST_UNDO ? 0 : 1)];

      if (undo) {
        let changes = [undo];

        if (LAST_REDO)
          changes.push(LAST_REDO);

        if (!LAST_UNDO)
          changes.push([characters, cursor, cursorEnd]);

        let start = undo[1];
        let end = undo[2];

        // console.log("* * * * * * * * * * * * * * * * * * * * * * * * *");
        // console.log("Undo: ", start, end);
        // console.log("* * * * * * * * * * * * * * * * * * * * * * * * *");
        // console.log(textAreaRef.current.resizableTextArea.textArea.scrollTop);

        setRedoHistories([...changes, ...REDO_HISTORIES].slice(0, UNDO_LENGTH));
        setUndoHistories(UNDO_HISTORIES.slice(1 + (LAST_UNDO ? 0 : 1), 100));

        setLastUndo(undo);
        setLastRedo(null);

        setCursor(start);
        setCursorEnd(end);
        setCharacters(undo[0]);
      } else if (characters.length === 1) {
        setCharacters([]);
      }
    }

    // setScrollTop(textAreaRef.current.resizableTextArea.textArea.scrollTop);
  };

  const onResize = (e) => {
    setSize({
      width : `${e.width}px`,
      height: `${e.height}px`
    });
  };

  const onChange = (e) => {
    let value = e.target.value;

    if (value.length > 1000) {
      message.error(translate("system.message.exceeded", "Character limit has been exceeded!"));
      return;
    }

    let values = emoji.split(value);
    // console.log("values: ", values);

    values = findAltEmoji(values);
    // console.log("convert: ", values);

    let CURSOR = textAreaRef.current.resizableTextArea.textArea.selectionStart;
    let length = values.slice(CURSOR, values.length).length;

    let start = values.slice(0, CURSOR);
    let end = values.slice(values.length - length, values.length);

    // console.log("start: ", start);
    // console.log("end: ", end);

    let CHARACTERS = [...start, ...end];
    // console.log("merge: ", CHARACTERS);


    let _characters = characters;

    if (CHARACTERS.length < characters.length) {
      _characters = [..._characters.slice(0, CURSOR), ..._characters.slice(CURSOR + (characters.length - CHARACTERS.length), _characters.length)];
    }

    let array = values.reduce((accumulator, iterator, index) => {
      if (iterator === DEFAULT_EMOJI) {
        return [...accumulator, CHARACTERS[index]];
      }

      return [...accumulator, iterator];
    }, []);

    // console.log("* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *");
    // console.log(array);
    // console.log("* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *");

    let OLD_CHARACTERS = _characters.filter(f => !(typeof f === "string" || f instanceof String));

    // console.log("OLD_CHARACTERS: ", OLD_CHARACTERS);

    array = array.reduce((accumulator, iterator) => {
      let character = iterator;

      if (character === DEFAULT_EMOJI) {
        character = OLD_CHARACTERS.shift();
      }

      return [...accumulator, character];
    }, []);

    if (array.length > 1000) {
      message.error(translate("system.message.limit", "Cannot send more than 1000 characters!"));
      return;
    }

    let exists = array.filter(e => e && e.productId && e.emojiId);

    if (exists.length > 20) {
      message.error(translate("system.message.emoji.limit", "Cannot send more than 20 characters!"));
    } else {
      let start = cursor + (array.length - characters.length);

      if (characters.length > array.length)
        start = textAreaRef.current.resizableTextArea.textArea.selectionStart;

      let end = start + Math.abs((array.length - characters.length));
      let changes = [[array, start, end]];

      // console.log("* * * * * * * * * * * * * * * * * * * * * * * * *");
      // console.log("Change: ", start, end);
      // console.log("* * * * * * * * * * * * * * * * * * * * * * * * *");

      if (LAST_UNDO)
        changes.push(LAST_UNDO);

      if (LAST_REDO)
        changes.push(LAST_REDO);

      let histories = [...changes, ...(UNDO_HISTORIES)].slice(0, 100);

      setUndoHistories(histories);
      setRedoHistories([]);
      setLastUndo(null);
      setLastRedo(null);
      setUndoLength(histories.length);

      setCursor(start);
      setCursorEnd(start);
      setCharacters(array);
      setScrollTop(textAreaRef.current.resizableTextArea.textArea.scrollTop);
    }
  };

  const onClipboard = async () => {
    let start = textAreaRef.current.resizableTextArea.textArea.selectionStart;
    let end = textAreaRef.current.resizableTextArea.textArea.selectionEnd;

    let copies = characters.slice(start, end);

    copies = copies.reduce((accumulator, iterator) => {
      if (!iterator) return accumulator;

      let character = iterator;

      if (!(typeof iterator === "string" || iterator instanceof String) && iterator && iterator.altText)
        character = `[${iterator.altText}]`;
      else if (!(typeof iterator === "string" || iterator instanceof String) && iterator[0])
        character = iterator[0];

      return accumulator + character;
    }, "");

    await navigator.clipboard.writeText(copies);
  };

  const onScroll = () => {
    overlayRef.current.scrollTop = textAreaRef.current.resizableTextArea.textArea.scrollTop;
  };

  const renderEmoji = (emoji) => {
    if (!emoji) return;
    if (emoji.productId && emoji.emojiId)
      return `<span style='background: url("/emoji/${emoji.productId}/${emoji.emojiId}.png") center center / cover no-repeat; color: transparent;'>${String.fromCodePoint(9940)}</span>`;
    return `${emoji[0]}`;
  };

  const renderCharacters = () => {
    let html = characters.reduce((accumulator, iterator) => {
      let character = iterator;

      if (!(typeof iterator === "string" || iterator instanceof String))
        character = renderEmoji(iterator);

      return accumulator + character;
    }, "");

    return parse(html);
  };

  const renderValues = () => {
    return characters.reduce((accumulator, iterator) => {
      if (typeof iterator === "string" || iterator instanceof String) {
        return accumulator + iterator;
      }

      return accumulator + DEFAULT_EMOJI;
    }, "");
  };

  const onChangeEmoji = ({ emoji }) => {
    setScrollTop(textAreaRef.current.resizableTextArea.textArea.scrollTop);

    let CURSOR = textAreaRef.current.resizableTextArea.textArea.selectionStart;
    let start = characters.slice(0, CURSOR);
    let end = characters.slice(CURSOR, characters.length);
    let array = [...start, [emoji === "☹️" ? "🙁" : emoji], ...end];

    setCursor(CURSOR + 1);
    setCursorEnd(CURSOR + 1);
    setCharacters(array);

    let histories = [[array, CURSOR + 1, CURSOR + 1], ...UNDO_HISTORIES].slice(0, 100);
    setUndoHistories(histories);
    setUndoLength(histories.length);
    setLastUndo(null);
    setLastRedo(null);
  };

  const onChangeLineEmoji = (emoji) => {
    let array = characters.filter(e => e && e.productId && e.emojiId);

    if (array.length + 1 > 20) {
      message.error(translate("system.message.emoji.limit", "Cannot send more than 20 characters!"));
      return;
    }

    setScrollTop(textAreaRef.current.resizableTextArea.textArea.scrollTop);

    let CURSOR = textAreaRef.current.resizableTextArea.textArea.selectionStart;
    let start = characters.slice(0, CURSOR);
    let end = characters.slice(CURSOR, characters.length);
    array = [...start, emoji, ...end];

    setCursor(CURSOR + 1);
    setCursorEnd(CURSOR + 1);
    setCharacters(array);

    let histories = [[array, CURSOR + 1, CURSOR + 1], ...UNDO_HISTORIES].slice(0, 100);
    setUndoHistories(histories);
    setUndoLength(histories.length);
    setLastUndo(null);
    setLastRedo(null);
  };

  const parseMessage = (array = characters) => {
    return {
      text: array.reduce((accumulator, iterator) => {
        if (!iterator) return accumulator;

        if (typeof iterator === "string" || iterator instanceof String)
          return accumulator + iterator;
        if (typeof iterator[0] === "string" || iterator[0] instanceof String)
          return accumulator + iterator[0];

        if (iterator && iterator.altText)
          return accumulator + `[${iterator.altText}]`;

        return accumulator;
      }, ""),
      characters: array,
      type      : "text"
    };
  };

  React.useEffect(() => {
    if (cursor !== null) {
      textAreaRef.current.resizableTextArea.textArea.focus();
      textAreaRef.current.resizableTextArea.textArea.selectionStart = cursor;

      if (cursorEnd !== null)
        textAreaRef.current.resizableTextArea.textArea.selectionEnd = cursorEnd;

      textAreaRef.current.resizableTextArea.textArea.scrollTop = scrollTop;

      if (LAST_UNDO || LAST_REDO) {
        textAreaRef.current.resizableTextArea.textArea.blur();
        textAreaRef.current.resizableTextArea.textArea.focus();
      }

      if (onChangeValues)
        onChangeValues(parseMessage());
    }
  }, [characters]);


  React.useEffect(()=> {
    if (values !== undefined){
      setCharacters(values);
    } else {
      setCharacters([]);
    }
  }, [value]);

  return (
    <EmojiContainer>
      <Tabs style={{ display: action }} defaultActiveKey="message.emoji.line" >
        <TabPane tab={translate("message.emoji.line", "LINE Emoji")} key="1">
          <EmojiContents onClick={onChangeLineEmoji} />
        </TabPane>
        <TabPane tab={translate("message.emoji", "Emoji")} key="message.emoji">
          <EmojiPickerWrapper style={{ display: "" }}>
            <EmojiPicker style={{ width: "100%" }} showPreview={false} onSelect={(emoji) => onChangeEmoji({ emoji: emoji.native })} />
          </EmojiPickerWrapper>
        </TabPane>
      </Tabs>

      {onAction && <a className="close" onClick={() => onAction && onAction("none")}><CloseOutlined /></a>}

      <TextAreaContainer>
        <TextArea
          disabled={!checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && !checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "SUPPORT"])}
          ref={textAreaRef}
          className="editor-textarea text-break-all scroll-all"
          value={renderValues()}
          placeholder={translate("privatechat.placeholder", "Type something to send...")}
          onScroll={onScroll}
          onCopy={onClipboard}
          onCut={onClipboard}
          onChange={onChange}
          onMouseDown={onMouseDown}
          onKeyPress={onSubmit}
          onKeyDown={onKeyDown}
          onResize={onResize}
          onSelect={(e) => {
            let start = e.target.selectionStart;
            let end = e.target.selectionEnd;

            // console.log("Last undo: ", LAST_UNDO);
            // console.log("Selection: ", start, end);

            if (UNDO_HISTORIES.length > 0 && !LAST_UNDO) {
              setUndoHistories([[UNDO_HISTORIES[0][0], start, end], ...UNDO_HISTORIES.slice(1, 100)]);
            }

            if (LAST_UNDO) {
              setLastUndo([LAST_UNDO[0], start, end]);
            }

            if (!LAST_UNDO) {
              setCursor(start);
              setCursorEnd(end);
            }
          }}
        />
        <div ref={overlayRef} className="editor-overlay text-break-all scroll-all" style={{ height: size.height }}>
          {renderCharacters()} <span>|</span>
        </div>
      </TextAreaContainer>
    </EmojiContainer>
  );
};
const EmojiContainer = styled.div`
  position: relative;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #333;
    font-size: 15px;
  }
`;
const EmojiPicker = styled(Picker)`
  section {
    .emoji-mart-bar {
      display :none;
    }
    .emoji-mart-category-list {
      button[aria-label="☹️, white_frowning_face"] {
        display: none!important;
      }
    }
  }
`;
const EmojiPickerWrapper = styled.div`
  aside.emoji-picker-react {
    width: 100% !important;
    height: 300px !important;
  }
`;
const TextAreaContainer = styled.div`
  position: relative;
  min-height: 100px;
  border: 1px solid #d9d9d9;
  .text-break-all {
    font-family: inherit!important;
    font-size: 15px!important;
    font-weight: normal!important;
    line-height: normal!important;
    letter-spacing: -0.4px!important;
    word-break: break-all!important;
    overflow-wrap: anywhere!important;
    white-space: pre-wrap!important;
    padding: 5px!important;
    border: none!important;
  }
  .scroll-all {
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    } 
  }
  .editor-textarea {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    min-height: 100px;
    background-color: transparent;
    caret-color: #353a40;
    color: transparent!important;
    user-select: none !important;
    white-space: pre-wrap;
    ::selection {
      color: transparent;
      background: #009d4b4f;
    }
  }
  .editor-overlay {
    width: 100%;
    pointer-events  : none;
    color           :black;
    overflow-y      : auto;
    background-color: white;
    span {
      color :transparent;
    }
  }
`;
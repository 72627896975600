import React from "react";
import { Card, Spin } from "antd";
import styled from "styled-components";
import colors from "../../../colors";
import { useSelector } from "react-redux";

const { Meta } = Card;

export default ({ values }) => {
  const { cooperationTypes, lang = "en" } = useSelector(state => state.general);

  if (!values || !values.type || !lang)
    return (
      <Spin spinning={true}>
        <CooperationContainer>
          <Cooperation></Cooperation>
        </CooperationContainer>
      </Spin>
    );

  let cooperation = cooperationTypes.find((type) => {
    let code = type[`${lang}`.toLowerCase()].code;
    return `${code}`.toLowerCase() === `${values.type}`.toLowerCase();
  })[lang.toLowerCase()];

  let Icon = require("@ant-design/icons")[cooperation.icon];

  return (
    <CooperationContainer>
      <Cooperation>
        <Meta
          avatar={<Icon size={22} />}
          title={cooperation.name}
          description={cooperation.description}
        />
      </Cooperation>
    </CooperationContainer>
  );
};
const Cooperation = styled(Card)`
  .anticon {
    font-size: 22px;
    color: ${colors.primaryDark};
  }
  .ant-card-meta-title {
    font-size: 17px;
    color: ${colors.primaryDark};
  }
  .ant-card-meta-description {
    color: #9a9a9a;
  }
`;
const CooperationContainer = styled.div`
  .ant-upload {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
    color: #9a9a9a;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 25px 0;
  border: 1px dashed #ddd;
  min-height: 160px;
  cursor: pointer;
  border-radius: 4px;
  .anticon {
    font-size: 22px;
    color: #607D8B;
  }
  &:hover {
    .upload-text {
      color: ${colors.primaryDark};
    }
  }
  .upload-text {
    font-size: 13px;
    margin-top: 10px;
    transition: all .2s;
    font-weight: 400;
  }
`;
import request from "../utils/request";

export const create = (data) => request.post("/api/richmenu", data);
export const setDefault = (id) => request.post(`/api/richmenu/${id}/default`);
export const cancel = () => request.get("/api/richmenu/cancel");
export const update = (data) => request.put(`/api/richmenu/${data._id}`, data);
export const copy = (data) => request.post("/api//richmenu/copy", data);
export const list = (data) => request.post("/api/richmenu/list", data);
export const remove = (id) => request.del(`/api/richmenu/${id}`);
export const get = (id) => request.get(`/api/richmenu/${id}`);



export const createAlias = (data) => request.post("/api/richmenu_alias", data);
export const updateAlias = (data) => request.put(`/api/richmenu_alias/${data._id}`, data);


import React from "react";
import styled from "styled-components";

export default ({ color, selected }) => {
  const [selectColor, setSelectColor] = React.useState(1);
  const ChooserColor = styled.div`
  .colorObject {
    margin  :0;
    padding :0;
    border-radius   : 20px;
    height          : 25px;
    width           : 25px;
    border          : solid ${selectColor}px;
    border-color    : white;
    background-color: ${color};
    /* margin-left     : 5px; */
  }
  .colorObject:hover {
    border : solid 2px;
    border-color : white;
  }`;

  React.useEffect(()=>{
    if (selected === true){
      setSelectColor(4);
    }
    else
      setSelectColor(1);
  }, [selected]);

  return (
    <ChooserColor>
      <div className="colorObject"></div>
    </ChooserColor>
  );
};




import React from "react";
import * as Yup from "yup";
import { useTranslate } from "../../hooks";
import { Form, Select, Input, FormItem, Checkbox } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import TaggedForm from "./components/TaggedForm";
import styled from "styled-components";
import { ManualAlert } from "../../components/Manual";
import { Alert } from "antd";

const { Option } = Select;
const FormSchema = (translate) => Yup.object().shape({
  description: Yup.string().required(translate("system.message.required", "Please fill out this field!")),

});

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { action, onCancel, taggedRef } = props;
  const { translate } = useTranslate();
  const submitRef = React.useRef();

  const [data, setData] = React.useState({
    tags       : undefined,
    description: undefined,
    view       : {
      type: "compact"
    },
    features            : undefined,
    permanentLinkPattern: undefined,
    ...(action && action[0] === "update" ? action[1] : {})
  });

  const onSubmit = async (data) => {};

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let values = await submitRef.current.submitForm();
      return values;
    },
  }));

  return (
    <Container>
      <Formik enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form layout="vertical">
              <Alert style={{ marginBottom: 5 }}type="warning" message={
                <div>
                  {translate("liff.form.alert", "You can not use LINE as name value in it!")}
                </div>
              } />
              {/* <FormItem name="view.type" label={translate("liff.view.type", "Size")}>
                <Select name="view.type">
                  <Option value="full">{translate("liff.view.full", "Full")}</Option>
                  <Option value="tall">{translate("liff.view.tall", "Tall")}</Option>
                  <Option value="compact">{translate("liff.view.compact", "Compact")}</Option>
                </Select>
              </FormItem> */}
              <FormItem name="description" label={translate("liff.title", "Title")}>
                <Input name="description" />
              </FormItem>

              <TaggedForm ref={taggedRef} editable={{ ...(action[0] && action[1]), targetType: "TAGS" }} />

              <SubmitForm ref={submitRef} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});

const Container = styled.div`
  .custom-divider { 
    border: solid 1px; 
    margin: 10px 0px;
    border-radius: 13px;
    padding: 11px;
    border-color: #ececec;
  }
  .custom-divider-title {
    font-size: 16px;
    font-weight: 400;
  }
  .ant-col-sm-16{
    max-width: 100% !important;
  }
`;
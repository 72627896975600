import React from "react";
import styled from "styled-components";

export default ({ src }) => {
  return (
    <ImageContainer>
      <img src={src} />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  /* height: 100px;
  overflow: hidden; */
  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #9096a5;
  border-radius: 6px;
  img {
    display: flex;
    width: 100%;
    border-radius : 5px;
  }
`;
import request from "../utils/request";

export const logs = (data) => request.post("/api/operation/logs", data);
export const reports = (data) => request.post("/api/operation/reports", data);
export const webhook = (data) => request.post("/api/operation/notify", data);
export const totals = (data) => request.get("/api/operation/totals", data);

export const exportFile = (data) => request.post("/api/operation/report/export", data);
export const broadcast = (data) => request.post("/api/operation/broadcast", data);
export const broadcastDetail = (id) => request.get(`/api/operation/broadcast/${id}`);
export const multicastDetail = (id) => request.get(`/api/operation/multicast/${id}`);
export const multicast = (data) => request.post("/api/operation/multicast", data);

export const carouselMulticastDetail = (id) => request.get(`/api/operation/multicast_carousel/${id}`);

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { template } from "../../apis";
import Form from "./Form";
import { useTranslate } from "../../hooks";
import { Spin } from "antd";

export default () => {
  const { translate } = useTranslate();
  const params = useParams();
  const [action, setAction] = React.useState();

  const reload = React.useCallback(async (signal) => {
    let res = await template.get(params.id, { signal });

    setAction(["update", {
      ...(res && res.content),
      _id       : res && res._id,
      targetType: res.targetType,
      tags      : res.tags.map(t => t.tag),
      deleteds  : res.deleteds,
      link      : res.link,
      values    : res.tags.map(t => {
        return {
          tag  : t.tag,
          value: t.value
        };
      })
    }]);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <Spin spinning={!action}>
      {action && <Form action={action} />}
    </Spin>
  );
};

import React from "react";
import moment from "moment";
import { Tag, Badge, Avatar } from "antd";
import renderHTML from "react-render-html";

const renderLastChat = (message) => {
  if (message.type === "sticker")
    return <img width="35" src={`https://stickershop.line-scdn.net/stickershop/v1/sticker/${message.sticker.stickerId}/IOS/sticker.png`} alt={`sticker_${message.sticker.stickerId}`} />;

  let { characters, text } = message;

  if (!characters || characters.length === 0)
    return text;

  let html = characters.reduce((accumulator, iterator) => {
    if (typeof iterator === "string" || iterator instanceof String)
      return accumulator + `<span>${iterator}</span>`;
    if (typeof iterator[0] === "string" || iterator[0] instanceof String)
      return accumulator + `<span>${iterator[0]}</span>`;
    return accumulator + `<img style="width:15px" src="/emoji/${iterator.productId}/${iterator.emojiId}_key.png" alt="[${iterator.altText}]" />`;
  }, "");

  return renderHTML(`${html.replace(/(?:\n|\n)/g, "")}`);
};


export const ConversationItem = (index, item, filters, conversation, notifications, onConversation, translate) => {
  if (filters.filter && filters.filter.type === "all") {
    return (
      <div
        key={index}
        className={`friend ${
          conversation &&
          item._id === conversation._id &&
          "selected"
        } ${
          notifications.find((n) => n._id === item._id) && "notified"
        }`}
        onClick={() => onConversation(item)}
      >
        <div className="avatar">
          <Avatar
            size={25}
            src={item.follower && item.follower.avatar}
          />
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="info">
            <div className="fullname">
              {item.follower && item.follower.name}
            </div>
            <div className="message">
              {item.lastChat && item.lastChat.message && (
                <span className="body">
                  {renderLastChat(item.lastChat.message)}
                </span>
              )}
              <span className="date">
                {moment(item.conversationDate).format(
                  "YYYY-MM-DD HH:mm"
                )}
              </span>
            </div>
          </div>
          <div>
            {item.conversationResolved && (
              <Tag
                color="white"
                style={{ color: "green", borderRadius: "10px" }}
              >
                {translate("privatechat.resolve", "Resolved")}
              </Tag>
            )}
            {item.conversationFollow && (
              <Tag
                color="white"
                style={{ color: "green", borderRadius: "10px" }}
              >
                {translate("privatechat.followup", "Follow up")}
              </Tag>
            )}
          </div>
        </div>
        <Badge className="reddot" status="error" />
      </div>
    );
  }


  if (filters.filter.type === "not_answer" && item.conversationAnswered === true) {
    return (
      <div key={index} className={`friend ${conversation && item._id === conversation._id && "selected" } ${notifications.find((n) => n._id === item._id) && "notified" }`} onClick={() => onConversation(item)} >
        <div className="avatar">
          <Avatar
            size={25}
            src={item.follower && item.follower.avatar}
          />
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="info">
            <div className="fullname">
              {item.follower && item.follower.name}
            </div>
            <div className="message">
              {item.lastChat && item.lastChat.message && (
                <span className="body">
                  {renderLastChat(item.lastChat.message)}
                </span>
              )}
              <span className="date">
                {moment(item.conversationDate).format(
                  "YYYY-MM-DD HH:mm"
                )}
              </span>
            </div>
          </div>
        </div>
        <Badge className="reddot" status="error" />
      </div>
    );
  }

  // eslint-disable-next-line no-unreachable
  if (filters.filter.type === "resolved" && item.conversationResolved === true) {
    return (
      <div
        key={index}
        className={`friend ${
          conversation &&item._id === conversation._id &&"selected"
        } ${
          notifications.find((n) => n._id === item._id) && "notified"
        }`}
        onClick={() => onConversation(item)}
      >
        <div className="avatar">
          <Avatar
            size={25}
            src={item.follower && item.follower.avatar}
          />
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="info">
            <div className="fullname">
              {item.follower && item.follower.name}
            </div>
            <div className="message">
              {item.lastChat && item.lastChat.message && (
                <span className="body">
                  {renderLastChat(item.lastChat.message)}
                </span>
              )}
              <span className="date">
                {moment(item.conversationDate).format(
                  "YYYY-MM-DD HH:mm"
                )}
              </span>
            </div>
          </div>
          <div>
            {item.conversationResolved && (
              <Tag
                color="white"
                style={{ color: "green", borderRadius: "10px" }}
              >
                {translate("privatechat.resolve", "Resolved")}
              </Tag>
            )}
            {item.conversationFollow && (
              <Tag
                color="white"
                style={{ color: "green", borderRadius: "10px" }}
              >
                {translate("privatechat.followup", "Follow up")}
              </Tag>
            )}
          </div>
        </div>
        <Badge className="reddot" status="error" />
      </div>
    );
  }

  if (
    filters.filter.type === "followup" && item.conversationFollow === true
  ) {
    return (
      <div
        key={index}
        className={`friend ${
          conversation && item._id === conversation._id && "selected"
        } ${
          notifications.find((n) => n._id === item._id) && "notified"
        }`}
        onClick={() => onConversation(item)}
      >
        <div className="avatar">
          <Avatar
            size={25}
            src={item.follower && item.follower.avatar}
          />
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="info">
            <div className="fullname">
              {item.follower && item.follower.name}
            </div>
            <div className="message">
              {item.lastChat && item.lastChat.message && (
                <span className="body">
                  {renderLastChat(item.lastChat.message)}
                </span>
              )}
              <span className="date">
                {moment(item.conversationDate).format(
                  "YYYY-MM-DD HH:mm"
                )}
              </span>
            </div>
          </div>
          <div>
            {item.conversationResolved && (
              <Tag
                color="white"
                style={{ color: "green", borderRadius: "10px" }}
              >
                {translate("privatechat.resolve", "Resolved")}
              </Tag>
            )}
            {item.conversationFollow && (
              <Tag
                color="white"
                style={{ color: "green", borderRadius: "10px" }}
              >
                {translate("privatechat.followup", "Follow up")}
              </Tag>
            )}
          </div>
        </div>
        <Badge className="reddot" status="error" />
      </div>
    );
  }
};
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { cooperation } from "../../../apis";
import ImagemapTemplate from "../../ImagemapTemplate/imageMapMessagePreview";
import ImagemapThumbnail from "../../Message/components/ImagemapThumbnail";

export default ({ showDivider, SetDivider, width, values = {} }) => {
  const { imageMapTemplates } = useSelector(state => state.general);
  const TEMPLATES = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  const [data, setData] = React.useState({});
  const dispatch = useDispatch();

  const loadData = () => {
    let actions = values.actions.map(action => ({
      ...action,
      area: {
        x     : action.area?.x / 3,
        y     : action.area?.y / 3,
        width : action.area?.width / 3,
        height: action.area?.height / 3
      }
    }));

    let payload = {
      _id       : values._id,
      type      : values.type,
      mapType   : values.mapType,
      template  : values.template,
      baseUrl   : values.baseUrl,
      baseSize  : values.baseSize,
      altText   : values.altText,
      previewUrl: values.previewUrl,
      actions
    };

    dispatch({
      type   : "general.imagemapSelect",
      payload: {
        ...payload,
        actions: actions
      }
    });

    if (payload.type !== "draw") {
      dispatch({
        type   : "general.imagemapSelect.templates",
        payload: imageMapTemplates[payload.type][payload.template] || []
      });
    }
    setData(payload);

  };
  const onClick = async (value) => {
    let INDEX = TEMPLATES.indexOf(value);
    let action = data.actions[INDEX];
    switch (action.type) {
      case "uri":
        window.open(action.isTagged ? action?.taggedUrl : action?.linkUri, "_blank", "noreferrer");
        break;
      case "message":
        break;
      case "share":
        window.open(`https://line.me/R/nv/recommendOA/${action.lineBasicId}`, "_blank", "noreferrer");
        break;
      case "cooperation":
        let data = await cooperation.get("rakuten_order");
        window.open(data?.link, "_blank", "noreferrer");
        break;
      case "coupon":
        window.open(action.isTagged ? action?.taggedUrl : action?.linkUri, "_blank", "noreferrer");
        break;
      case "no_action":
        break;
      default:
        break;
    }
    console.log("CLICKED_ACTION", action);
  };
  React.useEffect(() => {
    loadData();
  }, []);
  if (data.mapType === "image"){
    SetDivider(true);
    return (
      <Container style={{ width }}>
        <ImagemapTemplate
          showDivider={showDivider}
          width={(1040 / 3)}
          height={(
            data
                  && data.baseSize
                  && data.baseSize.height ?
              data.baseSize.height / 3 :
              1040 / 3
          )}
          type={data.type}
          template={data.template}
          onClick={(e) => onClick(e)}
          image={data.previewUrl}
          errors={{}} />
      </Container>
    );
  }
  return (
    <Container style={{ width }}>
      <ImagemapThumbnail
        width={(1040 / 3)}
        values={{
          ...data,
        }}
      />
    </Container>
  );


};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: transparent;
  width: 100%;
  padding: 4px;
  img{
    width :100%;
    border-radius: 10px;
  }
  .image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #e8e8e8;
  }
  .info {
    padding: 10px;
    width: 100%;
    background: #fff;
    h2 {
      font-size: 17px;
      margin-bottom: 5px;
      font-weight: 400;
    }
    p {
      font-size: 13px;
      font-weight: 300;
    }
  }
  .button {
    width: 100%;
    text-align: center;
    a {
      background: #e8e8e8;
      color: #333;
      font-weight: 300;
      display: block;
      text-transform: uppercase;
      padding: 10px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`;
import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { message } from "antd";
import { agency } from "../../apis";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Form from "./Form";


export default ({ props }) => {
  const { translate } = useTranslate();
  const history = useHistory();

  const onSubmit = async (data) => {
    let result = null;
    try {
      result = await agency.create(data);
      message.success(translate("login.register.checkmail", " Vertification mail has been sent."));
      // history.push("/user/list");
      history.goBack();
    } catch (error) {
      if (error.message === "ALREADY_REGISTERED") {
        message.success(translate("signup.warning.registered", "This e-mail address is already registered"));
      }
      // message.error(error.message);
    }
  };

  return (
    <div>
      <PageContainer>
        <PageHeader onBack={() => history.goBack()} title={translate("agency.create", "Create new agency user")} />
        <PageContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormWrapper>
              <Form onSubmit={onSubmit} />
            </FormWrapper>
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

const FormWrapper = styled.div`
  width: 600px;
`;
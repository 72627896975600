import React from "react";
import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import { useTranslate } from "../../../hooks";
import { formItemLayout } from "../../../utils";
import { Form, FormItem, Select } from "formik-antd";
import styled from "styled-components";
import { useSelector } from "react-redux";

const { Option } = Select;

const FormSchema =(translate) => Yup.object({
  nextStep: Yup.number().required(translate("system.message.required", "This field is required!"))
});

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        // message.error(translate("system.error.required", "Insert necessary fields first!"));
        return false;
      }

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { editable } = props;
  const { translate } = useTranslate();
  const { children, index, isEditable } = useSelector(state => state.process);
  const submitRef = React.useRef();
  const [data] = React.useState({
    nextStep: undefined,
    ...(editable && editable[0] === "update" ? editable[1] : {})
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    }
  }));

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={() => {}}>
        {() => {
          return (
            <Form {...formItemLayout}>
              <FormItem label={translate("scenario.form.next.step", "Next step")} name="nextStep" required>
                <Select name="nextStep" placeholder={translate("scenario.form.next.step", "Next step")} style={{ width: "100%" }} disabled={!isEditable}>
                  {children.filter(child => child.type === "step").map((child, i) => (
                    <Option value={index[child._id]} key={i}>{index[child._id] + 1}</Option>
                  ))}
                </Select>
              </FormItem>
              <SubmitForm ref={submitRef} translate={translate} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});

const Container = styled.div`
  padding : 10px;
  width: 100%;
  .ant-select-selector {
    background: #fff!important;
    color: #333!important;
  }
`;
import request from "../utils/request";

export const copy = (id) => request.post(`/api/scenario/${id}/copy`);
export const create = (data) => request.post("/api/scenario", data);
export const update = (id, data) => request.put(`/api/scenario/${id}`, data);
export const get = (id) => request.get(`/api/scenario/${id}`);
export const remove = (id) => request.del(`/api/scenario/${id}`);
export const list = (data) => request.get("/api/scenario", data);
export const report = (data) => request.get("/api/scenario/report", data);
export const setEvent = (id, data) => request.post(`/api/scenario/${id}/event`, data);
export const events = (data) => request.get("/api/scenario/events", data);

export const stopEvent = (id) => request.post(`/api/scenario/event/${id}/stop`);
export const forceEvent = (id) => request.post(`/api/scenario/event/${id}/force`);

export const hide = (id) => request.post(`/api/scenario/${id}/hide`);






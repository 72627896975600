import React from "react";
import { Descriptions } from "antd";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../../hooks";

export default () => {
  const { translate } = useTranslate();
  const { customers } = useSelector(state => state.general);
  return (
    <>
      <Descriptions size="small" title="" bordered>
        <Descriptions.Item label={translate("dashboard.totaluser", "Total Representing Customers")} span={3}>{customers.length}</Descriptions.Item>
      </Descriptions>
      <br />
    </>
  );
};
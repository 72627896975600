import React from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { message, Button } from "antd";
import { useTranslate } from "../../hooks";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Checkbox, Form, FormItem, Input } from "formik-antd";
import moment from "moment";
import EcLogo from "../../assets/ec-logo.png";

const FormSchema = (translate, isValidate) =>
  Yup.object({
    rakutenServiceSecret: Yup.string()
      .required(translate("system.message.required", "This field is required!"))
      .typeError(
        translate("system.message.required", "This field is required!")
      ),
    rakutenLicenseKey: Yup.string()
      .required(translate("system.message.required", "This field is required!"))
      .typeError(
        translate("system.message.required", "This field is required!")
      ),

    rakutenShopName:
      isValidate === true
        ? Yup.string()
          .required(
            translate("system.message.required", "This field is required!")
          )
          .typeError(
            translate("system.message.required", "This field is required!")
          )
        : null,
    rakutenShopPass:
      isValidate === true
        ? Yup.string()
          .required(
            translate("system.message.required", "This field is required!")
          )
          .typeError(
            translate("system.message.required", "This field is required!")
          )
        : null,
    rakutenUserName:
      isValidate === true
        ? Yup.string()
          .required(
            translate("system.message.required", "This field is required!")
          )
          .typeError(
            translate("system.message.required", "This field is required!")
          )
        : null,
    rakutenUserPass:
      isValidate === true
        ? Yup.string()
          .required(
            translate("system.message.required", "This field is required!")
          )
          .typeError(
            translate("system.message.required", "This field is required!")
          )
        : null,
  });
const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values, resetForm } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0) return false;

      return values;
    },
    async reload() {
      await resetForm();
    },
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { connectiveCheck, ecmSync } = props;
  const submitRef = React.useRef();
  const { translate } = useTranslate();
  const { account, customer, ecm_verifyUrl } = useSelector(
    (state) => state.general
  );
  const [isValidate, setIsValidate] = React.useState(true);
  const [disabled, setDisabled] = React.useState(!!account?.ecmaccesskey);
  const [data, setData] = React.useState({
    rakutenServiceSecret: account?.rakutenServiceSecret,
    rakutenLicenseKey   : account?.rakutenLicenseKey,

    rakutenShopName: account?.rakutenShopName,
    rakutenShopPass: account?.rakutenShopPass,
    rakutenUserName: account?.rakutenUserName,
    rakutenUserPass: account?.rakutenUserPass,

    id: account?._id,
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let values = await submitRef.current.submitForm();
      if (!values)
        message.error(
          translate("system.error.required", "Insert necessary fields first!")
        );

      return values;
    },
    async reload() {
      await submitRef.current.reload();
    },
  }));

  const render = () => {
    if (customer?.ecmaccesskey) {
      return (
        <RakutenSection>
          <FormItem
            label={translate("rakuten.shop.name.ecm", "Shop name")}
            name="rakutenShopName"
            required
          >
            <Input
              disabled={disabled}
              autocomplete="off"
              name="rakutenShopName"
              placeholder={translate(
                "rakuten.shop.name.placeholder.ecm",
                "Rakuten shop login name"
              )}
            />
          </FormItem>
          <FormItem
            label={translate("rakuten.shop.pass.ecm", "Shop password")}
            name="rakutenShopPass"
            required
          >
            <Input.Password
              disabled={disabled}
              autocomplete="new-password"
              name="rakutenShopPass"
              placeholder={translate(
                "rakuten.shop.pass.placeholder.ecm",
                "Rakuten shop password"
              )}
            />
          </FormItem>
          <FormItem
            label={translate("rakuten.user.name.ecm", "Rakuten user name")}
            name="rakutenUserName"
            required
          >
            <Input
              disabled={disabled}
              autocomplete="off"
              name="rakutenUserName"
              placeholder={translate(
                "rakuten.user.name.placeholder.ecm",
                "Rakuten user name"
              )}
            />
          </FormItem>
          <FormItem
            label={translate("rakuten.user.pass.ecm", "Rakuten user password")}
            name="rakutenUserPass"
            required
          >
            <Input.Password
              disabled={disabled}
              autocomplete="new-password"
              name="rakutenUserPass"
              placeholder={translate(
                "rakuten.user.pass.placeholder.ecm",
                "Rakuten user password"
              )}
            />
          </FormItem>
        </RakutenSection>
      );
    }
    return (
      <RakutenSection>
        <FormItem
          label={translate("rakuten.shop.name", "Shop name")}
          name="rakutenShopName"
          required
        >
          <Input
            disabled={disabled}
            autocomplete="off"
            name="rakutenShopName"
            placeholder={translate(
              "rakuten.shop.name",
              "Rakuten shop login name"
            )}
          />
        </FormItem>
        <FormItem
          label={translate("rakuten.shop.pass", "Shop password")}
          name="rakutenShopPass"
          required
        >
          <Input.Password
            disabled={disabled}
            autocomplete="new-password"
            name="rakutenShopPass"
            placeholder={translate(
              "rakuten.shop.pass",
              "Rakuten shop password"
            )}
          />
        </FormItem>
        <FormItem
          label={translate("rakuten.user.name", "Rakuten user name")}
          name="rakutenUserName"
          required
        >
          <Input
            disabled={disabled}
            autocomplete="off"
            name="rakutenUserName"
            placeholder={translate("rakuten.user.name", "Rakuten user name")}
          />
        </FormItem>
        <FormItem
          label={translate("rakuten.user.pass", "Rakuten user password")}
          name="rakutenUserPass"
          required
        >
          <Input.Password
            disabled={disabled}
            autocomplete="new-password"
            name="rakutenUserPass"
            placeholder={translate(
              "rakuten.user.pass",
              "Rakuten user password"
            )}
          />
        </FormItem>
      </RakutenSection>
    );
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate, isValidate)}
        onSubmit={() => {}}
      >
        {({ values }) => {
          if (
            values.rakutenShopName ||
            values.rakutenShopPass ||
            values.rakutenUserName ||
            values.rakutenUserPass
          ) {
            setIsValidate(true);
          } else {
            setIsValidate(false);
          }

          return (
            <div>
              <CooperationAlert>
                {translate(
                  "system.alert.servicekey",
                  "Service key and License key is renewed once in an month"
                )}
              </CooperationAlert>
              <Form layout={"vertical"} autocomplete="off">
                <RakutenSection>
                  <FormItem
                    label={translate(
                      "rakuten.secret.key",
                      "Rakuten service secret"
                    )}
                    name="rakutenLicenseKey"
                    required
                  >
                    <Input
                      disabled={disabled}
                      autocomplete="off"
                      name="rakutenServiceSecret"
                      placeholder={translate(
                        "rakuten.secret.key",
                        "Rakuten service secret"
                      )}
                    />
                  </FormItem>
                  <FormItem
                    label={translate(
                      "rakuten.licence.key",
                      "Rakuten license Key"
                    )}
                    name="rakutenServiceSecret"
                    required
                  >
                    <Input
                      disabled={disabled}
                      autocomplete="off"
                      name="rakutenLicenseKey"
                      placeholder={translate(
                        "rakuten.licence.key",
                        "Rakuten license Key"
                      )}
                    />
                    <div style={{ marginTop: "10px" }}>
                      {translate("rakuten.licenseKey.dueDate", "Due date")} :{" "}
                      {account?.rakutenLicenseDate
                        ? moment(account?.rakutenLicenseDate)
                          .tz(account?.timezone)
                          .format("YYYY-MM-DD HH:mm")
                        : "----/--/--"}
                    </div>
                  </FormItem>

                  <ButtonGroup>
                    <Button disabled={!values.rakutenLicenseKey || !values.rakutenServiceSecret} onClick={connectiveCheck}>
                      {translate("api.check.button", "Connection check")}
                    </Button>
                    {account?.ecmaccesskey && (
                      <Button onClick={ecmSync}>
                        {translate("api.ecmSync.button", "EC Masters sync")}
                      </Button>
                    )}
                  </ButtonGroup>
                </RakutenSection>

                <CooperationAlert>
                  {disabled === false ? (
                    translate(
                      "rakuten.shop.user.alert",
                      "Service key and License key is renewed once in an month"
                    )
                  ) : (
                    <div>
                      <div
                        style={{
                          width         : "100%",
                          display       : "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {translate(
                          "rakuten.shop.user.alert.ecm",
                          "To change the settings, please change the settings from Mall login information Rakuten of EC Masters Club."
                        )}
                        <a
                          href={"https://club.ec-masters.net/"}
                          target="_blank"
                        >
                          <img
                            width={"100%"}
                            src={EcLogo}
                            style={{ width: "111px", marginRight: "16px" }}
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </CooperationAlert>

                {render()}

                <SubmitForm ref={submitRef} />
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
});

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const CooperationAlert = styled.div`
  padding-left: 15px;
  padding-bottom: 10px;
  margin: 10px 0;
  color: #7c708f;
`;

const RakutenContent = styled.div`
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 20px;
  background: #fcfcfc;
  margin: 0 16px;
`;

const RakutenSection = (props) => {
  return <RakutenContent>{props.children}</RakutenContent>;
};

import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Form, Input, FormItem, Select } from "formik-antd";
import { Formik } from "formik";
import { Button, Form as AntForm } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "../../../hooks";

const { Option } = Select;
const { TextArea } = Input;

export default ({ nextPage, action, setFormData }) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { businessTypes } = useSelector(state => state.general);
  const companyName = useSelector(state => state.register.companyName);

  const onSubmit = async (data) => {
    setFormData(data);
    nextPage();
  };

  const [data] = React.useState({
    branchName : undefined,
    name       : undefined,
    companyName: undefined,
    ...(action&&action[0] === "update"?action[1]:{})
  });

  const FormSchema = Yup.object().shape({
    companyName: Yup.string().required(translate("system.message.required", "required!")),
    branchName : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    name       : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  });

  // React.useEffect(() => {
  //   setFormData({ ...data, companyName });
  // }, [companyName]);

  return (
    <StepOneWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical" style={{ width: "100%" }}>
            <Divider style={{ textAlign: "center" }}>{translate("useredit.company", "Company information")}</Divider>
            <FormItem label={<label className="form-input-title">{translate("useredit.companyname", "Company name")}</label>} name="companyName" required>
              <Input className="form-input" name="companyName" placeholder={translate("useredit.companyname", "Company name")} />
            </FormItem>
            <FormItem label={<label className="form-input-title">{translate("staff.verify.name", "Name")}</label>} name="name" required>
              <TextArea className="form-input" name="name" placeholder={translate("staff.verify.name", "Name")}/>
            </FormItem>
            <FormItem label={<label className="form-input-title">{translate("branchName", "Branch name")}</label>} name="branchName" required>
              <Input className="form-input" name="branchName" placeholder={translate("branchName", "Branch name")} />
            </FormItem>
            <AntForm.Item>
              <Button className="form-button" htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.confirm", "Confirm")}</Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>

    </StepOneWrapper>
  );
};

const StepOneWrapper = styled.div`
     display : flex;
     justify-content : center;
`;

const Divider = styled.div`
  margin-bottom : 20px;
  font-size     : 15px;
  font-weight   : 500;
  // background : #e7e7e7;
  background    : #009d4b;
  padding: 4px 8px;
  border-radius: 2px;
  color : white;
`;
const initialState = {
  target: {
    type       : "ALL",
    open       : true,
    percent    : 0,
    friends    : 0,
    calculating: false,
    tags       : []
  },
  broadcast: {
    open    : false,
    sendType: "NOW",
    sendDate: undefined
  },
  message: {
    open    : false,
    title   : undefined,
    children: []
  },
  segment    : [],
  demographic: null,
  collapse   : ["target"],
  preview    : false
};

const message = (state = initialState, action) => {
  switch (action.type) {
    case "message.target.estimate":
      return {
        ...state,
        target: {
          ...state.target,
          ...action.payload,
          calculating: false
        }
      };
    case "message.target.calculating":
      return {
        ...state,
        target: {
          ...state.target,
          calculating: action.payload
        }
      };
    case "message.demographic":
      return {
        ...state,
        demographic: action.payload
      };
    case "message.demographic..genders":
      return {
        ...state,
        demographic: { ...state.demographic, genders: [] }
      };
    case "message.demographic.remove.ages":
      return {
        ...state,
        demographic: { ...state.demographic, ages: [] }
      };
    case "message.demographic.remove.areas":
      return {
        ...state,
        demographic: { ...state.demographic, areas: [] }
      };
    case "message.demographic.remove.appTypes":
      return {
        ...state,
        demographic: { ...state.demographic, appTypes: [] }
      };
    case "message.demographic.remove.subscriptionPeriods":
      return {
        ...state,
        demographic: { ...state.demographic, subscriptionPeriods: [] }
      };

    case "message.collapse": {
      const preview = action.payload.indexOf("message") !== -1;
      return {
        ...state,
        collapse: action.payload,
        preview
      };
    }
    case "message.preview": {
      return {
        ...state,
        preview: action.payload
      };
    }
    case "message.segment": {
      return {
        ...state,
        segment: [...state.segment, action.payload]
      };
    }
    case "message.target.data": {
      return {
        ...state,
        target: {
          ...state.target,
          isValid: true,
          tags   : action.payload
        }
      };
    }
    case "message.target.type": {
      return {
        ...state,
        target: {
          ...state.target,
          isValid: true,
          type   : action.payload
        }
      };
    }
    case "message.broadcast.data": {
      return {
        ...state,
        broadcast: {
          ...state.target,
          isValid: true,
          ...action.payload
        }
      };
    }
    case "message.message.data": {
      return {
        ...state,
        message: {
          ...state.target,
          isValid: true,
          ...action.payload
        }
      };
    }
    case "message.cooperation.data": {
      return {
        ...state,
        cooperation: action.payload
      };
    }
    case "message.template.data": {
      return {
        ...state,
        template: action.payload
      };
    }
    default:
      return state;
  }
};

export default message;
import React, { memo } from "react";
import { DualAxes } from "@ant-design/plots";

const VisitorGraph = memo(({ visits, visits_cvr }) => {
  const config = {
    data           : [visits, visits_cvr],
    xField         : "date",
    yField         : ["visits", "visits_cvr"],
    geometryOptions: [
      {
        geometry        : "column",
        isStack         : true,
        seriesField     : "type",
        columnWidthRatio: 0.4,
        color: ['#aeda85', '#8ed6e6', '#7c9ed1', '#9a9a9a'],
      },
      {
        geometry   : "line",
        seriesField: "type",
        color: ['#53a843', '#2333d7', '#305eb6', '#555555'],
        // lineStyle  : ({ name }) => {
        //   if (name === "a") {
        //     return {
        //       lineDash: [1, 4],
        //       opacity : 1,
        //     };
        //   }
        //   return {
        //     opacity: 0.5,
        //   };
        // },
      },
    ],
  };

  return <DualAxes {...config} />;
});

VisitorGraph.displayName = "VisitorGraph";

export { VisitorGraph };

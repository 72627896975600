import React from "react";
import { Empty, Input, Avatar, Tag, Select, Button } from "antd";
import { useTranslate } from "../../hooks";
import { MyTable, RowAction } from "../../components";
import { useHistory } from "react-router";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import { carousel as carouselApi } from "../../apis";
import moment from "moment";
import { useSelector } from "react-redux";

const { Option } = Select;

export default ({ onSelect }) => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const [query, setQuery] = React.useState("");
  const { carouselTypes, lang } = useSelector(state =>state.general);
  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array?.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };
  const [filters, setFilters] = React.useState({
    query: "",
    type : "all"
  });

  const columns = useHeader({
    history,
    onSelect,
    translate,
    tableTitles: {
      no    : translate("system.number", "No."),
      title : translate("carousel.table.title", "Title"),
      cards : translate("carousel.table.cards", "Cards"),
      type  : translate("carousel.table.type", "Card type"),
      action: translate("system.action", "Action"),
      select: translate("system.select", "Select"),
      image : translate("imagemap.image", "Image"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "select": {
          onSelect(item);
          break;
        }
        default:
      }
    },
    lang,
    carouselTypes: convertArrayToObject(carouselTypes, "code"),
  });

  const setType = (type) => {
    setFilters({ ...filters, type: type });
  };

  const onClear =()=> {
    setFilters({
      query: "",
      type : "image"
    });
    setQuery("");
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <>
      <PageFilter style={{ padding: 0, marginBottom: 20 }}>
        <Input.Group compact>
          <Input
            name="query"
            value={query}
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            prefix={<SearchOutlined />}
          />
        </Input.Group>
        <Button onClick={onClear}>
          {translate("system.clear", "Clear")}
        </Button>
      </PageFilter>
      <MyTable
        locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
        scroll={{ x: 200 }}
        ref={myTableRef}
        filters={filters}
        rowKey={(record) => record._id}
        columns={columns}
        loadData={carouselApi.list}
      />
    </>
  );
};

const useHeader = ({ onAction, tableTitles, carouselTypes, lang }) => {
  if (lang){
    return [{
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    }, {
      title : tableTitles.image,
      width : "100px",
      render: (item) => {
        return (<Avatar shape="square" size={100} src={`${item?.columns[0]?.thumbnailImageUrl}`}/>);
      }
    }, {
      width : "50%",
      title : tableTitles.title,
      render: (record) => {
        return (
          <div style={{ width: "200px" }}>
            <p style={{ margin: 0, padding: 0 }}>{record.altText}</p>
          </div>
        );
      }
    }, {
      title : tableTitles.type,
      render: (row)=> {
        return (<div>{carouselTypes[row.type][lang?.toLowerCase()]}</div>);
      }
    }, {
      title : tableTitles.cards,
      render: (item)=> {
        return item.columns.length;
      }
    }, {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (row) => {
        return (
          <RowAction
            actions={{
              select: tableTitles.select,
            }}
            onClick={(key) => onAction(key, row)}
          />
        );
      },
    }];
  }
};


const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;
import React from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Button } from "antd";
import * as Yup from "yup";
import { useTranslate } from "../../../hooks";
import { useSelector } from "react-redux";


const FormSchema =(translate) => Yup.object().shape({
  ecmaccesskey: Yup.string().required(translate("system.message.required", "Please fill out this field")),
});

export default ({ setEcmaccesskey, onSubmit }) => {
  const { translate } = useTranslate();
  const { user } = useSelector(state=> state.general);
  const [data] = React.useState({
    ecmaccesskey: undefined,
  });

  return (
    <Formik
      initialValues={data}
      validationSchema={()=>FormSchema(translate)}

      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form layout="vertical">
          <FormItem label={translate("ecm.accesskey", "ECM accesskey")} name="ecmaccesskey" required>
            <Input name="ecmaccesskey" type="text" placeholder={translate("ecm.accesskey", "ECM accesskey")} />
          </FormItem>
          <AntForm.Item>
            <Button htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.change", "Change")}</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};

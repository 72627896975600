import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { message, Space, Input, Empty, Checkbox, Tag, Alert, Select, DatePicker } from "antd";
import { Button } from "../../components/Design";
import { useHistory } from "react-router-dom";
import { file as fileApi } from "../../apis";
import { useTranslate } from "../../hooks";
import { RowAction } from "../../components";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import MyTable from "../../components/MyTable";
import confirm from "antd/lib/modal/confirm";
import moment from "moment";
import usePlan from "../../hooks/usePlan";
import { useSelector } from "react-redux";
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => {
  const myTableRef = React.useRef(null);
  const { translate } = useTranslate();
  const { checkPermission } = usePlan();
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const { exportStatus, exportTypes } = useSelector(state => state.general);
  const { customer } = useSelector(state => state.general);

  const [filters, setFilters] = React.useState({
    query    : "",
    startDate: undefined,
    endDate  : undefined,
    type     : undefined,
    status   : undefined
  });
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });


  const changeRange = (e) => {
    setFilters({ ...filters, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const renderStatus = (status) => {
    if (status === "DONE")
      return <Tag color="green">{translate(status, status)}</Tag>;
    if (status === "FAILED")
      return <Tag color="red">{translate(status, status)}</Tag>;
    if (status === "PENDING")
      return <Tag color="blue">{translate(status, status)}</Tag>;
  };

  const clearRange = () => {
    setFilters({
      query    : "",
      startDate: undefined,
      endDate  : undefined,
      type     : undefined,
      status   : undefined
    });
    setRangePicker({ startDate: undefined, endDate: undefined });
  };

  const column = useHeader({
    history,
    translate,
    renderStatus,
    tableTitles: {
      no      : translate("system.number", "No."),
      date    : translate("file.date", "Export date"),
      customer: translate("file.customer", "Customer"),
      type    : translate("file.type", "File type"),
      name    : translate("file.name", "File name"),
      status  : translate("file.status", "Status"),
      download: translate("file.download", "Download"),
      remove  : translate("system.delete", "Remove"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "download": {
          try {
            let res = await fileApi.get(item._id);
            window.location.href = res.url;
            message.success(translate("system.message.success", "Successful"));
          } catch (err) {
            console.log(err);
            message.error(translate("system.message.download.failed", "Download failed！"));
          }
          break;
        }
        case "remove": {
          confirm({
            icon      : <ExclamationCircleOutlined />,
            content   : translate("system.confirmation.question", "Are you sure ?"),
            okText    : translate("system.yes", "Yes"),
            cancelText: translate("system.no", "No"),
            async onOk() {
              await fileApi.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
            okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark } }
          });
          break;
        }
        default:
      }
    },
  });

  const onChangeStatus = (status) => {
    setFilters({
      ...filters,
      status: status
    });
  };

  const onChangeType = (type) => {
    setFilters({
      ...filters,
      type: type
    });
  };
  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query: query });

    }, 300);
    return () => clearTimeout(timer);

  }, [query]);
  return (
    <div>
      <PageContainer>
        <PageHeader title={translate("file.list.title", "Exported files")} extra={[]} />
        <PageFilter>
          <Space>
            <Input.Group compact>
              <Input
                name="query"
                placeholder={translate("system.search.button", "Search...")}
                onChange={e => setQuery(e.target.value)}
                style={{ width: 250 }}
                prefix={<SearchOutlined />}
              />
            </Input.Group>
            <Select value={filters.status} name="exportStatus" style={{ width: 180 }} onChange={onChangeStatus} placeholder= {translate("file.status", "Status")} allowClear>
              {exportStatus.map((item, index) => <Option value={item} key={index}>{translate(item, item)}</Option>)}
            </Select>
            {<Select value={filters.type} name="exportStatus" style={{ width: 180 }} onChange={onChangeType} placeholder= {translate("file.type", "Type")} allowClear>
              {exportTypes.map((item, index) => {
                return <Option value={item} key={index}>{translate(item, item)}</Option>;
              })}
            </Select>}
            <RangePicker value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
            <Button type="default" onClick={() => clearRange()}>{translate("system.clear", "Clear")}</Button>
          </Space>
          <Space>
          </Space>
        </PageFilter>

        <PageContent>
          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            // filters={filters}
            ref={myTableRef}
            rowKey={(record) => record._id}
            columns={column}
            filters={filters}
            loadData={fileApi.list}
          />
        </PageContent>
      </PageContainer>
    </div>
  );
};
const useHeader = ({ onAction, tableTitles, translate, renderStatus }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.customer,
    render: (item) => {
      return (<div>{item?.customer?.companyName || "-"}</div>);
    }
  }, {
    title : tableTitles.date,
    render: (item) => {
      return (
        <div>
          {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
        </div>
      );
    }
  }, {
    title : tableTitles.type,
    render: (item) => {
      return (
        <Tag>{translate(item.fileType, item.fileType)}</Tag>
      );
    }
  }, {
    title    : tableTitles.name,
    dataIndex: "fileName",
  }, {
    title : tableTitles.status,
    render: (item) => {
      return renderStatus(item.fileStatus);
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (item) => {
      if (item.fileStatus === "DONE") {
        return <RowAction actions={{
          download: tableTitles.download,
          remove  : tableTitles.remove,
        }} onClick={(key) => onAction(key, item)} />;
      }
      return <RowAction actions={{
        remove: tableTitles.remove,
      }} onClick={(key) => onAction(key, item)} />;

    } }];
};
const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;
import request from "../utils/request";

export const list = (data) => request.get("/api/card", data);
export const add = (data) => request.post("/api/card", data);
export const update = (data) => request.put(`/api/card/${data._id}`, data);
export const remove = (id) => request.del(`/api/card/${id}`);

export const setChange = (id) => request.post(`/api/card/${id}/default`);



import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Tag } from "antd";
import React from "react";
import styled from "styled-components";
import CustomTable from "../../components/CustomTable";
import { useTranslate } from "../../hooks";

export default ({ action, rakutenAccessHide, hideSuccess = false, onCheck })=> {
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  let status = action?.rakutenAccess?.status;
  const renderTableOne = () => {
    if (hideSuccess){
      return <tbody key={1} className="ant-table-tbody">
        {!action?.rakutenAccess?.licenseDateGet ? <tr>
          <td className="ant-table-cell">1</td>
          <td className="ant-table-cell ">{translate("new.translate", "license.expiryDate.get")}</td>
          <td className="ant-table-cell ">{translate("license.expiryDate.get", "Get the expiry date of the provided license")}</td>
          <td className="ant-table-cell ">{
            <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
        {!action?.rakutenAccess?.rpay_order_getOrder ? <tr>
          <td className="ant-table-cell">2</td>
          <td className="ant-table-cell ">{translate("new.translate", "rpay.order.getOrder")}</td>
          <td className="ant-table-cell ">{translate("rpay.order.getOrder", "Getting order details by specifying the list of order number.")}</td>
          <td className="ant-table-cell ">{
            <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }
          </td>
        </tr> : null}
        {!action?.rakutenAccess?.rpay_order_searchOrder ? <tr>
          <td className="ant-table-cell">3</td>
          <td className="ant-table-cell ">{translate("new.translate", "rpay.order.searchOrder")}</td>
          <td className="ant-table-cell ">{translate("rpay.order.searchOrder", "Search orders with specifying search condition to return the list of order_number that meet the search condition.")}</td>
          <td className="ant-table-cell ">{
          action?.rakutenAccess?.rpay_order_searchOrder ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr> : null}
        { !action?.rakutenAccess?.coupon_issue ? <tr>
          <td className="ant-table-cell">4</td>
          <td className="ant-table-cell ">{translate("new.translate", "coupon.issue")}</td>
          <td className="ant-table-cell ">{translate("coupon.issue", "This function enables the registration of coupon information.")}</td>
          <td className="ant-table-cell ">{
        action?.rakutenAccess?.coupon_issue ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
        {! action?.rakutenAccess?.coupon_update ? <tr>
          <td className="ant-table-cell">5</td>
          <td className="ant-table-cell ">{translate("new.translate", "coupon.update")}</td>
          <td className="ant-table-cell ">{translate("coupon.update", "This function enables the updates of coupon information.")}</td>
          <td className="ant-table-cell ">{
        action?.rakutenAccess?.coupon_update ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr> : null}
        {!action?.rakutenAccess?.coupon_delete ? <tr>
          <td className="ant-table-cell">6</td>
          <td className="ant-table-cell ">{translate("new.translate", "coupon.delete")}</td>
          <td className="ant-table-cell ">{translate("coupon.delete", "This function enables the deletion of coupon information.")}</td>
          <td className="ant-table-cell ">{
         action?.rakutenAccess?.coupon_delete ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
        {!action?.rakutenAccess?.coupon_search ? <tr>
          <td className="ant-table-cell">7</td>
          <td className="ant-table-cell ">{translate("new.translate", "coupon.search")}</td>
          <td className="ant-table-cell ">{translate("coupon.search", "This function enables the search of coupon information by specifying the conditions.")}</td>
          <td className="ant-table-cell ">{
        action?.rakutenAccess?.coupon_search ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
        {!action?.rakutenAccess?.coupon_get ? <tr>
          <td className="ant-table-cell">8</td>
          <td className="ant-table-cell ">{translate("new.translate", "coupon.get")}</td>
          <td className="ant-table-cell ">{translate("coupon.get", "This function enables the acquisition of coupon information that is corresponding to the specified coupon code.")}</td>
          <td className="ant-table-cell ">{
            action?.rakutenAccess?.coupon_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr> : null}
        {!action?.rakutenAccess?.cabinet_folder_insert ? <tr>
          <td className="ant-table-cell">9</td>
          <td className="ant-table-cell ">{translate("new.translate", "cabinet.folder.insert")}</td>
          <td className="ant-table-cell ">{translate("cabinet.folder.insert", "This function enables the creation of a folder.")}</td>
          <td className="ant-table-cell ">{
        action?.rakutenAccess?.cabinet_folder_insert ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
        {!action?.rakutenAccess?.cabinet_file_insert ? <tr>
          <td className="ant-table-cell">10</td>
          <td className="ant-table-cell ">{translate("new.translate", "cabinet.file.insert")}</td>
          <td className="ant-table-cell ">{translate("cabinet.file.insert", "This function enables the registration of the image by specifying the image file.")}</td>
          <td className="ant-table-cell ">{
        action?.rakutenAccess?.cabinet_file_insert ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr> : null}
        {!action?.rakutenAccess?.cabinet_files_search ? <tr>
          <td className="ant-table-cell">11</td>
          <td className="ant-table-cell ">{translate("new.translate", "cabinet.files.search")}</td>
          <td className="ant-table-cell ">{translate("cabinet.files.search", "This function enables the search of the image by specifying the image name.")}</td>
          <td className="ant-table-cell ">{
            action?.rakutenAccess?.cabinet_files_search ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
        {!action?.rakutenAccess?.shop_categories_get ? <tr>
          <td className="ant-table-cell">12</td>
          <td className="ant-table-cell ">{translate("new.translate", "shop.category.get")}</td>
          <td className="ant-table-cell ">{translate("shop.categories.get", "This function enables the acquisition of the target category information by specifying category ID.")}</td>
          <td className="ant-table-cell ">{
            action?.rakutenAccess?.shop_categories_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr> : null}
        {!action?.rakutenAccess?.item_get ? <tr>
          <td className="ant-table-cell">13</td>
          <td className="ant-table-cell ">{translate("new.translate", "item.get")}</td>
          <td className="ant-table-cell ">{translate("item.get", "This function enables the acquisition of the item information registered in RMS by specifying Item Management Number.")}</td>
          <td className="ant-table-cell ">{
        action?.rakutenAccess?.item_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr> : null}
        {!action?.rakutenAccess?.getCategoriesV1 ? <tr>
          <td className="ant-table-cell">14</td>
          <td className="ant-table-cell ">{translate("new.translate", "shop.categories.get")}</td>
          <td className="ant-table-cell ">{translate("shop.categories.get", "Using this function, you can obtain a list of registered category information.If you have a Mega Shop plan contract and have registered a category set, you can obtain category information linked to the category set management number.")}</td>
          <td className="ant-table-cell ">{
         action?.rakutenAccess?.getCategoriesV1 ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr> : null}

      </tbody>;
    }
    return <tbody key={1} className="ant-table-tbody">
      <tr>
        <td className="ant-table-cell">1</td>
        <td className="ant-table-cell ">{translate("new.translate", "license.expiryDate.get")}</td>
        <td className="ant-table-cell ">{translate("license.expiryDate.get", "Get the expiry date of the provided license")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.licenseDateGet ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">2</td>
        <td className="ant-table-cell ">{translate("new.translate", "rpay.order.getOrder")}</td>
        <td className="ant-table-cell ">{translate("rpay.order.getOrder", "Getting order details by specifying the list of order number.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.rpay_order_getOrder ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">3</td>
        <td className="ant-table-cell ">{translate("new.translate", "rpay.order.searchOrder")}</td>
        <td className="ant-table-cell ">{translate("rpay.order.searchOrder", "Search orders with specifying search condition to return the list of order_number that meet the search condition.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.rpay_order_searchOrder ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">4</td>
        <td className="ant-table-cell ">{translate("new.translate", "coupon.issue")}</td>
        <td className="ant-table-cell ">{translate("coupon.issue", "This function enables the registration of coupon information.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.coupon_issue ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">5</td>
        <td className="ant-table-cell ">{translate("new.translate", "coupon.update")}</td>
        <td className="ant-table-cell ">{translate("coupon.update", "This function enables the updates of coupon information.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.coupon_update ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">6</td>
        <td className="ant-table-cell ">{translate("new.translate", "coupon.delete")}</td>
        <td className="ant-table-cell ">{translate("coupon.delete", "This function enables the deletion of coupon information.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.coupon_delete ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">7</td>
        <td className="ant-table-cell ">{translate("new.translate", "coupon.search")}</td>
        <td className="ant-table-cell ">{translate("coupon.search", "This function enables the search of coupon information by specifying the conditions.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.coupon_search ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">8</td>
        <td className="ant-table-cell ">{translate("new.translate", "coupon.get")}</td>
        <td className="ant-table-cell ">{translate("coupon.get", "This function enables the acquisition of coupon information that is corresponding to the specified coupon code.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.coupon_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">9</td>
        <td className="ant-table-cell ">{translate("new.translate", "cabinet.folder.insert")}</td>
        <td className="ant-table-cell ">{translate("cabinet.folder.insert", "This function enables the creation of a folder.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.cabinet_folder_insert ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">10</td>
        <td className="ant-table-cell ">{translate("new.translate", "cabinet.file.insert")}</td>
        <td className="ant-table-cell ">{translate("cabinet.file.insert", "This function enables the registration of the image by specifying the image file.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.cabinet_file_insert ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">11</td>
        <td className="ant-table-cell ">{translate("new.translate", "cabinet.files.search")}</td>
        <td className="ant-table-cell ">{translate("cabinet.files.search", "This function enables the search of the image by specifying the image name.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.cabinet_files_search ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>

      <tr>
        <td className="ant-table-cell">12</td>
        <td className="ant-table-cell ">{translate("new.translate", "shop.category.get")}</td>
        <td className="ant-table-cell ">{translate("shop.categories.get", "This function enables the acquisition of the target category information by specifying category ID.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.shop_categories_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>
      <tr>
        <td className="ant-table-cell">13</td>
        <td className="ant-table-cell ">{translate("new.translate", "item.get")}</td>
        <td className="ant-table-cell ">{translate("item.get", "This function enables the acquisition of the item information registered in RMS by specifying Item Management Number.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.item_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>
      <tr>
        <td className="ant-table-cell">14</td>
        <td className="ant-table-cell ">{translate("new.translate", "shop.categories.get")}</td>
        <td className="ant-table-cell ">{translate("shop.categories.all.get", "Using this function, you can obtain a list of registered category information.If you have a Mega Shop plan contract and have registered a category set, you can obtain category information linked to the category set management number.")}</td>
        <td className="ant-table-cell ">{
        action?.rakutenAccess?.getCategoriesV1 ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>
    </tbody>;
  };
  const renderTableTwo = () => {
    if (hideSuccess) {
      return <tbody key={2} className="ant-table-tbody">
        {!action?.rakutenAccess?.shop_status_get ?<tr>
          <td className="ant-table-cell">15</td>
          <td className="ant-table-cell ">{translate("new.translate", "shop.shopStatus.get")}</td>
          <td className="ant-table-cell ">{translate("shop.status.get", "You can check the migration status and usage status of a specific shop.")}</td>
          <td className="ant-table-cell ">{
          action?.rakutenAccess?.shop_status_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr> : null}
        {!action?.rakutenAccess?.item_getv2 ? <tr>
          <td className="ant-table-cell">16</td>
          <td className="ant-table-cell ">{translate("new.translate", "itemapi_2.0.items.get")}</td>
          <td className="ant-table-cell ">{translate("item.getv2", "retrieve item information by manageNumber.")}</td>
          <td className="ant-table-cell ">{
          action?.rakutenAccess?.item_getv2 ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr> : null}
        {!action?.rakutenAccess?.item_category_get ? <tr>
          <td className="ant-table-cell">17</td>
          <td className="ant-table-cell ">{translate("new.translate", "category.item-mappings.get")}</td>
          <td className="ant-table-cell ">{translate("item.category.get", "retrieve mapping information between item and category by manageNumber.")}</td>
          <td className="ant-table-cell ">{
          action?.rakutenAccess?.item_category_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
        {!action?.rakutenAccess?.shop_categories_getv2 ? <tr>
          <td className="ant-table-cell">18</td>
          <td className="ant-table-cell ">{translate("new.translate", "category.shop-categories.get")}</td>
          <td className="ant-table-cell ">{translate("shop.categories.getv2", "retrieve category information by categoryId.")}</td>
          <td className="ant-table-cell ">{
          action?.rakutenAccess?.shop_categories_getv2 ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
        {!action?.rakutenAccess?.getCategoriesV2 ? <tr>
          <td className="ant-table-cell">19</td>
          <td className="ant-table-cell ">{translate("new.translate", "category.category-trees.get")}</td>
          <td className="ant-table-cell ">{translate("category.category-trees.get", "You can specify the category ID to get category information.")}</td>
          <td className="ant-table-cell ">{
          action?.rakutenAccess?.getCategoriesV2 ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
        {!action?.rakutenAccess?.getCategorySetId ? <tr>
          <td className="ant-table-cell">20</td>
          <td className="ant-table-cell ">{translate("new.translate", "category.category-set-lists.get")}</td>
          <td className="ant-table-cell ">{translate("category.category-set-lists.get", "You can get all the category set information.")}</td>
          <td className="ant-table-cell ">{
          action?.rakutenAccess?.getCategorySetId ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
          }</td>
        </tr>: null}
      </tbody>;
    }
    return <tbody key={2} className="ant-table-tbody">
      <tr>
        <td className="ant-table-cell">15</td>
        <td className="ant-table-cell ">{translate("new.translate", "shop.shopStatus.get")}</td>
        <td className="ant-table-cell ">{translate("shop.status.get", "You can check the migration status and usage status of a specific shop.")}</td>
        <td className="ant-table-cell ">{
          action?.rakutenAccess?.shop_status_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>
      <tr>
        <td className="ant-table-cell">16</td>
        <td className="ant-table-cell ">{translate("new.translate", "itemapi_2.0.items.get")}</td>
        <td className="ant-table-cell ">{translate("item.getv2", "retrieve item information by manageNumber.")}</td>
        <td className="ant-table-cell ">{
          action?.rakutenAccess?.item_getv2 ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>
      <tr>
        <td className="ant-table-cell">17</td>
        <td className="ant-table-cell ">{translate("new.translate", "category.item-mappings.get")}</td>
        <td className="ant-table-cell ">{translate("item.category.get", "retrieve mapping information between item and category by manageNumber.")}</td>
        <td className="ant-table-cell ">{
          action?.rakutenAccess?.item_category_get ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>
      <tr>
        <td className="ant-table-cell">18</td>
        <td className="ant-table-cell ">{translate("new.translate", "category.shop-categories.get")}</td>
        <td className="ant-table-cell ">{translate("shop.categories.getv2", "retrieve category information by categoryId.")}</td>
        <td className="ant-table-cell ">{
          action?.rakutenAccess?.shop_categories_getv2 ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>
      <tr>
        <td className="ant-table-cell">19</td>
        <td className="ant-table-cell ">{translate("new.translate", "category.category-trees.get")}</td>
        <td className="ant-table-cell ">{translate("category.category-trees.get", "You can specify the category ID to get category information.")}</td>
        <td className="ant-table-cell ">{
          action?.rakutenAccess?.getCategoriesV2 ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>
      <tr>
        <td className="ant-table-cell">20</td>
        <td className="ant-table-cell ">{translate("new.translate", "category.category-set-lists.get")}</td>
        <td className="ant-table-cell ">{translate("category.category-set-lists.get", "You can get all the category set information.")}</td>
        <td className="ant-table-cell ">{
          action?.rakutenAccess?.getCategorySetId ? <Tag color="green">{translate("api.check.authorized", "Authorized")}</Tag> : <Tag color="red">{translate("api.check.unauthorized", "Unauthorized")}</Tag>
        }</td>
      </tr>
    </tbody>;
  };

  return (
    <Container>
      {(status === "FAILED" || status === "PARTLY_FAILED") && <div>
        <Tag color="red">{translate("rakuten.check..unusableapi", "Api not usable")}</Tag>
        {translate("rakuten.check.unusableapi.text", "There are some unusable api-s please check connection settings")}
      </div>}
      <div className="description">
        {translate("api.check.warning", "If check of all API's failed there is high chance of wrong Access license or Secret key, if it some of the API failes it means that specific API is not permitted for the account and you have to activate it from RMS.")}
      </div>
      {hideSuccess && <div className="description">
        <label>
          <Checkbox
            onChange={onCheck}
            value={rakutenAccessHide}
          />
          <span className="description-text">
            {" "}{translate("api.checkbox.rakutenaccesshide", "Don't show if only SKU api failed!")}
          </span>
        </label>
      </div>}
      <div>
        <a href="https://www.notion.so/ec-masters/2-RMS-56b78c32e89b42b6a888469f46d13a83" target={"_blank"}>{translate("rakuten.check.link.1", "APIの有効化マニュアル")}</a>
      </div>
      <div>
        <a href="https://www.notion.so/ec-masters/de9b25e477704724a9b4a0d9f4709aec" target={"_blank"}>{translate("rakuten.check.link.2", "ライセンスキーの設定マニュアル")}</a>
      </div>
      <CheckTable
        ref={myTableRef}
        customPagination={false}
        dataSource ={[0]}
        bordered
        thead={() => (
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell" rowSpan={2}>{translate("system.number", "No")}</th>
              <th className="ant-table-cell" rowSpan={2}>{translate("api.check.name", "Name")}</th>
              <th className="ant-table-cell" rowSpan={2}>{translate("api.check.description", "Description")}</th>
              <th className="ant-table-cell" colSpan={3}>{translate("system.status", "Status")}</th>
            </tr>
          </thead>
        )}
        tbody={(row, index) => (
          renderTableOne()
        )} />
      <div className="description">
        {translate("api.check.warning.second", "Below this is for SKU migration check.")}
      </div>
      <CheckTable
        ref={myTableRef}
        customPagination={false}
        dataSource ={[0]}
        bordered
        thead={() => (
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell" rowSpan={2}>{translate("system.number", "No")}</th>
              <th className="ant-table-cell" rowSpan={2}>{translate("api.check.name", "Name")}</th>
              <th className="ant-table-cell" rowSpan={2}>{translate("api.check.description", "Description")}</th>
              <th className="ant-table-cell" colSpan={3}>{translate("system.status", "Status")}</th>
            </tr>
          </thead>
        )}
        tbody={(row, index) => (
          renderTableTwo()
        )} />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom : 20px;
  .description { 
    margin-bottom: 15px;
  }
  .btn-group {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
  }
  .ant-pagination { 
    display: none;
  }
`;

const CheckTable = styled(CustomTable)`
  .ant-table-content { 
    table {
      width : auto !important;
    }
  }
  .ant-table-cell {
    padding: 8px!important;
  }
`;
import React from "react";
import { Form, FormItem, Input, Radio } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form as AntForm, Modal } from "antd";
import { Button } from "../../../components/Design";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import ChooserColor from "./ChooserColor";
import { useTranslate } from "../../../hooks";
import colors from "../../../colors";

const { TextArea } = Input;
const { confirm } = Modal;
const FormSchema =(translate) => Yup.object().shape({
  body : Yup.string().max(500).required(translate("system.message.required", "Please fill out this field!")),
  style: Yup.string().max(500).required(translate("system.message.required", "Please fill out this field!"))
});

export default ({ action, onSubmit, onRemove, onCancel }) => {
  const { translate, changeLanguage, lang } = useTranslate();
  const [data] = React.useState({
    body : "",
    style: "",
    ...(action && action[1] === "update" ? action[2] : {}),
  });

  const onConfirm = () => {
    confirm({
      icon   : <ExclamationCircleOutlined />,
      content: translate("system.confirmation.question", "Are you sure ?"),
      async onOk() {
        if (onRemove)
          onRemove(action[2]);
      },
    });
  };

  return (
    <Noteform>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form {...formItemLayout}>
              <FormItem label={translate("privatechat.addnote", "Note")} name="body" required>
                <TextArea name="body" rows="1" placeholder={translate("privatechat.note.placeholder", "Write the note here")} />
              </FormItem>
              <FormItem label={translate("privatechat.note.colors", "Color")} name="style" required>
                <Radio.Group name="style" size="middle" defaultValue="primary">
                  <Radio.Button value="primary">
                    <div className="color-wrapper">
                      <ChooserColor color="#429bf9" selected ={false} ></ChooserColor>
                    </div>
                  </Radio.Button>
                  <Radio.Button value="error">
                    <div className="color-wrapper">
                      <ChooserColor color="#ef808a" selected ={false}></ChooserColor>
                    </div>
                  </Radio.Button>
                  <Radio.Button value="success">
                    <div className="color-wrapper">
                      <ChooserColor color="#55c771" selected ={false}></ChooserColor>
                    </div>
                  </Radio.Button>
                  <Radio.Button value="warning">
                    <div className="color-wrapper">
                      <ChooserColor color="#f5c83a" selected ={false}></ChooserColor>
                    </div>
                  </Radio.Button>
                  <Radio.Button value="info">
                    <div className="color-wrapper">
                      <ChooserColor color="#1dd5f7" selected ={false}></ChooserColor>
                    </div>
                  </Radio.Button>
                </Radio.Group>
              </FormItem>
              <AntForm.Item style={{ marginTop: 20 }}>
                <Button type="primary" htmlType="submit" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
                {action[1] === "update" && <Button type="danger" loading={isSubmitting} style={{ marginRight: 10 }} onClick={onConfirm}>{translate("system.delete", "Remove")}</Button>}
                <Button type="default" onClick={onCancel}>{translate("system.cancel", "Cancel")}</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </Noteform>
  );
};
const Noteform = styled.div`
  margin-bottom: 10px;
  .ant-radio-button-wrapper {
    padding: 0!important;
    border-width: 2px;
    height: inherit;
    line-height: inherit;
    border-radius: 50%!important;
    margin-right: 5px!important;
    margin-top: 2px;
    &.ant-radio-button-wrapper-checked {
      border-color: ${colors.primaryDark};
    }
    &::first-child {
      border-width: 2px!important;
    }
    &::before {
      width: 0!important;
    }
  }
`;

import React from "react";
import { Button, Form as AntForm } from "antd";
import { useTranslate } from "../../../hooks";
import { tailFormItemLayout } from "../../../utils";
import colors from "../../../colors";
import TagForm from "../../../components/Message/components/TagForm";

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const tagFormRef = React.useRef();

  const [data] = React.useState({
    target: undefined,
    ...(action && action[1])
  });

  const onSave = async () => {
    let valid = await tagFormRef.current.validate();

    if (valid) {
      onSubmit({ target: valid });
    }
  };

  return (
    <>
      <TagForm ref={tagFormRef} type="custom" name="Select tag" editable={["update", data.target]} />

      <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
        <Button type="primary" style={{ marginRight: 10 }} onClick={onSave}>{translate("system.save", "Save")}</Button>
        <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
      </AntForm.Item>
    </>
  );
};
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { MyTable } from "../../components";
import { useSelector } from "react-redux";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { operation } from "../../apis";
import { AutoComplete, Button, DatePicker, Empty, Input, Select, Space } from "antd";
import moment from "moment";
import { useFetch, useTranslate } from "../../hooks";
import styled from "styled-components";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import { ManualDesc } from "../../components/Manual";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default () => {

  const { translate } = useTranslate();
  const history = useHistory();
  const { logTypes, lang, customer, customers, user } = useSelector(state => state.general);
  const myTableRef = useRef(null);
  const [query, setQuery] = React.useState(null);
  const [customerfilter, setCustomer] = React.useState(null);
  const [code, setCode] = React.useState(null);
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  let firstDay = new Date(y, m, 1);
  let lastDay = new Date(y, m + 1, 0);
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    query    : null,
    customer : null,
    code     : null,
    startDate: moment(new Date(firstDay)).startOf("day").toDate(),
    endDate  : moment(new Date(lastDay)).endOf("day").toDate()
  });

  const customerColumns = useHeader({
    history,
    logTypes, lang,
    tableTitles: {
      no             : translate("system.number", "No."),
      lineAccountName: translate("report.lineaccount", "LINE Account name"),
      url            : translate("webhook.url", "Webhook URL"),
      logName        : translate("log.type", "Log menu"),
      date           : translate("webhook.log.date", "Date"),
      email          : translate("webhook.email", "Email"),

      retryCount: translate("webhook.retrycount", "Retry count"),

      detail: translate("webhook.log.detail", "Detail"),
    },
    translate
  });

  const changeRange = (e) => {
    setFilters({ ...filters, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };


  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query: query });
    }, 300);
    return () => clearTimeout(timer);
  }, [query, customerfilter, code]);


  React.useEffect(() => {
    // let firstDay = new Date(y, m, 1);
    // let lastDay = new Date(y, m + 1, 0);
    // setFilters({ ...filters, startDate: moment(firstDay).format("YYYY-MM-DD"), endDate: moment(lastDay).format("YYYY-MM-DD") })
    setRangePicker({ startDate: moment(firstDay), endDate: moment(lastDay) });
  }, []);



  return (
    <PageContainer>
      <PageHeader title={translate("webhook.navbar.log", "Webhook log")} extra={[]} />
      <ManualDesc translateKey ="manual.desc.webhookreport" />
      <PageFilter>
        <Space>
          {
            <Input
              name="query"
              value={query}
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              className="ant-input-custom"
              prefix={<SearchOutlined />}
            />
          }
        </Space>
        <Space>
          {/* {customers && customers.length > 0 && <Select
                        showSearch
                        value={customerfilter === undefined ? undefined : customerfilter}
                        placeholder="Select a customer"
                        style={{ width: 200 }}
                        optionFilterProp="children"
                        onChange={e => setCustomer(e)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {customers && customers.map(item => (
                            <Option key={item._id} value={item._id}>{item.companyName}</Option>
                        ))}
                    </Select>} */}


          {/* {!customer && user.role === "ADMIN" ? <Select
                        showSearch
                        value={customerfilter === undefined ? undefined : customerfilter}
                        placeholder={translate("login.username", "Select a customer")}
                        style={{ width: 200 }}
                        optionFilterProp="children"
                        onChange={e => setCustomer(e)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {rows && rows.map(item => (
                            <Option key={item._id} value={item._id}>{item.companyName}</Option>
                        ))}

                    </Select> : null} */}

          <RangePicker allowClear={false} value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />

        </Space>
      </PageFilter>

      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={
            customerColumns
          }
          loadData={operation.webhook}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ logTypes, lang, tableTitles, translate }) => {
  return [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    },
    {
      title : tableTitles.date,
      render: (record) => moment(record.createdAt).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      title : tableTitles.lineAccountName,
      render: (record) => (<div>{record.lineAccountName}</div>)
    },
    {
      title    : tableTitles.url,
      dataIndex: "uri",
    }, {
      title    : tableTitles.email,
      dataIndex: "email",
    }, {
      title    : tableTitles.retryCount,
      dataIndex: "retryCount",
    }, {
      title    : tableTitles.detail,
      dataIndex: "notifyStatusError",
    }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;
import React from "react";
import { Form, FormItem, Input, Select } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { useSelector } from "react-redux";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";
import * as Yup from "yup";
import colors from "../../../colors";

const { Option } = Select;

const ValueScheam = Yup.object({
  _id : Yup.string(),
  name: Yup.string().required("Required!")
});

const FormSchema =(translate) => Yup.object({
  type  : Yup.string().required(translate("system.message.required", "This field is required!")),
  name  : Yup.string().max(50, translate("tag.name.limit", "Tag name exceeded character limit")).required(translate("system.message.required", "This field is required!")),
  values: Yup.array().of(ValueScheam)
});

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});


export default React.forwardRef((props, ref) => {
  const { action, onSubmit, onCancel } = props;
  const { translate } = useTranslate();
  const { tagTypes } = useSelector(state => state.general);
  const submitRef = React.useRef();

  const [data, setFormData] = React.useState({
    type  : "TAG",
    name  : "",
    values: [],
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onAddValue = (values) => {
    setFormData({
      ...values,
      values: [...values.values, {
        _id : undefined,
        name: undefined
      }]
    });
  };

  const onChangeType = (value, values) => {
    setFormData({
      ...values,
      type  : value,
      values: value === "SELECT" ? [{
        _id : undefined,
        name: undefined
      }] : []
    });
  };

  const onRemoveValue = ({ values, index }) => {
    if (values.values <= 1) return;

    setFormData({
      ...data,
      values: values.values.filter((value, i) => i !== index)
    });
  };

  const renderFields = ({ values, index }) => {
    switch (values.type) {
      case "SELECT": {
        return (
          <FormItem label={index + 1} key={index} name={`values[${index}].name`}>
            <InlineGroup>
              <Input type="text" name={`values[${index}].name`} />
              {values.values && values.values.length > 1 && <Button type="circle" style={{ marginLeft: 10 }} onClick={() => onRemoveValue({ values, index })}><MinusOutlined /></Button>}
            </InlineGroup>
          </FormItem>
        );
      }
      default:
        return <></>;
    }
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let values = await submitRef.current.submitForm();
      return values;
    },
    clear() {
      setFormData({});
    }
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={(e)=> console.log(e)}>
      {({ isSubmitting, values }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem label={translate("tag.manage.name", "Name")} name="name" required>
              <Input name="name" placeholder={translate("tag.manage.name.placeholder", "Tag name must be under 50 characters")} />
            </FormItem>
            <FormItem label={translate("tag.manage.type", "Type")} name="type" required>
              <Select name="type" placeholder="Select" onChange={(value) => onChangeType(value, values)} style={{ width: "100%" }}>
                {tagTypes.map((type, index) => {
                  return <Option key={index} value={type.code}>{translate(`system.constant.${type.code.toString()}`, type.name)}</Option>;
                })}
              </Select>
            </FormItem>

            {values.values.map((value, index) => {
              return renderFields({ values, value, index });
            })}

            {values.type === "SELECT" && (
              <AntForm.Item {...tailFormItemLayout}>
                <Button type="default" onClick={() => onAddValue(values)}><PlusOutlined />{translate("system.add", "Add")}</Button>
              </AntForm.Item>
            )}

            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button onClick={onSubmit} type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
            <SubmitForm ref={submitRef} />

          </Form>
        );
      }}
    </Formik>
  );
});

const InlineGroup = styled.div`
  display: flex;
  flex-direction: row;
`;
import React from "react";
import { Button, message, Spin } from "antd";
import styled from "styled-components";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { documented as documentedApi } from "../../apis/";
import renderHTML from "react-render-html";
import { useFetch, useTranslate } from "../../hooks";
import { useRouteMatch } from "react-router";
import { FileOutlined } from "@ant-design/icons";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";



export default () => {

  const match = useRouteMatch();
  const { translate } = useTranslate();
  const [body, setBody] = React.useState("");
  const [height, setHeight] = React.useState(0);
  const [filter, setFilter] = React.useState({ type: match.params.type.toUpperCase() });
  const [document, setDocument] = React.useState();
  const [load, setLoad] = React.useState(true);
  const [title, setTitle] = React.useState(match.params.type === "terms" ? `${translate("navbar.termsOfUse", "Terms of Use")}` : `${translate("navbar.privacyPolicy", "Privacy policy")}`);

  const reload = async () => {
    setLoad(true);

    let res = await documentedApi.get({ type: match.params.type.toUpperCase() });
    setDocument(res);
    setLoad(false);
  };

  const handleResize = (e) => {
    setHeight(window.innerHeight);
  };

  const onSubmit = async () => {
    if (document !== null) {
      await documentedApi.edit({ ...document, body: body });
      message.success(translate("system.message.success", "Success"));
    } else {
      await documentedApi.create({
        body: body,
        type: match.params.type.toUpperCase()
      });
      message.success(translate("system.message.success", "Success"));
    }
  };


  React.useEffect(() => {
    if (match.params.type === "terms") {
      setTitle(`${translate("navbar.termsOfUse", "Terms of Use")}`);
    }
    else {
      setTitle(`${translate("navbar.privacyPolicy", "Privacy policy")}`);
    }
    reload();
  }, [match.params.type]);

  React.useEffect(() => {
    setBody(`${document?.body}`);
  }, [document]);

  React.useEffect(() => {
    setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
  }, []);


  return (
    <PageContainer>
      <PageHeader title={translate(`docuemnt.${match.params.type}`, `${title}`)} extra={[
        <Button disabled={body === ""} key="createButton" type="primary" onClick={() => onSubmit()} ><FileOutlined /> {translate("system.save", "Save")} </Button>
      ]} />
      <PageContent>
        <Container>
          <Editor>
            <Spin spinning={load}>
              {load == false && <SunEditor
                setContents={body}
                enableToolbar={true}
                showToolbar={true}
                height={height - 170}
                onChange={(e) => {
                  setBody(e);
                }} />}
            </Spin>
          </Editor>
          <View style={{ height: height - 103 }}>
            {renderHTML(body)}
          </View>
        </Container>
      </PageContent>
    </PageContainer>
  );
};

const Container = styled.div`
  display: flex;
`;
const Editor = styled.div`
  margin-right: 2%;
  width:49%;
`;
const View = styled.div`
  overflow-y: scroll;
  border:solid 1px #CCCCCC;
  padding: 0 15px;
  width:49%;
`;
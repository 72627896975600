
import CarouselNew from "./New";
import CarouselEdit from "./Edit";
import { PageHeader, PageContainer, PageContent } from "../../components/Layout";
import React from "react";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { Button, Empty, Input, message, Space, Avatar } from "antd";
import { carousel as carouselApi } from "../../apis";
import { MyTable, RowAction } from "../../components";
import { useHistory } from "react-router";
import confirm from "antd/lib/modal/confirm";
import colors from "../../colors";
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { ManualDesc, ManualLink } from "../../components/Manual";

export default ()=> {
  const { translate } = useTranslate();
  const [filters, setFilters] = React.useState({
    query: ""
  });
  const [query, setQuery] = React.useState("");
  const myTableRef = React.useRef();
  const history = useHistory();

  const { carouselTypes, lang } = useSelector(state =>state.general);
  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array?.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };


  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  const columns = useHeader({
    history,
    tableTitles: {
      no    : translate("system.number", "No."),
      title : translate("carousel.table.title", "Title"),
      cards : translate("carousel.table.cards", "Cards"),
      type  : translate("carousel.table.type", "Card type"),
      action: translate("system.action", "Action"),
      edit  : translate("system.edit", "Edit"),
      remove: translate("system.delete", "Remove"),
      copy  : translate("system.copy", "Copy"),
      image : translate("imagemap.image", "Image"),
    },
    translate,
    onAction: async (key, item) => {
      switch (key) {
        case "copy": {
          console.log("*****(COPY)*****");
          await carouselApi.copy(item._id);
          myTableRef.current.reload();
          break;
        }
        case "edit": {
          history.push(`/carousel/edit/${item._id}`);
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await carouselApi.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
    lang,
    carouselTypes: convertArrayToObject(carouselTypes, "code"),

  });

  return (
    <Container>
      <PageContainer>
        <PageHeader subTitle={<ManualLink link="manual.link.carousel"></ManualLink>} title={translate("carousel.title", "Card type message")} extra={[
          <Button key="createButton" type="primary" onClick={() => history.push("/carousel/new")} ><PlusOutlined /> {translate("system.add", "Create")} </Button>
        ]} />
        <ManualDesc translateKey ="manual.desc.carousel" />
        <PageFilter>
          <Space>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              className="ant-input-custom"
              prefix={<SearchOutlined />}
            />
          </Space>
        </PageFilter>
        <PageContent>
          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            ref={myTableRef}
            filters={filters}
            rowKey={(record) => record._id}
            columns={columns}
            loadData={carouselApi.list}
          />
        </PageContent>
      </PageContainer>
    </Container>
  );
};

const useHeader = ({ onAction, tableTitles, carouselTypes, lang }) => {
  if (lang){
    return [{
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    }, {
      title : tableTitles.image,
      width : "100px",
      render: (item) => {
        return (<Avatar shape="square" size={100} src={`${item?.columns[0]?.thumbnailImageUrl}`}/>);
      }
    }, {
      width    : "40%",
      title    : tableTitles.title,
      dataIndex: "altText"
    }, {
      title : tableTitles.type,
      render: (row)=> {
        return (<div>{carouselTypes[row.type][lang?.toLowerCase()]}</div>);
      }
    }, {
      title : tableTitles.cards,
      render: (item)=> {
        return item.columns.length;
      }
    }, {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (row) => {
        return (
          <RowAction
            actions={{
              copy  : tableTitles.copy,
              edit  : tableTitles.edit,
              remove: tableTitles.remove,
            }}
            onClick={(key) => onAction(key, row)}
          />
        );
      },
    }];
  }
};


const Container = styled.div`
`;


const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;


export {
  CarouselNew,
  CarouselEdit
};
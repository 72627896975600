import React, { useState } from "react";
import { Form, FormItem, Select, DatePicker, } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Spin } from "antd";
import * as Yup from "yup";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import styled from "styled-components";

const { RangePicker } = DatePicker;


const FormSchema = (translate) => Yup.object({
  schedules: Yup.array().of(Yup.date().required()).required(translate("system.message.required", "Please fill out this field!"))
});

export default ({ onSubmit }) => {
  const { translate } = useTranslate();
  const [data] = useState({
    schedules: undefined,
  });


  return (
    <FormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting, values, handleSubmit }) => {
          return (
            <Form {...formItemLayout}>
              <AntForm.Item label={translate("broadcast", "Broadcast Time")}>
                <FormItem name="schedules">
                  <RangePicker placeholder={translate("broadcast.startdate", "Send date")} name="schedules" />
                </FormItem>
              </AntForm.Item>
              <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
                <Button onClick={handleSubmit} type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  .send-date {
    display: inline-flex;
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width : 100%;
      }
    }
    .ant-form-item-control-input {
      width   : 381px;
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  .send-now {
    display: inline-block;
    padding: 10px 0;
    width: 100%;
    .ant-picker {
      width: 245px;
      margin-left: 10px;
    }
  }
`;
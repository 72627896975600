import React from "react";
import { ErrorMessage, Field } from "formik";
import styled from "styled-components";
import colors from "../../colors";
import MaskInput from "react-maskinput";
import { useTranslate } from "../../hooks";

export default ({ size, name, action, ...restProps }) => {
  const { translate } = useTranslate();

  return (
    <Field name={name}>
      {fieldProps => {
        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError }
        } = fieldProps;

        return (
          <Container>
            <MaskInput {...restProps} onChange={(e)=> {
              setFieldValue(name, e.target.value);
            }}/>
          </Container>
        );
      }}
    </Field>
  );
};

const Container = styled.div`
  input {
    border : 1px solid #d9d9d9;
    height:32px;
    width :100%;
    border-radius: 2px;
    transition: all 0.3s;
    padding: 0px 10px;
    ::placeholder {
      color: #9c9c9c;
    }
    &.error {
      background-color: #fff;
      border-color: #ff4d4f;
    }
  }
  
  
  input:hover{
    border-color: #1bab5c;
    border-right-width: 1px !important;
  }
  input:focus {
    border-color: #1bab5c;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(0 157 75 / 20%);
  }
`;
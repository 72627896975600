import React from "react";
import Form from "./Form";
import { Button, message, Modal, Spin, Tag } from "antd";
import { useTranslate } from "../../hooks";
import { rakuten, auth, account as accountApi } from "../../apis";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import colors from "../../colors";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import usePlan from "../../hooks/usePlan";

export default ()=>{
  const { translate } = useTranslate();
  const submitRef = React.useRef();
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const { checkPermission } = usePlan();
  const [load, setLoad] = React.useState(false);
  const { account, customer } = useSelector(state => state.general);
  const [planPermission] = React.useState(checkPermission("RAKURAKU"));

  const onSubmit = async ()=> {
    setLoad(true);
    let connect = await submitRef.current.validate();
    if (!connect) {
      setLoad(false);
      return ;
    }
    try {
      await accountApi.rakurakuCon(connect.id, {
        rakurakuToken: connect.rakurakuToken?.trim(),
      });
      await auth.me()(dispatch);
      message.success(translate("system.message.success", "Success!"));
      setLoad(false);

    }
    catch (err){
      if (err?.message === "RAKURAKUTOKEN_USED"){
        message.error(translate("rakuraku.error.tokenused", "Your Secret Key is already in use !"));
      }
      if (err?.message === "RAKURAKU_HTTP_ERROR"){
        message.error(translate("rakuraku.error.http", "Rakuraku api error!"));
      }
      if (err?.message === "RAKURAKU_UNAUTHORIZED"){
        message.error(translate("rakuraku.error.unauthorized", "Rakuraku token unauthorized!"));
      }
      setLoad(false);
    }
  };

  const onDisconnect =async ()=> {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      content   : translate("rakuraku.connection.alert.disconnect", "When disconnected from Rakurakupon various integrated parts of the system will stop working, if it's okay with you click Yes?"),
      async onOk() {
        setLoad(true);
        try {
          await accountApi.rakurakuDiscon(data.id);
          await auth.me()(dispatch);
          message.success(translate("system.message.success", "Success!"));
          setLoad(false);
        } catch (err){
          if (err?.message === "RAKURAKU_HTTP_ERROR"){
            message.error(translate("rakuraku.error.http", "Rakuraku api error!"));
          }
          if (err?.message === "RAKURAKU_UNAUTHORIZED"){
            message.error(translate("rakuraku.error.unauthorized", "Rakuraku token unauthorized!"));
          }
          setLoad(false);
        }
      } });
  };

  const actions =()=> {
    return [
      <Button key="new" onClick={onDisconnect} type="primary" disabled={!account?.rakurakuToken} danger>{translate("rakuraku.connective.disconnect", "Disconnect")}</Button>,
      <Button loading={load} key="new" onClick={onSubmit} type="primary" disabled={account?.rakurakuToken} >{translate("system.save", "Save")}</Button>,
    ];
  };

  React.useEffect(()=> {
    setLoad(true);
    setData({
      rakurakuToken: account?.rakutenServiceSecret,
      id           : account?._id
    });
    setLoad(false);
  }, [account]);

  return (
    <PageContainer>
      <PageHeader subTitle={!planPermission ? <div>{translate("plan.permission.isblocked", "Plan setting is off")}</div> : ""} title={translate("rakupon.connection", "Rakurakupon connective")} extra={actions()}/>

      <PageContent>
        <Spin spinning={load === true}>
          <div style={{ height: "500px" }}>
            {load === false && <Form ref={submitRef} action ={["update", data]}/>}
          </div>
        </Spin>
      </PageContent>
    </PageContainer>
  );
};
import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { News } from "./components";
import { useTranslate } from "../../hooks";
import styled from "styled-components";
import colors from "../../colors";
import { CopyOutlined, MessageOutlined, SendOutlined } from "@ant-design/icons";
import Sent from "./components/customer/charts/Sent";
import Send from "./components/customer/charts/Send";
import { PanelBody, PanelHeader, PanelWrapper, PanelContainer } from "../../components/Design/Panel";
import { useSelector } from "react-redux";
import TotalPlan from "./components/admin/TotalPlan";
import TotalUsers from "./components/admin/TotalUsers";
import TotalCustomers from "./components/agency/TotalUsers";
import TotalCustomersCount from "./components/agency/TotalUsersCount";
import { ManualLink } from "../../components/Manual";

export default () => {
  const { translate } = useTranslate();
  const { customer, customers, user } = useSelector((state => state.general));

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.dashboard"></ManualLink>} title={translate("dashboard.title", "Dashboard")}/>
      <PageContent>
        {customers.length > 0 && <TotalCustomersCount customers={customers} />}
        {customer === undefined && customers.length === 0 && user.role ==="ADMIN" && (
          <div style={{ width: "100%" }}>
            <TotalUsers style={{ width: "100%" }} />
          </div>
        )}
        {customer &&
          <PanelContainer>
            <PanelWrapper style={{ border: "1px solid #f0f0f0" }}>
              <PanelHeader style={{ alignItems: "flex-start", paddingLeft: "20px" }} >
                <strong><CopyOutlined style={{ marginRight: "2px" }} />{translate("dashboard.announcements", "Announcements")}</strong>
              </PanelHeader>
              <PanelBody className="panel-body">
                <News />
              </PanelBody>
            </PanelWrapper>
            <br />
          </PanelContainer>
        }
        <PanelContainer style={{ margin: "20px 0" }}>
          <PanelWrapper>
            <PanelBody>
              {/* <PlanWrapper style={{ width: "100%" }}>
                {customer && (
                  <div className="currentPlan" >
                    <Descriptions title="" bordered>
                      <Descriptions.Item label={<div className="select-plan-header">{translate("plan.current.title", "Current plan")}</div>}><div style={{ color: "rgb(0 157 75)" }}>
                        <span style={{ fontSize: "1.4em" }}>{customer && customer.plan.name}</span></div>
                      <div style={{ color: "black" }}>{translate("dashboard.messagebalance", "Message limit:")} <strong>{customer && customer.plan.messageLimit === -1 ? "Unlimited" : customer.plan.messageLimit}</strong></div>
                      <div style={{ color: "black" }}>{translate("dashboard.unitprice", "Additional messages fee:")} <strong>{customer && customer.plan.unitPrice.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</strong></div>
                      <div style={{ color: "black" }}>{translate("plan.duedate", "Next Payment Date:")} <strong>{moment(user.balance && user.balance.expireIn).format("YYYY-MM-DD") || "-"}</strong></div>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                )}
              </PlanWrapper> */}
            </PanelBody>
          </PanelWrapper>
        </PanelContainer>
        <Container>
          <ChartWrapper>
            <div className="chart-header" style={{ justifyContent: "flex-start", paddingLeft: "20px" }}>
              <strong><SendOutlined style={{ marginRight: " 10px" }} /> {customer === undefined && customers.length === 0 ? translate("dashboard.sent", "Total sent messages") : translate("dashboard.sent", "Total sent messages")}</strong>
            </div>
            <div className="chart-container">
              {customer !== null ?
                <Sent customer={customer && customer} customers={customers && customers} /> :
                <Sent customer={customer && customer} customers={customers && customers} />
              }
            </div>
          </ChartWrapper>
          <div style={{ width: "10px" }}></div>
          <ChartWrapper >
            <div className="chart-header" style={{ justifyContent: "flex-start", paddingLeft: "20px" }}>
              <strong><MessageOutlined style={{ marginRight: " 10px" }} />{customer === undefined && customers.length === 0 ? translate("dashboard.scheduled", "Total scheduled messages") : translate("dashboard.scheduled", "Total scheduled messages")} </strong>
            </div>
            <div className="chart-container">
              {customer !== null ?
                <Send customer={customer && customer} customers={customers && customers} user ={user} /> :
                <Send customer={customer && customer} customers={customers && customers} user ={user} />
              }
            </div>
          </ChartWrapper>
        </Container>

        {customers.length > 0 && <TotalCustomers customers={customers} />}

        {customer === undefined && customers.length === 0 ?
          <div style={{ width: "100%" }}>
            <TotalPlan style={{ width: "100%" }} />
          </div> : null}
      </PageContent>
    </PageContainer>
  );
};

const ChartWrapper = styled.div`
    margin  : 20px  0px;
    width   : 50%;
    display : flex;
    border  : solid 1px rgb(244 244 247);
    border-radius : 5px;
    flex-direction : column;
    .chart-header{
      /* padding-left : 20px; */
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background-color :${colors.primaryDark};
      display : flex;
      justify-content :space-around;
      align-items : center;
      height : 40px;
      strong {
        font-size : 15px;
        color     : white;
      }
    }
    .chart-container {
      display :flex;
    }`;

const Container = styled.div`
  display : flex;
  justify-content :space-between;
`;

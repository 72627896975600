import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dragging, IsInvalid } from ".";
import { useTranslate } from "../../../hooks";

export const TimerMenu = ({ x, y }) => {
  const { translate } = useTranslate();
  return (
    <Dragging transform={`translate(${x}, ${y})`} menu={{ type: "timer" }}>
      <path stroke="rgb(204, 153, 0)" fill="rgb(255, 191, 0)" d="m 0 4 A 4 4 0 0 1 4 0 H 12 c 2 0 3 1 4 2 l 4 4 c 1 1 2 2 4 2 h 12 c 2 0 3 -1 4 -2 l 4 -4 c 1 -1 2 -2 4 -2 H 169 a 4 4 0 0 1 4 4 v 40 a 4 4 0 0 1 -4 4 H 48 c -2 0 -3 1 -4 2 l -4 4 c -1 1 -2 2 -4 2 h -12 c -2 0 -3 -1 -4 -2 l -4 -4 c -1 -1 -2 -2 -4 -2 H 4 a 4 4 0 0 1 -4 -4 z" />
      <text fill="#fff" transform="translate(8, 30)" style={{ userSelect: "none" }}>{translate("shape.timer", "Timer")}</text>
    </Dragging>
  );
};

export const TimerGhost = ({ children, transform }) => {
  return (
    <g transform="translate(0, 0)">
      <path stroke="#e5e5e5" fill="#e5e5e5" d="mm 0 4 A 4 4 0 0 1 4 0 H 12 c 2 0 3 1 4 2 l 4 4 c 1 1 2 2 4 2 h 12 c 2 0 3 -1 4 -2 l 4 -4 c 1 -1 2 -2 4 -2 H 169 a 4 4 0 0 1 4 4 v 40 a 4 4 0 0 1 -4 4 H 48 c -2 0 -3 1 -4 2 l -4 4 c -1 1 -2 2 -4 2 h -12 c -2 0 -3 -1 -4 -2 l -4 -4 c -1 -1 -2 -2 -4 -2 H 4 a 4 4 0 0 1 -4 -4 z" />
      <g transform={transform}>{children}</g>
    </g>
  );
};

export default ({ child, children, dragging, draggable, transform, index }) => {
  const dispatch = useDispatch();
  const { childrenForm } = useSelector(state => state.process);
  const { translate } = useTranslate();

  const onChange = (e, child) => {
    e.stopPropagation();
    dispatch({ type: "process.selected", payload: {} });
    dispatch({ type: "process.selected", payload: child });
  };

  const renderTimer = () => {
    if (childrenForm[child._id]?.timerType === "TIME") {
      let hours = childrenForm[child._id]?.hours; hours = hours < 10 ? `0${hours}` : hours;
      let minutes = childrenForm[child._id]?.minutes; minutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${hours}:${minutes}`;
    } if (childrenForm[child._id]?.timerType === "DAYS") {
      let days = childrenForm[child._id]?.days;
      let time = childrenForm[child._id]?.time;

      return `${days}~(${moment(time).format("hh:mm")})`;
    }

    return "--:--";
  };

  return (
    <Dragging dragging={dragging} draggable={draggable} child={child} index={index} cursor="pointer" onClick={(e) => onChange(e, child)}>
      <path stroke="rgb(204, 153, 0)" fill="rgb(255, 191, 0)" d="m 0 4 A 4 4 0 0 1 4 0 H 12 c 2 0 3 1 4 2 l 4 4 c 1 1 2 2 4 2 h 12 c 2 0 3 -1 4 -2 l 4 -4 c 1 -1 2 -2 4 -2 H 169 a 4 4 0 0 1 4 4 v 40 a 4 4 0 0 1 -4 4 H 48 c -2 0 -3 1 -4 2 l -4 4 c -1 1 -2 2 -4 2 h -12 c -2 0 -3 -1 -4 -2 l -4 -4 c -1 -1 -2 -2 -4 -2 H 4 a 4 4 0 0 1 -4 -4 z" />
      <text fill="#fff" transform="translate(8, 30)" style={{ userSelect: "none" }}>{translate("shape.timer.set", "Set timer")}</text>
      <IsInvalid child={child} strokeWidth={3} strokeColor="rgb(204, 153, 0)" draggable={draggable}>
        {({ strokeColor, strokeWidth }) => (
          <g transform="translate(30, 0)">
            <path fill="#fff" stroke={strokeColor} strokeWidth={strokeWidth} d="m 0,0 m 16,0 H 70 a 16 16 0 0 1 0 32 H 16 a 16 16 0 0 1 0 -32 z" transform="translate(48 8)"></path>
            <text x="34" y="18" fill="#333" dominantBaseline="middle" dy="0" textAnchor="middle" transform="translate(48 8) translate(8)">{renderTimer()}</text>
          </g>
        )}
      </IsInvalid>
      <g transform={transform}>{children}</g>
    </Dragging>
  );
};
import React from "react";
import { FormItem } from "formik-antd";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";
import { Button } from "antd";
import RichTextArea from "./RichTextArea";

export default ({ name, text, characters, onChange, onMouseDown }) => {
  const { translate } = useTranslate();
  const [emojiVisible, setEmojiVisible] = React.useState("none");

  const emojiWindow = () => {
    if (emojiVisible === "none") {
      setEmojiVisible("");
    } else {
      setEmojiVisible("none");
    }
  };

  return (
    <>
      <FormItem name={name}>
        <RichTextArea
          value={text}
          onAction={(e) => setEmojiVisible(e)}
          characters={characters}
          onChangeValues={onChange}
          onMouseDown={onMouseDown}
          action={emojiVisible} />
      </FormItem>

      <EmojiButtonGroup>
        <Button onClick={emojiWindow}>{translate("message.emoji", "Emoji")}</Button>
      </EmojiButtonGroup>
    </>
  );
};

const EmojiButtonGroup = styled.div`
  margin-top: 10px;
`;
import React from "react";
import styled from "styled-components";

export default ({ size, onClick, packageId, id }) => {
  return (
    <Sticker className={size} onClick={onClick}>
      <img src={`https://stickershop.line-scdn.net/stickershop/v1/sticker/${id}/IOS/sticker.png`} alt={`sticker_${id}`} />
      {/* <img src={`/sticker/${packageId}/${id}_key@2x.png`} /> */}
    </Sticker>
  );
};

const Sticker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 70px;
  height: 70px;
  &:hover {
    img {
      width: 70px;
    }
  }
  &.large {
    width: 180px;
    height: 180px;
    &:hover {
      width: 180px!important;
    }
    img {
      width: 180px;
    }
  }
  img {
    display: flex;
    width : 60px;
    transition: width .1s; 
  }
`;

import React from "react";
import { tagForm as tagFormApi, tags } from "../../apis";
import { Space, message, Empty, Button } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Modal } from "antd";
import styled from "styled-components";
import colors from "../../colors";
import { useFetch, useTranslate } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";


const { confirm } = Modal;

export default ({ select }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");

  useFetch(tags.select(dispatch))();


  const [filters, setFilters] = React.useState({
    query: ""
  });
  const create =()=> {
    history.push("/message/target_form/new");
  };
  const columns = useHeader({
    history,
    translate,
    select,
    tableTitles: {
      no    : translate("system.number", "No."),
      name  : translate("target.form.name", "Target Group"),
      action: translate("system.action", "Action"),
      edit  : translate("system.edit", "Edit"),
      remove: translate("system.delete", "Remove"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/message/target_form/edit/${item._id}`);
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await tagFormApi.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);


  return (
    <Container>
      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <MyTable
        locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
        ref={myTableRef}
        filters={filters}
        rowKey={(record) => record._id}
        columns={columns}
        loadData={tagFormApi.list}
      />
    </Container>
  );
};

const useHeader = ({ onAction, tableTitles, translate, select }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.name,
    render: (record) => {
      return `${record.name}`;
    },
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (item) => {
      return (
        <Button onClick={()=>select(item)}>{translate("system.add.import", "Import")}</Button>
      );
    },
  }];
};

const Container =styled.div`
  .ant-table-content {
    table {
      width: 300px !important;
    }
  }
`;
const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  justify-content: space-between;
  
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

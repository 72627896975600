import React from "react";
import { useHistory } from "react-router";
import { message, Space, Input, Empty, Checkbox, Tag, Button } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useTranslate, usePermission } from "../../hooks";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { RowAction, MyTable } from "../../components";
import colors from "../../colors";
import moment from "moment";
import styled from "styled-components";
import confirm from "antd/lib/modal/confirm";
import { infoLogin as infoLoginApi } from "../../apis";


export default ()=> {
  const history = useHistory();
  const myTableRef = React.useRef(null);
  const { translate, changeLanguage } = useTranslate();
  const [query, setQuery] = React.useState("");




  const column = useHeader({
    history,
    translate,
    tableTitles: {
      no    : translate("system.number", "No."),
      title : translate("system.title", "Title"),
      desc  : translate("announcement.description", "Description"),
      action: translate("system.action", "Action"),
      date  : translate("message.createddate", "Date"),
      edit  : translate("system.edit", "Edit"),
      remove: translate("system.delete", "Remove"),
      view  : translate("news.view", "View"),
      status: translate("system.status", "Status"),
      hide  : translate("news.hide", "Hide"),
      show  : translate("news.show", "Show"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/admin/news/${item._id}`);
          break;
        }
        case "view": {
          history.push(`/news/detail/${item._id}`);
          break;
        }
        case "remove": {
          confirm({
            icon      : <ExclamationCircleOutlined />,
            content   : translate("system.confirmation.question", "Are you sure ?"),
            okText    : translate("system.yes", "Yes"),
            cancelText: translate("system.no", "No"),
            async onOk() {
              await infoLoginApi.remove(item._id);

              message.success(translate("system.message.success", "Successful"));

              myTableRef.current.reload();
            },
            okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark } }
          });
          break;
        }
        default:
      }
    },
  });


  return (
    <PageContainer>
      <PageHeader title={translate("", "Info login")}/>
      <PageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>

        </Space>
        <Space>
          <Button key="new" onClick={()=> {history.push("/info/login/new");}} type="primary">{translate("system.add", "Add")}</Button>
        </Space>
      </PageFilter>

      <PageContent>

        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          // filters={filters}
          ref={myTableRef}
          rowKey={(record) => record._id}
          columns={column}
          loadData={infoLoginApi.list}
        />

      </PageContent>
    </PageContainer>
  );
};



const useHeader = ({ onAction, tableTitles, isPriority, translate }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title    : tableTitles.title,
    dataIndex: "title",
  }, {
    title : tableTitles.date,
    render: (item) => {
      return (
        <div>
          {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
        </div>
      );
    }
  }, {
    title    : tableTitles.desc,
    dataIndex: "desc",
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (item) => {
      if (item.hide === true) {
        return <RowAction actions={{
          edit  : tableTitles.edit,
          remove: tableTitles.remove,
          view  : tableTitles.view,
        }} onClick={(key) => onAction(key, item)} />;
      }
    }
  }];
};



const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;
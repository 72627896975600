import React from "react";
import { Skeleton } from "antd";
import styled from "styled-components";

export default ({ type, clsName }) => {
  if (type === "friends"){
    return (
      <div>
        <div className="friend"><Skeleton active="true" avatar paragraph={{ rows: 2 }} /></div>
        <div className="friend"><Skeleton active="true" avatar paragraph={{ rows: 2 }} /></div>
        <div className="friend"><Skeleton active="true" avatar paragraph={{ rows: 2 }} /></div>
        <div className="friend"><Skeleton active="true" avatar paragraph={{ rows: 2 }} /></div>
        <div className="friend"><Skeleton active="true" avatar paragraph={{ rows: 2 }} /></div>
      </div>
    );
  }
  if (type === "chat"){
    return (
      <div className={clsName} style={{ marginTop: "40px", width: "200px" }}>
        <Skeleton active="true" avatar paragraph={{ rows: 2 }} />
      </div>
    );
  }
  if (type === "chat-right"){
    return (
      <div className={clsName} style={{ marginTop: "40px" }}>
        <Skeleton.Input style={{ width: "200px" }} active={true} size="small" paragraph={{ rows: 2 }} />
        <Skeleton.Button active={true} size="small" shape="circle" style={{ marginLeft: "20px" }} />
      </div>
    );
  }
  if (type === "memo"){
    return (
      <LoadStyle>

      </LoadStyle>
    );
  }
};

const LoadStyle = styled.div`
.ant-skeleton-with-avatar {
  margin-top :-4px;
}
`;
import React from "react";
import { Tag } from "antd";
import styled from "styled-components";
import { ClockCircleOutlined } from "@ant-design/icons";
import colors from "../../colors";
import { useTranslate } from "../../hooks";
import moment from "moment";
import { useSelector } from "react-redux";


export default ({ balanceMessage, plan }) => {
  const { user } = useSelector(state => state.general);
  const { translate } = useTranslate();
  const [spent, setSpent] = React.useState(0);
  const sentPercent = Math.round(balanceMessage.sent * 100 / balanceMessage.limit);
  const datePercent = Math.round(balanceMessage.date * 100 / balanceMessage.limit);

  // const usagePercentage = Math.round(balance.sent / balance.limit * 100);
  // const usageSendPercentage = Math.round(balance.date / balance.limit * 100);
  React.useEffect(() => {
    setSpent(balanceMessage.sent + balanceMessage.date);
  }, []);

  return (
    <Container>
      <div className="header">
        <div className="title">{translate("messages.progress", "Messages sent this month:")}</div>
        {balanceMessage.limit === -1 ? <div>{plan.name}:<Tag color="green"> {translate("system.unlimited", "Unlimited")}</Tag></div> : <div>{plan.name}:<Tag color="green">{spent} / {balanceMessage.limit}</Tag></div>}
      </div>

      <div className="progress-info-container">
        <div className="progress-info">
          <span className="progress-info-item-sent">{translate("message.progress.sent", "Sent") }</span>
          <span className="progress-info-item-scheduled">{translate("message.progress.schedule", "Scheduled")}</span>
        </div>
        <div className="progress-info-text">
          {translate("message.progress.explanation", "Sometimes estimated sent count is different from the actual sent count")}
        </div>
      </div>
      <div className="process">
        {sentPercent > 0 &&<span className="sent-percent" style={{ width: `${sentPercent > 100 ? 100 : sentPercent}%` }}>{sentPercent > 100 ? 100 : sentPercent}%</span>}
        {datePercent > 0 &&<span className="date-percent" style={{ width: `${datePercent > 100 ? 100 : datePercent}%` }}>{datePercent > 100 ? 100 : datePercent}%</span> }
      </div>
      <div className="footer">
        <div className="timezone"><ClockCircleOutlined /> Asia/ Tokyo</div>
        <div className="nextDueDate">
          {translate("plan.duedate", "Next Payment Date:")} {user.balance && user.balance.expireIn && moment(user.balance.expireIn).format("YYYY-MM-DD") || "-" }
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 20px 24px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
      font-weight: 500;
    }
    .ant-tag {
      margin-left: 5px;
      margin-right: 0;
    }
  }
  .progress-info-container {
    display: flex;
    width  : 100%;
    justify-content: space-between;
    .progress-info-text {
      color :#a5a5a5;
    }
  }
  .progress-info {
    display :flex;
    .progress-info-item-scheduled {
      padding: 5px 10px;
      background-color :${colors.primary}!important;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    .progress-info-item-sent {
      padding: 5px 10px;
      background-color :${colors.primaryDark}!important;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right :5px;
      
    }
  }
  .process {
    width: 100%;
    height: 20px;
    background: #f7f7f7;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
    display :flex;
    .sent-percent {
      display: block;
      height: 20px;
      background: ${colors.primaryDark};
      text-align: right;
      color: #f7f7f7;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      padding-right: 10px;
    }
    .date-percent {
      display: block;
      height: 20px;
      background: ${colors.primary};
      text-align: right;
      color: #f7f7f7;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      padding-right: 10px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .timezone {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    font-size: 12px;
    margin-top: 5px;
    color: ${colors.primaryDark};
    font-weight: 400;
    .anticon {
      display: flex;
      color: ${colors.primaryDark};
      margin-right: 2px;
    }
  }
`;
import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import styled from "styled-components";
import { Button } from "../../components/Design";
import { customer as customerApi, payment, auth } from "../../apis";
import { Descriptions, Alert, Modal, message, Table, Tooltip, Spin, Input } from "antd";
import { CloseCircleOutlined, GiftOutlined, PrinterOutlined, SearchOutlined } from "@ant-design/icons";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import colors from "../../colors";
import { ExclamationCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PaymentMethod } from "../../components";
import Receipt from "./components/Receipt";
import Money from "../../assets/money.png";
import PlanAction from "./components/PlanAction";

const { confirm } = Modal;

export default () => {
  const history = useHistory();
  const { translate } = useTranslate();
  const [filters, setFilters] = React.useState({ query: null });
  const [query, setQuery] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);
  const [tableLoad, setTableLoad] = React.useState(false);
  const { checkRole } = usePermission();
  const [disabled, setDisabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN"]));

  // const [{ plans, current }, fetching, setPlanResults] = useFetch(customerApi.plans, null)({ plans: [], current: null });
  const [receiptVisible, setReceiptVisible] = React.useState(false);
  const [plans, setPlans] = React.useState([]);
  const [current, setCurrent] = React.useState({});
  const [order, setOrder] = React.useState({
    planData: {
      _id: null
    }
  });

  const { accounts, user, gmoUrl } = useSelector(state => state.general);
  const dispatch = useDispatch();
  const [action, setAction] = React.useState([]);


  const onAccountLogout = async () => {
    dispatch({ type: "main.load", payload: true });
    await customerApi.logout();
    await auth.me()(dispatch);

    message.success(translate("system.message.success", "Success"));
    history.push("/customer");
    dispatch({ type: "main.load", payload: false });
    dispatch({ type: "socket.on.conversation", payload: {} });
  };




  const switchPayment = (purchase, item) => {
    if (item.type === "FREE") {
      message.success(translate("system.message.success", "Success"));
      onFinish(null, null, item);
    }

    else if (item.plan && item.plan.type && item.plan.type === "LIMITED" && current.type === "FREE") {
      setAction(["method", purchase]);
    }
    else if (current && current.type !== "FREE") {
      message.success(translate("system.message.success", "Success"));
      onFinish(null, null, item);
    }

    else {
      setAction(["method", purchase]);
    }

  };

  const onFinish = async (value, setLoadButton, item) => {
    if (item.type === "FREE") {
      let res = await customerApi.plans();
      await auth.me()(dispatch);
      reload(filters);
      closeReciept();

      if (setLoadButton){
        setLoadButton(false);
        if (accounts.length === 0) {
          history.push("/account/new");
        }
      }

      // history.push("/account/new");

    }

    // limitedExpires

    else if (item.plan&&item.plan.limitedExpires > 0) {
      if (item.plan && item.plan.type && item.plan.type === "LIMITED" && current && current.type === "FREE") {
        let res = await customerApi.plans();
        await auth.me()(dispatch);
        reload(filters);
        setAction(["receipt", value]);
        if (setLoadButton)
          setLoadButton(false);
        onReceipt();
      }

      else if ((item.plan && item.plan.type && item.plan.type === "LIMITED" && current && current.type !== "FREE") || (item.plan && item.plan.type && item.plan.type === "LIMITED" && !current)) {
        let res = await customerApi.plans();
        await auth.me()(dispatch);
        reload(filters);
        closeReciept();
        if (setLoadButton)
          setLoadButton(false);
      }
    }

    else if (item.plan && item.plan.limitedExpires === 0) {
      let res = await customerApi.plans();
      await auth.me()(dispatch);
      reload(filters);
      setAction(["receipt", value]);
      if (setLoadButton)
        setLoadButton(false);
      onReceipt();
    }

    else if (current && current.type !== "FREE") {
      let res = await customerApi.plans();
      await auth.me()(dispatch);
      reload(filters);
      closeReciept();
    }

    else {
      let res = await customerApi.plans();
      await auth.me()(dispatch);
      reload(filters);
      setAction(["receipt", value]);
      if (setLoadButton)
        setLoadButton(false);
      onReceipt();
    }

    // if (current) {
    //   let res = await customerApi.plans();
    //   await auth.me()(dispatch);
    //   reload(filters);
    //   closeReciept();
    // }


  };


  const purchaseCancel = async (id) => {
    await payment.purchaseCancel(id);
    message.success(translate("system.message.success", "Success"));
    setOrder({
      planData: {
        _id: null
      }
    });
  };

  const reload = async (filter) => {
    setTableLoad(true);
    let res = await customerApi.plans(filter);
    setOrder({ ...order, ...res.purchase });
    setPlans(res.plans);
    setCurrent(res.current);

    setTableLoad(false);
    await auth.me()(dispatch);

  };



  const purchase = async (item) => {
    // console.log("****************************************");
    // console.log(`Current amount ${current&&current.amount} > 0 ${item.amount} === 0`);
    // if ((current.amount > 0) && (item.amount === 0)) {
    //   Modal.error({
    //     title  : translate("plan.planned", "Already planned"),
    //     content: translate("plan.planned.alert", "You cannot use this plan because you've already purchased the plan.")
    //   });
    //   return;
    // }
    if (current&&current.amount === 0 && item && item.amount === 0) {
      confirm({
        icon             : <ExclamationCircleOutlined />,
        content          : translate("system.confirmation.question", "Are you sure ?"),
        okText           : translate("system.yes", "Yes"),
        cancelText       : translate("system.no", "No"),
        okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
        cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
        className        : "btn-custom-class",
        async onOk() {

          try {

            let purchase = await payment.purchase(item._id);
            switchPayment(purchase, item);

          } catch (err) {
            if (err.message === "ACCOUNT_LIMIT_EXCEEDED") {
              message.error(translate("line.account.limit.alert", "The current connected Line account is over the limit of the ordering plan. Please remove Line accounts before continuing the order"));
            }
            else {
              message.error(err.message);
            }
          }
        },
      });

    }
    else {

      confirm({
        icon             : <ExclamationCircleOutlined />,
        content          : translate("system.confirmation.question", "Are you sure ?"),
        okText           : translate("system.yes", "Yes"),
        cancelText       : translate("system.no", "No"),
        okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
        cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
        className        : "btn-custom-class",
        async onOk() {

          try {

            let purchase = await payment.purchase(item._id);
            switchPayment(purchase, item);

          } catch (err) {
            if (err.message === "ACCOUNT_LIMIT_EXCEEDED") {
              message.error(translate("line.account.limit.alert", "The current connected Line account is over the limit of the ordering plan. Please remove Line accounts before continuing the order"));
            }
            else {
              message.error(err.message);
            }
          }
        },
      });

    }

  };

  const pay = async (item) => {
    setAction(["method", { ...item }]);
  };

  const columns = useHeader({ translate, purchase, current, pay, order, purchaseCancel, disabled });

  const handlePrint = event => {
    event.preventDefault();
    window.print();
  };

  const onBack = () => {
    if (accounts.length === 0) {
      history.push("/account/new");
    } else {
      history.push("/");
    }
  };
  const onClear = () => {
    let clearFilter = { ...filters, query: null };
    setFilters({ ...filters, query: null });
    reload(clearFilter);
  };
  const closeReciept = () => {
    setAction([]);
    setReceiptVisible(false);
    if (accounts.length === 0) {
      history.push("/account/new");
    }
  };
  const onReceipt = () => {
    setReceiptVisible(true);
  };

  const calculateDate = () => {
    if (user && user.balance && user.balance.trailExpire || user && user.balance && user.balance.trailExpire > 0) {
      return ` ${user.balance.trailExpire} ${translate("dashboard.day", "day")}`;
    }
    return translate("plan.trial.end", "Trail ended");
  };

  React.useEffect(() => {

    setFetching(true);
    reload(filters);
    const script = document.createElement("script");
    script.src = `${gmoUrl}`;
    document.body.appendChild(script);
    setFetching(false);
  }, []);

  React.useEffect(() => {
    setAction([...action]);

  }, [receiptVisible]);


  return (
    <PlanWrapper>
      <Spin spinning={fetching}>
        <PageContainer>
          <PageHeader title={translate("plan.page.title", "Plan configuration")} onBack={() => onBack("/")} extra={[
            ((()=>{
              if (user.role === "ADMIN" || user.role === "AGENCY"){
                return (<Button key={1} type="primary" onClick={() => onAccountLogout()}><LogoutOutlined />{translate("logout", "Log out")}</Button>);
              }
              return "";
            })())
          ]} />
          <PageContent>
            {current && (
              <div className="currentPlan">
                <Descriptions title="" bordered>
                  <Descriptions.Item label={<div className="select-plan-header">{translate("plan.current.title", "Current plan")}</div>}><div style={{ color: "rgb(0 157 75)" }}>
                    <span style={{ fontSize: "1.4em" }}>{current.name}</span>
                  </div>
                  <div style={{ color: "black" }}>{translate("plan.account", "Account balance")} <strong>{current.accountLimit === -1 ? translate("system.unlimited", "Unlimited") : current.accountLimit}</strong></div>
                  <div style={{ color: "black" }}>{translate("plan.message", "Message balance")} <strong>{current.messageLimit === -1 ? translate("system.unlimited", "Unlimited") : current.messageLimit}</strong></div>
                  {current.type === "LIMITED" &&
                      <div style={{ color: "black" }}>{translate("plan.limited.leftover", "Remaning trail day")} <strong>{`: ${calculateDate()} `}</strong></div>
                  }
                  {/* <div style={{ color: "black" }}>{translate("plan.duedate", "Next Payment Date:")} <strong>{moment(user.balance && user.balance.expireIn).format("YYYY-MM-DD") || "-"}</strong></div> */}
                  {/* <div style={{ color: "black" }}>{translate("plan.limited.leftover", "Trial ends in:")} <strong>{moment(user.balance && user.balance.expireIn).format("YYYY-MM-DD") || "-"}</strong></div> */}

                  </Descriptions.Item>
                </Descriptions>
              </div>
            )}

            <div className="current-plan-list" style={{ borderColor: "#CDD1D9", marginTop: "20px" }}>
              <div className="header-plan" style={{ display: "flex", justifyContent: "space-between" }} >
                <h2 style={{ color: "white", marginTop: "10px" }}>{translate("plan.table.title", "Purchase credit")}</h2>
              </div>

              <div className="plan-search-container">
                <div className="sub-title">
                  {translate("plan.subtitle.text", "Select a plan to purchase.")}
                </div>
                <span>
                  <Input
                    name="query"
                    value={filters.query}
                    placeholder={translate("plan.ivnitation.code", "Invitation Code")}
                    onChange={e => setFilters({ ...filters, query: e.target.value })}
                    style={{ width: 250, margin: "10px 0px" }}
                    className="ant-input-custom"
                    prefix={<SearchOutlined />}
                  />
                  <Button className="clear-button" type={"primary"} onClick={() => reload(filters)}>{translate("system.input", "Insert")}</Button>
                  <Button className="clear-button" onClick={onClear}>{translate("system.clear", "Clear")}</Button>

                </span>
              </div>
              <div style={{ margin: "0px 20px" }}>
                <Alert message={<div>{translate("plan.alertbox", "if the number of delivered messages exceeds the remaining credits, you will be charged for the surplus credit when you make next purchase.")}</div>} type="success" showIcon icon={<GiftOutlined />} />

                <Table
                  rowKey={(record) => record._id}
                  style={{ marginTop: "20px" }}
                  dataSource={plans}
                  loading = {tableLoad}
                  columns={columns.filter(item => { return Object.keys(item).length > 0&& item;})} />
              </div>
            </div>

            <Modal
              width={400}
              title={translate("payment.method", "Payment methods")}
              visible={action && action[0] === "method"}
              onCancel={() => setAction([])}
              maskClosable={false}
              footer={false}>
              {action && action[0] === "method" && <PaymentMethod onFinish={onFinish} payment={action && action[1]} paymentType />}
            </Modal>

            <Modal
              title={translate("receipt", "Receipt")}
              visible={receiptVisible}
              width = {550}
              onCancel={closeReciept}
              maskClosable={false}
              footer={[
                <Button type="primary" onClick={handlePrint}> <PrinterOutlined />{translate("receipt.print", "Print")}</Button>,
                <Button type="default" onClick={closeReciept}> <CloseCircleOutlined />{translate("receipt.close", "Close")}</Button>
              ]}>
              {action && action[0] === "receipt" && <Receipt action={action} />}
            </Modal>

          </PageContent>
        </PageContainer>
      </Spin>
    </PlanWrapper>
  );
};

const useHeader = ({ translate, purchase, pay, current, order, purchaseCancel, disabled }) => {

  return [{
    key   : "price",
    render: item => <div style={{ display: "flex", justifyContent: "space-around" }}><img alt="Money Icon" style={{ height: "20px", marginRight: "10px" }} src={Money} /><p>{item.amount.toLocaleString("jp-JP") + "￥"}</p></div>,
  }, {
    dataIndex: "name",
    key      : "name",
    render   : text => <p>{text}</p>,
  }, {
    key      : "description",
    render   : item => <p>{item.desc}{item.unitPrice === 0 ? translate("plan.additionalMessage.false", "(Additional message disabled)"): ""}</p>,
  }, {
    dataIndex: "accountLimit",
    render   : accountLimit => <p>{translate("plan.acc.limit", "Account limit")} <strong>{accountLimit === -1 ? translate("system.unlimited", "Unlimited") : accountLimit.toLocaleString()}</strong></p>
  }, {
    dataIndex: "followerLimit",
    render   : followerLimit => <p>{translate("plan.follower.limit", "Follower limit")} <strong>{followerLimit === -1 ? translate("system.unlimited", "Unlimited") : followerLimit.toLocaleString()}</strong></p>
  }, {
    dataIndex: "messageLimit",
    render   : messageLimit => <p>{translate("plan.message.limit", "Message limit")} <strong>{messageLimit === -1 ? translate("system.unlimited", "Unlimited") : messageLimit.toLocaleString()}</strong></p>
  }, disabled?{
    key   : "action",
    width : 10,
    render: (text, record) => {
      return (
        <PlanAction record={record} current={current} purchase={purchase} pay={pay} translate={translate} order={order} purchaseCancel={purchaseCancel} disabled={disabled}/>
      );
    }
  }:{}];
};



const PlanWrapper = styled.div`  
  .ant-spin-dot-item {
    background-color : ${colors.primary}
  }
  .ant-descriptions-item-label {
    width: 70%;
  } 
  .continue-button {
    margin-top: 10px;
    margin-right:37px;
    width: 116px 
  }
  .select-plan-header {
    margin-left: -5px;
    font-weight :bold;
    color :#434A54;
    font-weight: 500;
    color: rgb(0 157 75);
    font-size: 1.5em;
  }
  .header-plan {
    border :solid 1px;
    border-color :#CDD1D9;
    height :50px;
    padding-left :20px ;
    background-color :rgb(0 157 75)
  }
  .currentPlan {
    border: solid 1px;
    height: 100px;
    border-radius: 3px;
    border-color: #FAFAFA;
    display: flex;
    flex-direction :column;
  }
  .current-plan-list {
    border: solid 1px;
    border-radius: 3px;
    border-color: #FAFAFA;
    display: flex;
    flex-direction :column;
  }
  .current-items {
    // width: 100px;
    margin: 10px;
    margin-left: 70px;
    margin-right: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item{
    display : flex;
    flex-direction : column;
  }
  .item-header{
  font-size: 23px;
  }
  .item-text {
    font-size: 17px;
    font-family: sans-serif;
  }
  .plan-button {  
    border : solid 1px;
    border-color: #F0F0F0;
    width: 100%;
    height: 47px;
    border-radius: 4px;
    font-size: 19px;
    color : black;
    display : flex;
    justify-content: center;
    align-items: center;
  }
  .plan-wrapper-title {
    padding-bottom : 15px;
  }
  .wrapper-title { 
    color : red;
    font-size: 16px;
    font-weight: 400;
  }
  .plan-card-item-wrapper {
  }
  .plan-card {
    margin-top: 50px;
  }
  .plan-card-title {
    font-size: 16px;
    font-weight: 400;
    padding-bottom : 20px;
  }
  .plan-card-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 1px;
    width: 238px;
    height: 386px;
    border-radius: 10px;
    border-color: #F0F0F0;
  }
  .card-item-title {
    display : flex;
    justify-content: center;
  }
  .text-wrapper{
    display : flex;
    flex-direction : column;
  }
  .sub-title {
    margin :20px;
    font-feature-settings: "palt";
    word-break: break-word;
    line-height: 1.6;
    font-size: 1em;
  }
  .plan-search-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 19px;
    .clear-button {
      margin-left: 10px;
    }
  }
`;

import React, { memo } from "react";

import { Column } from "@ant-design/plots";

const ColumnChart = memo(({ oa }) => {
  const config = {
    data  : oa,
    xField: "date",
    yField: "gms_value",
    label : {
      style: {
        fill   : "#009d4b",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide  : true,
        autoRotate: true,
      },
    },
    meta: {
      gms_value: {
        alias: "売上",
      },
      date: {
        alias: "期間",
      },
    },
    columnStyle: {
      fill: "#009d4b",
    },
  };
  return <Column {...config} />;
});

ColumnChart.displayName = "ColumnChart";

export { ColumnChart };

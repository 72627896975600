import React from "react";
import { Dropdown, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useTranslate } from "../../hooks";
import { Button } from "../Design";

export default ({ actions, onClick, size = "middle" }) => {
  const { SubMenu } = Menu;
  const { translate, lang } = useTranslate();
  return (
    <Dropdown overlay={(
      <Menu>
        {Object.keys(actions).map(key => {
          if (actions[key]?.children) {
            return (
              <SubMenu key={`${actions[key].name}`} title={`${actions[key].name}`}>
                {actions[key]?.name === "Activate" ?
                  actions[key].children.map(item => (
                    <Menu.Item key={`Priority ${item}`} onClick={() => onClick("activate", item)}> {`${translate("scenario.event.priority", "Priority")} ${item}`}</Menu.Item>
                  )):
                  actions[key].children.map(item => (
                    <Menu.Item key={`Priority ${item}`} onClick={() => onClick({ name: actions[key].name }, item)}> {`${translate("scenario.event.priority", "Priority")} ${item}`}</Menu.Item>
                  ))
                }
              </SubMenu>
            );
          }

          return (
            <Menu.Item key={key} onClick={() => onClick(key)}>
              {actions[key]}
            </Menu.Item>
          );

        })}
      </Menu>
    )} trigger={["click"]}>
      <Button key={"dropdown btn"} size={size} type="default" >
        {translate("system.select", "Select")}
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};
import React from "react";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { FileImageOutlined } from "@ant-design/icons";

export default ({ link }) => {
  const { translate, lang } = useTranslate();
  return (
    <Container
      onClick={() => {
        window.open(translate(link, "link"), "_blank");
      }} >
      <FileImageOutlined style={{ marginRight: 8 }} />
      {translate("manual.link", "Manual link")}
    </Container>
  );
};
const Container = styled.button`
  background-color: white;
  border-radius: 3px;
  border: 1px solid gray;
  color: black;
  padding: 3px 6px;
  cursor: pointer; 
`;
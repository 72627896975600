import React from "react";
import { reference } from "../../apis";
import { Space, message, Empty, Tag } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Modal } from "antd";
import Form from "./Form";
import styled from "styled-components";
import { useSelector } from "react-redux";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";

const { confirm } = Modal;

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const { mappedReferenceTypes } = useSelector(state => state.general);
  const match = useRouteMatch();
  const myTableRef = React.useRef(null);
  const [action, setAction] = React.useState(null);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: ""
  });



  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  const columns = useHeader({
    history,
    onAction: (key, item) => {
      switch (key) {
        case "edit": {
          setAction(["update", item]);
          break;
        }
        case "remove": {
          confirm({
            icon      : <ExclamationCircleOutlined />,
            content   : translate("system.confirmation.question", "Are you sure ?"),
            okText    : translate("system.yes", "Yes"),
            cancelText: translate("system.no", "No"),
            async onOk() {
              await reference.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
            okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark } }
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction(null);
  };
  const onSubmit = async (data) => {
    let res = null;

    if (!action || (action && action[0] === "create"))
      res = await reference.create(match.params.type)(data);
    else
      res = await reference.update(match.params.type)(data);

    message.success(translate("system.message.success", "Successful"));

    myTableRef.current.reload(filters);
    onCancel(true);
  };
  React.useEffect(()=>{
    myTableRef.current.reload();
  });

  return (
    <PageContainer>
      <PageHeader title={mappedReferenceTypes[match.params.type.toUpperCase()] && translate("system.constant."+mappedReferenceTypes[match.params.type.toUpperCase()].code, mappedReferenceTypes[match.params.type.toUpperCase()].name) } extra={[
        <Button key={1} type="primary" onClick={() => setAction(["create", {}])}><PlusOutlined />{translate("system.add", "Add Business type")}</Button>
      ]} />

      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          filters={filters}
          ref={myTableRef}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={reference.list("business")}
        />
      </PageContent>
      <Modal
        maskClosable={false}
        title={action && action[0] === "create" ? translate("system.add", "Add") : translate("system.edit", "Edit")}
        visible={!!action}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form onCancel={onCancel} onSubmit={onSubmit} action={action} />
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction }) => {
  const { translate, changeLanguage, lang } = useTranslate();
  return [{
    title    : "No.",
    dataIndex: "key",
    width    : "10px"
  }, {
    title : translate("businesstype.name", "Name"),
    render: (record) => {
      return `${record.name}`;
    },
  }, {
    title : translate("system.action", "Action"),
    key   : "action",
    width : 100,
    render: (row) => {
      return <RowAction actions={{
        edit  : translate("system.edit", "Edit"),
        remove: translate("system.delete", "Remove")
      }} onClick={(key) => onAction(key, row)} />;
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
`;

import React from "react";
import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import { useTranslate } from "../../../hooks";
import { Form, FormItem, TimePicker, Radio, Input } from "formik-antd";
import { Form as AntForm, Row, Col } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";

const FormSchema = Yup.object({
  timerType: Yup.string().required(),
  hours    : Yup.number().when("timerType", {
    is  : "TIME",
    then: Yup.number().required(),
  }),
  minutes: Yup.number().when("timerType", {
    is  : "TIME",
    then: Yup.number().required(),
  }),
  days: Yup.number().when("timerType", {
    is  : "DAYS",
    then: Yup.number().required(),
  }),
  time: Yup.date().when("timerType", {
    is  : "DAYS",
    then: Yup.date().required(),
  }),
});

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        // message.error(translate("system.error.required", "Insert necessary fields first!"));
        return false;
      }

      return values;
    }
  }));

  return null;
});


export default React.forwardRef((props, ref) => {
  const { editable } = props;
  const submitRef = React.useRef();
  const { translate } = useTranslate();
  const { isEditable } = useSelector(state => state.process);
  const [data] = React.useState({
    timerType: "TIME", // TIME, DAYS
    hours    : undefined,
    minutes  : undefined,
    days     : undefined,
    time     : undefined,
    ...(editable && editable[0] === "update" ? editable[1] : {})
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    }
  }));

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema}
        onSubmit={() => {}}>
        {({ values }) => {
          return (
            <Form>
              <AntForm.Item label={translate("scenario.settings.form.timer", "Set timer")}>
                <Radio.Group name="timerType" disabled={!isEditable}>
                  <Radio value="TIME" className="timer">
                    <Row gutter={8}>
                      <Col span={10}>
                        <FormItem name="hours">
                          <Input min={0} type="number" name="hours" placeholder={translate("scenario.settings.form.timer.hours", "〇〇 Hours after")} disabled={!isEditable || values.timerType === "DAYS" } />
                        </FormItem>
                      </Col>
                      <Col span={2} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}><label>:</label></Col>
                      <Col span={10}>
                        <FormItem name="minutes">
                          <Input min={0} type="number" name="minutes" placeholder={translate("scenario.settings.form.timer.minutes", "〇〇 Minutes")} disabled={!isEditable || values.timerType === "DAYS"} />
                        </FormItem>
                      </Col>
                    </Row>
                  </Radio>
                  <Radio value="DAYS" className="timer">
                    <Row gutter={8}>
                      <Col span={10}>
                        <FormItem name="days">
                          <Input min={0} type="number" name="days" placeholder={translate("scenario.settings.form.timer.days", "〇〇 Days")} disabled={!isEditable || values.timerType === "TIME"} />
                        </FormItem>
                      </Col>
                      <Col span={2} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}><label>~</label></Col>
                      <Col span={10}>
                        <FormItem name="time">
                          <TimePicker name="time" format="HH:mm" style={{ width: "100%" }} disabled={!isEditable || values.timerType === "TIME"} />
                        </FormItem>
                      </Col>
                    </Row>
                  </Radio>
                </Radio.Group>
              </AntForm.Item>


              <SubmitForm ref={submitRef} translate={translate} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});

const Container = styled.div`
  padding : 10px;
  width: 100%;
  .timer {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    input {
      width: 100%;
    }
    .ant-form-item {
      margin-bottom: 0!important;
    }
    .ant-form-item-explain {
      display: none!important;
    }
  }

  .ant-input[disabled] {
    background: #fff!important;
    color: #333!important;
  }
  .ant-picker.ant-picker-disabled {
    background: #fff!important;
    color: #333!important;
  }
  .ant-picker-input > input[disabled] {
    background: #fff!important;
    color: #333!important;
  }
`;
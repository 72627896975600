import React from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router";
import Image from "../../assets/end.png";
import { agency as agencyApi } from "../../apis";

export default ()=> {

  const match = useRouteMatch();
  console.log(match.params);


  const connectAgency =async ()=> {
    await agencyApi.inviteConfirm(match.params);
  };

  React.useEffect(()=>{
    if (match.params.token && match.params.customerId) {
      connectAgency();
    }
  }, [match.params]);

  return (
    <Container>
      <div> <img src={Image}/></div>
    </Container>
  );
};

const Container = styled.div`
  width :100%;
  height: 100%; 
  min-height: 100%; 
  position: absolute; 
  display:flex;
  justify-content: center;
  align-items: center;
`;
import React from "react";
import { rakuten } from "../../apis";
import { Space, message, Empty, Tag, Button } from "antd";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import moment from "moment";
import CustomTable from "../../components/CustomTable";

export default (props) => {
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query   : "",
    follower: props.location.state.id
  });
  const { push } = useHistory();

  const onAction = async (item) => {
    try {
      await myTableRef.current.setLoading(true);
      await rakuten.renew({ orderNo: item.orderNo });
      await myTableRef.current.reload();
    } catch (err){
      if (err.code === "RAKUTEN_ORDER_NOTFOUND"){
        message.error(translate("rakuten.renew.order.notfound", "Order not found!"));
      }
    }
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);


  return (
    <PageContainer>
      <PageHeader title={translate("rakuten.order.title", "Rakuten orders")} />

      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>

      <PageContent>
        <a style={{ marginBottom: 12 }} onClick={() => {push("/rakuten/category");}}>{translate("target.form.desc.goto.category", "Go to the category page to sync category data")}</a>
        <CouponTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          loadData={rakuten.list}
          bordered
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell">{translate("system.number", "No")}</th>
                <th className="ant-table-cell">{translate("rakuten.order.date", "Order date")}</th>
                <th className="ant-table-cell">{translate("rakuten.order.number", "Order No")}</th>
                <th className="ant-table-cell">{translate("rakuten.order.item.id", "Rakuten Product ID")}</th>
                <th className="ant-table-cell">{translate("rakuten.order.item.name", "Rakuten Product name")}</th>
                <th className="ant-table-cell">{translate("rakuten.order.category", "Rakuten Product category")}</th>
                <th className="ant-table-cell">{translate("rakuten.order.coupon.id", "Coupon ID")}</th>
                <th className="ant-table-cell">{translate("rakuten.order.amount", "Purchase amount")}</th>
                <th className="ant-table-cell">{translate("system.action", "Action")}</th>
              </tr>
            </thead>
          )} tbody={(row, index) => (
            <tbody key={index} className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" rowSpan={row.items?.length }>{row.key}</td>
                <td className="ant-table-cell" rowSpan={row.items?.length }>{moment(row.orderDate).format("YYYY-MM-DD HH:mm")}</td>
                <td className="ant-table-cell" rowSpan={row.items?.length }>{row.orderNo}</td>

                <td className="ant-table-cell" >{
                  row?.items[0]?row?.items.map(item => {
                    return (
                      <>
                        <Tag>{item.itemId}</Tag>
                      </>
                    );
                  }):"-"
                }</td>

                <td className="ant-table-cell" >{
                  row?.items.length > 0?row?.items.map(item => {
                    return (
                      <>
                        <Tag>{item.name}</Tag>
                      </>
                    );
                  }):"-"
                }</td>
                <td className="ant-table-cell" >{
                  row?.items.length > 0?row?.items.map(item => {
                    return item.categories.map(category => {
                      return (
                        <>
                          <Tag>{category.parentNames.join(">")}{category.parentNames?.length > 0 ? ">" : ""}{category.name}</Tag>
                        </>
                      );
                    });
                  }):"-"
                }</td>
                <td className="ant-table-cell" rowSpan={row.items?.length }>
                  {
                    row?.couponIds.length >0?row?.couponIds.map(item => {
                      return (
                        <>
                          <Tag color="green">{item}</Tag>
                        </>
                      );
                    }):"-"
                  }
                </td>
                <td className="ant-table-cell" rowSpan={row.items?.length }>{row?.purchaseAmount?row?.purchaseAmount.toLocaleString("ja-JP", { style: "currency", currency: "JPY" }):"-"}</td>
                <td>
                  <Button className="ant-table-cell" onClick={() => onAction(row)}>{translate("rakuten.order.list.renew", "Renew")}</Button>
                </td>
              </tr>
            </tbody>
          )} />

      </PageContent>

    </PageContainer>
  );
};


const CouponTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px!important;
  }
`;


const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

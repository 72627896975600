import React from "react";
import styled from "styled-components";
import RichGrid from "./RichGrid";
import colors from "../../colors";

export const TemplateMenu = (props) => {
  const { width, type, template, active, onSelect } = props;

  return (
    <Menu href="#" className={`${active && "active"}`} onClick={() => onSelect(type)} {...props}>
      <img alt="origin" width={width || null} className="origin" src={require(`./images/${type}_${template}.jpg`)} />
      <img alt="hover" width={width || null} className="hover" src={require(`./images/_${type}_${template}.jpg`)} />
    </Menu>
  );
};

const RICH_TEMPLATES = {
  large: {
    A: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="33.3%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="C" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="33.3%">
                <RichGrid name="D" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="E" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="F" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    B: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="50%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
              <td width="50%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="50%">
                <RichGrid name="C" onSelect={onSelect} />
              </td>
              <td width="50%">
                <RichGrid name="D" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    C: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td colSpan="3" width="100%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="33.3%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="C" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="D" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    D: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td rowSpan="2" width="66.6%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="33.3%">
                <RichGrid name="C" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    E: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="100%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="100%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    F: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="50%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
              <td width="50%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    G: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="100%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  },
  compact: {
    A: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tr>
            <td width="33.3%">
              <RichGrid name="A" onSelect={onSelect} />
            </td>
            <td width="33.3%">
              <RichGrid name="B" onSelect={onSelect} />
            </td>
            <td width="33.3%">
              <RichGrid name="C" onSelect={onSelect} />
            </td>
          </tr>
        </table>
      );
    },
    B: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tr>
            <td width="33.3%">
              <RichGrid name="A" onSelect={onSelect} />
            </td>
            <td width="66.6%">
              <RichGrid name="B" onSelect={onSelect} />
            </td>
          </tr>
        </table>
      );
    },
    C: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tr>
            <td width="66.6%">
              <RichGrid name="A" onSelect={onSelect} />
            </td>
            <td width="33.3%">
              <RichGrid name="B" onSelect={onSelect} />
            </td>
          </tr>
        </table>
      );
    },
    D: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tr>
            <td width="50%">
              <RichGrid name="A" onSelect={onSelect} />
            </td>
            <td width="50%">
              <RichGrid name="B" onSelect={onSelect} />
            </td>
          </tr>
        </table>
      );
    },
    E: ({ width, height, onSelect, image }) => {
      return (
        <table width={width} height={height}>
          <tr>
            <td width="100%">
              <RichGrid name="A" onSelect={onSelect} />
            </td>
          </tr>
        </table>
      );
    }
  }
};

export default ({ width = 180, height = 60, type = "compact", template = "E", image, onSelect, errors }) => {
  return (
    <Template style={{ width, height }} className={`${errors.image && "error"}`}>
      {image && <img src={image.indexOf("http") === 0 ? image : `/uploads/${image}`} />}
      {RICH_TEMPLATES[type][template]({ width, height, image, onSelect: (name) => {
        if (onSelect)
          onSelect(name);
      } })}
    </Template>
  );
};


const Template = styled.div`
  position: relative;
  background-color: #00000024;
  &.error {
    table, th, td {
      border-color: red;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  table {
    border-collapse: collapse;
    position: absolute;
  }
  table, th, td {
    border: 2px solid ${colors.primaryDark}; // #06b900;
    padding: 0;
    top: 0;
  }
`;

const Menu = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  &.active {
    .origin {
      display: none;
    }
    .hover {
      display: inline-block;
    }
  }
  .hover {
    display: none;
  }
`;
import React from "react";
import { Empty, Input, Avatar, Tag, Select, Button } from "antd";
import { useTranslate } from "../../hooks";
import { MyTable, RowAction } from "../../components";
import { useHistory } from "react-router";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import { imagemap as imagemapService } from "../../apis";

const { Option } = Select;

export default ({ onSelect }) => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: "",
    type : "all"
  });

  const columns = useHeader({
    history,
    onSelect,
    translate,
    tableTitles: {
      no    : translate("system.number", "No."),
      image : translate("imagemap.image", "Image"),
      title : translate("imagemap.title", "Title"),
      action: translate("imagemap.action", "Action"),
      select: translate("system.select", "Select"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "select": {
          onSelect(item);
          break;
        }
        default:
      }
    },
  });

  const setType = (type) => {
    setFilters({ ...filters, type: type });
  };

  const onClear =()=> {
    setFilters({
      query: "",
      type : "image"
    });
    setQuery("");
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <>
      <PageFilter style={{ padding: 0, marginBottom: 20 }}>
        <Input.Group compact>
          <Input
            name="query"
            value={query}
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            prefix={<SearchOutlined />}
          />
          <Select tyle={{ width: 100 }} value={filters.type} onChange={setType}>
            <Option key="all">{translate("imagemap.map.type.all", "All type")}</Option>
            <Option key="image">{translate("imagemap.map.type.image", "Image")}</Option>
            <Option key="video">{translate("imagemap.map.type.video", "Video")}</Option>
          </Select>
        </Input.Group>
        <Button onClick={onClear}>
          {translate("system.clear", "Clear")}
        </Button>
      </PageFilter>
      <MyTable
        locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
        scroll={{ x: 200 }}
        ref={myTableRef}
        filters={filters}
        rowKey={(record) => record._id}
        columns={columns}
        loadData={imagemapService.list(filters.type)}
      />
    </>
  );
};

const useHeader = ({ onAction, tableTitles, translate }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.image,
    width : "100px",
    render: (item) => {
      return (<Avatar shape="square" size={100} src={`${item.previewUrl}`}/>);
    }
  }, {
    title : tableTitles.title,
    render: (record) => {
      return (
        <>
          <p style={{ margin: 0, padding: 0 }}>{record.altText}</p>
          {record.mapType === "image" ? <Tag color="green">{translate("imagemap.type.image", "IMAGE")}</Tag> : <Tag color="red">{translate("imagemap.type.video", "VIDEO")}</Tag>}
        </>
      );
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (order) => {
      return <RowAction actions={{
        select: tableTitles.select,
      }} onClick={(key) => onAction(key, order)} />;
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;
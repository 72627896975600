import React from "react";
import { useFetch } from "../../hooks";
import styled from "styled-components";
import renderHTML from "react-render-html";
import { documented as documentApi } from "../../apis";
import { Spin } from "antd";


export default () => {
  const [type, setType] = React.useState({ type:"TERMS"});
  const [document, fetching] = useFetch(documentApi.get, type)({});
  return (
    <Document>
      <Spin spinning={fetching}>
        {fetching === false && renderHTML(`${document?.body}`)}
      </Spin>
    </Document>
  );
};

const Document = styled.div`
  display: block;
  margin-right: 15px;
  margin-left: 15px;
  border: 1px solid #ccc;
  padding: 30px 60px;
  margin: 10px auto;

  h2 {
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    width: 600px;
  }

  @media screen and (min-width: 1280px) {
    width: 960px;
  }
`;
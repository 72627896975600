import React from "react";
import { Button, DatePicker, Space, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { dashboard } from "../../../../apis";
import { MyTable } from "../../../../components";
import { useTranslate } from "../../../../hooks";
import styled from "styled-components";

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const { translate } = useTranslate();
  const { RangePicker } = DatePicker;
  const [action, setAction] = React.useState([]);

  const [filters, setFilters] = React.useState({
    startDate: "",
    endDate  : ""
  });
  const columns = useHeader({
    history,
    tableTitles: {
      plan     : translate("dashboard.plan", "Plan name"),
      customers: translate("dashboard.totalusers", "Number of sales"),
      income   : translate("dashboard.planincome", "Total Income"),
    },
  });

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setFilters({ ...filters, startDate: action === null ? null : action[0], endDate: action === null ? null : action[1] });
    }, 300);

    return () => clearTimeout(timer);
  }, [action]);

  return (
    <>
      <PageFilter>
        <Space>
          <RangePicker value={[filters.startDate, filters.endDate]} onChange={values => setAction(values)} />
          <Button onClick={() => setAction(null)} >{translate("system.clear", "Clear")}</Button>
        </Space>
      </PageFilter>
      <MyTable
        filters={filters}
        ref={myTableRef}
        rowKey={(record) => record._id}
        columns={columns}
        // dataSource={data}
        loadData={dashboard.plans}
        pagination={false}
      />
    </>
  );
};

const useHeader = ({ onAction, tableTitles }) => {
  return [{
    title    : tableTitles.plan,
    dataIndex: "name",
  }, {
    title    : tableTitles.customers,
    dataIndex: "customers",
  }, {
    title : tableTitles.income,
    render: (item) => {
      return (
        <div>
          {item.totals.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}
        </div>
      );
    }
  }];
};

const Wrapper = styled.div`
  .ant-descriptions-item-label{
    width : 50%;
  }
`;
const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom :10px;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;
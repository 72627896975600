import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Alert, Empty } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { auth, account as accountApi } from "../../apis";
import { useSelector, useDispatch } from "react-redux";
import { MyTable, RowAction } from "../../components";
import { PageHeader, PageContainer, PageContent } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export default () => {
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const { accounts } = useSelector(state => state.general);
  const history = useHistory();
  const dispatch = useDispatch();
  const change = async (value) => {
    let res = await accountApi.change(value._id);

    dispatch({
      type   : "auth.account",
      payload: res
    });
    // window.location.reload();
  };

  const columns = useHeader({
    tableTitles: {
      no    : translate("system.number", "No."),
      name  : translate("report.lineaccount", "Line Acccount"),
      action: translate("system.action", "Action"),
      login : translate("system.login", "Login"),
    },
    onClick: (item) => {
      change(item);
    },

  });

  return (
    <div>
      <PageContainer>
        <PageHeader title={translate("account.list", "Account list")} extra={[
          <Button onClick={() => history.push("/account/new")} key="createButton" type="primary"><PlusOutlined /> {translate("login.register.text.signup", "Add account")} </Button>
        ]}/>
        <PageContent>

          <AlertStyle>
            <Alert message={translate("line.alert", "You have to connect at least one Line official account")} type="warning" showIcon />
          </AlertStyle>

          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            ref={myTableRef}
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={accounts}
          />
        </PageContent>
      </PageContainer>
    </div>
  );
};

const useHeader = ({ tableTitles, onClick }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.name,
    render: (record) => {
      return (
        <div>
          {
            record.pictureUrl ?
              <Avatar src={record.pictureUrl} style={{ marginRight: "10px" }} />
              :<Avatar icon={<UserOutlined />} style={{ marginRight: "10px" }} />
          }
          {record.displayName}
        </div>
      );
    },
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (row) => {
      return <RowAction actions={{
        login: tableTitles.login
      }} onClick={(key) => onClick(row)} />;
    }
  }];
};

const AlertStyle = styled.div`
  margin-bottom :20px;
`;
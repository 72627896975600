import React from "react";
import { useHistory } from "react-router-dom";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { richmenu } from "../../apis";
import { MyTable } from "../../components";
import { DatePicker, Input, Space, Modal, message, Empty, } from "antd";
import { SearchOutlined, CheckCircleOutlined } from "@ant-design/icons";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import styled from "styled-components";
import moment from "moment";

const { RangePicker } = DatePicker;

export default ({ onSelect }) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    query         : "",
    richmenuStatus: "ACTIVE",
    inSchedule    : true,
  });

  const columns = useHeader({
    translate,
    tableTitles: {
      no           : translate("system.priority", "Priority"),
      image        : translate("richmenu.image", "Image"),
      title        : translate("system.title", "Title"),
      actions      : translate("system.action", "Actions"),
      DisplayPeriod: translate("richmenu.display", "Display period"),
      type         : translate("message.type", "Type"),
      action       : translate("system.action", "Action"),
      default      : translate("richmenu.default", "Default"),
      targetType   : translate("richmenu.target.type", "Target type"),
    },
    history,
    onAction: (item) => {
      onSelect(item);
    }
  });

  const changeRange = (e) => {
    setFilters({ ...filters, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const clearRange = () => {
    setFilters({ ...filters, startDate: null, endDate: null });
    setRangePicker({ startDate: null, endDate: null });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title = {translate("products.richmenu", "Rich menus")}>
      </PageHeader>
      <PageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
        </Space>
        <Space>
          <RangePicker allowClear={false} value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
          <Button type="default" onClick={() => clearRange()}>{translate("system.clear", "Clear")}</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <Container>
          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            filters={filters}
            ref={myTableRef}
            rowKey={(record) => record._id}
            columns={columns}
            loadData={richmenu.list}
          />
        </Container>
      </PageContent>
    </PageContainer>
  );
};
const useHeader = ({ onAction, translate, tableTitles }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title : tableTitles.image,
    render: (record) => {
      return (
        <>
          <ImageContainer>
            <img style={{ width: "92px" }} src={record.image.indexOf("http") === 0 ? record.image : `/uploads/${record.image}`} />
          </ImageContainer>
        </>
      );
    }
  }, {
    title    : tableTitles.title,
    dataIndex: "name",
  }, {
    title : tableTitles.DisplayPeriod,
    render: (record) => {
      if (moment(record.schedules[1]).format("YYYY-MM-DD HH:mm") !== moment(record.schedules[0]).format("YYYY-MM-DD HH:mm")) {
        return (
          <>
            {record.schedules[0] && <span className="date">{moment(record.schedules[0]).format("YYYY-MM-DD HH:mm")}&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
            <span className="date">{moment(record.schedules[1]).format("YYYY-MM-DD HH:mm")}</span>
          </>
        );
      }
      return (
        <>
          {record.schedules[0] && <span className="date">{moment(record.richmenuSentDate).format("YYYY-MM-DD HH:mm")}&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
          <span className="date">{moment(record.schedules[1]).format("YYYY-MM-DD HH:mm")}</span>
        </>
      );
    }
  }, {
    title : tableTitles.targetType,
    render: (record) => (
      <div>{translate(`system.constant.${record.targetType}`, record.targetType)}</div>
    )
  }, {
    title : tableTitles.type,
    render: (record) => {
      return (
        <div>{translate(`system.constant.${record.type}`, record.type)}{record.isMulti ? translate("richmenu.ismutli", "・Multi") : ""}</div>
      );
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (record) => {
      return (
        <div>
          <Button onClick={() => onAction(record)}>{translate("system.select", "Set default")}</Button>
        </div>
      );
    }
  }];
};

const ImageContainer =styled.div`
  width: 92px;
  height: 63px;
  background-color: #FAFAFA;
  display : flex;
  align-items: center; 
`;
const DefaultRichMenu = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  margin-bottom: 20px;
  .image {
    width: 160px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    .row {
      margin-bottom: 5px;
    }
    .label {
      font-size: 14px;
      margin-right: 10px;
      width: 100px;
      text-align: right;
      color: #333;
    }
    .value {
      font-size: 14px;
      color: ${colors.primaryDark};
      font-weight: 400;
    }
  }
`;

const Container = styled.div`
// ant-table-row-expand-icon ant-table-row-expand-icon-collapsed
  .ant-table-row-expand-icon {
    display: none;
  }
`;

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;

import React from "react";
import { Upload, message } from "antd";
import { Field } from "formik";
import { FormItem } from "formik-antd";
import { CloseOutlined, LoadingOutlined, PictureOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../../colors";
import { useTranslate } from "../../../hooks";

const FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"];

const onBeforeUpload = (file) => {
  const isJpgOrPng = FILE_TYPES.indexOf(file.type) !== -1;

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }

  const isLt2M = file.size / 1024 / 1024 < 10;

  if (!isLt2M) {
    message.error("Image must smaller than 10MB!");
  }

  return isJpgOrPng && isLt2M;
};

export default ({ name, action, ...restProps }) => {
  const { translate } = useTranslate();
  const [loading, setLoading] = React.useState(false);

  const onChange = (e, { setFieldValue }) => {
    if (e.file.status === "uploading") {
      setLoading(true);
    }

    if (e.file.status === "done") {
      setFieldValue(name, e.file.response);
      setLoading(false);
    }
  };

  const onRemove = ({ setFieldValue }) => {
    setFieldValue(name, undefined);
  };

  return (
    <FormItem name={name}>
      <Field name={name}>
        {fieldProps => {
          const {
            field: { value },
            form: { setFieldValue }
          } = fieldProps;

          return (
            <UploadContainer>
              {value && !loading ? (
                <Uploaded>
                  <img src={value.previewImageUrl} alt='avatar' style={{ width: "100%" }} />
                  <a className="close" href="#" onClick={() => onRemove({ setFieldValue })}><CloseOutlined /></a>
                </Uploaded>
              ) : (
                <>
                  <Upload
                    name='file'
                    showUploadList={false}
                    action={action}
                    accept={FILE_TYPES}
                    beforeUpload={onBeforeUpload}
                    onChange={e => onChange(e, { setFieldValue })} {...restProps}>
                    <SelectFile>
                      {loading ? <LoadingOutlined /> : <PictureOutlined />}
                      <div className="upload-text">{ translate("imagemap.upload", "Upload Image")}</div>
                    </SelectFile>
                  </Upload>
                  <p>
                    {translate("message.fileformat", "File formats: ")}JPG, JPEG, PNG <br/>
                    {translate("message.filesize", "File size:")} Up to 10 MB
                  </p>
                </>
              )}
            </UploadContainer>
          );
        }}
      </Field>
    </FormItem>
  );
};

const SelectFile = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 25px 0;
  border: 1px dashed #ddd;
  min-height: 160px;
  cursor: pointer;
  border-radius: 4px;
  .anticon {
    font-size: 22px;
    color: #607D8B;
  }
  &:hover {
    .upload-text {
      color: ${colors.primaryDark};
    }
  }
  .upload-text {
    font-size: 13px;
    margin-top: 10px;
    transition: all .2s;
    font-weight: 400;
  }
`;
const Uploaded = styled.div`
  width: 120px;
  height: 100px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #c7c7c7;
  img {
    display: flex;
  }
  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    background: #6f6f6f;
    color: #fff;
    width: 22px;
    height: 20px;
    text-align: center;
    font-weight: 400;
    transition: all .2s;
    &:hover {
      background: #333;
    }
  }
`;
const UploadContainer = styled.div`
  .ant-upload {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
    color: #9a9a9a;
  }
`;
import React from "react";
import { useHistory } from "react-router-dom";
import { MyTable } from "../../components";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { operation } from "../../apis";
import { DatePicker, Descriptions, Empty, Input, Select, Space, message } from "antd";
import { Button } from "../../components/Design";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import colors from "../../colors";
import { useFetch } from "../../hooks";
import { useTranslate } from "../../hooks";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import usePlan from "../../hooks/usePlan";
import { ManualAlert, ManualDesc, ManualLink } from "../../components/Manual";

const { RangePicker } = DatePicker;
const { Option } = Select;
export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const { accounts, customer, customers } = useSelector(state => state.general);
  const [fileExportVisible, setFileExportVisible] = React.useState(false);
  // const [totals] = useFetch(operation.totals, accounts)({});
  const [totals, setTotals] = React.useState();
  const myTableRef = React.useRef(null);
  const [query, setQuery] = React.useState(undefined);
  const [customerfilter, setCustomer] = React.useState(undefined);
  const { checkPermission } = usePlan();
  const [usable, setUsable] = React.useState(checkPermission("FILE_EXPORT"));

  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  let firstDay = new Date(y, m, 1);
  let lastDay = new Date(y, m + 1, 0);
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    query    : undefined,
    customer : undefined,
    startDate: moment(new Date(firstDay)).startOf("day").toDate(),
    endDate  : moment(new Date(lastDay)).endOf("day").toDate()
  });

  const customerColumns = useHeader({
    history,
    tableTitles: {
      no             : translate("system.number", "No."),
      lineAccountName: translate("report.lineaccount", "LINE Account name"),
      totals         : translate("report.sentcount", "Sent count"),
      messageTitle   : translate("report.messagetitle", "Message Title"),
      messageSentDate: translate("report.senddate", "Sent Date"),
      replytoken     : translate("report.list.replytoken", "Reply tokens")
    },
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          //   history.push(`/product/${item._id}`)
          break;
        }
        default:
      }
    },
  });
  const adminOrAgencyColumns = useHeaderAdminOrAgency({
    history,
    tableTitles: {
      no             : translate("system.number", "No."),
      lineAccountName: translate("report.lineaccount", "LINE Account name"),
      totals         : translate("report.sentcount", "Sent count"),
      messageTitle   : translate("report.messagetitle", "Message Title"),
      messageSentDate: translate("report.senddate", "Sent Date"),
      customerName   : translate("report", "Customer name"),
    },
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          //   history.push(`/product/${item._id}`)
          break;
        }
        default:
      }
    },
  });
  const changeRange = (e) => {
    setFilters({ ...filters, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const exportDone = () => {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("file.export.request.accepted", "File export request has been accepted?"),
      okText    : translate("file.list.title", "Go to exported files"),
      cancelText: translate("file.export.ok", "Ok"),
      async onOk() {
        if (customer){
          history.push("/file");
        } else {
          history.push("/admin_file");
        }
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };

  const fileExport = async () => {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("file.export.request", "Would like to start exporting data to excel file?"),
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      async onOk() {
        try {
          await operation.exportFile({ filter: filters });
          exportDone();
        } catch (err) {
          if (err.message === "FILE_PROCESSING"){
            message.error(translate("file.export.error.processing", "File is in process. Please wait until current export process is over!"));
          }
          if (err.message === "PLAN_PERMISSION_INSUFFICIENT"){
            message.error(translate("system.error.plan.permission", "Your plan permission is insufficient to use this function"));
          }
        }
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
      if (filters.customer !== customerfilter)
        setFilters({ ...filters, customer: customerfilter });

    }, 300);
    return () => clearTimeout(timer);
  }, [query, customerfilter, filters]);


  React.useEffect(() => {
    setRangePicker({ startDate: moment(firstDay), endDate: moment(lastDay) });
  }, []);


  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.operationreport"></ManualLink>} title={translate("operation.report", "Operation Report")} extra={[]} />
      <ManualDesc translateKey ="manual.desc.operationreport" />
      <ManualAlert translateKey={"manual.alert.operationreport"} />
      {customer ?
        <TotalContainer style={{ marginBottom: "20px" }}>
          <Descriptions size="small" bordered>
            <Descriptions.Item key="header" label={translate("report.lineaccount", "LINE Account name")} span={4}>{translate("report.sentcount", "Sent count")}</Descriptions.Item>
            {totals && totals.accounts && totals.accounts.map((account, index) => {
              if (totals && totals.totals[account._id] > 0) {
                return <Descriptions.Item key={index} label={account.displayName} span={4}>{totals && totals.totals[account._id] || 0}</Descriptions.Item>;
              }
              return "";
            })}
            <Descriptions.Item label={translate("report.total", "Total")} span={4}>{totals&&totals.totals.all || 0}</Descriptions.Item>
          </Descriptions>
        </TotalContainer>
        :
        null
      }
      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
        </Space>

        <Space>
          {usable && <Button loading={fileExportVisible} onClick={fileExport} type="primary">{translate("payment.file.export", "File export")}</Button>}

          {customers&&customers.length > 0 && <Select
            showSearch
            value={customerfilter === undefined ? undefined : customerfilter}
            placeholder="Select a customer"
            style={{ width: 200 }}
            optionFilterProp="children"
            onChange={e => setCustomer(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {customers && customers.map(item => (
              <Option key={item._id} value={item._id}>{item.companyName}</Option>
            ))}

          </Select>}

          <RangePicker allowClear={false} value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
          {/* <Button onClick={() => { setQuery(undefined); setCustomer(undefined); setFilters({ ...filters, startDate: undefined, endDate: undefined }); }}>{translate("system.clear", "Clear")}</Button> */}
        </Space>
      </PageFilter>

      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={
            customer ?customerColumns: adminOrAgencyColumns
          }
          onResult={(item) => setTotals(item)}
          loadData={operation.reports}
        />
      </PageContent>
    </PageContainer>
  );
};
const useHeader = ({ tableTitles }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title    : tableTitles.lineAccountName,
    dataIndex: "lineAccountName",
  }, {
    title    : tableTitles.totals,
    dataIndex: "totals",
  }, {
    title    : tableTitles.messageTitle,
    dataIndex: "messageTitle",
  }, {
    title : tableTitles.replytoken,
    render: (record) => {
      return record?.message?.replyTokens.reduce((acc, itr) => {
        if (acc === "-")
          return itr;
        return acc + "/" + itr;
      }, "-");
    }
  }, {
    title : tableTitles.messageSentDate,
    render: (record) => moment(record.createdAt).format("YYYY-MM-DD HH:mm:ss")
  }];
};
const useHeaderAdminOrAgency = ({ tableTitles }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title    : tableTitles.customerName,
    dataIndex: "companyName",
  }, {
    title    : tableTitles.lineAccountName,
    dataIndex: "lineAccountName",
  }, {
    title    : tableTitles.totals,
    dataIndex: "totals",
  }, {
    title    : tableTitles.messageTitle,
    dataIndex: "messageTitle",
  }, {
    title : tableTitles.messageSentDate,
    render: (record) => moment(record.createdAt).format("YYYY-MM-DD HH:mm:ss")
  }];
};

const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-descriptions {
    width: 100%;
  }
`;
const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;

import React from "react";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { Alert } from "antd";
import { useSelector } from "react-redux";
import ManualText from "./ManualText";

export default ({ translateKey }) => {
  const { translate, lang } = useTranslate();

  return (
    <Container>
      <Alert type="warning" message={
        <div>
          {translate(translateKey, ManualText[translateKey])}
        </div>
      } />
    </Container>
  );
};
const Container = styled.div`
  color: #5a5d61;
  margin: 0px 24px 12px;
`;
const initialState = {
  chats             : {},
  conversations     : [],
  notifications     : [],
  conversation      : null,
  balanceNotfication: {}
};

const socket = (state = initialState, action) => {
  switch (action.type) {
    case "socket.chats": {
      const { payload } = action;
      return {
        ...state,
        chats: payload.reduce((accumulator, iterator) => {
          return {
            ...accumulator,
            [iterator.uid]: iterator
          };
        }, {})
      };
    }
    case "socket.chat": {
      const { payload } = action;
      if (state.conversation && state.conversation._id !== payload.conversation._id)
        return state;

      return {
        ...state,
        chats: {
          ...state.chats,
          [payload.uid]: payload
        }
      };
    }

    case "socket.balance.notfication": {
      const { payload } = action;

      return {
        ...state,
        balanceNotfication: payload
      };
    }

    case "socket.chat.seen": {
      const { payload = {} } = action;

      return {
        ...state,
        chats: {
          ...state.chats,
          [payload.uid]: payload
        }
      };
    }
    case "socket.conversations": {
      const { payload } = action;

      return {
        ...state,
        conversations: payload
      };
    }
    case "socket.conversation.follower.tag": {
      const { payload } = action;

      return {
        ...state,
        conversation: {
          ...state.conversation,
          follower: { ...state.conversation.follower, tags: payload }
        }
      };
    }
    case "socket.conversation.status": {
      const { payload } = action;

      return {
        ...state,
        conversation: {
          ...state.conversation,
          conversationFollow  : payload.conversationFollow,
          conversationResolved: payload.conversationResolved,
          conversationAnswered: payload.conversationAnswered,
          conversationStatusAt: payload.conversationStatusAt
        },
        conversations: state.conversations.map(item => {
          if (item._id === payload._id)
            return {
              ...item,
              conversationFollow  : payload.conversationFollow,
              conversationResolved: payload.conversationResolved,
              conversationAnswered: payload.conversationAnswered,
              conversationStatusAt: payload.conversationStatusAt
            };
          return item;
        })
      };
    }
    case "socket.on.conversation": {
      const { payload } = action;

      return {
        ...state,
        conversation: payload
      };
    }
    case "socket.conversation": {
      const { payload } = action;
      const conversation = state.conversations.find(c => c._id === payload._id);

      return {
        ...state,
        conversations: conversation ? [{
          ...conversation,
          lastChat: payload.lastChat
        }, ...state.conversations.filter(f => f._id !== conversation._id)] : state.conversations
      };
    }
    case "socket.conversation.seen": {
      const { payload = {} } = action;

      return {
        ...state,
        notifications: state.notifications.filter(n => n._id !== payload._id)
      };
    }
    case "socket.notification": {
      const { payload } = action;

      return {
        ...state,
        notifications: [...state.notifications, payload].reduce((accumulator, iterator) => {
          let conversation = accumulator.find(a => a._id === iterator._id);

          if (conversation)
            return accumulator;

          return [...accumulator, iterator];
        }, [])
      };
    }
    case "socket.notifications": {
      const { payload } = action;

      return {
        ...state,
        notifications: payload
      };
    }
    default:
      return state;
  }
};

export default socket;
import React, { useState } from "react";
import { Form, Input, FormItem, Select, Checkbox } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Spin } from "antd";
import * as Yup from "yup";
import styled from "styled-components";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import colors from "../../../colors";


const FormSchema = (translate) => Yup.object().shape({
  email      : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  name       : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  companyName: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
});
const { Option } = Select;
export default ({ action, onSubmit }) => {
  const { translate } = useTranslate();
  const [data] = useState({
    email      : undefined,
    companyName: undefined,
    name       : undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  return (
    <Spin spinning={false}>
      <Formik
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <Divider>{translate("admin.logincred", "Login credentials")}</Divider>
            <FormItem label={translate("forgot.email", "E-mail")} name="email" required>
              <Input name="email" type="email" label={translate("forgot.email", "E-mail")} placeholder={translate("forgot.email", "E-mail")}/>
            </FormItem>

            <FormItem label={translate("useredit.companyname", "Company name")} name="companyName" required>
              <Input name="companyName" placeholder={translate("useredit.companyname", "Company name")} />
            </FormItem>

            <FormItem label={translate("useredit.name", "Name")} name="name" required>
              <Input name="name" placeholder={translate("useredit.name", "Name")} />
            </FormItem>

            <AntForm.Item>
              <Button htmlType="submit" type="primary" loading={isSubmitting} block>{translate("button.register", "Register")}</Button>
            </AntForm.Item>

          </Form>
        )}
      </Formik>
    </Spin>
  );
};

const Divider = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 500;
  background: #e7e7e7;
  padding: 4px 8px;
  border-radius: 2px;
  color: ${colors.primaryDark};
`;

import React from "react";
import moment from "moment";
import { Button, Form as AntForm, message } from "antd";
import { Formik } from "formik";
import { useTranslate } from "../../../hooks";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { Form, FormItem, DatePicker } from "formik-antd";
import colors from "../../../colors";
import * as Yup from "yup";

const FormSchema = (translate) => Yup.object({
  date: Yup.string().required(translate("system.message.required", "This field is required!")).typeError(translate("system.message.required", "This field is required!"))
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    date: undefined,
    ...(action && action[1])
  });

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={(data, { setSubmitting })=> {
        let error = false;
        if (moment(data.date) < moment()){
          message.error(translate("system.error.schedule.time", "Set schedule time in the future"));
        } else {
          onSubmit(data);
        }
      }}>
      {() => {
        return (
          <Form {...formItemLayout}>
            <FormItem name="date" label={translate("trigger.event.date.form", "Date")}>
              <DatePicker
                name="date"
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                showTime={{ defaultValue: moment("HH:mm"), format: "HH:mm" }}
              />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button htmlType="submit" type="primary" style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};
import React, { useState } from "react";
import { Form, FormItem, Input, Select } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form as AntForm, Modal, Collapse } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import Button from "../../components/Design/Button";
import { useTranslate } from "../../hooks";
import { account as accountService, auth } from "../../apis";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined, ExclamationCircleOutlined, BulbOutlined } from "@ant-design/icons";
import colors from "../../colors";
import copy from "copy-to-clipboard";
import moment from "moment-timezone";
import styled from "styled-components";

const { confirm } = Modal;
const { Option } = Select;

const FormSchema = (translate) => Yup.object().shape({
  lineChannelAccessToken : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  lineChannelId          : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  lineChannelSecret      : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  lineClientChannelId    : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  lineClientChannelSecret: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
});

const { Panel } = Collapse;

export default ({ action }) => {
  const [removing, isRemoving] = useState(false);
  const { translate } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const timezones = moment.tz.names();
  const [removeButton, setRemoveButton] = React.useState(false);
  const [data, setFormData] = React.useState({
    lineChannelAccessToken : undefined,
    lineChannelId          : undefined,
    lineChannelSecret      : undefined,
    lineClientChannelId    : undefined,
    lineClientChannelSecret: undefined,
    ...(action && action[0] === "update" ? {
      ...action[1],
      lineClientRedirectUri: action[1].lineClientRedirectUri + "\n"+action[1].liffRedirectUri
    } : {}),
  });

  const onCopy = async () => {
    copy(data.lineWebhookUrl);
    message.success(translate("system.message.success", "Success"));
  };

  // lineClientRedirectUri
  const onCopyClientURL = async () => {
    copy(data.lineClientRedirectUri);
    message.success(translate("system.message.success", "Success"));
  };
  const onRemove = async () => {
    isRemoving(true);
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("system.confirmation.question", "Are you sure ?"),
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      async onOk() {
        await accountService.remove(data._id);
        message.success(translate("system.message.success", "Success"));

        auth.me()(dispatch);

        history.goBack();
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
    isRemoving(false);
  };


  const onSubmit = async (value) => {
    if (!value.timezone) value.timezone = "Asia/Tokyo";

    let res;

    try {
      if (value && value._id && data.lineChannelId) {
        res = await accountService.update({
          lineChannelAccessToken : value.lineChannelAccessToken.trim(),
          lineChannelId          : value.lineChannelId.trim(),
          lineChannelSecret      : value.lineChannelSecret.trim(),
          lineClientChannelId    : value.lineClientChannelId.trim(),
          lineClientChannelSecret: value.lineClientChannelSecret.trim(),
          timezone               : value.timezone,
          _id                    : value._id
        });
      }
      else if (value && value._id && data?.ecmaccesskey && !data?.lineChannelId) {
        res = await accountService.ecmCreate({
          lineChannelAccessToken : value.lineChannelAccessToken.trim(),
          lineChannelId          : value.lineChannelId.trim(),
          lineChannelSecret      : value.lineChannelSecret.trim(),
          lineClientChannelId    : value.lineClientChannelId.trim(),
          lineClientChannelSecret: value.lineClientChannelSecret.trim(),
          timezone               : value.timezone,
          _id                    : value._id
        });
      }
      else {
        res = await accountService.create({
          lineChannelAccessToken : value.lineChannelAccessToken.trim(),
          lineChannelId          : value.lineChannelId.trim(),
          lineChannelSecret      : value.lineChannelSecret.trim(),
          lineClientChannelId    : value.lineClientChannelId.trim(),
          lineClientChannelSecret: value.lineClientChannelSecret.trim(),
          timezone               : value.timezone
        });
        setRemoveButton(true);
      }

      setFormData({
        ...res,
        lineClientRedirectUri: res.lineClientRedirectUri + "\n"+res.liffRedirectUri
      });

      message.success(translate("system.message.success", "Success"));
      await auth.me()(dispatch);
    } catch (err) {
      if (err.message === "ALREADY_REGISTERED") {
        message.error(translate("line.alert2", "This account has been registered already!"));
      }
      if (err.message === "LOGIN_CHANNEL_AUTH") {
        message.error(translate("account.linelogin.failed", "Failed to authenticate line login channel!"));
      }
      else {
        message.error(translate("system.message.accesstoken.invalid", "Channel access token is invalid !"));
      }
    }
  };

  const onChange = (value) => {
    moment.tz.setDefault(value);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <CollapseStyle>
              <Collapse
                ghost={true}
                defaultActiveKey={["1", "2"]}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                <Panel disabled header={translate("line.messaging.api", "Messaging API")} key="1" ghost>
                  <FormItem label={translate("line.id", "Channel ID")} name="lineChannelId" required>
                    <Input name="lineChannelId" placeholder={translate("line.id", "Channel ID")} />
                  </FormItem>
                  <FormItem label={translate("line.secret", "Channel secret")} name="lineChannelSecret" required>
                    <Input name="lineChannelSecret" placeholder={translate("line.secret", "Channel secret")} rows="1" />
                  </FormItem>
                  <FormItem label={translate("line.token", "Channel access token")} name="lineChannelAccessToken" required>
                    <Input name="lineChannelAccessToken" placeholder={translate("line.token", "Channel access token")} rows="1" />
                  </FormItem>
                  <FormItem style={{ display: "none" }} label={translate("line.timezone", "Time zone")} name="timezone" required>
                    <Select
                      showSearch
                      name="timezone"
                      placeholder={translate("line.selectzone", "Select time zone")}
                      defaultValue="Asia/Tokyo"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={onChange}>
                      {timezones && timezones.map((zone, index) => <Option key={index} value={zone}>{zone}</Option>)}
                    </Select>
                  </FormItem>
                  {data.lineWebhookUrl && (
                    <FormItem label={translate("line.webhook", "Webhook URL")} name="lineWebhookUrl">
                      <Input name="lineWebhookUrl" placeholder="Line webhook url" suffix={<Button size="small" type="default" onClick={onCopy}>{translate("line.copy", "Copy")}</Button>} disabled />
                    </FormItem>
                  )}

                </Panel>
                <Panel disabled header={translate("line.login.channel", "Line Login Channel")} key="2">
                  <FormItem label={translate("line.id", "Channel ID")} name="lineClientChannelId" required>
                    <Input name="lineClientChannelId" placeholder={translate("line.id", "Channel ID")} rows="1" />
                  </FormItem>
                  <FormItem label={translate("line.secret", "Channel secret")} name="lineClientChannelSecret" required>
                    <Input name="lineClientChannelSecret" placeholder={translate("line.secret", "Channel secret")} rows="1" />
                  </FormItem>
                  {data.lineClientChannelId && (
                    <FormItem label={translate("line.login.callback", "Callback URL")} name="lineClientRedirectUri">
                      <TextAreaContainer>
                        <Input.TextArea name="lineClientRedirectUri" placeholder="Callback url" disabled />
                        <Button className="text-area-copy-button" size="small" type="default" onClick={onCopyClientURL}>{translate("line.copy", "Copy")}</Button>
                      </TextAreaContainer>
                    </FormItem>
                  )}
                </Panel>
              </Collapse>
            </CollapseStyle>
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>

              {removeButton ?
                <Button type="danger" onClick={onRemove} loading={removing} style={{ marginRight: 10 }}>{translate("system.delete", "Remove")}</Button> : null}

              {action && action[0] === "update" && (data.lineChannelId)?
                <Button type="danger" onClick={onRemove} loading={removing} style={{ marginRight: 10 }}>{translate("system.delete", "Remove")}</Button> : null}
              <Button type="default" onClick={() => history.push("/")}>{translate("system.back", "Back")}</Button>

              <a style={{ marginLeft: 10 }} href="https://rdr.jp/GkagbQk" target="blank">
                <Button type="default">
                  <BulbOutlined /> {translate("system.manual", "Manual")}
                </Button>
              </a>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};


const TextAreaContainer = styled.div`
  .text-area-copy-button {
    position: absolute;
    z-index: 1;
    right: 0px;
    margin-top: 7px;
    margin-right: 10px;
  }
`;

const CollapseStyle = styled.div`
 .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
   color :black;
 }
`;
import React from "react";
import styled from "styled-components";
import RichGrid from "./RichGrid";
import colors from "../../colors";
import { Stage, Layer, Rect, Group, Text, Image, } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import useImage from "use-image";

const DRAW_TEMPLATES = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

export const TemplateMenu = (props) => {
  const { width, type, template, active, onSelect } = props;

  return (
    <Menu className={`${active && "active"}`} onClick={() => onSelect(type)} {...props}>
      <img alt="origin" width={width || null} className="origin" src={require(`./images/${type}_map_${template}.jpg`)} />
      <img alt="hover" width={width || null} className="hover" src={require(`./images/_${type}_map_${template}.jpg`)} />
    </Menu>
  );
};

const IMAGEMAP_TEMPLATES = {
  custom: {
    H: ({ width, height, onSelect }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="100%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  },
  large: {
    A: ({ width, height, onSelect }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="50%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
              <td width="50%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="50%">
                <RichGrid name="C" onSelect={onSelect} />
              </td>
              <td width="50%">
                <RichGrid name="D" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    B: ({ width, height, onSelect }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="50%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
              <td width="50%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    C: ({ width, height, onSelect }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="50%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="50%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    D: ({ width, height, onSelect }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td colSpan="3" width="100%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="50%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
              <td width="50%">
                <RichGrid name="C" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    E: ({ width, height, onSelect }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="33.3%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="C" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="33.3%">
                <RichGrid name="D" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="E" onSelect={onSelect} />
              </td>
              <td width="33.3%">
                <RichGrid name="F" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    F: ({ width, height, onSelect }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="100%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="100%">
                <RichGrid name="B" onSelect={onSelect} />
              </td>
            </tr>
            <tr>
              <td width="100%">
                <RichGrid name="C" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    },

    G: ({ width, height, onSelect }) => {
      return (
        <table width={width} height={height}>
          <tbody>
            <tr>
              <td width="100%">
                <RichGrid name="A" onSelect={onSelect} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

  },
};

export default ({ width = 180, height = 60, type = "large", template = "G", onSelect, errors, image }) => {
  const dispatch = useDispatch();
  const { imageMapSelect, imageMapSelectTemplates } = useSelector(state => state.general);
  const [annotations, setAnnotations] = React.useState([]);
  const [newAnnotation, setNewAnnotation] = React.useState([]);
  const [bitmap] = useImage(image);

  const onMouseDown = (e) => {
    if (newAnnotation.length === 0) {
      const { x, y } = e.target.getStage().getPointerPosition();
      setNewAnnotation([{ x, y, width: 0, height: 0, key: "0" }]);
    }
  };

  const onMouseUp = (e) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;

      const { x, y } = e.target.getStage().getPointerPosition();

      const annotationToAdd = {
        x     : sx,
        y     : sy,
        width : x - sx,
        height: y - sy,
        key   : DRAW_TEMPLATES[annotations.length]
      };

      if (annotationToAdd.width < 0) {
        annotationToAdd.x = x;
        annotationToAdd.width = Math.abs(annotationToAdd.width);
      }

      if (annotationToAdd.height < 0) {
        annotationToAdd.y = y;
        annotationToAdd.height = Math.abs(annotationToAdd.height);
      }

      if (annotationToAdd.width < 15 || annotationToAdd.height < 15) {
        setNewAnnotation([]);
        return;
      }

      if (annotations.length >= 26) {
        setNewAnnotation([]);
        return;
      }

      annotations.push(annotationToAdd);

      setNewAnnotation([]);
      setAnnotations(annotations);

      dispatch({
        type   : "general.imagemapSelect.templates",
        payload: annotations.map(annotation => annotation.key)
      });

      dispatch({
        type   : "general.imagemapSelect.bounds",
        payload: annotations
      });
    }
  };

  const onMouseMove = (e) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;

      const { x, y } = e.target.getStage().getPointerPosition();
      const width = x - sx;
      const height = y - sy;

      setNewAnnotation([{
        x     : sx,
        y     : sy,
        width : width,
        height: height,
        key   : `${Math.round(Math.abs(width))}x${Math.abs(Math.round(height))}`
      }]);
    }
  };

  React.useEffect(() => {
    if (imageMapSelect && imageMapSelect.type === "draw" && imageMapSelect.actions) {
      let templates = [];
      let array = imageMapSelect.actions.filter(action => action.area).map((action, index) => {
        templates.push(DRAW_TEMPLATES[index]);
        return {
          x     : action.area.x,
          y     : action.area.y,
          width : action.area.width,
          height: action.area.height,
          key   : DRAW_TEMPLATES[index]
        };
      });

      setAnnotations(array);

      dispatch({
        type   : "general.imagemapSelect.templates",
        payload: templates
      });

      dispatch({
        type   : "general.imagemapSelect.bounds",
        payload: array
      });
    }
  }, [imageMapSelect]);

  React.useEffect(() => {
    if (imageMapSelect && imageMapSelect.type === "draw" && annotations.length > 0 && imageMapSelectTemplates.length < annotations.length) {
      let templates = [];
      let array = annotations.filter(annotation => imageMapSelectTemplates.indexOf(annotation.key) !== -1).reduce((accumulator, iterator, index) => {
        templates.push(DRAW_TEMPLATES[index]);

        return [
          ...accumulator, {
            ...iterator,
            key: DRAW_TEMPLATES[index]
          }];
      }, []);

      setAnnotations(array);

      dispatch({
        type   : "general.imagemapSelect.templates",
        payload: templates
      });

      dispatch({
        type   : "general.imagemapSelect.bounds",
        payload: array
      });
    }
  }, [imageMapSelectTemplates]);

  const annotationsToDraw = [...annotations, ...newAnnotation];

  const RenderImage = ({ width, height }) => {
    return <Image width={width} height={height} image={bitmap} />;
  };
  const onShapeClick = (value) => {
    console.log("clickecent, ", value);
  };
  return (
    <Template style={{ width: Math.round(width), height: Math.round(height) }} className={`${errors.baseUrl && "error"}`}>
      {(() => {
        if (type === "draw")
          return (
            <div style={{ border: `2px solid ${errors.baseUrl ? "red" : "#009d4b"}`, width: Math.round(width), height: Math.round(height), overflow: "hidden" }}>
              <Stage
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
                width={width}
                height={height}>
                <Layer>
                  <RenderImage width={Math.round(width)} height={Math.round(height)} />
                  {annotationsToDraw.map((value, index) => {
                    return (
                      <Group key={index} >
                        <Rect
                          x={value.x}
                          y={value.y}
                          width={value.width}
                          height={value.height}
                          onClick={onShapeClick(value)}
                          fill="rgba(0, 0, 0, 0.36)"
                          stroke={errors.baseUrl ? "red" : "#009d4b"} />
                        <Text
                          x={value.x + (value.width / 2) - 40}
                          y={value.y + (value.height / 2) - 10}
                          text={value.key}
                          fontSize={20}
                          fill="#ffffff"
                          align="center"
                          width={80}
                          height={20} />

                      </Group>
                    );
                  })}
                </Layer>
              </Stage>
            </div>
          );

        return (
          <>
            <div style={{ width: Math.round(width), height: Math.round(height), overflow: "hidden" }}>
              {image && <img src={`${image}`} width={Math.round(width)} height={Math.round(height)} />}
            </div>
            {IMAGEMAP_TEMPLATES[type][template]({ width: Math.round(width), height: Math.round(height), onSelect: (name) => onSelect && onSelect(name) })}
          </>
        );
      })()}

    </Template>
  );
};

const Template = styled.div`
  position: relative;
  background-color: #00000024;
  &.error {
    table, th, td {
      border-color: red;
    }
  }
  table {
    border-collapse: collapse;
    position: absolute;
  }
  table, th, td {
    border: 2px solid ${colors.primaryDark}; // #06b900;
    padding: 0;
    top: 0;
  }
`;

const Menu = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  &.active {
    .origin {
      display: none;
    }
    .hover {
      display: inline-block;
    }
  }
  .hover {
    display: none;
  }
`;
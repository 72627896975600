import React from "react";
import { Form, FormItem, Input, Select, Checkbox, DatePicker, TimePicker } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Row, Col } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { useSelector } from "react-redux";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import styled from "styled-components";
import colors from "../../colors";
import * as Yup from "yup";
const { Option } = Select;

const types = [{
  name : "Monthly",
  value: "MONTH"
}, {
  name : "Weekly",
  value: "WEEK"
}];

const FormSchema =(translate) => Yup.object({
  type        : Yup.string().required(translate("system.message.required", "This field is required!")),
  name        : Yup.string().max(50, translate("tag.name.limit", "Name exceeded character limit")).required(translate("system.message.required", "This field is required!")),
  days        : Yup.array().min(1).required(translate("system.message.required", "This field is required!")),
  hours       : Yup.array().of(Yup.string().nullable().required(translate("system.message.required", "This field is required!"))).min(1).required(translate("system.message.required", "This field is required!")),
  isEndOfMonth: Yup.boolean().required(translate("system.message.required", "This field is required!"))
});

export default ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const { regularSets } = useSelector(state => state.general);
  const [data, setFormData] = React.useState({
    type        : undefined,
    name        : "",
    days        : [],
    hours       : [null],
    regularSet  : undefined,
    isEndOfMonth: false,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onEndOfMonth = (value, values) => {
    setFormData({
      ...values,
      days        : value === true ? [...values.days, 32] : [...values.days.filter(i => i !== 32)],
      isEndOfMonth: value
    });
  };

  const onChangeRegularSet = (value, values) => {
    setFormData({
      ...values,
      regularSet  : value,
      isEndOfMonth: false,
      days        : regularSets.find(i => i.code === value).value,
    });
  };


  const renderWeek = (day) => {
    switch (day) {
      case 1: {
        return translate("system.constant.monday", "Monday");
      }
      case 2: {
        return translate("system.constant.tuesday", "Tuesday");
      }
      case 3: {
        return translate("system.constant.wednesday", "Wednesday");
      }
      case 4: {
        return translate("system.constant.thursday", "Thursday");
      }
      case 5: {
        return translate("system.constant.friday", "Friday");
      }
      case 6: {
        return translate("system.constant.saturday", "Saturday");
      }
      case 7: {
        return translate("system.constant.sunday", "Sunday");
      }
      default: {
        return day;
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      key={"add regular"}
      id="add-regular"
      onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue, handleSubmit }) => {
        return (
          <Form >
            <FormItem label={translate("regular.name", "Name")} name="name" required>
              <Input name="name" placeholder={translate("tag.manage.name.placeholder", "Tag name must be under 50 characters")} />
            </FormItem>
            <FormItem label={translate("regular.type", "Type")} name="type" required>
              <Select name="type" placeholder={translate("system.select", "Select")} style={{ width: "100%" }}>
                {types.map((type, index) => {
                  return <Option key={index} value={type.value}>{translate(`system.constant.${type.value}`, type.name)}</Option>;
                })}
              </Select>
            </FormItem>
            { values.type === "MONTH" &&
               <FormItem label={translate("regular.manage.type", "Regular set")} name="regularSet">
                 <Select name="regularSet" placeholder={translate("system.select", "Select")} onChange={(e) => onChangeRegularSet(e, values)} style={{ width: "100%" }}>
                   {regularSets.map((item, index) => {
                     return <Option key={index} value={item.code}>{translate(`regular.sets.${item.code.toLowerCase()}`, item.name)}</Option>;
                   })}
                 </Select>
               </FormItem>
            }
            {values.type === "MONTH" &&
               <FormItem label={translate("regular.days", "Days")} name="days" required>
                 <Checkbox.Group name="days" style={{ width: "100%" }}>
                   <Row>
                     {Array.from(Array(32).keys()).map((item, index) => {
                       if (item === 31) {
                         return <Col key={index} span={3}>
                           <Checkbox value={item + 1} onChange={(e) => onEndOfMonth(e.target.checked, values)}> {translate("regular.endofmonth", "End")}</Checkbox>
                         </Col>;
                       }
                       return <Col key={index} span={3}>
                         <Checkbox value={item + 1}> {item + 1}</Checkbox>
                       </Col>;
                     }
                     )}
                   </Row>
                 </Checkbox.Group>
               </FormItem>
            }
            {values.type === "WEEK" &&
               <FormItem label={translate("regular.weekdays", "Week days")} name="days" required>
                 <Checkbox.Group name="days" style={{ width: "100%" }}>
                   <Row>
                     {Array.from(Array(7).keys()).map((item, index) => (
                       <Col key={index} span={8}>
                         <Checkbox value={item + 1}> {renderWeek(item + 1)}</Checkbox>
                       </Col>
                     ))}
                   </Row>
                 </Checkbox.Group>
               </FormItem>
            }
            {values.hours.map((it, index) => {
              return (
                <FormItem label={translate("regular.hours", "Hours")} name={`hours[${index}]`} key={index} required>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <TimePicker format="HH:mm" name={`hours[${index}]`} showSecond={false} />
                    {index !== 0 && <Button type="danger" onClick={() => { setFieldValue("hours", [...values.hours.filter((i, index2) => index2 !== index)]); } } >{translate("system.delete", "Remove")}</Button> }
                    {index === 0 && <Button onClick={() => { setFieldValue("hours", [...values.hours, null]); } } >{translate("system.add", "Add")}</Button> }
                  </div>
                </FormItem>);
            })}
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
              <Button onClick={handleSubmit} type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

import React from "react";
import styled from "styled-components";
import Image from "../../assets/end.png";

export default ()=> {
  return (
    <Container>
      <div> <img src={Image}/></div>
    </Container>
  );
};

const Container = styled.div`
  width :100%;
  height: 100%; 
  min-height: 100%; 
  position: absolute; 
  display:flex;
  justify-content: center;
  align-items: center;
`;
/* eslint-disable no-nested-ternary */
import React from "react";
import { Form, FormItem, Select, Input, Checkbox, DatePicker } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";
import * as Yup from "yup";

const { Option } = Select;

const ValueSchema =(translate) => Yup.object({
  tag  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  value: Yup.mixed().required(translate("system.message.required", "Please fill out this field!"))
});

const FormSchema =(translate) => Yup.object({
  tags  : Yup.array().of(Yup.string()).min(1, translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
  values: Yup.array().of(ValueSchema(translate)).min(1, translate("system.message.required", "Please fill out this field!"))
});

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        // message.error(translate("system.error.required", "Insert necessary fields first!"));
        return false;
      }

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { editable } = props;
  const submitRef = React.useRef();
  const { tags } = useSelector(state => state.general);
  const { isEditable } = useSelector(state => state.process);
  const { translate } = useTranslate();
  const [data, setFormData] = React.useState({
    tags  : undefined,
    values: [],
    ...(editable && editable[0] === "update" ? {
      tags  : editable[1]?.tags?.map(t => t.tag),
      values: editable[1]?.tags || []
    } : {})
  });

  const onChange = (ids, values) => {
    setFormData({
      ...data,
      tags  : ids,
      values: ids.map(id => {
        let value = values.find(v => v.tag === id);
        let tag = tags.find(tag => tag._id === id);
        let val;

        switch (tag.type) {
          case "CHECKBOX":
            val = false;
            break;
          case "TAG":
            val = tag._id;
            break;
          default:
        }

        return {
          tag  : id,
          value: value !== undefined ? value.value : val
        };
      })
    });
  };

  const name = (tag) => {
    switch (tag.auto) {
      case "ages":
        return translate("tag.ages", "age");
      case "subscriptionPeriods":
        return translate("tag.days", "day");
      default:
        return "";
    }
  };

  const renderField = (id, index) => {
    let tag = tags.find(tag => tag._id === id);

    if (!tag) return;

    switch (tag.type) {
      case "SELECT": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`} required>
            <Select name={`values[${index}].value`} placeholder= {translate("system.select", "Select")} disabled={!isEditable}>
              {/* {tag.values.map((tag, index) => <Option key={index} value={tag._id}> {tag.auto === undefined ? tag.name : translate(`default.tag.${tag.auto}`, tag.name)} </Option>)} */}
              {tag.values.sort((a, b) => a.name > b.name ? 1 : -1).map((item, index) => <Option key={index} value={item._id}> {item.auto ?
                tag.auto === "ages" ? `${item.name} ${name(tag)}` : tag.auto === "subscriptionPeriods" ? `${item.name} ${name(item)}` :
                  translate(`default.tag.${item.value}`, item.name) : item.name
              }
              </Option>)}
            </Select>
          </FormItem>
        );
      }
      case "NUMBER": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name}name={`values[${index}].value`}>
            <Input type="number" name={`values[${index}].value`} disabled={!isEditable} />
          </FormItem>
        );
      }
      case "CHECKBOX": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`}>
            <Checkbox name={`values[${index}].value`} defaultChecked={false} disabled={!isEditable} />
          </FormItem>
        );
      }
      case "DATE": {
        return (
          <FormItem key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`values[${index}].value`} required>
            <DatePicker name={`values[${index}].value`} style={{ width: "100%" }} disabled={!isEditable} />
          </FormItem>
        );
      }
      default:
    }
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    }
  }));

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={() => {}}>
        {({ values }) => {
          return (
            <Form layout="vertical">
              <FormItem label= {translate("scenario.form.tag.title", "Auto tag")}name="tags" required>
                <Select name="tags" placeholder= {translate("system.search.button", "Search")} mode="multiple" onChange={(value) => onChange(value, values.values || [])} style={{ width: "100%" }} disabled={!isEditable} >
                  {tags.filter(t => !t.parent).map((tag, index) => {
                    return <Option key={index} value={tag._id}>{tag.auto === undefined ? tag.name: translate(`default.tag.${tag.auto}`, tag.name)}</Option>;
                  })}
                </Select>
              </FormItem>

              {values.tags && values.tags.map((id, index) => {
                return renderField(id, index);
              })}

              <SubmitForm ref={submitRef} translate={translate} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});


const Container = styled.div`
  .ant-picker  {
    background: #fff!important;
    color: #333!important;
    input { 
      color: #333!important;
    }
  }
  .ant-select-selector {
    background: #fff!important;
    color: #333!important;
  }
`;
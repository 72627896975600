import { LoadingOutlined } from "@ant-design/icons";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


const Translate = memo((props) => {
  const dispatch = useDispatch();
  const { lang, translate, user } = useSelector((state) => state.general);

  console.log("WTF.........");
  // useEffect(() => {
  //   if (user?._id) {
  //     console.log("LOADING :", user?._id);
  //     dispatch({ type: "loading", payload: false });
  //   }
  // }, [user?._id]);

  if (Object.keys(translate).length > 0 && Object.keys(user).length > 0) {
    console.log("translate :", translate);
    return props?.children;
  }



  return <div>
    <LoadingOutlined />
  </div>;

});

Translate.displayName = "Translate";

export default Translate;

import React from "react";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import { Form, FormItem, Input, InputNumber } from "formik-antd";
import { customer as customerApi } from "../../../apis";
import { Formik } from "formik";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import colors from "../../../colors";
import * as Yup from "yup";
import { Form as AntForm } from "antd";
import SearchField from "../../../components/SearchField";

const FormSchema =(translate) => Yup.object({
  customerId: Yup.string().required(translate("system.message.required", "This field is required!")),
  amount    : Yup.number().required(translate("system.message.required", "This field is required!")).typeError(translate("system.message.required", "This field is required!")),
});

export default ({ onSubmit, onCancel })=> {
  const { translate } = useTranslate();
  const [data, setData] = React.useState({
    customerId: undefined,
    amount    : undefined
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem label={translate("new.translate", "Select user")} name="customerId" required>
              <SearchField name="customerId" loadData={customerApi.list}/>
            </FormItem>
            <FormItem label={translate("new.translate", "Amount")} name="amount" required>
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                name="amount"
                placeholder={translate("new.translate", "Amount")}
              />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
              <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
              <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};
import React from "react";
import styled from "styled-components";
import colors from "../../../colors";
import { Spin } from "antd";
import moment from "moment";
import { useTranslate } from "../../../hooks";

export default ({ action }) => {
  const { translate } = useTranslate();
  // const [plan, setPlan] = useFetch(planApi.get, action[1].purchase.plan)(null);
  const [receiptData, setReceiptData] = React.useState(action[1].receipt);

  React.useEffect(() => {
    setReceiptData(action[1].receipt);
  }, [action]);

  const dateFormat = (dt, index) => {
    if (dt === undefined) {
      return <div>-</div>;
    }
    let year = dt.slice(0, 4);
    let month = dt.slice(4, 6);
    let day = dt.slice(6, 8);
    let hour = dt.slice(8, 10);
    let min = dt.slice(10, 12);
    return <span>{`${year}-${month}-${day} ${hour}:${min}`}</span>;
  };

  if (receiptData) {
    return (
      <Spin spinning={false}>
        <ReceiptWrapper>
          {/* <div className="receipt-header">
            <h2>レシート</h2>
          </div> */}
          <div className="receipt-body">
            <h4 style={{ marginBottom: 10 }}>{translate("receipt.date", "Date")} {action[1] && dateFormat(action[1].gmoTranDate)}</h4>
            <hr />
            <div style={{ marginBottom: 10 }}>{translate("receipt.company", "Company: ")} : {receiptData.customer.companyName} {translate("receipt.company.onchu", "")}</div>
            <div style={{ marginBottom: 10 }}>{translate("receipt.plan", "Plan")} : {receiptData && receiptData.planName} </div>
            <table className="receipt-table">

              <tr>
                <td>{translate("receipt.description", "Description")}</td>
                <td className="data">{receiptData && receiptData.planDescription}</td>
              </tr>
              <tr>
                <td>{translate("receipt.accountlimit", "LINE Account limit")}</td>
                <td className="data" >{receiptData && (receiptData.accountLimit === -1 ? translate("system.unlimited", "Unlimited") : receiptData.accountLimit)}</td>
              </tr>
              <tr>
                <td>{translate("plan.follower.limit", "Follower limit")}</td>
                <td className="data" >{receiptData && (receiptData.followerLimit === -1 ? translate("system.unlimited", "Unlimited") : receiptData.followerLimit)}</td>
              </tr>
              <tr>
                <td>{translate("receipt.message.limit", "Message limit")}</td>
                <td className="data" >{receiptData && (receiptData.messageLimit === -1 ? translate("system.unlimited", "Unlimited") : receiptData.messageLimit)}</td>
              </tr>
              <tr>
                <td>{translate("receipt.additional.fee", "Additional message fee")}</td>
                <td className="data">
                  
                {receiptData.perMessagePrice  > 0 ?
                    (
                      <span>{receiptData.perMessagePrice} {translate("system.yen", "￥")} / ({translate("receipt.per.message", "Per message")})</span>
                    )
                    :(
                      <span>{translate("plan.additionalMessage.false", "Additional message disabled")}</span>
                    )
                  }
                  </td>
              </tr>

            </table>
            <hr />

            <table className="receipt-table">
              {
                receiptData.receiptLines.map(item => (
                  <tr>
                    <td>{item.description} <br/>
                      {moment(item.date).format("YYYY/MM")}
                      {/* {translate("system.year", "/")} */}
                      {/* {moment(item.date).format("DD")} */}
                      {/* {translate("system.month.fee", "")} */}
                    </td>
                    {item.amount && <td className="data" >{item.amount && item.amount.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</td>}
                    {item.unitPrice && <td className="data" >
                      {item.quantity > 1 && <span>
                        ({item.quantity && item.quantity}通)</span>}
                      {item.unitPrice && item.unitPrice.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}
                    </td>}

                  </tr>
                ))
              }
            </table>
            <hr />
            <table className="receipt-table">
              <tr>
                <td>{translate("receipt.subtotal", "Subtotal")}</td>
                <td className="data" >{receiptData && receiptData.subTotal.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</td>
              </tr>
              <tr>
                <td>{translate("receipt.tax", "Tax")}</td>
                <td className="data" >{receiptData && receiptData.tax.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</td>
              </tr>
            </table> <hr />
            <table className="receipt-table">
              <tr>
                <td>{translate("receipt.total", "Total")}</td>
                <td className="data" >{receiptData && receiptData.total.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</td>
              </tr>
            </table>
          </div>
        </ReceiptWrapper>
      </Spin>
    );
  }
};

const ReceiptWrapper = styled.div`
  .receipt-header{
    display           : flex;
    flex-direction    : column;
    width             : 100%;
    height            : 80px;
    background-color  : ${colors.primary};
    justify-content   : center;
    align-items       : center;
    h2 {
      color : white;
    }
  }
  .receipt-body {
    width : 100%;
    background-color :#f5f5f5;
    padding: 10px;
  }
  .receipt-table {
    width : 100%;
  }
   tr {
      border : 1px solid #e8e0e0;
      padding-left : 10px;
    }
    td {
      border : 1px solid #e8e0e0;
      padding-left : 10px;
      width: 44%;
    }
    .data {
      text-align: right;
      border : 1px solid #e8e0e0;
      padding-right: 10px;
      padding-left : 10px;
      width: 44%;
    }
`;